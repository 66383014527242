import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, styled } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import { StatsBar, StatsBody, StatsContainer, StatsTile, Title, Value } from '@vertice/core/src/components/StatsBar';
import ChipButton from '@verticeone/design-system/src/components/ChipButton';
import { useFormatCurrency } from '@vertice/core/src/utils/formatting/currency';
import { TooltipInfoTrigger } from '@verticeone/design-system/src/components/Tooltip';
import { useFormatPercentage } from '@vertice/core/src/utils/formatting/percentage';
import { useInstanceData } from './providers/InstanceProvider';

const StyledStatsTile = styled(StatsTile)({
  padding: '4px 12px',
});

const Legend = styled((props) => <Text {...props} variant="caption" size="XS" color="text2" />)(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  left: '18px',
  top: '-5px',
  background: theme.palette.core.bg,
  padding: '0 8px',
}));

type WithIsActiveProp = {
  $isActive: boolean;
};

const StyledTitle = styled(Title)<WithIsActiveProp>(({ theme, $isActive }) => ({
  [TooltipInfoTrigger]: {
    opacity: 0.5,
    color: theme.palette[$isActive ? 'tertiary' : 'secondary'].color1,
  },
}));

type InstanceStatsProps = {
  customStatsBody?: React.ReactNode;
};

const InstanceStats = ({ customStatsBody }: InstanceStatsProps) => {
  const formatCurrency = useFormatCurrency();
  const formatPercentage = useFormatPercentage();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.OPTIMIZE.RI_STATS' });
  const { item, managedByVertice } = useInstanceData();
  const isOptimized = !item.isOverCommitted && !item.isUnderCommitted;

  return (
    <StatsContainer>
      <Legend>{t('LEGEND')}</Legend>
      <StatsBar>
        <StyledStatsTile>
          <StatsBody
            isLoading={false}
            data-testid="estimated_savings"
            title={
              <StyledTitle
                testId="label"
                $isActive={managedByVertice.isActive}
                color={managedByVertice.isActive ? 'tertiary1' : 'secondary1'}
                tooltip={{ title: t('SAVINGS.TOOLTIP.TITLE'), content: t('SAVINGS.TOOLTIP.CONTENT') }}
              >
                {t('SAVINGS.TITLE')}
              </StyledTitle>
            }
            value={
              <Stack direction="row" gap={1} alignItems="center">
                <Value color={managedByVertice.isActive ? 'tertiary1' : 'secondary1'}>
                  {formatCurrency(item.savingsCost, { maximumFractionDigits: 0 })}
                </Value>

                <Text
                  testId="percentage"
                  variant="body-bold"
                  size="M"
                  color={managedByVertice.isActive ? 'tertiary1' : 'secondary1'}
                  sx={{ opacity: 0.5 }}
                >
                  {formatPercentage(item.savingsPercent, { maximumFractionDigits: 0 })}
                </Text>
              </Stack>
            }
          />
        </StyledStatsTile>
        <StyledStatsTile>
          <StatsBody
            isLoading={false}
            data-testid="resource_cost"
            title={
              <Title
                testId="label"
                tooltip={{
                  title: t('COST.TOOLTIP.TITLE'),
                  content: t('COST.TOOLTIP.CONTENT'),
                }}
              >
                {t('COST.TITLE')}
              </Title>
            }
            value={<Value>{formatCurrency(item.totalCost, { maximumFractionDigits: 0 })}</Value>}
          />
        </StyledStatsTile>
        <StyledStatsTile>
          <StatsBody
            isLoading={false}
            data-testid="ri_coverage"
            title={
              <Title
                testId="label"
                tooltip={{
                  title: t('RI_COVERAGE.TOOLTIP.TITLE'),
                  content: t('RI_COVERAGE.TOOLTIP.CONTENT'),
                }}
              >
                {t('RI_COVERAGE.TITLE')}
              </Title>
            }
            value={<Value color={isOptimized ? 'success' : 'neutral'}>{formatPercentage(item.riCoverage)}</Value>}
            badge={
              <ChipButton
                isActive={managedByVertice.isActive}
                color={isOptimized ? 'success' : 'warning'}
                variant="ghost"
                size="S"
                testId="chip"
              >
                {item.isOverCommitted && t('RI_COVERAGE.CHIP.OVER_COMMITTED')}
                {item.isUnderCommitted && t('RI_COVERAGE.CHIP.UNDER_COMMITTED')}
                {isOptimized && t('RI_COVERAGE.CHIP.ON_TARGET')}
              </ChipButton>
            }
          />
        </StyledStatsTile>
        {customStatsBody && <StyledStatsTile>{customStatsBody}</StyledStatsTile>}
      </StatsBar>
    </StatsContainer>
  );
};

export default InstanceStats;
