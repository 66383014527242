import {
  Dialog,
  DialogActions,
  DialogHeader,
  DialogText,
  DialogContent,
  DialogTextWrapper,
} from '@verticeone/design-system/src/components/Dialog';
import Button from '@verticeone/design-system/src/components/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';

type RecommendationStatusChangeDialogProps = {
  open: boolean;
  onClose: () => void;
};

const RecommendationStatusChangeDialog = ({ open, onClose }: RecommendationStatusChangeDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog size="M" open={open} align="center">
      <DialogHeader>{t('CLOUD.CLOUD_RECOMMENDATIONS.STATUS_CHANGED_DIALOG.TITLE')}</DialogHeader>
      <DialogContent>
        <DialogTextWrapper>
          <DialogText variant="body-regular">
            {t('CLOUD.CLOUD_RECOMMENDATIONS.STATUS_CHANGED_DIALOG.DESCRIPTION')}
          </DialogText>
        </DialogTextWrapper>
      </DialogContent>
      <DialogActions>
        <Button variant="solid" onClick={onClose}>
          {t('DIALOG.BUTTONS.CONFIRM')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RecommendationStatusChangeDialog;
