import { PickersRangeCalendarHeaderProps } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { Stack, useTheme } from '@mui/material';
import IconButton from '../../IconButton';
import { ChevronLeft } from '@mui/icons-material';
import Text from '../../Text';
import ChevronRight from '@mui/icons-material/ChevronRightTwoTone';
import * as React from 'react';
import { useStaticDateRangePickerContext } from '../context/StaticDateRangePickerContext';
import { useTranslation } from 'react-i18next';
import i18n from '../../../translate';

type CustomCalendarHeaderProps = PickersRangeCalendarHeaderProps<Dayjs>;

const CustomCalendarHeader = (props: CustomCalendarHeaderProps) => {
  const { size } = useStaticDateRangePickerContext();
  const { currentMonth, onMonthChange, month, calendars, monthIndex } = props;
  const { palette } = useTheme();
  const { t } = useTranslation(undefined, { i18n });

  const selectNextMonth = () => onMonthChange(currentMonth.add(calendars, 'month'), 'left');
  const selectPreviousMonth = () => onMonthChange(currentMonth.subtract(calendars, 'month'), 'right');

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <IconButton
        variant="plain"
        onClick={selectPreviousMonth}
        disabled={monthIndex !== 0}
        sx={{ opacity: monthIndex === 0 ? undefined : '0' }}
        title={t('DATE_RANGE_PICKER.HEADER_ACTIONS.PREVIOUS_MONTH')}
        size={size}
        icon={ChevronLeft}
      />
      <Stack direction="row">
        <Text variant="body-bold" size={size} color={palette.text.color1}>
          {month.format('MMMM')}&nbsp;
        </Text>
        <Text variant="body-bold" size={size} color={palette.text.color3}>
          {month.format('YYYY')}
        </Text>
      </Stack>
      <IconButton
        variant="plain"
        onClick={selectNextMonth}
        disabled={monthIndex !== calendars - 1}
        sx={{ opacity: monthIndex === calendars - 1 ? undefined : '0' }}
        title={t('DATE_RANGE_PICKER.HEADER_ACTIONS.NEXT_MONTH')}
        size={size}
        icon={ChevronRight}
      />
    </Stack>
  );
};

export default CustomCalendarHeader;
