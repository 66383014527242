import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import Loader from '@verticeone/design-system/src/components/Loader';
import DataGrid, { DataGridProps } from '@verticeone/design-system/src/components/DataGrid';
import Text from '@verticeone/design-system/src/components/Text';
import AddItemFooter from './AddItemFooter';
import { getRowId, preProcessNonEmptyCell, valueComparator } from './utils';
import NoRows from './NoRows';
import { AnnualCostCell, EditableAnnualCostCell, EditableLicensesCell, EditableNameCell } from './cells';
import { useVendorProductsContext } from './VendorProductsContextProvider';
import { ProductItem, Tier } from './types';
import EditableTierCell from './cells/EditableTierCell';
import TierCell from './cells/TierCell';
import NameCell from './cells/NameCell';

export interface ProductListProps {
  extraColumns?: GridColDef[];
  dataGridExtraProps?: Partial<DataGridProps>;
  readOnlyMode?: boolean;
  showAnnualCost?: boolean;
  showLicenseType?: boolean;
  selectedProducts: ProductItem[];
}

const ProductList: FC<ProductListProps> = ({
  extraColumns = [],
  dataGridExtraProps = {},
  showAnnualCost,
  showLicenseType,
  selectedProducts,
  readOnlyMode,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTAKE_FORM.PRODUCTS_TABLE' });
  const { vendorProducts } = useVendorProductsContext();

  const nameCell = (params: GridCellParams<any, Tier>) => <NameCell {...params} />;
  const editableNameCell = (params: GridCellParams<any, string>) => (
    <EditableNameCell {...params} selectedProducts={selectedProducts} />
  );
  const tierCell = (params: GridCellParams<any, Tier>) => <TierCell {...params} />;
  const editableTierCell = (params: GridCellParams<any, Tier>) => <EditableTierCell {...params} />;
  const annualCostCell = (params: GridCellParams<any, number>) => <AnnualCostCell {...params} />;
  const editableAnnualCostCell = (params: GridCellParams) => <EditableAnnualCostCell {...params} />;
  const licensesCell = (params: GridCellParams) => <Text variant="caption">{params.row.numberOfLicences ?? ''}</Text>;
  const editableLicensesCell = (params: GridCellParams) => <EditableLicensesCell {...params} />;

  const columns: GridColDef[] = [
    {
      field: 'productId',
      headerName: t('PRODUCT'),
      renderCell: nameCell,
      renderEditCell: editableNameCell,
      preProcessEditCellProps: preProcessNonEmptyCell,
      disableColumnMenu: true,
      editable: true,
      flex: 1.3,
    },
    {
      field: 'licenseType',
      headerName: t('TIER'),
      renderCell: tierCell,
      renderEditCell: editableTierCell,
      disableColumnMenu: true,
      editable: true,
      flex: 1.3,
    },
    {
      field: 'annualCost',
      headerName: t('ANNUAL_COST'),
      renderCell: annualCostCell,
      renderEditCell: editableAnnualCostCell,
      disableColumnMenu: true,
      sortComparator: valueComparator,
      editable: true,
      flex: 1.3,
    },
    {
      field: 'numberOfLicences',
      headerName: t('LICENSES'),
      renderCell: licensesCell,
      renderEditCell: editableLicensesCell,
      disableColumnMenu: true,
      editable: true,
      flex: 1,
    },
  ];

  const columnsToUse = [...columns, ...extraColumns];

  if (vendorProducts === undefined) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  return (
    <DataGrid
      rows={selectedProducts || []}
      columns={columnsToUse}
      getRowId={getRowId}
      slots={{
        footer: AddItemFooter,
        noRowsOverlay: () => <NoRows readOnlyMode={readOnlyMode} />,
      }}
      sortingMode="client"
      isCellEditable={() => false}
      disableRowSelectionOnClick
      hideFooter
      columnHeaderHeight={48}
      autoHeight={true}
      columnVisibilityModel={{
        annualCost: !!showAnnualCost,
        licenseType: !!showLicenseType,
      }}
      {...dataGridExtraProps}
    />
  );
};

export default ProductList;
