import dagre from 'dagre';
import { WorkflowEdge, WorkflowModel, WorkflowNode } from '../model/types';
import { RendererEdge, RendererNode } from './types';
import assertExhausted from '@vertice/core/src/utils/assertExhausted';
import uniq from 'lodash/uniq';
import { getNodeDimensions } from './NodeComponents/getNodeDimensions';

/*
  The functionality in this file is not tested as it's not clear
  whether we're going to continue using ReactFlow for rendering.
 */

const modelNodeToRendererNode = (node: WorkflowNode, positions: dagre.graphlib.Graph): RendererNode => {
  const kind = node.kind;
  const nodeWithPosition = positions.node(node.id);

  const nodeDimensions = getNodeDimensions(node.kind);
  const position = {
    x: nodeWithPosition.x - nodeDimensions.width / 2,
    y: nodeWithPosition.y - nodeDimensions.height / 2,
  };

  switch (kind) {
    case 'start':
      return {
        id: node.id,
        type: 'start',
        data: node,
        position,
      };
    case 'end':
      return {
        id: node.id,
        type: 'end',
        data: node,
        position,
      };
    case 'task':
      return {
        id: node.id,
        type: 'task',
        data: node,
        position,
      };
    case 'gateway':
      return {
        id: node.id,
        type: 'gateway',
        data: node,
        position,
      };
    default:
      assertExhausted(kind);
      throw new Error('Unknown node kind');
  }
};

const modelEdgeToRendererEdge = (edge: WorkflowEdge): RendererEdge => {
  //waypoints can be obtained with positions.edge(edge.from, edge.to)?.points,
  return {
    id: edge.id,
    source: edge.from,
    target: edge.to,
  };
};

/*
  Get IDs of last edges of a path in the workflow that would make a cycle in the directed graph.
 */

const getLastCycleEdgesForPath = (
  edges: WorkflowEdge[],
  currentPath: string[],
  currentLoopEdges: string[]
): string[] => {
  const lastNodeId = currentPath[currentPath.length - 1];
  const nextEdges = edges.filter((edge) => edge.from === lastNodeId);
  return nextEdges
    .map((edge) => {
      const nextTo = edge.to;
      if (currentPath.includes(nextTo)) {
        return uniq([...currentLoopEdges, edge.id]);
      }
      const nextPath = [...currentPath, nextTo];
      return getLastCycleEdgesForPath(edges, nextPath, currentLoopEdges);
    })
    .flat();
};

export const getLastCycleEdges = (model: WorkflowModel) => {
  const starts = model.nodes.filter((node) => node.kind === 'start');
  const startPaths: string[][] = starts.map((start) => [start.id]);
  const modelLoopEdges = startPaths
    .map((path) => {
      return getLastCycleEdgesForPath(model.edges, path, []);
    })
    .flat();
  return uniq(modelLoopEdges);
};

const getGraphPositions = (model: WorkflowModel) => {
  const loopEdges = getLastCycleEdges(model);

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: 'LR', nodesep: 150, ranksep: 100 });

  model.nodes.forEach((node) => {
    const nodeDimensions = getNodeDimensions(node.kind);
    dagreGraph.setNode(node.id, nodeDimensions);
  });
  model.edges.forEach((edge) => {
    // exclude loop edges from layout calculation
    if (!loopEdges.includes(edge.id)) {
      dagreGraph.setEdge(edge.from, edge.to);
    }
  });

  dagre.layout(dagreGraph);

  return dagreGraph;
};

export const modelToRendererGraph = (model: WorkflowModel): { nodes: RendererNode[]; edges: RendererEdge[] } => {
  const positions = getGraphPositions(model);
  const nodes = model.nodes.map((node) => modelNodeToRendererNode(node, positions));
  const edges = model.edges.map((edge) => modelEdgeToRendererEdge(edge));

  return {
    nodes,
    edges,
  };
};
