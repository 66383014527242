import React from 'react';
import NavigationItem from '../../../../layouts/Main/Sidebar/components/NavigationItem';
import DashboardIcon from '@mui/icons-material/DashboardTwoTone';
import ScienceIcon from '@mui/icons-material/ScienceTwoTone';
import AnalyticsIcon from '@mui/icons-material/AnalyticsTwoTone';
import InterestsIcon from '@mui/icons-material/InterestsTwoTone';
import InsightsIcon from '@mui/icons-material/InsightsOutlined';
import RequestQuoteIcon from '@mui/icons-material/RequestQuoteOutlined';
import NavigationGroup from '../../../../layouts/Main/Sidebar/components/NavigationGroup';
import useCloudPermissions from '../../hooks/useCloudPermissions';
import useCloudFeature from '../../hooks/useCloudFeature';
import { ROUTES } from '@vertice/utils/src/constants/cfa/constants';
import { useTranslation } from 'react-i18next';
import { AWS_BRAND_COLOR } from '../../constants';
import { ReactComponent as NavbarIcon } from './navbarIcon.svg';
import { useMainLayout } from '../../../../layouts/Main/LayoutWrapper';
import { generatePath } from 'react-router-dom';

const {
  CLOUD_OVERVIEW,
  CLOUD_ANALYTICS,
  CLOUD_ANALYTICS_TAB,
  CLOUD_INSIGHTS_BASE,
  CLOUD_EDP,
  CLOUD_INSIGHTS_TAB,
  CLOUD_OPTIMIZATION,
  CLOUD_OPTIMIZATION_RECOMMENDATION,
  CLOUD_OPTIMIZATION_RECOMMENDATION_CODE,
  CLOUD_RIO_TAB,
  CLOUD_RIO_TAB_DETAIL,
} = ROUTES;

const CloudNavbarItems = () => {
  const { t } = useTranslation();
  const cloudFeature = useCloudFeature();
  const cloudPermissions = useCloudPermissions();
  const { isOpen: isNavbarExpanded } = useMainLayout();

  const isCcoGroupVisible =
    !cloudFeature.isLoading &&
    cloudFeature.data.isMainFeatureEnabled &&
    !cloudPermissions.isLoading &&
    cloudPermissions.data.canUserAccessCCO;

  if (!isCcoGroupVisible) {
    return null;
  }

  const isFullVersion =
    !cloudFeature.isLoading &&
    cloudFeature.data.isMainFeatureEnabled &&
    !cloudFeature.data.subFeatures.activeSpendAudit;

  const isRIOEnabled =
    !cloudFeature.isLoading && cloudFeature.data.isMainFeatureEnabled && cloudFeature.data.subFeatures.rio;

  const isAnalyticsEnabled =
    !cloudFeature.isLoading && cloudFeature.data.isMainFeatureEnabled && cloudFeature.data.subFeatures.analytics;

  const isEdpEnabled =
    !cloudFeature.isLoading && cloudFeature.data.isMainFeatureEnabled && cloudFeature.data.subFeatures.activeEDP;

  return (
    <NavigationGroup
      isVisible={isCcoGroupVisible}
      label={isNavbarExpanded ? t('CLOUD.NAVBAR.CLOUD_COST_OPTIMIZATION') : t('CLOUD.NAVBAR.CLOUD')}
      icon={NavbarIcon}
    >
      <NavigationItem
        to={CLOUD_OVERVIEW}
        icon={DashboardIcon}
        label={t(isFullVersion ? 'CLOUD.NAVBAR.OVERVIEW' : 'CLOUD.NAVBAR.SPEND_AUDIT')}
        color={AWS_BRAND_COLOR}
      />
      {isAnalyticsEnabled && (
        <NavigationItem
          to={CLOUD_ANALYTICS}
          match={[CLOUD_ANALYTICS_TAB]}
          icon={AnalyticsIcon}
          label={t('CLOUD.NAVBAR.ANALYTICS')}
          color={AWS_BRAND_COLOR}
        />
      )}
      <NavigationItem
        to={CLOUD_INSIGHTS_BASE}
        match={[CLOUD_INSIGHTS_TAB]}
        icon={ScienceIcon}
        label={t('CLOUD.NAVBAR.INSIGHTS')}
        color={AWS_BRAND_COLOR}
      />
      {isEdpEnabled && (
        <NavigationItem to={CLOUD_EDP} icon={RequestQuoteIcon} label={t('CLOUD.NAVBAR.EDP')} color={AWS_BRAND_COLOR} />
      )}
      <NavigationItem
        to={CLOUD_OPTIMIZATION}
        match={[CLOUD_OPTIMIZATION_RECOMMENDATION, CLOUD_OPTIMIZATION_RECOMMENDATION_CODE]}
        icon={InterestsIcon}
        label={t('CLOUD.NAVBAR.OPTIMIZATION')}
        color={AWS_BRAND_COLOR}
      />
      {isRIOEnabled && (
        <NavigationItem
          to={generatePath(CLOUD_RIO_TAB, { activeTab: 'optimize' })}
          match={[CLOUD_RIO_TAB_DETAIL]}
          icon={InsightsIcon}
          label={t('CLOUD.NAVBAR.RIO.MAIN')}
          color={AWS_BRAND_COLOR}
        />
      )}
    </NavigationGroup>
  );
};

export default CloudNavbarItems;
