import { GridColumnMenuContainer } from '@mui/x-data-grid-pro';
import { styled } from '@mui/material';

const StyledGridColumnMenuContainer = styled(GridColumnMenuContainer)`
  min-width: unset;
  &:global {
    width: 240px;
    color: var(--grayscale-black);

    .MuiMenuItem-root {
      svg {
        fill: var(--grayscale-normal);
      }

      .MuiListItemIcon-root {
        min-width: 26px;
      }

      &:hover {
        color: var(--brand-primary-purple);
        background-color: var(--brand-primary-purple-transparent-01);

        svg {
          fill: var(--brand-primary-purple);
        }
      }
    }
  }
`;

export default StyledGridColumnMenuContainer;
