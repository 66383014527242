import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import {
  AccountUser,
  IntegrationConnectionStatus,
  Oauth2ConnectionStatus,
  SlackUser,
  UserIdentity,
} from '@vertice/slices';
import { INTEGRATION_ROUTES, ROUTES } from '@vertice/utils/src/constants/cfa/constants';
import { WizardStepProps } from '../components/Wizard';

export const SLACK_INTEGRATION_ID = 'Provider:Slack';
export const VERTICE_INTEGRATION_ID = 'Provider:Vertice';
export const SLACK_DATA_SOURCE_CODES = ['SLACK_USER_BASE', 'VERTICE_USER_BASE'];
export const SLACK_ENTITY_TYPE = 'CUSTOMER/USER_SLACK';
export const SLACK_WIZARD_ROUTE = `${ROUTES.PREFERENCES}/${INTEGRATION_ROUTES.BASE_PATH}/${INTEGRATION_ROUTES.SLACK_SETUP}`;
export const INTEGRATIONS_ROUTE = `${ROUTES.PREFERENCES}/${ROUTES.INTEGRATIONS}`;

export const STEPS = {
  START: 'start',
  DATA_FIDELITY: 'data_fidelity',
  SELECT_DATA_SOURCE: 'select_data_source',
  ALLOW_ACCESS: 'allow_access',
  CONNECTION: 'connection',
  CHECK_CONNECTION: 'check_connection',
  CONNECTION_ERROR: 'connection_error',
  CONNECTION_SUCCESS: 'connection_success',
};

export type StepProps = WizardStepProps & {
  accountId: string;
};

type ValidResponse = { data: Oauth2ConnectionStatus | IntegrationConnectionStatus };
type ErrorResponse = { error: FetchBaseQueryError | SerializedError };

export type SlackConnections = AccountUser & { slackUsers: SlackUser[] };

export enum SlackConnectionStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'ADMIN_PENDING',
}

export const SLACK_USER_FIELD = 'slackUser';

export const getConnectedUser = (verticeUserId: string, connectedUsers?: UserIdentity[]) => {
  return connectedUsers?.find((user) => user.userId === verticeUserId);
};

export const isValidResponse = (response: ValidResponse | ErrorResponse): response is ValidResponse => {
  return (response as ValidResponse).data !== undefined;
};
