import React from 'react';
import { Stack } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';

type StatsItemProps = {
  title: string;
  value: number | string;
  color: string;
};

const StatsItem = ({ title, value, color }: StatsItemProps) => {
  return (
    <Stack direction="row" gap={1}>
      <Text variant="body-regular" color={color}>
        {title}
      </Text>
      <Text variant="body-bold" color={color}>
        {value}
      </Text>
    </Stack>
  );
};

export default StatsItem;
