import { useTranslation } from 'react-i18next';
import { useTaskContext } from '../../../TaskContext';
import { Grid, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DocumentsSection } from '../shared/formSections/DocumentsSection';
import { AdditionalNotesFormEntry } from '../shared/formFields/AdditionalNotesFormEntry';
import { TargetSignDateFormEntry } from '../shared/formFields/TargetSignDateFormEntry';
import { FormData } from './schema';
import { CurrencyFormEntry } from '../shared/formFields/CurrencyFormEntry';
import { DesiredContractLengthFormEntry } from '../shared/formFields/DesiredContractLengthFormEntry';
import { MoneyFormEntry } from '../shared/formFields/MoneyFormEntry';
import { useFormContext, useWatch } from 'react-hook-form';
import { SelectedContractPanel } from '../shared/formFields/SelectedContractPanel';
import { useAccountSettings } from '@vertice/core/src/hooks/useAccountSettings';
import { RenewalTypeFormEntry } from '../shared/formFields/RenewalTypeFormEntry';
import { FormProductList } from '../shared/formFields/FormProductList';
import Card from '@verticeone/design-system/src/components/Card';
import { PurchaseRequirements } from '../shared/formSections/PurchaseRequirements';
import { PurchasingManagerPermissions } from '../shared/formSections/PurchasingManagerPermissions';
import { VendorContact } from '../shared/formSections/VendorContact';

export const RenewalRequirementsForm = () => {
  const { t } = useTranslation();
  const { taskOverview } = useTaskContext();
  const requestId = taskOverview?.request?.ref.split('/').pop();
  const [contactVendorDisabled, setContactVendorDisabled] = useState(false);

  const { setValue } = useFormContext<FormData>();

  const contractCurrency = useWatch<FormData, 'contractCurrency'>({ name: 'contractCurrency' });
  const mainVendorContact = useWatch<FormData, 'mainVendorContact'>({ name: 'mainVendorContact' });

  const { data: accountSettings } = useAccountSettings();
  useEffect(() => {
    const defaultCurrency = accountSettings?.preferredCurrency;
    if (defaultCurrency && !contractCurrency) {
      setValue('contractCurrency', defaultCurrency);
    }
  }, [accountSettings, setValue, contractCurrency]);

  useEffect(() => {
    if (!mainVendorContact) {
      setValue('contactVendorDirectly', true);
    }
    setContactVendorDisabled(!mainVendorContact);
  }, [mainVendorContact, setValue]);

  return (
    <Stack gap={6}>
      <SelectedContractPanel />
      <RenewalTypeFormEntry<FormData> name="renewalType" label={t('ENTITIES.CONTRACT.LABELS.RENEWAL_TYPE')} />
      <Card>
        <FormProductList noBorder />
      </Card>
      <Grid container spacing={4} py={4}>
        <TargetSignDateFormEntry width={6} />
        <CurrencyFormEntry<FormData> name="contractCurrency" />
        <MoneyFormEntry<FormData>
          name="approvedBudget"
          label={t('ENTITIES.CONTRACT.LABELS.APPROVED_BUDGET')}
          currency={contractCurrency}
        />
        <DesiredContractLengthFormEntry />
      </Grid>
      <Grid item xs={12}>
        <Stack>
          <PurchaseRequirements />
        </Stack>
      </Grid>
      <AdditionalNotesFormEntry />
      {requestId ? <DocumentsSection /> : null}
      <VendorContact />
      <PurchasingManagerPermissions contactVendorDisabled={contactVendorDisabled} />
    </Stack>
  );
};
