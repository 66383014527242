import { createRequiredContext } from '@verticeone/design-system/src/utils/contexts';
import { EditabilityMode } from './types';
import { GridApiPro, GridRowId, GridRowModes } from '@mui/x-data-grid-pro';
import { MutableRefObject } from 'react';

export type EditableDataGridContextType = {
  mode: EditabilityMode;
  apiRef: MutableRefObject<GridApiPro>;
  setRowMode: (rowId: string, mode: GridRowModes) => void;
  handleRowEditClick: (rowId: GridRowId) => void;
  handleRowSaveClick: (rowId: GridRowId) => void;
  handleRowCancelClick: (rowId: GridRowId) => void;
  handleRowDeleteClick: (rowId: GridRowId) => void;
  isRowEditable?: (row: unknown) => boolean;
  isRowDeletable?: (row: unknown) => boolean;
};

export const { useEditableDataGridContext, EditableDataGridContextProvider } = createRequiredContext<
  EditableDataGridContextType,
  'EditableDataGrid'
>('EditableDataGrid');
