import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import Text from '@verticeone/design-system/src/components/Text';
import Tooltip, { TooltipInfoTrigger } from '@verticeone/design-system/src/components/Tooltip';
import React from 'react';

const TotalLabelCell = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'SAAS.SAVINGS.COMPLETED_CONTRACTS_CARD.FOOTER' });
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Text variant="body-bold">{t('TOTAL')}</Text>
      <Tooltip title={t('TOTAL')} content={t('TOTAL_TOOLTIP')}>
        <TooltipInfoTrigger />
      </Tooltip>
    </Stack>
  );
};

export default TotalLabelCell;
