import { colorPalette } from './colorPalette';
import { transparencyPalette } from './transparencyPalette';
import { gradientPalette } from './gradientPalette';
import { illustrationPalette } from './illustrationPalette';
import { getShadow } from './getShadow';

export const global = {
  color: colorPalette,
  transparency: transparencyPalette,
  gradient: gradientPalette,
  illustration: illustrationPalette,
  getShadow,
};

export type Global = typeof global;
