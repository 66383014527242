import React from 'react';
import { useTranslation } from 'react-i18next';
import useSpendByServiceLineData from './useSpendByServiceLineData';
import SpendByServiceLineGraph from './SpendByServiceLineGraph';
import { LoadableContentWrapper } from '../../components/LoadableContentWrapper';
import { CalendarTodayOutlined } from '@mui/icons-material';
import Card, { CardHeader, CardHeaderSubtitle, CardHeaderTitle } from '@verticeone/design-system/src/components/Card';

const SpendByServiceLineCard = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { data, ...loadingStatus } = useSpendByServiceLineData();
  return (
    <Card sx={{ minHeight: 480, minWidth: 485 }}>
      <CardHeader size="S" noDivider>
        <CardHeaderTitle text={t('SPEND_BY_SERVICE_LINE.TITLE')} tag="h2" />
        <CardHeaderSubtitle text={t('LAST_X_MONTHS', { monthCount: 12 })} icon={CalendarTodayOutlined} />
      </CardHeader>
      <LoadableContentWrapper {...loadingStatus} pb={6}>
        <SpendByServiceLineGraph data={data!} />
      </LoadableContentWrapper>
    </Card>
  );
};

export default SpendByServiceLineCard;
