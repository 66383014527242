import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { FieldPath, FieldValues, Path, PathValue, useController } from 'react-hook-form';
import VendorSelect, { VendorSelectProps } from '../../VendorSelect';
import { identity, isNil } from 'lodash';
import { Vendor } from '../../types';

type FormVendorSelectFieldProps<FormDataType extends FieldValues> = Pick<
  VendorSelectProps,
  'placeholder' | 'color' | 'id' | 'inlineEnabled'
> & {
  name: FieldPath<FormDataType>;
  disabled?: boolean;
  required?: boolean;
  unboxValue?: (
    selectValue: Vendor | null
  ) => PathValue<FormDataType, (string | string[] | undefined) & Path<FormDataType>>;
  boxValue?: (value: PathValue<FormDataType, (string | string[] | undefined) & Path<FormDataType>>) => Vendor | null;
};

export type FormVendorSelectFieldComponentType<FormDataType extends FieldValues> = (
  props: FormVendorSelectFieldProps<FormDataType>
) => JSX.Element;

const FormVendorSelectField = <FormDataType extends FieldValues = never>({
  name,
  required,
  disabled,
  boxValue = identity,
  unboxValue = identity,
  ...otherProps
}: FormVendorSelectFieldProps<FormDataType>) => {
  const {
    field: { ref, value, onChange, onBlur, disabled: formDisabled },
    fieldState: { invalid, error },
  } = useController<FormDataType>({ name, rules: { required } });

  return (
    <VendorSelect
      ref={ref}
      onBlur={onBlur}
      value={boxValue(value)}
      onChange={(newValue) => {
        onChange(!isNil(newValue) ? unboxValue(newValue) : newValue);
      }}
      isDisabled={disabled || formDisabled}
      error={invalid}
      helperText={invalid && error?.message}
      isClearable
      {...otherProps}
    />
  );
};

export default FormVendorSelectField;
