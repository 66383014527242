import { SortOutlined as SortOutlinedIcon } from '@mui/icons-material';
import useTheme from '@mui/material/styles/useTheme';
import IconWrapper from '../../IconWrapper/IconWrapper';
import { useDataGridContext } from '../DataGridContext';

export const DescendingIcon = () => {
  const { palette } = useTheme();
  const { size, color } = useDataGridContext();

  return <IconWrapper icon={SortOutlinedIcon} size={size} htmlColor={palette[color].color1} />;
};
