import { useCallback } from 'react';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';

export const useRequestsNavigation = () => {
  const { generatePath } = useRouteNavigate();
  const routes = useRoutes();

  const generateRequestPath = useCallback(
    (requestId: string, isLegacy?: boolean): string => {
      if (!isLegacy) {
        return generatePath(routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL.ABSOLUTE_PATH, {
          requestId: requestId,
        });
      }

      return generatePath(routes.CONTRACTS.DETAIL.ABSOLUTE_PATH, {
        contractId: requestId,
      });
    },
    [generatePath, routes.CONTRACTS.DETAIL.ABSOLUTE_PATH, routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL.ABSOLUTE_PATH]
  );

  return {
    generateRequestPath,
  };
};
