import React, { FC } from 'react';

import { Stack, styled } from '@mui/material';
import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { Schedule, useScheduleView } from './useScheduleView';
import { generatePath, useNavigate } from 'react-router-dom';
import Text from '@verticeone/design-system/src/components/Text';
import ScheduleItem from './ScheduleItem';

const WeekStack = styled(Stack)<{ $highlighted?: boolean }>(({ $highlighted, theme }) => ({
  flex: 1,
  color: $highlighted ? theme.palette.primary.color1 : theme.palette.text.color4,

  '&:last-child > *:first-child': {
    borderRight: '1px solid',
  },
}));

const DayStack = styled(Stack)<{ $highlighted?: boolean }>(({ $highlighted, theme }) => ({
  flexDirection: 'column-reverse',
  alignItems: 'center',
  height: '100%',
  maxHeight: '270px',
  color: $highlighted ? theme.palette.primary.color1 : theme.palette.text.color4,

  overflow: 'hidden',
  borderRight: 0,
  borderLeft: '1px solid',
  gap: theme.spacing(0.5),
  marginBlockEnd: theme.spacing(0.5),
}));

const DayText = styled(Text)(({ theme }) => ({
  paddingInlineStart: theme.spacing(0.5),
}));

interface ScheduleViewProps {
  contracts: ViewOnContract[] | undefined;
  contractRoute: string;
  getSchedule: (c: ViewOnContract) => Schedule;
}

const ScheduleView: FC<ScheduleViewProps> = ({ contracts, contractRoute, getSchedule }) => {
  const { empty, series } = useScheduleView(contracts, getSchedule);
  const navigate = useNavigate();

  if (empty) {
    return null;
  }

  return (
    <Stack flexDirection="row" paddingTop={10} paddingX={10} minHeight={164}>
      {series.map(({ week, isCurrentWeek, ...categories }) => (
        <WeekStack key={week.toString()} $highlighted={isCurrentWeek}>
          <DayStack $highlighted={isCurrentWeek}>
            {Object.keys(categories).flatMap((item) => {
              const key = item as keyof typeof categories;
              return categories[key].map(({ contract, scheduleDate }) => (
                <ScheduleItem
                  key={contract.record.contractId}
                  scheduleStage={key}
                  scheduleDate={scheduleDate}
                  contract={contract}
                  handleClick={() => navigate(generatePath(contractRoute, { contractId: contract.record.contractId }))}
                />
              ));
            })}
          </DayStack>

          <DayText size="XS" variant="body-regular">
            {week.format('MMM')}
          </DayText>
          <DayText size="XS" variant="body-regular">
            {week.format('D')}
          </DayText>
        </WeekStack>
      ))}
    </Stack>
  );
};

export default ScheduleView;
