import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import Loadable from '@vertice/core/src/utils/async/loadable';
import { FEATURES } from '@vertice/core/src/modules/features/constants';

const FEATURE_MAIN = FEATURES.AWS_COST_INSIGHT;
const FEATURE_RIO = FEATURES.AWS_RIO;
const FEATURE_ANALYTICS = FEATURES.AWS_ANALYTICS;

export enum CloudSubFeature {
  ACTIVE_INTEGRATION = 'activateIntegration',
  ACTIVE_SPEND_AUDIT = 'activeSpendAudit',
  ACTIVE_EDP = 'activeEDP',
  LIMITED_EDP = 'limitedEDP',
  RIO = 'rio',
  ANALYTICS = 'analytics',
  VISIBLE_TESTS = 'visibleTests',
}

export type CloudFeature =
  | { isMainFeatureEnabled: false }
  | {
      isMainFeatureEnabled: true;
      subFeatures: Record<CloudSubFeature, boolean>;
    };

const useCloudFeature = (): Loadable<CloudFeature> => {
  const { getFeature, isEnabled, isLoading } = useFeatures();

  return isLoading
    ? { isLoading: true }
    : {
        isLoading: false,
        data: isEnabled(FEATURE_MAIN)
          ? {
              isMainFeatureEnabled: true,
              subFeatures: {
                activateIntegration: getFeature(FEATURE_MAIN)?.properties?.activateIntegration ?? false,
                activeSpendAudit: getFeature(FEATURE_MAIN)?.properties?.activeSpendAudit ?? true,
                activeEDP: getFeature(FEATURES.AWS_EDP_INSIGHT)?.enabled ?? false,
                limitedEDP: getFeature(FEATURES.AWS_EDP_INSIGHT)?.properties?.limited ?? false,
                visibleTests: getFeature(FEATURE_MAIN)?.properties?.visibleTests ?? '',
                rio: getFeature(FEATURE_RIO)?.enabled ?? false,
                analytics: getFeature(FEATURE_ANALYTICS)?.enabled ?? false,
              },
            }
          : { isMainFeatureEnabled: false },
      };
};

export default useCloudFeature;
