import React, { forwardRef, useMemo } from 'react';
import MuiTabs, { TabsProps as MuiTabsProps, tabsClasses } from '@mui/material/Tabs';
import styled from '@mui/material/styles/styled';
import { CustomizedProps, DesignSystemColor, DesignSystemSize, TestProps } from '../../types';
import { TabsContextProvider } from './TabsContext';
import { testProps } from '../../utils/testProperties';
import { FOCUS_UNDERLINE_THICKNESS } from '../Tab/Tab';
import { tabClasses } from '@mui/material/Tab';
import { useTheme } from '@mui/material';

export type TabsVariant = 'outlined';
export const allVariants: TabsVariant[] = ['outlined'];

export type TabsProps = TestProps &
  CustomizedProps &
  Omit<MuiTabsProps, 'size' | 'variant' | 'color'> & {
    variant?: TabsVariant;
    size?: DesignSystemSize;
    color?: DesignSystemColor;
  };

const StyledTabs = styled(MuiTabs)<{ $color: DesignSystemColor }>(({ $color, theme }) => ({
  minHeight: 0,
  // We need to make space for the focus underline that gets rendered below the tabs but doesn't take any vertical space.
  marginBottom: `-${FOCUS_UNDERLINE_THICKNESS}px`,

  [`.${tabsClasses.flexContainer}`]: {
    gap: '8px',
    paddingBottom: `${FOCUS_UNDERLINE_THICKNESS}px`,
  },

  // Temporary until we remove muiOverrides.scss.
  [`.${tabsClasses.indicator}`]: {
    height: 2,
    backgroundColor: theme.palette[$color].color2,
    bottom: `${FOCUS_UNDERLINE_THICKNESS}px`,
  },

  // During the loading phase, indicator is first rendered inside a single tab before getting moved
  // outside the flexContainer. For this short moment, to avoid invalid placement, we need to set position
  // from bottom to -1.
  [`.${tabClasses.root} .${tabsClasses.indicator}`]: {
    bottom: -1,
  },

  [`.${tabsClasses.scrollButtons}:not(.Mui-disabled)`]: {
    opacity: 1,
  },
}));

const Tabs = forwardRef<HTMLDivElement, TabsProps>(
  ({ variant = 'outlined', size = 'M', color = 'primary', testId, scrollButtons, ...otherProps }, ref) => {
    const { palette } = useTheme();
    const contextValue = useMemo(() => ({ variant, size, color }), [variant, size, color]);
    if (!(variant === 'outlined' && size === 'M')) {
      // eslint-disable-next-line no-console
      console.error('Tabs: The specified configuration is not yet supported:', variant, size);
      return <>Not supported</>;
    }
    return (
      <TabsContextProvider value={contextValue}>
        <StyledTabs
          ref={ref}
          {...testProps(testId, 'tabs')}
          $color={color}
          {...otherProps}
          scrollButtons={scrollButtons}
          variant={scrollButtons ? 'scrollable' : undefined}
          slotProps={{
            startScrollButtonIcon: {
              htmlColor: palette.neutral.color1,
            },
            endScrollButtonIcon: {
              htmlColor: palette.neutral.color1,
            },
          }}
        />
      </TabsContextProvider>
    );
  }
);

export default Tabs;
