import React, { useEffect } from 'react';
import { Navigate, Outlet, RouteObject, generatePath, useRoutes } from 'react-router-dom';
import { Account, accountSlice, useGetAccountQuery, useListFeaturesQuery, useNotifier } from '@vertice/slices';
import useCfaRoutes from './Routes';
import {
  INTEGRATION_ROUTES,
  PREFERENCES_PAGES,
  ROUTES,
  VENDOR_ROUTE_OPTIONS,
} from '@vertice/utils/src/constants/cfa/constants';
import Login from '@vertice/pages/src/Auth/Login/Login';
import Error from '@vertice/pages/src/Auth/Error/Error';
import SignUp from '@vertice/pages/src/Auth/SignUp/SignUp';
import ResetPassword from '@vertice/pages/src/Auth/ResetPassword/ResetPassword';
import SubmitResetPassword from '@vertice/pages/src/Auth/ResetPassword/SubmitResetPassword';
import ForceNewPassword from '@vertice/pages/src/Auth/ForceNewPassword/ForceNewPassword';
import useSmartlook from '@vertice/hooks/src/useSmartlook';
import SSOLogin from '@vertice/pages/src/Auth/SSOLogin/SSOLogin';
import OneLoginSetupWizard from '@vertice/dashboard/src/pages/Preferences/Integrations/OneLogin/OneLoginWizard';
import JumpCloudSetupWizard from '@vertice/dashboard/src/pages/Preferences/Integrations/JumpCloud/JumpCloudWizard';
import CloudOverview from '@vertice/dashboard/src/pages/Cloud/CloudOverview';
import PageNotFound from '@vertice/core/src/modules/notFound/PageNotFound';
import Vendors from '../pages/VendorList/Vendors';
import Vendor from '../pages/VendorDetail/Vendor';
import Dashboard from '../pages/Dashboard/Dashboard';
import Savings from '@vertice/dashboard/src/modules/saas/savings/pages/Savings';
import { Preferences } from '../pages/Preferences/Preferences';
import { KnowledgeBase } from '../pages/KnowledgeBase/KnowledgeBase';
import RequireAuth from './RequireAuth';
import AuthRoutes from './AuthRoutes';
import SamlIntegrationStart from '../pages/SamlIntegration/SamlIntegrationStart';
import SamlIntegration from '../pages/SamlIntegration/SamlIntegration';
import SamlIntegrationCreateApp from '../pages/SamlIntegration/SamlIntegrationCreateApp';
import SamlIntegrationUploadConfig from '../pages/SamlIntegration/SamlIntegrationUploadConfig';
import SamlIntegrationRegisterDomain from '../pages/SamlIntegration/SamlIntegrationRegisterDomain';
import SamlIntegrationTeamMembers from '../pages/SamlIntegration/SamlIntegrationTeamMembers';
import SamlIntegrationRestart from '../pages/SamlIntegration/SamlIntegrationRestart';
import AWSSetupWizard from '../pages/Preferences/Integrations/AWS/AWSSetupWizard/AWSSetupWizard';
import { EntraSetupWizard } from '../pages/Preferences/Integrations/EntraSetup/EntraSetupWizard';
import OktaSetupWizard from '../pages/Preferences/Integrations/OktaSetup/OktaSetupWizard';
import { smartlookConfigs } from '../config';
import MainLayout from '../layouts/Main';
import GoogleSetupWizard from '../pages/Preferences/Integrations/Google/GoogleSetupWizard';
import CloudInsights from '../pages/Cloud/CloudInsights/CloudInsights';
import { AddExistingContractWizard } from '../pages/IntakeFormWizards/AddExistingContractWizard';
import { NewPurchaseWizard } from '../pages/IntakeFormWizards/NewPurchaseWizard';
import { RenewalWizard } from '../pages/IntakeFormWizards/RenewalWizard';
import CloudOptimization from '../pages/Cloud/CloudOptimization';
import CloudRecommendations from '../pages/Cloud/CloudOptimization/Recommendation';
import { SlackSetupWizard } from '../pages/Preferences/Integrations/Slack/V1/SlackSetupWizard';
import { SlackSetupWizardV2 } from 'pages/Preferences/Integrations/Slack/V2/SlackSetupWizardV2';
import SlackIntegrationWizard from '../pages/Preferences/User/SlackIntegration/Wizard/SlackIntegrationWizard';
import { ApplicationsPage } from '@vertice/core/src/modules/applications/Applications/ApplicationsPage';
import Usage from '@vertice/core/src/modules/applications/UsageDetail';
import AccountingSetupWizard from '@vertice/dashboard/src/pages/Preferences/Integrations/Accounting/AccountingSetupWizard';
import CloudRIOLayout from '@vertice/dashboard/src/pages/Cloud/CloudRIOptimiser/components/CloudRIOLayout';
import Contract from '../pages/Contract/Contract';
import ConditionalPage from '../layouts/Main/ConditionalPage';
import { Contracts } from '../pages/Contracts/Contracts';
import ContractListFilterContextWrapper from '@vertice/core/src/modules/saas/contract/contexts/ContractListFilterContext';
import { SCIMSetupWizard } from 'pages/Preferences/Integrations/SCIM/SCIMSetupWizard';
import CloudTransactionsTab from '../pages/Cloud/CloudRIOptimiser/tabs/CloudTransactionsTab';
import CloudInventoryTab from '../pages/Cloud/CloudRIOptimiser/tabs/CloudInventoryTab/CloudInventoryTab';
import CloudOptimizeTab from '../pages/Cloud/CloudRIOptimiser/tabs/CloudOptimizeTab';
import CloudRioInstanceTypes from '../pages/Cloud/CloudRIOptimiser/tabs/CloudOptimizeTab/instances/CloudRioInstanceTypes';
import ZendeskSSOLogin from '../pages/Zendesk/ZendeskSSOLogin';
import ZendeskSSOLogout from '../pages/Zendesk/ZendeskSSOLogout';
import { CfaAccountContextProvider } from '../layouts/Main/CfaAccountContext';
import AssumeRole from '../pages/AssumeRole/AssumeRole';
import { useAuthentication } from '@verticeone/auth/src';
import type AuthUser from '@verticeone/auth/src/authentication/AuthUser';
import { useDispatch } from 'react-redux';
import {
  RequestsListPage,
  RequestDetailPage,
  WorkflowsListPage,
  WorkflowDetailPage,
  WorkflowEditorPage,
} from '../modules/intelligentWorkflows';
import CloudAnalytics from '../pages/Cloud/CloudAnalytics';
import VendorListFilterContextWrapper from '../pages/VendorList/VendorListFilterContext';
import UsageContextWrapper from '@vertice/core/src/modules/applications/Applications/UsageContext';
import { TasksListPage } from '../modules/intelligentWorkflows/task/TasksListPage/TasksListPage';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import CloudInsightsEDP from '../pages/Cloud/CloudInsightsEDP';

const {
  ROOT,
  INTAKE_EXISTING_CONTRACT,
  SAML,
  SAML_CREATE_APP,
  SAML_UPLOAD_CONFIG,
  SAML_SSO_DOMAIN,
  SAML_TEAM_MEMBERS,
  SAML_RESTART,
  SCIM,
  AWS,
  KNOWLEDGE_BASE,
  LOGIN,
  SSO_LOGIN,
  SIGN_UP,
  ERROR,
  RESET_PASSWORD,
  SUBMIT_RESET_PASSWORD,
  FORCE_NEW_PASSWORD,
  ZENDESK_SSO_LOGIN,
  ZENDESK_SSO_LOGOUT,
} = ROUTES;

const CreateRouter = () => {
  const routes = useCfaRoutes();
  const { isEnabled } = useFeatures();

  const privateRouter: RouteObject[] = [
    {
      path: ROOT,
      element: <Navigate to={routes.DASHBOARD.ABSOLUTE_PATH} />,
    },
    {
      path: ZENDESK_SSO_LOGIN,
      element: (
        <CfaAccountContextProvider>
          <ZendeskSSOLogin />
        </CfaAccountContextProvider>
      ),
    },
    {
      path: '',
      element: (
        <ContractListFilterContextWrapper>
          <MainLayout />
        </ContractListFilterContextWrapper>
      ),
      children: [
        {
          path: routes.DASHBOARD.PATH,
          children: [
            {
              path: '',
              element: <Dashboard />,
            },
            {
              path: routes.DASHBOARD.SAVINGS.PATH,
              element: <Savings />,
            },
          ],
        },
        {
          path: routes.APPLICATIONS.PATH,
          element: (
            <UsageContextWrapper>
              <Outlet />
            </UsageContextWrapper>
          ),
          children: [
            {
              path: '',
              element: <ApplicationsPage />,
            },
            {
              path: routes.APPLICATIONS.USAGE_DETAIL.PATH,
              element: <Usage />,
            },
          ],
        },
        {
          path: routes.VENDORS.PATH,
          element: (
            <VendorListFilterContextWrapper>
              <Outlet />
            </VendorListFilterContextWrapper>
          ),
          children: [
            {
              path: '',
              element: <Vendors />,
            },
            {
              path: routes.VENDORS.VENDOR.PATH,
              children: [
                {
                  path: '',
                  element: <Vendor />,
                },
                {
                  path: VENDOR_ROUTE_OPTIONS.NEW_PURCHASE,
                  element: <NewPurchaseWizard />,
                },
                {
                  path: VENDOR_ROUTE_OPTIONS.ADD_EXISTING_CONTRACT,
                  element: <AddExistingContractWizard />,
                },
              ],
            },
          ],
        },
        {
          path: routes.CONTRACTS.PATH,
          children: [
            {
              path: '',
              element: <Contracts />,
            },
            {
              path: routes.CONTRACTS.DETAIL.PATH,
              children: [
                {
                  path: '',
                  element: <Contract />,
                },
                {
                  path: routes.CONTRACTS.DETAIL.RENEWAL_INTAKE.PATH,
                  element: <RenewalWizard />,
                },
              ],
            },
          ],
        },
        {
          path: INTAKE_EXISTING_CONTRACT,
          element: <AddExistingContractWizard />,
        },
        {
          path: routes.PREFERENCES.PATH,
          children: [
            {
              path: INTEGRATION_ROUTES.BASE_PATH,
              children: [
                {
                  path: '',
                  element: <Preferences page={PREFERENCES_PAGES.INTEGRATIONS} />,
                },
                {
                  path: INTEGRATION_ROUTES.OKTA_SETUP,
                  element: <OktaSetupWizard />,
                },
                {
                  path: INTEGRATION_ROUTES.ACCOUNTING,
                  element: <AccountingSetupWizard />,
                },
                {
                  path: INTEGRATION_ROUTES.ENTRA_SETUP,
                  element: <EntraSetupWizard />,
                },
                {
                  path: INTEGRATION_ROUTES.ONELOGIN_SETUP,
                  element: <OneLoginSetupWizard />,
                },
                {
                  path: INTEGRATION_ROUTES.JUMPCLOUD_SETUP,
                  element: <JumpCloudSetupWizard />,
                },
                {
                  path: INTEGRATION_ROUTES.GOOGLE_SETUP,
                  element: <GoogleSetupWizard />,
                },
                {
                  path: INTEGRATION_ROUTES.SLACK_SETUP,
                  element: isEnabled(FEATURES.INTEGRATION_SLACK_V2) ? <SlackSetupWizardV2 /> : <SlackSetupWizard />,
                },
                {
                  path: AWS,
                  element: <AWSSetupWizard />,
                },
              ],
            },
            {
              path: PREFERENCES_PAGES.COMPANY,
              element: <Preferences page={PREFERENCES_PAGES.COMPANY} />,
            },
            {
              path: PREFERENCES_PAGES.USER,
              element: <Preferences page={PREFERENCES_PAGES.USER} />,
            },
            {
              path: PREFERENCES_PAGES.USER_SLACK_WIZARD,
              element: <SlackIntegrationWizard />,
            },
            {
              path: PREFERENCES_PAGES.DIRECTORY,
              element: <Preferences page={PREFERENCES_PAGES.DIRECTORY} />,
            },
            {
              path: PREFERENCES_PAGES.CUSTOMIZATION,
              element: <Preferences page={PREFERENCES_PAGES.CUSTOMIZATION} />,
            },
            {
              path: PREFERENCES_PAGES.WORKFLOWS,
              element: <Preferences page={PREFERENCES_PAGES.WORKFLOWS} />,
            },
            {
              path: '',
              element: (
                <Navigate to={generatePath(routes.PREFERENCES.TAB.ABSOLUTE_PATH, { tab: PREFERENCES_PAGES.COMPANY })} />
              ),
            },
          ],
        },
        {
          path: SAML,
          element: <SamlIntegration />,
          children: [
            {
              path: '',
              element: <SamlIntegrationStart />,
            },
            {
              path: SAML_CREATE_APP,
              element: <SamlIntegrationCreateApp />,
            },
            {
              path: SAML_UPLOAD_CONFIG,
              element: <SamlIntegrationUploadConfig />,
            },
            {
              path: SAML_SSO_DOMAIN,
              element: <SamlIntegrationRegisterDomain />,
            },
            {
              path: SAML_TEAM_MEMBERS,
              element: <SamlIntegrationTeamMembers />,
            },
            {
              path: SAML_RESTART,
              element: <SamlIntegrationRestart />,
            },
          ],
        },
        {
          path: SCIM,
          element: <SCIMSetupWizard />,
        },
        {
          path: KNOWLEDGE_BASE,
          element: <KnowledgeBase />,
        },
        {
          path: routes.CLOUD.PATH,
          children: [
            { path: routes.CLOUD.OVERVIEW.PATH, element: <CloudOverview /> },
            {
              path: routes.CLOUD.ANALYTICS.PATH,
              children: [
                {
                  path: '',
                  element: (
                    <ConditionalPage isAccessible={({ isFeatureEnabled }) => isFeatureEnabled('AWS_ANALYTICS')}>
                      <CloudAnalytics />
                    </ConditionalPage>
                  ),
                },
                {
                  path: routes.CLOUD.ANALYTICS.TAB.PATH,
                  element: (
                    <ConditionalPage isAccessible={({ isFeatureEnabled }) => isFeatureEnabled('AWS_ANALYTICS')}>
                      <CloudAnalytics />
                    </ConditionalPage>
                  ),
                },
              ],
            },
            {
              path: routes.CLOUD.INSIGHTS.PATH,
              children: [
                { path: '', element: <CloudInsights /> },
                { path: routes.CLOUD.INSIGHTS.TAB.PATH, element: <CloudInsights /> },
              ],
            },
            {
              path: routes.CLOUD.EDP.PATH,
              children: [{ path: '', element: <CloudInsightsEDP /> }],
            },
            {
              path: routes.CLOUD.OPTIMIZATION.PATH,
              children: [
                { path: '', element: <CloudOptimization /> },
                {
                  path: routes.CLOUD.OPTIMIZATION.RECOMMENDATION.PATH,
                  children: [
                    { path: '', element: <CloudRecommendations /> },
                    { path: routes.CLOUD.OPTIMIZATION.RECOMMENDATION.CODE.PATH, element: <CloudRecommendations /> },
                  ],
                },
              ],
            },
            {
              path: routes.CLOUD.RIO.PATH,
              children: [
                { path: '', element: <Navigate to="/optimize" replace={true} relative="route" /> },
                {
                  path: routes.CLOUD.RIO.OPTIMIZE.PATH,
                  element: <CloudRIOLayout activeTab="optimize" />,
                  children: [
                    { path: '', element: <CloudOptimizeTab /> },
                    { path: routes.CLOUD.RIO.OPTIMIZE.DETAIL.PATH, element: <CloudRioInstanceTypes /> },
                  ],
                },
                {
                  path: routes.CLOUD.RIO.INVENTORY.PATH,
                  element: <CloudRIOLayout activeTab="inventory" />,
                  children: [
                    { path: '', element: <CloudInventoryTab /> },
                    { path: routes.CLOUD.RIO.INVENTORY.DETAIL.PATH, element: <CloudInventoryTab /> },
                  ],
                },
                {
                  path: routes.CLOUD.RIO.TRANSACTIONS.PATH,
                  element: <CloudRIOLayout activeTab="transactions" />,
                  children: [{ path: '', element: <CloudTransactionsTab /> }],
                },
              ],
            },
          ],
        },
        {
          path: routes.INTELLIGENT_WORKFLOWS.PATH,
          element: (
            <ConditionalPage isAccessible={({ isFeatureEnabled }) => isFeatureEnabled('INTELLIGENT_WORKFLOWS')}>
              <Outlet />
            </ConditionalPage>
          ),
          children: [
            {
              path: routes.INTELLIGENT_WORKFLOWS.TASKS.PATH,
              element: <TasksListPage />,
            },
            {
              path: routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.PATH,
              children: [
                {
                  path: '',
                  element: <WorkflowsListPage />,
                },
                {
                  path: routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.DETAIL.PATH,
                  children: [
                    {
                      path: '',
                      element: <WorkflowDetailPage />,
                    },
                    {
                      path: routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.DETAIL.VERSION.PATH,
                      children: [
                        {
                          path: routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.DETAIL.VERSION.EDITOR.PATH,
                          element: <WorkflowEditorPage />,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: routes.INTELLIGENT_WORKFLOWS.REQUESTS.PATH,
              children: [
                {
                  path: '',
                  element: <RequestsListPage />,
                },
                {
                  path: routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL.PATH,
                  element: <RequestDetailPage />,
                },
              ],
            },
            {
              path: '',
              element: <PageNotFound />,
            },
          ],
        },
      ],
    },
    {
      path: routes.PAGE_NOT_FOUND.PATH,
      element: <PageNotFound verticalFill="window" />,
    },
    {
      path: '*',
      element: <PageNotFound verticalFill="window" />,
    },
  ];

  const authRouter = [
    {
      path: SIGN_UP,
      element: <SignUp />,
    },
    {
      path: LOGIN,
      element: <Login />,
    },
    {
      path: ERROR,
      element: <Error />,
    },
    {
      path: SSO_LOGIN,
      element: <SSOLogin />,
    },
    {
      path: RESET_PASSWORD,
      element: <ResetPassword />,
    },
    {
      path: SUBMIT_RESET_PASSWORD,
      element: <SubmitResetPassword />,
    },
    {
      path: FORCE_NEW_PASSWORD,
      element: <ForceNewPassword />,
    },
  ];

  const publicRouter = [
    {
      path: routes.OTP.ASSUME_ROLE.ABSOLUTE_PATH,
      element: <AssumeRole />,
    },
    {
      path: ZENDESK_SSO_LOGOUT,
      element: <ZendeskSSOLogout />,
    },
  ];

  const router: RouteObject[] = [
    {
      path: '',
      element: <RequireAuth />,
      children: privateRouter,
    },
    {
      path: '',
      element: <AuthRoutes />,
      children: authRouter,
    },
    ...publicRouter,
  ];

  return router;
};

/** Syncs account of the signed-in user to Redux so that it is available via the legacy `useSelector(getAccount)`. */
const useSyncAccountToLegacyRedux = (user?: AuthUser, accountId?: string) => {
  const dispatch = useDispatch();
  const { data: account } = useGetAccountQuery({ accountId: accountId! }, { skip: !accountId });

  useEffect(() => {
    dispatch(
      user && account ? accountSlice.actions.setAccount(account as Account) : accountSlice.actions.unsetAccount()
    );
  }, [user, account, dispatch]);
};

const Router = () => {
  const { user } = useAuthentication();

  useNotifier();
  useSmartlook({ enable: smartlookConfigs.enable, projectKey: smartlookConfigs.projectKey });

  const accountId = user?.accountId;

  useSyncAccountToLegacyRedux(user, accountId);
  useListFeaturesQuery({ accountId: accountId! }, { skip: !accountId });

  return useRoutes(CreateRouter());
};

export default Router;
