import { vendorApi as api } from '../../api/vendorAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    listVendors: build.query<ListVendorsApiResponse, ListVendorsApiArg>({
      query: (queryArg) => ({
        url: `/vendors`,
        params: {
          sort_order: queryArg.sortOrder,
          limit: queryArg.limit,
          offset: queryArg.offset,
          query: queryArg.query,
          filters: queryArg.filters,
          restrict: queryArg.restrict,
        },
      }),
    }),
    createVendor: build.mutation<CreateVendorApiResponse, CreateVendorApiArg>({
      query: (queryArg) => ({ url: `/vendors`, method: 'POST', body: queryArg.vendor }),
    }),
    listVendorCategories: build.query<ListVendorCategoriesApiResponse, ListVendorCategoriesApiArg>({
      query: () => ({ url: `/vendors/categories` }),
    }),
    getVendorById: build.query<GetVendorByIdApiResponse, GetVendorByIdApiArg>({
      query: (queryArg) => ({ url: `/vendors/${queryArg.vendorId}` }),
    }),
    listVendorProducts: build.query<ListVendorProductsApiResponse, ListVendorProductsApiArg>({
      query: (queryArg) => ({
        url: `/vendors/${queryArg.vendorId}/products`,
        params: { limit: queryArg.limit, offset: queryArg.offset },
      }),
    }),
    addProductToVendor: build.mutation<AddProductToVendorApiResponse, AddProductToVendorApiArg>({
      query: (queryArg) => ({ url: `/vendors/${queryArg.vendorId}/products`, method: 'POST', body: queryArg.product }),
    }),
    getVendorLegalInfo: build.query<GetVendorLegalInfoApiResponse, GetVendorLegalInfoApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/vendors/${queryArg.vendorId}/legal` }),
    }),
    requestVendorLegalInfo: build.mutation<RequestVendorLegalInfoApiResponse, RequestVendorLegalInfoApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/vendors/${queryArg.vendorId}/legal`,
        method: 'POST',
      }),
    }),
    getProductById: build.query<GetProductByIdApiResponse, GetProductByIdApiArg>({
      query: (queryArg) => ({ url: `/vendors/${queryArg.vendorId}/products/${queryArg.productId}` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as vendorAPICodegen };
export type ListVendorsApiResponse = /** status 200 List of vendors resource
 */ Vendor[];
export type ListVendorsApiArg = {
  sortOrder?: 'asc' | 'desc';
  limit?: number;
  offset?: number;
  query?: string;
  /** Filter contracts by value in specified fields.
    Supported fields: vendorId, category
    Maximal number of filters is 25, if client needs to filter more than 25 fields, then it must use
    multiple requests to get all results.
     */
  filters?: string[];
  restrict?: 'VENDORS' | 'PRODUCTS' | 'VENDORS_PRODUCTS';
};
export type CreateVendorApiResponse = /** status 200 Vendor resource
 */ Vendor;
export type CreateVendorApiArg = {
  /** Vendor resource
   */
  vendor: Vendor;
};
export type ListVendorCategoriesApiResponse = /** status 200 List of vendors categories
 */ {
  items?: string[];
};
export type ListVendorCategoriesApiArg = void;
export type GetVendorByIdApiResponse = /** status 200 Vendor resource
 */ Vendor;
export type GetVendorByIdApiArg = {
  vendorId: Identifier;
};
export type ListVendorProductsApiResponse = /** status 200 List of products
 */ ProductsList;
export type ListVendorProductsApiArg = {
  vendorId: Identifier;
  limit?: number;
  offset?: number;
};
export type AddProductToVendorApiResponse = /** status 200 Product resource
 */ Product;
export type AddProductToVendorApiArg = {
  vendorId: Identifier;
  /** Product resource
   */
  product: Product;
};
export type GetVendorLegalInfoApiResponse = /** status 200 Vendor legal info
 */ VendorLegalInfo;
export type GetVendorLegalInfoApiArg = {
  accountId: Identifier;
  vendorId: Identifier;
};
export type RequestVendorLegalInfoApiResponse = /** status 200 Request insight
 */ undefined;
export type RequestVendorLegalInfoApiArg = {
  accountId: Identifier;
  vendorId: Identifier;
};
export type GetProductByIdApiResponse = /** status 200 Product resource
 */ Product;
export type GetProductByIdApiArg = {
  vendorId: Identifier;
  productId: Identifier;
};
export type Identifier = string;
export type Url = string | null;
export type Version = number;
export type PricingScore = number;
export type Vendor = {
  id?: Identifier;
  name?: string;
  category?: string | null;
  logo?: Url;
  hq?: string | null;
  website?: Url;
  linkedIn?: Url;
  twitter?: Url;
  numberOfEmployees?: number | null;
  description?: string | null;
  yearFounded?: number | null;
  version?: Version;
  hasContract?: boolean;
  simplicityRating?: PricingScore;
  transparencyRating?: PricingScore;
  parityRating?: PricingScore;
  rank?: number;
  products?: {
    name?: string;
  }[];
};
export type Product = {
  id?: Identifier;
  name?: string;
  description?: string;
  version?: Version;
};
export type ProductsList = {
  vendor?: Vendor;
  products?: Product[];
  totalCount?: number;
};
export type VendorLegalInfo = {
  securityUrl?: string;
  gdprUrl?: string;
  msaUrl?: string;
  msaAgreementUrl?: string;
  complianceUrl?: string;
  dpaUrl?: string;
  statusUrl?: string;
  privacyUrl?: string;
  subprocessorsUrl?: string;
  iso27001Url?: string;
  soc2Url?: string;
  soc3Url?: string;
  feesRefundable?: string;
  feesRefundableClause?: string;
  paymentTerms?: string;
  paymentTermsClause?: string;
  liability?: string;
  liabilityClause?: string;
  liabilityLimits?: string;
  liabilityLimitsClause?: string;
  warranties?: string;
  warrantiesClause?: string;
  autoRenewal?: string;
  autoRenewalClause?: string;
  noticePeriod?: string;
  noticePeriodClause?: string;
  usageLimits?: string;
  usageLimitsClause?: string;
  serviceAgreement?: string;
  serviceAgreementClause?: string;
  marketingPermit?: string;
  marketingPermitClause?: string;
  governingLaw?: string;
  governingLawClause?: string;
  otherConsiderations?: string;
};
export const {
  useListVendorsQuery,
  useLazyListVendorsQuery,
  useCreateVendorMutation,
  useListVendorCategoriesQuery,
  useLazyListVendorCategoriesQuery,
  useGetVendorByIdQuery,
  useLazyGetVendorByIdQuery,
  useListVendorProductsQuery,
  useLazyListVendorProductsQuery,
  useAddProductToVendorMutation,
  useGetVendorLegalInfoQuery,
  useLazyGetVendorLegalInfoQuery,
  useRequestVendorLegalInfoMutation,
  useGetProductByIdQuery,
  useLazyGetProductByIdQuery,
} = injectedRtkApi;
