import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadableContentWrapper } from '../../components/LoadableContentWrapper';
import useEC2OSUsageData from './useEC2OSUsageData';
import EC2OSUsageGraph from './EC2OSUsageGraph';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import Card, { CardHeader, CardHeaderSubtitle, CardHeaderTitle } from '@verticeone/design-system/src/components/Card';
import { CalendarTodayOutlined } from '@mui/icons-material';

type EC2OSUsageCardProps = {
  sx?: SxProps<Theme>;
};

const EC2OSUsageCard = (props: EC2OSUsageCardProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { data, ...loadingStatus } = useEC2OSUsageData();

  return (
    <Card {...props}>
      <CardHeader size="S" noDivider>
        <CardHeaderTitle text={t('EC2_OS_USAGE.TITLE')} tag="h2" />
        <CardHeaderSubtitle text={t('LAST_MONTH')} icon={CalendarTodayOutlined} />
      </CardHeader>
      <LoadableContentWrapper {...loadingStatus} pb={6}>
        <EC2OSUsageGraph points={data!} />
      </LoadableContentWrapper>
    </Card>
  );
};

export default EC2OSUsageCard;
