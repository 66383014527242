import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { InvitationStatus, useInvitationStatusQuery } from '@vertice/slices';
import { ROUTES } from '@vertice/utils/src/constants/cfa/constants';
import { parseBase64EncodedToken } from '@vertice/utils';
import { Invitation } from '@vertice/pages/src/Auth/models/invitation';
import { NotificationSeverity } from '@vertice/slices/src/slices/Notifications/types';

type TokenError = {
  message?: {
    type: NotificationSeverity;
    textKey: string;
  };
  redirect?: string;
};

const useStatusError: (...props: any) => Record<InvitationStatus, TokenError | null> = (isSSO, userEmail) => {
  const { t } = useTranslation();

  return {
    VALID: null,
    EXPIRED: {
      message: {
        type: 'error',
        textKey: t('AUTH.INVITATION_STATUS.EXPIRED'),
      },
    },
    ACCEPTED: {
      message: {
        type: 'info',
        textKey: t('AUTH.INVITATION_STATUS.ACCEPTED'),
      },
      redirect: `${isSSO ? ROUTES.SSO_LOGIN : ROUTES.LOGIN}?userEmail=${userEmail}`,
    },
  };
};

const GENERIC_ERROR: TokenError = {
  redirect: ROUTES.ERROR,
} as const;

export const useInvitationToken = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { data, isLoading, error } = useInvitationStatusQuery({ token: token! }, { skip: !token });
  const userEmail = parseBase64EncodedToken<Invitation>(searchParams.get('token'))?.userEmail ?? '';
  const statusError = useStatusError(location.pathname === ROUTES.SSO_LOGIN, userEmail);

  const tokenError = useMemo(() => {
    if (error) {
      return GENERIC_ERROR;
    }
    if (Boolean(token) && data?.status) {
      return statusError[data.status];
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, error, data]);

  return {
    hasToken: Boolean(token),
    isLoading,
    tokenError,
  };
};
