import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import Highcharts, { Chart as HighchartsChart } from 'highcharts';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { useGetColoredItems } from '@vertice/core/src/components/charts/highcharts-specific/utils/seriesUtils';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import { ChartPortal, fromChartPortal } from '@vertice/core/src/components/charts/components/ChartPortal';
import { Stack } from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import Text from '@verticeone/design-system/src/components/Text';
import { sumBy } from 'lodash';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';

type EC2OSUsageGraphProps = { points: { name: string; y: number }[] };

const graphId = 'ec2OSUsageGraph';

const extractLegendItems = (chart: HighchartsChart) =>
  chart.series[0].points.map(({ name, y, color }) => ({ id: name, color: color as string, label: name }));

const EC2OSUsageGraph = ({ points }: EC2OSUsageGraphProps) => {
  const [chart, setChart] = useState<HighchartsChart | null>(null);
  const saveChartRef = useCallback((ch: HighchartsChart) => setChart(ch), []);
  const applyStyledHighcharts = useStyledHighcharts();
  const getColoredSeries = useGetColoredItems();
  const [, rerender] = useState({});
  const totalPositive = useMemo(() => sumBy(points, ({ y }) => y), [points]);

  const options = useMemo(() => {
    return buildOptions([
      applyStyledHighcharts,
      mergeOptions({
        chart: {
          type: 'pie',
          plotBackgroundColor: 'transparent',
          backgroundColor: 'transparent',
          plotBorderWidth: 0,
          height: 366,
          spacing: [24, 0, 24, 0],
        },
        plotOptions: {
          pie: { innerSize: '80%' },
          series: {
            dataLabels: {
              formatter: function () {
                return fromChartPortal({ chart, portalKey: `${(this as any).key}-${graphId}` });
              },
            },
          },
        },
        tooltip: { enabled: false },
        series: [
          {
            type: 'pie',
            data: getColoredSeries(points) ?? [],
            dataLabels: {
              enabled: true,
              crop: false,
              y: -10,
              useHTML: true,
              connectorPadding: 5,
              distance: 40,
              padding: 5,
              style: {
                textAlign: 'center',
              },
            },
          },
        ],
      }),
    ]);
  }, [applyStyledHighcharts, getColoredSeries, points, chart]);

  useLayoutEffect(() => {
    // Wait for initial Highchart render (possibly with empty div portal targets)
    if (chart) {
      // Force React render portals into Highchart
      rerender({});
      // Wait for React render to finish
      requestAnimationFrame(() => {
        // Position the React-rendered elements
        chart.series.forEach((ser) => (ser as any).redraw());
      });
    }
  }, [chart, options]);

  return (
    <Stack>
      <HighchartsReact highcharts={Highcharts} options={options} callback={saveChartRef} />
      <Legend items={chart ? extractLegendItems(chart) : []} />
      {totalPositive
        ? points?.map((point) => (
            <ChartPortal chart={chart} portalKey={`${point.name}-${graphId}`} key={`${point.name}-${graphId}`}>
              <Stack direction="column" alignItems="center">
                <Text variant="body-bold" size="S" color="text2">
                  {point.name}
                </Text>
                <Text variant="heading" size="XS" color="text1">
                  {Math.round((point.y / totalPositive) * 100)}%
                </Text>
              </Stack>
            </ChartPortal>
          ))
        : null}
    </Stack>
  );
};

export default EC2OSUsageGraph;
