import React, { PropsWithChildren, forwardRef } from 'react';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import { gradientBorder } from '../../../utils/css/gradientBorder';
import { ifExpression } from '../../../utils/ifExpression';
import { type StyledComponentProps, type TextFieldAreaProps, areaPadding, labelContentGap } from './constants';
import Label, { StyledLabel, getHoverState } from './Label';

const StyledSolidWrapper = styled('div')<StyledComponentProps>(({ theme, $size, $color, $disabled, $hasError }) => ({
  backgroundColor: $disabled ? theme.palette.inactive.color4 : theme.palette.core.bg,
  padding: '0 5px 5px 0',
  '.textarea': {
    backgroundColor: 'transparent',
    paddingLeft: `${areaPadding[$size] * 2}px`,
    paddingRight: `calc(${areaPadding[$size] * 2}px - 5px)`,
    paddingBottom: `calc(${areaPadding[$size]}px - 5px)`,
    borderRadius: theme.spacing(2),
    border: 'none',
    outline: 'none',
  },
  ...gradientBorder({
    color: theme.palette.gradient.transparentToBlack10,
    radius: theme.spacing(2),
    width: '1px',
  }),
  '&:hover, &:focus-within': ifExpression(!$disabled && !$hasError, {
    backgroundColor: theme.palette.input.color1,
    [StyledLabel]: getHoverState({ theme, $color, $disabled }),
  }),
  ...ifExpression($hasError && !$disabled, {
    '&::before': {
      display: 'none',
    },
    border: `1px solid ${theme.palette.error.color2}`,
    [StyledLabel]: getHoverState({ theme, $color: 'error', $disabled }),
  }),
  '&:focus-within': {
    border: `1px solid ${theme.palette[$hasError ? 'error' : $color].color2}`,
    outline: `4px solid ${theme.palette[$hasError ? 'error' : $color].color4}`,
    '&::before': {
      display: 'none',
    },
  },
}));

const SolidWrapper = forwardRef<HTMLDivElement, PropsWithChildren<TextFieldAreaProps>>((props, ref) => (
  <StyledSolidWrapper
    $size={props.size}
    $color={props.color}
    $disabled={!!props.disabled}
    $hasError={!!props.hasError}
    $hasLabel={!!props.label}
  >
    <Stack ref={ref} gap={labelContentGap[props.size]}>
      <Stack pt={`${areaPadding[props.size]}px`} px={`${areaPadding[props.size] * 2}px`}>
        <Label {...props} />
      </Stack>
      {props.children}
    </Stack>
  </StyledSolidWrapper>
));

export default SolidWrapper;
