import React, { FC } from 'react';
import UserListBase, { UserListProps as UserListPropsBase } from './UserList';
import WithActions, { ForbiddenDeletionProps } from './WithActions';
import WithAddingContact from './WithAddingContact';

export type { UpdatedItem, columnName } from './UserList';

interface UserListProps extends UserListPropsBase {
  withActions?: boolean;
  withAddingContact?: boolean;
  checkAvailableDeletion?: (value: string) => Promise<ForbiddenDeletionProps>;
  addLabel?: string;
}

export const UserList: FC<UserListProps> = ({
  withActions,
  withAddingContact,
  checkAvailableDeletion,
  addLabel,
  ...rest
}) => {
  if (withActions && withAddingContact) {
    return (
      <WithAddingContact {...{ addLabel }}>
        <WithActions {...{ checkAvailableDeletion }}>
          <UserListBase {...rest} />
        </WithActions>
      </WithAddingContact>
    );
  }

  if (withAddingContact) {
    return (
      <WithAddingContact {...{ addLabel }}>
        <UserListBase {...rest} />
      </WithAddingContact>
    );
  }

  if (withActions) {
    return (
      <WithActions {...{ checkAvailableDeletion }}>
        <UserListBase {...rest} />
      </WithActions>
    );
  }

  return <UserListBase {...rest} />;
};
