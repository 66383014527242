import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { Box, StackProps } from '@mui/material';
import { ContractOrigin, SimplifiedStage } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { ReactComponent as VerticeV } from './vertice_v.svg';
import { EllipsisText } from '@verticeone/design-system/src/components/Text';

export enum AdditionalInfoFlag {
  PLANNED_FOR_EXPIRATION = 'plannedForExpiration',
  RENEWAL_COMPLETED = 'renewalCompleted',
  RENEWAL_IN_PROGRESS = 'renewalInProgress',
}

type AdditionalDetailsProps = StackProps & {
  nextContract?: {
    simplifiedStage?: SimplifiedStage;
    contractOrigin?: ContractOrigin;
  } | null;
  isPlannedForExpiration: boolean;
  /**
   * You can control the visibility of additional info flags by listing them in the flags array.
   * If not specified, all flags are visible.
   */
  flags?: AdditionalInfoFlag[];
};

const AdditionalDetails = ({ nextContract, isPlannedForExpiration, flags }: AdditionalDetailsProps) => {
  const { t } = useTranslation();

  const visibility = {
    inProgress: !flags || flags.includes(AdditionalInfoFlag.RENEWAL_IN_PROGRESS),
    completed: !flags || flags.includes(AdditionalInfoFlag.RENEWAL_COMPLETED),
    plannedExpiration: !flags || flags.includes(AdditionalInfoFlag.PLANNED_FOR_EXPIRATION),
  };

  if (nextContract && nextContract.simplifiedStage) {
    if (nextContract.simplifiedStage === 'WORKFLOW_IN_PROGRESS') {
      return visibility.inProgress ? (
        <Stack direction="row" gap={1} alignItems="center">
          <Box flexShrink={0}>
            <VerticeV height={14} />
          </Box>
          <EllipsisText variant="body-bold" color="primary1" size="S">
            {t('CONTRACT.CFA.RENEWAL_IN_PROGRESS')}
          </EllipsisText>
        </Stack>
      ) : null;
    }

    if (
      Array<SimplifiedStage>(
        'LIVE',
        'NOT_YET_LIVE',
        'EXPIRED_NATURAL',
        'EXPIRED_PLANNED',
        'EXPIRED_UNINTENTIONAL',
        'DRAFT',
        'TERMINATED'
      ).includes(nextContract.simplifiedStage)
    ) {
      return visibility.completed ? (
        nextContract.contractOrigin === 'RENEWAL_WITH_VERTICE' ? (
          <Stack direction="row" gap={1} alignItems="center">
            <Box flexShrink={0}>
              <VerticeV height={14} />
            </Box>
            <EllipsisText variant="body-bold" color="primary1" size="S">
              {t('CONTRACT.CFA.CONTRACT_RENEWALS.COMPLETED.TITLE')}
            </EllipsisText>
          </Stack>
        ) : (
          <EllipsisText variant="body-bold" color="text2" size="S">
            {t('CONTRACTS.GRID.RENEWED')}
          </EllipsisText>
        )
      ) : null;
    }
  }

  if (isPlannedForExpiration && visibility.plannedExpiration) {
    return (
      <EllipsisText variant="body-bold" size="S" color="text3">
        {t(`CONTRACTS.GRID.PLANNED_FOR_EXPIRATION`)}
      </EllipsisText>
    );
  }

  return null;
};

export default AdditionalDetails;
