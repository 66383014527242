import React from 'react';
import { useController } from 'react-hook-form';
import { FormControlLabel, Switch } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';

type FormSwitchFieldProps = React.ComponentProps<typeof Switch> & {
  name: string;
  label: string;
  required?: boolean;
};

export type FormSwitchFieldType = (props: FormSwitchFieldProps) => JSX.Element;

const FormSwitchField = ({ required, name, label, ...otherProps }: FormSwitchFieldProps) => {
  const {
    field: { ref, value, ...field },
  } = useController({ name, rules: { required } });

  return (
    <FormControlLabel
      checked={value}
      control={<Switch inputRef={ref} size="small" {...field} {...otherProps} />}
      label={
        <Text variant="button" size="M">
          {label}
        </Text>
      }
    />
  );
};

export default FormSwitchField;
