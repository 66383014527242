import React, { FC } from 'react';
import { Box, Stack } from '@mui/material';
import EmptyStateError from './EmptyStateError';
import EmptyStateNoData from './EmptyStateNoData';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import Loader from '@verticeone/design-system/src/components/Loader';
import { AWS_BRAND_COLOR } from '../constants';

interface LoadableContentWrapperProps {
  error: unknown;
  isEmpty: boolean;
  isLoading: boolean;
  children: React.ReactNode;
  pt?: string | number;
  pb?: string | number;
  sx?: SxProps<Theme>;
}

export const LoadableContentWrapper: FC<LoadableContentWrapperProps> = ({
  error,
  isEmpty,
  isLoading,
  children,
  ...otherProps
}) => {
  const isError = !!error;
  if (isError || isLoading || isEmpty) {
    return (
      <Box display="flex" justifyContent="center" p={6} flexGrow={1}>
        {isError ? <EmptyStateError /> : isLoading ? <Loader color={AWS_BRAND_COLOR} /> : <EmptyStateNoData />}
      </Box>
    );
  }

  return (
    <Stack flexDirection="column" {...otherProps}>
      {children}
    </Stack>
  );
};
