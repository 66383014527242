import React, { FC } from 'react';
import ScheduleView from '@vertice/core/src/modules/saas/schedule/components/ScheduleView';
import { useRoutes } from '@verticeone/router/src';
import { getScheduleDate, getScheduleStage } from './utils';
import { useContracts } from '@vertice/core/src/modules/saas/contract/components/ContractList/useContracts';
import { PREDEFINED_VIEW_ALL } from '@vertice/core/src/modules/saas/contract/components/ContractList/types';

const Calendar: FC = () => {
  const { viewsOnContracts } = useContracts({ activeFilterView: PREDEFINED_VIEW_ALL });
  const routes = useRoutes();

  return (
    <ScheduleView
      contractRoute={routes.CONTRACTS.DETAIL.ABSOLUTE_PATH}
      contracts={viewsOnContracts}
      getSchedule={(viewOnContract) => {
        const scheduleStage = getScheduleStage(viewOnContract);
        return { scheduleStage, scheduleDate: getScheduleDate(viewOnContract, scheduleStage) };
      }}
    />
  );
};

export default Calendar;
