import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Stack, styled, Typography } from '@mui/material';
import type { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import DataGrid from '@vertice/components/src/DataGrid';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { useGetColumnBuilder } from '@vertice/core/src/modules/saas/contract/components/ContractList/useGetColumnBuilder';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useListContractsQuery, ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { ApplicationWithMeasureReports } from '@vertice/slices/src/graphql/bffeUsage/derivedTypes';
import NoDataGridWrapper from '@vertice/core/src/components/NoDataGridWrapper';
import { ContractListColumn } from '@vertice/core/src/modules/saas/contract/components/ContractList/types';
import Card, { CardHeader } from '@verticeone/design-system/src/components/Card';
import Loader from '@verticeone/design-system/src/components/Loader';
import { isNotNil } from '@vertice/core/src/utils/validation/isNotNil';
import NoRows from './gridComponents/NoRows';

type LinkedContractsProps = {
  application: ApplicationWithMeasureReports;
};

const StyledDataGrid = styled(DataGrid<ViewOnContract>)({
  width: '100%',
  borderRadius: '0 0 1rem 1rem',
  border: 'none',
  'MuiDataGrid-row': {
    cursor: 'pointer',

    'MuiDataGrid-cell': {
      fontSize: '1rem',
      lineHeight: '1.3rem',

      'deadline-cell-date': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
});

const useGetColumns = (): Array<GridColDef<ViewOnContract>> => {
  const { buildColumn } = useGetColumnBuilder();
  if (!buildColumn) {
    return [];
  }

  return [
    buildColumn(ContractListColumn.ANNUAL_COST),
    buildColumn(ContractListColumn.STAGE),
    buildColumn(ContractListColumn.DEADLINE),
  ].filter(isNotNil);
};

const LinkedContracts = ({ application }: LinkedContractsProps) => {
  const { t } = useTranslation();
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();
  const fromLocation = useLocation();
  const { accountId } = useAccountContext();

  const lineageId = application?.matches?.find((match) => match.entityType === 'Contract')?.entityId;

  const { data: linkedContracts, isFetching } = useListContractsQuery({ accountId, lineageId }, { skip: !lineageId });

  const columns = useGetColumns();

  const onRowClick = (item: GridRowParams<ViewOnContract>) => {
    navigate(
      routes.CONTRACTS.DETAIL,
      {
        contractId: item.row.contract.record.contractId,
      },
      { state: { fromLocation } }
    );
  };

  return (
    <Card>
      <CardHeader>
        <Stack gap={2} width="100%">
          <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
            <Typography variant="heading-s">{t('APPLICATIONS.USAGE.LINKED_CONTRACTS.HEADER')}</Typography>
          </Stack>
        </Stack>
      </CardHeader>
      {isFetching ? (
        <Stack p={6} alignItems={'center'}>
          <Loader />
        </Stack>
      ) : (
        <NoDataGridWrapper hasData={!!linkedContracts}>
          <StyledDataGrid
            getRowId={(row: ViewOnContract) => row.contract.record.contractId}
            autoHeight={!!linkedContracts}
            columns={columns}
            rows={linkedContracts?.contracts || []}
            hideFooter
            disableColumnMenu
            onRowClick={onRowClick}
            slots={{
              noRowsOverlay: NoRows,
            }}
          />
        </NoDataGridWrapper>
      )}
    </Card>
  );
};

export default LinkedContracts;
