import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridFilterItem,
  GridFilterOperator,
  GridLogicOperator,
  gridNumberComparator,
  useGridApiRef,
} from '@mui/x-data-grid-pro';

import Text from '@verticeone/design-system/src/components/Text';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useRoutes, useRouteNavigate } from '@verticeone/router/src';
import { getDaysFromNow } from '@vertice/slices/src/slices/UsageAnalytics/helpers';
import DiscoverySourcesCell from '../components/DiscoverySourcesCell';
import LastUsedCellWithSkeleton from '../components/LastUsedCellWithSkeleton';
import UtilizationCell from './UtilizationCell';
import ApplicationGridHeaderCell from './ApplicationGridHeaderCell';
import VendorCell from './VendorCell';
import { ApplicationGridNoRows } from './ApplicationGridNoRows';
import { ApplicationWithMeasureReportsListItem } from '@vertice/slices/src/graphql/bffeUsage/derivedTypes';
import { getActivityFrequency, getUtilization, isCheckWarning } from './hooks';
import { Bins } from '../UsageDetail/components/ActivityFrequency/utils';
import { HealthFilter, Tabs } from '../UsageDetail/utils';
import ActivityFrequencyCell from './ActivityFrequencyCell';
import { UsageContext } from './UsageContext';
import DataGrid from '@verticeone/design-system/src/components/DataGrid';

type ApplicationsGridProps = {
  applications?: readonly ApplicationWithMeasureReportsListItem[];
  unhealthyApplicationsCount?: number;
  loading?: boolean;
  search?: string;
  dataHealthFilter?: HealthFilter;
};

export const ApplicationsGrid = ({
  applications,
  unhealthyApplicationsCount,
  loading,
  search,
  dataHealthFilter,
}: ApplicationsGridProps) => {
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();
  const { t } = useTranslation();
  const apiRef = useGridApiRef();

  const { setActiveTab } = useContext(UsageContext);

  useEffect(() => {
    const items: Array<GridFilterItem> = [
      {
        field: 'vendor',
        value: search || '',
        operator: 'contains',
        id: 'vendor',
      },
    ];

    (dataHealthFilter === HealthFilter.HEALTHY || dataHealthFilter === HealthFilter.UNHEALTHY) &&
      items.push({
        field: 'healthiness',
        value: dataHealthFilter === HealthFilter.UNHEALTHY,
        operator: 'booleanIs',
        id: 'healthiness',
      });

    apiRef.current.setFilterModel({
      logicOperator: GridLogicOperator.And,
      items,
    });
  }, [search, dataHealthFilter, loading, apiRef]);

  const columns: GridColDef<ApplicationWithMeasureReportsListItem>[] = useMemo(
    () => [
      {
        field: 'vendor',
        headerName: t('PREFERENCES.INTEGRATIONS.USAGE.APPLICATION'),
        width: 400,
        flex: 1,
        renderHeader: (params) => (
          <ApplicationGridHeaderCell>{params.colDef.headerName || ''}</ApplicationGridHeaderCell>
        ),
        valueGetter: (params) => params.row.name,
        renderCell: (params) => VendorCell(params.row),
      },
      {
        field: 'source',
        headerName: t('APPLICATIONS.USAGE.SOURCE.HEADER'),
        width: 100,
        editable: false,
        sortable: false,
        renderHeader: (params) => (
          <ApplicationGridHeaderCell>{params.colDef.headerName || ''}</ApplicationGridHeaderCell>
        ),
        valueGetter: (params) => params.row.sources,
        renderCell: (params) => <DiscoverySourcesCell sources={params.value} />,
      },
      {
        field: 'utilization',
        headerName: t('PREFERENCES.INTEGRATIONS.USAGE.UTILIZATION'),
        minWidth: 225,
        editable: false,
        sortable: true,
        valueGetter: (params) => getUtilization(params.row),
        renderHeader: (params) => (
          <ApplicationGridHeaderCell
            tooltipTitle={t('APPLICATIONS.TOOLTIPS.UTILIZATION.TITLE')}
            tooltipContent={t('APPLICATIONS.TOOLTIPS.UTILIZATION.TOOLTIP')}
          >
            {params.colDef.headerName || ''}
          </ApplicationGridHeaderCell>
        ),
        renderCell: (params) => (
          <UtilizationCell
            utilization={params.value}
            license={params.row.license || {}}
            activeUsers={params.row.measureReports?.uniqueUserAccessCountLast28d?.at(0)?.value || '0'}
            userCount={params.row.measureReports?.totalUsers}
          />
        ),
      },
      {
        field: 'issues',
        headerName: t('APPLICATIONS.USAGE.ISSUES'),
        width: 500,
        valueGetter: (params) =>
          params.row.dataHealth?.checks
            ?.filter(isCheckWarning)
            .map((check) => t(`APPLICATIONS.USAGE.DATA_HEALTH.${check.code}.HEADER`))
            .join(', '),
        renderHeader: (params) => (
          <ApplicationGridHeaderCell>{params.colDef.headerName || ''}</ApplicationGridHeaderCell>
        ),
        renderCell: (params) => (
          <Text variant="body-regular" size="M">
            {params.value}
          </Text>
        ),
      },
      {
        field: 'activityFrequency',
        headerName: t('PREFERENCES.INTEGRATIONS.USAGE.TYPICALLY_USED'),
        width: 225,
        editable: false,
        sortable: true,
        sortComparator: (a, b) => Bins.indexOf(a) - Bins.indexOf(b),
        valueGetter: (params) => getActivityFrequency(params.row),
        renderHeader: (params) => (
          <ApplicationGridHeaderCell
            tooltipTitle={t('APPLICATIONS.TOOLTIPS.TYPICALLY_USED.TITLE')}
            tooltipContent={t('APPLICATIONS.TOOLTIPS.TYPICALLY_USED.TOOLTIP')}
          >
            {params.colDef.headerName || ''}
          </ApplicationGridHeaderCell>
        ),
        renderCell: (params) => <ActivityFrequencyCell activityFrequency={params.value} />,
      },
      {
        field: 'lastUsed',
        headerName: t('PREFERENCES.INTEGRATIONS.USAGE.LAST_ACTIVITY'),
        width: 225,
        editable: false,
        sortable: true,
        sortComparator: gridNumberComparator,
        valueGetter: (params) => getDaysFromNow(params.row.measureReports?.lastActivity56d),
        renderHeader: (params) => (
          <ApplicationGridHeaderCell
            tooltipTitle={t('APPLICATIONS.TOOLTIPS.LAST_USED.TITLE')}
            tooltipContent={t('APPLICATIONS.TOOLTIPS.LAST_USED.TOOLTIP')}
          >
            {params.colDef.headerName || ''}
          </ApplicationGridHeaderCell>
        ),
        renderCell: (params) => <LastUsedCellWithSkeleton lastUsed={params.value} />,
      },
      {
        field: 'healthiness',
        type: 'boolean',
        valueGetter: (params) => params.row.hasWarnings,
        filterOperators: [booleanFilterOperator],
      },
    ],
    [t]
  );

  const columnVisibilityModel = useMemo(
    () => ({
      healthiness: false,
      issues: dataHealthFilter === HealthFilter.UNHEALTHY,
      utilization: dataHealthFilter !== HealthFilter.UNHEALTHY,
      activityFrequency: dataHealthFilter !== HealthFilter.UNHEALTHY,
      lastUsed: dataHealthFilter !== HealthFilter.UNHEALTHY,
    }),
    [dataHealthFilter]
  );

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    setActiveTab(Tabs.APPLICATIONS);
    navigate(routes.APPLICATIONS.USAGE_DETAIL, { applicationId: params.row.id });
  };

  return (
    <DataGrid<ApplicationWithMeasureReportsListItem>
      noRowsOverlayHeight={400}
      autoHeight
      getRowHeight={() => 60} // defines actual rows height
      apiRef={apiRef}
      columns={columns}
      rows={applications || []}
      disableRowSelectionOnClick
      disableColumnMenu
      hideFooterRowCount
      loadingStyle="skeleton"
      loading={loading}
      hideFooter
      initialState={{
        sorting: {
          sortModel: [{ field: 'activeUsers', sort: 'desc' }],
        },
      }}
      columnVisibilityModel={columnVisibilityModel}
      onRowClick={handleRowClick}
      slots={{
        noRowsOverlay: () => (
          <ApplicationGridNoRows filter={dataHealthFilter} unhealthyApplicationsCount={unhealthyApplicationsCount} />
        ),
        noResultsOverlay: () => (
          <ApplicationGridNoRows filter={dataHealthFilter} unhealthyApplicationsCount={unhealthyApplicationsCount} />
        ),
      }}
    />
  );
};

// there is a bug in MUI DataGrid where the boolean filter operator doesn't work
export const booleanFilterOperator: GridFilterOperator<ApplicationWithMeasureReportsListItem, boolean> = {
  label: 'Boolean',
  value: 'booleanIs',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.field || filterItem.value === null || !filterItem.operator) {
      return () => true;
    }

    return (params: GridCellParams): boolean => {
      return params.value === filterItem.value;
    };
  },
};
