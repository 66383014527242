import React, { FC, ReactNode } from 'react';
import { Stack } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import Breadcrumbs, { BreadcrumbLink } from '@verticeone/design-system/src/components/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import useCfaRoutes from '../../../router/Routes';
import { Link as RouterLink } from 'react-router-dom';
import Placeholder from '@verticeone/design-system/src/components/Placeholder';

type IntelligentWorkflowsBreadcrumbsProps = {
  currentPageName?: string;
  isLoading?: boolean;
  parentPageName?: string;
  parentPagePath?: string;
};

const IntelligentWorkflowsBreadcrumbs: FC<IntelligentWorkflowsBreadcrumbsProps> = (props) => {
  const { t } = useTranslation();
  const paths = useCfaRoutes();

  const currentPath = window.location.pathname;
  const RootBreadcrumbLink = (['WORKFLOWS', 'TASKS', 'REQUESTS'] as const).map((link) => {
    return (
      currentPath.startsWith(paths.INTELLIGENT_WORKFLOWS[link].ABSOLUTE_PATH) && (
        <BreadcrumbLink to={'..'} {...(props.currentPageName ? { component: RouterLink } : {})} key={link}>
          {t(`INTELLIGENT_WORKFLOWS.NAVBAR.${link}`)}
        </BreadcrumbLink>
      )
    );
  });

  return (
    <Breadcrumbs variant="slash" size="S">
      <BreadcrumbLink>{t('INTELLIGENT_WORKFLOWS.NAVBAR.GROUP_TITLE')}</BreadcrumbLink>
      {RootBreadcrumbLink}
      {(props.parentPageName || props.isLoading) && (
        <BreadcrumbLink component={RouterLink} to={props.parentPagePath || ''}>
          {props.isLoading ? <Placeholder width={100} /> : props.parentPageName}
        </BreadcrumbLink>
      )}
      {(props.currentPageName || props.isLoading) && (
        <BreadcrumbLink>{props.isLoading ? <Placeholder width={100} /> : props.currentPageName}</BreadcrumbLink>
      )}
    </Breadcrumbs>
  );
};

type IntelligentWorkflowsLayoutProps = {
  heading?: ReactNode;
  children: ReactNode;
  currentPageName?: string;
  parentPageName?: string;
  parentPagePath?: string;
  isLoading?: boolean;
  actions?: ReactNode;
};

export const IntelligentWorkflowsLayout: FC<IntelligentWorkflowsLayoutProps> = ({
  heading,
  children,
  currentPageName,
  parentPageName,
  parentPagePath,
  actions,
  isLoading,
}) => {
  return (
    <Stack padding={10} gap={8}>
      <Stack direction="row" justifyContent="space-between">
        <Stack gap={2}>
          <IntelligentWorkflowsBreadcrumbs
            currentPageName={currentPageName}
            parentPageName={parentPageName}
            parentPagePath={parentPagePath}
            isLoading={isLoading}
          />
          {!isLoading ? (
            <Text variant="heading" size="M">
              {heading}
            </Text>
          ) : (
            <Placeholder width={200} height={30} />
          )}
        </Stack>
        {actions}
      </Stack>

      <Stack>{children}</Stack>
    </Stack>
  );
};
