import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Select from '@verticeone/design-system/src/components/Select';
import { MultiValue } from 'react-select';
import { ContractListFilterContext } from '@vertice/core/src/modules/saas/contract/contexts/ContractListFilterContext';
import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import uniqueBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import { Filter } from '../types';

type Owner = {
  ownerId: string;
  name: string;
};

type OwnerFilterProps = {
  views?: ViewOnContract[];
};

const getContractsOwners = (views: ViewOnContract[]): Owner[] => {
  const owners: Owner[] = [];
  views.forEach((view) => {
    const { computed } = view;
    if (computed?.owners) {
      computed.owners.forEach((owner) => {
        if (owner.userId) {
          owners.push({
            ownerId: owner.userId,
            name: computed.labels?.[owner.userId] || owner.userId,
          });
        }
      });
    }
  });
  const uniqueOwnerOptions = uniqueBy(owners, (owner) => owner.ownerId);
  return orderBy(uniqueOwnerOptions, (owner) => owner.name.toLowerCase());
};

export const OwnerFilter: FC<OwnerFilterProps> = ({ views = [] }) => {
  const { t } = useTranslation();
  const { filterValues, setFilterValue } = useContext(ContractListFilterContext);
  const setLastOwners = setFilterValue(Filter.OWNER);
  const onChange = (selection: MultiValue<Owner>) =>
    setLastOwners(selection.length ? selection?.map((option) => option.ownerId) : undefined);
  const owners = getContractsOwners(views);

  return (
    <Stack minWidth={240}>
      {/* TODO: Use our unified @vertice/core/src/modules/user/UserSelect instead */}
      <Select<Owner, true>
        variant="solid"
        size="S"
        isMulti={true}
        maxMultiChips={1}
        isClearable={true}
        options={owners}
        value={owners?.filter(({ ownerId }) => filterValues?.[Filter.OWNER]?.includes(ownerId))}
        getOptionLabel={({ name }) => name}
        getOptionValue={({ ownerId }) => ownerId}
        placeholder={t('ENTITIES.FILTERS.PLACEHOLDERS.OWNER')}
        onChange={onChange}
        testId={'owner-filter'}
      />
    </Stack>
  );
};
