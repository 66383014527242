import { Stack } from '@mui/material';
import { DesignSystemSize } from '../../types';
import { multiItemGapSizes } from '../../guidelines/Sizing/sizings';
import ChipButton, { ChipButtonProps } from './ChipButton';
import { ValuesAggregatorChip } from './ValuesAggregatorChip';

export type MultiChipContainerProps = {
  size: DesignSystemSize;
  maxChips?: number;
  chips: (ChipButtonProps & { label: string })[];
  isActive?: boolean;
};

export const MultiChipContainer = ({ size, maxChips, chips, isActive }: MultiChipContainerProps) => {
  const shownChips = maxChips ? chips.slice(0, maxChips) : chips;
  const aggregatedChips = maxChips ? chips.slice(maxChips) : [];
  const aggregatedChipsValues = aggregatedChips.map((chip) => chip.label);

  return (
    <Stack direction="row" gap={multiItemGapSizes[size]}>
      {shownChips.map((chip, index) => {
        const { label, ...rest } = chip;
        return <ChipButton key={index} size={size} {...rest} isActive={chip.isActive || isActive} />;
      })}
      {aggregatedChips.length > 0 && <ValuesAggregatorChip values={aggregatedChipsValues} size={size} />}
    </Stack>
  );
};
