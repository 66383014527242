import { List, ListItem, ListItemText, Paper, Stack, Tooltip } from '@mui/material';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { API_URLS } from '@vertice/slices';
import { useIssueAccessTokenForScimMutation } from '@vertice/slices/src/openapi/codegen/scimAPI';
import Button from '@verticeone/design-system/src/components/Button';
import Text from '@verticeone/design-system/src/components/Text';

import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { PREFERENCES_PAGES } from '@vertice/utils/src/constants/cfa/constants';

export const SCIMSetupWizard = () => {
  const { accountId } = useAccountContext();
  const [issueAccessToken] = useIssueAccessTokenForScimMutation();
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();
  const { t } = useTranslation();
  const [URLCopied, setURLCopied] = useState<boolean>(false);
  const [accessTokenCopiedText, setAccessTokenCopiedText] = useState<string>('');

  const { SCIM } = API_URLS;

  const accountSCIMEndpointURL = `${SCIM}/accounts/${accountId}/scim`;

  const copyToClipboard = (text?: string, setCallback?: (value: boolean) => void) => {
    if (!text || !setCallback) {
      return;
    }

    navigator.clipboard.writeText(text).then(
      () => {
        setCallback(true);
        setTimeout(() => setCallback(false), 2000);
      },
      (err) => {
        // eslint-disable-next-line no-console
        console.error('Async: Could not copy text: ', err);
      }
    );
  };

  const handleCloseSetup = () => navigate(routes.PREFERENCES.TAB, { tab: PREFERENCES_PAGES.INTEGRATIONS });

  return (
    <Stack alignItems="center" sx={{ width: 1, height: 1 }} p={10}>
      <Stack direction="row" justifyContent="space-between" alignItems="stretch" spacing={2} sx={{ width: 1 }}>
        <Text variant="heading" size="S">
          {t('PREFERENCES.INTEGRATIONS.SCIM.WIZARD.TITLE')}
        </Text>
        <Button onClick={handleCloseSetup}>{t('PREFERENCES.INTEGRATIONS.SCIM.WIZARD.CLOSE_SETUP')}</Button>
      </Stack>
      <Paper sx={{ width: 540, height: '100%', padding: 10 }}>
        <Stack direction="column" gap={3}>
          <List sx={{ listStyle: 'decimal', pl: 3 }}>
            <ListItem>
              <ListItemText>
                <Text variant="caption">{t('PREFERENCES.INTEGRATIONS.SCIM.WIZARD.OKTA_SETUP_HEADER')}</Text>
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText>
                <Text variant="body-regular" size="S">
                  <Trans i18nKey="PREFERENCES.INTEGRATIONS.SCIM.WIZARD.OKTA_SETUP_STEP1" components={{ bold: <b /> }} />
                </Text>
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText>
                <Text variant="body-regular" size="S">
                  <Trans i18nKey="PREFERENCES.INTEGRATIONS.SCIM.WIZARD.OKTA_SETUP_STEP2" components={{ bold: <b /> }} />
                </Text>
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText>
                <Text variant="body-regular" size="S">
                  <Trans i18nKey="PREFERENCES.INTEGRATIONS.SCIM.WIZARD.OKTA_SETUP_STEP3" components={{ bold: <b /> }} />
                </Text>
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText>
                <Text variant="body-regular" size="S">
                  <Trans i18nKey="PREFERENCES.INTEGRATIONS.SCIM.WIZARD.OKTA_SETUP_STEP4" components={{ bold: <b /> }} />
                </Text>
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText>
                <Text variant="body-regular" size="S">
                  <Trans i18nKey="PREFERENCES.INTEGRATIONS.SCIM.WIZARD.OKTA_SETUP_STEP5" components={{ bold: <b /> }} />
                </Text>
                <Button
                  variant="plain"
                  onClick={() => copyToClipboard(accountSCIMEndpointURL, () => setURLCopied(true))}
                >
                  <Tooltip
                    title={
                      URLCopied
                        ? t('PREFERENCES.INTEGRATIONS.SCIM.WIZARD.COPIED')
                        : t('PREFERENCES.INTEGRATIONS.SCIM.WIZARD.COPY_TO_CLIBOARD')
                    }
                    placement="top"
                    onOpen={() => setURLCopied(false)}
                  >
                    <Text variant="code" size="S" align="left">
                      {accountSCIMEndpointURL}
                    </Text>
                  </Tooltip>
                </Button>
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText>
                <Text variant="body-regular" size="S">
                  <Trans i18nKey="PREFERENCES.INTEGRATIONS.SCIM.WIZARD.OKTA_SETUP_STEP6" components={{ bold: <b /> }} />
                </Text>
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText>
                <Text variant="body-regular" size="S">
                  <Trans
                    i18nKey="PREFERENCES.INTEGRATIONS.SCIM.WIZARD.OKTA_SETUP_STEP7"
                    components={{ bold: <b />, ul: <ul />, li: <li /> }}
                  />
                </Text>
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText>
                <Text variant="body-regular" size="S">
                  <Trans i18nKey="PREFERENCES.INTEGRATIONS.SCIM.WIZARD.OKTA_SETUP_STEP8" components={{ bold: <b /> }} />
                </Text>
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText>
                <Text variant="body-regular" size="S">
                  <Trans i18nKey="PREFERENCES.INTEGRATIONS.SCIM.WIZARD.OKTA_SETUP_STEP9" components={{ bold: <b /> }} />
                </Text>
                <Tooltip
                  title={accessTokenCopiedText}
                  placement="top"
                  onOpen={() => setAccessTokenCopiedText(t('PREFERENCES.INTEGRATIONS.SCIM.WIZARD.GENERATE_AND_COPY'))}
                >
                  <Button
                    size="S"
                    sx={{ width: 200 }}
                    onClick={() => {
                      issueAccessToken({ accountId })
                        .then((res) => {
                          if ('data' in res) {
                            copyToClipboard(res.data?.accessToken, () =>
                              setAccessTokenCopiedText(t('PREFERENCES.INTEGRATIONS.SCIM.WIZARD.COPIED'))
                            );
                          }
                        })
                        .catch(() => setAccessTokenCopiedText(t('PREFERENCES.INTEGRATIONS.SCIM.WIZARD.ERROR')));
                    }}
                  >
                    {t('PREFERENCES.INTEGRATIONS.SCIM.WIZARD.GENERATE_NEW_TOKEN')}
                  </Button>
                </Tooltip>
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText>
                <Text variant="body-regular" size="S">
                  <Trans
                    i18nKey="PREFERENCES.INTEGRATIONS.SCIM.WIZARD.OKTA_SETUP_STEP10"
                    components={{ bold: <b /> }}
                  />
                </Text>
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText>
                <Text variant="body-regular" size="S">
                  <Trans
                    i18nKey="PREFERENCES.INTEGRATIONS.SCIM.WIZARD.OKTA_SETUP_STEP11"
                    components={{ bold: <b /> }}
                  />
                </Text>
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText>
                <Text variant="body-regular" size="S">
                  <Trans
                    i18nKey="PREFERENCES.INTEGRATIONS.SCIM.WIZARD.OKTA_SETUP_STEP12"
                    components={{ bold: <b />, ul: <ul />, li: <li /> }}
                  />
                </Text>
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText>
                <Text variant="body-regular" size="S">
                  <Trans
                    i18nKey="PREFERENCES.INTEGRATIONS.SCIM.WIZARD.OKTA_SETUP_STEP13"
                    components={{ bold: <b /> }}
                  />
                </Text>
              </ListItemText>
            </ListItem>
          </List>
        </Stack>
      </Paper>
    </Stack>
  );
};
