import { KeyboardEventHandler, KeyboardEvent, useState } from 'react';
import { isAlpha } from 'validator';

export type KeyboardShortcut = {
  keys: string[];
  action: VoidFunction | null;
  onDownCallback?: KeyboardEventHandler;
  onUpCallback?: KeyboardEventHandler;
};

export type KeyboardShortcuts = Record<string, KeyboardShortcut>;

export type ActiveShortcuts = {
  [key in keyof KeyboardShortcuts]: boolean;
};

export type UseKeyboardShortcuts = {
  shortcuts: KeyboardShortcuts;
  ignoreLetterPresses?: boolean;
};

export type UseKeyboardShortcutsReturn = {
  handlers: {
    onKeyDown: KeyboardEventHandler;
    onKeyUp: KeyboardEventHandler;
  };
};

const useKeyboardShortcuts = ({
  shortcuts,
  ignoreLetterPresses = false,
}: UseKeyboardShortcuts): UseKeyboardShortcutsReturn => {
  const [, setCurrentKeys] = useState<string[]>([]);

  const handleKeySettingInShortcuts = (event: KeyboardEvent<Element>, keys: string[]) => (shortcut: string) => {
    const shortcutKeys = shortcuts[shortcut];

    if (!shortcutKeys.keys.every((k) => keys.includes(k.toLowerCase())) || shortcutKeys.keys.length !== keys.length)
      return;

    if (event.type === 'keydown') {
      shortcutKeys.onDownCallback?.(event);
    } else if (event.type === 'keyup') {
      shortcutKeys.onUpCallback?.(event);
      shortcutKeys.action?.();
    }
  };

  const onKeyDown = (event: KeyboardEvent<Element>) => {
    const key = event.key.toLowerCase();

    if (ignoreLetterPresses && isAlpha(key) && key.length === 1) return;

    setCurrentKeys((oldKeys) => {
      const newKeys = oldKeys.includes(key) ? oldKeys : [...oldKeys, key];

      Object.keys(shortcuts).forEach(handleKeySettingInShortcuts(event, newKeys));

      return newKeys;
    });
  };

  const onKeyUp = (event: KeyboardEvent<Element>) => {
    const key = event.key.toLowerCase();

    if (ignoreLetterPresses && isAlpha(key) && key.length === 1) return;

    setCurrentKeys((oldKeys) => {
      const newKeys = oldKeys.filter((k) => k !== key);

      Object.keys(shortcuts).forEach(handleKeySettingInShortcuts(event, oldKeys));

      return newKeys;
    });
  };

  return {
    handlers: {
      onKeyDown,
      onKeyUp,
    },
  };
};

export default useKeyboardShortcuts;
