import React from 'react';
import Text from '../Text';
import styled from '@mui/material/styles/styled';

export type CardHeaderDescriptionProps = {
  children: React.ReactNode;
};

export const StyledCardHeaderDescription = styled(Text)({});

export const CardHeaderDescription: React.FC<CardHeaderDescriptionProps> = ({ children }) => {
  return (
    <StyledCardHeaderDescription color="text2" variant="body-regular">
      {children}
    </StyledCardHeaderDescription>
  );
};
