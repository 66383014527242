import React, { ReactNode } from 'react';
import { Stack } from '@mui/material';
import Text from '../../Text';
import useTheme from '@mui/material/styles/useTheme';
import { useTabsContext } from '../../Tabs/TabsContext';
import type { DesignSystemSize } from '../../../types';

type RequiredLabelProps = {
  label?: ReactNode;
  disabled?: boolean;
};

const sizeDefinitions: Record<DesignSystemSize, { width: number; gap: number; margin: number }> = {
  XL: { width: 10, gap: 6, margin: 4.9 },
  L: { width: 9, gap: 6, margin: 4.41 },
  M: { width: 8, gap: 4, margin: 4 },
  S: { width: 7, gap: 4, margin: 3.5 },
  XS: { width: 6, gap: 2, margin: 3.06 },
  XXS: { width: 5, gap: 2, margin: 2.55 },
};

const RequiredLabel = ({ label, disabled }: RequiredLabelProps) => {
  const { palette } = useTheme();
  const { size } = useTabsContext();
  const sizeDefinition = sizeDefinitions[size];

  if (!label) {
    return undefined;
  }

  return (
    <Stack direction="row" gap={`${sizeDefinition.gap}px`}>
      <Text
        variant="caption"
        size={size}
        color={disabled ? palette.inactive.color3 : palette.error.color2}
        sx={{ display: 'block', marginX: `-${sizeDefinition.margin}px` }}
        aria-hidden
      >
        ﹡
      </Text>
      {label}
    </Stack>
  );
};

export default RequiredLabel;
