import { rgba } from 'polished';
import type { StylesConfig } from 'react-select';
import { getTextVariantStyle } from '../../Text/utils';
import sizeDefinitions from '../../TextField/styledVariants/sizeDefinitions';
import { MakeStylesParams } from './common';
import { GroupBase } from '../types';

/** Add this to your react-select styles config to apply our menu styles. */
export const makeMenuStyles = <Option extends unknown, IsMulti extends boolean, Group extends GroupBase<Option>>({
  theme: { palette },
  error,
  color,
  size,
}: MakeStylesParams): StylesConfig<Option, IsMulti, Group> => ({
  control: (baseStyles) => ({
    ...baseStyles,
    cursor: 'pointer',
  }),

  placeholder: (baseStyles) => ({
    ...baseStyles,
    color: palette.text.color3,
    margin: 0,
  }),

  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isDisabled ? palette.inactive.color2 : palette.text.color1,
    margin: 0,
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),

  dropdownIndicator: (baseStyles) => ({
    ...baseStyles,
    padding: sizeDefinitions[size].paddingY,
  }),

  valueContainer: (baseStyles) => ({
    ...baseStyles,
    padding: `0 0 0 ${sizeDefinitions[size].paddingX}px`,
  }),

  menu: (baseStyles) => ({
    ...baseStyles,
    marginTop: sizeDefinitions[size].gapY,
    backgroundColor: palette.input.bg,
    boxShadow: palette.global.getShadow({ color: 'core', type: 'soft', depth: '1z', distance: '50' }),
    borderRadius: '8px',
    zIndex: 3,
  }),

  menuList: (baseStyles) => ({
    ...baseStyles,
    padding: '4px 0 4px 0',
    borderRadius: '8px',
  }),

  menuPortal: (baseStyles) => ({
    ...baseStyles,
    zIndex: 9999,
  }),

  option: (baseStyles, state) => ({
    ...baseStyles,
    ...getTextVariantStyle({ variant: 'body-regular', size }),
    boxSizing: 'border-box',
    padding: `${sizeDefinitions[size].paddingY}px ${sizeDefinitions[size].paddingX}px`,
    borderBottom: `1px solid ${palette.core.color3}`,
    '&:last-child': {
      borderBottom: !state.isFocused && !state.isSelected ? 'none' : undefined,
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
    },
    '&:first-of-type': {
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
    },
    '&:active': !state.isDisabled && {
      background: palette[color].color4,
      color: palette[color].color2,
    },
    ...(() => {
      if (state.isDisabled) {
        return {
          backgroundColor: palette.inactive.color3,
          color: palette.inactive.color2,
        };
      } else if (state.isSelected) {
        return {
          background: palette[color].color4,
          color: palette[color].color2,
          borderColor: palette[color].color2,
        };
      } else if (state.isFocused) {
        return {
          background: palette.core.color2,
          cursor: 'pointer',
        };
      } else {
        return {
          color: palette.text.color1,
        };
      }
    })(),
  }),

  noOptionsMessage: (baseStyles) => ({
    ...baseStyles,
    ...getTextVariantStyle({ variant: 'body-regular', size }),
    padding: `${sizeDefinitions[size].paddingY}px ${sizeDefinitions[size].paddingX}px`,
    color: palette.text.color3,
  }),

  loadingMessage: (baseStyles) => ({
    ...baseStyles,
    ...getTextVariantStyle({ variant: 'body-regular', size }),
    padding: `${sizeDefinitions[size].paddingY}px ${sizeDefinitions[size].paddingX}px`,
    color: palette.text.color3,
  }),

  input: (baseStyles, state) => ({
    ...baseStyles,
    margin: 0,
    padding: 0,
    // for multi select reserve height with the input to be the same as the chips which are not always present
    lineHeight: state.isMulti ? '150%' : undefined,
    color: state.isDisabled ? palette.inactive.color2 : palette.text.color1,
    '> input::selection': {
      background: rgba(palette[error ? 'error' : color].color2, 0.2),
    },
  }),

  // remove default styles
  multiValueRemove: () => ({}),
});
