import useContract from '@vertice/core/src/modules/saas/contract/useContract';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { ContractContextProvider } from '@vertice/core/src/modules/saas/contract/ContractContext';
import { AccessVia, FetchedContract } from '@vertice/core/src/modules/saas/contract/types';
import { useMemo } from 'react';
import { ContractFileList } from './ContractFileList';
import Accordion, { AccordionSummary } from '@verticeone/design-system/src/components/Accordion';
import Text from '@verticeone/design-system/src/components/Text';
import { AccordionDetails, Box, Stack, useTheme } from '@mui/material';
import VendorLogo from '@vertice/core/src/components/VendorLogo/VendorLogo';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useFormatDate } from '@vertice/core/src/utils/formatting/date';

const getContractStatus = (contract: Contract, t: TFunction) => {
  switch (contract.materialized?.simplifiedStage) {
    case 'LIVE':
      return t('APPLICATIONS.USAGE.LICENSE_DIALOG.REDUCED_CONTACT_STATUSES.LIVE');
    case 'EXPIRED_NATURAL':
    case 'EXPIRED_PLANNED':
    case 'EXPIRED_UNINTENTIONAL':
      return t('APPLICATIONS.USAGE.LICENSE_DIALOG.REDUCED_CONTACT_STATUSES.EXPIRED');
    case 'WORKFLOW_IN_PROGRESS':
      return t('APPLICATIONS.USAGE.LICENSE_DIALOG.REDUCED_CONTACT_STATUSES.IN_PROGRESS');
    default:
      return null;
  }
};

const ContractView = ({ contract }: { contract: Contract }) => {
  const { accountId } = useAccountContext();
  const { t } = useTranslation();
  const { palette } = useTheme();
  const formatDate = useFormatDate();

  const accessedVia: AccessVia = useMemo(
    () => ({
      accountId,
      type: 'account',
    }),
    [accountId]
  );

  const fetchedContract: FetchedContract = useMemo(
    () => ({
      contract,
      accessedVia,
    }),
    [contract, accessedVia]
  );

  const contractContext = useContract('REQUIRE_FETCHED', accessedVia, fetchedContract);

  return (
    <ContractContextProvider value={contractContext}>
      <Accordion variant="solid" defaultExpanded={false}>
        <AccordionSummary title={t('APPLICATIONS.USAGE.LICENSE_DIALOG.RELATED_CONTRACT_DATA')} />
        <AccordionDetails>
          <Stack direction="column" gap={2} p={2}>
            <Text variant="caption" size="XS" color="text2">
              {t('APPLICATIONS.USAGE.LICENSE_DIALOG.LINKED_CONTRACT')}
            </Text>
            <Stack
              direction="row"
              gap={4}
              p={2}
              alignItems="center"
              sx={{ backgroundColor: palette.core.color1, borderRadius: '12px' }}
              divider={
                <Text variant="body-regular" color="text3" size="M">
                  •
                </Text>
              }
            >
              <>
                <Box padding={1} bgcolor={palette.background.default} borderRadius={2}>
                  <VendorLogo vendorId={contract.parts.contractual?.vendor?.vendorId} bg={palette.background.default} />
                </Box>
                <Text variant="body-regular" color="text1" size="M" noWrap>
                  {contract.parts.contractual?.vendor?.vendorName}
                </Text>
              </>

              <Text variant="body-regular" color="text2" size="M" noWrap>
                {getContractStatus(contract, t)}
              </Text>

              {contract.parts.contractual?.lifecycle?.startDate && (
                <Text variant="body-regular" color="text2" size="M" noWrap>
                  {formatDate(contract.parts.contractual?.lifecycle?.startDate)}
                </Text>
              )}
            </Stack>

            <ContractFileList />
          </Stack>
        </AccordionDetails>
      </Accordion>
    </ContractContextProvider>
  );
};

export default ContractView;
