import { isNil } from 'lodash';
import { EllipsisText, TextProps } from '@verticeone/design-system/src/components/Text';
import React from 'react';

export type EllipsisTextCellProps = { value: string | null | undefined; color?: TextProps['color'] };

export const EllipsisTextCell = ({ value, color }: EllipsisTextCellProps) => (
  <EllipsisText component="div" variant="body-regular" title={value ?? undefined} color={color}>
    {isNil(value) ? '—' : value}
  </EllipsisText>
);
