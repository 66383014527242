import React, { PropsWithChildren, useCallback } from 'react';
import { FilterView } from '../components/ContractList/types';
import useFilterConfig from './useFilterConfig';
import { Filter, FilterValues } from '../components/ContractList/Filters/types';

type ContractListFilterState = {
  lastActiveFilterView?: FilterView;
  setLastActiveFilterView: (filter: FilterView) => void;
  filterValues?: FilterValues;
  setFilterValue: (filterName: Filter) => (value: any) => void;
  availableFilters?: Filter[];
  activeFilters?: Filter[];
  setFiltersEnabled: (filtersToChange: Filter[], enabled: boolean) => void;
  setFiltersVisible: (filtersToChange: Filter[]) => void;
};

const defaultContractListFilterState: ContractListFilterState = {
  setLastActiveFilterView: () => null,
  setFilterValue: () => () => null,
  setFiltersEnabled: () => null,
  setFiltersVisible: () => null,
};

export const ContractListFilterContext = React.createContext<ContractListFilterState>(defaultContractListFilterState);

/* A wrapper to be used in Router to allow state sharing between Contract pages - filter from list needs to be preserved when going to detail and back */
const ContractListFilterContextWrapper = ({ children }: PropsWithChildren) => {
  const [lastActiveFilterView, setLastActiveFilterView] = React.useState<FilterView>();
  const [filterValues, setFilterValues] = React.useState<FilterValues>();

  const { availableFilters, activeFilters, setFiltersEnabled, setFiltersVisible } = useFilterConfig();

  const setFilterValue = useCallback(
    (filterName: Filter) => (value: any) => {
      setFilterValues((oldValues) => ({
        ...(oldValues ?? {}),
        [filterName]: value,
      }));
    },
    [setFilterValues]
  );

  return (
    <ContractListFilterContext.Provider
      value={{
        lastActiveFilterView,
        setLastActiveFilterView,
        filterValues,
        setFilterValue,
        availableFilters,
        activeFilters,
        setFiltersEnabled,
        setFiltersVisible,
      }}
    >
      {children}
    </ContractListFilterContext.Provider>
  );
};

export default ContractListFilterContextWrapper;
