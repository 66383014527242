import { ReactNode } from 'react';
import AuthProvider from '@verticeone/auth/src';
import withAmplify from '@verticeone/auth/src/authentication/amplify/withAmplify';
import useCfaAuth from './useCfaAuth';
import { API_URLS } from '@vertice/slices';

export const CfaAuthContextProvider = withAmplify(({ children }: { children: ReactNode }) => {
  const authContextData = useCfaAuth();

  return (
    <AuthProvider authServiceBaseUrl={API_URLS.BASE_URLS.AUTH_SERVICE} authentication={authContextData}>
      {children}
    </AuthProvider>
  );
});
