export const FEATURES = {
  /** Old Workflows functionality from times before Contracts V2 */
  CUSTOM_WORKFLOWS: 'CUSTOM_WORKFLOWS',
  SSO: 'SSO',
  USAGE_ANALYTICS: 'USAGE_ANALYTICS',
  AWS_COST_INSIGHT: 'AWS_COST_INSIGHT',
  AWS_RIO: 'AWS_RIO',
  AWS_ANALYTICS: 'AWS_ANALYTICS',
  AWS_EDP_INSIGHT: 'AWS_EDP_INSIGHT',
  INTEGRATIONS: 'INTEGRATIONS',
  INTEGRATION_GOOGLE: 'INTEGRATION_GOOGLE',
  INTEGRATION_MERGE: 'INTEGRATION_MERGE',
  INTEGRATION_MS_DYNAMICS: 'INTEGRATION_MS_DYNAMICS',
  INTEGRATION_QUICKBOOKS: 'INTEGRATION_QUICKBOOKS',
  INTEGRATION_NETSUITE: 'INTEGRATION_NETSUITE',
  INTEGRATION_XERO: 'INTEGRATION_XERO',
  INTEGRATION_SLACK: 'INTEGRATION_SLACK',
  INTEGRATION_SLACK_V2: 'INTEGRATION_SLACK_V2',
  INSIGHTS: 'INSIGHTS',
  INSIGHT_ACCOUNTING: 'INSIGHT_ACCOUNTING',
  SAVINGS_DASHBOARD: 'SAVINGS_DASHBOARD',
  CONTRACTS_CUSTOMIZATION: 'CONTRACTS_CUSTOMIZATION',
  SAML_SCIM: 'SAML_SCIM',
  VENDOR_LEGAL: 'VENDOR_LEGAL',
  INTELLIGENT_WORKFLOWS: 'INTELLIGENT_WORKFLOWS',
  ZENDESK: 'ZENDESK',
} as const;
