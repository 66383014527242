import React from 'react';
import { styled } from '@mui/material';
import Text, { TextProps } from '@verticeone/design-system/src/components/Text';
import { ifExpression } from '@verticeone/design-system/src/utils/ifExpression';

export type ValueProps = TextProps;

export const Value = styled((props) => <Text {...props} variant="heading" size="M" testId="value" />)(
  ({ theme, color }) => ({
    ...ifExpression(!color, {
      color: theme.palette.text.color1,
    }),
  })
);
