import React from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';

import FormNumberField from '@vertice/core/src/modules/forms/fields/FormNumberField';
import { TaskFormEntry } from './TaskFormEntry';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { getCurrencySymbol } from '@vertice/core/src/utils/formatting/currency';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { TooltipProps } from '@verticeone/design-system/src/components/Tooltip';

type MoneyFormEntryProps<FormDataType extends FieldValues> = {
  name: FieldPath<FormDataType>;
  label: string;
  currency?: string;
  tooltip?: Pick<TooltipProps, 'title' | 'content'>;
  width?: number;
};

export const MoneyFormEntry = <FormDataType extends FieldValues>({
  name,
  label,
  currency,
  tooltip,
  width = 6,
}: MoneyFormEntryProps<FormDataType>) => {
  const { locale } = useLocaleContext();
  return (
    <TaskFormEntry<any, typeof FormNumberField>
      name={name}
      label={label}
      component={FormNumberField}
      tooltip={tooltip}
      componentProps={{
        minValue: 0,
        unit: currency ? getCurrencySymbol(locale, currency) : '',
        unitPosition: 'start',
        color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
      }}
      width={width}
    />
  );
};
