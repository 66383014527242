import {
  DataTableResult,
  useAnalyticsChartAccountServicesQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useMemo } from 'react';
import useDeferredQuery from '@vertice/core/src/utils/api/useDeferredQuery';
import LoadableAdvanced from '@vertice/utils/src/loadableAdvanced';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { getSortedTableData, prepareCostDataForPeriods } from './utils';
import { PeriodCostComparisonData } from './types';
import { useCloudAccounts } from '../CloudAccountsContext';

type AccountServicesGraphDataProps = {
  linkedAccountId: string;
};

const useAccountServicesGraphData = ({
  linkedAccountId,
}: AccountServicesGraphDataProps): LoadableAdvanced<PeriodCostComparisonData> => {
  const { accountId } = useAccountContext();
  const { period, chartDataLimit } = useCloudAccounts();
  const { periodOneStart, periodOneEnd, periodTwoStart, periodTwoEnd } = period;

  const {
    data: rawData,
    error,
    isLoading,
  } = useDeferredQuery(
    useAnalyticsChartAccountServicesQuery,
    {
      accountId,
      linkedAccountId: linkedAccountId,
      periodOneStart,
      periodOneEnd,
      periodTwoStart,
      periodTwoEnd,
      limit: chartDataLimit,
    },
    { skip: !accountId, pollingInterval: 5000 },
    ({ athenaViewQuery }) =>
      athenaViewQuery?.__typename === 'DeferredQueryResult' ? undefined : { result: athenaViewQuery }
  );

  const computedData = useMemo(() => getSortedTableData(rawData as DataTableResult), [rawData]);

  const data = useMemo(
    () => prepareCostDataForPeriods(computedData, periodOneStart, periodOneEnd, periodTwoStart, periodTwoEnd),
    [computedData, periodOneEnd, periodOneStart, periodTwoEnd, periodTwoStart]
  );

  return {
    error: error,
    isEmpty: Boolean(computedData && computedData.length === 0),
    isLoading,
    data,
  };
};

export default useAccountServicesGraphData;
