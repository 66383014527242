import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Breadcrumbs, { BreadcrumbLink } from '@verticeone/design-system/src/components/Breadcrumbs';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Text from '@verticeone/design-system/src/components/Text';
import { VendorLogo } from '@vertice/components';
import { Box, Skeleton } from '@mui/material';
import { ContractLocator, FetchedContract } from '@vertice/core/src/modules/saas/contract/types';
import useFetchContract from '@vertice/core/src/modules/saas/contract/hooks/useFetchContract';
import useContract from '@vertice/core/src/modules/saas/contract/useContract';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';

export type PageHeaderProps = {
  contractLocator: ContractLocator;
};

const PageHeaderContent = ({ vendorId, vendorName }: { vendorId?: string; vendorName?: string }) => {
  const { t } = useTranslation();
  const { generatePath } = useRouteNavigate();
  const routes = useRoutes();

  return (
    <Stack gap={1} px={10}>
      <Breadcrumbs variant="slash" size="S">
        <BreadcrumbLink>{t('SAAS.NAVBAR.SAAS')}</BreadcrumbLink>
        <BreadcrumbLink component={RouterLink} to={generatePath(routes.CONTRACTS.PATH)}>
          {t('SAAS.NAVBAR.CONTRACTS')}
        </BreadcrumbLink>
        <BreadcrumbLink disabled>{vendorName ?? <Skeleton width="6em" />}</BreadcrumbLink>
      </Breadcrumbs>
      <Stack direction="row" gap={2}>
        <Box py={0.5}>
          <VendorLogo vendorId={vendorId} />
        </Box>

        <Text variant="heading" color="text1" tag="h1" flex={1}>
          {vendorName ?? <Skeleton width="6em" />}
        </Text>
      </Stack>
    </Stack>
  );
};

const PageHeaderLoaded = ({ fetchedContract }: { fetchedContract: FetchedContract }) => {
  const contractContextData = useContract('REQUIRE_FETCHED', fetchedContract.accessedVia, fetchedContract);
  const vendor = contractContextData.fetchedContract.contract.parts.contractual?.vendor;
  return <PageHeaderContent vendorId={vendor?.vendorId ?? undefined} vendorName={vendor?.vendorName ?? undefined} />;
};

const PageHeader = ({ contractLocator }: PageHeaderProps) => {
  const { contractId } = useParams();
  const fetchContractQuery = useFetchContract(contractLocator);

  const fetchingContract =
    fetchContractQuery.isLoading || fetchContractQuery.data.contract.record.contractId !== contractId;

  return fetchingContract ? <PageHeaderContent /> : <PageHeaderLoaded fetchedContract={fetchContractQuery.data} />;
};

export default PageHeader;
