import React from 'react';
import Card, { CardHeader, CardHeaderTitle } from '@verticeone/design-system/src/components/Card';
import DataGrid from '@verticeone/design-system/src/components/DataGrid';
import Placeholder from '@verticeone/design-system/src/components/Placeholder';
import { WorkflowRow } from './types';
import { useWorkflowsColumns } from './workflowsHooks';

export const WorkflowsListSkeleton = ({ serviceName }: { serviceName?: string }) => {
  const columns = useWorkflowsColumns();
  return (
    <Card>
      <CardHeader size="S" density="medium">
        <CardHeaderTitle text={serviceName || <Placeholder width={200} height={24} />} />
      </CardHeader>
      <DataGrid<WorkflowRow>
        color="neutral"
        columns={columns}
        loading
        loadingStyle="skeleton"
        rows={[]}
        noBorder
        noBorderRadius
        loadingRowCount={2}
        getRowHeight={() => 60}
      ></DataGrid>
    </Card>
  );
};
