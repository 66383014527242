import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  DialogText,
} from '@verticeone/design-system/src/components/Dialog';
import Button from '@verticeone/design-system/src/components/Button';
import { useTranslation } from 'react-i18next';

interface TerminateContractDialogProps {
  isOpened: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const TerminateContractDialog = ({ isOpened, onClose, onSubmit }: TerminateContractDialogProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.CONTRACT.DIALOGS.TERMINATE_CONTRACT' });

  return (
    <Dialog size="M" open={isOpened} align="center">
      <DialogHeader>{t('TITLE')}</DialogHeader>
      <DialogContent>
        <DialogText variant="body-regular">{t('MESSAGE')}</DialogText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outline">
          {t('BUTTONS.NO')}
        </Button>
        <Button onClick={onSubmit} variant="solid">
          {t('BUTTONS.YES')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
