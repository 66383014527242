import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import Loader from '@verticeone/design-system/src/components/Loader';
import { useListContractsQuery } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { ApplicationWithMeasureReports } from '@vertice/slices/src/graphql/bffeUsage/derivedTypes';
import Text from '@verticeone/design-system/src/components/Text';
import ContractCard from './ContractCard';

type ContractListProps = {
  selectedContract: string;
  setSelectedContract: Dispatch<SetStateAction<string>>;
  application?: ApplicationWithMeasureReports;
};

const ContractList = ({ selectedContract, setSelectedContract, application }: ContractListProps) => {
  const { accountId } = useAccountContext();
  const vendorId = application?.matches?.find((match) => match.entityType === 'Vendor')?.entityId;
  const { t } = useTranslation();

  const {
    data: suggestions,
    isFetching,
    isLoading,
  } = useListContractsQuery({ accountId, vendorId }, { skip: !vendorId });

  if (isFetching || isLoading) {
    return (
      <Stack p={3} alignItems={'center'}>
        <Loader />
      </Stack>
    );
  }

  if (!suggestions || suggestions.contracts.length === 0) {
    return (
      <Stack p={6} alignItems={'center'}>
        <Text variant="body-regular" size="S">
          {t('APPLICATIONS.USAGE.CONTRACT_DIALOG.NO_SUGGESTIONS')}
        </Text>
      </Stack>
    );
  }

  return (
    <Stack gap={2}>
      {suggestions.contracts.map((contract) => (
        <ContractCard
          vendorId={vendorId}
          contract={contract.contract}
          selectedContract={selectedContract}
          setSelectedContract={setSelectedContract}
          key={contract.contract.record.contractId}
        />
      ))}
    </Stack>
  );
};

export default ContractList;
