// eslint-disable react/no-this-in-sfc
import Highcharts, {
  Chart,
  Chart as HighchartsChart,
  TooltipFormatterCallbackFunction,
  TooltipFormatterContextObject,
} from 'highcharts';
import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

const generateTooltipId = (chartId: number) => `highcharts-custom-tooltip-${chartId}`;

interface Props {
  chart: Chart | null;
  children(formatterContext: TooltipFormatterContextObject): JSX.Element;
}

const updateTooltipSize = (chart: HighchartsChart) => {
  const tooltip: any = chart.tooltip;
  const rowHeight = tooltip.label.text.element.offsetHeight;
  const rowWidth = tooltip.label.text.element.offsetWidth;

  tooltip.label.box.attr({
    height: rowHeight,
    width: rowWidth,
  });
};

export const HighchartTooltip = ({ chart, children }: Props) => {
  const isInit = useRef(false);
  const [context, setContext] = useState<TooltipFormatterContextObject | null>(null);

  useEffect(() => {
    if (chart) {
      // eslint-disable-next-line func-names
      const formatter: TooltipFormatterCallbackFunction = function () {
        // Ensures that tooltip DOM container is rendered before React portal is created.
        if (!isInit.current) {
          isInit.current = true;

          // requestAnimationFrame = run AFTER this React rendering finishes
          window.requestAnimationFrame(() => {
            // Position the tooltip correctly for the first render
            if (chart.hoverPoints) {
              chart.tooltip.refresh(chart.hoverPoints);
            }
            chart.tooltip.hide(0);

            Highcharts.addEvent(chart.tooltip, 'refresh', () => {
              // Eliminate resize flashing for tooltips with shared: true
              updateTooltipSize(chart);
            });
          });
        }

        setContext(this);

        return `<div id="${generateTooltipId(chart.index)}"></div>`;
      };

      chart.update({
        tooltip: {
          formatter,
          useHTML: true,
        },
      });
    }
  }, [chart]);

  useEffect(() => {
    if (context && chart) {
      updateTooltipSize(chart);
    }
  }, [context, chart]);

  const node = chart && document.getElementById(generateTooltipId(chart.index));

  return node && context ? ReactDOM.createPortal(children(context), node) : null;
};
