import { forwardRef } from 'react';
import { styled } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

import IconWrapper from '@verticeone/design-system/src/components/IconWrapper';

const InfoIcon = styled(
  forwardRef<SVGSVGElement>((props, ref) => (
    <IconWrapper {...props} ref={ref} icon={InfoOutlined} size="S" color="info" />
  ))
)(({ theme }) => ({
  backgroundColor: theme.palette.info.color2,
  borderRadius: '10%',
  color: theme.palette.info.contrastText,
  padding: 2,
}));

export default InfoIcon;
