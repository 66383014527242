import React, { useMemo, useState } from 'react';
import { ArrowForwardIos } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate, generatePath } from 'react-router-dom';
import { Card, CardActions, CardContent, CardHeader } from '@mui/material';
import IntegrationsCardContent from '../../components/IntegrationsCardContent';
import styles from '../../common.module.scss';
import { StatusBadge, StatusBadgeVariant } from '../../components/StatusBadge';
import { useRevoke } from './useRevoke';
import { useGetAccountIntegrationQuery } from '@vertice/slices';
import { providers } from '../providers';
import { INTEGRATION_ROUTES } from '@vertice/utils/src/constants/cfa/constants';
import ConfirmationDialog from '@vertice/components/src/ConfirmationDialog/ConfirmationDialog';
import Button from '@verticeone/design-system/src/components/Button/Button';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

type MergeAccountingSetupCardProps = {
  id: keyof typeof providers;
};

const MergeAccountingSetupCard = ({ id }: MergeAccountingSetupCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    providerCode,
    translationId,
    icon: { small: Icon },
  } = providers[id];
  const providerName = t(`PREFERENCES.INTEGRATIONS.ACCOUNTING.${translationId}.TITLE`);
  const providerDescription = t(`PREFERENCES.INTEGRATIONS.ACCOUNTING.${translationId}.DESCRIPTION`);
  const { accountId } = useAccountContext();
  const { data: integration } = useGetAccountIntegrationQuery(
    { accountId: accountId!, integrationId: providerCode },
    { skip: !accountId }
  );
  const onRevokeCompleted = () => setOpenRevokeDialog(false);
  const { isRevoking, revoke } = useRevoke({ providerCode, onRevokeCompleted });
  const [openRevokeDialog, setOpenRevokeDialog] = useState(false);
  const integrationStatus = useMemo(
    () => ({
      isActive: integration?.status === 'ACTIVE',
      isFailed: integration?.connection?.status === 'FAILED',
    }),
    [integration]
  );

  const badgeStatus = useMemo(() => {
    if (integrationStatus.isActive) {
      return StatusBadgeVariant.ACTIVE;
    }
    if (integrationStatus.isFailed) {
      return StatusBadgeVariant.ERROR;
    }
    return StatusBadgeVariant.NEW;
  }, [integrationStatus]);

  return (
    <div className={styles.card}>
      <Card variant="outlined">
        <CardHeader avatar={<Icon />} />
        <CardContent classes={{ root: styles['card-content'] }}>
          <IntegrationsCardContent
            title={providerName}
            description={providerDescription}
            statusBadge={badgeStatus && <StatusBadge variant={badgeStatus} />}
          />
        </CardContent>
        <CardActions className={styles['card-actions']}>
          {!integrationStatus.isActive && !integrationStatus.isFailed && (
            <Button
              onClick={() =>
                navigate(generatePath(INTEGRATION_ROUTES.ACCOUNTING, { providerId: id }), { relative: 'route' })
              }
              variant="outline"
              color="neutral"
              size="S"
            >
              {t('PREFERENCES.INTEGRATIONS.CONNECT')}
              <ArrowForwardIos sx={{ opacity: 0.6 }} />
            </Button>
          )}
          {(integrationStatus.isActive || integrationStatus.isFailed) && (
            <Button
              isLoading={isRevoking}
              onClick={() => setOpenRevokeDialog(true)}
              variant="ghost"
              color="neutral"
              size="S"
            >
              {t('PREFERENCES.INTEGRATIONS.REVOKE_ACCESS')}
            </Button>
          )}
        </CardActions>
      </Card>
      <ConfirmationDialog
        isOpen={openRevokeDialog}
        headerText={t('PREFERENCES.INTEGRATIONS.REVOKE_DIALOG.WARNING')}
        bodyPrimaryText={t('PREFERENCES.INTEGRATIONS.REVOKE_DIALOG.TITLE', { providerName })}
        bodySecondaryText={t('PREFERENCES.INTEGRATIONS.REVOKE_DIALOG.DESCRIPTION', { providerName })}
        secondaryButtonText={t('PREFERENCES.INTEGRATIONS.REVOKE_DIALOG.CANCEL')}
        secondaryButtonAction={() => setOpenRevokeDialog(false)}
        primaryButtonText={t('PREFERENCES.INTEGRATIONS.REVOKE_DIALOG.REVOKE')}
        primaryButtonAction={() => revoke()}
      />
    </div>
  );
};

export default MergeAccountingSetupCard;
