import React from 'react';
import { Check, Close, Remove } from '@mui/icons-material';
import clsx from 'clsx';
import styles from './IconBullet.module.scss';

export const BulletType = {
  check: 'check',
  minus: 'minus',
  cross: 'cross',
};

export type TypeOfBulletType = keyof typeof BulletType;

interface IconBulletProps {
  type?: TypeOfBulletType;
  size?: number;
}

const IconBullet: React.FC<IconBulletProps> = ({ type = BulletType.check, size = 24 }) => {
  const iconSize = Math.round(size - (2 * size) / 6);
  const iconStyles = { fontSize: `${iconSize}px` };
  const iconMap = {
    [BulletType.check]: <Check sx={iconStyles} />,
    [BulletType.minus]: <Remove sx={iconStyles} />,
    [BulletType.cross]: <Close sx={iconStyles} />,
  };

  return (
    <div className={clsx(styles['icon-bullet'], styles[`icon-bullet--${type}`])} style={{ width: size, height: size }}>
      {iconMap[type]}
    </div>
  );
};

export default IconBullet;
