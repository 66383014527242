import { useExchangeCurrency } from '../../../../../../../utils/currency/useExchangeCurrency';
import { useAccountSettings } from '../../../../../../../hooks/useAccountSettings';
import { useCallback, useMemo } from 'react';
import { getFilterForContractValue } from '../filterFunctions';
import { Contract, ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { ContractValue } from '../types';
import { DEFAULT_CURRENCY } from '../../../../../../../constants/currency';

export type UseContractValueFilterReturn = {
  filterContractValue: (view: ViewOnContract, contractValues?: ContractValue[]) => boolean;
  isLoading: boolean;
};

export const THRESHOLD_CURRENCY = 'USD';

const useContractValueFilter = (): UseContractValueFilterReturn => {
  const { exchangeCurrency, isLoading: isLoadingExchangeCurrencies } = useExchangeCurrency();
  const { data: accountSettings, isLoading: isLoadingAccountSettings } = useAccountSettings();

  const getContractCostInThresholdCurrency = useCallback(
    (contract: Contract) => {
      const value = contract.parts.overview?.effectiveAnnualCost;
      const currency =
        contract.parts.contractual?.financial?.baseCurrency || accountSettings?.preferredCurrency || DEFAULT_CURRENCY;

      if (currency === THRESHOLD_CURRENCY) {
        return value;
      }

      return exchangeCurrency({
        value,
        sourceCurrency: currency,
        targetCurrency: THRESHOLD_CURRENCY,
      });
    },
    [accountSettings?.preferredCurrency, exchangeCurrency]
  );

  const filterContractValue = useMemo(() => {
    return getFilterForContractValue(
      getContractCostInThresholdCurrency,
      accountSettings?.verticeManagedContractThreshold
    );
  }, [getContractCostInThresholdCurrency, accountSettings?.verticeManagedContractThreshold]);

  return {
    filterContractValue,
    isLoading: isLoadingExchangeCurrencies || isLoadingAccountSettings,
  };
};

export default useContractValueFilter;
