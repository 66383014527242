import React, { FC, ReactElement, useState } from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Box, Tab, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import styles from './UserFormNotificationsTabContent.module.scss';
import NotificationsPreferences from '@vertice/core/src/modules/notifications/NotificationsPreferences';

export const MODAL_TABS = {
  TEAM_MEMBER: 'team-member',
  DIRECTORY: 'directory',
  NOTIFICATIONS: 'notifications',
};

export interface LocationWithState extends Location {
  state: {
    currentTab?: string;
  };
}

interface UserFormNotificationsTabContentProps {
  teamMemberComponent: ReactElement;
  userId: string;
}

const UserFormNotificationsTabContent: FC<UserFormNotificationsTabContentProps> = ({ teamMemberComponent, userId }) => {
  const { t } = useTranslation();
  const location = useLocation() as LocationWithState;
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(location?.state?.currentTab || MODAL_TABS.TEAM_MEMBER);

  return (
    <div className={styles['modal-tabs-container']}>
      <TabContext value={currentTab}>
        <Box>
          <TabList onChange={(e, value) => setCurrentTab(value)}>
            <Tab
              label={<Typography variant="button-m">{t('PREFERENCES.DIRECTORY.CONTACT_MODAL.TABS.USER')}</Typography>}
              value={MODAL_TABS.TEAM_MEMBER}
              className="tab-item"
              data-target={MODAL_TABS.TEAM_MEMBER}
              onClick={() => navigate(location.pathname, { state: { currentTab: MODAL_TABS.TEAM_MEMBER } })}
            />
            <Tab
              label={
                <Typography variant="button-m">
                  {t('PREFERENCES.DIRECTORY.CONTACT_MODAL.TABS.NOTIFICATIONS')}
                </Typography>
              }
              value={MODAL_TABS.NOTIFICATIONS}
              className="tab-item"
              data-target={MODAL_TABS.NOTIFICATIONS}
              onClick={() => navigate(location.pathname, { state: { currentTab: MODAL_TABS.NOTIFICATIONS } })}
            />
          </TabList>
        </Box>
        <hr className={clsx('tabs-divider', styles['modal-tabs-divider'])} />
        <TabPanel value={MODAL_TABS.TEAM_MEMBER}>{teamMemberComponent}</TabPanel>
        <TabPanel value={MODAL_TABS.NOTIFICATIONS} sx={{ px: 8 }}>
          <NotificationsPreferences userId={userId} />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default UserFormNotificationsTabContent;
