import { FC, useMemo } from 'react';
import WorkflowRenderer from '../WorkflowRenderer/WorkflowRenderer';
import {
  Definition,
  Definitions,
  ProcessDefinition,
} from '@vertice/core/src/modules/intelligentWorkflows/definitionsTypes';
import { processDefinitionToModel } from '../model/processDefinitionToModel';
import { useRequestState } from './useRequestState';

type WorkflowViewerProps = {
  definitions?: Definitions;
  requestId?: string;
  requestRoute?: string;
};

const isProcessDefinition = (definition: Definition): definition is ProcessDefinition => {
  return definition.kind === 'ProcessEngine:ProcessDefinition';
};

export const WorkflowViewer: FC<WorkflowViewerProps> = ({ definitions, requestId, requestRoute }) => {
  const state = useRequestState(requestId, requestRoute);

  const model = useMemo(() => {
    if (!definitions) {
      return undefined;
    }
    const processDefinition = definitions.definitions.find(isProcessDefinition);
    if (!processDefinition) {
      throw new Error('No process definition found');
    }
    return processDefinitionToModel(processDefinition, state);
  }, [definitions, state]);

  if (!model) {
    return null;
  }

  return <WorkflowRenderer model={model} />;
};
