import { Accordion as MuiAccordion, AccordionProps as MuiAccordionProps, accordionClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DesignSystemSize, TestProps } from '../../types';
import { testProps } from '../../utils/testProperties';
import { AccordionVariant } from './types';
import { AccordionContextProvider } from './AccordionContext';

const StyledAccordion = styled(MuiAccordion)({
  // mui adds separator between multiple accordions with ::before
  '&:before': {
    display: 'none',
  },

  [`&.${accordionClasses.root}`]: {
    backgroundColor: 'transparent',
  },

  [`&.${accordionClasses.disabled}`]: {
    backgroundColor: 'unset',
  },
});

type Props = Pick<MuiAccordionProps, 'children' | 'sx' | 'disabled' | 'defaultExpanded' | 'expanded' | 'onChange'> & {
  variant?: AccordionVariant;
  size?: DesignSystemSize;
  alwaysExpanded?: boolean;
} & TestProps;

const Accordion = ({
  variant = 'outlined',
  size = 'M',
  children,
  sx,
  alwaysExpanded,
  disabled,
  defaultExpanded,
  expanded,
  onChange,
  testId,
}: Props) => {
  return (
    <AccordionContextProvider value={{ variant, size, disabled, alwaysExpanded }}>
      <StyledAccordion
        disableGutters
        sx={sx}
        disabled={disabled || alwaysExpanded}
        defaultExpanded={defaultExpanded || alwaysExpanded}
        expanded={expanded}
        onChange={onChange}
        {...testProps(testId, 'accordion')}
      >
        {children}
      </StyledAccordion>
    </AccordionContextProvider>
  );
};

export default Accordion;
