import { Request } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { Request as LegacyRequest } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { parseRequestRef, parseUserRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { RequestRow } from './types';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useListServiceRequestsPaginatedQuery } from '@vertice/slices';
import { useCallback, useMemo } from 'react';
import { useListRequestsQuery } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

export const addRequestsRows = (
  items: Request[],
  closed: boolean,
  requests: RequestRow[],
  requestIds: Set<RequestRow['requestId']>
): [RequestRow[], Set<RequestRow['requestId']>] => {
  items
    .filter((request) => {
      return (
        Array<Request['status']>('COMPLETED', 'CANCELLED', 'TERMINATED', 'FAILED').includes(request.status) === closed
      );
    })
    .forEach((request) => {
      const { requestId } = parseRequestRef(request.ref);
      const parentRequestId = request.parentRequestRef
        ? parseRequestRef(request.parentRequestRef).requestId
        : undefined;

      requests.push({
        requestId: requestId,
        requestName: request.name,
        requestType: request.serviceName,
        createdAt: request.createdAt,
        lastActivityAt: request.updatedAt,
        closedAt: request.closedAt,
        status: request.status,
        parentId: parentRequestId,
        verticeReserved: request.verticeReserved,
        ownerId: request.owner ? parseUserRef(request.owner).userId : undefined,
      });
      requestIds.add(requestId);
    });

  return [requests, requestIds];
};

export const addLegacyRequestRows = (
  items: LegacyRequest[],
  closed: boolean,
  requests: RequestRow[],
  requestIds: Set<RequestRow['requestId']>
) => {
  items
    .filter((request) => {
      return Array<LegacyRequest['status']>('COMPLETED', 'CANCELLED', 'TERMINATED').includes(request.status) === closed;
    })
    .forEach((request) => {
      const { requestId } = parseRequestRef(request.ref);
      requests.push({
        requestId: request.contractId || requestId,
        requestName: request.name,
        createdAt: request.createdAt,
        lastActivityAt: request.lastActivityAt,
        closedAt: request.closedAt,
        status: request.status,
        isLegacy: true,
      });
      requestIds.add(requestId);
    });

  return [requests, requestIds];
};

export const useRequests = (closed: boolean) => {
  const { accountId } = useAccountContext();
  const { data: requests, isLoading: isLoadingRequests, refetch } = useListServiceRequestsPaginatedQuery({ accountId });
  const {
    data: legacyRequests,
    isLoading: isLoadingLegacyRequests,
    refetch: refetchLegacyRequests,
  } = useListRequestsQuery({ accountId });

  const isLoading = useMemo(
    () => isLoadingRequests || isLoadingLegacyRequests,
    [isLoadingRequests, isLoadingLegacyRequests]
  );
  const refetchAll = useCallback(async () => {
    await Promise.all([refetch(), refetchLegacyRequests()]);
  }, [refetch, refetchLegacyRequests]);

  const { rows, ids } = useMemo(() => {
    if (!requests?.items || !legacyRequests?.items) {
      return { rows: [], ids: new Set() };
    }

    const requestRows: RequestRow[] = [];
    const requestIds: Set<RequestRow['requestId']> = new Set();
    addRequestsRows(requests.items, closed, requestRows, requestIds);
    addLegacyRequestRows(legacyRequests.items, closed, requestRows, requestIds);

    return { rows: requestRows, ids: requestIds };
  }, [closed, requests?.items, legacyRequests?.items]);

  return {
    requests: rows,
    requestIds: ids,
    isLoadingRequests: isLoading,
    refetch: refetchAll,
  };
};
