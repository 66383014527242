import React, { FC } from 'react';
import { JumpCloudIcon } from '@vertice/assets';
import { useTranslation } from 'react-i18next';
import { INTEGRATION_ROUTES, ROUTES } from '@vertice/utils/src/constants/cfa/constants';
import UsageStatsSetupCard from '../UsageStatsSetupCard';

interface JumpCloudSetupCardProps {
  providerId: string;
}

const JumpCloudSetupCard: FC<JumpCloudSetupCardProps> = ({ providerId }) => {
  const { t } = useTranslation();

  return (
    <UsageStatsSetupCard
      providerId={providerId}
      providerName="JumpCloud"
      providerIcon={<JumpCloudIcon />}
      description={t('PREFERENCES.INTEGRATIONS.JUMPCLOUD.DESCRIPTION')}
      wizardRoute={`${ROUTES.PREFERENCES}/${INTEGRATION_ROUTES.BASE_PATH}/${INTEGRATION_ROUTES.JUMPCLOUD_SETUP}`}
    />
  );
};

export default JumpCloudSetupCard;
