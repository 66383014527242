import { FC, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ProductsList } from '@vertice/core/src/modules/saas/contract/components/ProductList';
import { FormData } from '../../PurchaseIntakeSimpleForm/schema';
import { FormDataProductItem } from '../schemas';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { useTaskFormContext } from '../TaskFormContext';

type FormProductListProps = {
  showAnnualCost?: boolean;
  noBorder?: boolean;
};

export const FormProductList: FC<FormProductListProps> = ({ showAnnualCost, noBorder }) => {
  const { setValue } = useFormContext<FormData>();
  const { readOnly } = useTaskFormContext();

  const products: FormDataProductItem[] = useWatch<FormData, 'products'>({ name: 'products' });
  const vendor = useWatch<FormData, 'vendor'>({ name: 'vendor' });

  // In some forms, e.g. the NewPurchaseForm, the currency is not present at all
  const contractCurrency: string | undefined = useWatch({ name: 'contractCurrency' });

  const setProducts = useCallback(
    (productsToSet: FormDataProductItem[]) => {
      setValue('products', productsToSet);
    },
    [setValue]
  );

  return (
    <ProductsList
      withActions={!readOnly}
      showAnnualCost={showAnnualCost}
      selectedProducts={products || []}
      setSelectedProducts={setProducts}
      currency={contractCurrency}
      vendorId={vendor?.id}
      readOnlyMode={readOnly}
      color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
      dataGridExtraProps={{
        noBorder,
        noBorderRadius: noBorder,
      }}
    />
  );
};
