import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useCallback, useState } from 'react';
import { parseRequestRef, formatUserRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { useCreateRequestMutation } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useWaitForRequestStatus } from './useWaitForRequestStatus';
import { Vendor } from '@vertice/core/src/modules/vendor/types';
import { useLoggedUser } from '@verticeone/auth/src';

export type CreateRequestParams = {
  vendor: Vendor;
};

// Inspired by packages/dashboard/src/pages/IntakeFormWizards/NewPurchaseWizard/Steps/AdditionalRequirements/useCreateNewPurchaseRequest.ts

export const useCreateNewPurchaseRequest = (): [
  (params: CreateRequestParams) => Promise<string | undefined>,
  boolean
] => {
  const { accountId } = useAccountContext();
  const { userId } = useLoggedUser();
  const [inProgress, setInProgress] = useState(false);
  const [createRequestMutation] = useCreateRequestMutation();
  const waitForRequestStage = useWaitForRequestStatus();

  const createRequest = useCallback(
    async ({ vendor }: CreateRequestParams): Promise<string | undefined> => {
      try {
        if (vendor.id === null) {
          // this should never happen as the vendor is always pre-filled
          throw new Error('Vendor is not selected');
        }

        const requestInput = {
          contractCategory: 'SAAS',
          // duplicated in order to simplify IO mapping
          vendor,
          vertice: {
            vendor,
            requestOwner: formatUserRef(userId),
            request: {
              requestor: formatUserRef(userId),
            },
          },
        };

        setInProgress(true);

        const createRequestResponse = await createRequestMutation({
          accountId: accountId!,
          createRequest: {
            sourceRef: `urn:verticeone:vertice:${accountId}:cfa:page/vendor/${vendor.id}`,
            serviceRef: `urn:verticeone:vertice:${accountId}:services:service/saas/purchase/v3`,
            name: `New Purchase - ${vendor.name}`,
            input: requestInput,
            owner: formatUserRef(userId),
          },
        });

        const requestReference = 'data' in createRequestResponse ? createRequestResponse.data?.ref : undefined;
        if (requestReference) {
          const requestId = parseRequestRef(requestReference).requestId;
          await waitForRequestStage(accountId, requestId, 'ACTIVE');
          return requestId;
        }
        return undefined;
      } finally {
        setInProgress(false);
      }
    },
    [userId, createRequestMutation, accountId, waitForRequestStage]
  );

  return [createRequest, inProgress];
};
