import React from 'react';
import { Stack } from '@mui/material';
import { EllipsisText } from '@verticeone/design-system/src/components/Text';
import { TaskIcon } from '@vertice/core/src/modules/intelligentWorkflows/sharedVisualStyle/taskStyle';
import { TaskRow } from '../../types';

export const TaskName = ({ taskRow }: { taskRow: TaskRow }) => {
  return (
    <Stack direction="row" gap={4} alignItems="center" minWidth={0}>
      <TaskIcon status={taskRow.status} assigneeIds={taskRow.assignees.map((a) => a.id)} />
      <EllipsisText variant="heading" size="S" title={taskRow.name}>
        {taskRow.name}
      </EllipsisText>
    </Stack>
  );
};
