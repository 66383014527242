import React from 'react';
import { DeleteOutline } from '@mui/icons-material';
import Button from '@verticeone/design-system/src/components/Button';
import { useContractGridContext } from '../ContractContext';
import { useTranslation } from 'react-i18next';

type DeleteProps = {
  contractId: string;
};

const DeleteContract = ({ contractId }: DeleteProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EDP_CONTRACT_GRID.BUTTONS' });
  const { actions, isEditing, isDeleting } = useContractGridContext();

  if (isEditing) {
    return null;
  }

  return (
    <Button
      size="S"
      variant="outline"
      color="neutral"
      onClick={() => actions.delete(contractId)}
      disabled={isDeleting}
      isLoading={isDeleting}
    >
      {t('DELETE')}
      <DeleteOutline />
    </Button>
  );
};

export default DeleteContract;
