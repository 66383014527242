import { GridComparatorFn, GridPreProcessEditCellProps, GridRowModel } from '@mui/x-data-grid-pro';
import { Money } from '@vertice/slices';

export const preProcessNonEmptyCell = (params: GridPreProcessEditCellProps) => {
  const hasError = !params.props.value || params.props.value === '';
  return { ...params.props, error: hasError };
};

export const valueComparator: GridComparatorFn<Money> = (v1: Money, v2: Money) => {
  if (!v1) {
    return 0;
  }
  if (v1.amount > v2.amount) {
    return 1;
  }
  if (v1.amount < v2.amount) {
    return -1;
  }
  return 0;
};

export const getRowId = (row: GridRowModel) => row.id;
