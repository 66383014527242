import { GridRenderCellParams, GridRowModes, useGridApiContext } from '@mui/x-data-grid-pro';
import { Stack } from '@mui/material';
import { GridIconButton } from '@verticeone/design-system/src/components/DataGrid';
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from '@mui/icons-material';
import React from 'react';
import { useEditableDataGridContext } from './EditableDataGridContext';
import { useTranslation } from 'react-i18next';
import { GridValidRowModel } from '@mui/x-data-grid';

export const ActionsCell = <R extends GridValidRowModel>(params: GridRenderCellParams<R, R>) => {
  const { t } = useTranslation();

  const apiRef = useGridApiContext();
  const editableGridContext = useEditableDataGridContext();
  const rowId = apiRef.current.getRowId(params.row);
  const isRowInEditMode = apiRef.current.getRowMode(rowId) === GridRowModes.Edit;
  const isRowEditable = editableGridContext.isRowEditable?.(params.row) ?? true;

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      {editableGridContext.mode === 'confirm' && isRowInEditMode ? (
        <>
          <GridIconButton
            variant="ghost"
            color="success"
            onClick={() => editableGridContext.handleRowSaveClick(rowId)}
            icon={CheckOutlined}
            aria-label={t('CORE.COMPONENTS.EDITABLE_DATA_GRID.ACTIONS.SAVE_CHANGES')}
          />
          <GridIconButton
            variant="ghost"
            color="error"
            onClick={() => editableGridContext.handleRowCancelClick(rowId)}
            icon={CloseOutlined}
            aria-label={t('CORE.COMPONENTS.EDITABLE_DATA_GRID.ACTIONS.DISCARD_CHANGES')}
          />
        </>
      ) : editableGridContext.mode === 'confirm' && !isRowInEditMode ? (
        <>
          <GridIconButton
            variant="ghost"
            onClick={() => editableGridContext.handleRowEditClick(rowId)}
            icon={EditOutlined}
            aria-label={t('CORE.COMPONENTS.EDITABLE_DATA_GRID.ACTIONS.EDIT_ROW')}
            disabled={!isRowEditable}
          />
          {(!editableGridContext.isRowDeletable || editableGridContext.isRowDeletable?.(params.row)) && (
            <GridIconButton
              variant="ghost"
              color="error"
              onClick={() => editableGridContext.handleRowDeleteClick(rowId)}
              icon={DeleteOutlined}
              aria-label={t('CORE.COMPONENTS.EDITABLE_DATA_GRID.ACTIONS.DELETE_ROW')}
              disabled={!isRowEditable}
            />
          )}
        </>
      ) : (
        // Auto-save mode
        <>
          <GridIconButton
            variant="ghost"
            onClick={() => editableGridContext.handleRowDeleteClick(rowId)}
            icon={DeleteOutlined}
            aria-label={t('CORE.COMPONENTS.EDITABLE_DATA_GRID.ACTIONS.DELETE_ROW')}
          />
        </>
      )}
    </Stack>
  );
};
