import { FormatPercentageOptions, useFormatPercentage } from '../../../utils/formatting/percentage';
import { useCallback } from 'react';

const roundUpToWholePercents = (value: number) => Math.ceil(value * 100) / 100;

/** Similar formatter to useFormatPercentage, but with presets specific to savings (e.g. rounding up) */
const useFormatSavingsPercentage = () => {
  const formatPercentage = useFormatPercentage();
  return useCallback(
    (value: number, options?: Omit<FormatPercentageOptions, 'locale'>) =>
      formatPercentage(roundUpToWholePercents(value), {
        ...options,
        // We want to force zero decimal places (we expect 0 decimal places in the rounding function)
        maximumFractionDigits: 0,
      }),
    [formatPercentage]
  );
};

export default useFormatSavingsPercentage;
