import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import {
  useListWorkflowVersionsQuery,
  useGetWorkflowVersionQuery,
} from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { WorkflowVersionRow } from './types';
import groupBy from 'lodash/groupBy';

export const useWorkflowVersions = (workflowId: string) => {
  const { accountId } = useAccountContext();

  const { data: workflowVersionsResponse, isLoading: isLoadingWorkflowVersions } = useListWorkflowVersionsQuery({
    accountId: accountId!,
    workflowId,
  });

  const workflowVersionsList = workflowVersionsResponse?.workflows || [];

  const workflowVersions = groupBy<WorkflowVersionRow>(workflowVersionsList, (workflow) => workflow.status);

  const firstActive = workflowVersions['ACTIVE']?.[0];

  const { data: activeVersionData } = useGetWorkflowVersionQuery(
    { accountId, workflowId: firstActive?.id!, workflowVersion: firstActive?.versionId! },
    { skip: !firstActive }
  );

  return {
    workflowVersions,
    isLoadingWorkflowVersions,
    activeVersion: activeVersionData,
  };
};
