import { useTranslation } from 'react-i18next';
import { useRoutes } from '@verticeone/router/src';
import Stack from '@mui/material/Stack';
import Breadcrumbs, { BreadcrumbLink } from '@verticeone/design-system/src/components/Breadcrumbs';
import { Link, Navigate } from 'react-router-dom';
import Text from '@verticeone/design-system/src/components/Text';
import React from 'react';
import CompletedContractsCard from '../cards/CompletedContractsCard/CompletedContractsCard';
import useSavingsDashboard from '../useSavingsDashboard';
import Loader from '@verticeone/design-system/src/components/Loader';
import SavingsStatsCard from '../cards/SavingsStatsCard/SavingsStatsCard';

const Savings = () => {
  const { t } = useTranslation();
  const routes = useRoutes();

  const { enabled: savingsDashboardEnabled, isLoading: savingsDashboardLoading } = useSavingsDashboard();

  if (savingsDashboardLoading) {
    return <Loader />;
  }

  if (!savingsDashboardLoading && !savingsDashboardEnabled) {
    return <Navigate to={routes.DASHBOARD.ABSOLUTE_PATH} replace />;
  }

  return (
    <Stack gap={8} py={10}>
      <Stack gap={1} px={10}>
        <Breadcrumbs variant="slash" size="S">
          <BreadcrumbLink>{t('SAAS.NAVBAR.SAAS')}</BreadcrumbLink>
          <BreadcrumbLink component={Link} to={routes.DASHBOARD.ABSOLUTE_PATH}>
            {t('SAAS.NAVBAR.DASHBOARD')}
          </BreadcrumbLink>
          <BreadcrumbLink disabled>{t('SAAS.NAVBAR.DASHBOARD_SAVINGS')}</BreadcrumbLink>
        </Breadcrumbs>
        <Text variant="heading" size="M" tag="h1">
          {t('SAAS.SAVINGS.SAVINGS_DASHBOARD')}
        </Text>
      </Stack>
      <Stack px={10} spacing={4}>
        <SavingsStatsCard />
        <CompletedContractsCard />
      </Stack>
    </Stack>
  );
};

export default Savings;
