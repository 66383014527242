import { TooltipFormatterContextObject } from 'highcharts';
import { SortTooltipPointsType } from './sortTooltipPointsUtils';
import { sumBy } from 'lodash';
import { TooltipValueFormatter } from './valueFormatters';
import { SeriesOptionsWithStates } from '../../highcharts-specific/types';

export type TooltipItem = {
  color: string;
  name: string;
  value: string;
};

const ROUND_TO_ZERO_THRESHOLD = 0.005;
const extractTooltipContent =
  (tooltipSort: SortTooltipPointsType, valueFormatter: TooltipValueFormatter) =>
  ({ points }: TooltipFormatterContextObject) => {
    const items = points
      ?.filter((point) => Math.abs(point.y!) >= ROUND_TO_ZERO_THRESHOLD)
      .sort(tooltipSort)
      .map((point, pointIndex) => {
        const { series, y } = point;
        const seriesOptions = series.options as SeriesOptionsWithStates;
        return {
          color: (seriesOptions?.states?.hover?.color ?? series.color) as string,
          name: point.series.name,
          value: valueFormatter(y),
        };
      });

    const sum = points ? sumBy(points, (point) => point.y ?? 0) : 0;
    const total = valueFormatter(Math.abs(sum) < ROUND_TO_ZERO_THRESHOLD ? 0 : sum);

    return { items, total };
  };

export default extractTooltipContent;
