import { isNil } from 'lodash';
import { ResultValueFormatter } from './types';
import { TFunction } from 'i18next';
import { formatPercentage } from '@vertice/core/src/utils/formatting/percentage';

export const valueFormatter = (value: string, type: string, locale: string, t: any) => {
  switch (type) {
    case 'percentage':
      return formatPercentage(parseFloat(value), { locale, minimumFractionDigits: 0, maximumFractionDigits: 1 });
    case 'integer':
    case 'string':
    case 'boolean':
      return genericValueFormatter(value, t);
    case 'items':
      return t('N_ITEMS', { count: parseInt(value) });
    default:
      return null;
  }
};

export const summaryFormatter: ResultValueFormatter = ({ results }, { locale, t }) => {
  if (
    results?.__typename !== 'MulticlassBinnedTestResult' &&
    results?.__typename !== 'BinnedTestResult' &&
    results?.__typename !== 'BooleanTestResult'
  ) {
    return t('NA');
  }
  if (!results?.summary) {
    return null;
  }

  return valueFormatter(results?.summary.value, results?.summary.type, locale, t);
};

export const genericValueFormatter = (value: string, t: TFunction) => {
  if (value === 'true') {
    return t('TRUE');
  }

  if (value === 'false') {
    return t('FALSE');
  }

  return value;
};

const PERCENTAGE_MAX_FRACTION_DIGITS = 1;

export const valueToPercentage: ResultValueFormatter = ({ results }, { locale, t }) => {
  if (results?.__typename !== 'BooleanTestResult' && results?.__typename !== 'BinnedTestResult') {
    return t('NA');
  }

  // Example: 1 fraction digit -> 0.05% threshold decides whether to round to 0.0% or 0.1% -> 0.0005 threshold
  const prefixThreshold = Math.pow(10, -PERCENTAGE_MAX_FRACTION_DIGITS - 2) / 2;
  const prefix = !isNil(results?.value) && parseFloat(results?.value) < prefixThreshold ? '~' : '';

  return (
    prefix +
    formatPercentage(isNil(results?.value) ? 0 : parseFloat(results?.value), {
      locale,
      minimumFractionDigits: 0,
      maximumFractionDigits: PERCENTAGE_MAX_FRACTION_DIGITS,
    })
  );
};
