import { FC } from 'react';
import InputAdornment from '@verticeone/design-system/src/components/InputAdornment/InputAdornment';
import { Clear, Search } from '@mui/icons-material';
import { styled } from '@mui/system';
import TextField, { TextFieldProps } from '@verticeone/design-system/src/components/TextField';

const StyledSearchTextField = styled(TextField)(({ theme }) => ({
  svg: { color: theme.palette.inactive.color2 },
  '.MuiInputAdornment-positionEnd': {
    svg: {
      width: 17,
    },
  },
}));

type SearchTextFieldProps = TextFieldProps & {
  onClear?: () => void;
};

export const SearchTextField: FC<SearchTextFieldProps> = ({ value, onClear, ...props }) => {
  return (
    <StyledSearchTextField
      {...props}
      value={value}
      size="S"
      variant="solid"
      hiddenLabel
      startAdornment={
        <InputAdornment position="start" size="S">
          <Search />
        </InputAdornment>
      }
      endAdornment={
        value ? (
          <InputAdornment position="end" style={{ cursor: 'pointer' }}>
            <Clear onClick={onClear} />
          </InputAdornment>
        ) : null
      }
    />
  );
};
