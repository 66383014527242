import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { Box, Stack, useTheme } from '@mui/material';

import Card from '@verticeone/design-system/src/components/Card';
import Divider from '@verticeone/design-system/src/components/Divider';
import Grid from '@verticeone/design-system/src/components/Grid';

import { SelectedVendorPanel } from '../shared/formFields/SelectedVendorPanel';
import { FormProductList } from '../shared/formFields/FormProductList';
import { FormData } from './schema';
import { CurrencyFormEntry } from '../shared/formFields/CurrencyFormEntry';
import { MoneyFormEntry } from '../shared/formFields/MoneyFormEntry';
import { useAccountSettings } from '@vertice/core/src/hooks/useAccountSettings';
import { RecurrenceFormEntry } from '../shared/formFields/RecurrenceFormEntry';
import { DateFormEntry } from '../shared/formFields/DateFormEntry';

export const CustomerNegotiationForm = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { setValue } = useFormContext<FormData>();
  const { data: accountSettings } = useAccountSettings();

  const contractCurrency = useWatch<FormData, 'contractCurrency'>({ name: 'contractCurrency' });
  const recurrence = useWatch<FormData, 'recurrence'>({ name: 'recurrence' });
  const isFixedTerm = recurrence === 'fixed-term';

  useEffect(() => {
    const defaultCurrency = accountSettings?.preferredCurrency;
    if (defaultCurrency && !contractCurrency) {
      setValue('contractCurrency', defaultCurrency);
    }
  }, [accountSettings, setValue, contractCurrency]);

  return (
    <Stack gap={2}>
      <Card>
        <Box bgcolor={palette.core.color1} p={4}>
          <SelectedVendorPanel />
        </Box>
        <Divider />
        <FormProductList noBorder showAnnualCost />
      </Card>
      <Grid container spacing={4} py={4}>
        <RecurrenceFormEntry<FormData>
          name="recurrence"
          label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.CUSTOMER_LED_NEGOTIATION.LABELS.RECURRENCE')}
        />
        <CurrencyFormEntry<FormData> name="contractCurrency" />
        <DateFormEntry<FormData>
          name="startDate"
          width={isFixedTerm ? 6 : 12}
          label={t('ENTITIES.CONTRACT.LABELS.START_DATE')}
        />
        {isFixedTerm && (
          <DateFormEntry<FormData> name="endDate" required label={t('ENTITIES.CONTRACT.LABELS.END_DATE')} />
        )}
        <MoneyFormEntry<FormData>
          name="vendorProposedCost"
          label={t(
            `INTELLIGENT_WORKFLOWS.TASK_FORMS.CUSTOMER_LED_NEGOTIATION.LABELS.BASELINE_COST_${recurrence.toUpperCase()}`
          )}
          tooltip={{ content: t('INTELLIGENT_WORKFLOWS.TASK_FORMS.CUSTOMER_LED_NEGOTIATION.TOOLTIPS.BASELINE') }}
          currency={contractCurrency}
        />
        <MoneyFormEntry<FormData>
          name="negotiatedCost"
          label={t(
            `INTELLIGENT_WORKFLOWS.TASK_FORMS.CUSTOMER_LED_NEGOTIATION.LABELS.NEGOTIATED_COST_${recurrence.toUpperCase()}`
          )}
          tooltip={{ content: t('INTELLIGENT_WORKFLOWS.TASK_FORMS.CUSTOMER_LED_NEGOTIATION.TOOLTIPS.NEGOTIATED') }}
          currency={contractCurrency}
        />
      </Grid>
    </Stack>
  );
};
