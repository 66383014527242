import ContractFormEntry from '../../ContractFormEntry';
import { useFormatDate } from '../../../../../utils/formatting/date';
import FormReadOnlyText, { FormReadOnlyTextType } from '@vertice/core/src/modules/forms/fields/FormReadOnlyText';
import FormDateField from '@vertice/core/src/modules/forms/fields/FormDateField';
import React from 'react';
import { FormDateFieldType } from '@vertice/core/src/modules/forms/fields/FormDateField';
import { ContractFormData } from '../../types';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';

type ReadComponent = FormReadOnlyTextType<ContractFormData>;
type WriteComponent = FormDateFieldType<ContractFormData>;

const AutoRenewalDateFormEntry = () => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const autoRenewalDeadline = useWatch<ContractFormData>({ name: 'parts.contractual.lifecycle.autoRenewalDeadline' });
  const autoRenewalWaived = useWatch<ContractFormData>({ name: 'parts.contractual.renewal.autoRenewalWaived' });

  const strikeThrough = autoRenewalDeadline && autoRenewalWaived;

  return (
    <ContractFormEntry<ReadComponent, WriteComponent>
      name="parts.contractual.lifecycle.autoRenewalDeadline"
      label={t('ENTITIES.CONTRACT.LABELS.AUTO_RENEWAL_DATE')}
      width={12}
      component={FormReadOnlyText}
      componentProps={{
        formatter: formatDate,
        textProps: {
          variant: 'body-regular',
          size: 'M',
          sx: { textDecoration: strikeThrough ? 'line-through' : 'none' },
        },
      }}
      writeComponent={FormDateField}
    />
  );
};

export default AutoRenewalDateFormEntry;
