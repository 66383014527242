import React, { FC } from 'react';
import { VendorPageHeaderProps } from './VendorPageHeader';
import { useCreatePurchaseRequest } from './hooks/useCreatePurchaseRequest';
import { InitiateRequestBetaDialog } from '@vertice/dashboard/src/modules/intelligentWorkflows/request/InitiateRequestBetaDialog/InitiateRequestBetaDialog';

type IWPurchaseRequestBetaModalProps = Pick<VendorPageHeaderProps, 'vendorId' | 'vendorName'> & {
  isOpened: boolean;
  onClose: () => void;
};

// Interim modal for beta customers of intelligent workflows
export const InitiatePurchaseRequestBetaDialog: FC<IWPurchaseRequestBetaModalProps> = ({
  isOpened,
  onClose,
  vendorId,
  vendorName,
}) => {
  const {
    createPurchaseRequest,
    isLoading: isCreatingIWPurchaseRequest,
    legacyIntakeForm,
  } = useCreatePurchaseRequest();

  const { newPurchaseRoute, isLoading: isIntakeFormLoading } = legacyIntakeForm;

  const handleCreateIWPurchaseRequestClick = async () => {
    await createPurchaseRequest(vendorId, vendorName);
  };

  return (
    <InitiateRequestBetaDialog
      isOpened={isOpened}
      onClose={onClose}
      isLoading={isCreatingIWPurchaseRequest}
      isDisabled={isIntakeFormLoading}
      requestType="PURCHASE"
      legacyRequestLinkRoute={newPurchaseRoute}
      onIWRequestClick={handleCreateIWPurchaseRequestClick}
    />
  );
};
