import React, { type CSSProperties } from 'react';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import type { Theme } from '@mui/material/styles';
import Text from '../../Text';
import { ifExpression } from '../../../utils/ifExpression';
import type { DesignSystemColor } from '../../../types';
import { labelSizes, type TextFieldAreaProps } from './constants';

type LabelProps = {
  showDivider?: boolean;
} & TextFieldAreaProps;

type StyledLabelProps = {
  theme: Theme;
  $color: DesignSystemColor;
  $disabled?: boolean;
};

export const getHoverState = (
  { theme, $color, $disabled }: StyledLabelProps,
  property: keyof CSSProperties = 'color'
) => ifExpression(!$disabled, { [property ?? 'color']: theme.palette[$color].hover.color1 });

export const Divider = styled(Stack)(({ theme }) => ({
  margin: '0 2px',
  backgroundColor: theme.palette.core.color5,
  height: '1px',
  width: '100%',
}));

export const StyledLabel = styled(Text)(() => ({}));

const Label = (props: LabelProps) => (
  <Stack direction="row" justifyContent="space-between" alignItems="center">
    {!!props.label && (
      <StyledLabel variant="body-regular" size={labelSizes[props.size]} color="text3">
        {props.label}
      </StyledLabel>
    )}
    {!!props.showDivider && <Divider />}
    {props.textLimit && (
      <Text
        variant="body-regular"
        size={labelSizes[props.size]}
        color={props.value && props.value.length > props.textLimit ? 'error' : 'text3'}
      >
        {props.value?.length ?? 0}/{props.textLimit}
      </Text>
    )}
  </Stack>
);

export default Label;
