import React, { FC } from 'react';
import { Stack, styled, useTheme } from '@mui/material';
import { NodeProps } from 'reactflow';
import 'reactflow/dist/style.css';
import { NodeControls } from '../NodeControls';
import { WorkflowNode, WorkflowTaskNode } from '../../../model/types';
import Text from '@verticeone/design-system/src/components/Text';
import { getNodeDimensions } from '../getNodeDimensions';
import { TaskColorVariant } from './types';
import { TaskAssignees } from './TaskAssignees';
import { TaskDate } from './TaskDate';
import { useTaskStyle, TaskIcon } from '../../../../sharedVisualStyle/taskStyle';
import { TaskVerticeAssignment } from './TaskVerticeAssignment';
import { TaskNavigateAction } from './TaskNavigateAction';

const StyledTaskNode = styled(Stack)<{
  $colorVariant: TaskColorVariant;
  $withShadow?: boolean;
  $withColoredBorder?: boolean;
}>(({ theme, $colorVariant, $withShadow, $withColoredBorder }) => ({
  backgroundColor: theme.palette.background.default,
  width: '100%',
  borderRadius: 8,
  borderWidth: 1,
  overflow: 'hidden',
  borderStyle: 'solid',
  borderColor: $withColoredBorder ? theme.palette[$colorVariant].color2 : theme.palette.core.color4,
  alignItems: 'center',
  flexDirection: 'column',
  boxShadow: $withShadow
    ? theme.palette.global.getShadow({
        // shadows are not defined for neutral and transparent colors - use core color instead
        color: $colorVariant === 'neutral' || $colorVariant === 'transparent' ? 'core' : $colorVariant,
        type: 'soft',
        depth: '1z',
        distance: '50',
      })
    : 'none',
}));

const TaskHeader = styled(Stack)<{ $colorVariant: TaskColorVariant; $invertedColors?: boolean }>(
  ({ theme, $colorVariant, $invertedColors }) => ({
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    width: '100%',
    backgroundColor: $invertedColors ? theme.palette[$colorVariant].color2 : theme.palette.background.default,
  })
);

const TaskBody = styled(Stack)<{ $colorVariant: TaskColorVariant; $invertedColors?: boolean }>(
  ({ theme, $colorVariant, $invertedColors }) => ({
    borderTop: `1px solid ${$invertedColors ? theme.palette[$colorVariant].color3 : theme.palette.core.color4}`,
    padding: theme.spacing(4),
    width: '100%',
    backgroundColor: $invertedColors ? theme.palette[$colorVariant].color2 : theme.palette.background.default,
    '&:empty': {
      display: 'none',
    },
  })
);

/*
  Task node may vary in height depending on the content,
  so here we use a wrapper with constant width and height which are used for the auto-layouting
  and the task node itself is centered inside the wrapper.
 */
export const VerticalAlignWrapper = styled(Stack)<{ kind: WorkflowNode['kind'] }>(({ kind }) => ({
  ...getNodeDimensions(kind),
  flexDirection: 'row',
  alignItems: 'center',
}));

export const TaskNodeComponent: FC<NodeProps<WorkflowTaskNode>> = (props) => {
  const { palette } = useTheme();
  const state = props.data.state;
  const taskNodeType = props.data.type;
  const { colorVariant, withShadow, withColoredBorder, invertedColors } = useTaskStyle(
    state?.status,
    state?.assigneeIds,
    taskNodeType
  );
  const textColor = invertedColors ? palette.text.color5 : palette.text.color1;

  return (
    <NodeControls
      {...props}
      content={
        <VerticalAlignWrapper kind={props.data.kind}>
          <StyledTaskNode $colorVariant={colorVariant} $withShadow={withShadow} $withColoredBorder={withColoredBorder}>
            <TaskHeader $colorVariant={colorVariant} $invertedColors={invertedColors}>
              <TaskIcon status={state?.status} type={taskNodeType} assigneeIds={state?.assigneeIds} size="XS" />
              <Text variant="heading" size="XS" color={textColor}>
                {props.data.name}
              </Text>
            </TaskHeader>
            <TaskBody $colorVariant={colorVariant} $invertedColors={invertedColors} gap={1}>
              <TaskAssignees assigneeIds={state?.assigneeIds} textColor={textColor} />
              <TaskVerticeAssignment status={state?.status} textColor={textColor} taskNodeType={taskNodeType} />
              <TaskDate state={state} textColor={textColor} />
              <TaskNavigateAction state={state} route={props.data.requestRoute} taskNodeType={taskNodeType} />
            </TaskBody>
          </StyledTaskNode>
        </VerticalAlignWrapper>
      }
    />
  );
};
