import useWindowDimensions from '@vertice/hooks/src/useWindowDimensions';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

const TABLE_MAX_HEIGHT = 800;

const useGetMaxTableHeight = (): number => {
  const { height: windowHeight } = useWindowDimensions();
  const [maxTableHeight, setMaxTableHeight] = useState<number>(TABLE_MAX_HEIGHT);
  const debounceTableHeight = useDebouncedCallback(() => {
    if (windowHeight) {
      setMaxTableHeight(Math.ceil(windowHeight / 2));
    }
  }, 300);

  useEffect(() => {
    debounceTableHeight();
  }, [debounceTableHeight, windowHeight]);

  return maxTableHeight;
};

export default useGetMaxTableHeight;
