import React, { useCallback } from 'react';
import { FileWithPath } from '@vertice/components';
import { Box, Stack, useTheme } from '@mui/material';
import { PictureAsPdfOutlined, DeleteOutlined } from '@mui/icons-material';
import Text from '@verticeone/design-system/src/components/Text';
import IconButton from '@verticeone/design-system/src/components/IconButton';
import dayjs from 'dayjs';
import { useFormContext } from 'react-hook-form';
import { formatBytes } from './formatBytes';
import { CommonIntakeFormFormData } from '../types';
import { getShortenFileName } from '@verticeone/design-system/src/components/Fileupload/utils';
import { useFormatDate } from '@vertice/core/src/utils/formatting/date';

const FilesList: React.FC = () => {
  const { palette } = useTheme();
  const formatDate = useFormatDate();
  const { watch, setValue } = useFormContext<CommonIntakeFormFormData>();
  const files = watch('files');

  const handleRemove = useCallback(
    (fileToRemove: FileWithPath) => {
      setValue(
        'files',
        files.filter((f) => f !== fileToRemove)
      );
    },
    [setValue, files]
  );

  if (files.length === 0) {
    return null;
  }

  return (
    <Stack direction="column" gap={1}>
      {files.map((file) => (
        <Stack
          direction="row"
          key={file.name + file.lastModified + file.size}
          gap={4}
          p={4}
          border={`1px solid ${palette.core.color3}`}
          borderRadius={2}
        >
          <Box display="flex" color={palette.primary.color2} borderRadius={2} bgcolor={palette.primary.color4} p={2}>
            <PictureAsPdfOutlined />
          </Box>

          <Stack direction="column" gap={1} flexGrow={1}>
            <Text variant="body-regular" size="M" color="text1">
              {getShortenFileName(file.name, 30)}
            </Text>
            <Stack direction="row" gap={2}>
              <Text variant="caption" size="XS" color="text2">
                {formatBytes(file.size)}
              </Text>
              <Text variant="caption" size="XS" color="text3">
                {formatDate(dayjs(file.lastModified).toISOString())}
              </Text>
            </Stack>
          </Stack>

          <Stack direction="column" justifyContent="center">
            <IconButton variant="outline" icon={DeleteOutlined} onClick={() => handleRemove(file)} />
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default FilesList;
