import type { StyledComponentProps } from './components/constants';
import getTextVariantStyle from '../Text/utils/getTextVariantStyle';
import { isUndefined } from 'lodash';
import { CSSProperties } from '@mui/material/styles/createMixins';

export const getTextFieldAreaStyles = ({
  theme,
  $size,
  $minHeight,
  $autosize,
}: Pick<StyledComponentProps, 'theme' | '$size' | '$minHeight' | '$autosize'>): CSSProperties => ({
  backgroundColor: 'transparent',
  color: theme.palette.text.color1,
  ...getTextVariantStyle({ variant: 'body-regular', size: $size }),
  minHeight: !isUndefined($minHeight) ? theme.spacing($minHeight) : theme.spacing(30),
  minWidth: theme.spacing(64),
  resize: !$autosize ? 'block' : 'none',
  '&::placeholder': {
    color: theme.palette.text.color3,
    opacity: 1,
  },
});
