import React from 'react';
import styled from '@mui/material/styles/styled';
import Text from '../Text';
import Stack from '@mui/material/Stack';
import { SvgIconComponent } from '@mui/icons-material';
import CircleIcon from '@mui/icons-material/Circle';

type CardHeaderSubtitleProps = {
  text: string;
  textDimmed?: string;
  icon?: SvgIconComponent;
};

export const StyledCardHeaderSubtitleIcon = styled(Stack)({});
export const StyledCardHeaderSubtitle = styled(Text)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
export const StyledCardHeaderSubtitleBullet = styled(CircleIcon)(({ theme }) => ({
  color: theme.palette.core.color3,
}));
export const StyledCardHeaderSubtitleDimmed = styled(Text)({ overflow: 'hidden', textOverflow: 'ellipsis' });

export const CardHeaderSubtitle: React.FC<CardHeaderSubtitleProps> = ({ text, textDimmed, icon: Icon }) => {
  return (
    <Stack direction="row" alignItems="center">
      {Icon && (
        <StyledCardHeaderSubtitleIcon>
          <Icon />
        </StyledCardHeaderSubtitleIcon>
      )}
      <Stack flexGrow={0} minWidth={0}>
        <StyledCardHeaderSubtitle color="text2" variant="body-regular">
          {text}
        </StyledCardHeaderSubtitle>
      </Stack>
      {textDimmed && (
        <>
          <StyledCardHeaderSubtitleBullet />
          <Stack flexGrow={0} minWidth={0}>
            <StyledCardHeaderSubtitleDimmed color="text3" variant="body-regular">
              {textDimmed}
            </StyledCardHeaderSubtitleDimmed>
          </Stack>
        </>
      )}
    </Stack>
  );
};
