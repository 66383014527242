import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import SSOSetupCard from './SSOSetupCard';
import { SCIMSetupCard } from './SCIM/SCIMSetupCard';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
const SecurityAccess = () => {
  const { t } = useTranslation();
  const { isEnabled } = useFeatures();

  return (
    <Stack direction="column" gap="24px">
      <Text variant="heading" size="S">
        {t('PREFERENCES.INTEGRATIONS.SECURITY')}
      </Text>
      <Stack direction="row" gap={4}>
        <SSOSetupCard />
        {isEnabled(FEATURES.SAML_SCIM) && isEnabled(FEATURES.SSO) && <SCIMSetupCard />}
      </Stack>
    </Stack>
  );
};

export default SecurityAccess;
