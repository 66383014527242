import { createRequiredContext } from '../../utils/contexts';
import { DialogHeader } from './DialogHeader';
import { DialogContent } from './DialogContent';
import { Dialog, DialogActions, DialogText, DialogTextWrapper } from './index';
import Button from '../Button';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { DesignSystemColor } from '../../types';
import { useTranslation } from 'react-i18next';
import i18n from '../../translate';

type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: T[SubKey] };

type SimpleDialogButtonConfig = {
  label: string;
  color: DesignSystemColor;
};

type SimpleDialogConfig = {
  title: string;
  description?: ReactNode;
  okButton: SimpleDialogButtonConfig;
  cancelButton: SimpleDialogButtonConfig;
};

export type SimpleDialogStatus = 'ok' | 'cancel';

export type SimpleDialogResult = {
  status: SimpleDialogStatus;
};

type SimpleDialogInstance = {
  config: SimpleDialogConfig;
  resolve: (result: SimpleDialogResult) => void;
};

type SimpleDialogButtonConfigInput = MakeOptional<SimpleDialogButtonConfig, 'label' | 'color'>;

export type SimpleDialogConfigInput = Omit<SimpleDialogConfig, 'okButton' | 'cancelButton'> & {
  okButton?: SimpleDialogButtonConfigInput;
  cancelButton?: SimpleDialogButtonConfigInput;
};

export type SimpleDialogContextData = {
  getConfirmation: (config: SimpleDialogConfigInput) => Promise<SimpleDialogResult>;
};

const { SimpleDialogContextProvider: SimpleDialogContextProviderRaw, useSimpleDialogContext } = createRequiredContext<
  SimpleDialogContextData,
  'SimpleDialog'
>('SimpleDialog');

export { useSimpleDialogContext };

const getBestVariantForColor = (color: DesignSystemColor) => (color === 'neutral' ? 'outline' : 'solid');

export const SimpleDialogContextProvider = ({ children }: { children: ReactNode }) => {
  const [openedDialog, setOpenedDialog] = useState<SimpleDialogInstance | null>(null);
  const { t } = useTranslation(undefined, { i18n });

  const getConfirmation = useCallback(
    async ({
      title,
      description,
      okButton: { label: okLabel = t('DIALOG.BUTTONS.OK'), color: okColor = 'primary' } = {},
      cancelButton: { label: cancelLabel = t('DIALOG.BUTTONS.CANCEL'), color: cancelColor = 'neutral' } = {},
    }: SimpleDialogConfigInput): Promise<SimpleDialogResult> => {
      if (openedDialog) {
        openedDialog.resolve({ status: 'cancel' });
        setOpenedDialog(null);
      }
      return new Promise<SimpleDialogResult>((resolve) => {
        setOpenedDialog({
          config: {
            title,
            description,
            okButton: { label: okLabel, color: okColor },
            cancelButton: { label: cancelLabel, color: cancelColor },
          },
          resolve,
        });
      });
    },
    [openedDialog, t]
  );

  const getHandleButtonClick = (status: SimpleDialogStatus) => () => {
    if (openedDialog) {
      openedDialog.resolve({ status });
      setOpenedDialog(null);
    }
  };

  const contextValue = useMemo((): SimpleDialogContextData => ({ getConfirmation }), [getConfirmation]);

  return (
    <>
      {openedDialog && (
        <Dialog size="M" open={true} align="center">
          <DialogHeader>{openedDialog.config.title}</DialogHeader>
          <DialogContent>
            <DialogTextWrapper>
              {typeof openedDialog.config.description === 'string' ? (
                <DialogText variant="body-regular">{openedDialog.config.description}</DialogText>
              ) : (
                openedDialog.config.description
              )}
            </DialogTextWrapper>
          </DialogContent>
          <DialogActions>
            {(
              [
                ['cancelButton', 'cancel'],
                ['okButton', 'ok'],
              ] as const
            ).map(([buttonKey, status]) => (
              <Button
                key={buttonKey}
                onClick={getHandleButtonClick(status)}
                variant={getBestVariantForColor(openedDialog.config[buttonKey].color)}
                color={openedDialog.config[buttonKey].color}
              >
                {openedDialog.config[buttonKey].label}
              </Button>
            ))}
          </DialogActions>
        </Dialog>
      )}
      <SimpleDialogContextProviderRaw value={contextValue}>{children}</SimpleDialogContextProviderRaw>
    </>
  );
};
