import React from 'react';
import { uniq } from 'lodash';
import { ErrorCode } from 'react-dropzone';
import ErrorOutlineIcon from '@mui/icons-material/WarningAmberOutlined';
import Alert from '../Alert/Alert';
import type { DesignSystemSize } from '../../types';
import { useFileupload } from './Fileupload';
import { useGetErrorMessage } from './hooks';

type ErrorMessageListProps = {
  size?: DesignSystemSize;
};

const ErrorMessageList = ({ size: customSize }: ErrorMessageListProps) => {
  const { rejectedFiles, size: parentSize, customErrorCodes } = useFileupload();
  const getErrorMessage = useGetErrorMessage();
  const size = customSize || parentSize || 'XL';

  const getErrorMessages = () => {
    const errorCodes = uniq(rejectedFiles.map((file) => file.errors.map((error) => error.code)).flat());
    const nonCustomErrorCodes = errorCodes.filter((code) => !customErrorCodes || !customErrorCodes.includes(code));
    const errorMessages = (nonCustomErrorCodes as Array<ErrorCode>).map(getErrorMessage);

    //Add custom error messages
    if (customErrorCodes && errorCodes.some((code) => customErrorCodes.includes(code))) {
      rejectedFiles.forEach((file) => {
        file.errors.forEach((error) => {
          if (customErrorCodes.includes(error.code)) {
            errorMessages.push(error.message);
          }
        });
      });
    }

    return errorMessages;
  };

  if (!rejectedFiles.length) {
    return null;
  }

  return (
    <>
      {getErrorMessages().map((message) => (
        <Alert key={message} size={size} color="error" variant="ghost" title={message} icon={ErrorOutlineIcon} />
      ))}
    </>
  );
};

export default ErrorMessageList;
