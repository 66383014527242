import { FC } from 'react';

import Card from '@verticeone/design-system/src/components/Card';
import Placeholder from '@verticeone/design-system/src/components/Placeholder';

export const TaskCardPlaceholder: FC = () => {
  return (
    <Card>
      <Placeholder width="100%" height={80} />
    </Card>
  );
};
