import React from 'react';
import { useTranslation } from 'react-i18next';
import { IntegrationFail } from '@vertice/assets';
import Button from '@verticeone/design-system/src/components/Button';
import { STEPS, ConnectionFinishedStepProps } from './common';
import IntegrationStatus from '../../components/IntegrationStatus';
import WizardStepTemplate from '../../components/WizardStepTemplate';

export const ConnectionErrorStep = ({ setStep, setTestingInProgress }: ConnectionFinishedStepProps) => {
  const { t } = useTranslation();

  const onBack = () => {
    setTestingInProgress(false);
    setStep(STEPS.COLLECT_API_KEYS);
  };

  return (
    <WizardStepTemplate
      content={
        <IntegrationStatus
          icon={<IntegrationFail />}
          title={t('PREFERENCES.INTEGRATIONS.CONNECTION_ERROR')}
          text={t('PREFERENCES.INTEGRATIONS.ONELOGIN.USAGE.CONNECTION_ERROR_INFO')}
        />
      }
      buttons={[
        <Button variant="outline" size="S" isCaption onClick={onBack}>
          {t('COMMON.BACK')}
        </Button>,
        <Button variant="solid" color="primary" size="S" isCaption disabled>
          {t('COMMON.FINISH')}
        </Button>,
      ]}
      scrollable={false}
    />
  );
};
