import React, { useMemo } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import {
  monthFormatter,
  yLabelCurrencyFormatter,
} from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import { useChartRef } from '@vertice/core/src/components/charts/highcharts-specific/utils/useChartRef';
import { useFormatCurrency } from '@vertice/core/src/utils/formatting/currency';
import TooltipSeriesValuePair from '@vertice/core/src/components/charts/components/Tooltip/TooltipSeriesValuePair';
import { AWS_DEFAULT_CURRENCY_DECIMAL_PLACES } from '@vertice/dashboard/src/modules/cloud/constants';
import { type SavingsData } from './useSavingsData';

type SavingsGraphProps = {
  data: SavingsData;
};

const SavingsGraph = ({ data }: SavingsGraphProps) => {
  const [chart, setChartRef] = useChartRef();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { palette } = useTheme();
  const { locale } = useLocaleContext();
  const formatCurrency = useFormatCurrency();
  const applyXAxisOffset = useXAxisOffset();
  const applyStyledHighcharts = useStyledHighcharts();

  const options = useMemo(
    () =>
      buildOptions([
        applyStyledHighcharts,
        applyXAxisOffset,
        mergeOptions({
          chart: { type: 'column' },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
                states: {
                  hover: { fillColor: palette.core.color6 },
                },
              },
            },
          },
          yAxis: {
            labels: { formatter: yLabelCurrencyFormatter(palette, locale, '$') },
          },
          xAxis: {
            categories: data.map((item) => item.dateStart),
            labels: { formatter: monthFormatter },
          },
          tooltip: { shared: true, outside: true },
          series: [
            {
              name: t('EDP_SAVINGS.TOOLTIP'),
              type: 'column',
              color: palette.visualization.divergent.tertiary['+20'],
              states: {
                hover: { color: palette.visualization.divergent.tertiary['+50'] },
              },
              point: {
                events: {
                  mouseOver() {
                    const point = this;
                    point.series.points.forEach((p) => {
                      if (p !== point) {
                        p.update({ color: palette.visualization.divergent.tertiary['+10'] }, false);
                      }
                    });
                  },
                  mouseOut(e) {
                    const point = this;
                    point.series.points.forEach((p) => {
                      if (p !== point) {
                        p.update({ color: palette.visualization.divergent.tertiary['+20'] }, false);
                      }
                    });
                  },
                },
              },
              borderRadius: 8,
              data: data.map((item) => item?.data?.total_edp_discount ?? 0),
            },
          ],
        }),
      ]),
    [applyStyledHighcharts, applyXAxisOffset, palette, locale, data, t]
  );

  return (
    <Stack gap={1.5}>
      <HighchartsReact highcharts={Highcharts} options={options} callback={setChartRef} />
      <HighchartTooltip chart={chart}>
        {({ points }) => (
          <TooltipWrapper>
            {points?.map((point) => (
              <TooltipSeriesValuePair
                seriesName={point.series.name}
                seriesColor={palette.visualization.divergent.tertiary['+50']}
                value={formatCurrency(point.y!, {
                  maximumFractionDigits: AWS_DEFAULT_CURRENCY_DECIMAL_PLACES,
                })}
                key={point.series.name}
              />
            ))}
          </TooltipWrapper>
        )}
      </HighchartTooltip>
    </Stack>
  );
};

export default SavingsGraph;
