import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import Highcharts, { Chart as HighchartsChart } from 'highcharts';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { useGetColoredItems } from '@vertice/core/src/components/charts/highcharts-specific/utils/seriesUtils';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import { ChartPortal, fromChartPortal } from '@vertice/core/src/components/charts/components/ChartPortal';
import { Stack } from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import Text from '@verticeone/design-system/src/components/Text';
import { keyBy, sumBy } from 'lodash';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import { useTranslation } from 'react-i18next';
import { EC2ProcessorUsageData } from './useEC2ProcessorUsageData';
import { AWS_DEFAULT_CURRENCY, AWS_DEFAULT_CURRENCY_DECIMAL_PLACES } from '../../constants';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import TooltipSeriesValuePair from '@vertice/core/src/components/charts/components/Tooltip/TooltipSeriesValuePair';
import { useFormatCurrency } from '@vertice/core/src/utils/formatting/currency';

type EC2ProcessorUsageGraphProps = { data: EC2ProcessorUsageData };

const graphId = 'ec2ProcessorUsageGraph';

const extractLegendItems = (chart: HighchartsChart) =>
  chart.series[0].points.map(({ name, y, color }) => ({ id: name, color: color as string, label: name }));

const EC2ProcessorUsageGraph = ({ data }: EC2ProcessorUsageGraphProps) => {
  const [chart, setChart] = useState<HighchartsChart | null>(null);
  const saveChartRef = useCallback((ch: HighchartsChart) => setChart(ch), []);
  const applyStyledHighcharts = useStyledHighcharts();
  const getColoredSeries = useGetColoredItems();
  const [, rerender] = useState({});
  const totalPositive = useMemo(() => sumBy(data, ({ normalizedUsageQuantity }) => normalizedUsageQuantity), [data]);
  const rowsByName = useMemo(() => keyBy(data, ({ name }) => name), [data]);
  const points = useMemo(
    () => data.map(({ name, normalizedUsageQuantity }) => ({ name, y: normalizedUsageQuantity })),
    [data]
  );
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EC2_PROCESSOR_USAGE' });
  const { locale } = useLocaleContext();
  const formatCurrency = useFormatCurrency();

  const options = useMemo(() => {
    return buildOptions([
      applyStyledHighcharts,
      mergeOptions({
        chart: {
          type: 'pie',
          plotBackgroundColor: 'transparent',
          backgroundColor: 'transparent',
          plotBorderWidth: 0,
          height: 366,
          spacing: [24, 0, 24, 0],
        },
        plotOptions: {
          pie: { innerSize: '80%' },
          series: {
            dataLabels: {
              formatter: function () {
                return fromChartPortal({ chart, portalKey: `${(this as any).key}-${graphId}` });
              },
            },
          },
        },
        series: [
          {
            type: 'pie',
            data: getColoredSeries(points) ?? [],
            dataLabels: {
              enabled: true,
              crop: false,
              y: -10,
              useHTML: true,
              connectorPadding: 5,
              distance: 40,
              padding: 5,
              style: {
                textAlign: 'center',
              },
            },
          },
        ],
      }),
    ]);
  }, [applyStyledHighcharts, getColoredSeries, points, chart]);

  useLayoutEffect(() => {
    // Wait for initial Highchart render (possibly with empty div portal targets)
    if (chart) {
      // Force React render portals into Highchart
      rerender({});
      // Wait for React render to finish
      requestAnimationFrame(() => {
        // Position the React-rendered elements
        chart.series.forEach((ser) => (ser as any).redraw());
      });
    }
  }, [chart, options]);

  return (
    <Stack>
      <HighchartsReact highcharts={Highcharts} options={options} callback={saveChartRef} />
      <Legend items={chart ? extractLegendItems(chart) : []} />
      {totalPositive
        ? data?.map((row) => (
            <ChartPortal chart={chart} portalKey={`${row.name}-${graphId}`} key={`${row.name}-${graphId}`}>
              <Stack direction="column" alignItems="center">
                <Text variant="body-bold" size="S" color="text2">
                  {row.name}
                </Text>
                <Text variant="heading" size="XS" color="text1">
                  {Math.round((row.normalizedUsageQuantity / totalPositive) * 100)}%
                </Text>
              </Stack>
            </ChartPortal>
          ))
        : null}
      <HighchartTooltip chart={chart}>
        {({ point }) => {
          const row = rowsByName[point.name];
          return (
            <TooltipWrapper>
              <TooltipSeriesValuePair
                seriesName={t('TOOLTIP.NORMALIZED_HOURS')}
                value={new Intl.NumberFormat(locale, { maximumFractionDigits: 0 }).format(row.normalizedUsageQuantity)}
              />
              <TooltipSeriesValuePair
                seriesName={t('TOOLTIP.COST')}
                value={formatCurrency(row.cost, {
                  currency: AWS_DEFAULT_CURRENCY,
                  maximumFractionDigits: AWS_DEFAULT_CURRENCY_DECIMAL_PLACES,
                })}
              />
              <TooltipSeriesValuePair
                seriesName={t('TOOLTIP.EFFECTIVE_HOURLY_RATE')}
                value={
                  row.normalizedUsageQuantity === 0
                    ? t('TOOLTIP.NA')
                    : formatCurrency(row.cost / row.normalizedUsageQuantity, {
                        currency: AWS_DEFAULT_CURRENCY,
                        maximumFractionDigits: 3,
                      })
                }
              />
            </TooltipWrapper>
          );
        }}
      </HighchartTooltip>
    </Stack>
  );
};

export default EC2ProcessorUsageGraph;
