import React from 'react';

export type PatternColorSquareProps = {
  pattern: {
    path: { d: string; strokeWidth: number };
    width: number;
    height: number;
    color: string;
  };
};

const PatternColorSquare = ({ pattern }: PatternColorSquareProps) => {
  const svgPattern = `<svg xmlns='http://www.w3.org/2000/svg' width='${pattern.width}' height='${pattern.height}'><path d='${pattern.path.d}' stroke='${pattern.color}' strokeWidth='${pattern.path.strokeWidth}' fill='none' /></svg>`;
  const encodedSvgPattern = encodeURIComponent(svgPattern);

  return (
    <div
      style={{
        width: 14,
        height: 14,
        backgroundImage: `url("data:image/svg+xml;utf8,${encodedSvgPattern}")`,
        backgroundRepeat: 'repeat',
        borderRadius: 4,
      }}
    />
  );
};

export default PatternColorSquare;
