import React from 'react';
import { useTranslation } from 'react-i18next';
import useCloudWatchFeaturesBySpendData from './useCloudWatchFeaturesBySpendData';
import CloudWatchFeaturesBySpendTable from './CloudWatchFeaturesBySpendTable';
import { LoadableContentWrapper } from '../../components/LoadableContentWrapper';
import Card, { CardHeader, CardHeaderSubtitle, CardHeaderTitle } from '@verticeone/design-system/src/components/Card';
import { CalendarTodayOutlined } from '@mui/icons-material';

const CloudWatchFeaturesBySpendCard = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { data, ...loadingStatus } = useCloudWatchFeaturesBySpendData();
  return (
    <Card sx={{ position: 'relative', height: '100%', minHeight: 480, minWidth: 485 }}>
      <CardHeader size="S">
        <CardHeaderTitle text={t('TOP_CLOUD_WATCH_FEATURES_BY_SPEND.TITLE')} tag="h2" />
        <CardHeaderSubtitle text={t('LAST_MONTH')} icon={CalendarTodayOutlined} />
      </CardHeader>
      <LoadableContentWrapper {...loadingStatus} sx={{ flex: 1 }}>
        <CloudWatchFeaturesBySpendTable data={data!} />
      </LoadableContentWrapper>
    </Card>
  );
};

export default CloudWatchFeaturesBySpendCard;
