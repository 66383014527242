import React from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarTodayOutlined } from '@mui/icons-material';

import Card, {
  CardHeader,
  CardHeaderActions,
  CardHeaderDescription,
  CardHeaderSubtitle,
  CardHeaderTitle,
} from '@verticeone/design-system/src/components/Card';
import RioTableDataDownloadButton from './RioTableDataDownloadButton';
import { useRecommendationData } from './RecommendationProvider';

type ShowType = 'title' | 'subtitle' | 'description';

type GenericCardProps = {
  translationKey: string;
  children: React.ReactNode;
  show: ShowType[];
};

const GenericCard = ({ children, translationKey, show = ['title', 'subtitle', 'description'] }: GenericCardProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: translationKey });
  const { item } = useRecommendationData();

  const testCode = item.testResults[0].code;

  return (
    <Card>
      <CardHeader size="S">
        {show?.includes('title') && <CardHeaderTitle text={t('TITLE')} tag="h2" />}
        {show?.includes('subtitle') && <CardHeaderSubtitle text={t('SUBTITLE')} icon={CalendarTodayOutlined} />}
        {show?.includes('description') && <CardHeaderDescription>{t('DESCRIPTION')}</CardHeaderDescription>}
        {testCode && (
          <CardHeaderActions>
            <RioTableDataDownloadButton code={testCode!} />
          </CardHeaderActions>
        )}
      </CardHeader>
      {children}
    </Card>
  );
};

export default GenericCard;
