import { DeepPartial, useForm, UseFormReturn } from 'react-hook-form';
import { ContractFormData, FetchedContract, FetchedDataTypeFromMode, FieldsConfig, FormSupportedMode } from '../types';
import { contractFieldsRulesResolver } from '../utils/contractFieldsRulesResolver';
import { getBaseTranslatedErrorMap } from '../baseTranslatedErrorMap';
import { merge } from 'lodash';
import { fetchedContractToForm } from '../transformations';
import { useTranslation } from 'react-i18next';

export type UseContractHookFormParams<Mode extends FormSupportedMode> = {
  fetchedContract: FetchedDataTypeFromMode<Mode>;
  defaultFormDataOverrides?: DeepPartial<ContractFormData>;
  fieldsConfig: FieldsConfig<Mode>;
};

const makeDefaults = (
  fetchedContract?: FetchedContract,
  defaultFormDataOverrides?: DeepPartial<ContractFormData>
): ContractFormData =>
  merge<ContractFormData, DeepPartial<ContractFormData> | undefined>(
    fetchedContract
      ? fetchedContractToForm(fetchedContract)
      : // Minimal version of the form data
        {
          record: {},
          parts: {
            model: { cost: {} },
            contractual: {
              vendor: { vendorOption: null },
            },
            overview: {},
          },
          additionalDetails: {},
          classification: {},
          workflow: {},
          customFields: {},
        },
    defaultFormDataOverrides
  );

const useContractHookForm = <Mode extends FormSupportedMode>({
  fetchedContract,
  fieldsConfig,
  defaultFormDataOverrides,
}: UseContractHookFormParams<Mode>): UseFormReturn<ContractFormData> => {
  const { t } = useTranslation();
  return useForm<ContractFormData>({
    defaultValues: makeDefaults(fetchedContract, defaultFormDataOverrides),

    mode: 'all', // Validate on change, blur, submit
    // This enables validation + error messages for WRITABLE fields according to our fieldsConfig
    resolver: contractFieldsRulesResolver(fieldsConfig.getRules, fetchedContract, {
      zodSchemaOptions: { errorMap: getBaseTranslatedErrorMap(t) },

      // Uncomment to print all validation errors to console
      // debugErrors: true,
    }),
  });
};

export default useContractHookForm;
