import sleep from '@vertice/core/src/utils/async/sleep';
import { useLazyGetRequestQuery, Request } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useMemo } from 'react';

export const useWaitForRequestStatus = (delay = 2000, maxAttempts = 20) => {
  const [getRequest] = useLazyGetRequestQuery();

  return useMemo(
    () => async (accountId: string, requestId: string, awaitedStage?: Request['status']) => {
      let stageFound = false;
      let attempts = 0;
      do {
        await sleep(delay);
        stageFound = await getRequest({ accountId, requestId })
          .unwrap()
          .then((response) =>
            // if awaitedStage is not provided, we just check if the stage is present
            awaitedStage ? response.request?.status === awaitedStage : !!response.request?.status
          );
        attempts++;
      } while (!stageFound && attempts < maxAttempts);
      return stageFound;
    },
    [delay, getRequest, maxAttempts]
  );
};
