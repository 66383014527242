import React, { forwardRef } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import timezones from '@vertice/utils/src/timezones/timezones.json';

interface TimezoneAutocompleteProps {
  value?: string;
  onChange: (value: string | null) => void;
}

const TimezoneAutocomplete = forwardRef<HTMLInputElement, TimezoneAutocompleteProps>(({ value, onChange }, ref) => {
  const { t } = useTranslation(['timezones']);

  return (
    <Autocomplete
      value={value ? timezones.find((timezone) => timezone.value === value) : null}
      options={timezones}
      getOptionLabel={(option) => `(${option.offsetText}) - ${t(`TIMEZONE.${option.value}`)}`}
      renderInput={(params) => <TextField inputRef={ref} {...params} variant="outlined" />}
      onChange={(_, data) => onChange(data?.value || null)}
      data-target="timeZone"
    />
  );
});

export default TimezoneAutocomplete;
