import React from 'react';
import { Box, Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Text from '@verticeone/design-system/src/components/Text';
import Breadcrumbs, { BreadcrumbLink } from '@verticeone/design-system/src/components/Breadcrumbs';
import HighChartRounded from 'highcharts-rounded-corners';
import Highcharts from 'highcharts';
import Tabs from '@verticeone/design-system/src/components/Tabs';
import Tab from '@verticeone/design-system/src/components/Tab';
import { generatePath, Navigate, useNavigate, useParams } from 'react-router-dom';
import CloudInsightsSpendTab from './tabs/CloudInsightsSpendTab';
import CloudInsightsUsageTab from './tabs/CloudInsightsUsageTab';
import LoadableAWSPageWrapper from '../LoadableAWSPageWrapper';
import toConstantCase from '@vertice/core/src/utils/strings/toConstantCase';
import { CloudSubFeature } from '../../../modules/cloud/hooks/useCloudFeature';
import { ROUTES } from '@vertice/utils/src/constants/cfa/constants';
import PageNotFound from '@vertice/core/src/modules/notFound/PageNotFound';
import { useCloudContext } from '../../../modules/cloud/CloudContext';
import TabsBottomLineWrapper from '@verticeone/design-system/src/components/Tabs/TabsBottomLineWrapper';
import { AWS_BRAND_COLOR } from '../../../modules/cloud/constants';

// Enable rounded corners plugin for all HighCharts on the page
HighChartRounded(Highcharts);

type TabDef = {
  id: string;
  component: React.ComponentType;

  /**
   * If provided, tab is visible only if this function returns true.
   * If not provided, tab is always visible.
   * @param subFeatures Info about what sub-features are enabled/disabled. Can be useful to make the decision.
   */
  getIsTabVisible?: (subFeatures: Record<CloudSubFeature, boolean | string>) => boolean;
};

const TABS: TabDef[] = [
  {
    id: 'spend',
    component: CloudInsightsSpendTab,
  },
  {
    id: 'usage',
    component: CloudInsightsUsageTab,
    getIsTabVisible: ({ activeSpendAudit }) => !activeSpendAudit,
  },
];

const getTabLabelKey = (tabId: string) => `CLOUD.CLOUD_INSIGHTS.${toConstantCase(tabId)}_TAB`;

const Content = () => {
  const { subFeatures } = useCloudContext();
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { activeTab: activeTabId } = useParams();
  const navigate = useNavigate();

  const visibleTabs = TABS.filter(({ getIsTabVisible }) => (getIsTabVisible ? getIsTabVisible(subFeatures) : true));
  if (visibleTabs.length === 0) {
    return <PageNotFound />;
  }

  const activeTab = visibleTabs.find(({ id }) => id === activeTabId ?? '');
  if (!activeTab) {
    return <Navigate replace={true} to={generatePath(ROUTES.CLOUD_INSIGHTS_TAB, { activeTab: visibleTabs[0].id })} />;
  }

  return (
    <Stack gap={8} py={10}>
      <Stack gap={1} px={10}>
        <Breadcrumbs variant="slash" size="S">
          <BreadcrumbLink>{t('CLOUD.NAVBAR.CLOUD')}</BreadcrumbLink>
          <BreadcrumbLink>{t('CLOUD.CLOUD_INSIGHTS.TITLE')}</BreadcrumbLink>
          <BreadcrumbLink disabled>{t(getTabLabelKey(activeTab.id))}</BreadcrumbLink>
        </Breadcrumbs>
        <Text variant="heading" size="M" color={palette.text.color1} tag="h1">
          {t('CLOUD.CLOUD_INSIGHTS.TITLE')}
        </Text>
      </Stack>

      <TabsBottomLineWrapper paddingX={10}>
        <Tabs
          variant="outlined"
          value={activeTab.id}
          onChange={(_, value) => navigate(`../${value}`, { relative: 'path' })}
          color={AWS_BRAND_COLOR}
        >
          {visibleTabs.map(({ id }) => (
            <Tab key={id} value={id} label={t(getTabLabelKey(id))} />
          ))}
        </Tabs>
      </TabsBottomLineWrapper>

      <Box px={10} position="relative">
        <activeTab.component />
      </Box>
    </Stack>
  );
};

const CloudInsights = () => (
  <LoadableAWSPageWrapper>
    <Content />
  </LoadableAWSPageWrapper>
);

export default CloudInsights;
