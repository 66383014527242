import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { useSnackbar } from 'notistack';
import {
  useConnectAccountIntegrationMutation,
  useGetAccountIntegrationQuery,
  useInitiateOauth2AccountIntegrationMutation,
  useTestAccountIntegrationMutation,
  useUpdateSlackIntegrationConfigMutation,
} from '@vertice/slices';
import { SlackConnection } from '@vertice/assets';
import Text from '@verticeone/design-system/src/components/Text';
import Button from '@verticeone/design-system/src/components/Button';
import IconWrapper from '@verticeone/design-system/src/components/IconWrapper';
import Card, { CardHeader, CardHeaderTitle } from '@verticeone/design-system/src/components/Card';
import { useActivateIntegrationAndProvider } from '../../../../util';
import {
  StepProps,
  SLACK_INTEGRATION_ID,
  STEPS,
  isValidResponse,
  SLACK_DATA_SOURCE_CODES,
  VERTICE_INTEGRATION_ID,
} from '../../../common';
import {
  DataSourceStatus,
  useUpdateDataSourceMutation,
} from '@vertice/slices/src/graphql/insight/generated/insightGraphQL';

export const InitialStep = ({ setStep, accountId }: StepProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [pollingInterval, setPollingInterval] = useState(0);
  const [connectionWindow, setConnectionWindow] = useState<Window | null>(null);
  const [isConnectionInitiated, setIsConnectionInitiated] = useState(false);
  const [isConnectionExecuted, setIsConnectionExecuted] = useState(false);
  const [isError, setIsError] = useState(false);

  const [updateDataSource] = useUpdateDataSourceMutation();
  const [connectAccountIntegrationMutation] = useConnectAccountIntegrationMutation();
  const [initiateOauth2AccountIntegration] = useInitiateOauth2AccountIntegrationMutation();
  const [testAccountIntegrationMutation] = useTestAccountIntegrationMutation();
  const [enableAutoConnection] = useUpdateSlackIntegrationConfigMutation();

  const { data: integrationData } = useGetAccountIntegrationQuery(
    {
      accountId,
      integrationId: SLACK_INTEGRATION_ID,
    },
    { pollingInterval: pollingInterval > 0 ? pollingInterval : undefined }
  );
  const { activate: activateSlackProvider } = useActivateIntegrationAndProvider(
    SLACK_INTEGRATION_ID,
    SLACK_INTEGRATION_ID,
    accountId
  );
  const { activate: activateVerticeProvider } = useActivateIntegrationAndProvider(
    VERTICE_INTEGRATION_ID,
    VERTICE_INTEGRATION_ID,
    accountId
  );

  const closeConnectionWindow = (window: Window | null) => {
    if (window) {
      window.close();
    }
  };

  const activateDataSource = async () => {
    const dataSourceCodeUpdates = SLACK_DATA_SOURCE_CODES.map((code) =>
      updateDataSource({
        accountId,
        dataSourceCode: code,
        input: {
          status: DataSourceStatus.Active,
        },
      })
    );

    await Promise.all(dataSourceCodeUpdates).then(() => connectIntegration());
  };

  const connectIntegration = async () => {
    setIsConnectionInitiated(true);

    await connectAccountIntegrationMutation({
      accountId,
      integrationId: SLACK_INTEGRATION_ID,
      integrationConnectionInput: {
        parameters: {
          secret: {},
        },
      },
    });

    const response = await initiateOauth2AccountIntegration({
      accountId,
      integrationId: SLACK_INTEGRATION_ID,
    });

    if (isValidResponse(response)) {
      const windowReference = window.open(undefined, '_blank');

      if (windowReference) {
        windowReference.location = response.data.authorizationUrl ? response.data.authorizationUrl : '';
        setConnectionWindow(windowReference);
      } else {
        enqueueSnackbar(t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.OPENING_WINDOW_NOT_ALLOWED'), {
          variant: 'error',
        });
      }
    } else {
      setIsError(true);
    }
  };

  useEffect(() => {
    setPollingInterval(isConnectionInitiated ? 1000 : 0);

    if (isConnectionInitiated) {
      const auth2ConnectionStatus = integrationData?.parameters?.OAuth2Status;
      const connectionStatus = integrationData?.connection?.status;

      if (connectionStatus === 'OK') {
        setStep(STEPS.CONNECTION);
        closeConnectionWindow(connectionWindow);
        setIsConnectionInitiated(false);
        void activateSlackProvider();
        void activateVerticeProvider();
        void enableAutoConnection({ accountId, body: { automaticallyConnectMatchedUsers: true } });
      }

      if (auth2ConnectionStatus === 'ERROR' || connectionStatus === 'FAILED') {
        setIsError(true);
        closeConnectionWindow(connectionWindow);
        setIsConnectionInitiated(false);
      }

      if (auth2ConnectionStatus === 'AUTHORIZED' && !isConnectionExecuted) {
        void testAccountIntegrationMutation({
          accountId,
          integrationId: SLACK_INTEGRATION_ID,
          testName: 'connection',
        });
        setIsConnectionExecuted(true);
        closeConnectionWindow(connectionWindow);
      }
    }
  }, [
    accountId,
    connectionWindow,
    integrationData?.connection?.status,
    integrationData?.parameters?.OAuth2Status,
    setStep,
    testAccountIntegrationMutation,
    isConnectionInitiated,
    isConnectionExecuted,
    activateSlackProvider,
    activateVerticeProvider,
    enableAutoConnection,
  ]);

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle text={t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CARD_HEADER_TITLE')} />
      </CardHeader>
      <Stack direction="row" gap={8} p={6}>
        <IconWrapper icon={SlackConnection} />
        <Stack gap={4}>
          <Text variant="body-bold" size="M" color="text2">
            {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CARD_CONTENT_TITLE')}
          </Text>
          <Text variant="body-regular" size="M" color="text2">
            {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CARD_CONTENT_TEXT')}
          </Text>
          <Stack gap={1}>
            <Button
              variant="ghost"
              size="M"
              color="primary"
              sx={{ alignSelf: 'flex-start', mt: 4 }}
              onClick={activateDataSource}
              isLoading={isConnectionInitiated}
            >
              {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CARD_CONTENT_BUTTON')}
            </Button>
            {!isConnectionInitiated && isError && (
              <Text variant="body-bold" size="XS" color="error2">
                {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.ERROR_MESSAGE')}
              </Text>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};
