import React from 'react';
import Card, { CardHeader, CardHeaderTitle } from '@verticeone/design-system/src/components/Card';
import VendorContractsTable from './VendorContractsTable';
import { useTranslation } from 'react-i18next';
import useVendorContractsData from './useVendorContractsData';

export type VendorContractsCardProps = {
  vendorId: string;
};

const VendorContractsCard = ({ vendorId }: VendorContractsCardProps) => {
  const { t } = useTranslation();
  const { data, isLoading } = useVendorContractsData({ vendorId });

  if (!isLoading && (!data || data.length === 0)) {
    return null;
  }

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle text={t('VENDOR.CONTRACTS.TITLE')} />
      </CardHeader>
      <VendorContractsTable rows={data} />
    </Card>
  );
};

export default VendorContractsCard;
