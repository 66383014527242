import React from 'react';
import { CustomContractFieldModel, ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import VendorCell, { vendorCellValueGetter } from './Cells/VendorCell';
import { AnnualCostCell, annualCostCellValueGetter } from './Cells/AnnualCostCell';
import { DepartmentCell, departmentCellValueGetter } from './Cells/DepartmentCell';
import { StageIndicatorCell, stageIndicatorCellValueGetter } from './Cells/StageIndicatorCell';
import { DeadlineCell, deadlineCellValueGetter } from './Cells/DeadlineCell';

import { useExchangeToAccountCurrency } from '@vertice/core/src/utils/currency/useExchangeToAccountCurrency';
import { useTranslation } from 'react-i18next';
import { ContractListColumn, FilterView } from './types';
import assertExhausted from '@vertice/core/src/utils/assertExhausted';
import { SavingsCell, savingsCellValueGetter } from './Cells/SavingsCell';
import { completedCellValueGetter, DateCell, nextRollDateValueGetter } from './Cells/DateCell';
import { TargetSignDateCell } from './Cells/TargetSignDateCell';
import { OwnersCell } from './Cells/OwnersCell';
import { ContractIdCell } from './Cells/ContractIdCell';
import { DataGridColDef, GridCellSkeleton } from '@verticeone/design-system/src/components/DataGrid';
import { Stack } from '@mui/material';
import { RollingFrequencyCell, rollingFrequencyCellSortableValueGetter } from './Cells/RollingFrequencyCell';
import { autoRenewalDateComparator, emptyLastComparator, savingsComparator } from './Cells/sortUtils';
import { AutoRenewalDateCell, autoRenewalDateValueGetter } from './Cells/AutoRenewalDateCell';
import { RenewalDateCell, renewalDateValueGetter } from './Cells/RenewalDateCell';
import { isNotNil } from '@vertice/core/src/utils/validation/isNotNil';
import { useVisibleCustomFields } from '../CustomFields/useVisibleCustomFields';
import { isNil } from 'lodash';
import { useFormatDate } from '@vertice/core/src/utils/formatting/date';
import { useFormatNumber } from '@vertice/core/src/utils/formatting/number';
import { EllipsisTextCell } from './Cells/EllipsisTextCell';
import { BillingFrequencyCell } from './Cells/BillingFrequencyCell';
import { PaymentTermsCell } from './Cells/PaymentTermsCell';
import { ContractCategoryCell } from './Cells/ContractCategoryCell';

export type ContractColDef = DataGridColDef<ViewOnContract> & {
  customFieldType?: CustomContractFieldModel['type'];
};

export type UseGetColumnBuilderParams = {
  resolveCustomFields?: boolean;
};

export const useGetColumnBuilder = ({ resolveCustomFields }: UseGetColumnBuilderParams = {}) => {
  const { exchangeToAccountCurrency, accountCurrency } = useExchangeToAccountCurrency();
  const { t } = useTranslation();

  const { visibleFields, isLoading: isCustomFieldsLoading } = useVisibleCustomFields({ skip: !resolveCustomFields });
  const resolveCustomFieldHeaderName = (fieldId: string) =>
    resolveCustomFields && visibleFields ? visibleFields.find((f) => f.id === fieldId)?.label ?? fieldId : fieldId;

  const resolveCustomDropdownItemLabel = (fieldId: string, selectedId?: string) => {
    if (!resolveCustomFields || !visibleFields) {
      return selectedId;
    }

    const field = visibleFields.find((f) => f.id === fieldId);
    if (!field || field.type !== 'DROPDOWN') {
      return selectedId;
    }

    return field.options?.find((o) => o.id === selectedId)?.label;
  };

  const formatDate = useFormatDate();
  const formatNumber = useFormatNumber();

  const buildColumn = (column: ContractListColumn): ContractColDef | null => {
    switch (column) {
      case ContractListColumn.VENDOR:
        return {
          field: column,
          headerName: t('ENTITIES.CONTRACT.LABELS.VENDOR'),
          valueGetter: (params) => vendorCellValueGetter(params.row),
          renderCell: ({ row }) => <VendorCell viewOnContract={row} />,
          renderSkeletonCell: () => (
            <Stack direction="row" gap={2} alignItems="center">
              <GridCellSkeleton variant="rectangular" width="32px" height="32px" sx={{ borderRadius: 2 }} />
              <GridCellSkeleton width="200px" />
            </Stack>
          ),
          flex: 1,
          minWidth: 200,
        };
      case ContractListColumn.ANNUAL_COST:
        return {
          field: column,
          headerName: t('CONTRACTS.GRID.ANNUAL_COST'),
          align: 'right',
          headerAlign: 'left', // Do not change to right unless explicitly requested by PM (BLUE-258)
          renderCell: ({ row }) => (
            <AnnualCostCell
              viewOnContract={row}
              accountCurrency={accountCurrency}
              exchangeCurrency={exchangeToAccountCurrency}
            />
          ),
          valueGetter: (params) => annualCostCellValueGetter(params.row, exchangeToAccountCurrency),
          flex: 0.6,
        };
      case ContractListColumn.DEPARTMENT:
        return {
          field: column,
          headerName: t('ENTITIES.CONTRACT.LABELS.DEPARTMENT'),
          renderCell: ({ row }) => <DepartmentCell viewOnContract={row} />,
          valueGetter: (params) => departmentCellValueGetter(params.row),
          flex: 0.6,
        };
      case ContractListColumn.STAGE:
        return {
          field: column,
          headerName: t('ENTITIES.CONTRACT.LABELS.STAGE'),
          renderCell: ({ row }) => <StageIndicatorCell viewOnContract={row} />,
          valueGetter: (params) => stageIndicatorCellValueGetter(params.row),
          width: 190,
        };
      case ContractListColumn.DEADLINE:
        return {
          field: column,
          headerName: t('ENTITIES.CONTRACT.LABELS.DEADLINE'),
          width: 160,
          valueGetter: (params) => deadlineCellValueGetter(params.row),
          renderCell: ({ row }) => <DeadlineCell viewOnContract={row} />,
        };
      case ContractListColumn.ROLLING_FREQUENCY:
        return {
          field: column,
          headerName: t('ENTITIES.CONTRACT.LABELS.ROLLING_FREQUENCY'),
          width: 180,
          valueGetter: (params) => rollingFrequencyCellSortableValueGetter(params.row),
          renderCell: ({ row }) => <RollingFrequencyCell viewOnContract={row} />,
        };
      case ContractListColumn.CONTRACT_ID:
        return {
          field: column,
          headerName: t('ENTITIES.CONTRACT.LABELS.ID'),
          valueGetter: (params) => params.row.contract.record.contractId,
          renderCell: ({ value }) => <ContractIdCell contractId={value} />,
          flex: 1.1,
          maxWidth: 350,
        };
      case ContractListColumn.COMPLETED:
        return {
          field: column,
          headerName: t('ENTITIES.CONTRACT.LABELS.COMPLETED'),
          width: 160,
          valueGetter: (params) => completedCellValueGetter(params.row),
          renderCell: ({ value }) => <DateCell date={value} />,
        };
      case ContractListColumn.SAVINGS:
        return {
          field: column,
          headerName: t('ENTITIES.CONTRACT.LABELS.TOTAL_SAVINGS'),
          width: 200,
          align: 'right',
          headerAlign: 'left', // Do not change to right unless explicitly requested by PM (BLUE-258)
          valueGetter: (params) =>
            savingsCellValueGetter(params.row.contract, accountCurrency, exchangeToAccountCurrency),
          sortComparator: savingsComparator,
          renderCell: ({ value }) => <SavingsCell {...value} accountCurrency={accountCurrency} />,
        };
      case ContractListColumn.AUTO_RENEWAL_DATE:
        return {
          field: column,
          headerName: t('ENTITIES.CONTRACT.LABELS.AUTO_RENEWAL_DATE'),
          width: 200,
          valueGetter: ({ row }) => autoRenewalDateValueGetter(row),
          sortComparator: autoRenewalDateComparator,
          renderCell: ({ value }) => <AutoRenewalDateCell date={value?.date} autoRenewalWaived={value?.waived} />,
        };
      case ContractListColumn.RENEWAL_DATE:
        return {
          field: column,
          headerName: t('ENTITIES.CONTRACT.LABELS.RENEWAL_DATE'),
          width: 160,
          valueGetter: ({ row }) => renewalDateValueGetter(row),
          sortComparator: emptyLastComparator,
          renderCell: ({ value, row }) => <RenewalDateCell viewOnContract={row} />,
        };
      case ContractListColumn.NEXT_ROLL_DATE:
        return {
          field: column,
          headerName: t('ENTITIES.CONTRACT.LABELS.NEXT_ROLL_DATE'),
          width: 160,
          valueGetter: ({ row }) => nextRollDateValueGetter(row),
          renderCell: ({ value }) => <DateCell date={value} />,
        };
      case ContractListColumn.TARGET_SIGN_DATE:
        return {
          field: column,
          headerName: t('ENTITIES.CONTRACT.LABELS.TARGET_SIGN_DATE'),
          width: 160,
          valueGetter: ({ row }) => row.contract.workflow?.externalDocuments?.targetSignDate,
          sortComparator: emptyLastComparator,
          renderCell: ({ value }) => <TargetSignDateCell date={value} />,
        };
      case ContractListColumn.OWNER:
        return {
          field: column,
          headerName: t('ENTITIES.CONTRACT.LABELS.OWNER'),
          flex: 0.6,
          sortable: false,
          disableColumnMenu: true,
          renderCell: ({ row }) => <OwnersCell viewOnContract={row} />,
        };
      case ContractListColumn.SIGNING_ENTITY:
        return {
          field: column,
          headerName: t('ENTITIES.CONTRACT.LABELS.SIGNING_ENTITY'),
          valueGetter: (params) => params.row.contract.parts.contractual?.signatory?.signingEntity?.name,
          renderCell: ({ value }) => <EllipsisTextCell value={value} />,
          sortComparator: emptyLastComparator,
          width: 200,
        };
      case ContractListColumn.REGION:
        return {
          field: column,
          headerName: t('ENTITIES.CONTRACT.LABELS.REGION'),
          valueGetter: (params) => params.row.contract.classification?.region,
          renderCell: ({ value }) => <EllipsisTextCell value={value} />,
          sortComparator: emptyLastComparator,
          width: 200,
        };
      case ContractListColumn.BILLING_FREQUENCY:
        return {
          field: column,
          headerName: t('ENTITIES.CONTRACT.LABELS.BILLING_FREQUENCY'),
          valueGetter: (params) => params.row.contract.parts.contractual?.financial?.billingFrequency,
          renderCell: ({ value }) => <BillingFrequencyCell value={value} />,
          sortComparator: emptyLastComparator,
          width: 130,
        };
      case ContractListColumn.PAYMENT_TERMS:
        return {
          field: column,
          headerName: t('ENTITIES.CONTRACT.LABELS.PAYMENT_TERMS'),
          valueGetter: (params) => params.row.contract.parts.contractual?.financial?.paymentTerms,
          renderCell: ({ value }) => <PaymentTermsCell value={value} />,
          sortComparator: emptyLastComparator,
          width: 130,
        };
      case ContractListColumn.CONTRACT_LENGTH:
        return {
          field: column,
          headerName: t('ENTITIES.CONTRACT.LABELS.CONTRACT_LENGTH'),
          valueGetter: (params) => params.row.contract.parts.overview?.contractLength,
          renderCell: ({ value }) => (
            <EllipsisTextCell value={isNil(value) ? '—' : `${value} ${t('UNITS.MONTH', { count: value })}`} />
          ),
          sortComparator: emptyLastComparator,
          width: 130,
        };
      case ContractListColumn.CATEGORY:
        return {
          field: column,
          headerName: t('ENTITIES.CONTRACT.LABELS.CONTRACT_CATEGORY'),
          valueGetter: (params) => params.row.contract.classification?.category,
          renderCell: ({ value }) => <ContractCategoryCell value={value} />,
          sortComparator: emptyLastComparator,
          width: 130,
        };

      // Custom fields

      case ContractListColumn.CHECKBOX_1:
      case ContractListColumn.CHECKBOX_2:
      case ContractListColumn.CHECKBOX_3:
      case ContractListColumn.CHECKBOX_4:
      case ContractListColumn.CHECKBOX_5:
        return {
          field: column,
          renderCell: ({ value }) => (isNil(value) ? '—' : value ? 'Yes' : 'No'),
          valueGetter: ({ row }) => row.contract.customFields?.[column],
          headerName: resolveCustomFieldHeaderName(column),
          sortComparator: emptyLastComparator,
          width: 150,
          minWidth: 80,
          customFieldType: 'CHECKBOX',
        };
      case ContractListColumn.DECIMAL_1:
      case ContractListColumn.DECIMAL_2:
      case ContractListColumn.DECIMAL_3:
      case ContractListColumn.DECIMAL_4:
      case ContractListColumn.DECIMAL_5:
        return {
          field: column,
          valueGetter: ({ row }) => row.contract.customFields?.[column],
          headerName: resolveCustomFieldHeaderName(column),
          renderCell: ({ value }) =>
            isNil(value) ? '—' : formatNumber(value, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          sortComparator: emptyLastComparator,
          minWidth: 100,
          width: 150,
          customFieldType: 'DECIMAL',
        };
      case ContractListColumn.DATE_1:
      case ContractListColumn.DATE_2:
      case ContractListColumn.DATE_3:
      case ContractListColumn.DATE_4:
      case ContractListColumn.DATE_5:
        return {
          field: column,
          valueGetter: ({ row }) => row.contract.customFields?.[column],
          headerName: resolveCustomFieldHeaderName(column),
          renderCell: ({ value }) => (isNil(value) ? '—' : formatDate(value)),
          sortComparator: emptyLastComparator,
          minWidth: 100,
          width: 150,
          customFieldType: 'DATE',
        };
      case ContractListColumn.TEXT_1:
      case ContractListColumn.TEXT_2:
      case ContractListColumn.TEXT_3:
      case ContractListColumn.TEXT_4:
      case ContractListColumn.TEXT_5:
        return {
          field: column,
          valueGetter: ({ row }) => row.contract.customFields?.[column],
          headerName: resolveCustomFieldHeaderName(column),
          renderCell: ({ value }) => <EllipsisTextCell value={value} />,
          sortComparator: emptyLastComparator,
          minWidth: 100,
          width: 200,
          customFieldType: 'TEXT',
        };
      case ContractListColumn.DROPDOWN_1:
      case ContractListColumn.DROPDOWN_2:
      case ContractListColumn.DROPDOWN_3:
      case ContractListColumn.DROPDOWN_4:
      case ContractListColumn.DROPDOWN_5:
        return {
          field: column,
          valueGetter: ({ row }) =>
            resolveCustomDropdownItemLabel(column, row.contract.customFields?.[column] ?? undefined),
          headerName: resolveCustomFieldHeaderName(column),
          renderCell: ({ value }) => <EllipsisTextCell value={value} />,
          sortComparator: emptyLastComparator,
          minWidth: 100,
          width: 200,
          customFieldType: 'DROPDOWN',
        };

      default:
        assertExhausted(column);
    }
    return null;
  };

  const buildColumnsFromView = (view: FilterView): DataGridColDef<ViewOnContract>[] => {
    return view.columns.map((column) => buildColumn(column as ContractListColumn)).filter(isNotNil);
  };

  return resolveCustomFields && isCustomFieldsLoading
    ? {
        isLoading: true,
        buildColumn: undefined,
        buildColumnsFromView: undefined,
      }
    : {
        isLoading: false,
        buildColumn,
        buildColumnsFromView,
      };
};
