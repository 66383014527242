import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import AWSSetupCard from './AWSSetupCard';

const CloudCostOptimization = () => {
  const { t } = useTranslation();

  return (
    <Stack direction="column" gap="24px">
      <Text variant="heading" size="S">
        {t('PREFERENCES.INTEGRATIONS.CLOUD_COST_OPTIMIZATION')}
      </Text>
      <AWSSetupCard />
    </Stack>
  );
};

export default CloudCostOptimization;
