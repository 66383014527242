import LoadableAdvanced from '@vertice/utils/src/loadableAdvanced';
import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import { useOptimizationCheckQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useMemo } from 'react';
import { DATE_FORMAT, fillMissingMonths, getTableData } from '../../utils/graphDataUtils';
import { addMonths, startOfMonth } from 'date-fns';
import format from 'date-fns/format';
import useDeferredQuery from '@vertice/core/src/utils/api/useDeferredQuery';
import { chain, flatMap, sumBy, uniq } from 'lodash';
import { SeriesOptionsWithData } from '@vertice/core/src/components/charts/highcharts-specific/types';

const startDate = addMonths(startOfMonth(new Date()), -12);

export type AWSSupportFeesData = {
  /** All months in the X axis in the format of yyyy-MM-dd. */
  months: string[];

  /** The list of storages classes with storage volume. The number of values should correspond to the number of months. */
  values: SeriesOptionsWithData[];

  /** Order of series from top to bottom */
  usedCategories: string[];
};

type ProductCostItem = {
  product: string;
  cost: number;
};

const supportPlanMap = {
  AWSSupportBusiness: 'Business Plan',
  AWSDeveloperSupport: 'Developer Plan',
  AWSSupportEnterprise: 'Enterprise Plan',
};

const splitIntoSeries = (items: { time: string; values: Record<string, number | null> }[]) => {
  const usedCategories = uniq(flatMap(items, ({ values }) => Object.keys(values)));

  return {
    usedCategories: usedCategories,
    series: usedCategories.map((category) => ({
      id: category,
      name: supportPlanMap[category as keyof typeof supportPlanMap] ?? category,
      data: items.map(({ values: valuePerCategory }) => valuePerCategory[category] ?? null),
      type: 'column',
    })),
  };
};

const useAWSSupportFeesData = (): LoadableAdvanced<AWSSupportFeesData> => {
  const { accountId } = useSelector(getAccount);

  const {
    data: supportCostData,
    error,
    isLoading,
  } = useDeferredQuery(
    useOptimizationCheckQuery,
    {
      accountId: accountId!,
      // Acceptable timezone inaccuracy: We're sending local timezone date to UTC endpoint
      startDate: format(startDate, DATE_FORMAT),
      checkCode: 'AWS_SUPPORT_COST',
    },
    { skip: !accountId, pollingInterval: 5000 },
    ({ checkQuery }) => checkQuery
  );

  const computedData = useMemo(() => {
    if (!supportCostData || supportCostData.length === 0) {
      return undefined;
    }

    const allMonthsData = chain(supportCostData)
      .flatMap(({ values }) => values)
      .map(({ dateStart, checkResult }) => ({
        // 2023-01-01T00:00:00 -> 2023-01-01
        time: format(new Date(dateStart), DATE_FORMAT),
        values: chain(
          getTableData(checkResult, {
            product: 'product',
            aws_support_cost: 'cost',
          }) as ProductCostItem[]
        )
          .groupBy(({ product }) => product)
          .mapValues((classItems) => sumBy(classItems, ({ cost }) => cost))
          .value(),
      }))
      .orderBy('time', 'asc')
      .thru((items) => (items.length > 0 ? fillMissingMonths(items, new Date(items[0].time), {}) : items))
      .value();

    const { series, usedCategories } = splitIntoSeries(allMonthsData);

    return {
      values: series,
      usedCategories,
      months: allMonthsData.map(({ time }) => time),
    };
  }, [supportCostData]);

  return {
    isLoading,
    error,
    isEmpty: Boolean(
      (supportCostData && supportCostData.length === 0) || (computedData && computedData.values.length === 0)
    ),
    data: computedData,
  };
};

export default useAWSSupportFeesData;
