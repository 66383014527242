import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import Button from '@verticeone/design-system/src/components/Button';
import Text from '@verticeone/design-system/src/components/Text';
import Alert, { AlertButton } from '@verticeone/design-system/src/components/Alert';
import Loader from '@verticeone/design-system/src/components/Loader';

import { useFulfillUserTaskMutation } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { parseUserRef, parseUserTaskRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { useLoggedUser } from '@verticeone/auth/src';
import TaskModalForm from './TaskModalForm';
import { FORM_CONFIGURATION_KIND } from './constants';
import { useTaskContext } from './TaskContext';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { COMMON_BUTTON_PROPS } from '../../constants';
import {
  SAAS_APPROVAL_FORM_CONFIG,
  isSaaSApprovalTask,
} from './TaskModalForm/predefinedForms/ApprovalSimpleFormSaaS/formDef';
import { TaskModalFormResult } from './TaskModalForm/TaskModalFormResult';

export const TaskModalActions = () => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const { userId } = useLoggedUser();
  const { taskOverview } = useTaskContext();

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [fulfillUserTask] = useFulfillUserTaskMutation();
  const [taskCompletionResult, setTaskCompletionResult] = useState<'SUCCESS' | 'ERROR' | undefined>();

  const openForm = () => {
    setIsFormOpen(true);
    setTaskCompletionResult(undefined);
  };

  const handleCompleteTask = async (resultBody: object) => {
    if (taskOverview) {
      const response = await fulfillUserTask({
        accountId,
        taskId: parseUserTaskRef(taskOverview.task.ref).taskId,
        body: resultBody,
      });

      if ('error' in response) {
        setTaskCompletionResult('ERROR');
      } else {
        setTaskCompletionResult('SUCCESS');
      }
      setIsFormOpen(false);
    }
  };

  const isTaskActive = taskOverview?.task.status === 'ACTIVE';
  const formConfig = isSaaSApprovalTask(taskOverview)
    ? SAAS_APPROVAL_FORM_CONFIG
    : taskOverview?.task.configurations?.find((configuration) => configuration.kind === FORM_CONFIGURATION_KIND);
  const currentUserAssigned = taskOverview?.task.assignment?.some(
    (assignment) => parseUserRef(assignment).userId === userId
  );

  if (!taskOverview) {
    return <Loader size={20} color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} />;
  }
  if (!formConfig) {
    return null;
  }

  return (
    <>
      {taskCompletionResult === 'ERROR' && (
        <Alert
          size="S"
          variant="ghost"
          color="error"
          title={t('INTELLIGENT_WORKFLOWS.TASK_MODAL.TASK_COMPLETION_FAILED')}
        >
          <AlertButton onClick={() => setTaskCompletionResult(undefined)}>{t('DIALOG.BUTTONS.CLOSE')}</AlertButton>
        </Alert>
      )}
      {!isFormOpen && taskCompletionResult === undefined && isTaskActive && currentUserAssigned && (
        <Stack direction="row" gap={2}>
          <Button variant="solid" {...COMMON_BUTTON_PROPS} onClick={openForm}>
            {t('ENTITIES.WORKFLOW_TASK.ACTIONS.COMPLETE_LONG')}
          </Button>
        </Stack>
      )}
      {isFormOpen && (
        <Stack gap={4}>
          <Stack alignItems="center" gap={4}>
            <Text variant="heading" size="M">
              {t('ENTITIES.WORKFLOW_TASK.ACTIONS.COMPLETE_LONG')}
            </Text>
          </Stack>
          <TaskModalForm onSubmit={handleCompleteTask} onCancel={() => setIsFormOpen(false)} formConfig={formConfig} />
        </Stack>
      )}
      {/*task completed confirmation*/}
      {taskCompletionResult === 'SUCCESS' && (
        <Stack gap={7} alignItems="center">
          <Text variant="heading" size="M">
            {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.TASK_COMPLETED_HEADING')}
          </Text>
          <Stack gap={5} alignItems="center">
            <Text variant="body-regular" size="M">
              {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.TASK_COMPLETED_MESSAGE', {
                taskType: t('ENTITIES.WORKFLOW_TASK.NAME'),
              })}
            </Text>
          </Stack>
          <Button variant="outline" {...COMMON_BUTTON_PROPS} onClick={() => setTaskCompletionResult(undefined)}>
            {t('DIALOG.BUTTONS.CLOSE')}
          </Button>
        </Stack>
      )}

      {taskCompletionResult === undefined && taskOverview.task.status === 'COMPLETED' && (
        <Stack gap={4} alignItems="center">
          <Text variant="heading" size="M">
            {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.TASK_COMPLETE_HEADING')}
          </Text>
          <TaskModalFormResult formConfig={formConfig} />
        </Stack>
      )}
    </>
  );
};
