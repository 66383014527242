import React, { FC } from 'react';
import { Stack } from '@mui/material';
import ProductsContractFormEntry from './form/ProductsContractFormEntry';

type ProductsTabProps = {
  showAnnualCost?: boolean;
};

const ProductsTab: FC<ProductsTabProps> = ({ showAnnualCost }) => {
  return (
    <Stack p={6} maxWidth={1000}>
      <ProductsContractFormEntry showAnnualCost={showAnnualCost} name="parts.contractual.products" width={12} />
    </Stack>
  );
};

export default ProductsTab;
