import { ContractOrigin, SimplifiedStage, ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { isRollingContract } from '../../computed';
import { CustomFilterView } from './Filters/customViews/types';
import * as z from 'zod';

export enum ContractListColumn {
  VENDOR = 'vendor',
  ANNUAL_COST = 'annualCost',
  DEPARTMENT = 'department',
  STAGE = 'stage',
  DEADLINE = 'deadline',
  ROLLING_FREQUENCY = 'rollingFrequency',
  CONTRACT_ID = 'contractId',
  COMPLETED = 'completed',
  SAVINGS = 'savings',
  AUTO_RENEWAL_DATE = 'autoRenewalDate',
  RENEWAL_DATE = 'renewalDate',
  NEXT_ROLL_DATE = 'nextRollDate',
  TARGET_SIGN_DATE = 'targetSignDate',
  OWNER = 'owner',
  SIGNING_ENTITY = 'signingEntity',
  BILLING_FREQUENCY = 'billingFrequency',
  PAYMENT_TERMS = 'paymentTerms',
  REGION = 'region',
  CONTRACT_LENGTH = 'contractLength',
  CATEGORY = 'category',

  // Custom fields

  CHECKBOX_1 = 'checkbox01',
  CHECKBOX_2 = 'checkbox02',
  CHECKBOX_3 = 'checkbox03',
  CHECKBOX_4 = 'checkbox04',
  CHECKBOX_5 = 'checkbox05',
  TEXT_1 = 'text01',
  TEXT_2 = 'text02',
  TEXT_3 = 'text03',
  TEXT_4 = 'text04',
  TEXT_5 = 'text05',
  DECIMAL_1 = 'decimal01',
  DECIMAL_2 = 'decimal02',
  DECIMAL_3 = 'decimal03',
  DECIMAL_4 = 'decimal04',
  DECIMAL_5 = 'decimal05',
  DATE_1 = 'date01',
  DATE_2 = 'date02',
  DATE_3 = 'date03',
  DATE_4 = 'date04',
  DATE_5 = 'date05',
  DROPDOWN_1 = 'dropdown01',
  DROPDOWN_2 = 'dropdown02',
  DROPDOWN_3 = 'dropdown03',
  DROPDOWN_4 = 'dropdown04',
  DROPDOWN_5 = 'dropdown05',
}

export type PredefinedFilterView = {
  key: string;
  filterFunction: (contract: ViewOnContract) => boolean;
  labelToTranslate: string; // translation string => needs to be t'd before use
  columns: ContractListColumn[];
};

export type FilterView = ({ type: 'predefined' } & PredefinedFilterView) | ({ type: 'custom' } & CustomFilterView);

export type FilterViewWithCount = {
  filterView: FilterView;
  count: number;
};

export const PREDEFINED_VIEW_ALL: FilterView = {
  type: 'predefined',
  key: 'all',
  filterFunction: Boolean,
  labelToTranslate: 'ENTITIES.CONTRACT.LABELS.LIST_FILTERS.ALL',
  columns: [
    ContractListColumn.VENDOR,
    ContractListColumn.ANNUAL_COST,
    ContractListColumn.DEPARTMENT,
    ContractListColumn.OWNER,
    ContractListColumn.STAGE,
    ContractListColumn.DEADLINE,
  ],
};

export const PREDEFINED_VIEW_TOTAL: FilterView = {
  type: 'predefined',
  key: 'total',
  filterFunction: (viewOnContract: ViewOnContract) =>
    !Array<SimplifiedStage | undefined>(
      'EXPIRED_NATURAL',
      'EXPIRED_PLANNED',
      'EXPIRED_UNINTENTIONAL',
      'TERMINATED',
      'CANCELLED_REQUEST',
      'CANCELLED_FIXED_TERM'
    ).includes(viewOnContract.contract.materialized?.simplifiedStage),
  labelToTranslate: 'ENTITIES.CONTRACT.LABELS.LIST_FILTERS.TOTAL',
  columns: [
    ContractListColumn.VENDOR,
    ContractListColumn.ANNUAL_COST,
    ContractListColumn.DEPARTMENT,
    ContractListColumn.OWNER,
    ContractListColumn.STAGE,
    ContractListColumn.DEADLINE,
  ],
};

export const PREDEFINED_VIEW_LIVE_FIXED_TERM: FilterView = {
  type: 'predefined',
  key: 'liveFixedTerm',
  filterFunction: (viewOnContract: ViewOnContract) =>
    Array<SimplifiedStage | undefined>('LIVE', 'NOT_YET_LIVE').includes(
      viewOnContract.contract.materialized?.simplifiedStage
    ) && !isRollingContract(viewOnContract.contract),
  labelToTranslate: 'ENTITIES.CONTRACT.LABELS.LIST_FILTERS.LIVE_FIXED_TERM',
  columns: [
    ContractListColumn.VENDOR,
    ContractListColumn.ANNUAL_COST,
    ContractListColumn.DEPARTMENT,
    ContractListColumn.OWNER,
    ContractListColumn.STAGE,
    ContractListColumn.AUTO_RENEWAL_DATE,
    ContractListColumn.RENEWAL_DATE,
  ],
};

export const PREDEFINED_VIEW_LIVE_ROLLING: FilterView = {
  type: 'predefined',
  key: 'liveRolling',
  filterFunction: (viewOnContract: ViewOnContract) =>
    Array<SimplifiedStage | undefined>('LIVE', 'NOT_YET_LIVE').includes(
      viewOnContract.contract.materialized?.simplifiedStage
    ) && isRollingContract(viewOnContract.contract),
  labelToTranslate: 'ENTITIES.CONTRACT.LABELS.LIST_FILTERS.LIVE_ROLLING',
  columns: [
    ContractListColumn.VENDOR,
    ContractListColumn.ANNUAL_COST,
    ContractListColumn.DEPARTMENT,
    ContractListColumn.OWNER,
    ContractListColumn.STAGE,
    ContractListColumn.ROLLING_FREQUENCY,
    ContractListColumn.NEXT_ROLL_DATE,
  ],
};

export const PREDEFINED_VIEW_IN_PROGRESS: FilterView = {
  type: 'predefined',
  key: 'inProgress',
  filterFunction: (viewOnContract: ViewOnContract) =>
    viewOnContract.contract.materialized?.simplifiedStage === 'WORKFLOW_IN_PROGRESS',
  labelToTranslate: 'ENTITIES.CONTRACT.LABELS.LIST_FILTERS.IN_PROGRESS',
  columns: [
    ContractListColumn.VENDOR,
    ContractListColumn.ANNUAL_COST,
    ContractListColumn.DEPARTMENT,
    ContractListColumn.OWNER,
    ContractListColumn.STAGE,
    ContractListColumn.DEADLINE,
  ],
};

export const PREDEFINED_VIEW_AWAITING_APPROVAL: FilterView = {
  type: 'predefined',
  key: 'awaitingApproval',
  filterFunction: (viewOnContract: ViewOnContract) =>
    viewOnContract.contract.materialized?.simplifiedStage === 'WORKFLOW_IN_PROGRESS' &&
    viewOnContract.contract.workflow?.stage === 'APPROVAL',
  labelToTranslate: 'ENTITIES.CONTRACT.LABELS.LIST_FILTERS.AWAITING_APPROVAL',
  columns: [
    ContractListColumn.VENDOR,
    ContractListColumn.ANNUAL_COST,
    ContractListColumn.DEPARTMENT,
    ContractListColumn.OWNER,
    ContractListColumn.STAGE,
    ContractListColumn.DEADLINE,
  ],
};

export const PREDEFINED_VIEW_DRAFT: FilterView = {
  type: 'predefined',
  key: 'draft',
  filterFunction: (viewOnContract: ViewOnContract) => viewOnContract.contract.materialized?.simplifiedStage === 'DRAFT',
  labelToTranslate: 'ENTITIES.CONTRACT.LABELS.LIST_FILTERS.DRAFT',
  columns: [
    ContractListColumn.VENDOR,
    ContractListColumn.ANNUAL_COST,
    ContractListColumn.DEPARTMENT,
    ContractListColumn.OWNER,
    ContractListColumn.STAGE,
    ContractListColumn.DEADLINE,
  ],
};

export const PREDEFINED_VIEW_EXPIRED: FilterView = {
  type: 'predefined',
  key: 'expired',
  filterFunction: (viewOnContract: ViewOnContract) =>
    Array<SimplifiedStage | undefined>(
      'EXPIRED_NATURAL',
      'EXPIRED_PLANNED',
      'EXPIRED_UNINTENTIONAL',
      'TERMINATED'
    ).includes(viewOnContract.contract.materialized?.simplifiedStage),
  labelToTranslate: 'ENTITIES.CONTRACT.LABELS.LIST_FILTERS.EXPIRED',
  columns: [
    ContractListColumn.VENDOR,
    ContractListColumn.ANNUAL_COST,
    ContractListColumn.DEPARTMENT,
    ContractListColumn.OWNER,
    ContractListColumn.STAGE,
  ],
};

export const PREDEFINED_VIEW_EXPIRED_IAT: FilterView = {
  type: 'predefined',
  key: 'expired',
  filterFunction: (viewOnContract: ViewOnContract) =>
    Array<SimplifiedStage | undefined>(
      'EXPIRED_NATURAL',
      'EXPIRED_PLANNED',
      'EXPIRED_UNINTENTIONAL',
      'CANCELLED_FIXED_TERM',
      'CANCELLED_REQUEST',
      'TERMINATED'
    ).includes(viewOnContract.contract.materialized?.simplifiedStage),
  labelToTranslate: 'ENTITIES.CONTRACT.LABELS.LIST_FILTERS.EXPIRED',
  columns: [
    ContractListColumn.VENDOR,
    ContractListColumn.ANNUAL_COST,
    ContractListColumn.DEPARTMENT,
    ContractListColumn.OWNER,
    ContractListColumn.STAGE,
  ],
};

export const PREDEFINED_VIEW_COMPLETED: FilterView = {
  type: 'predefined',
  key: 'completed',
  filterFunction: (viewOnContract: ViewOnContract) =>
    Array<SimplifiedStage | undefined>(
      'EXPIRED_NATURAL',
      'EXPIRED_PLANNED',
      'EXPIRED_UNINTENTIONAL',
      'TERMINATED',
      'LIVE',
      'NOT_YET_LIVE'
    ).includes(viewOnContract.contract.materialized?.simplifiedStage) &&
    Array<ContractOrigin | undefined>('PURCHASE_WITH_VERTICE', 'RENEWAL_WITH_VERTICE').includes(
      viewOnContract.contract?.record?.contractOrigin
    ),
  labelToTranslate: 'ENTITIES.CONTRACT.LABELS.LIST_FILTERS.COMPLETED',
  columns: [
    ContractListColumn.VENDOR,
    ContractListColumn.ANNUAL_COST,
    ContractListColumn.DEPARTMENT,
    ContractListColumn.OWNER,
    ContractListColumn.STAGE,
    ContractListColumn.SAVINGS,
    ContractListColumn.COMPLETED, // does not exist yet (sign date in current implementation)
  ],
};

export const unifiedContractStageSchema = z.enum([
  'DRAFT',
  'NOT_YET_LIVE',
  'LIVE',
  'TERMINATED',
  'EXPIRED',
  'EXPIRED_PLANNED',
  'REQUIREMENTS_GATHERING',
  'NEGOTIATING',
  'APPROVAL',
  'CONTRACTING',
] as const);

/** Special enum that contains specific selection of both workflow and simplified stages */
export type UnifiedContractStage = z.infer<typeof unifiedContractStageSchema>;
