import { FC } from 'react';
import { Stack } from '@mui/material';
import snakeCase from 'lodash/snakeCase';
import { useTranslation } from 'react-i18next';

import Text from '@verticeone/design-system/src/components/Text';

import { RequestJournalItem, RequestJournalItemType } from '../types';
import { JournalItemOfType } from './types';
import { CompletedTaskJournalItem } from './CompletedTaskJournalItem';
import {
  GenericDateIcon,
  GenericRequestIcon,
  GenericServiceIcon,
  GenericTaskIcon,
  GenericUserIcon,
} from '../../../../../components/icons/Icons';
import { useUsersContext } from '@vertice/core/src/contexts/UsersContext';
import { dateTimeFormatterOptions, useFormatDate } from '@vertice/core/src/utils/formatting/date';
import { ReassignedTaskJournalItem } from './ReassignedTaskJournalItem';
import { RequestOwnerChangedJournalItem } from './RequestOwnerChangedJournalItem';

const useJournalItemOfType = (type: RequestJournalItemType): JournalItemOfType => {
  const { t } = useTranslation();
  const journalItemByType = {
    requestCreated: {
      icon: GenericRequestIcon,
    },
    requestCompleted: {
      icon: GenericRequestIcon,
    },
    requestTerminated: {
      icon: GenericRequestIcon,
    },
    requestOwnerChanged: {
      icon: GenericUserIcon,
      contentComponent: RequestOwnerChangedJournalItem,
    },
    taskCompleted: {
      icon: GenericTaskIcon,
      contentComponent: CompletedTaskJournalItem,
    },
    serviceCompleted: {
      icon: GenericServiceIcon,
    },
    taskAssignmentChanged: {
      icon: GenericTaskIcon,
      contentComponent: ReassignedTaskJournalItem,
    },
    unknown: {
      label: 'Unknown',
    },
  };

  return {
    ...journalItemByType[type],
    label: t(`INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.JOURNAL.EVENTS.${snakeCase(type).toUpperCase()}`),
  };
};

type JournalItemProps = {
  item: RequestJournalItem;
};

export const JournalItem: FC<JournalItemProps> = ({ item }) => {
  const journalItemOfType = useJournalItemOfType(item.type);

  const ContentComponent = journalItemOfType?.contentComponent;

  const { getFullName } = useUsersContext();
  const userFullName = item.userId ? getFullName(item.userId) : undefined;

  const Icon = journalItemOfType.icon;

  const formatDate = useFormatDate();

  return (
    <Stack gap={2}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap={1} alignItems="center" paddingLeft={6}>
          {Icon && <Icon />}
          <Text variant="caption" color="text1">
            {journalItemOfType.label}
          </Text>
        </Stack>
        <Stack gap={4} direction="row">
          {userFullName && (
            <Stack alignItems="center" direction="row" gap={1}>
              <GenericUserIcon />
              <Text variant="body-regular" size="S" color="text1">
                {userFullName}
              </Text>
            </Stack>
          )}
          <Stack alignItems="center" direction="row" gap={1}>
            <GenericDateIcon />
            <Text variant="body-regular" size="S" color="text1">
              {formatDate(item.timestamp, { formatterOptions: dateTimeFormatterOptions })}
            </Text>
          </Stack>
        </Stack>
      </Stack>
      {ContentComponent ? (
        <Stack>
          <ContentComponent item={item} />
        </Stack>
      ) : null}
      {/*<pre>{JSON.stringify(JSON.parse(item.original.delta), null, 2)}</pre>*/}
    </Stack>
  );
};
