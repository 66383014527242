import { useTranslation } from 'react-i18next';
import { IntegrationFail } from '@vertice/assets';
import Button from '@verticeone/design-system/src/components/Button';
import { AWS_STEPS, AWSStepProps } from '../AWSSetupWizard';
import IntegrationStatus from '../../../components/IntegrationStatus';
import WizardStepTemplate from '../../../components/WizardStepTemplate';

const AWSConnectionError = ({ setStep }: AWSStepProps) => {
  const { t } = useTranslation();

  const onBack = () => setStep(AWS_STEPS.COST_AND_USAGE);

  return (
    <WizardStepTemplate
      content={
        <IntegrationStatus
          icon={<IntegrationFail />}
          title={t('PREFERENCES.INTEGRATIONS.AWS.ERROR.TITLE')}
          text={t('PREFERENCES.INTEGRATIONS.AWS.ERROR.DESC')}
        />
      }
      buttons={[
        <Button variant="outline" size="S" isCaption onClick={onBack}>
          {t('COMMON.BACK')}
        </Button>,
        <Button variant="solid" color="primary" size="S" isCaption disabled>
          {t('COMMON.FINISH')}
        </Button>,
      ]}
      scrollable={false}
    />
  );
};

export default AWSConnectionError;
