import React from 'react';
import { useTranslation } from 'react-i18next';
import { WarningAmberOutlined } from '@mui/icons-material';

import { CardHeader } from '@verticeone/design-system/src/components/Card';
import Alert from '@verticeone/design-system/src/components/Alert';

import { useInstanceData } from './providers/InstanceProvider';

const MissingRiLookupBanner = () => {
  const { item } = useInstanceData();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.OPTIMIZE' });

  if (!item.missingRiLookup) {
    return null;
  }

  return (
    <CardHeader size="S" density="medium">
      <Alert
        size="M"
        variant="ghost"
        color="warning"
        subtitle={t('MISSING_RI_LOOKUP_MESSAGE')}
        icon={WarningAmberOutlined}
      />
    </CardHeader>
  );
};

export default MissingRiLookupBanner;
