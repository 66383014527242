import { Stack, styled } from '@mui/material';
import { CardGridColDef, ValidRowModel } from './types';
import Card from '@verticeone/design-system/src/components/Card';

export const OnCardHover = styled(Stack)({});

const StyledRowCard = styled(Card)<{ $clickable: boolean; $height?: number }>(({ theme, $clickable, $height }) => ({
  width: '100%',
  flexDirection: 'row',
  marginBottom: theme.spacing(2),
  justifyContent: 'stretch',
  cursor: $clickable ? 'pointer' : 'default',
  height: $height,
  [OnCardHover]: {
    opacity: 0,
    maxHeight: 0,
    transition: 'all 200ms ease-in-out',
  },
  transition: 'border 200ms ease-in-out',
  '&:hover': {
    [OnCardHover]: {
      opacity: 1,
      maxHeight: '30px',
    },
    border: `1px solid ${theme.palette.core.color5}`,
  },
}));

export const CardGridColumn = styled(Stack)<{ $flex: number }>(({ $flex = 1 }) => ({
  padding: 16,
  flex: $flex,
  alignItems: 'center',
  flexDirection: 'row',
  overflow: 'hidden',
  minWidth: 0,
}));

type CardGridRowProps<R extends ValidRowModel> = {
  columns: CardGridColDef<R>[];
  row: R;
  height?: number;
  onClick?: (row: R, event: React.MouseEvent) => void;
};

export const CardGridRow = <R extends ValidRowModel>({ columns, row, onClick, height }: CardGridRowProps<R>) => {
  return (
    <StyledRowCard
      onClick={(e) => onClick?.(row, e)}
      $clickable={!!onClick}
      $height={height}
      role="row" /* <- for testing & a11y */
    >
      {columns.map((column) => (
        <CardGridColumn
          $flex={column.flex}
          key={column.field}
          role="cell" /* <- for testing & a11y */
          data-field={column.field} /* <- for testing */
        >
          {column.renderCell ? column.renderCell({ row }) : row[column.field]}
        </CardGridColumn>
      ))}
    </StyledRowCard>
  );
};
