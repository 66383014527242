import React, { FC } from 'react';
import { useSnackbar } from 'notistack';
import { useLegalSubscriptionMutation, useRequestVendorLegalInfoMutation } from '@vertice/slices';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Loader } from '../Loader';
import styles from './VendorDiligenceInsights.module.scss';

export interface BlurredContentProps {
  accountId: string;
  vendorId: string;
  mode: 'NO_SUBSCRIPTION' | 'NO_DATA';
}

const BlurredContent: FC<BlurredContentProps> = ({ accountId, vendorId, mode }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [requestSubscription, { isLoading: isSubscriptionRequesting }] = useLegalSubscriptionMutation();
  const [requestVendorInsight, { isLoading: isLegalInfoRequesting }] = useRequestVendorLegalInfoMutation();

  const { t } = useTranslation();
  const contractsInsights = ['PAYMENT', 'LIABILITY', 'RENEWAL_USAGE', 'MISC'].map((item) =>
    t('LEGAL_INFO.INSIGHTS.' + item)
  );

  const handleResponse = (response: any) => {
    if (response.data) {
      enqueueSnackbar(t('SNACKBAR.LEGAL_REQUEST'));
    } else {
      throw new Error(response.error);
    }
  };

  const onContactManager = async () => {
    const response = await requestSubscription({
      accountId,
    });
    handleResponse(response);
  };

  const onRequestInsights = async () => {
    const response = await requestVendorInsight({
      accountId,
      vendorId,
    });
    handleResponse(response);
  };

  const onActionButton = mode === 'NO_SUBSCRIPTION' ? onContactManager : onRequestInsights;

  return isSubscriptionRequesting || isLegalInfoRequesting ? (
    <Loader />
  ) : (
    <div className={styles.content}>
      <div className={styles['action-button-wrap']}>
        <div className={styles['action-button']} onClick={onActionButton}>
          <span>{t(`LEGAL_INFO.${mode}.BUTTON`)}</span>
          <ArrowForwardIosSharpIcon />
        </div>
      </div>
      <div className={`${styles.body} ${styles.blurred}`}>
        <Stack gap="16px">
          <Typography variant="label-xs" className={styles['sub-header']}>
            {t('LEGAL_INFO.CONTRACT_INSIGHTS')}
          </Typography>
          <Stack className={styles['accordion-list']}>
            {contractsInsights.map((item, i) => (
              <li key={`blurred-insights-${i}`}>{item}</li>
            ))}
          </Stack>
        </Stack>
      </div>
    </div>
  );
};

export default BlurredContent;
