import React from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { EllipsisText } from '@verticeone/design-system/src/components/Text';
import { AvatarStack } from '@verticeone/design-system/src/components/Avatar';

import { TaskRow } from '../../types';
import { GenericUserIcon } from '../../../components/icons/Icons';

export const TaskAssignees = ({ taskRow }: { taskRow: TaskRow }) => {
  const { t } = useTranslation();
  const validAssignees = taskRow.assignees.filter((assignee) => assignee.valid);
  const hasOnlyInvalidAssignees = validAssignees.length === 0 && taskRow.assignees.length > 0;

  const avatarItems = validAssignees.map((assignee) => ({
    personId: assignee.id,
    personName: assignee.fullName,
    color: 'auto' as const,
  }));
  return (
    <Stack minWidth={0} direction="row" alignItems="center" gap={0.5}>
      {validAssignees.length > 0 ? (
        <AvatarStack items={avatarItems} />
      ) : (
        <Stack direction="row" alignItems="center" gap={1}>
          <GenericUserIcon />
          <EllipsisText variant="body-bold" size="M" color="error1">
            {t(
              hasOnlyInvalidAssignees
                ? 'ENTITIES.WORKFLOW_TASK.LABELS.INVALID_ASSIGNEE'
                : 'ENTITIES.WORKFLOW_TASK.LABELS.NO_ASSIGNEE'
            )}
          </EllipsisText>
        </Stack>
      )}
    </Stack>
  );
};
