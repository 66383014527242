import React, { FC, useState, ReactElement, cloneElement } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { GridValueGetterParams } from '@mui/x-data-grid-pro';
import { Box, Button } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { AccountUser, useListAccountUsersQuery, useRemoveUserRoleMutation } from '@vertice/slices';
import { getFullName } from '@vertice/utils';
import { useLoggedUser } from '@verticeone/auth/src';
import { logHttpError } from '@vertice/hooks/src/useAwsRum';
import { createUsersMap } from './utils';
import useAddEditInviteContactModal from './useAddEditInviteContactModal';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import styles from './GridList.module.scss';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

export type ForbiddenDeletionProps = {
  departmentOwner: boolean;
  contractOwner: boolean;
  contractApprover: boolean;
};

const showForbiddenDeletionInitState = {
  departmentOwner: false,
  contractOwner: false,
  contractApprover: false,
};

interface WithActionsProps {
  children: ReactElement;
  checkAvailableDeletion?: (value: string) => Promise<ForbiddenDeletionProps>;
}

const WithActions: FC<WithActionsProps> = ({ children, checkAvailableDeletion, ...rest }) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const { userId: currentUserId } = useLoggedUser();

  const [removeUserRole] = useRemoveUserRoleMutation();

  const { data, refetch } = useListAccountUsersQuery(
    { accountId: accountId! },
    { skip: !accountId, refetchOnMountOrArgChange: false }
  );
  const usersMap = createUsersMap(data?.users || []);

  const { modal, handleEditRequest } = useAddEditInviteContactModal();

  const [confirmDeleteRoleOpen, setConfirmDeleteRoleOpen] = useState(false);
  const [confirmDeleteLoading, setConfirmDeleteLoading] = useState(false);
  const [showForbiddenDeletion, setShowForbiddenDeletion] =
    useState<ForbiddenDeletionProps>(showForbiddenDeletionInitState);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  const handleOkClick = () => setShowForbiddenDeletion(showForbiddenDeletionInitState);

  const handleEditClick = (user: AccountUser) => () => {
    if (user) {
      handleEditRequest(user);
    }
  };

  const handleConfirmedDeleteRole = () => {
    setConfirmDeleteRoleOpen(false);
    if (accountId && selectedUserId) {
      const removeUserRoleArgs = {
        accountId,
        userId: selectedUserId,
      };
      removeUserRole(removeUserRoleArgs)
        .unwrap()
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          refetch();
        })
        .catch(({ data: { errorMessage }, status }) => {
          logHttpError(status, {
            endpointName: 'removeUserRole',
            originalArgs: removeUserRoleArgs,
            message: errorMessage,
          });
        });
    }
  };

  const handleDeleteClick = (userId: string) => async () => {
    if (confirmDeleteLoading) {
      return;
    }
    setSelectedUserId(userId);

    if (checkAvailableDeletion) {
      setConfirmDeleteLoading(true);
      const availableDeletion = await checkAvailableDeletion(userId);

      if (Object.values(availableDeletion).some((v) => v)) {
        setShowForbiddenDeletion(availableDeletion);
      } else {
        setConfirmDeleteRoleOpen(true);
      }
      setConfirmDeleteLoading(false);
    } else {
      setConfirmDeleteRoleOpen(true);
    }
  };

  const actionsCell = (params: GridValueGetterParams<AccountUser, AccountUser>) => {
    const { userId } = params.row;

    if (userId === currentUserId) {
      return (
        <Box display="flex" flexDirection="row" alignItems="center" className={clsx('actions', styles.actions)}>
          &nbsp;
        </Box>
      );
    }

    return (
      <Box display="flex" flexDirection="row" alignItems="center" className={clsx('actions', styles.actions)}>
        <Button
          className={clsx('button-wrap', styles['button-wrap'], styles['blue-button'])}
          variant="contained"
          onClick={handleEditClick(params.row)}
        >
          <Edit />
        </Button>

        <LoadingButton
          loading={userId === selectedUserId && confirmDeleteLoading}
          loadingPosition="start"
          startIcon={<Delete />}
          className={clsx('button-wrap', styles['button-wrap'], styles['red-button'])}
          variant="contained"
          onClick={handleDeleteClick(userId)}
        />
      </Box>
    );
  };

  const actionsColumn = {
    field: 'actions',
    headerName: '',
    sortable: false,
    disableColumnMenu: true,
    renderCell: actionsCell,
    width: 97,
    type: 'actions',
  };

  const forbiddenDeletionMessage =
    Object.entries({
      contractOwner: t('FORBIDDEN_DELETION.CONTRACT_OWNER_MESSAGE'),
      departmentOwner: t('FORBIDDEN_DELETION.DEPARTMENT_OWNER_MESSAGE'),
      contractApprover: t('FORBIDDEN_DELETION.CONTRACT_APPROVER_MESSAGE'),
    }).find(([key]) => showForbiddenDeletion[key as keyof ForbiddenDeletionProps])?.[1] || '';

  const extraColumns = [...(children.props.extraColumns || []), actionsColumn];

  return (
    <>
      {cloneElement(children, {
        extraColumns,
        dataGridExtraProps: {
          editMode: 'row',
          experimentalFeatures: { newEditingApi: true },
        },
        ...rest,
      })}
      <ConfirmationDialog
        isOpen={confirmDeleteRoleOpen}
        headerText={t('LIST_ITEM.DELETE_TEAM_MEMBER_TITLE')}
        bodySecondaryText={t('LIST_ITEM.DELETE_MESSAGE', {
          item: selectedUserId && getFullName(usersMap.get(selectedUserId)),
        })}
        secondaryButtonText={t('LIST_ITEM.CANCEL')}
        secondaryButtonAction={() => {
          setConfirmDeleteRoleOpen(false);
        }}
        primaryButtonText={t('LIST_ITEM.YES')}
        primaryButtonAction={handleConfirmedDeleteRole}
      />

      <ConfirmationDialog
        isOpen={Object.values(showForbiddenDeletion).some((v) => v)}
        bodySecondaryText={forbiddenDeletionMessage}
        primaryButtonText={t('FORBIDDEN_DELETION.OK')}
        primaryButtonAction={handleOkClick}
      />
      {modal}
    </>
  );
};

export default WithActions;
