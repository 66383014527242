import React from 'react';
import type { ButtonGroupProps } from './ButtonGroup';

type ButtonGroupContextProps = {
  className?: string;
  color?: ButtonGroupProps['color'];
  size?: ButtonGroupProps['size'];
  variant?: ButtonGroupProps['variant'];
  disabled?: boolean;
};

const ButtonGroupContext = React.createContext<ButtonGroupContextProps>({});

export default ButtonGroupContext;
