import { useMemo } from 'react';
import useDeferredQuery from '@vertice/core/src/utils/api/useDeferredQuery';
import {
  RecommendationStatus,
  useRecommendationEstimatedSavingsQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';

type ResponseItem = {
  billing_period_start: string;
  status: RecommendationStatus;
  saving: number;
};

const useSavingsData = (code: `VR-${string}` | 'ALL') => {
  const { accountId } = useAccountContext();
  const dateRangeFrom = useMemo(() => {
    const today = new Date();
    today.setMonth(today.getMonth() - 6);
    today.setDate(today.getDate() - (today.getDay() - 1));

    return today.toISOString().split('T')[0];
  }, []);

  const response = useDeferredQuery(
    useRecommendationEstimatedSavingsQuery,
    {
      code,
      accountId,
      date_range_from: dateRangeFrom,
    },
    {
      skip: !accountId,
      pollingInterval: 5000,
    },
    (result) => {
      if (result?.athenaViewQuery?.__typename === 'DataTableResult') {
        const tableData: Array<ResponseItem> = getTableData(
          result.athenaViewQuery.table!
        )! as unknown as Array<ResponseItem>;

        return { result: tableData };
      }
      return;
    }
  );

  const computedData = useMemo(() => {
    if (response.data) {
      // we need 2 series for the graph, one for cancelled (Won't do) and one for discovered (To do)
      if (code === 'ALL') {
        const discoveredItems = response.data
          .filter(({ status }) => status === RecommendationStatus.Discovered)
          .sort((a, b) => a.billing_period_start.localeCompare(b.billing_period_start));

        const dates = discoveredItems.map((curr) => curr.billing_period_start);

        const cancelledItems = dates.map((date) => {
          const cancelledItem = response.data?.find(
            ({ status, billing_period_start }) =>
              status === RecommendationStatus.Cancelled && billing_period_start === date
          );
          return {
            saving: cancelledItem?.saving || 0,
          };
        });

        return {
          values: [
            {
              status: RecommendationStatus.Cancelled,
              data: cancelledItems.map((curr) => curr.saving),
            },
            {
              status: RecommendationStatus.Discovered,
              data: discoveredItems.map((curr) => curr.saving),
            },
          ],
          dates,
        };
        // here we need pairs of data for the linked graph lines
      } else {
        const sortedTableData = response.data?.sort((a, b) =>
          a.billing_period_start.localeCompare(b.billing_period_start)
        );

        const dates = sortedTableData.map((curr) => curr.billing_period_start);

        const values = sortedTableData.map((item, index, array) => {
          const data = [
            {
              x: index,
              y: item.saving || 0,
            },
          ];

          const nextIndex = index + 1;
          const nextItem = array[nextIndex];
          if (nextItem) {
            data.push({
              x: nextIndex,
              y: nextItem.saving || 0,
            });
          }

          return {
            status: item.status,
            data,
          };
        });

        return {
          values: values,
          dates,
        };
      }
    }
  }, [code, response.data]);

  return {
    data: computedData,
    isLoading: response.isLoading,
  };
};

export default useSavingsData;
