import React, { useMemo } from 'react';
import { Stack } from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { OptionsStackingValue } from 'highcharts';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import Text from '@verticeone/design-system/src/components/Text';
import ColorSquare from '@vertice/core/src/components/charts/components/ColorSquare';
import { useFormatPercentage } from '@vertice/core/src/utils/formatting/percentage';
import Tile from './Tile';
import type { InstanceSize } from '../types';
import { useGetColoredItems } from '@vertice/core/src/components/charts/highcharts-specific/utils/seriesUtils';
import { useTranslation } from 'react-i18next';

type SeriesBarOptions = {
  name: string;
  value: number;
};

type SizeGraphProps = {
  categories: Array<{
    name: InstanceSize;
    value: number;
  }>;
};

const BAR_HEIGHT = 16;

const getDefaultOptions = (seriesBarOptions: SeriesBarOptions[]): Highcharts.Options => ({
  chart: {
    type: 'bar',
    plotBackgroundColor: 'transparent',
    backgroundColor: 'transparent',
    height: BAR_HEIGHT,
    spacing: [-1, 0, 0, 0],
  },
  yAxis: {
    min: 0,
    max: 100,
    labels: {
      enabled: false,
    },
  },
  xAxis: {
    labels: {
      enabled: false,
    },
  },
  plotOptions: {
    series: {
      stacking: 'normal' as OptionsStackingValue,
      pointWidth: BAR_HEIGHT + 1,
    } as Highcharts.PlotSeriesOptions,
  },
  series: seriesBarOptions.map(({ name, value, ...otherProps }) => ({
    type: 'bar',
    name,
    data: [value],
    ...otherProps,
  })),
  tooltip: { enabled: false },
});

const SizeGraph = ({ categories }: SizeGraphProps) => {
  const formatPercentage = useFormatPercentage();
  const applyStyledHighcharts = useStyledHighcharts();
  const getColoredSeries = useGetColoredItems();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.INVENTORY.DETAIL.CHARTS.SIZE' });

  const coloredCategories = useMemo(() => getColoredSeries(categories), [getColoredSeries, categories]);
  const options = useMemo(() => {
    return buildOptions([applyStyledHighcharts, mergeOptions(getDefaultOptions([...coloredCategories!].reverse()))]);
  }, [applyStyledHighcharts, coloredCategories]);

  return (
    <Tile sx={{ overflow: 'auto' }}>
      <Stack minWidth={700} gap={6}>
        <Stack direction="row" justifyContent="space-between">
          <Text tag="h2" variant="heading" size="S" color="text1">
            {t('TITLE')}
          </Text>
          <Stack direction="row" gap={4}>
            {coloredCategories!.map((category) => (
              <Stack key={category.name} direction="row" gap={1} alignItems="center">
                <ColorSquare color={category.color} />
                <Text variant="body-regular" size="S" children={t(`LEGEND.${category.name.toUpperCase()}`)} />
                <Text variant="body-regular" size="S" children="-" />
                <Text variant="body-bold" size="S" children={formatPercentage(category.value / 100)} />
              </Stack>
            ))}
          </Stack>
        </Stack>
        <Stack borderRadius={2} overflow="hidden">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </Stack>
      </Stack>
    </Tile>
  );
};

export default SizeGraph;
