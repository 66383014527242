import React from 'react';
import { Stack, Tooltip, Typography, useTheme } from '@mui/material';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { formatCurrency } from '@vertice/core/src/utils/formatting/currency';

type PercentageTileProps = {
  value: number;
  previousValue: number;
  currency?: string;
  selected?: boolean;
};

const PercentageTile = ({ value, previousValue, currency, selected }: PercentageTileProps) => {
  const { locale } = useLocaleContext();
  const { palette } = useTheme();

  if (Math.abs(previousValue) < 0.5) {
    return null;
  }

  const roundedPreviousValue = Math.max(previousValue, 1);
  const formattedPreviousValue =
    (previousValue < 1 ? '< ' : '') +
    formatCurrency(roundedPreviousValue, {
      currency,
      locale,
      maximumFractionDigits: 0,
    });

  const percentageValue = Math.round(((value - previousValue!) * 100) / previousValue!);

  if (percentageValue === 0) {
    return null;
  }

  const percentageColor = percentageValue && percentageValue < 0 ? palette.success.color1 : palette.error.color1;
  const percentageTrendStyle = {
    width: '14px',
    height: '14px',
    color: selected ? percentageColor : palette.inactive.color2,
  };

  return (
    <Stack direction="row">
      {percentageValue > 0 ? (
        <ExpandLessOutlinedIcon sx={percentageTrendStyle} />
      ) : (
        <ExpandMoreOutlinedIcon sx={percentageTrendStyle} />
      )}
      <Tooltip title={formattedPreviousValue}>
        <Typography variant="body-bold-xs" color={selected ? percentageColor : palette.inactive.color2}>
          {Math.abs(percentageValue) + '%'}
        </Typography>
      </Tooltip>
    </Stack>
  );
};

export default PercentageTile;
