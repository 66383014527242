import React, { ReactElement, ReactNode, useMemo } from 'react';
import { Stack } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ArrowForwardIos';
import styled from '@mui/material/styles/styled';
import IconWrapper from '../IconWrapper/IconWrapper';
import Text from '../Text';
import type { DesignSystemSize } from '../../types';

import BreadcrumbsContext from './BreadcrumbsContext';
import { TestProps } from '../../types';
import { testProps } from '../../utils/testProperties';

type BreadcrumbsProps = TestProps & {
  variant: 'chevron' | 'slash';
  truncated?: boolean;
  size: DesignSystemSize;
  children: ReactNode;
};

const StyledSlash = styled((props) => <Text variant="body-regular" {...props} />)(({ theme }) => ({
  color: theme.palette.core.color4,
}));

const StyledChevron = styled(IconWrapper)(({ theme }) => ({
  color: theme.palette.core.color4,
}));

const Breadcrumbs = ({ variant, truncated, size, children, testId }: BreadcrumbsProps) => {
  const modifiedChildren: Array<ReactNode> = useMemo(() => {
    const divider = {
      chevron: <StyledChevron aria-hidden size={size} icon={ChevronRightIcon} />,
      slash: (
        <StyledSlash aria-hidden size={size}>
          /
        </StyledSlash>
      ),
    }[variant];

    return React.Children.toArray(children)
      .map((child, index, arr) => {
        if (!React.isValidElement(child)) {
          return null;
        }

        return [
          React.cloneElement<any>(child as ReactElement, {
            truncated: truncated && ![0, arr.length - 1].includes(index),
          }),
          ...(index !== arr.length - 1 ? [divider] : []),
        ];
      })
      .flat()
      .map((child, index) => React.cloneElement(child as ReactElement, { key: index }));
  }, [variant, truncated, size, children]);

  return (
    <BreadcrumbsContext.Provider value={{ size }}>
      <Stack direction="row" alignItems="center" gap={2} component="nav" {...testProps(testId, 'breadcrumbs')}>
        {modifiedChildren}
      </Stack>
    </BreadcrumbsContext.Provider>
  );
};

export default Breadcrumbs;
