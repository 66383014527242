import { coreAppGraphQL } from '@vertice/slices';

const CONTRACT_DRAFT = 'ContractDraft';

export const enhancedCoreAppGraphQL = coreAppGraphQL.api.enhanceEndpoints({
  addTagTypes: [CONTRACT_DRAFT],
  endpoints: {
    GetContractDraft: {
      providesTags: [CONTRACT_DRAFT],
    },
    GetContractDrafts: {
      providesTags: [CONTRACT_DRAFT],
    },
    CreateContractDraft: {
      invalidatesTags: [CONTRACT_DRAFT],
    },
    UpdateContractDraft: {
      invalidatesTags: [CONTRACT_DRAFT],
    },
    DeleteContractDraft: {
      invalidatesTags: [CONTRACT_DRAFT],
    },
  },
});
