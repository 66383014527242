import { useEffect, useState } from 'react';
import { FileAccepted, FileRejected } from '@verticeone/design-system/src/components/Fileupload/utils';
import { autoDownload } from '@vertice/core/src/utils/file/downloadFile';
import {
  useDeleteFileMutation,
  useLazyGetPreSignedLinkQuery,
  useListFilesQuery,
  useUploadFileMutation,
} from '@vertice/slices';
import { useRequestDetails } from './useRequestDetails';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { getFiles } from '@vertice/core/src/modules/intelligentWorkflows/utils';

const useRequestDocuments = (requestId: string) => {
  const [files, setFiles] = useState<FileAccepted[]>([]);
  const { request } = useRequestDetails(requestId);
  const { accountId } = useAccountContext();

  const [uploadRequestFile] = useUploadFileMutation();
  const [deleteRequestFile] = useDeleteFileMutation();
  const [getPreSignedLink] = useLazyGetPreSignedLinkQuery();

  const { data: standardAssetsData, isFetching } = useListFilesQuery({
    accountId: accountId,
    'path+': `requests/${requestId}/files`,
  });

  useEffect(() => {
    const fileList = getFiles(standardAssetsData?.files || []);

    const mappedAttachments = fileList.map(
      (attachment) =>
        ({
          name: attachment.fileName,
          size: attachment.fileSize,
          lastModified: attachment.lastModified ? new Date(attachment.lastModified).getTime() : 0,
        } as unknown as File)
    );
    setFiles(mappedAttachments.map((file) => ({ file })));
  }, [standardAssetsData]);

  const filePath = (fileName: string) => {
    return `requests/${requestId}/files/${fileName}`;
  };

  const uploadFiles = async (newFiles: FileAccepted[]) => {
    const originalFiles = files.map((file) => file.file);
    const allAcceptedFiles = [...(originalFiles ?? []), ...newFiles.map((file) => file.file)];
    setFiles(allAcceptedFiles.map((file) => ({ file, status: 'done' })));
    if (request) {
      const uploadPromises: Promise<string>[] = [];
      newFiles.forEach((newFile) => {
        void uploadRequestFile({
          accountId: accountId,
          'path+': filePath(newFile.file.name),
          body: newFile.file,
        }).unwrap();
      });

      await Promise.all(uploadPromises);
    }
  };

  const deleteFile = async (removedFiles: FileAccepted[]) => {
    const originalFiles = files.map((file) => file.file);
    const removedFileNames = removedFiles.map((f) => f.file.name);
    const allAcceptedFiles = originalFiles?.filter((f) => !removedFileNames.includes(f.name)) ?? [];
    setFiles(allAcceptedFiles.map((file) => ({ file, status: 'deleted' })));

    if (request) {
      const removePromises: Promise<unknown>[] = removedFiles.map((file) => {
        return deleteRequestFile({
          accountId: accountId,
          'path+': filePath(file.file.name),
        }).unwrap();
      });

      await Promise.all(removePromises);
    }
  };

  const downloadFile = async (file: FileAccepted | FileRejected) => {
    const fileName = file.file.name;
    if (request) {
      void getPreSignedLink({
        accountId: accountId,
        'path+': filePath(fileName),
        responseContentDisposition: 'attachment',
      })
        .unwrap()
        .then((reply) => {
          if (reply.preSignedLink) {
            autoDownload(reply.preSignedLink, fileName);
          }
        });
    }
  };

  return {
    files: files,
    isFetching: isFetching && !files,
    uploadFiles,
    deleteFile,
    downloadFile,
    allowDeletion: !(request?.status === 'COMPLETED'),
  };
};

export default useRequestDocuments;
