import React, { useCallback, useMemo } from 'react';
import { FieldItem, FieldType } from '../../types';
import { MultiChipContainer } from '@verticeone/design-system/src/components/ChipButton';
import { useTranslation } from 'react-i18next';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import Text from '@verticeone/design-system/src/components/Text';
import { DesignSystemColor, DesignSystemVariant } from '@verticeone/design-system/src/types';
import { CustomContractFieldDropdownOption } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { Box, styled } from '@mui/material';

const StyledChipLabel = styled('span')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const ReadOnlyOptionCell = ({
  value,
  row,
}: GridRenderCellParams<FieldItem, CustomContractFieldDropdownOption[] | undefined>) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.CONTRACT_CUSTOMIZATION' });
  const isDropdown = row.fieldType?.type === FieldType.DROPDOWN;

  const mapOptionToChip = useCallback(
    (option: CustomContractFieldDropdownOption) => ({
      children: <StyledChipLabel>{option.label}</StyledChipLabel>,
      label: option.label,
      color: 'primary' as DesignSystemColor,
      variant: 'ghost' as DesignSystemVariant,
      isActive: false,
    }),
    []
  );

  const chips = useMemo(
    () => value?.filter((option: CustomContractFieldDropdownOption) => option.enabled).map(mapOptionToChip) ?? [],
    [mapOptionToChip, value]
  );

  if (!isDropdown) {
    return (
      <Text variant="body-regular" color="text3">
        {t('NOT_APPLICABLE')}
      </Text>
    );
  }

  return (
    <Box width="100%">
      <MultiChipContainer size="M" isActive={false} maxChips={3} chips={chips} />
    </Box>
  );
};

export default ReadOnlyOptionCell;
