import React from 'react';
import { useCloudAccounts } from '../CloudAccountsContext';
import BaseCloudAccountsStats from './BaseCloudAccountsStats';
import useAccountServicesUsageData from '../Table/useAccountServicesUsageData';

type CloudAccountServicesUsageStatsProps = {
  linkedAccountId: string;
  productCode: string;
};

const CloudAccountServicesUsageStats = ({ linkedAccountId, productCode }: CloudAccountServicesUsageStatsProps) => {
  const { period } = useCloudAccounts();
  const { data, isLoading } = useAccountServicesUsageData({
    linkedAccountId,
    productCode,
    ...period,
  });
  return <BaseCloudAccountsStats data={data?.stats} isLoading={isLoading} />;
};

export default CloudAccountServicesUsageStats;
