import { z } from 'zod';

export const formDataProductItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  productId: z.string(),
  annualCost: z.number().nullable(),
  numberOfLicences: z.number().nullable(),
});
export type FormDataProductItem = z.infer<typeof formDataProductItemSchema>;

export const vendorSchema = z.object({
  type: z.string(),
  id: z.string(),
  name: z.string(),
  label: z.string().optional(),
});
export type Vendor = z.infer<typeof vendorSchema>;
