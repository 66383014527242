import { maxBy, minBy } from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';

import { fetchCloudOptimization } from '../CloudClientProvider';
import { ContractYear } from './useContractCreate';

export const QUERY_KEY = 'EDPContractValidate' as const;

const EDPContractValidateQuery = graphql(`
  query EDPContractValidate($accountId: ID!, $costModel: CostModelInput!) {
    validateContractModel(params: { accountId: $accountId, costModel: $costModel }) {
      ... on EDPValidModel {
        __typename
        success
      }
      ... on EDPValidationError {
        __typename
        fieldName
        code
        context
      }
      ... on ErroredQueryResult {
        __typename
        error
      }
    }
  }
`);

export const useContractValidator = () => {
  const { accountId } = useAccountContext();
  const queryClient = useQueryClient();

  return (newContractYears: Array<ContractYear>) =>
    queryClient.ensureQueryData({
      queryKey: [QUERY_KEY, newContractYears],
      queryFn: () =>
        fetchCloudOptimization(EDPContractValidateQuery, {
          accountId,
          costModel: {
            startDate: minBy(newContractYears, (year) => new Date(year.startDate).getTime())!.startDate,
            endDate: maxBy(newContractYears, (year) => new Date(year.endDate).getTime())!.endDate,
            costAllocationSpans: newContractYears.map((year, index) => ({
              startDate: year.startDate,
              endDate: year.endDate,
              commitment: year.commitment,
              discount: year.discount,
              name: `Term ${index + 1}`,
            })),
          },
        }),
    });
};
