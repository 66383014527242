import { DateRange, PickersShortcutsItem } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

export type ShortcutItemsWithLastBillingPeriodCount = {
  lastBillingPeriodInMonths: number;
} & PickersShortcutsItem<DateRange<Dayjs>>;

export const calculateLastBillingPeriodDate = () => {
  const today = dayjs().startOf('day');
  const dataGenerationDayOfMonth = 8;
  const startOfMonth = today.startOf('month');
  return today.date() <= dataGenerationDayOfMonth ? startOfMonth.subtract(1, 'month') : startOfMonth;
};

export const useShortcutsItems = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.ACCOUNTS.FILTER.DATE_RANGE' });
  const prevMonth = calculateLastBillingPeriodDate().subtract(1, 'month');

  const shortcutsItems: Array<ShortcutItemsWithLastBillingPeriodCount> = [
    {
      lastBillingPeriodInMonths: 1,
      label: t('LAST_BILLING_PERIOD'),
      getValue: () => {
        return [prevMonth.startOf('month'), prevMonth.endOf('month')];
      },
    },
    {
      lastBillingPeriodInMonths: 3,
      label: t('LAST_BILLING_PERIODS', { count: 3 }),
      getValue: () => {
        return [prevMonth.subtract(2, 'month').startOf('month'), prevMonth.endOf('month')];
      },
    },
    {
      lastBillingPeriodInMonths: 6,
      label: t('LAST_BILLING_PERIODS', { count: 6 }),
      getValue: () => {
        return [prevMonth.subtract(5, 'month').startOf('month'), prevMonth.endOf('month')];
      },
    },
    {
      lastBillingPeriodInMonths: 12,
      label: t('LAST_BILLING_PERIODS', { count: 12 }),
      getValue: () => {
        return [prevMonth.subtract(11, 'month').startOf('month'), prevMonth.endOf('month')];
      },
    },
  ];

  return shortcutsItems;
};
