import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColDef, GridRenderCellParams, GridRowModel } from '@mui/x-data-grid-pro';
import { Box, styled } from '@mui/material';
import Loader from '@verticeone/design-system/src/components/Loader';
import DataGrid, { DataGridProps } from '@verticeone/design-system/src/components/DataGrid';
import AddItemFooter from './AddItemFooter';
import NoRowsOverlay from './NoRowsOverlay';
import { useCustomFieldsListContext } from './CustomFieldsListContextProvider';
import {
  fieldTypeComparator,
  isShownComparator,
  labelComparator,
  preProcessDropdownValuesCell,
  preProcessFieldTypeCell,
  preProcessLabelCell,
} from './utils';
import { FieldItem } from './types';
import ToggleSwitchCell from './components/cells/ToggleSwitchCell';
import ReadOnlyFieldTypeCell from './components/cells/ReadOnlyFieldTypeCell';
import EditableFieldTypeCell from './components/cells/EditableFieldTypeCell';
import EditableOptionCell from './components/cells/EditableOptionCell';
import ReadOnlyOptionCell from './components/cells/ReadOnlyOptionCell';
import SetValuesDialog from './components/SetValuesDialog';

export interface CustomFieldsListProps {
  extraColumns?: GridColDef[];
  dataGridExtraProps?: Partial<DataGridProps<FieldItem>>;
  readOnlyMode?: boolean;
  noBorder?: boolean;
}

const StyledBox = styled(Box)(({ theme }) => ({
  '& .Mui-error': {
    height: '100%',
    border: '1px solid',
    borderColor: theme.palette.error.color1,
  },
}));

const CustomFieldsList: FC<CustomFieldsListProps> = ({
  extraColumns = [],
  dataGridExtraProps = {},
  readOnlyMode,
  noBorder,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.CONTRACT_CUSTOMIZATION' });
  const { fields, isLoading } = useCustomFieldsListContext();

  const columns: GridColDef[] = [
    {
      field: 'isVisible',
      headerName: t('IS_SHOWN'),
      valueParser: (value?: boolean) => !!value,
      disableColumnMenu: true,
      sortComparator: isShownComparator,
      editable: true,
      renderCell: (params: GridRenderCellParams) => <ToggleSwitchCell disabled={true} {...params} />,
      renderEditCell: (params: GridRenderCellParams) => <ToggleSwitchCell {...params} />,
      width: 120,
    },
    {
      field: 'fieldType',
      headerName: t('FIELD_TYPE'),
      preProcessEditCellProps: preProcessFieldTypeCell,
      disableColumnMenu: true,
      sortComparator: fieldTypeComparator,
      editable: true,
      renderCell: (params: GridRenderCellParams) => <ReadOnlyFieldTypeCell {...params} />,
      renderEditCell: (params: GridRenderCellParams) => <EditableFieldTypeCell {...params} />,
      flex: 1,
    },
    {
      field: 'label',
      headerName: t('LABEL'),
      preProcessEditCellProps: preProcessLabelCell(fields),
      valueParser: (value: string) => value.trimStart(),
      disableColumnMenu: true,
      sortComparator: labelComparator,
      editable: true,
      flex: 1,
    },
    {
      field: 'options',
      headerName: t('VALUES'),
      preProcessEditCellProps: preProcessDropdownValuesCell,
      disableColumnMenu: true,
      sortable: false,
      editable: true,
      renderCell: (params: GridRenderCellParams) => <ReadOnlyOptionCell {...params} />,
      renderEditCell: (params: GridRenderCellParams) => <EditableOptionCell {...params} />,
      flex: 1,
    },
  ];

  const columnsToUse = [...columns, ...extraColumns];

  if (!fields || isLoading) {
    return (
      <Box p={6}>
        <Loader />
      </Box>
    );
  }

  return (
    <StyledBox>
      <DataGrid
        rows={fields || []}
        columns={columnsToUse}
        getRowId={(row: GridRowModel) => row.id}
        slots={{
          footer: AddItemFooter,
          noRowsOverlay: () => <NoRowsOverlay />,
        }}
        sortingMode="client"
        isCellEditable={() => false}
        disableRowSelectionOnClick
        hideFooter
        columnHeaderHeight={48}
        autoHeight={true}
        noBorder={noBorder}
        noBorderRadius={noBorder}
        {...dataGridExtraProps}
      />
      <SetValuesDialog />
    </StyledBox>
  );
};

export default CustomFieldsList;
