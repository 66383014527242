import { SimplifiedStage, ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { Stage } from '@vertice/core/src/modules/saas/schedule/components/ScheduleView/useScheduleView';
import { DASHBOARD_FILTERS } from './types';
import { isContractPurchase } from '@vertice/core/src/modules/saas/contract/computed';
import { evaluateFilterView } from '@vertice/core/src/modules/saas/contract/components/ContractList/functions';

export const getScheduleStage = (viewOnContract: ViewOnContract) => {
  for (const [stage, filterView] of Object.entries(DASHBOARD_FILTERS)) {
    if (evaluateFilterView(filterView, viewOnContract)) {
      return stage as Stage;
    }
  }
};

const isFinished = (stage?: SimplifiedStage) => {
  return stage ? ['NOT_YET_LIVE', 'LIVE'].includes(stage) : false;
};

export const getScheduleDate = ({ contract }: ViewOnContract, scheduleStage?: Stage): string | undefined => {
  if (scheduleStage === 'RECENTLY_COMPLETED' && isFinished(contract.materialized?.next?.simplifiedStage)) {
    return contract.materialized?.next?.signingDate;
  } else if (
    scheduleStage === 'RECENTLY_COMPLETED' &&
    isContractPurchase(contract) &&
    isFinished(contract.materialized?.simplifiedStage) &&
    (!contract.materialized?.next || !isFinished(contract.materialized?.next?.simplifiedStage))
  ) {
    return contract.parts.contractual?.signatory?.signingDate;
  }

  return contract.materialized?.renewalDeadline?.deadlineDate;
};
