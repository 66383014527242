import React, { useCallback, useMemo, useState } from 'react';
import Highcharts, { Chart as HighchartsChart } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Box, Stack, useTheme } from '@mui/material';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { useTranslation } from 'react-i18next';
import { rgba } from 'polished';
import { OptimizedVsUnoptimizedData } from './useOptimizedVsUnoptimizedData';
import {
  monthFormatter,
  yLabelCurrencyFormatter,
} from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import TooltipValueWithTimeInfo from '@vertice/core/src/components/charts/components/Tooltip/TooltipValueWithTimeInfo';
import extractLegendItemsFromSeries from '@vertice/core/src/components/charts/highcharts-specific/utils/extractLegendItemsFromSeries';
import { useCustomCrosshair } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useCustomCrosshair';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { useFormatCurrency } from '@vertice/core/src/utils/formatting/currency';
import { AWS_DEFAULT_CURRENCY_DECIMAL_PLACES } from '../../constants';

type OptimizedVsUnoptimizedLineChartProps = {
  data: OptimizedVsUnoptimizedData;
};

enum SeriesId {
  CURRENT_MONTHLY_SPEND = 'current-monthly-spend',
  OPTIMIZED_FORECAST = 'optimized-forecast',
  UNOPTIMIZED_FORECAST = 'unoptimized-forecast',
}

/** Translation keys of labels displayed in tooltips for different series */
const seriesTooltipLabels: Record<SeriesId, string | undefined> = {
  [SeriesId.UNOPTIMIZED_FORECAST]: 'UNOPTIMIZED',
  [SeriesId.OPTIMIZED_FORECAST]: 'OPTIMIZED',
  [SeriesId.CURRENT_MONTHLY_SPEND]: undefined,
};

const CHART_HEIGHT = 483;
const MAX_POINT_TOP_PADDING_PX = 65;

const OptimizedVsUnoptimizedLineChart = ({
  data: { pastData, unoptimizedForecastData, optimizedForecastData, currency },
}: OptimizedVsUnoptimizedLineChartProps) => {
  const { palette } = useTheme();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { locale } = useLocaleContext();
  const formatCurrency = useFormatCurrency();
  const tooltipXAxisValueFormatter = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'short',
  });

  const [chart, setChart] = useState<HighchartsChart | null>(null);
  const saveChartRef = useCallback((ch: HighchartsChart) => setChart(ch), []);
  const applyCustomCrosshair = useCustomCrosshair(palette.core.color6, 1);
  const applyStyledHighcharts = useStyledHighcharts();
  const lastKnownData = useMemo(() => (pastData.length ? pastData[pastData.length - 1] : null), [pastData]);

  const options = useMemo(() => {
    return buildOptions([
      applyStyledHighcharts,
      applyCustomCrosshair,
      mergeOptions({
        chart: {
          height: CHART_HEIGHT,
          spacing: [0, 0, 0, 0],
          marginTop: 0,
        },
        plotOptions: {
          series: {
            states: {
              inactive: { opacity: 1 },
              hover: {
                enabled: true,
                halo: { size: 0 },
              },
            },
            marker: { enabled: false },
            lineWidth: 1,
          },
        },
        yAxis: {
          labels: { formatter: yLabelCurrencyFormatter(palette, locale, currency) },
          showLastLabel: false,
          maxPadding: MAX_POINT_TOP_PADDING_PX / CHART_HEIGHT,
        },
        xAxis: {
          type: 'datetime',
          labels: { formatter: monthFormatter },
          showFirstLabel: false,
          plotBands: unoptimizedForecastData.length
            ? [
                {
                  // forecast background
                  color: rgba(palette.visualization.divergent.secondary['+10'], 0.5),
                  from: unoptimizedForecastData[0][0],
                  to: unoptimizedForecastData[unoptimizedForecastData.length - 1][0],
                },
              ]
            : undefined,
        },
        tooltip: { shared: true },
        series: [
          {
            id: SeriesId.CURRENT_MONTHLY_SPEND,
            name: t('ESTIMATED_SAVINGS.CURRENT_MONTHLY_SPEND'),
            type: 'area',
            color: palette.core.color5,
            fillColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                [0, rgba(palette.core.color5, 0.2)],
                [1, rgba(palette.core.color5, 0)],
              ],
            },
            data: pastData,
          },
          {
            id: SeriesId.UNOPTIMIZED_FORECAST,
            name: t('ESTIMATED_SAVINGS.UNOPTIMIZED_FORECAST'),
            type: 'line',
            color: palette.visualization.divergent.secondary['+50'],
            data: unoptimizedForecastData,
          },
          {
            id: SeriesId.OPTIMIZED_FORECAST,
            name: t('ESTIMATED_SAVINGS.OPTIMIZED_FORECAST'),
            type: 'line',
            dashStyle: 'Dash',
            color: palette.visualization.divergent.secondary['+50'],
            data: optimizedForecastData,
          },
        ],
      }),
    ]);
  }, [
    applyCustomCrosshair,
    applyStyledHighcharts,
    currency,
    locale,
    optimizedForecastData,
    palette,
    pastData,
    t,
    unoptimizedForecastData,
  ]);

  return (
    <Stack position="relative">
      <Box position="absolute" width="auto" height="auto" top="22px" right="24px" zIndex={1}>
        <Legend
          items={extractLegendItemsFromSeries(options).map((legendItem) => {
            if (legendItem.id === SeriesId.OPTIMIZED_FORECAST) {
              return { ...legendItem, outlined: true };
            }
            return legendItem;
          })}
        />
      </Box>
      <HighchartsReact highcharts={Highcharts} options={options} callback={saveChartRef} />
      <HighchartTooltip chart={chart}>
        {({ points }) => (
          <TooltipWrapper>
            {!!points && points.length > 0 && (
              <TooltipValueWithTimeInfo value="" timeInfo={tooltipXAxisValueFormatter.format(new Date(points[0].x!))} />
            )}
            {points
              ?.filter((point) => {
                const seriesId = point.series.userOptions.id as SeriesId;
                // Ignore the helper connector point at the end of historical data series
                return !(seriesId === SeriesId.CURRENT_MONTHLY_SPEND && lastKnownData && point.x === lastKnownData[0]);
              })
              .map((point, pointIndex) => {
                const formattedValue = formatCurrency(point.y!, {
                  currency,
                  maximumFractionDigits: AWS_DEFAULT_CURRENCY_DECIMAL_PLACES,
                });
                const seriesId = point.series.userOptions.id as SeriesId;
                const valueSuffix =
                  seriesId && seriesTooltipLabels[seriesId]
                    ? t(`ESTIMATED_SAVINGS.${seriesTooltipLabels[seriesId]!}`)
                    : undefined;
                return (
                  <TooltipValueWithTimeInfo value={formattedValue} valueSuffix={valueSuffix} key={point.series.name} />
                );
              })}
          </TooltipWrapper>
        )}
      </HighchartTooltip>
    </Stack>
  );
};

export default OptimizedVsUnoptimizedLineChart;
