import { useCloudAccounts } from '../../CloudAccountsContext';
import React from 'react';
import CloudAccountServicesGraph from './CloudAccountServicesGraph';
import CloudAccountServicesUsageGraph from './CloudAccountServicesUsageGraph';
import CloudAccountsGraph from './CloudAccountsGraph';

const GraphByLastNode = () => {
  const { node, getLastNode } = useCloudAccounts();
  const lastNode = getLastNode();

  switch (lastNode.type) {
    case 'account':
      return <CloudAccountServicesGraph linkedAccountId={lastNode.id} />;
    case 'service':
      return <CloudAccountServicesUsageGraph linkedAccountId={node?.children?.id || ''} productCode={lastNode.id} />;
    default:
      return <CloudAccountsGraph />;
  }
};

export default GraphByLastNode;
