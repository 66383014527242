import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import useDeferredQuery from '@vertice/core/src/utils/api/useDeferredQuery';
import {
  TableType,
  TestDataPoint,
  useTestResultsQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { addMonths, startOfMonth, subMonths } from 'date-fns';
import format from 'date-fns/format';
import {
  DATE_FORMAT,
  fillMissingMonths,
  getTableData,
} from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';
import { useMemo } from 'react';
import { chain, flatMap, sumBy, uniq } from 'lodash';
import toConstantCase from '@vertice/core/src/utils/strings/toConstantCase';
import { AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';
import { HistoricalSpendByEBSGenerationData } from './types';
import LoadableAdvanced from '@vertice/utils/src/loadableAdvanced';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

const splitIntoSeries = (items: { time: string; values: Record<string, number | null> }[]) => {
  const usedCategories = uniq(flatMap(items, ({ values }) => Object.keys(values)));

  return {
    usedCategories,
    series: usedCategories.map((category) => ({
      id: category,
      data: items.map(({ values: valuePerCategory }) => valuePerCategory[category] ?? null),
    })),
  };
};

export const prepareData = (
  rawData: TestDataPoint[] | undefined,
  t: TFunction
): HistoricalSpendByEBSGenerationData | undefined => {
  if (!rawData) {
    return undefined;
  }

  const allMonthsData = chain(rawData)
    .map((rec) => {
      const time = format(subMonths(new Date(rec.time), 1), DATE_FORMAT);
      if (rec.resultDetails.__typename === 'MulticlassBinnedTestResult' && rec.resultDetails.dataViews) {
        const dataViews = rec.resultDetails.dataViews.find((dataView) => dataView.name === 'type_to_cost');
        if (dataViews) {
          const tableData = getTableData(
            {
              columns: dataViews.columns,
              data: dataViews.data,
              dataTypes: dataViews.types,
            } as TableType,
            {
              usage_type: 'usageType',
              is_legacy: 'isLegacy',
              sum_cost: 'cost',
            }
          ) as {
            usageType: string;
            cost: number;
            isLegacy: boolean;
          }[];

          const tableDataWithGeneration = tableData?.map((row) => ({
            ...row,
            generation: row.isLegacy ? 'legacy' : 'current',
          }));

          return {
            time,
            values: chain(tableDataWithGeneration)
              .groupBy(({ generation }) => generation)
              .mapValues((generationItems) => sumBy(generationItems, ({ cost }) => cost))
              .value(),
          };
        }
      }
      return {
        time,
        values: {},
      };
    })
    .filter(Boolean)
    .orderBy('time', 'asc')
    .thru((items) => (items.length > 0 ? fillMissingMonths(items, new Date(items[0].time), {}) : items))
    .value();

  const { series: seriesRaw, usedCategories } = splitIntoSeries(allMonthsData);

  const series = seriesRaw.map((serie) => ({
    ...serie,
    name: t(`EBS_STORAGE_DISTRIBUTION.GENERATIONS.${toConstantCase(serie.id)}`) ?? serie.id,
  }));

  return {
    series,
    usedCategories,
    months: allMonthsData.map(({ time }) => time),
    currency: AWS_DEFAULT_CURRENCY,
  };
};

const startDate = addMonths(startOfMonth(new Date()), -11);

const useHistoricalSpendByEBSGenerationData = (): LoadableAdvanced<HistoricalSpendByEBSGenerationData> => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { accountId } = useAccountContext();

  const {
    data: testResultData,
    error: testResultError,
    isLoading,
  } = useDeferredQuery(
    useTestResultsQuery,
    {
      accountId,
      startDate: format(startDate, DATE_FORMAT),
      testCode: 'VT-31',
    },
    { skip: !accountId, pollingInterval: 5000 },
    (response) =>
      response.testResultsQuery?.__typename === 'DeferredQueryResult' ? undefined : response.testResultsQuery
  );

  const computedTestResults = useMemo(() => prepareData(testResultData as TestDataPoint[], t), [testResultData, t]);

  return {
    error: testResultError,
    isEmpty: Boolean(!computedTestResults || computedTestResults.series.length === 0),
    isLoading: isLoading,
    data: computedTestResults,
  };
};

export default useHistoricalSpendByEBSGenerationData;
