import { useTranslation } from 'react-i18next';

import Button from '@verticeone/design-system/src/components/Button';
import Text from '@verticeone/design-system/src/components/Text';

type ErrorLogButtonProps = {
  onClick: () => void;
};

export const ErrorLogButton = ({ onClick }: ErrorLogButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button color="primary" variant="outline" onClick={onClick}>
      <Text variant="button" size="S">
        {t('APPLICATIONS.USAGE.ERROR_LOG.BUTTON')}
      </Text>
    </Button>
  );
};
