import CommentSkeleton from '../Comment/CommentSkeleton';

const CommentLoading = () => {
  return (
    <>
      {Array.from({ length: 4 }).map((_, index) => (
        <CommentSkeleton key={['comment-skeleton', index].join()} />
      ))}
    </>
  );
};

export default CommentLoading;
