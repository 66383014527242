import { useCloudAccounts } from '../../CloudAccountsContext';
import CloudAccountServicesTable from './CloudAccountServicesTable';
import CloudAccountServicesUsageTable from './CloudAccountServicesUsageTable';
import CloudAccountsTable from './CloudAccountsTable';
import React from 'react';

const TableByLastNode = () => {
  const { node, getLastNode } = useCloudAccounts();
  const lastNode = getLastNode();

  switch (lastNode.type) {
    case 'account':
      return <CloudAccountServicesTable linkedAccountId={lastNode.id} />;
    case 'service':
      return <CloudAccountServicesUsageTable linkedAccountId={node?.children?.id || ''} productCode={lastNode.id} />;
    default:
      return <CloudAccountsTable />;
  }
};

export default TableByLastNode;
