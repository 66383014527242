import React, { useMemo } from 'react';
import { IntelligentWorkflowsLayout } from '../../components/IntelligentWorkflowsLayout';
import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from '@verticeone/design-system/src/components/Link';

import Text from '@verticeone/design-system/src/components/Text';
import Accordion from '@verticeone/design-system/src/components/Accordion';
import AccordionSummary from '@verticeone/design-system/src/components/Accordion/AccordionSummary';
import AccordionDetails from '@verticeone/design-system/src/components/Accordion/AccordionDetails';
import Divider from '@verticeone/design-system/src/components/Divider';
import { Link as RouterLink } from 'react-router-dom';

import { useRequestDetails } from './useRequestDetails';
import { RequestDetailSkeleton } from './RequestDetailSkeleton';
import DocumentsSection from './sections/DocumentsSection';
import { TasksSection, DetailsSection, VisualizerSection, DiligenceInsightsSection, JournalSection } from './sections';
import { RequestHeaderMenu } from './components/RequestHeaderMenu';
import { RequestStatusChip } from '../components/RequestStatusChip';
import { useRequestsNavigation } from '../hooks/useRequestsNavigation';
import { parseRequestRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { GenericRequestIcon } from '../../components/icons/Icons';
import { UsersContextProvider } from '@vertice/core/src/contexts/UsersContext';
import { Conversation } from './sections/Conversation/Conversation';

export const RequestDetailPage = () => {
  const { t } = useTranslation();

  const params = useParams() as { requestId: string };
  const { request, parentRequest, isLoading, workflowVersion, isExecutedByWorkflow } = useRequestDetails(
    params.requestId
  );

  const { generateRequestPath } = useRequestsNavigation();
  const parentRequestPath = parentRequest
    ? generateRequestPath(parseRequestRef(parentRequest.ref).requestId)
    : undefined;

  const header = useMemo(() => {
    return (
      <Stack>
        {parentRequest && (
          <Stack direction="row" alignItems="center" gap={1}>
            <GenericRequestIcon />
            <Text variant="body-regular" size="M">
              <Link component={RouterLink} to={parentRequestPath!}>
                {parentRequest?.name}
              </Link>
            </Text>
            <RequestStatusChip status={parentRequest?.status} variant="outline" size="XS" />
          </Stack>
        )}
        {request && (
          <Stack direction="row" alignItems="center" gap={1}>
            <GenericRequestIcon size="XL" />
            <Text variant="heading" size="M">
              {request?.name}
            </Text>
            <RequestStatusChip status={request?.status} variant="solid" />
          </Stack>
        )}
      </Stack>
    );
  }, [parentRequest, parentRequestPath, request]);

  if (isLoading || request === undefined) {
    return (
      <IntelligentWorkflowsLayout isLoading={isLoading}>
        <RequestDetailSkeleton />
      </IntelligentWorkflowsLayout>
    );
  }

  const requestVendorId = request?.input?.vertice?.vendor?.id;

  const accordionProps = {
    defaultExpanded: true,
    variant: 'label' as const,
    size: 'XS' as const,
  };

  return (
    <IntelligentWorkflowsLayout
      heading={header}
      currentPageName={request.name}
      parentPageName={parentRequest?.name}
      parentPagePath={parentRequestPath}
      actions={<RequestHeaderMenu request={request} />}
      isLoading={isLoading}
    >
      <Stack gap={4}>
        {isExecutedByWorkflow && <VisualizerSection request={request} workflowVersion={workflowVersion} />}
        <Divider />
        <Stack gap={4} divider={<Divider />} direction={{ xs: 'column', md: 'row' }}>
          <Stack gap={4} divider={<Divider />}>
            <Accordion {...accordionProps}>
              <AccordionSummary title={t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.SECTIONS.ACTIVE_TASKS')} />
              <AccordionDetails>
                <TasksSection requestId={params.requestId} />
              </AccordionDetails>
            </Accordion>
            <Accordion {...accordionProps}>
              <AccordionSummary title={t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.SECTIONS.REQUEST_ACTIVITY')} />
              <AccordionDetails>
                <JournalSection requestId={params.requestId} />
              </AccordionDetails>
            </Accordion>
            <Accordion {...accordionProps}>
              <AccordionSummary title={t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.SECTIONS.REQUEST_DETAILS')} />
              <AccordionDetails>
                <DetailsSection request={request} workflowVersion={workflowVersion} />
              </AccordionDetails>
            </Accordion>
            {requestVendorId && (
              <Accordion {...accordionProps}>
                <AccordionSummary title={t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.SECTIONS.DILIGENCE_INSIGHTS')} />
                <AccordionDetails>
                  <DiligenceInsightsSection request={request} />
                </AccordionDetails>
              </Accordion>
            )}
            <Accordion {...accordionProps}>
              <AccordionSummary title={t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.SECTIONS.DOCUMENTS')} />
              <AccordionDetails>
                <DocumentsSection />
              </AccordionDetails>
            </Accordion>
          </Stack>
          <Stack minHeight={768} maxHeight={'100%'} minWidth={368}>
            <UsersContextProvider>
              <Conversation requestId={params.requestId} />
            </UsersContextProvider>
          </Stack>
        </Stack>
      </Stack>
    </IntelligentWorkflowsLayout>
  );
};
