import { useTranslation } from 'react-i18next';
import { Stack, useTheme } from '@mui/material';
import { CommentTwoTone } from '@mui/icons-material';

import Text from '../../Text';
import i18n from '../../../translate';

const EmptyComments = () => {
  const { t } = useTranslation(undefined, { i18n });
  const { palette } = useTheme();

  return (
    <Stack gap={7} justifyContent="center" alignItems="center" height={'100%'}>
      <CommentTwoTone htmlColor={palette.secondary.color2} />
      <Stack gap={1.75} alignItems="center" justifyContent="center">
        <Text variant="body-bold" size="XS" color="text1" textAlign="center">
          {t('COMMENTS.NO_COMMENTS.TITLE')}
        </Text>
        <Text variant="body-regular" size="XS" color="text1" textAlign="center">
          {t('COMMENTS.NO_COMMENTS.DESCRIPTION')}
        </Text>
      </Stack>
    </Stack>
  );
};

export default EmptyComments;
