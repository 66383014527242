import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useExchangeToAccountCurrency } from '../../../../utils/currency/useExchangeToAccountCurrency';
import { useCallback } from 'react';

const useGetContractEffectiveCurrency = () => {
  const { accountCurrency } = useExchangeToAccountCurrency();

  const getContractEffectiveCurrency = useCallback(
    (contract: Contract) => contract.parts.contractual?.financial?.baseCurrency ?? accountCurrency,
    [accountCurrency]
  );

  return getContractEffectiveCurrency;
};

export default useGetContractEffectiveCurrency;
