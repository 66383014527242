import type { Account, AccountSettings, Feature } from '@vertice/slices';
import { createRequiredContext } from '@verticeone/design-system/src/utils/contexts';

export type AccountContextType = {
  accountId: string;
  accountType: 'DEMO' | 'NORMAL' | 'WORKFLOWS_ADMIN';
  getFeature: (feature: string) => Feature | null;
  isFeatureEnabled: (feature: string) => boolean;
  accountDetails?: Account;
  accountSettings?: AccountSettings;
};

const { AccountContextProvider, useAccountContext } = createRequiredContext<AccountContextType, 'Account'>('Account');

export { useAccountContext, AccountContextProvider };
