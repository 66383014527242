import React, { FC } from 'react';
import { Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormatCurrency } from '../../../../../utils/formatting/currency';
import { FormatDateOptions, useFormatDate } from '../../../../../utils/formatting/date';

import Text from '@verticeone/design-system/src/components/Text';
import { Divider } from '@verticeone/design-system/src/components/TextFieldArea/components/Label';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

const DATE_FORMAT: FormatDateOptions = {
  fallbackValue: '-',
  formatterOptions: { month: 'short', day: 'numeric' },
};

const RegularText = styled(Text)(({ theme }) => ({
  color: theme.palette.text.color5,
}));
const RegularCoreText = styled(Text)(({ theme }) => ({
  color: theme.palette.text.color4,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxWidth: theme.spacing(30),
}));
const UpperText = styled(Text)(({ theme }) => ({
  color: theme.palette.text.color5,
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
}));
const UpperCoreText = styled(Text)(({ theme }) => ({
  color: theme.palette.text.color4,
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
}));

type ScheduleInfoProps = {
  scheduleDate: string;
  vendorId: string | undefined;
  vendorName: string | undefined;
  products: string[] | undefined;
  currency: string | undefined;
  annualCost: number | undefined;
};
export const VendorInfo: FC<ScheduleInfoProps> = ({ vendorName, products, annualCost, currency, scheduleDate }) => {
  const formatCurrency = useFormatCurrency();
  const formatDate = useFormatDate();
  const { t } = useTranslation();

  return (
    <Stack flexDirection="row" gap={4} justifyContent="space-between">
      <Stack justifyContent="space-between" paddingY={1} flex={1}>
        <RegularText size="M" variant="body-bold">
          {vendorName}
        </RegularText>
        <RegularCoreText size="S" variant="body-regular">
          {products?.join(', ')}
        </RegularCoreText>
      </Stack>

      <Stack justifyContent="space-between" paddingY={1} marginTop={0.75} flex={1}>
        <Stack flexDirection="row" justifyContent="space-between" gap={1}>
          <UpperCoreText size="XS" variant="body-bold">
            {t('DASHBOARD.SCHEDULE.ANNUAL_COST')}
          </UpperCoreText>
          <UpperText size="XS" variant="body-bold">
            {formatCurrency(annualCost || 0, {
              currency,
              maximumFractionDigits: 0,
            })}
          </UpperText>
        </Stack>
        <Stack flexDirection="row" justifyContent="space-between" gap={1}>
          <UpperCoreText size="XS" variant="body-bold">
            {t('DASHBOARD.SCHEDULE.DEADLINE')}
          </UpperCoreText>
          <UpperText size="XS" variant="body-bold">
            {formatDate(scheduleDate, DATE_FORMAT)}
          </UpperText>
        </Stack>
      </Stack>
    </Stack>
  );
};

const isRenewal = (contract: Contract) =>
  contract.record.contractOrigin === 'RENEWAL_WITHOUT_VERTICE' ||
  contract.record.contractOrigin === 'RENEWAL_WITH_VERTICE';

type TimelineInfoProps = {
  contract: Contract;
};
export const TimelineInfo: FC<TimelineInfoProps> = ({ contract }) => {
  const formatDate = useFormatDate();
  const { t } = useTranslation();

  const targetSignDate = contract.workflow?.externalDocuments?.targetSignDate;
  const autoRenewalWaived = !!contract.materialized?.prev?.autoRenewalWaived;
  const autoRenewalDeadline = contract.materialized?.prev?.autoRenewalDeadline;

  return (
    <>
      <Divider />
      <Stack flex={1}>
        <Stack flexDirection="row" justifyContent="space-between" gap={1}>
          <UpperCoreText size="XS" variant="body-bold">
            {t('DASHBOARD.SCHEDULE.TARGET_SIGN')}
          </UpperCoreText>
          <UpperText size="XS" variant="body-bold">
            {formatDate(targetSignDate, DATE_FORMAT)}
          </UpperText>
        </Stack>
        {isRenewal(contract) && (
          <>
            <Stack flexDirection="row" justifyContent="space-between" gap={1}>
              <UpperCoreText size="XS" variant="body-bold">
                {t('DASHBOARD.SCHEDULE.RENEWAL')}
              </UpperCoreText>
              <UpperText size="XS" variant="body-bold">
                {formatDate(contract.materialized?.prev?.renewalDate, DATE_FORMAT)}
              </UpperText>
            </Stack>
            <Stack flexDirection="row" justifyContent="space-between" gap={1}>
              <UpperCoreText size="XS" variant="body-bold">
                {t('DASHBOARD.SCHEDULE.AUTO_RENEWAL')}
              </UpperCoreText>
              <UpperText
                size="XS"
                variant="body-bold"
                sx={{ textDecoration: autoRenewalWaived ? 'line-through' : 'none' }}
              >
                {formatDate(autoRenewalDeadline, DATE_FORMAT)}
              </UpperText>
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
};
