import { CommonFormFieldProps } from '../../types';
import { useWatch } from 'react-hook-form';
import Text from '@verticeone/design-system/src/components/Text';
import React, { ReactNode } from 'react';
import { identity } from 'lodash';
import { styled, SxProps } from '@mui/material';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { TextPropsCombination } from '@verticeone/design-system/src/components/Text/utils';
import { Theme } from '@mui/material/styles';

export type FormReadOnlyTextProps<FormDataType extends FieldValues, ValueType> = CommonFormFieldProps<FormDataType> & {
  fallbackValue?: string;
  formatter?: (value?: ValueType) => ReactNode;
  textProps?: TextPropsCombination & { color?: string; sx?: SxProps<Theme> };
};

export type FormReadOnlyTextType<FormDataType extends FieldValues, ValueType = string> = (
  props: FormReadOnlyTextProps<FormDataType, ValueType>
) => JSX.Element;

const StyledText = styled(Text)({
  wordBreak: 'break-word',

  '& pre': {
    whiteSpace: 'pre-wrap',
  },
});

const FormReadOnlyText = <FormDataType extends FieldValues = never, ValueType = string>({
  id,
  name,
  fallbackValue = '-',
  formatter = identity,
  textProps = {
    size: 'M',
    variant: 'body-regular',
  },
}: FormReadOnlyTextProps<FormDataType, ValueType>) => {
  const value = useWatch<FormDataType>({ name });
  return (
    <StyledText id={id} {...textProps}>
      {formatter(value) ?? fallbackValue}
    </StyledText>
  );
};

export default FormReadOnlyText;
