import React, { ReactNode } from 'react';
import { Stack } from '@mui/material';
import Tooltip, { TooltipInfoTrigger } from '../../Tooltip';
import { GridColumnHeaderParams, GridColumnHeaderTitle } from '@mui/x-data-grid-pro';
import { GridValidRowModel } from '@mui/x-data-grid';
import { useDataGridContext } from '../DataGridContext';
import styled from '@mui/material/styles/styled';
import { DesignSystemColor } from '../../../types';

type TooltipIconProps = {
  title?: ReactNode;
  content?: ReactNode;
};

type GridHeaderCellWithTooltipProps<T extends GridValidRowModel> = GridColumnHeaderParams<T> & {
  tooltip: TooltipIconProps;
};

type StyledTooltipInfoTriggerProps = {
  $color: DesignSystemColor;
};

const StyledTooltipInfoTrigger = styled(TooltipInfoTrigger)<StyledTooltipInfoTriggerProps>(({ theme, $color }) => ({
  color: theme.palette[$color].color2,
  opacity: 0.4,
}));

const TooltipIcon = ({ title, content }: TooltipIconProps) => {
  const { size, color } = useDataGridContext();
  return (
    <Tooltip title={title} content={content} size={size}>
      <StyledTooltipInfoTrigger $color={color} />
    </Tooltip>
  );
};

const GridHeaderCellWithTooltip = <T extends GridValidRowModel = any>({
  tooltip,
  ...params
}: GridHeaderCellWithTooltipProps<T>) => {
  const rightAligned = params.colDef.headerAlign === 'right';

  return (
    <Stack direction="row" alignItems="center" gap={1} minWidth={0}>
      {rightAligned && <TooltipIcon {...tooltip} />}
      <GridColumnHeaderTitle
        label={params.colDef.headerName || ''}
        aria-label={params.colDef.headerName || ''}
        columnWidth={0}
      />
      {!rightAligned && <TooltipIcon {...tooltip} />}
    </Stack>
  );
};

export default GridHeaderCellWithTooltip;
