import { Tag, TagAssignment } from '@vertice/slices';
export const DEPARTMENT_TAG_PREFIX = 'iam_dep_';
export const DEFAULT_DEPARTMENT_TAG_PREFIX = 'iam_dep_reserved';
export const CONTRACT_TAG_PREFIX = 'iam_asoc_contract';
const DEPARTMENT_OWNER_VALUE = '[r:w:x]';

export const isDefaultDepartmentTag = (tag: Tag) => {
  return tag.tagId !== undefined && tag.tagId.startsWith(DEFAULT_DEPARTMENT_TAG_PREFIX);
};

export const isDepartmentTagAssignment = (tagAssignment: TagAssignment) => {
  return tagAssignment.tagId !== undefined && tagAssignment.tagId.startsWith(DEPARTMENT_TAG_PREFIX);
};

export const isContractTagAssignment = (tagAssignment: TagAssignment) => {
  return tagAssignment.tagId !== undefined && tagAssignment.tagId.startsWith(CONTRACT_TAG_PREFIX);
};

export const isOwnersDepartmentTagAssignment = (tagAssignment: TagAssignment) => {
  return isDepartmentTagAssignment(tagAssignment) && tagAssignment.value === DEPARTMENT_OWNER_VALUE;
};

export const getOwnedDepartmentIdsFromTagAssignments = (tagAssignments?: TagAssignment[]) => {
  return tagAssignments?.filter(isOwnersDepartmentTagAssignment).map((tagAssignment) => tagAssignment.tagId);
};
