import React, { FC } from 'react';

import Card from '@verticeone/design-system/src/components/Card';

import VendorDiligenceInsights from '@vertice/components/src/VendorDiligenceInsights/VendorDiligenceInsights';
import { Request } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

type DiligenceInsightsSectionProps = {
  request: Request;
};

export const DiligenceInsightsSection: FC<DiligenceInsightsSectionProps> = ({ request }) => {
  const { accountId } = useAccountContext();

  const requestVendorId = request?.input?.vertice?.vendor?.id;

  return (
    <Card>
      <VendorDiligenceInsights mainSectionCols={0} vendorId={requestVendorId} accountId={accountId} />
    </Card>
  );
};
