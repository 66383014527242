import ContractFormEntry, { ContractFormEntryPropsWithFixedComponents } from '../../ContractFormEntry';
import { useFormatDate } from '../../../../../utils/formatting/date';
import FormReadOnlyText, { FormReadOnlyTextType } from '@vertice/core/src/modules/forms/fields/FormReadOnlyText';
import FormDateField from '@vertice/core/src/modules/forms/fields/FormDateField';
import React from 'react';
import { FormDateFieldType } from '@vertice/core/src/modules/forms/fields/FormDateField';
import { ContractFormData } from '../../types';

type ReadComponent = FormReadOnlyTextType<ContractFormData>;
type WriteComponent = FormDateFieldType<ContractFormData>;

const DateContractFormEntry = (props: ContractFormEntryPropsWithFixedComponents<ReadComponent, WriteComponent>) => {
  const formatDate = useFormatDate();

  return (
    <ContractFormEntry<ReadComponent, WriteComponent>
      {...props}
      component={FormReadOnlyText}
      componentProps={{
        formatter: formatDate,
        ...props.componentProps,
      }}
      writeComponent={FormDateField}
    />
  );
};

export default DateContractFormEntry;
