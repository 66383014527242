import React from 'react';
import { Stack } from '@mui/material';

import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import Loader from '@verticeone/design-system/src/components/Loader';
import Button from '@verticeone/design-system/src/components/Button';

import { useInstanceList } from './providers/InstanceListProvider';

type InstanceListProps = {
  isLoading: boolean;
  children: React.ReactNode;
};

const InstanceList = ({ isLoading, children }: InstanceListProps) => {
  const { pagination } = useInstanceList();

  if (isLoading) {
    return <Loader color={AWS_BRAND_COLOR} />;
  }

  return (
    <Stack gap={5}>
      <Stack gap={4}>{children}</Stack>
      <Stack display="inline-block" margin="auto">
        {pagination.isVisible && (
          <Button
            testId="load-more"
            color="primary"
            variant="solid"
            isCaption
            size="S"
            onClick={pagination.loadNextPage}
          >
            Load More
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default InstanceList;
