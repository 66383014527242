import { CloudInventoryProvider } from '@vertice/dashboard/src/modules/cloud/cards/RIUtilizationCard/useCloudInventory';
import React from 'react';

import CloudInventoryContent from './CloudInventoryContent';

const CloudInventoryTab = () => {
  return (
    <CloudInventoryProvider>
      <CloudInventoryContent />
    </CloudInventoryProvider>
  );
};

export default CloudInventoryTab;
