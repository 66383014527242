import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { CloseOutlined, KeyboardArrowDownTwoTone, KeyboardArrowUpTwoTone } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { Dialog } from '@verticeone/design-system/src/components/Dialog';
import Text from '@verticeone/design-system/src/components/Text';
import Divider from '@verticeone/design-system/src/components/Divider';
import IconButton from '@verticeone/design-system/src/components/IconButton';

import { TaskRow } from '../types';
import { TaskModalContent } from './TaskModalContent';
import { TaskIcon } from '@vertice/core/src/modules/intelligentWorkflows/sharedVisualStyle/taskStyle';
import { TaskModalActions } from './TaskModalActions';
import { useGetTaskOverviewQuery } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { TaskContextProvider } from './TaskContext';
import { parseUserTaskRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { testProps } from '@verticeone/design-system/src/utils/testProperties';

type TaskModalProps = {
  defaultOpenTaskId?: string;
  /*
  We use the current task IDs to allow the user to navigate between tasks in the modal.
  The list of complete tasks is generated dynamically in the modal to always have the latest data (state, etc.)
  Also, a task won't disappear from the modal if it is assigned to somebody elso and the user has "My Tasks Only" selected
  */
  taskIds?: string[];
  allTasks: TaskRow[];
  onClose: () => void;
};

const MODAL_WIDTH = 680;

export const TaskModal: FC<TaskModalProps> = ({ defaultOpenTaskId, taskIds, allTasks, onClose }) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();

  const [currentTask, setCurrentTask] = useState<TaskRow>();
  useEffect(() => {
    setCurrentTask(allTasks.find((task) => task.id === defaultOpenTaskId));
  }, [allTasks, defaultOpenTaskId]);

  const modalTasks = useMemo(() => allTasks.filter((task) => taskIds?.includes(task.id)), [allTasks, taskIds]);

  const moveToTask = useCallback(
    (shift: number) => {
      if (currentTask) {
        const currentIndex = allTasks.indexOf(currentTask);
        const nextTask = allTasks[currentIndex + shift];
        setCurrentTask(nextTask);
      }
    },
    [allTasks, currentTask]
  );

  const {
    data: taskOverview,
    isLoading: isLoadingTaskOverview,
    isFetching: isFetchingTaskOverview,
  } = useGetTaskOverviewQuery({ accountId, taskId: currentTask?.id! }, { skip: !currentTask });

  const isFetchingDifferentTask =
    isFetchingTaskOverview && taskOverview && parseUserTaskRef(taskOverview.task.ref).taskId !== currentTask?.id;

  // We want to display no loaders if we only received (optimistic) cache update of the same task.
  // Example: Open the task modal, change assignment, the UserSelect shouldn't disappear during saving.
  const currentTaskOverview = isLoadingTaskOverview || isFetchingDifferentTask ? undefined : taskOverview;

  if (!currentTask) {
    return null;
  }

  return (
    <TaskContextProvider task={currentTask} taskOverview={currentTaskOverview}>
      <Dialog open={true} size="S" width={MODAL_WIDTH} fullHeight onClose={onClose}>
        <Stack minHeight={0} maxHeight="100%">
          <Stack
            gap={2}
            paddingTop={2}
            paddingBottom={6}
            paddingX={3}
            direction="row"
            justifyContent="space-between"
            {...testProps('taskModalHeading')}
          >
            <Stack gap={2} direction="row" {...testProps('navigationButtons')}>
              {modalTasks.length > 1 && (
                <>
                  <IconButton
                    size="M"
                    variant="outline"
                    icon={KeyboardArrowUpTwoTone}
                    disabled={currentTask === modalTasks[0]}
                    onClick={() => moveToTask(-1)}
                  ></IconButton>
                  <IconButton
                    size="M"
                    variant="outline"
                    icon={KeyboardArrowDownTwoTone}
                    disabled={currentTask === modalTasks[allTasks.length - 1]}
                    onClick={() => moveToTask(1)}
                  ></IconButton>
                </>
              )}
            </Stack>
            <Stack direction="row" alignItems="center" gap={2}>
              <TaskIcon status={currentTask.status} size="M" assigneeIds={currentTask.assignees.map((a) => a.id)} />
              <Text variant="heading" size="S">
                {t('ENTITIES.WORKFLOW_TASK.NAME')}
              </Text>
            </Stack>
            <IconButton size="M" variant="outline" icon={CloseOutlined} onClick={onClose}></IconButton>
          </Stack>
          <Divider />
          <Stack minHeight={0} overflow="auto" gap={6} paddingTop={6}>
            <TaskModalContent />
            <Divider />
            <TaskModalActions key={currentTask.id} />
          </Stack>
        </Stack>
      </Dialog>
    </TaskContextProvider>
  );
};
