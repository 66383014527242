import { api } from '../baseApi';
export type Maybe<T> = T;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Application = {
  __typename?: 'Application';
  accountId: Scalars['String'];
  children?: Maybe<Array<Maybe<Application>>>;
  dataHealth?: Maybe<DataHealth>;
  dataHealthConfigs?: Maybe<Array<Maybe<DataHealthCheckConfig>>>;
  id: Scalars['String'];
  license?: Maybe<License>;
  master?: Maybe<Application>;
  matches?: Maybe<Array<Maybe<Match>>>;
  measures?: Maybe<Array<Maybe<Measure>>>;
  name: Scalars['String'];
  providerName?: Maybe<Scalars['String']>;
  sources?: Maybe<Array<Maybe<DiscoverySource>>>;
  status: ApplicationStatus;
  suggestions?: Maybe<Array<Maybe<Array<Maybe<Suggestion>>>>>;
};

export type ApplicationMeasuresArgs = {
  bin?: InputMaybe<Scalars['String']>;
  category: Scalars['String'];
  from: Scalars['String'];
  name: Scalars['String'];
  to: Scalars['String'];
};

export type ApplicationMeasureRequestInput = {
  applicationIds: Array<Scalars['ID']>;
  bin?: InputMaybe<Scalars['String']>;
  category: Scalars['String'];
  from: Scalars['String'];
  name: Scalars['String'];
  to: Scalars['String'];
  userAccountIds?: InputMaybe<Array<Scalars['ID']>>;
};

export enum ApplicationStatus {
  Discovered = 'DISCOVERED',
  Ignored = 'IGNORED',
  MergedWithMaster = 'MERGED_WITH_MASTER',
  Undiscovered = 'UNDISCOVERED',
}

export type CreateApplicationInput = {
  license?: InputMaybe<LicenseInput>;
  matches?: InputMaybe<Array<InputMaybe<MatchInput>>>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ApplicationStatus>;
};

export type CustomerTransaction = DiscoveryEntity & {
  __typename?: 'CustomerTransaction';
  accountId: Scalars['ID'];
  createdAt?: Maybe<Scalars['String']>;
  discoveryStatus: DiscoveryStatus;
  document?: Maybe<Document>;
  id: Scalars['ID'];
  master?: Maybe<CustomerVendor>;
  matches?: Maybe<Array<Maybe<Match>>>;
  name: Scalars['String'];
  sources?: Maybe<Array<Maybe<DiscoverySource>>>;
  suggestions?: Maybe<Array<Maybe<Array<Maybe<Suggestion>>>>>;
  transactionType?: Maybe<Scalars['String']>;
};

export type CustomerUserAccount = DiscoveryEntity & {
  __typename?: 'CustomerUserAccount';
  accountId: Scalars['ID'];
  discoveryStatus: DiscoveryStatus;
  id: Scalars['ID'];
  identityKey?: Maybe<Scalars['String']>;
  master?: Maybe<DiscoveryEntity>;
  matches?: Maybe<Array<Maybe<Match>>>;
  name: Scalars['String'];
  sources?: Maybe<Array<Maybe<DiscoverySource>>>;
  suggestions?: Maybe<Array<Maybe<Array<Maybe<Suggestion>>>>>;
};

export type CustomerUserIdentity = DiscoveryEntity & {
  __typename?: 'CustomerUserIdentity';
  accountId: Scalars['ID'];
  discoveryStatus: DiscoveryStatus;
  id: Scalars['ID'];
  identityKey?: Maybe<Scalars['String']>;
  master?: Maybe<DiscoveryEntity>;
  matches?: Maybe<Array<Maybe<Match>>>;
  name: Scalars['String'];
  sources?: Maybe<Array<Maybe<DiscoverySource>>>;
  suggestions?: Maybe<Array<Maybe<Array<Maybe<Suggestion>>>>>;
};

export type CustomerVendor = DiscoveryEntity & {
  __typename?: 'CustomerVendor';
  accountId: Scalars['ID'];
  createdAt?: Maybe<Scalars['String']>;
  discoveryStatus: DiscoveryStatus;
  id: Scalars['ID'];
  master?: Maybe<CustomerVendor>;
  matches?: Maybe<Array<Maybe<Match>>>;
  name: Scalars['String'];
  sources?: Maybe<Array<Maybe<DiscoverySource>>>;
  suggestions?: Maybe<Array<Maybe<Array<Maybe<Suggestion>>>>>;
};

export type DataHealth = {
  __typename?: 'DataHealth';
  checks?: Maybe<Array<DataHealthCheck>>;
  status: DataHealthStatus;
};

export type DataHealthCheck = {
  __typename?: 'DataHealthCheck';
  code: Scalars['String'];
  priority: Scalars['Int'];
  severity: DataHealthCheckSeverity;
  status: DataHealthCheckStatus;
};

export type DataHealthCheckConfig = {
  __typename?: 'DataHealthCheckConfig';
  code: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
};

export type DataHealthCheckConfigInput = {
  code: Scalars['String'];
  enabled?: InputMaybe<Scalars['Boolean']>;
};

export enum DataHealthCheckSeverity {
  Info = 'INFO',
  Warning = 'WARNING',
}

export enum DataHealthCheckStatus {
  Failed = 'FAILED',
  Ignored = 'IGNORED',
  Passed = 'PASSED',
}

export enum DataHealthStatus {
  IssuesDetected = 'ISSUES_DETECTED',
  Passed = 'PASSED',
}

export type DataSource = {
  __typename?: 'DataSource';
  class: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
  provider?: Maybe<Provider>;
  status?: Maybe<DataSourceStatus>;
  version: Scalars['String'];
};

export enum DataSourceStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type DiscoverApplicationInput = {
  dataHealthConfigs?: InputMaybe<Array<InputMaybe<DataHealthCheckConfigInput>>>;
  id: Scalars['String'];
  license?: InputMaybe<LicenseInput>;
  masterRecordId?: InputMaybe<Scalars['ID']>;
  matches?: InputMaybe<Array<InputMaybe<MatchInput>>>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ApplicationStatus>;
};

export type DiscoverCustomerTransactionInput = {
  id: Scalars['ID'];
  masterRecordId?: InputMaybe<Scalars['ID']>;
  matches?: InputMaybe<Array<InputMaybe<MatchInput>>>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DiscoveryStatus>;
};

export type DiscoverCustomerUserAccountInput = {
  id: Scalars['ID'];
  masterRecordId?: InputMaybe<MasterRecordRef>;
  matches?: InputMaybe<Array<InputMaybe<MatchInput>>>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DiscoveryStatus>;
};

export type DiscoverCustomerUserIdentityInput = {
  id: Scalars['ID'];
  masterRecordId?: InputMaybe<MasterRecordRef>;
  matches?: InputMaybe<Array<InputMaybe<MatchInput>>>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DiscoveryStatus>;
};

export type DiscoverCustomerVendorInput = {
  id: Scalars['ID'];
  masterRecordId?: InputMaybe<Scalars['ID']>;
  matches?: InputMaybe<Array<InputMaybe<MatchInput>>>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DiscoveryStatus>;
};

export type DiscoveryEntity = {
  accountId: Scalars['ID'];
  discoveryStatus: DiscoveryStatus;
  id: Scalars['ID'];
  master?: Maybe<DiscoveryEntity>;
  matches?: Maybe<Array<Maybe<Match>>>;
  name: Scalars['String'];
  sources?: Maybe<Array<Maybe<DiscoverySource>>>;
  suggestions?: Maybe<Array<Maybe<Array<Maybe<Suggestion>>>>>;
};

export enum DiscoveryEntityType {
  CustomerUserAccount = 'CustomerUserAccount',
  CustomerUserIdentity = 'CustomerUserIdentity',
}

export type DiscoveryJob = {
  __typename?: 'DiscoveryJob';
  id: Scalars['ID'];
  status: JobStatus;
};

export type DiscoveryJobInput = {
  cutoffInterval?: InputMaybe<Scalars['String']>;
  entityType: Scalars['String'];
};

export type DiscoverySource = {
  __typename?: 'DiscoverySource';
  accountId: Scalars['String'];
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kind: DiscoverySourceKind;
  mdm: Scalars['String'];
  ownerId?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  provider?: Maybe<Provider>;
  recordId?: Maybe<Scalars['String']>;
  recordType?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export enum DiscoverySourceKind {
  DataRecord = 'DATA_RECORD',
  Discovery = 'DISCOVERY',
  IntegrationProvider = 'INTEGRATION_PROVIDER',
}

export enum DiscoveryStatus {
  Discovered = 'DISCOVERED',
  Ignored = 'IGNORED',
  MergedWithMaster = 'MERGED_WITH_MASTER',
  Undiscovered = 'UNDISCOVERED',
}

export type Document = {
  __typename?: 'Document';
  attributes?: Maybe<Array<Maybe<DocumentAttribute>>>;
};

export type DocumentAttribute = {
  __typename?: 'DocumentAttribute';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type IntakeJob = {
  __typename?: 'IntakeJob';
  executionId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  status: IntakeJobStatus;
  updatedAt?: Maybe<Scalars['String']>;
};

export enum IntakeJobStatus {
  Failed = 'FAILED',
  Finished = 'FINISHED',
  Fulfilled = 'FULFILLED',
  Open = 'OPEN',
  Processing = 'PROCESSING',
  Running = 'RUNNING',
  Succeeded = 'SUCCEEDED',
}

export type Job = {
  __typename?: 'Job';
  id: Scalars['ID'];
};

export type JobResult = {
  __typename?: 'JobResult';
  date: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  status: JobStatus;
};

export enum JobStatus {
  Failed = 'FAILED',
  Running = 'RUNNING',
  Scheduled = 'SCHEDULED',
  Succeeded = 'SUCCEEDED',
}

export type License = {
  __typename?: 'License';
  quantity?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type LicenseInput = {
  quantity?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

export type MasterRecordRef = {
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type Match = {
  __typename?: 'Match';
  entityId: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  entityType: Scalars['String'];
};

export type MatchInput = {
  entityId: Scalars['String'];
  entityName?: InputMaybe<Scalars['String']>;
  entityType: Scalars['String'];
};

export type Measure = {
  __typename?: 'Measure';
  binSize: Scalars['String'];
  binStart: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  value: Scalars['String'];
  valueType: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateProvider?: Maybe<Provider>;
  createApplication?: Maybe<Application>;
  deactivateProvider?: Maybe<Provider>;
  executeDiscoveryJob?: Maybe<DiscoveryJob>;
  startRunner?: Maybe<Job>;
  updateApplication?: Maybe<Application>;
  updateCustomerTransaction?: Maybe<CustomerTransaction>;
  updateCustomerVendor?: Maybe<CustomerVendor>;
  updateDataSource: DataSource;
};

export type MutationActivateProviderArgs = {
  accountId: Scalars['ID'];
  providerId: Scalars['String'];
};

export type MutationCreateApplicationArgs = {
  accountId: Scalars['ID'];
  input: CreateApplicationInput;
};

export type MutationDeactivateProviderArgs = {
  accountId: Scalars['ID'];
  providerId: Scalars['String'];
};

export type MutationExecuteDiscoveryJobArgs = {
  accountId: Scalars['ID'];
  input: DiscoveryJobInput;
};

export type MutationStartRunnerArgs = {
  accountId: Scalars['ID'];
  runnerId: Scalars['String'];
};

export type MutationUpdateApplicationArgs = {
  accountId: Scalars['ID'];
  input: DiscoverApplicationInput;
};

export type MutationUpdateCustomerTransactionArgs = {
  accountId: Scalars['ID'];
  input: DiscoverCustomerTransactionInput;
};

export type MutationUpdateCustomerVendorArgs = {
  accountId: Scalars['ID'];
  input: DiscoverCustomerVendorInput;
};

export type MutationUpdateDataSourceArgs = {
  accountId: Scalars['ID'];
  dataSourceCode: Scalars['String'];
  input: UpdateDataSourceInput;
};

export type PaginatedApplications = {
  __typename?: 'PaginatedApplications';
  items?: Maybe<Array<Maybe<Application>>>;
  pagination?: Maybe<PaginationResult>;
};

export type PaginatedCustomerTransaction = {
  __typename?: 'PaginatedCustomerTransaction';
  items?: Maybe<Array<Maybe<CustomerTransaction>>>;
  pagination?: Maybe<PaginationResult>;
};

export type PaginatedCustomerVendor = {
  __typename?: 'PaginatedCustomerVendor';
  items?: Maybe<Array<Maybe<CustomerVendor>>>;
  pagination?: Maybe<PaginationResult>;
};

export type PaginatedDataSource = {
  __typename?: 'PaginatedDataSource';
  items?: Maybe<Array<Maybe<DataSource>>>;
  pagination?: Maybe<PaginationResult>;
};

export type PaginatedDiscoveryEntity = {
  __typename?: 'PaginatedDiscoveryEntity';
  items?: Maybe<Array<Maybe<DiscoveryEntity>>>;
  pagination?: Maybe<PaginationResult>;
};

export type PaginatedIntakeJob = {
  __typename?: 'PaginatedIntakeJob';
  items?: Maybe<Array<IntakeJob>>;
  pagination?: Maybe<PaginationResult>;
};

export type PaginatedMeasure = {
  __typename?: 'PaginatedMeasure';
  items?: Maybe<Array<Maybe<Array<Maybe<Measure>>>>>;
  pagination?: Maybe<PaginationResult>;
};

export type PaginatedTask = {
  __typename?: 'PaginatedTask';
  items?: Maybe<Array<Task>>;
  pagination?: Maybe<PaginationResult>;
};

export type PaginationResult = {
  __typename?: 'PaginationResult';
  finished?: Maybe<Scalars['Boolean']>;
  nextToken?: Maybe<Scalars['String']>;
  scannedCount?: Maybe<Scalars['Int']>;
};

export type Provider = {
  __typename?: 'Provider';
  id: Scalars['ID'];
  status: ProviderStatus;
};

export enum ProviderStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type Query = {
  __typename?: 'Query';
  applicationById?: Maybe<Application>;
  applications?: Maybe<Array<Maybe<Application>>>;
  applicationsPaginated?: Maybe<PaginatedApplications>;
  customerTransactions?: Maybe<Array<Maybe<CustomerTransaction>>>;
  customerTransactionsByContract?: Maybe<Array<Maybe<CustomerTransaction>>>;
  customerTransactionsByContractV2?: Maybe<Array<Maybe<CustomerTransaction>>>;
  customerTransactionsPaginated?: Maybe<PaginatedCustomerTransaction>;
  customerVendorById?: Maybe<CustomerVendor>;
  customerVendors?: Maybe<Array<Maybe<CustomerVendor>>>;
  customerVendorsPaginated?: Maybe<PaginatedCustomerVendor>;
  dataSource?: Maybe<DataSource>;
  dataSources: Array<Maybe<DataSource>>;
  dataSourcesPaginated?: Maybe<PaginatedDataSource>;
  discoveryEntities?: Maybe<Array<Maybe<DiscoveryEntity>>>;
  discoveryEntitiesPaginated?: Maybe<PaginatedDiscoveryEntity>;
  jobsPaginated?: Maybe<PaginatedIntakeJob>;
  measuresForApplications?: Maybe<Array<Maybe<Array<Maybe<Measure>>>>>;
  provider?: Maybe<Provider>;
  providers?: Maybe<Array<Provider>>;
  runner?: Maybe<Runner>;
  tasksPaginated?: Maybe<PaginatedTask>;
};

export type QueryApplicationByIdArgs = {
  accountId: Scalars['String'];
  applicationId: Scalars['String'];
};

export type QueryApplicationsArgs = {
  accountId: Scalars['String'];
};

export type QueryApplicationsPaginatedArgs = {
  accountId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<InputMaybe<ApplicationStatus>>>;
};

export type QueryCustomerTransactionsArgs = {
  accountId: Scalars['String'];
  createdAfter?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DiscoveryStatus>;
};

export type QueryCustomerTransactionsByContractArgs = {
  accountId: Scalars['String'];
  contractId: Scalars['String'];
  contractVersion: Scalars['String'];
  departmentId: Scalars['String'];
};

export type QueryCustomerTransactionsByContractV2Args = {
  accountId: Scalars['String'];
  contractId: Scalars['String'];
};

export type QueryCustomerTransactionsPaginatedArgs = {
  accountId: Scalars['String'];
  createdAfter?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DiscoveryStatus>;
};

export type QueryCustomerVendorByIdArgs = {
  accountId: Scalars['String'];
  vendorId: Scalars['String'];
};

export type QueryCustomerVendorsArgs = {
  accountId: Scalars['String'];
  createdAfter?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DiscoveryStatus>;
};

export type QueryCustomerVendorsPaginatedArgs = {
  accountId: Scalars['String'];
  createdAfter?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DiscoveryStatus>;
};

export type QueryDataSourceArgs = {
  accountId: Scalars['String'];
  code: Scalars['String'];
};

export type QueryDataSourcesArgs = {
  accountId: Scalars['String'];
};

export type QueryDataSourcesPaginatedArgs = {
  accountId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryDiscoveryEntitiesArgs = {
  accountId: Scalars['String'];
  entityType: DiscoveryEntityType;
  providers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryDiscoveryEntitiesPaginatedArgs = {
  accountId: Scalars['String'];
  entityType: DiscoveryEntityType;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  providers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryJobsPaginatedArgs = {
  accountId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryMeasuresForApplicationsArgs = {
  accountId?: InputMaybe<Scalars['String']>;
  measureRequests?: InputMaybe<Array<InputMaybe<ApplicationMeasureRequestInput>>>;
};

export type QueryProviderArgs = {
  accountId: Scalars['String'];
  providerId: Scalars['String'];
};

export type QueryProvidersArgs = {
  accountId: Scalars['String'];
};

export type QueryRunnerArgs = {
  accountId: Scalars['String'];
  from?: InputMaybe<Scalars['String']>;
  runnerId: Scalars['String'];
  to?: InputMaybe<Scalars['String']>;
};

export type QueryTasksPaginatedArgs = {
  accountId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type Runner = {
  __typename?: 'Runner';
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  status: RunnerStatus;
  tasks: Array<RunnerTask>;
};

export enum RunnerStatus {
  Failed = 'FAILED',
  Idle = 'IDLE',
  Missing = 'MISSING',
  Running = 'RUNNING',
  Success = 'SUCCESS',
}

export type RunnerTask = {
  __typename?: 'RunnerTask';
  id: Scalars['String'];
  jobs: Array<JobResult>;
};

export type Suggestion = {
  __typename?: 'Suggestion';
  entityId: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  entityType: Scalars['String'];
};

export type Task = {
  __typename?: 'Task';
  createdAt: Scalars['String'];
  dataSource: Scalars['String'];
  errorData?: Maybe<TaskErrorData>;
  id: Scalars['ID'];
  jobId?: Maybe<Scalars['ID']>;
  range?: Maybe<TimeSpan>;
  status: TaskStatus;
  type: Scalars['String'];
};

export type TaskErrorData = {
  __typename?: 'TaskErrorData';
  errorDetails?: Maybe<Scalars['String']>;
  remainingAttempts?: Maybe<Scalars['Int']>;
  retryable?: Maybe<Scalars['Boolean']>;
};

export enum TaskStatus {
  Failed = 'FAILED',
  Fulfilled = 'FULFILLED',
  Open = 'OPEN',
}

export type TimeSpan = {
  __typename?: 'TimeSpan';
  end: Scalars['String'];
  start: Scalars['String'];
};

export type UpdateDataSourceInput = {
  status: DataSourceStatus;
};

export type MeasuresForApplicationsQueryVariables = Exact<{
  accountId: Scalars['String'];
  measureRequests?: InputMaybe<
    Array<InputMaybe<ApplicationMeasureRequestInput>> | InputMaybe<ApplicationMeasureRequestInput>
  >;
}>;

export type MeasuresForApplicationsQuery = {
  __typename?: 'Query';
  measuresForApplications?: Array<
    Array<{
      __typename?: 'Measure';
      id: string;
      name: string;
      value: string;
      valueType: string;
      binStart: string;
      binSize: string;
    }>
  >;
};

export type MeasuresForApplicationGroupsQueryVariables = Exact<{
  accountId: Scalars['String'];
  groupMeasureRequests?: InputMaybe<
    Array<InputMaybe<ApplicationMeasureRequestInput>> | InputMaybe<ApplicationMeasureRequestInput>
  >;
  singleAppMeasureRequests?: InputMaybe<
    Array<InputMaybe<ApplicationMeasureRequestInput>> | InputMaybe<ApplicationMeasureRequestInput>
  >;
}>;

export type MeasuresForApplicationGroupsQuery = {
  __typename?: 'Query';
  groups?: Array<
    Array<{
      __typename?: 'Measure';
      id: string;
      name: string;
      value: string;
      valueType: string;
      binStart: string;
      binSize: string;
    }>
  >;
  singleApplications?: Array<
    Array<{
      __typename?: 'Measure';
      id: string;
      name: string;
      value: string;
      valueType: string;
      binStart: string;
      binSize: string;
    }>
  >;
};

export type RunnerQueryVariables = Exact<{
  accountId: Scalars['String'];
  runnerId: Scalars['String'];
  from?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
}>;

export type RunnerQuery = {
  __typename?: 'Query';
  runner?: {
    __typename?: 'Runner';
    id: string;
    name: string;
    enabled: boolean;
    tasks: Array<{
      __typename?: 'RunnerTask';
      id: string;
      jobs: Array<{ __typename?: 'JobResult'; date: string; status: JobStatus }>;
    }>;
  };
};

export type GetProviderQueryVariables = Exact<{
  accountId: Scalars['String'];
  providerId: Scalars['String'];
}>;

export type GetProviderQuery = {
  __typename?: 'Query';
  provider?: { __typename?: 'Provider'; id: string; status: ProviderStatus };
};

export type GetProvidersQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;

export type GetProvidersQuery = {
  __typename?: 'Query';
  providers?: Array<{ __typename?: 'Provider'; id: string; status: ProviderStatus }>;
};

export type ActivateProviderMutationVariables = Exact<{
  accountId: Scalars['ID'];
  providerId: Scalars['String'];
}>;

export type ActivateProviderMutation = {
  __typename?: 'Mutation';
  activateProvider?: { __typename?: 'Provider'; id: string; status: ProviderStatus };
};

export type DeactivateProviderMutationVariables = Exact<{
  accountId: Scalars['ID'];
  providerId: Scalars['String'];
}>;

export type DeactivateProviderMutation = {
  __typename?: 'Mutation';
  deactivateProvider?: { __typename?: 'Provider'; id: string; status: ProviderStatus };
};

export type ApplicationsBasicQueryVariables = Exact<{
  accountId: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type ApplicationsBasicQuery = {
  __typename?: 'Query';
  applicationsPaginated?: {
    __typename?: 'PaginatedApplications';
    items?: Array<{
      __typename?: 'Application';
      id: string;
      name: string;
      status: ApplicationStatus;
      children?: Array<{ __typename?: 'Application'; id: string }>;
      matches?: Array<{ __typename?: 'Match'; entityId: string; entityName?: string; entityType: string }>;
      license?: { __typename?: 'License'; quantity?: number; type?: string };
      master?: { __typename?: 'Application'; id: string; name: string };
      sources?: Array<{ __typename?: 'DiscoverySource'; id?: string }>;
    }>;
    pagination?: { __typename?: 'PaginationResult'; nextToken?: string };
  };
};

export type RunnerStatsQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;

export type RunnerStatsQuery = {
  __typename?: 'Query';
  runner?: {
    __typename?: 'Runner';
    id: string;
    name: string;
    enabled: boolean;
    status: RunnerStatus;
    tasks: Array<{
      __typename?: 'RunnerTask';
      id: string;
      jobs: Array<{ __typename?: 'JobResult'; date: string; status: JobStatus; error?: string }>;
    }>;
  };
};

export type CustomerTransactionsQueryVariables = Exact<{
  accountId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  createdAfter?: InputMaybe<Scalars['String']>;
}>;

export type CustomerTransactionsQuery = {
  __typename?: 'Query';
  customerTransactionsPaginated?: {
    __typename?: 'PaginatedCustomerTransaction';
    items?: Array<{
      __typename?: 'CustomerTransaction';
      id: string;
      accountId: string;
      name: string;
      discoveryStatus: DiscoveryStatus;
      matches?: Array<{ __typename?: 'Match'; entityId: string; entityName?: string; entityType: string }>;
      suggestions?: Array<
        Array<{ __typename?: 'Suggestion'; entityId: string; entityName?: string; entityType: string }>
      >;
      document?: {
        __typename?: 'Document';
        attributes?: Array<{ __typename?: 'DocumentAttribute'; name?: string; value?: string }>;
      };
      master?: { __typename?: 'CustomerVendor'; id: string; name: string };
      sources?: Array<{ __typename?: 'DiscoverySource'; provider?: { __typename?: 'Provider'; id: string } }>;
    }>;
    pagination?: { __typename?: 'PaginationResult'; finished?: boolean; nextToken?: string; scannedCount?: number };
  };
};

export type CustomerTransactionsByContractQueryVariables = Exact<{
  accountId: Scalars['String'];
  departmentId: Scalars['String'];
  contractId: Scalars['String'];
  contractVersion: Scalars['String'];
}>;

export type CustomerTransactionsByContractQuery = {
  __typename?: 'Query';
  customerTransactionsByContract?: Array<{
    __typename?: 'CustomerTransaction';
    id: string;
    accountId: string;
    name: string;
    discoveryStatus: DiscoveryStatus;
    matches?: Array<{ __typename?: 'Match'; entityId: string; entityName?: string; entityType: string }>;
    suggestions?: Array<
      Array<{ __typename?: 'Suggestion'; entityId: string; entityName?: string; entityType: string }>
    >;
    document?: {
      __typename?: 'Document';
      attributes?: Array<{ __typename?: 'DocumentAttribute'; name?: string; value?: string }>;
    };
    master?: { __typename?: 'CustomerVendor'; id: string; name: string };
    sources?: Array<{ __typename?: 'DiscoverySource'; provider?: { __typename?: 'Provider'; id: string } }>;
  }>;
};

export type CustomerTransactionsByContractV2QueryVariables = Exact<{
  accountId: Scalars['String'];
  contractId: Scalars['String'];
}>;

export type CustomerTransactionsByContractV2Query = {
  __typename?: 'Query';
  customerTransactionsByContractV2?: Array<{
    __typename?: 'CustomerTransaction';
    id: string;
    accountId: string;
    name: string;
    discoveryStatus: DiscoveryStatus;
    matches?: Array<{ __typename?: 'Match'; entityId: string; entityName?: string; entityType: string }>;
    suggestions?: Array<
      Array<{ __typename?: 'Suggestion'; entityId: string; entityName?: string; entityType: string }>
    >;
    document?: {
      __typename?: 'Document';
      attributes?: Array<{ __typename?: 'DocumentAttribute'; name?: string; value?: string }>;
    };
    master?: { __typename?: 'CustomerVendor'; id: string; name: string };
    sources?: Array<{ __typename?: 'DiscoverySource'; provider?: { __typename?: 'Provider'; id: string } }>;
  }>;
};

export type DiscoveredApplicationMutationVariables = Exact<{
  accountId: Scalars['ID'];
  input: DiscoverApplicationInput;
}>;

export type DiscoveredApplicationMutation = {
  __typename?: 'Mutation';
  updateApplication?: {
    __typename?: 'Application';
    id: string;
    name: string;
    status: ApplicationStatus;
    dataHealthConfigs?: Array<{ __typename?: 'DataHealthCheckConfig'; code: string; enabled?: boolean }>;
    dataHealth?: {
      __typename?: 'DataHealth';
      status: DataHealthStatus;
      checks?: Array<{ __typename?: 'DataHealthCheck'; code: string; status: DataHealthCheckStatus }>;
    };
    children?: Array<{ __typename?: 'Application'; id: string }>;
    matches?: Array<{ __typename?: 'Match'; entityId: string; entityName?: string; entityType: string }>;
    suggestions?: Array<
      Array<{ __typename?: 'Suggestion'; entityId: string; entityName?: string; entityType: string }>
    >;
    license?: { __typename?: 'License'; quantity?: number; type?: string };
    master?: { __typename?: 'Application'; id: string; name: string };
  };
};

export type UpdateCustomerTransactionMutationVariables = Exact<{
  accountId: Scalars['ID'];
  input: DiscoverCustomerTransactionInput;
}>;

export type UpdateCustomerTransactionMutation = {
  __typename?: 'Mutation';
  updateCustomerTransaction?: {
    __typename?: 'CustomerTransaction';
    id: string;
    accountId: string;
    name: string;
    discoveryStatus: DiscoveryStatus;
    matches?: Array<{ __typename?: 'Match'; entityId: string; entityName?: string; entityType: string }>;
    suggestions?: Array<
      Array<{ __typename?: 'Suggestion'; entityId: string; entityName?: string; entityType: string }>
    >;
    master?: { __typename?: 'CustomerVendor'; id: string; name: string };
  };
};

export type DiscoveredCustomerVendorMutationVariables = Exact<{
  accountId: Scalars['ID'];
  input: DiscoverCustomerVendorInput;
}>;

export type DiscoveredCustomerVendorMutation = {
  __typename?: 'Mutation';
  updateCustomerVendor?: {
    __typename?: 'CustomerVendor';
    id: string;
    accountId: string;
    name: string;
    discoveryStatus: DiscoveryStatus;
    matches?: Array<{ __typename?: 'Match'; entityId: string; entityName?: string; entityType: string }>;
    suggestions?: Array<
      Array<{ __typename?: 'Suggestion'; entityId: string; entityName?: string; entityType: string }>
    >;
    master?: { __typename?: 'CustomerVendor'; id: string; name: string };
  };
};

export type CustomerVendorsQueryVariables = Exact<{
  accountId: Scalars['String'];
  status?: InputMaybe<DiscoveryStatus>;
  createdAfter?: InputMaybe<Scalars['String']>;
}>;

export type CustomerVendorsQuery = {
  __typename?: 'Query';
  customerVendors?: Array<{
    __typename?: 'CustomerVendor';
    id: string;
    accountId: string;
    name: string;
    discoveryStatus: DiscoveryStatus;
    matches?: Array<{ __typename?: 'Match'; entityId: string; entityName?: string; entityType: string }>;
    suggestions?: Array<
      Array<{ __typename?: 'Suggestion'; entityId: string; entityName?: string; entityType: string }>
    >;
    master?: { __typename?: 'CustomerVendor'; id: string; name: string };
    sources?: Array<{ __typename?: 'DiscoverySource'; provider?: { __typename?: 'Provider'; id: string } }>;
  }>;
};

export type CustomerVendorsPaginatedQueryVariables = Exact<{
  accountId: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<DiscoveryStatus>;
  createdAfter?: InputMaybe<Scalars['String']>;
}>;

export type CustomerVendorsPaginatedQuery = {
  __typename?: 'Query';
  customerVendorsPaginated?: {
    __typename?: 'PaginatedCustomerVendor';
    items?: Array<{
      __typename?: 'CustomerVendor';
      id: string;
      accountId: string;
      name: string;
      discoveryStatus: DiscoveryStatus;
      matches?: Array<{ __typename?: 'Match'; entityId: string; entityName?: string; entityType: string }>;
      suggestions?: Array<
        Array<{ __typename?: 'Suggestion'; entityId: string; entityName?: string; entityType: string }>
      >;
      master?: { __typename?: 'CustomerVendor'; id: string; name: string };
      sources?: Array<{ __typename?: 'DiscoverySource'; provider?: { __typename?: 'Provider'; id: string } }>;
    }>;
    pagination?: { __typename?: 'PaginationResult'; nextToken?: string; finished?: boolean; scannedCount?: number };
  };
};

export type CustomerVendorByIdQueryVariables = Exact<{
  accountId: Scalars['String'];
  vendorId: Scalars['String'];
}>;

export type CustomerVendorByIdQuery = {
  __typename?: 'Query';
  customerVendorById?: {
    __typename?: 'CustomerVendor';
    id: string;
    accountId: string;
    name: string;
    discoveryStatus: DiscoveryStatus;
    matches?: Array<{ __typename?: 'Match'; entityId: string; entityName?: string; entityType: string }>;
    suggestions?: Array<
      Array<{ __typename?: 'Suggestion'; entityId: string; entityName?: string; entityType: string }>
    >;
    master?: { __typename?: 'CustomerVendor'; id: string; name: string };
    sources?: Array<{ __typename?: 'DiscoverySource'; provider?: { __typename?: 'Provider'; id: string } }>;
  };
};

export type DataSourceQueryVariables = Exact<{
  accountId: Scalars['String'];
  code: Scalars['String'];
}>;

export type DataSourceQuery = {
  __typename?: 'Query';
  dataSource?: { __typename?: 'DataSource'; class: string; name: string; status?: DataSourceStatus; version: string };
};

export type DataSourcesQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;

export type DataSourcesQuery = {
  __typename?: 'Query';
  dataSources: Array<{
    __typename?: 'DataSource';
    code: string;
    class: string;
    name: string;
    status?: DataSourceStatus;
    version: string;
  }>;
};

export type UpdateDataSourceMutationVariables = Exact<{
  accountId: Scalars['ID'];
  dataSourceCode: Scalars['String'];
  input: UpdateDataSourceInput;
}>;

export type UpdateDataSourceMutation = {
  __typename?: 'Mutation';
  updateDataSource: { __typename?: 'DataSource'; status?: DataSourceStatus };
};

export type ProvidersQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;

export type ProvidersQuery = {
  __typename?: 'Query';
  providers?: Array<{ __typename?: 'Provider'; id: string; status: ProviderStatus }>;
};

export type ProviderQueryVariables = Exact<{
  accountId: Scalars['String'];
  providerId: Scalars['String'];
}>;

export type ProviderQuery = {
  __typename?: 'Query';
  provider?: { __typename?: 'Provider'; id: string; status: ProviderStatus };
};

export type JobsPaginatedQueryVariables = Exact<{
  accountId: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type JobsPaginatedQuery = {
  __typename?: 'Query';
  jobsPaginated?: {
    __typename?: 'PaginatedIntakeJob';
    items?: Array<{
      __typename?: 'IntakeJob';
      id: string;
      status: IntakeJobStatus;
      executionId?: string;
      updatedAt?: string;
    }>;
    pagination?: { __typename?: 'PaginationResult'; nextToken?: string; finished?: boolean; scannedCount?: number };
  };
};

export type TasksPaginatedQueryVariables = Exact<{
  accountId: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type TasksPaginatedQuery = {
  __typename?: 'Query';
  tasksPaginated?: {
    __typename?: 'PaginatedTask';
    items?: Array<{
      __typename?: 'Task';
      id: string;
      type: string;
      dataSource: string;
      status: TaskStatus;
      jobId?: string;
      createdAt: string;
      range?: { __typename?: 'TimeSpan'; start: string; end: string };
      errorData?: {
        __typename?: 'TaskErrorData';
        retryable?: boolean;
        remainingAttempts?: number;
        errorDetails?: string;
      };
    }>;
    pagination?: { __typename?: 'PaginationResult'; nextToken?: string; finished?: boolean; scannedCount?: number };
  };
};

export type UpdateThreeDataSourcesMutationVariables = Exact<{
  accountId: Scalars['ID'];
  dataSourceCode1: Scalars['String'];
  dataSourceCode2: Scalars['String'];
  dataSourceCode3: Scalars['String'];
  input: UpdateDataSourceInput;
}>;

export type UpdateThreeDataSourcesMutation = {
  __typename?: 'Mutation';
  status1: { __typename?: 'DataSource'; status?: DataSourceStatus };
  status2: { __typename?: 'DataSource'; status?: DataSourceStatus };
  status3: { __typename?: 'DataSource'; status?: DataSourceStatus };
};

export type ExecuteDiscoveryJobMutationVariables = Exact<{
  accountId: Scalars['ID'];
  input: DiscoveryJobInput;
}>;

export type ExecuteDiscoveryJobMutation = {
  __typename?: 'Mutation';
  executeDiscoveryJob?: { __typename?: 'DiscoveryJob'; id: string; status: JobStatus };
};

export const MeasuresForApplicationsDocument = `
    query MeasuresForApplications($accountId: String!, $measureRequests: [ApplicationMeasureRequestInput]) {
  measuresForApplications(
    accountId: $accountId
    measureRequests: $measureRequests
  ) {
    id
    name
    value
    valueType
    binStart
    binSize
  }
}
    `;
export const MeasuresForApplicationGroupsDocument = `
    query MeasuresForApplicationGroups($accountId: String!, $groupMeasureRequests: [ApplicationMeasureRequestInput], $singleAppMeasureRequests: [ApplicationMeasureRequestInput]) {
  groups: measuresForApplications(
    accountId: $accountId
    measureRequests: $groupMeasureRequests
  ) {
    id
    name
    value
    valueType
    binStart
    binSize
  }
  singleApplications: measuresForApplications(
    accountId: $accountId
    measureRequests: $singleAppMeasureRequests
  ) {
    id
    name
    value
    valueType
    binStart
    binSize
  }
}
    `;
export const RunnerDocument = `
    query Runner($accountId: String!, $runnerId: String!, $from: String, $to: String) {
  runner(accountId: $accountId, runnerId: $runnerId, from: $from, to: $to) {
    id
    name
    enabled
    tasks {
      id
      jobs {
        date
        status
      }
    }
  }
}
    `;
export const GetProviderDocument = `
    query GetProvider($accountId: String!, $providerId: String!) {
  provider(accountId: $accountId, providerId: $providerId) {
    id
    status
  }
}
    `;
export const GetProvidersDocument = `
    query GetProviders($accountId: String!) {
  providers(accountId: $accountId) {
    id
    status
  }
}
    `;
export const ActivateProviderDocument = `
    mutation ActivateProvider($accountId: ID!, $providerId: String!) {
  activateProvider(accountId: $accountId, providerId: $providerId) {
    id
    status
  }
}
    `;
export const DeactivateProviderDocument = `
    mutation DeactivateProvider($accountId: ID!, $providerId: String!) {
  deactivateProvider(accountId: $accountId, providerId: $providerId) {
    id
    status
  }
}
    `;
export const ApplicationsBasicDocument = `
    query ApplicationsBasic($accountId: String!, $nextToken: String, $limit: Int) {
  applicationsPaginated(
    accountId: $accountId
    nextToken: $nextToken
    limit: $limit
    statuses: [DISCOVERED]
  ) {
    items {
      id
      name
      children {
        id
      }
      matches {
        entityId
        entityName
        entityType
      }
      license {
        quantity
        type
      }
      status
      master {
        id
        name
      }
      sources {
        id
      }
    }
    pagination {
      nextToken
    }
  }
}
    `;
export const RunnerStatsDocument = `
    query RunnerStats($accountId: String!) {
  runner(accountId: $accountId, runnerId: "Insight/Usage") {
    id
    name
    enabled
    status
    tasks {
      id
      jobs {
        date
        status
        error
      }
    }
  }
}
    `;
export const CustomerTransactionsDocument = `
    query CustomerTransactions($accountId: String!, $limit: Int, $nextToken: String, $createdAfter: String) {
  customerTransactionsPaginated(
    accountId: $accountId
    limit: $limit
    nextToken: $nextToken
    createdAfter: $createdAfter
  ) {
    items {
      id
      accountId
      name
      matches {
        entityId
        entityName
        entityType
      }
      suggestions {
        entityId
        entityName
        entityType
      }
      discoveryStatus
      document {
        attributes {
          name
          value
        }
      }
      master {
        id
        name
      }
      sources {
        provider {
          id
        }
      }
    }
    pagination {
      finished
      nextToken
      scannedCount
    }
  }
}
    `;
export const CustomerTransactionsByContractDocument = `
    query CustomerTransactionsByContract($accountId: String!, $departmentId: String!, $contractId: String!, $contractVersion: String!) {
  customerTransactionsByContract(
    accountId: $accountId
    departmentId: $departmentId
    contractId: $contractId
    contractVersion: $contractVersion
  ) {
    id
    accountId
    name
    matches {
      entityId
      entityName
      entityType
    }
    suggestions {
      entityId
      entityName
      entityType
    }
    discoveryStatus
    document {
      attributes {
        name
        value
      }
    }
    master {
      id
      name
    }
    sources {
      provider {
        id
      }
    }
  }
}
    `;
export const CustomerTransactionsByContractV2Document = `
    query CustomerTransactionsByContractV2($accountId: String!, $contractId: String!) {
  customerTransactionsByContractV2(accountId: $accountId, contractId: $contractId) {
    id
    accountId
    name
    matches {
      entityId
      entityName
      entityType
    }
    suggestions {
      entityId
      entityName
      entityType
    }
    discoveryStatus
    document {
      attributes {
        name
        value
      }
    }
    master {
      id
      name
    }
    sources {
      provider {
        id
      }
    }
  }
}
    `;
export const DiscoveredApplicationDocument = `
    mutation DiscoveredApplication($accountId: ID!, $input: DiscoverApplicationInput!) {
  updateApplication(accountId: $accountId, input: $input) {
    id
    name
    dataHealthConfigs {
      code
      enabled
    }
    dataHealth {
      status
      checks {
        code
        status
      }
    }
    children {
      id
    }
    matches {
      entityId
      entityName
      entityType
    }
    suggestions {
      entityId
      entityName
      entityType
    }
    license {
      quantity
      type
    }
    status
    master {
      id
      name
    }
  }
}
    `;
export const UpdateCustomerTransactionDocument = `
    mutation UpdateCustomerTransaction($accountId: ID!, $input: DiscoverCustomerTransactionInput!) {
  updateCustomerTransaction(accountId: $accountId, input: $input) {
    id
    accountId
    name
    matches {
      entityId
      entityName
      entityType
    }
    suggestions {
      entityId
      entityName
      entityType
    }
    discoveryStatus
    master {
      id
      name
    }
  }
}
    `;
export const DiscoveredCustomerVendorDocument = `
    mutation DiscoveredCustomerVendor($accountId: ID!, $input: DiscoverCustomerVendorInput!) {
  updateCustomerVendor(accountId: $accountId, input: $input) {
    id
    accountId
    name
    matches {
      entityId
      entityName
      entityType
    }
    suggestions {
      entityId
      entityName
      entityType
    }
    discoveryStatus
    master {
      id
      name
    }
  }
}
    `;
export const CustomerVendorsDocument = `
    query CustomerVendors($accountId: String!, $status: DiscoveryStatus, $createdAfter: String) {
  customerVendors(
    accountId: $accountId
    status: $status
    createdAfter: $createdAfter
  ) {
    id
    accountId
    name
    matches {
      entityId
      entityName
      entityType
    }
    suggestions {
      entityId
      entityName
      entityType
    }
    discoveryStatus
    master {
      id
      name
    }
    sources {
      provider {
        id
      }
    }
  }
}
    `;
export const CustomerVendorsPaginatedDocument = `
    query CustomerVendorsPaginated($accountId: String!, $nextToken: String, $limit: Int, $status: DiscoveryStatus, $createdAfter: String) {
  customerVendorsPaginated(
    accountId: $accountId
    nextToken: $nextToken
    limit: $limit
    status: $status
    createdAfter: $createdAfter
  ) {
    items {
      id
      accountId
      name
      matches {
        entityId
        entityName
        entityType
      }
      suggestions {
        entityId
        entityName
        entityType
      }
      discoveryStatus
      master {
        id
        name
      }
      sources {
        provider {
          id
        }
      }
    }
    pagination {
      nextToken
      finished
      scannedCount
    }
  }
}
    `;
export const CustomerVendorByIdDocument = `
    query CustomerVendorById($accountId: String!, $vendorId: String!) {
  customerVendorById(accountId: $accountId, vendorId: $vendorId) {
    id
    accountId
    name
    matches {
      entityId
      entityName
      entityType
    }
    suggestions {
      entityId
      entityName
      entityType
    }
    discoveryStatus
    master {
      id
      name
    }
    sources {
      provider {
        id
      }
    }
  }
}
    `;
export const DataSourceDocument = `
    query DataSource($accountId: String!, $code: String!) {
  dataSource(accountId: $accountId, code: $code) {
    class
    name
    status
    version
  }
}
    `;
export const DataSourcesDocument = `
    query DataSources($accountId: String!) {
  dataSources(accountId: $accountId) {
    code
    class
    name
    status
    version
  }
}
    `;
export const UpdateDataSourceDocument = `
    mutation UpdateDataSource($accountId: ID!, $dataSourceCode: String!, $input: UpdateDataSourceInput!) {
  updateDataSource(
    accountId: $accountId
    dataSourceCode: $dataSourceCode
    input: $input
  ) {
    status
  }
}
    `;
export const ProvidersDocument = `
    query Providers($accountId: String!) {
  providers(accountId: $accountId) {
    id
    status
  }
}
    `;
export const ProviderDocument = `
    query Provider($accountId: String!, $providerId: String!) {
  provider(accountId: $accountId, providerId: $providerId) {
    id
    status
  }
}
    `;
export const JobsPaginatedDocument = `
    query JobsPaginated($accountId: String!, $nextToken: String, $limit: Int) {
  jobsPaginated(accountId: $accountId, nextToken: $nextToken, limit: $limit) {
    items {
      id
      status
      executionId
      updatedAt
    }
    pagination {
      nextToken
      finished
      scannedCount
    }
  }
}
    `;
export const TasksPaginatedDocument = `
    query TasksPaginated($accountId: String!, $nextToken: String, $limit: Int) {
  tasksPaginated(accountId: $accountId, nextToken: $nextToken, limit: $limit) {
    items {
      id
      type
      dataSource
      status
      range {
        start
        end
      }
      jobId
      createdAt
      errorData {
        retryable
        remainingAttempts
        errorDetails
      }
    }
    pagination {
      nextToken
      finished
      scannedCount
    }
  }
}
    `;
export const UpdateThreeDataSourcesDocument = `
    mutation UpdateThreeDataSources($accountId: ID!, $dataSourceCode1: String!, $dataSourceCode2: String!, $dataSourceCode3: String!, $input: UpdateDataSourceInput!) {
  status1: updateDataSource(
    accountId: $accountId
    dataSourceCode: $dataSourceCode1
    input: $input
  ) {
    status
  }
  status2: updateDataSource(
    accountId: $accountId
    dataSourceCode: $dataSourceCode2
    input: $input
  ) {
    status
  }
  status3: updateDataSource(
    accountId: $accountId
    dataSourceCode: $dataSourceCode3
    input: $input
  ) {
    status
  }
}
    `;
export const ExecuteDiscoveryJobDocument = `
    mutation ExecuteDiscoveryJob($accountId: ID!, $input: DiscoveryJobInput!) {
  executeDiscoveryJob(accountId: $accountId, input: $input) {
    id
    status
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    MeasuresForApplications: build.query<MeasuresForApplicationsQuery, MeasuresForApplicationsQueryVariables>({
      query: (variables) => ({ document: MeasuresForApplicationsDocument, variables }),
    }),
    MeasuresForApplicationGroups: build.query<
      MeasuresForApplicationGroupsQuery,
      MeasuresForApplicationGroupsQueryVariables
    >({
      query: (variables) => ({ document: MeasuresForApplicationGroupsDocument, variables }),
    }),
    Runner: build.query<RunnerQuery, RunnerQueryVariables>({
      query: (variables) => ({ document: RunnerDocument, variables }),
    }),
    GetProvider: build.query<GetProviderQuery, GetProviderQueryVariables>({
      query: (variables) => ({ document: GetProviderDocument, variables }),
    }),
    GetProviders: build.query<GetProvidersQuery, GetProvidersQueryVariables>({
      query: (variables) => ({ document: GetProvidersDocument, variables }),
    }),
    ActivateProvider: build.mutation<ActivateProviderMutation, ActivateProviderMutationVariables>({
      query: (variables) => ({ document: ActivateProviderDocument, variables }),
    }),
    DeactivateProvider: build.mutation<DeactivateProviderMutation, DeactivateProviderMutationVariables>({
      query: (variables) => ({ document: DeactivateProviderDocument, variables }),
    }),
    ApplicationsBasic: build.query<ApplicationsBasicQuery, ApplicationsBasicQueryVariables>({
      query: (variables) => ({ document: ApplicationsBasicDocument, variables }),
    }),
    RunnerStats: build.query<RunnerStatsQuery, RunnerStatsQueryVariables>({
      query: (variables) => ({ document: RunnerStatsDocument, variables }),
    }),
    CustomerTransactions: build.query<CustomerTransactionsQuery, CustomerTransactionsQueryVariables>({
      query: (variables) => ({ document: CustomerTransactionsDocument, variables }),
    }),
    CustomerTransactionsByContract: build.query<
      CustomerTransactionsByContractQuery,
      CustomerTransactionsByContractQueryVariables
    >({
      query: (variables) => ({ document: CustomerTransactionsByContractDocument, variables }),
    }),
    CustomerTransactionsByContractV2: build.query<
      CustomerTransactionsByContractV2Query,
      CustomerTransactionsByContractV2QueryVariables
    >({
      query: (variables) => ({ document: CustomerTransactionsByContractV2Document, variables }),
    }),
    DiscoveredApplication: build.mutation<DiscoveredApplicationMutation, DiscoveredApplicationMutationVariables>({
      query: (variables) => ({ document: DiscoveredApplicationDocument, variables }),
    }),
    UpdateCustomerTransaction: build.mutation<
      UpdateCustomerTransactionMutation,
      UpdateCustomerTransactionMutationVariables
    >({
      query: (variables) => ({ document: UpdateCustomerTransactionDocument, variables }),
    }),
    DiscoveredCustomerVendor: build.mutation<
      DiscoveredCustomerVendorMutation,
      DiscoveredCustomerVendorMutationVariables
    >({
      query: (variables) => ({ document: DiscoveredCustomerVendorDocument, variables }),
    }),
    CustomerVendors: build.query<CustomerVendorsQuery, CustomerVendorsQueryVariables>({
      query: (variables) => ({ document: CustomerVendorsDocument, variables }),
    }),
    CustomerVendorsPaginated: build.query<CustomerVendorsPaginatedQuery, CustomerVendorsPaginatedQueryVariables>({
      query: (variables) => ({ document: CustomerVendorsPaginatedDocument, variables }),
    }),
    CustomerVendorById: build.query<CustomerVendorByIdQuery, CustomerVendorByIdQueryVariables>({
      query: (variables) => ({ document: CustomerVendorByIdDocument, variables }),
    }),
    DataSource: build.query<DataSourceQuery, DataSourceQueryVariables>({
      query: (variables) => ({ document: DataSourceDocument, variables }),
    }),
    DataSources: build.query<DataSourcesQuery, DataSourcesQueryVariables>({
      query: (variables) => ({ document: DataSourcesDocument, variables }),
    }),
    UpdateDataSource: build.mutation<UpdateDataSourceMutation, UpdateDataSourceMutationVariables>({
      query: (variables) => ({ document: UpdateDataSourceDocument, variables }),
    }),
    Providers: build.query<ProvidersQuery, ProvidersQueryVariables>({
      query: (variables) => ({ document: ProvidersDocument, variables }),
    }),
    Provider: build.query<ProviderQuery, ProviderQueryVariables>({
      query: (variables) => ({ document: ProviderDocument, variables }),
    }),
    JobsPaginated: build.query<JobsPaginatedQuery, JobsPaginatedQueryVariables>({
      query: (variables) => ({ document: JobsPaginatedDocument, variables }),
    }),
    TasksPaginated: build.query<TasksPaginatedQuery, TasksPaginatedQueryVariables>({
      query: (variables) => ({ document: TasksPaginatedDocument, variables }),
    }),
    UpdateThreeDataSources: build.mutation<UpdateThreeDataSourcesMutation, UpdateThreeDataSourcesMutationVariables>({
      query: (variables) => ({ document: UpdateThreeDataSourcesDocument, variables }),
    }),
    ExecuteDiscoveryJob: build.mutation<ExecuteDiscoveryJobMutation, ExecuteDiscoveryJobMutationVariables>({
      query: (variables) => ({ document: ExecuteDiscoveryJobDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useMeasuresForApplicationsQuery,
  useLazyMeasuresForApplicationsQuery,
  useMeasuresForApplicationGroupsQuery,
  useLazyMeasuresForApplicationGroupsQuery,
  useRunnerQuery,
  useLazyRunnerQuery,
  useGetProviderQuery,
  useLazyGetProviderQuery,
  useGetProvidersQuery,
  useLazyGetProvidersQuery,
  useActivateProviderMutation,
  useDeactivateProviderMutation,
  useApplicationsBasicQuery,
  useLazyApplicationsBasicQuery,
  useRunnerStatsQuery,
  useLazyRunnerStatsQuery,
  useCustomerTransactionsQuery,
  useLazyCustomerTransactionsQuery,
  useCustomerTransactionsByContractQuery,
  useLazyCustomerTransactionsByContractQuery,
  useCustomerTransactionsByContractV2Query,
  useLazyCustomerTransactionsByContractV2Query,
  useDiscoveredApplicationMutation,
  useUpdateCustomerTransactionMutation,
  useDiscoveredCustomerVendorMutation,
  useCustomerVendorsQuery,
  useLazyCustomerVendorsQuery,
  useCustomerVendorsPaginatedQuery,
  useLazyCustomerVendorsPaginatedQuery,
  useCustomerVendorByIdQuery,
  useLazyCustomerVendorByIdQuery,
  useDataSourceQuery,
  useLazyDataSourceQuery,
  useDataSourcesQuery,
  useLazyDataSourcesQuery,
  useUpdateDataSourceMutation,
  useProvidersQuery,
  useLazyProvidersQuery,
  useProviderQuery,
  useLazyProviderQuery,
  useJobsPaginatedQuery,
  useLazyJobsPaginatedQuery,
  useTasksPaginatedQuery,
  useLazyTasksPaginatedQuery,
  useUpdateThreeDataSourcesMutation,
  useExecuteDiscoveryJobMutation,
} = injectedRtkApi;
