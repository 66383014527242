import React from 'react';
import styled from '@mui/material/styles/styled';
import Text from '../Text';

type CardHeaderCaptionProps = {
  children: React.ReactNode;
};

export const StyledCardHeaderCaption = styled(Text)({});

export const CardHeaderCaption: React.FC<CardHeaderCaptionProps> = ({ children }) => (
  <StyledCardHeaderCaption color="text3" variant="body-regular">
    {children}
  </StyledCardHeaderCaption>
);
