import { useCreateNewPurchaseRequest } from '../../../modules/intelligentWorkflows';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import useIntakeForm from '../../../hooks/useIntakeForm';

export const useCreatePurchaseRequest = () => {
  const { t } = useTranslation();
  const { navigate } = useRouteNavigate();
  const routes = useRoutes();
  const { enqueueSnackbar } = useSnackbar();
  const intakeForm = useIntakeForm();

  const [createIWPurchaseRequest, isCreatingIWPurchaseRequest] = useCreateNewPurchaseRequest();
  const createPurchaseRequest = useCallback(
    async (vendorId: string, vendorName?: string) => {
      const pollingSuccessful = await createIWPurchaseRequest({
        vendor: { type: 'PREDEFINED', id: vendorId, name: vendorName },
      });
      if (pollingSuccessful) {
        enqueueSnackbar(t('ENTITIES.WORKFLOW_REQUEST.SNACKBARS.CREATED'), { variant: 'success' });
      }
      navigate(routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL, {
        requestId: pollingSuccessful!,
      });
    },
    [createIWPurchaseRequest, enqueueSnackbar, navigate, routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL, t]
  );

  return {
    isLoading: isCreatingIWPurchaseRequest,
    createPurchaseRequest,
    legacyIntakeForm: intakeForm,
  };
};
