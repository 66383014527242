import { ResultTooltipFormatter } from './types';
import React from 'react';
import Text from '@verticeone/design-system/src/components/Text';
import { Stack } from '@mui/material';
import { valueFormatter } from './valueFormatters';

export const summaryTooltipFormatter: ResultTooltipFormatter = ({ results }, { locale, t }) => {
  if (
    results?.__typename !== 'MulticlassBinnedTestResult' &&
    results?.__typename !== 'BinnedTestResult' &&
    results?.__typename !== 'BooleanTestResult'
  ) {
    return null;
  }

  if ((results?.summary.tooltip?.data?.length ?? 0) <= 0) {
    return null;
  }

  const tooltip = results?.summary.tooltip!;

  return (
    <Stack>
      {tooltip?.data?.map((row: string[], row_index: number) => (
        <Stack key={row_index} direction="row" gap={4} alignItems="center">
          {row.map((column: string, col_index: number) => (
            <Text key={column} variant="body-regular" flex={col_index === 0 ? 2 : 1}>
              {valueFormatter(column, tooltip.types![col_index], locale, t)}
            </Text>
          ))}
        </Stack>
      ))}
    </Stack>
  );
};
