import React from 'react';
import { useNavigate } from 'react-router-dom';
import NoData, { NoDataButton } from '@verticeone/design-system/src/components/NoData';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { ROUTES } from '@vertice/utils/src/constants/cfa/constants';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

const NoDataWrapper = styled('div')`
  display: flex;
  max-width: 30rem;
  height: 100%;
  margin: auto;
`;

const NoRows = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <NoDataWrapper>
      <NoData
        header={t(`APPLICATIONS.USAGE.USERS_GRID.NO_DATA.HEADER`)}
        content={t(`APPLICATIONS.USAGE.USERS_GRID.NO_DATA.CONTENT`)}
        button={
          <NoDataButton
            text={t(`APPLICATIONS.USAGE.USERS_GRID.NO_DATA.BUTTON`)}
            onClick={() => navigate(`${ROUTES.PREFERENCES}/${ROUTES.INTEGRATIONS}`)}
          />
        }
      />
    </NoDataWrapper>
  );
};

export default NoRows;
