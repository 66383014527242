import { useTranslation } from 'react-i18next';

import Text from '@verticeone/design-system/src/components/Text';
import Drawer, { DrawerBody, DrawerHeader, DrawerHeaderTitle } from '@verticeone/design-system/src/components/Drawer';
import { CardCloseButton, CardHeaderActions } from '@verticeone/design-system/src/components/Card';
import NoData from '@verticeone/design-system/src/components/NoData';
import { DataInOrderIcon } from '@vertice/assets';
import { AlarmDay } from '@vertice/slices/src/graphql/bffeUsage/generated/usageGraphQL';
import ErrorLogItem from './ErrorLogItem';

type ErrorLogDrawerProps = {
  open: boolean;
  onClose: () => void;
  alarmsPerDay?: AlarmDay[];
};

export const ErrorLogDrawer = ({ open, onClose, alarmsPerDay }: ErrorLogDrawerProps) => {
  const { t } = useTranslation();

  const DRAWER_COLUMN_WIDTH = 5;
  const DATA_IN_ORDER_ICON_SIZE = 200;

  return (
    <Drawer width={`calc(100% / 12 * ${DRAWER_COLUMN_WIDTH})`} open={open} onClose={onClose}>
      <DrawerHeader>
        <DrawerHeaderTitle text={t('APPLICATIONS.USAGE.ERROR_LOG.HEADER_TITLE')} />
        <Text variant="body-bold" size="M" color="text2">
          {t('APPLICATIONS.USAGE.ERROR_LOG.HEADER_SUBTITLE')}
        </Text>
        <Text variant="body-regular" size="M" color="text2">
          {t('APPLICATIONS.USAGE.ERROR_LOG.HEADER_CONTENT')}
        </Text>
        <CardHeaderActions>
          <CardCloseButton onClick={onClose} />
        </CardHeaderActions>
      </DrawerHeader>
      <DrawerBody gap={2} flex={1}>
        {alarmsPerDay === undefined || alarmsPerDay.length === 0 ? (
          <NoData
            icon={<DataInOrderIcon width={DATA_IN_ORDER_ICON_SIZE} height={DATA_IN_ORDER_ICON_SIZE} />}
            header={t('APPLICATIONS.USAGE.ERROR_LOG.NO_DATA_HEADER')}
            content={t('APPLICATIONS.USAGE.ERROR_LOG.NO_DATA_CONTENT')}
          />
        ) : (
          alarmsPerDay?.map((dailyAlarm) => <ErrorLogItem dailyAlarm={dailyAlarm} key={dailyAlarm.date} />)
        )}
      </DrawerBody>
    </Drawer>
  );
};
