import { WorkflowVersion } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { ServiceCatalogResource } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useMemo } from 'react';
import { isVerticeWorkflowHandler, parseServiceHandlerRef } from '@vertice/core/src/hooks/workflows/refUtils';

export const useIsDefaultWorkflow = (workflowVersion?: WorkflowVersion, service?: ServiceCatalogResource) => {
  return useMemo(() => {
    if (workflowVersion !== undefined && service !== undefined) {
      const parsedServiceHandler = parseServiceHandlerRef(service.definition.ServiceProvider?.HandlerUrn);
      if (isVerticeWorkflowHandler(parsedServiceHandler)) {
        return parsedServiceHandler.workflowId === workflowVersion.id;
      }
    }
    return false;
  }, [workflowVersion, service]);
};
