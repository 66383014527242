import React, { ForwardedRef } from 'react';
import { Alert, Stack, styled, Typography } from '@mui/material';
import { CustomContentProps, useSnackbar, SnackbarContent, WithDescription } from 'notistack';
import { Close, CheckCircleOutline } from '@mui/icons-material';

import type { NotificationSeverity } from './types';
import type { WithRequired } from '../../types/WithRequired';

const ICON_MAP: Partial<Record<NotificationSeverity, React.ReactNode>> = {
  success: <CheckCircleOutline fontSize="inherit" />,
};

const CloseButton = styled('button')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  color: var(--tokens-color-light-text-5);
  background: var(--global-transparency-white-20);
  font-size: 0.7rem !important;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  &:hover {
    background: var(--global-transparency-black-20);
  }
`;

const StyledAlert = styled(Alert)<{ severity: NotificationSeverity }>`
  background: var(${(props) => `--tokens-color-light-${props.severity}-2`});
  border: 1px solid var(${(props) => `--tokens-color-light-${props.severity}-1`});
  padding: 0.9rem 1rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  min-width: 20rem;
  max-width: 35rem;
  & > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .MuiAlert-action {
    margin: 0 0 0 auto;
  }
  .MuiAlert-icon svg {
    opacity: 0.6;
  }
`;

const Title = styled(Typography)`
  font-weight: 600;
`;

const Description = styled(Typography)`
  color: var(--tokens-color-light-text-5);
  opacity: 0.6;
`;

const Notification = React.forwardRef(
  (
    {
      id,
      variant,
      message,
      style,
      className,
      description,
      action,
    }: WithRequired<CustomContentProps & WithDescription, 'message'>,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { closeSnackbar } = useSnackbar();

    return (
      <SnackbarContent ref={ref} role="alert" style={style} className={className}>
        <StyledAlert
          variant="filled"
          action={
            <Stack direction="row" gap={1}>
              {typeof action === 'function' ? action(id) : action}
              <CloseButton onClick={() => closeSnackbar(id)}>
                <Close fontSize="small" />
              </CloseButton>
            </Stack>
          }
          iconMapping={ICON_MAP}
          severity={(variant as NotificationSeverity) ?? 'info'}
        >
          <Stack flexDirection="column">
            {description ? (
              <>
                <Title variant="body-regular-m">{message}</Title>
                {description && <Description variant="body-regular-m">{description}</Description>}
              </>
            ) : (
              <Typography variant="body-regular-m">{message}</Typography>
            )}
          </Stack>
        </StyledAlert>
      </SnackbarContent>
    );
  }
);

export default Notification;
