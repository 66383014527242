import React, { useMemo } from 'react';
import { Divider, Stack } from '@mui/material';
import Button from '@verticeone/design-system/src/components/Button';
import { DesignSystemSize } from '@verticeone/design-system/src/types';

type ButtonProps = {
  title: string;
  onClick?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
};

type ButtonsProps = {
  primary?: ButtonProps;
  secondary?: ButtonProps;
  fullWidth?: boolean;
};

export const Buttons: React.FC<ButtonsProps> = ({ primary, secondary, fullWidth = false }) => {
  const commonProps = useMemo(
    () => ({
      fullWidth: true,
      size: 'S' as DesignSystemSize,
      isCaption: true,
    }),
    []
  );

  return (
    <>
      <Divider />
      <Stack p={8} direction={'row'} justifyContent={'center'}>
        <Stack flexShrink={0} maxWidth={fullWidth ? '100%' : 500} direction="row" gap={4} flexGrow={1}>
          {secondary !== undefined && secondary.onClick && (
            <Button color="neutral" variant="outline" {...secondary} {...commonProps}>
              {secondary.title}
            </Button>
          )}
          {primary !== undefined && primary.onClick && (
            <Button color="primary" variant="solid" {...primary} {...commonProps}>
              {primary.title}
            </Button>
          )}
        </Stack>
      </Stack>
    </>
  );
};
