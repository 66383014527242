import React from 'react';
import { Stack } from '@mui/material';
import Text from '../../Text';
import { SIZE_DEFINITION } from '../constants';
import { useStaticDateRangePickerContext } from '../context/StaticDateRangePickerContext';

type InfoTextProps = {
  children: string;
};

const InfoText = ({ children }: InfoTextProps) => {
  const { size } = useStaticDateRangePickerContext();

  return (
    <Stack direction="row" padding={SIZE_DEFINITION[size].defaultPadding}>
      <Text variant="body-regular" size={size} color="text2">
        {children}
      </Text>
    </Stack>
  );
};

export default InfoText;
