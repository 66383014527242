import { useCallback, useContext, useMemo, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { Chart as HighchartsChart } from 'highcharts';
import { Palette, Stack, useTheme } from '@mui/material';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { cssObjectToString } from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import { yLabelStyles } from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import { useTranslation } from 'react-i18next';
import Text from '@verticeone/design-system/src/components/Text';

import { UsageContext } from '@vertice/core/src/modules/applications/Applications/UsageContext';
import { ActivityFrequencyData } from '@vertice/slices/src/graphql/bffeUsage/derivedTypes';
import { useStackedColumnHover } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStackedColumnHover';
import { EMPTY_FILTER, Tabs } from '../../utils';
import { ACTIVITY_FREQUENCIES, Bins, extractActivityFrequency } from './utils';

type ActivityFrequencyGraphProps = {
  activityFrequency: ActivityFrequencyData;
};

export const yLabelFormatter =
  (palette: Palette, locale: string) =>
  ({ value }: { value: number | string }) => {
    return `<span style="${cssObjectToString(yLabelStyles(palette))}">${value}</span>`;
  };

const ActivityFrequencyGraph = ({ activityFrequency }: ActivityFrequencyGraphProps) => {
  const { locale } = useLocaleContext();
  const { t } = useTranslation();
  const { palette } = useTheme();

  const [chart, setChart] = useState<HighchartsChart | null>(null);
  const saveChartRef = useCallback((ch: HighchartsChart) => setChart(ch), []);

  const applyXAxisOffset = useXAxisOffset();

  const applyStyledHighcharts = useStyledHighcharts();
  const applyStackedColumnHover = useStackedColumnHover();

  const data = useMemo(() => extractActivityFrequency(activityFrequency), [activityFrequency]);
  const labels = useMemo(() => Bins.map((bin) => t(`APPLICATIONS.USAGE.ACTIVITY_FREQUENCY.BINS.${bin}`)), [t]);

  const { setActiveTab, setUserActivityFilter } = useContext(UsageContext);

  const optionOverrides = useMemo(
    () =>
      buildOptions([
        applyXAxisOffset,
        applyStyledHighcharts,
        applyStackedColumnHover,
        (opts) =>
          mergeOptions(
            {
              chart: { type: 'column', height: 300 },
              yAxis: {
                labels: {
                  formatter: yLabelFormatter(palette, locale),
                },
              },
              xAxis: {
                categories: labels,
              },
              plotOptions: {
                column: {
                  stacking: 'normal',
                  borderRadius: 3,
                },
                series: {
                  marker: {
                    enabled: false,
                  },
                  cursor: 'pointer',
                  point: {
                    events: {
                      click: (event) => {
                        setActiveTab(Tabs.USERS);
                        setUserActivityFilter({
                          ...EMPTY_FILTER,
                          [ACTIVITY_FREQUENCIES[event.point.index]]: true,
                        });
                      },
                    },
                  },
                },
              },
              series: [
                {
                  id: 'logins',
                  data: data.values,
                  type: 'column',
                  color: palette.primary.color3,
                  states: {
                    hover: { color: palette.primary.hover.color2 },
                  },
                  index: 1,
                },
              ],
            },
            opts
          ),
      ]),
    [
      applyXAxisOffset,
      applyStyledHighcharts,
      applyStackedColumnHover,
      palette,
      locale,
      labels,
      data.values,
      setActiveTab,
      setUserActivityFilter,
    ]
  );

  return (
    <Stack gap={4} pb={4} sx={{ backgroundColor: palette.core.color1 }}>
      <Stack
        gap={1}
        paddingY={3}
        pl={7}
        sx={{ backgroundColor: palette.core.color1, borderBottom: '1px solid', borderColor: palette.core.color3 }}
      >
        <Text variant="body-bold" size="S" color={palette.text.color2}>
          {t('APPLICATIONS.USAGE.ACTIVITY_FREQUENCY.CHART.MOST_COMMON')}
        </Text>
        <Text variant="body-bold" size="M">
          {t(`APPLICATIONS.USAGE.ACTIVITY_FREQUENCY.BINS.${data.mostCommon.bin}`)}
        </Text>
      </Stack>

      <HighchartsReact highcharts={Highcharts} options={optionOverrides} callback={saveChartRef} />
      <HighchartTooltip chart={chart}>
        {(context) => {
          return (
            <TooltipWrapper>
              {t('APPLICATIONS.USAGE.ACTIVITY_FREQUENCY.TOOLTIP.USERS', { count: context.y || 0 })}
            </TooltipWrapper>
          );
        }}
      </HighchartTooltip>
    </Stack>
  );
};

export default ActivityFrequencyGraph;
