import { Trans, useTranslation } from 'react-i18next';
import { Box, Stack, styled } from '@mui/material';

import Text from '@verticeone/design-system/src/components/Text';
import ChipButton, { ChipButtonProps } from '@verticeone/design-system/src/components/ChipButton';
import Accordion, {
  AccordionDetails,
  AccordionSummary,
  AccordionMultiChipContainer,
} from '@verticeone/design-system/src/components/Accordion';
import { Divider } from '@vertice/core/src/components/Dialog/Components';
import { isNotNil } from '@vertice/core/src/utils/validation/isNotNil';
import {
  Alarm,
  AlarmDay,
  AlarmStatus,
  ErrorLogCategory,
} from '@vertice/slices/src/graphql/bffeUsage/generated/usageGraphQL';
import { useFormatDate } from '@vertice/core/src/utils/formatting/date';
import i18n from '@vertice/i18n/src/i18n';
import { getProvider } from '../LastFetchedInfo/LastFetchedTooltip';

const LogItemWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2.75),
  border: `1px solid ${theme.palette.core.color3}`,
  borderRadius: theme.spacing(2),
}));

const ErrorMessageBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  border: `1px solid ${theme.palette.core.color3}`,
  borderRadius: theme.spacing(2),
}));

type ErrorLogItemProps = {
  dailyAlarm: AlarmDay;
};

const isFailedAlarm = (alarm: Alarm) => alarm.status === AlarmStatus.Failed && alarm.errorLog;
const isErrorLogWarning = (category?: ErrorLogCategory) => category === ErrorLogCategory.ThrottlingIssues;

const ErrorLogItem = ({ dailyAlarm }: ErrorLogItemProps) => {
  const { t } = useTranslation();

  const formatDate = useFormatDate();

  const dailyAlarmErrors =
    dailyAlarm.alarms?.filter((alarm) => isFailedAlarm(alarm) && !isErrorLogWarning(alarm.errorLog?.category)) || [];
  const dailyAlarmWarnings =
    dailyAlarm.alarms?.filter((alarm) => isFailedAlarm(alarm) && isErrorLogWarning(alarm.errorLog?.category)) || [];
  const failedDailyAlarms = [...dailyAlarmErrors, ...dailyAlarmWarnings];

  const ErrorsChipButton =
    dailyAlarmErrors.length > 0
      ? ({
          color: 'error',
          variant: 'ghost',
          children: t('APPLICATIONS.USAGE.ERROR_LOG.ERROR', {
            count: dailyAlarmErrors.length,
          }),
          label: t('APPLICATIONS.USAGE.ERROR_LOG.ERROR', {
            count: dailyAlarmErrors.length,
          }),
        } satisfies ChipButtonProps & { label: string })
      : undefined;
  const WarningsChipButton =
    dailyAlarmWarnings.length > 0
      ? ({
          color: 'warning',
          variant: 'ghost',
          children: t('APPLICATIONS.USAGE.ERROR_LOG.WARNING', {
            count: dailyAlarmWarnings.length,
          }),
          label: t('APPLICATIONS.USAGE.ERROR_LOG.WARNING', {
            count: dailyAlarmWarnings.length,
          }),
        } satisfies ChipButtonProps & { label: string })
      : undefined;

  if (failedDailyAlarms.length === 0)
    return (
      <LogItemWrapper>
        <Text variant="button" size="S">
          {formatDate(dailyAlarm.date)}
        </Text>
        <ChipButton variant="ghost" size="S" color="success" isActive={false}>
          {t('APPLICATIONS.USAGE.ERROR_LOG.OK')}
        </ChipButton>
      </LogItemWrapper>
    );

  return (
    <Accordion variant="outlined" size="S">
      <AccordionSummary
        title={
          <Text variant="button" size="S">
            {formatDate(dailyAlarm.date)}
          </Text>
        }
        adornment={<AccordionMultiChipContainer chips={[ErrorsChipButton, WarningsChipButton].filter(isNotNil)} />}
      />
      <AccordionDetails>
        <Stack gap={4}>
          {failedDailyAlarms.map((alarm, index, { length }) => (
            <Stack gap={4} key={alarm.errorLog?.id}>
              <Stack direction="row" gap={1}>
                {getProvider(alarm.providerName)?.icon}
                <Text
                  variant="body-bold"
                  size="S"
                  color={isErrorLogWarning(alarm.errorLog?.category) ? 'warning2' : 'error'}
                >
                  {isErrorLogWarning(alarm.errorLog?.category)
                    ? t('APPLICATIONS.USAGE.ERROR_LOG.WARNING_IDENTIFIED')
                    : t('APPLICATIONS.USAGE.ERROR_LOG.ERROR_IDENTIFIED')}
                </Text>
                <Text variant="body-bold" size="S">
                  {getProvider(alarm.providerName)?.name}
                </Text>
              </Stack>
              <Text variant="body-regular" size="S" color="text2">
                <Trans
                  i18n={i18n}
                  i18nKey={
                    isErrorLogWarning(alarm.errorLog?.category)
                      ? 'APPLICATIONS.USAGE.ERROR_LOG.WARNING_DESCRIPTION'
                      : 'APPLICATIONS.USAGE.ERROR_LOG.ERROR_DESCRIPTION'
                  }
                  date={alarm.errorLog?.createdAt}
                  tOptions={{ date: alarm.errorLog?.createdAt }}
                  components={{ b: <Text variant="body-bold" size="S" /> }}
                />
              </Text>
              <Stack gap={1}>
                <Text variant="caption" size="XS" color="text2">
                  {t('APPLICATIONS.USAGE.ERROR_LOG.ERROR_CODE')}
                </Text>
                <ErrorMessageBox>
                  <Text variant="code" size="S" color="error2">
                    {alarm.errorLog?.errorMessage}
                  </Text>
                </ErrorMessageBox>
              </Stack>
              {index + 1 < length && <Divider />}
            </Stack>
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default ErrorLogItem;
