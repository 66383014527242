import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Clear, Search } from '@mui/icons-material';
import { useDebouncedCallback } from 'use-debounce';
import IconButton from '@verticeone/design-system/src/components/IconButton';
import TextField, { TextFieldProps } from '@verticeone/design-system/src/components/TextField';
import IconWrapper from '@verticeone/design-system/src/components/IconWrapper';
import InputAdornment from '@verticeone/design-system/src/components/InputAdornment/InputAdornment';

type SearchInputProps = Partial<TextFieldProps> & {
  value: string;
  onSearch: (value: string) => void;
  debounce?: number;
};

const SearchInput = ({ value, onSearch, debounce = 500, ...inputProps }: SearchInputProps) => {
  const [search, setSearch] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);
  const onDebouncedSearch = useDebouncedCallback((newValue: string) => onSearch(newValue), debounce);

  useEffect(() => {
    setSearch(value);
  }, [value]);

  const onChangeHandler = useCallback((newValue: string) => {
    onDebouncedSearch(newValue);
    setSearch(newValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClear = () => {
    onChangeHandler('');
    inputRef.current?.focus();
  };

  return (
    <>
      <TextField
        variant="solid"
        color="neutral"
        {...inputProps}
        hiddenLabel
        value={search}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeHandler(e.target.value)}
        ref={inputRef}
        startAdornment={
          <InputAdornment position="start">
            <IconWrapper icon={Search} />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            {!!search && (
              <IconButton
                variant="plain"
                icon={Clear}
                onClick={onClear}
                sx={{
                  color: 'inherit',
                  mx: '-8px',
                }}
              />
            )}
          </InputAdornment>
        }
      />
    </>
  );
};

export default SearchInput;
