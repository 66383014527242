import BaseCloudAccountsGraph from './BaseCloudAccountsGraph';
import React from 'react';
import useAccountServicesUsageGraphData from '../useAccountServicesUsageGraphData';

type CloudAccountsServicesGraphProps = {
  linkedAccountId: string;
  productCode: string;
};

const CloudAccountServicesUsageGraph = ({ linkedAccountId, productCode }: CloudAccountsServicesGraphProps) => {
  const { data: graphData, ...loadingStatus } = useAccountServicesUsageGraphData({
    linkedAccountId,
    productCode,
  });

  return <BaseCloudAccountsGraph data={graphData!} {...loadingStatus} />;
};

export default CloudAccountServicesUsageGraph;
