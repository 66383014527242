import BaseCloudAccountsGraph from './BaseCloudAccountsGraph';
import React from 'react';
import useAccountServicesGraphData from '../useAccountServicesGraphData';

type CloudAccountsServicesGraphProps = {
  linkedAccountId: string;
};

const CloudAccountServicesGraph = ({ linkedAccountId }: CloudAccountsServicesGraphProps) => {
  const { data: graphData, ...loadingStatus } = useAccountServicesGraphData({
    linkedAccountId,
  });

  return <BaseCloudAccountsGraph data={graphData!} {...loadingStatus} />;
};

export default CloudAccountServicesGraph;
