import React from 'react';
import { formatCurrency } from '@vertice/core/src/utils/formatting/currency';
import { styled, Tooltip, Typography } from '@mui/material';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';

type ChipProps = {
  value: number;
  previousValue: number;
  currency?: string;
};

const StyledChip = styled('div')<{ variant: 'success' | 'error' | 'info' }>(({ theme, variant }) => ({
  borderRadius: '24px',
  padding: '3px 6px',
  background: theme.palette[variant].color3,
  color: theme.palette[variant].color1,
  cursor: 'default',
}));

const Chip = ({ value, previousValue, currency }: ChipProps) => {
  const { locale } = useLocaleContext();

  if (Math.abs(previousValue) < 0.0001) {
    return null;
  }

  const formattedPreviousValue = formatCurrency(previousValue, {
    currency,
    locale,
    maximumFractionDigits: 0,
    withSpace: true,
  });

  const percentageValue = Math.round(((value - previousValue) * 100) / previousValue);
  const percentageString = (percentageValue >= 0 ? '+' : '') + percentageValue + '%';

  // eslint-disable-next-line no-nested-ternary
  const chipVariant = percentageValue === 0 ? 'info' : percentageValue > 0 ? 'error' : 'success';

  return (
    <Tooltip title={formattedPreviousValue}>
      <StyledChip variant={chipVariant}>
        <Typography variant="button-xs">{percentageString}</Typography>
      </StyledChip>
    </Tooltip>
  );
};

export default Chip;
