import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import Select from '@verticeone/design-system/src/components/Select';
import { SxProps } from '@mui/material';
import { TimeThreshold } from '../types';

type TimeThresholdOption = { value: TimeThreshold; label: string };

const TimeThresholdSelect = ({
  value,
  onChange,
  sx,
  disabled,
}: {
  value: TimeThreshold;
  onChange: (value: TimeThreshold) => void;
  sx?: SxProps;
  disabled?: boolean;
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'PREFERENCES.EMAIL_NOTIFICATIONS' });

  const options = useMemo(
    (): TimeThresholdOption[] => [
      { value: 'P3M', label: t('TIME_THRESHOLD.N_MONTHS', { count: 3 }) },
      { value: 'P4M', label: t('TIME_THRESHOLD.N_MONTHS', { count: 4 }) },
      { value: 'P5M', label: t('TIME_THRESHOLD.N_MONTHS', { count: 5 }) },
    ],
    [t]
  );

  return (
    <Select<TimeThresholdOption, false>
      sx={sx}
      variant="solid"
      options={options}
      isDisabled={disabled}
      value={options.find(({ value: v }) => v === value)}
      onChange={(option) => onChange(option?.value!)}
    />
  );
};

export default TimeThresholdSelect;
