import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import Text from '@verticeone/design-system/src/components/Text';
import Button from '@verticeone/design-system/src/components/Button';

import CardGrid from '../../../task/TaskList/CardGrid';
import { TaskRow } from '../../../task/types';
import { useRequestTasks } from '../useRequestTasks';
import { useRequestTaskColumns } from '../useRequestTaskColumns';
import { OnRowClickEvent } from '../../../task/TaskList/CardGrid/types';
import { TaskModal } from '../../../task/TaskModal/TaskModal';

type TasksSectionProps = {
  requestId?: string;
};

export const TasksSection: FC<TasksSectionProps> = ({ requestId }) => {
  const { t } = useTranslation();

  const { activeTasks, allTasks, isLoadingTasks, isFetchingTasks, refetchTasks } = useRequestTasks(requestId);
  const taskColumns = useRequestTaskColumns();

  const [modalState, setModalState] = useState<{ openedTaskId: string; taskIds: string[] } | undefined>(undefined);
  const onOpenTask: OnRowClickEvent<TaskRow> = (openParams) => {
    setModalState({
      openedTaskId: openParams.row.id,
      taskIds: openParams.sortedRows.map((row) => row.id),
    });
  };

  return (
    <>
      <CardGrid<TaskRow>
        columns={taskColumns}
        rows={activeTasks}
        hideHeaders
        loading={isLoadingTasks}
        loadingRowCount={1}
        onRowClick={onOpenTask}
        slots={{
          noRowsOverlay: () => (
            <Stack alignItems="flex-start" direction="column" gap={2}>
              <Text variant="body-regular">{t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.NO_TASKS')}</Text>
              <Button variant="outline" size="S" onClick={refetchTasks} isLoading={isFetchingTasks}>
                {t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.REFRESH_TASKS')}
              </Button>
            </Stack>
          ),
        }}
        rowHeight={67}
        sortModel={[{ field: 'createdAt', sort: 'desc' }]}
      />
      <TaskModal
        onClose={() => setModalState(undefined)}
        defaultOpenTaskId={modalState?.openedTaskId}
        taskIds={modalState?.taskIds}
        allTasks={allTasks}
      />
    </>
  );
};
