import { GridCellParams } from '@mui/x-data-grid-pro';
import React from 'react';

import Text from '@verticeone/design-system/src/components/Text';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Currency } from '@vertice/components';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { useVendorProductsContext } from '../VendorProductsContextProvider';

const AnnualCostCell = ({ value }: GridCellParams<any, number>) => {
  const { locale } = useLocaleContext();
  const { currency } = useVendorProductsContext();
  return (
    <div>
      <Text variant="caption">
        {value === undefined || value === null ? '' : <Currency currency={currency} locale={locale} value={value} />}
      </Text>
    </div>
  );
};

export default AnnualCostCell;
