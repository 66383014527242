import React, { ReactNode } from 'react';
import { Stack } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import styles from './IntegrationsCardContent.module.scss';

interface IntegrationsCardContentProps {
  title: ReactNode | string;
  subtitle?: ReactNode | string;
  description?: ReactNode | string;
  statusBadge?: ReactNode;
}

const IntegrationsCardContent = ({ title, subtitle, description, statusBadge }: IntegrationsCardContentProps) => {
  const titleComponent =
    typeof title === 'string' ? (
      <Text variant="heading" size="S" color="text1">
        {title}
      </Text>
    ) : (
      title
    );

  const subtitleComponent =
    typeof title === 'string' ? (
      <Text variant="heading" size="S" color="text1">
        {subtitle}
      </Text>
    ) : (
      subtitle
    );

  const descriptionComponent =
    typeof description === 'string' ? (
      <Text variant="body-regular" size="S" color="text2">
        {description}
      </Text>
    ) : (
      description
    );

  return (
    <Stack direction="column" gap="16px">
      <Stack direction="column">
        <Stack className={styles['title-section']} direction="row" gap="8px">
          {titleComponent}
          {statusBadge}
        </Stack>
        {subtitleComponent}
      </Stack>
      {descriptionComponent}
    </Stack>
  );
};

export default IntegrationsCardContent;
