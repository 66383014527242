import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { Box, Stack, useTheme } from '@mui/material';

import Card from '@verticeone/design-system/src/components/Card';
import Divider from '@verticeone/design-system/src/components/Divider';
import Grid from '@verticeone/design-system/src/components/Grid';

import { SelectedVendorPanel } from '../shared/formFields/SelectedVendorPanel';
import { FormProductList } from '../shared/formFields/FormProductList';
import { createTypedTaskFormEntry } from '../shared/formFields/TaskFormEntry';
import { FormData } from './schema';
import { TargetSignDateFormEntry } from '../shared/formFields/TargetSignDateFormEntry';
import { AdditionalNotesFormEntry } from '../shared/formFields/AdditionalNotesFormEntry';
import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';
import { DesiredContractLengthFormEntry } from '../shared/formFields/DesiredContractLengthFormEntry';
import { PurchasingManagerPermissions } from '../shared/formSections/PurchasingManagerPermissions';
import { PurchaseRequirements } from '../shared/formSections/PurchaseRequirements';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { CurrencyFormEntry } from '../shared/formFields/CurrencyFormEntry';
import { DepartmentsFormEntry } from '../shared/formFields/DepartmentsFormEntry';
import { MoneyFormEntry } from '../shared/formFields/MoneyFormEntry';
import { useAccountSettings } from '@vertice/core/src/hooks/useAccountSettings';

export const RequirementsGatheringFormEntry = createTypedTaskFormEntry<FormData>();

export const RequirementsGatheringForm = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { setValue } = useFormContext<FormData>();

  const { data: accountSettings } = useAccountSettings();
  const contractCurrency = useWatch<FormData, 'contractCurrency'>({ name: 'contractCurrency' });

  useEffect(() => {
    const defaultCurrency = accountSettings?.preferredCurrency;
    if (defaultCurrency && !contractCurrency) {
      setValue('contractCurrency', defaultCurrency);
    }
  }, [accountSettings, setValue, contractCurrency]);

  return (
    <Stack gap={2}>
      <Card>
        <Box bgcolor={palette.core.color1} p={4}>
          <SelectedVendorPanel />
        </Box>
        <Divider />
        <FormProductList noBorder />
      </Card>
      <Grid container spacing={4} py={4}>
        <DepartmentsFormEntry width={6} />
        <CurrencyFormEntry<FormData> name="contractCurrency" />
        <TargetSignDateFormEntry width={6} />
        <MoneyFormEntry<FormData>
          name="approvedBudget"
          label={t('ENTITIES.CONTRACT.LABELS.APPROVED_BUDGET')}
          currency={contractCurrency}
        />
        <DesiredContractLengthFormEntry />
        <RequirementsGatheringFormEntry
          name="signingEntity"
          label={t('ENTITIES.CONTRACT.LABELS.SIGNING_ENTITY')}
          tooltip={{
            title: t('ENTITIES.CONTRACT.LABELS.SIGNING_ENTITY'),
            content: t('ENTITIES.CONTRACT.TOOLTIPS.SIGNING_ENTITY'),
          }}
          component={FormTextField}
          width={12}
          componentProps={{
            placeholder: t('ENTITIES.CONTRACT.PLACEHOLDERS.SIGNING_ENTITY'),
            color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
          }}
        />
        <Grid item xs={12}>
          <Stack>
            <PurchaseRequirements />
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={4} py={4}>
        <AdditionalNotesFormEntry />
      </Grid>
      <Grid container spacing={4} p={4}>
        <PurchasingManagerPermissions />
      </Grid>
    </Stack>
  );
};
