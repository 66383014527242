import React, { useState } from 'react';
import {
  gridFilteredDescendantCountLookupSelector,
  GridRenderCellParams,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { Box, ButtonProps, Stack } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import { RegionCode, regionsCodeToNameMap } from '../../../regions';
import { useTranslation } from 'react-i18next';
import { ExpandMore, ChevronRight } from '@mui/icons-material';
import IconButton, { IconButtonProps } from '@verticeone/design-system/src/components/IconButton';

const CollapseButton = ({ isOpen, ...props }: Partial<IconButtonProps> & { isOpen: boolean }) => {
  return (
    <IconButton
      {...props}
      size="M"
      variant="plain"
      aria-label={isOpen ? 'Close' : 'Open'}
      sx={{ margin: '0 -6px' }}
      icon={isOpen ? ExpandMore : ChevronRight}
    />
  );
};

const RegionContent = (props: GridRenderCellParams) => {
  const { id, field, rowNode, row } = props;
  const apiRef = useGridApiContext();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RESERVED_INSTANCES' });
  const [isOpen, setOpen] = useState(true);

  const handleCollapseButtonClick: ButtonProps['onClick'] = (event) => {
    setOpen(!isOpen);
    'childrenExpanded' in rowNode && apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <Stack direction="row" alignItems="center">
      <CollapseButton onClick={handleCollapseButtonClick} isOpen={isOpen} />
      <Text variant="body-bold" size="M">{`${t('REGION')}: ${regionsCodeToNameMap[row.region as RegionCode]}`}</Text>
    </Stack>
  );
};

const TypeContent = (props: GridRenderCellParams) => {
  const { row } = props;

  return (
    <Stack direction="row" gap={1}>
      <Text variant="body-regular" size="M">
        {row.instanceFamily}
      </Text>
      {row.availabilityZone && <Text variant="body-regular" size="M">{`(${row.availabilityZone})`}</Text>}
    </Stack>
  );
};

const RegionTypeCell = (props: GridRenderCellParams) => {
  const { rowNode } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

  return (
    <Box sx={{ ml: rowNode.depth * 4 }}>
      {filteredDescendantCount > 0 ? <RegionContent {...props} /> : <TypeContent {...props} />}
    </Box>
  );
};

export default RegionTypeCell;
