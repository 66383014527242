import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import NoRowsOverlay from '../NoRowsOverlay';

type NoRowsProps = {
  readOnlyMode?: boolean;
};
const NoRows: FC<NoRowsProps> = ({ readOnlyMode }) => {
  const { t } = useTranslation();

  return (
    <NoRowsOverlay
      text={t(`INTAKE_FORM.PRODUCTS_TABLE.${readOnlyMode ? 'NO_PRODUCTS_SELECTED' : 'ADD_PRODUCTS_IF_KNOWN'}`)}
    />
  );
};

export default NoRows;
