import React, { forwardRef, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, TypographyProps } from '@mui/material';
import '../../assets/fonts/fonts.css';
import type { CustomizedProps, TestProps } from '../../types';
import useTheme from '@mui/material/styles/useTheme';
import { getShortcutColor, getTextVariantStyle, ShortcutColor, TextPropsCombination } from './utils';
import { testProps } from '../../utils/testProperties';

export type TextProps = { color?: string | ShortcutColor } & TestProps &
  TextPropsCombination &
  CustomizedProps &
  Omit<TypographyProps, 'variant' | 'size' | 'color'> & {
    children?: React.ReactNode;
    tag?: string;
    htmlFor?: string;
  };

const StyledTypography = styled(Typography)<{ $style: any }>(({ $style }) => $style);

const Text = forwardRef<HTMLElement, TextProps>(
  ({ children, variant = 'body-regular', size = 'M', tag = 'span', color = 'inherit', testId, ...otherProps }, ref) => {
    const { palette } = useTheme();

    const style = useMemo(() => getTextVariantStyle({ variant, size } as TextPropsCombination), [variant, size]);
    const finalColor = useMemo(() => getShortcutColor(palette, color) ?? color, [palette, color]);

    return (
      <StyledTypography
        // the particular variant doesn't matter here as it's always mapped to the tag
        variant={'inherit'}
        variantMapping={{ inherit: tag }}
        $style={style}
        color={finalColor}
        {...testProps(testId, 'text')}
        {...otherProps}
        ref={ref}
      >
        {children}
      </StyledTypography>
    );
  }
);

export default Text;
