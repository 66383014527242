import { green, grey } from '@mui/material/colors';
import type { PaletteOptions } from '@mui/material/styles/createPalette';
import type { ThemeVariant } from './types';
import { lightPalette } from './palette/light';
import { darkPalette } from './palette/dark';

const customBlue = {
  50: '#e9f0fb',
  100: '#c8daf4',
  200: '#a3c1ed',
  300: '#7ea8e5',
  400: '#6395e0',
  500: '#4782da',
  600: '#407ad6',
  700: '#376fd0',
  800: '#2f65cb',
  900: '#2052c2 ',
};

export type VariantType = {
  palette: PaletteOptions;
  header?: ColorBgType & {
    search: {
      color: string;
    };
    indicator: {
      background: string;
    };
  };
  footer?: ColorBgType;
  sidebar?: ColorBgType & {
    header: ColorBgType & {
      brand: {
        color: string;
      };
    };
    footer: ColorBgType & {
      online: {
        background: string;
      };
    };
    badge: ColorBgType;
  };
};

type ColorBgType = {
  color: string;
  background: string;
};

const lightVariant: VariantType = {
  palette: lightPalette,
  header: {
    color: grey[500],
    background: '#FFF',
    search: {
      color: grey[800],
    },
    indicator: {
      background: customBlue[600],
    },
  },
  footer: {
    color: grey[500],
    background: '#FFF',
  },
  sidebar: {
    color: grey[200],
    background: '#233044',
    header: {
      color: grey[200],
      background: '#233044',
      brand: {
        color: customBlue[500],
      },
    },
    footer: {
      color: grey[200],
      background: '#1E2A38',
      online: {
        background: green[500],
      },
    },
    badge: {
      color: '#FFF',
      background: customBlue[500],
    },
  },
};

const darkVariant: VariantType = {
  palette: darkPalette,
};

const variants: Record<ThemeVariant, VariantType> = {
  default: lightVariant,
  light: lightVariant,
  dark: darkVariant,
};

export default variants;
