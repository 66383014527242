export const concatenateName = (firstName?: string, middleName?: string, lastName?: string) =>
  [firstName, middleName, lastName].filter((item) => item).join(' ');

/**
 * @deprecated Use `getFullName` from `@vertice/core/src/utils/formatting/user.ts`
 */
export const getFullName = (user?: { firstName?: string; middleName?: string; lastName?: string }): string => {
  if (!user) {
    return '';
  }
  const { firstName, middleName, lastName } = user;
  return concatenateName(firstName, middleName, lastName);
};
