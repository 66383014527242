import React, { useCallback, useId, useMemo, useState } from 'react';
import Highcharts, { Chart as HighchartsChart } from 'highcharts';
import injectHighchartsTrendline from 'highcharts/indicators/trendline';
import injectHighchartsIndicators from 'highcharts/indicators/indicators';
import injectHighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import { Stack, useTheme } from '@mui/material';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { millisecondsInHour } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { rgba } from 'polished';
import { ForecastData } from './useForecastGraphData';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/theme';
import {
  dayInMonthFormatter,
  yLabelCurrencyFormatter,
} from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';
import LegendControlsBox from '@vertice/core/src/components/charts/components/Legend/LegendControlsBox';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import TooltipValueWithTimeInfo from '@vertice/core/src/components/charts/components/Tooltip/TooltipValueWithTimeInfo';
import extractLegendItemsFromSeries from '@vertice/core/src/components/charts/highcharts-specific/utils/extractLegendItemsFromSeries';
import { useCustomCrosshair } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useCustomCrosshair';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import { useFormatCurrency } from '@vertice/core/src/utils/formatting/currency';
import { AWS_BRAND_COLOR, AWS_DEFAULT_CURRENCY_DECIMAL_PLACES } from '../../constants';
import ToggleSwitch from '@verticeone/design-system/src/components/ToggleSwitch';
import Text from '@verticeone/design-system/src/components/Text';

injectHighchartsMore(Highcharts);
injectHighchartsIndicators(Highcharts);
injectHighchartsTrendline(Highcharts);

type ForecastGraphProps = {
  data: ForecastData;
};

const SERIES_ID_SPEND = 'spend';
const SERIES_ID_TREND_SOURCE = 'trend-source';
const SERIES_ID_TREND = 'trend';

const CHART_HEIGHT = 455;
const MAX_POINT_TOP_PADDING_PX = 50;

const ForecastGraph = ({
  data: { pastData, forecastBoundValues, forecastMeanValues, currency },
}: ForecastGraphProps) => {
  const { palette } = useTheme();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { locale } = useLocaleContext();
  const formatCurrency = useFormatCurrency();
  const tooltipXAxisValueFormatter = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  const confidenceIntervalSwitchId = useId();
  const [confidenceIntervalVisible, setConfidenceIntervalVisible] = useState(false);

  const [chart, setChart] = useState<HighchartsChart | null>(null);
  const saveChartRef = useCallback((ch: HighchartsChart) => setChart(ch), []);
  const applyCustomCrosshair = useCustomCrosshair(palette.core.color6, 1);
  const lastKnownData = useMemo(() => (pastData.length ? pastData[pastData.length - 1] : null), [pastData]);

  const optionOverrides = useMemo(() => {
    // In order to join the series, we add an extra point to the forecast series which is equal to the last point from real data
    const connectedForecastMeanValues = lastKnownData
      ? [lastKnownData.slice(), ...forecastMeanValues]
      : forecastMeanValues;
    const connectedForecastBoundValues = lastKnownData
      ? [[lastKnownData[0], lastKnownData[1], lastKnownData[1]], ...forecastBoundValues]
      : forecastBoundValues;

    return buildOptions([
      applyCustomCrosshair,
      (opts) =>
        mergeOptions(
          {
            chart: {
              height: CHART_HEIGHT,
              spacing: [0, 0, 0, 0],
              marginTop: 0,
            },
            plotOptions: {
              series: {
                states: {
                  inactive: { opacity: 1 },
                  hover: {
                    enabled: true,
                    halo: {
                      size: 0,
                    },
                  },
                },
                marker: { enabled: false },
                lineWidth: 1,
              },
            },
            yAxis: {
              labels: {
                formatter: yLabelCurrencyFormatter(palette, locale, currency),
              },
              showLastLabel: false,
              maxPadding: MAX_POINT_TOP_PADDING_PX / CHART_HEIGHT,
            },
            xAxis: {
              type: 'datetime',
              labels: { formatter: dayInMonthFormatter },
              minTickInterval: 7 * 24 * millisecondsInHour,
              showFirstLabel: false,
              plotBands: connectedForecastMeanValues.length
                ? [
                    {
                      // forecast background
                      color: rgba(palette.visualization.divergent.secondary['+10'], 0.5),
                      from: connectedForecastMeanValues[0][0],
                      to: connectedForecastMeanValues[connectedForecastMeanValues.length - 1][0],
                    },
                  ]
                : undefined,
            },
            tooltip: { shared: true },
            series: [
              {
                id: SERIES_ID_SPEND,
                name: t('FORECAST.SPEND_AXIS_LABEL'),
                type: 'area',
                color: palette.visualization.divergent.primary['-50'],
                fillColor: {
                  linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1,
                  },
                  stops: [
                    [0, rgba(palette.visualization.divergent.primary['-40'], 0.2)],
                    [1, rgba(palette.visualization.divergent.primary['-40'], 0)],
                  ],
                },
                data: pastData,
              },
              {
                id: SERIES_ID_TREND_SOURCE,
                type: 'line',
                data: [...pastData, ...forecastMeanValues],
                visible: false,
              },
              {
                id: SERIES_ID_TREND,
                name: t('FORECAST.TREND_LINE'),
                type: 'trendline',
                linkedTo: SERIES_ID_TREND_SOURCE,
                color: palette.core.color6,
                lineWidth: 2,
                dashStyle: 'ShortDash',
                visible: true,
                enableMouseTracking: false,
                zIndex: 3,
              },
              {
                name: t('FORECAST.FORECAST_SPEND_AXIS_LABEL'),
                type: 'line',
                color: palette.visualization.divergent.secondary['+50'],
                data: connectedForecastMeanValues,
                zIndex: 2,
              },
              {
                name: t('FORECAST.FORECAST_SPREAD_AXIS_LABEL'),
                type: 'arearange',
                visible: confidenceIntervalVisible,
                // Opacity used for drawing legend & tooltip color squares
                color: rgba(palette.visualization.divergent.secondary['+50'], 0.4),
                lineWidth: 0,
                // Opacity used for drawing the actual data
                fillOpacity: 0.2,
                zIndex: 1,
                enableMouseTracking: false,
                data: connectedForecastBoundValues,
              },
            ],
          },
          opts
        ),
    ]);
  }, [
    applyCustomCrosshair,
    currency,
    forecastBoundValues,
    forecastMeanValues,
    lastKnownData,
    locale,
    palette,
    pastData,
    t,
    confidenceIntervalVisible,
  ]);
  const options = useStyledHighcharts(optionOverrides);

  return (
    <Stack position="relative">
      <Stack
        direction="row"
        position="absolute"
        width="auto"
        height="auto"
        top="22px"
        right="24px"
        zIndex={1}
        spacing={4}
      >
        <Legend
          items={extractLegendItemsFromSeries(options)
            .filter((item) => item.id !== SERIES_ID_TREND_SOURCE)
            .map((legendItem) => {
              if (legendItem.id === SERIES_ID_TREND) {
                return { ...legendItem, outlined: true };
              }
              return legendItem;
            })}
        />
        <LegendControlsBox>
          <Stack direction="row" spacing="7px" alignItems="center">
            <ToggleSwitch
              checked={confidenceIntervalVisible}
              size="S"
              color={AWS_BRAND_COLOR}
              onChange={(e, checked) => {
                setConfidenceIntervalVisible(checked);
              }}
              id={confidenceIntervalSwitchId}
            />
            <label htmlFor={confidenceIntervalSwitchId}>
              <Text variant="body-regular" size="S">
                {t('FORECAST.CONFIDENCE_INTERVAL_VISIBLE')}
              </Text>
            </label>
          </Stack>
        </LegendControlsBox>
      </Stack>
      <HighchartsReact highcharts={Highcharts} options={options} callback={saveChartRef} />
      <HighchartTooltip chart={chart}>
        {({ points }) => (
          <TooltipWrapper>
            {points
              // Show tooltip only for spend data and for forecast unless it's the point they have in common
              ?.filter((point) => {
                const { type } = point.series;
                return type === 'area' || (type === 'line' && (!lastKnownData || point.x !== lastKnownData[0]));
              })
              .map((point, pointIndex) => {
                const formattedValue = formatCurrency(point.y!, {
                  currency,
                  maximumFractionDigits: AWS_DEFAULT_CURRENCY_DECIMAL_PLACES,
                });
                const valueSuffix =
                  point.series.type === 'area' ? t('FORECAST.TOOLTIP.SPEND') : t('FORECAST.TOOLTIP.FORECAST');
                return (
                  <TooltipValueWithTimeInfo
                    value={formattedValue}
                    valueSuffix={valueSuffix}
                    timeInfo={pointIndex === 0 ? tooltipXAxisValueFormatter.format(new Date(point.x!)) : undefined}
                    key={point.series.name}
                  />
                );
              })}
          </TooltipWrapper>
        )}
      </HighchartTooltip>
    </Stack>
  );
};

export default ForecastGraph;
