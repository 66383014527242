import React, { FC, useEffect } from 'react';
import { GridSortModel } from '@mui/x-data-grid-pro';
import DataGrid from '@verticeone/design-system/src/components/DataGrid';
import { DataGridColDef } from '@verticeone/design-system/src/components/DataGrid';
import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { styled } from '@mui/material';
import { Link } from 'react-router-dom';
import NoRows from './NoRows';

type ContractsTableProps = {
  rows: ViewOnContract[];
  isLoading: boolean;
  columns: DataGridColDef<ViewOnContract>[];
  defaultSortModel?: GridSortModel;
  getContractPath: (contractId: string) => string;
  pageSize?: number;
} & Pick<React.ComponentProps<typeof DataGrid>, 'noBorder' | 'noBorderRadius'>;

const ColumnLink = styled(Link)(() => {
  return {
    display: 'block',
    width: 'calc(100% + 32px)',
    textDecoration: 'unset',
    color: 'unset',
    marginLeft: -16,
    padding: 16,
    marginRight: -16,
  };
});

export const ContractsTable: FC<ContractsTableProps> = ({
  defaultSortModel,
  pageSize = 50,
  columns,
  getContractPath,
  isLoading,
  ...props
}) => {
  const clickableColumns = columns.map<DataGridColDef<ViewOnContract>>(({ renderCell, ...rest }) => {
    return {
      ...rest,
      renderCell: (params) => {
        return (
          <ColumnLink to={getContractPath(params.row.contract.record.contractId)}>{renderCell?.(params)}</ColumnLink>
        );
      },
    };
  });

  const [sortModel, setSortModel] = React.useState<GridSortModel | undefined>(defaultSortModel);

  useEffect(() => {
    if (!sortModel) setSortModel(defaultSortModel);
  }, [defaultSortModel, sortModel]);

  return (
    <DataGrid<ViewOnContract>
      {...props}
      columns={clickableColumns}
      getRowId={(row) => row.contract.record.contractId}
      sortingMode="client"
      disableRowSelectionOnClick
      getRowHeight={() => 60}
      noRowsOverlayHeight={200}
      sortingOrder={['asc', 'desc']}
      autoHeight
      rowSelection={false}
      loading={isLoading}
      loadingStyle="skeleton"
      slots={{
        noRowsOverlay: () => <NoRows />,
      }}
      pagination
      sortModel={sortModel}
      onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
      initialState={{
        pagination: { paginationModel: { pageSize } },
      }}
    />
  );
};
