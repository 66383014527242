import React, { useMemo } from 'react';
import FormDateField from '@vertice/core/src/modules/forms/fields/FormDateField';
import FormSelectField from '@vertice/core/src/modules/forms/fields/FormSelectField';
import { FormControl, Grid, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useFormContext } from 'react-hook-form';
import TextFieldCaption from '@verticeone/design-system/src/components/TextFieldCaption';
import TextField from '@verticeone/design-system/src/components/TextField/TextField';
import { AddExistingContractFormData, AddExistingContractFormEntry } from '../../types';
import { BILLING_FREQUENCIES, ROLLING_FREQUENCIES } from './constants';
import { useFormatDate } from '@vertice/core/src/utils/formatting/date';

export const RollingContractFields = () => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const { watch } = useFormContext<AddExistingContractFormData>();

  const startDate = watch('startDate');
  const rollingFrequency = watch('rollingFrequency');

  // calculate next roll date based on start date and rolling frequency
  const nextRollDate = useMemo(() => {
    if (startDate && rollingFrequency) {
      const rollingFrequencyIndex = ROLLING_FREQUENCIES.indexOf(rollingFrequency);
      if (rollingFrequencyIndex >= 0) {
        const rollingMonths = [1, 3, 6, 12];
        return formatDate(dayjs(startDate).add(rollingMonths[rollingFrequencyIndex], 'month').toISOString());
      }
      return '';
    }
    return '';
  }, [formatDate, startDate, rollingFrequency]);

  return (
    <>
      <AddExistingContractFormEntry
        name="startDate"
        label={t('ENTITIES.CONTRACT.LABELS.START_DATE')}
        component={FormDateField}
        required
        width={6}
      />
      <AddExistingContractFormEntry
        name="signDate"
        label={t('ENTITIES.CONTRACT.LABELS.SIGN_DATE')}
        component={FormDateField}
        width={6}
      />
      <AddExistingContractFormEntry
        name="rollingFrequency"
        label={t('ENTITIES.CONTRACT.LABELS.ROLLING_FREQUENCY')}
        component={FormSelectField}
      >
        {ROLLING_FREQUENCIES.map((freq) => (
          <MenuItem key={freq} value={freq}>
            {t(`ENTITIES.CONTRACT.LABELS.FREQUENCY_${freq}`)}
          </MenuItem>
        ))}
      </AddExistingContractFormEntry>
      <AddExistingContractFormEntry
        name="billingFrequency"
        label={t('ENTITIES.CONTRACT.LABELS.BILLING_FREQUENCY')}
        component={FormSelectField}
      >
        {BILLING_FREQUENCIES.map((freq) => (
          <MenuItem key={freq} value={freq}>
            {t(`ENTITIES.CONTRACT.LABELS.FREQUENCY_${freq}`)}
          </MenuItem>
        ))}
      </AddExistingContractFormEntry>
      {nextRollDate && (
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth>
            <TextFieldCaption htmlFor="nextRollDate" label={t('ENTITIES.CONTRACT.LABELS.NEXT_ROLL_DATE')} size="XS" />
            <TextField
              name="nextRollDate"
              id="nextRollDate"
              disabled
              value={nextRollDate}
              variant="solid"
              hiddenLabel
            />
          </FormControl>
        </Grid>
      )}
    </>
  );
};
