import { useCloudAccounts } from '../CloudAccountsContext';
import React from 'react';
import CloudAccountServicesStats from './CloudAccountServicesStats';
import CloudAccountServicesUsageStats from './CloudAccountServicesUsageStats';
import CloudAccountsStats from './CloudAccountsStats';

const StatsByLastNode = () => {
  const { node, getLastNode } = useCloudAccounts();
  const lastNode = getLastNode();

  switch (lastNode.type) {
    case 'account':
      return <CloudAccountServicesStats linkedAccountId={lastNode.id} />;
    case 'service':
      return <CloudAccountServicesUsageStats linkedAccountId={node?.children?.id || ''} productCode={lastNode.id} />;
    default:
      return <CloudAccountsStats />;
  }
};

export default StatsByLastNode;
