import React, { useMemo } from 'react';
import { ContractContextDataRequireFetched, FetchedContract } from '@vertice/core/src/modules/saas/contract/types';
import Card, { CardHeader, CardHeaderActions, CardHeaderTitle } from '@verticeone/design-system/src/components/Card';
import { useTranslation } from 'react-i18next';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import Button from '@verticeone/design-system/src/components/Button';
import Text from '@verticeone/design-system/src/components/Text';
import { useFormatDate } from '@vertice/core/src/utils/formatting/date';

const ContractRenewedOutsideCardContent = ({ fetchedContract }: { fetchedContract: FetchedContract }) => {
  const { t } = useTranslation();
  const routes = useRoutes();
  const formatDate = useFormatDate();
  const { generatePath } = useRouteNavigate();

  const nextContract = fetchedContract.contract.materialized?.next;
  const completedDate = nextContract?.signingDate;

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle color="primary2" text={t('SAAS.CONTRACT_RENEWED_OUTSIDE_CARD.TITLE')} />
        <CardHeaderActions>
          <Stack gap={0.5}>
            <Text variant="label" color="primary2" size="XS">
              {t(`ENTITIES.CONTRACT.STATUSES.COMPLETED`)}
            </Text>
            {completedDate ? (
              <Text variant="body-regular" color="primary2" size="M">
                {formatDate(completedDate)}
              </Text>
            ) : (
              <Text variant="body-regular" size="M" color="inactive2">
                —
              </Text>
            )}
          </Stack>
        </CardHeaderActions>
      </CardHeader>
      <Stack p={4.5} spacing={4} alignItems="start">
        <Button
          variant="plain"
          color="neutral"
          fullWidth
          component={RouterLink}
          to={generatePath(routes.CONTRACTS.DETAIL.ABSOLUTE_PATH, {
            contractId: nextContract?.contractId ?? 'missing-next-contract-id',
          })}
        >
          {t('ENTITIES.CONTRACT.ACTIONS.VIEW_NEW_CONTRACT')}
        </Button>
      </Stack>
    </Card>
  );
};

export const shouldCardBeVisible = ({ materialized }: Contract, isEditing: boolean): boolean =>
  !isEditing && Boolean(materialized?.next && materialized.next.contractOrigin === 'RENEWAL_WITHOUT_VERTICE');

export const useContractRenewedOutsideCard = ({
  fetchedContract,
  editMode: { isEditing },
}: ContractContextDataRequireFetched) =>
  useMemo(
    () =>
      shouldCardBeVisible(fetchedContract.contract, isEditing)
        ? () => <ContractRenewedOutsideCardContent fetchedContract={fetchedContract} />
        : null,
    [fetchedContract, isEditing]
  );
