import { z } from 'zod';
import { zodSchema as purchaseIntakeSimpleFormSchema } from '../PurchaseIntakeSimpleForm/schema';
import { RECURRENCE_OPTIONS } from '../shared/formFields/RecurrenceFormEntry';

export const zodSchema = z
  .object({
    ...purchaseIntakeSimpleFormSchema.shape,

    recurrence: z.enum(RECURRENCE_OPTIONS),
    contractCurrency: z.string(),

    startDate: z.string(),
    endDate: z.string().optional().nullish(),
    vendorProposedCost: z.number().optional().nullish(),
    negotiatedCost: z.number(),
  })
  .refine((data) => {
    // make end date required if recurrence is fixed-term
    if (data.recurrence === 'fixed-term') {
      return !!data.endDate;
    }
    return true;
  });

export type FormData = z.infer<typeof zodSchema>;
