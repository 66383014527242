import React from 'react';
import { useCloudAccounts } from '../CloudAccountsContext';
import BaseCloudAccountsStats from './BaseCloudAccountsStats';
import useAccountServicesData from '../Table/useAccountServicesData';

type CloudAccountServicesStatsProps = {
  linkedAccountId: string;
};

const CloudAccountServicesStats = ({ linkedAccountId }: CloudAccountServicesStatsProps) => {
  const { period } = useCloudAccounts();
  const { data, isLoading } = useAccountServicesData({
    linkedAccountId,
    ...period,
  });
  return <BaseCloudAccountsStats data={data?.stats} isLoading={isLoading} />;
};

export default CloudAccountServicesStats;
