import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import format from 'date-fns/format';
import { addMonths, startOfMonth } from 'date-fns';
import {
  ForecastPrecisionType,
  TimeSeriesDataPoint,
  useUsageCostPerRecordTypeQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useMemo } from 'react';
import useDeferredQuery from '@vertice/core/src/utils/api/useDeferredQuery';
import {
  DATE_FORMAT,
  findFirstCurrency,
  sumPositiveDataPointValues,
  sumTimeSeriesValues,
} from '../utils/graphDataUtils';
import LoadableAdvanced from '@vertice/utils/src/loadableAdvanced';
import { sumBy } from 'lodash';
import { AWS_DEFAULT_CURRENCY } from '../constants';

// We want to calculate the annual spend for completed months only
// On any day of October 2023 the time range would be October 2022 - September 2023 (included)
const startOfCurrentMonth = startOfMonth(new Date());
const startDate = addMonths(startOfCurrentMonth, -12);

export type AnnualSpendData = {
  currency: string;
  annualGrossSpend: number;
  annualInvoicedSpend: number;
};

export const prepareAnnualSpendData = (dataPointsRaw: TimeSeriesDataPoint[]): AnnualSpendData => ({
  annualGrossSpend: sumBy(dataPointsRaw, (p) => sumPositiveDataPointValues(p)),
  annualInvoicedSpend: sumBy(dataPointsRaw, ({ values }) => Math.max(0, sumTimeSeriesValues(values))),
  currency: findFirstCurrency(dataPointsRaw) || AWS_DEFAULT_CURRENCY,
});

const useAnnualSpendData = (): LoadableAdvanced<AnnualSpendData> => {
  const { accountId } = useSelector(getAccount);
  const { data: dataPointsRaw, error } = useDeferredQuery(
    useUsageCostPerRecordTypeQuery,
    {
      accountId: accountId!,
      // Acceptable timezone inaccuracy: We're sending local timezone date to UTC endpoint
      startDate: format(startDate, DATE_FORMAT),
      endDate: format(startOfCurrentMonth, DATE_FORMAT),
      precision: ForecastPrecisionType.Month,
    },
    { skip: !accountId, pollingInterval: 5000 },
    ({ costUsageQuery }) => costUsageQuery
  );

  const computed = useMemo(() => {
    if (!dataPointsRaw) return undefined;
    return prepareAnnualSpendData(dataPointsRaw);
  }, [dataPointsRaw]);

  return {
    error: error,
    isEmpty: Boolean(dataPointsRaw && dataPointsRaw.length === 0),
    isLoading: !computed,
    data: computed,
  };
};

export default useAnnualSpendData;
