import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CardGridColDef } from '../../task/TaskList/CardGrid/types';
import { TaskRow } from '../../task/types';
import { TaskName } from '../../task/TaskList/taskListColumns/TaskName';
import { TaskAssignees } from '../../task/TaskList/taskListColumns/TaskAssignees';
import { TaskCreatedAt } from '../../task/TaskList/taskListColumns/TaskCreatedAt';

export const useRequestTaskColumns = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    const columns: CardGridColDef<TaskRow>[] = [
      {
        field: 'name',
        headerName: t('INTELLIGENT_WORKFLOWS.TASKS_LIST.TASK_NAME_COLUMN'),
        flex: 1.5,
        renderCell: ({ row }) => <TaskName taskRow={row} />,
      },
      {
        field: 'assignees',
        headerName: t('INTELLIGENT_WORKFLOWS.TASKS_LIST.ASSIGNEE_COLUMN'),
        renderCell: ({ row }) => <TaskAssignees taskRow={row} />,
        sortable: false,
        flex: 1,
      },
      {
        field: 'createdAt',
        headerName: t('INTELLIGENT_WORKFLOWS.TASKS_LIST.DAYS_ACTIVE_COLUMN'),
        renderCell: ({ row }) => <TaskCreatedAt short={false} taskRow={row} />,
        flex: 1,
      },
    ];

    return columns;
  }, [t]);
};
