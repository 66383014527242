import { useTranslation } from 'react-i18next';
import { getOrdinalPhase } from './phaseUtils';
import Stack from '@mui/material/Stack';
import StatusBar from './StatusBar';
import { StackProps } from '@mui/material';
import styled from '@mui/material/styles/styled';
import Text from '@verticeone/design-system/src/components/Text';

type WorkflowStageIndicatorProps = StackProps & {
  stage?: string;
};

const StyledCardHeader = styled(Text)(({ theme: { palette } }) => ({
  color: palette.text.color1,
  paddingTop: 1,
}));

const WorkflowStageIndicator = ({ stage, ...otherProps }: WorkflowStageIndicatorProps) => {
  const { t } = useTranslation();
  const phaseProps = getOrdinalPhase(stage);
  return (
    phaseProps.phase && (
      <Stack {...otherProps}>
        <StatusBar numberOfElements={phaseProps.outOf} breakIndex={phaseProps.phase} />
        <StyledCardHeader variant="body-regular" size="S" whiteSpace="nowrap">
          {t(`ENTITIES.CONTRACT.WORKFLOW_STAGES.${stage}`)}
        </StyledCardHeader>
      </Stack>
    )
  );
};

export default WorkflowStageIndicator;
