import React from 'react';
import { styled } from '@mui/material/styles';
import type { StyledComponent } from 'styled-components';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useMainLayout } from '@vertice/dashboard/src/layouts/Main/LayoutWrapper';
import IconButton, { IconButtonProps } from '@verticeone/design-system/src/components/IconButton';

const BaseCollapseButton = (props: Partial<IconButtonProps>) => {
  const { isOpen, toggle } = useMainLayout();

  return <IconButton {...props} size="S" variant="ghost" onClick={toggle} icon={isOpen ? ChevronLeft : ChevronRight} />;
};

const CollapseButton = styled(BaseCollapseButton)({
  position: 'absolute',
  right: '-14px',
  bottom: '30px',
  zIndex: '1201',
  transition: 'left 300ms ease-in-out, right 300ms ease-in-out',
  '&:hover': {
    position: 'absolute',
  },
}) as StyledComponent<typeof BaseCollapseButton, Partial<IconButtonProps>>;

export default CollapseButton;
