import {
  EntraSourceIcon,
  GoogleSourceIcon,
  JumpCloudSourceIcon,
  OktaSourceIcon,
  OneLoginSourceIcon,
} from '@vertice/assets';

import { chain } from 'lodash';

import { Stack, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ApplicationWithMeasureReportsListItem } from '@vertice/slices/src/graphql/bffeUsage/derivedTypes';

type DiscoverySourcesCellProps = {
  sources: ApplicationWithMeasureReportsListItem['sources'];
  size?: number;
};

type IconWithTooltipProps = {
  icon: JSX.Element;
  tooltip: string;
  size?: number;
};

const IconWithTooltip = ({ icon, tooltip }: IconWithTooltipProps) => (
  <Tooltip disableFocusListener disableTouchListener title={tooltip}>
    <span>{icon}</span>
  </Tooltip>
);

/*
provider IDs:
  Provider:Google:Workspace:SocialLogin
  Provider:Google:Workspace:SAML
  Provider:Azure
  Provider:JumpCloud
  Provider:Okta
  Provider:OneLogin
  Provider:Simulator
*/

const DiscoverySourcesCell = ({ sources, size }: DiscoverySourcesCellProps) => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" spacing={-3}>
      {chain(sources || [])
        .map((item) => item.id)
        .sort()
        .uniq()
        .map((source) => {
          switch (source) {
            case 'Provider:Google:Workspace:SocialLogin':
              return (
                <IconWithTooltip
                  icon={<GoogleSourceIcon width={size || 32} height={size || 32} />}
                  tooltip={t('APPLICATIONS.TOOLTIPS.SOURCE.GOOGLE_SOCIAL')}
                  key={source}
                />
              );
            case 'Provider:Google:Workspace:SAML':
              return (
                <IconWithTooltip
                  icon={<GoogleSourceIcon width={size || 32} height={size || 32} />}
                  tooltip={t('APPLICATIONS.TOOLTIPS.SOURCE.GOOGLE_SSO')}
                  key={source}
                />
              );
            case 'Provider:Azure':
              return (
                <IconWithTooltip
                  icon={<EntraSourceIcon width={size || 32} height={size || 32} />}
                  tooltip={t('APPLICATIONS.TOOLTIPS.SOURCE.ENTRA')}
                  key={source}
                />
              );
            case 'Provider:JumpCloud':
              return (
                <IconWithTooltip
                  icon={<JumpCloudSourceIcon width={size || 32} height={size || 32} />}
                  tooltip={t('APPLICATIONS.TOOLTIPS.SOURCE.JUMPCLOUD')}
                  key={source}
                />
              );
            case 'Provider:Okta':
              return (
                <IconWithTooltip
                  icon={<OktaSourceIcon width={size || 32} height={size || 32} />}
                  tooltip={t('APPLICATIONS.TOOLTIPS.SOURCE.OKTA')}
                  key={source}
                />
              );
            case 'Provider:OneLogin':
              return (
                <IconWithTooltip
                  icon={<OneLoginSourceIcon width={size || 32} height={size || 32} />}
                  tooltip={t('APPLICATIONS.TOOLTIPS.SOURCE.ONELOGIN')}
                  key={source}
                />
              );
            case 'Provider:Simulator': // demo only
              return (
                <IconWithTooltip
                  icon={<OktaSourceIcon width={size || 32} height={size || 32} />}
                  tooltip={t('APPLICATIONS.TOOLTIPS.SOURCE.OKTA')}
                  key={source}
                />
              );
            default:
              return null;
          }
        })
        .value()}
    </Stack>
  );
};

export default DiscoverySourcesCell;
