import React, { Dispatch, FC, SetStateAction } from 'react';
import { createRequiredContext } from '@verticeone/design-system/src/utils/contexts';
import useDropdownValueListData from './useDropdownValueListData';
import { CustomContractFieldDropdownOption } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

type DropdownValueListContextProviderProps = {
  children: string | JSX.Element | JSX.Element[];
  selectedValues: CustomContractFieldDropdownOption[];
  setSelectedValues: Dispatch<SetStateAction<CustomContractFieldDropdownOption[]>>;
  setError: Dispatch<SetStateAction<string | undefined>>;
};

type DropdownValueListContextType = {
  values: CustomContractFieldDropdownOption[];
  addValue: (newField: CustomContractFieldDropdownOption) => void;
  updateValue: (updatedField: CustomContractFieldDropdownOption) => void;
  reorderValues: (oldIndex: number, targetIndex: number) => void;
  setError: Dispatch<SetStateAction<string | undefined>>;
};

const { DropdownValueListContextProvider: RawDropdownValueListContextProvider, useDropdownValueListContext } =
  createRequiredContext<DropdownValueListContextType, 'DropdownValueList'>('DropdownValueList');

export { useDropdownValueListContext };

const DropdownValueListContextProvider: FC<DropdownValueListContextProviderProps> = ({
  selectedValues,
  setSelectedValues,
  setError,
  children,
}) => {
  const { addValue, updateValue, reorderValues } = useDropdownValueListData({ selectedValues, setSelectedValues });

  const context: DropdownValueListContextType = {
    values: selectedValues,
    addValue,
    updateValue,
    reorderValues,
    setError,
  };

  return <RawDropdownValueListContextProvider value={context}>{children}</RawDropdownValueListContextProvider>;
};

export default DropdownValueListContextProvider;
