import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import wildcardMatch from 'wildcard-match';

import { useAuthentication } from '../authentication/AuthenticationContext';
import { authorizerReducer, authRequestDataToItem, ReducerItem, AuthRequestProps, AuthResponseProps } from './reducer';

type UseAuthorizerProps = {
  <ID extends string>(item: AuthRequestProps<ID>): ReducerItem<ID>;
  <ID extends string>(item: Array<AuthRequestProps<ID>>): Array<ReducerItem<ID>>;
};

type AuthorizerContextProps = {
  authorize: (authData: AuthRequestProps | Array<AuthRequestProps>) => void;
  authorizer: Array<ReducerItem<string>>;
  compareAuthItems: (item: AuthRequestProps, item2: AuthRequestProps) => boolean;
};

type AuthorizerContextProviderProps = {
  baseUrl: string;
  children: React.ReactNode;
}

const AuthorizerContext = createContext<AuthorizerContextProps>({} as AuthorizerContextProps);

const AuthorizerContextProvider = ({ baseUrl, children }: AuthorizerContextProviderProps) => {
  const { getJwtToken } = useAuthentication();
  const queryClient = useQueryClient();
  const { user } = useAuthentication();
  const [authorizer, dispatch] = useReducer(authorizerReducer, []);
  const accountId = useMemo(() => user?.accountId, [user]);

  const compareAuthItems = useCallback((item: AuthRequestProps, item2: AuthRequestProps) => {
    return item.object === item2.object && item.action === item2.action;
  }, []);

  const hasPermission = useCallback((item: AuthRequestProps): boolean => {
    const isMatch = wildcardMatch(item.object.replace(/\/\*$/, '/**'));

    return authorizer
      .filter(({ metadata }) => item.action === metadata.action && isMatch(metadata.object))
      .some(({ isAllowed }) => isAllowed);
  }, [authorizer]);

  const isInCache = useCallback((item: AuthRequestProps): boolean => {
    return !!authorizer.find(({ metadata }) => compareAuthItems(metadata, item));
  }, [authorizer, compareAuthItems]);

  const authorize = useCallback((authData: AuthRequestProps | Array<AuthRequestProps>) => {
    const dataToCreate = [authData]
      .flat()
      .filter(item => !isInCache(item))
      .filter(item => !hasPermission(item));

    if (dataToCreate.length) {
      dispatch({ type: 'ADD_POLICIES', payload: dataToCreate });

      void queryClient
        .fetchQuery({
          queryKey: ['authorizer'],
          queryFn: async () => fetch(`${baseUrl}/accounts/${accountId}/auth/batch`, {
            headers: {
              Authorization: `Bearer ${await getJwtToken()}`,
              'content-type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
              requests: [dataToCreate].flat().map((item) => ({
                id: item.id,
                obj: item.object,
                act: item.action,
              })),
            }),
          }),
        })
        .then(resp => resp.clone().json() as Promise<{ responses: Array<AuthResponseProps> }>)
        .then(resp => dispatch({ type: 'VALIDATE_POLICIES', payload: resp.responses }));
    }
  }, [isInCache, hasPermission, queryClient, baseUrl, accountId, getJwtToken]);

  return <AuthorizerContext.Provider
    value={{ authorizer, authorize, compareAuthItems }}>{children}</AuthorizerContext.Provider>;
};

export const useAuthorizer: UseAuthorizerProps = (data) => {
  const { authorize, authorizer, compareAuthItems } = useContext(AuthorizerContext);

  useEffect(() => {
    authorize(data);
  }, [authorize, data]);

  const dataToDisplay = useMemo(() => [
    ...[data]
      .flat()
      .filter((item) => !authorizer.some(({ metadata }) => compareAuthItems(item, metadata)))
      .map(authRequestDataToItem),
    ...authorizer
      .filter(({ metadata }) => [data].flat().some(item => compareAuthItems(item, metadata))),
  ], [authorizer, compareAuthItems, data]);

  return useMemo(() => {
    return Array.isArray(data)
      ? dataToDisplay as any
      : dataToDisplay.find(item => item.metadata.id === data.id) as any;
  }, [data, dataToDisplay]);
};

export default AuthorizerContextProvider;
