import { WorkflowNode } from '../../model/types';
import assertExhausted from '@vertice/core/src/utils/assertExhausted';

/*
 Node dimensions used for the auto-layouting,
 They don't need to match the real dimensions of the node components
 */
export const getNodeDimensions = (kind: WorkflowNode['kind']): { width: number; height: number } => {
  switch (kind) {
    case 'start':
    case 'end':
      return { width: 80, height: 24 };
    case 'task':
      return { width: 256, height: 100 };
    case 'gateway':
      return { width: 100, height: 34 };
    default:
      assertExhausted(kind);
      throw new Error('Unknown node kind');
  }
};
