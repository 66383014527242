import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useQuery } from '@tanstack/react-query';
import { fetchCloudOptimization } from '../../CloudClientProvider';
import { getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';
import type { TableType } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import dayjs from 'dayjs';

const EDPCumulativeSpendQuery = graphql(`
  query EDPCumulativeSpend($accountId: String!) {
    athenaViewQuery(params: { accountId: $accountId, name: "cco_view_cum_sum_spend_v1", parameters: ["{accountId}"] }) {
      ... on DataTableResult {
        __typename
        table(
          columns: [
            "edp_month_start"
            "spend_current_month"
            "forecasted_spend"
            "cumulative_month_spend"
            "regular_target_commitment"
            "cumulative_forecasted_target_spend"
            "is_forecast"
          ]
        ) {
          columns
          data
          dataTypes
        }
      }
      ... on DeferredQueryResult {
        __typename
      }
      ... on ErroredQueryResult {
        __typename
        error
      }
    }
  }
`);

type DataColumns =
  | 'edp_month_start'
  | 'spend_current_month'
  | 'forecasted_spend'
  | 'cumulative_month_spend'
  | 'regular_target_commitment'
  | 'cumulative_forecasted_target_spend'
  | 'is_forecast';

export type CumulativeSumChartData = {
  months: string[];
  commitment: number;
  totalSpend: number;
  values: { id: string; data: Array<number | null>; type: string; lineWidth?: number; dashStyle?: string }[];
  usedCategories: string[];
};

export const useCumulativeSumChartData = () => {
  const { accountId } = useAccountContext();

  return useQuery({
    queryKey: ['EDPCumulativeSpend'],
    queryFn: () =>
      fetchCloudOptimization(EDPCumulativeSpendQuery, {
        accountId,
      }),
    enabled: !!accountId,
    refetchInterval: (data) => {
      return data.state.data?.athenaViewQuery?.__typename === 'DeferredQueryResult' ? 2000 : false;
    },
    select: (data) => {
      if (data.athenaViewQuery?.__typename === 'DataTableResult') {
        const result = (getTableData(data.athenaViewQuery.table as TableType) as Record<DataColumns, number>[]) ?? null;

        const filteredResult = result.filter((item) => !item.is_forecast);

        return {
          months: result.map((item) => dayjs(item.edp_month_start).format()),
          commitment: result
            .map((item, index) => item.regular_target_commitment + item.cumulative_forecasted_target_spend)
            .slice(-1)[0],
          totalSpend: filteredResult
            .map((item) => item.regular_target_commitment + item.spend_current_month)
            .slice(-1)[0],
          values: [
            {
              id: 'actualSpend',
              data: result.map((item) => (item.is_forecast ? 0 : item.spend_current_month)),
              type: 'column',
            },
            {
              id: 'cumulativeSpendBefore',
              data: result.map((item) => (item.is_forecast ? 0 : item.cumulative_month_spend)),
              type: 'column',
            },
          ],
          usedCategories: ['cumulativeSpendBefore', 'actualSpend'],
        };
      }

      return null;
    },
  });
};
