import { useCallback } from 'react';
import { useTheme } from '@mui/material';
import type { Options, XAxisLabelsOptions } from 'highcharts';
import { mergeOptions } from '../utils/optionsUtils';
import getTextVariantStyle from '@verticeone/design-system/src/components/Text/utils/getTextVariantStyle';
import { useTranslation } from 'react-i18next';

const useStyledHighcharts = () => {
  const { typography, palette } = useTheme();
  const { t } = useTranslation(undefined, { keyPrefix: 'CHARTS' });

  return useCallback(
    (options: Options = {}) => {
      const labels: XAxisLabelsOptions = {
        style: {
          color: palette.text.color1,
          fontSize: typography['body-regular-s'].fontSize as string,
          fontFamily: typography.fontFamily,
        },
      };

      return mergeOptions(options, {
        credits: {
          enabled: false, // hides Highcharts license watermark
        },
        chart: {
          type: 'column',
          plotBackgroundColor: palette.core.color1,
          backgroundColor: palette.core.color1,
          spacing: [10, 0, 0, 0],
        },
        yAxis: {
          title: {
            text: '',
          },
          labels: { ...labels, align: 'left', x: 24, useHTML: true },
          maxPadding: 0,
          gridLineWidth: 1,
          gridLineDashStyle: 'Dot',
          gridLineColor: palette.core.color5,
          plotLines: [
            {
              color: palette.core.color4,
              width: 1,
              zIndex: 3,
              value: 0,
            },
          ],
        },
        tooltip: {
          style: {
            fontFamily: 'inherit',
            pointerEvents: 'auto',
            top: '0px',
          },
          padding: 0,
          backgroundColor: 'transparent',
          borderColor: 'none',
          shadow: false,
        },
        xAxis: {
          labels: { ...labels, y: 42, useHTML: true },
          gridLineWidth: 0,
          lineWidth: 0,
          tickWidth: 0,
          maxPadding: 0,
          minPadding: 0,
        },
        plotOptions: {
          series: {
            marker: {
              symbol: 'circle',
              lineWidth: 2,
              lineColor: palette.core.bg,
            },
          },
          column: {
            pointPadding: 0.05,
            borderWidth: 0,
            groupPadding: 0.1,
            states: {
              inactive: {
                opacity: 1,
              },
            },
          },
          pie: {
            size: 254,
            states: {
              hover: {
                enabled: true,
                halo: {
                  size: 0,
                },
              },
              inactive: {
                opacity: 1,
              },
            },
            innerSize: '85%',
            dataLabels: {
              connectorColor: palette.core.color4,
              style: {
                fontFamily: typography.fontFamily,
                color: palette.text.color2,
                fontWeight: typography['body-bold-s'].fontWeight?.toString(),
                fontSize: typography['body-bold-s'].fontSize as string,
              },
            },
          },
        },
        legend: { enabled: false },
        title: { text: '' },
        drilldown: {
          activeDataLabelStyle: {
            textDecoration: 'none',
            color: 'inherit',
          },
          breadcrumbs: {
            useHTML: true,
            position: { x: -6, y: -47 },
            separator: {
              style: {
                color: palette.core.color4,
              },
            },
            formatter: (event, s) => {
              // For some reason, the `level` props are missing in the event type.
              const ctx = event as unknown as { level: 0 | 1; levelOptions: { name: string } };
              return ctx.level === 0 ? t('DRILLDOWN_BREADCRUMBS_ROOT') : ctx.levelOptions.name;
            },
            buttonTheme: {
              style: {
                color: palette.neutral.color1,
                fontFamily: typography.fontFamily,
                fontSize: getTextVariantStyle({ variant: 'body-regular', size: 'S' }).fontSize as string,
                textDecoration: 'none',
              },
              states: {
                hover: {
                  fill: 'none',
                  style: {
                    textDecoration: 'underline',
                  },
                },
                select: {
                  style: {
                    color: palette.inactive.color2,
                    // Unfortunately, the last breadcrumbs entry in Highcharts uses the "select" state instead of "disabled" state.
                    // For this reason, "hover" styles are applied to it even though it is not clickable.
                    //
                    // Highcharts button hover logic (not applying hover only for state 3 == disabled):
                    // https://github.com/highcharts/highcharts/blob/7f1924d8d85c93ea561b84a8de6624546cce0a75/ts/Core/Renderer/SVG/SVGRenderer.ts#L840
                    //
                    // Place where breadcrumbs sets state 2 == select for the last breadcrumbs entry:
                    // https://github.com/highcharts/highcharts/blob/6ba7435b2284a9e24c25059bdeb8ca0ef8ca446d/ts/Extensions/Breadcrumbs/Breadcrumbs.ts#L906
                    textDecoration: 'none',
                    fontWeight: getTextVariantStyle({ variant: 'body-regular', size: 'S' }).fontWeight,
                  },
                },
              },
            },
          },
        },
      });
    },
    [t, typography, palette]
  );
};

export default useStyledHighcharts;
