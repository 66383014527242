import React, { useMemo } from 'react';
import { Stack, styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import RocketLaunchTwoToneIcon from '@mui/icons-material/RocketLaunchTwoTone';

import { StatsBar, StatsBody, StatsContainer, StatsTile, Title, Value } from '@vertice/core/src/components/StatsBar';
import { StatsBarBase } from '@vertice/core/src/components/StatsBar/StatsBar';
import Text from '@verticeone/design-system/src/components/Text';
import { useFormatCurrency } from '@vertice/core/src/utils/formatting/currency';
import { useFormatPercentage } from '@vertice/core/src/utils/formatting/percentage';
import { AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';

import { useRecommendationData } from '../RecommendationProvider';
import CardHeaderStatus from './CardHeaderStatus';

const StyledIcon = styled(RocketLaunchTwoToneIcon)(({ theme }) => ({
  color: theme.palette.neutral.hover.color3,
  width: '29px',
  height: '29px',
}));

const StyledStatsBar = styled(StatsBar)(({ $noBottomBorder }: { $noBottomBorder: boolean }) => ({
  [StatsBarBase]: {
    borderRadius: 0,
    borderRight: 'none',
    borderLeft: 'none',
    borderTop: 'none',
    borderBottom: $noBottomBorder ? 'none' : undefined,
  },
}));

const Summary = () => {
  const { item, isFetching } = useRecommendationData();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.CLOUD_RECOMMENDATIONS' });
  const { t: tRecommendations } = useTranslation('cloudRecommendations', { keyPrefix: item.code });
  const { palette } = useTheme();
  const formatPercentage = useFormatPercentage();
  const formatCurrency = useFormatCurrency();
  const { empty: isRecommendationEmpty } = item;

  const formattedCost = useMemo(() => {
    return formatCurrency(item.resourceCost ?? 0, {
      currency: AWS_DEFAULT_CURRENCY,
      maximumFractionDigits: 0,
    });
  }, [item, formatCurrency]);

  const formattedSavings = useMemo(() => {
    return formatCurrency(item.saving, {
      currency: AWS_DEFAULT_CURRENCY,
      maximumFractionDigits: 0,
    });
  }, [item, formatCurrency]);

  const formattedPercentageSavings = useMemo(() => {
    return item.resourceCost > 0 ? formatPercentage(item.saving / item.resourceCost) : null;
  }, [item, formatPercentage]);

  const valueOrNA = (value: string | null) => (!isRecommendationEmpty ? value : t('EFFORT_NA'));

  return (
    <StyledStatsBar
      $noBottomBorder={!!isRecommendationEmpty}
      header={<CardHeaderStatus status={item.status} code={item.code} />}
    >
      <StatsContainer flex={1}>
        <StatsTile>
          <StatsBody
            isLoading={isFetching}
            title={<Title id="resource-cost-id">{t('SUMMARY.RESOURCE_COST_LAST_MONTH')}</Title>}
            value={<Value aria-labelledby="resource-cost-id">{valueOrNA(formattedCost)}</Value>}
          />
        </StatsTile>
      </StatsContainer>
      <StatsContainer flex={1}>
        <StatsTile>
          <StatsBody
            isLoading={isFetching}
            title={
              <Title tooltip={{ content: t('SUMMARY.ESTIMATED_MONTHLY_SAVING.TOOLTIP') }} id="estimated-savings-id">
                {t('SUMMARY.ESTIMATED_MONTHLY_SAVING.TITLE')}
              </Title>
            }
            value={
              <Value aria-labelledby="estimated-savings-id">
                {valueOrNA(formattedSavings)}
                {formattedPercentageSavings && !isRecommendationEmpty && (
                  <Text variant="heading" size="M" color={palette.text.color3}>
                    {' '}
                    ({formattedPercentageSavings})
                  </Text>
                )}
              </Value>
            }
          />
        </StatsTile>
      </StatsContainer>
      <StatsContainer flex={1}>
        <StatsTile>
          <StatsBody
            isLoading={isFetching}
            title={
              <Title tooltip={{ content: t('SUMMARY.IMPLEMENTATION_EFFORT.TOOLTIP') }} id="implementation-effort-id">
                {t('SUMMARY.IMPLEMENTATION_EFFORT.TITLE')}
              </Title>
            }
            value={
              <Stack direction="row" gap={1} alignItems="center">
                <StyledIcon />
                <Value aria-labelledby="implementation-effort-id">
                  {tRecommendations('EFFORT', { defaultValue: t('EFFORT_NA') })}
                </Value>
              </Stack>
            }
          />
        </StatsTile>
      </StatsContainer>
    </StyledStatsBar>
  );
};

export default Summary;
