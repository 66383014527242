import { z } from 'zod';

export const zodSchema = z.object({
  contractSigned: z.literal(true),
  vendorInformed: z.literal(true),
  contractSentToSignatory: z.literal(true),
  additionalNotes: z.string().optional(),
});

export type FormData = z.infer<typeof zodSchema>;
