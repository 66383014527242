/* eslint-disable react/no-this-in-sfc */
import React, { useMemo } from 'react';
import { ExpiringReservedInstance } from './useExpiringReservedInstancesData';
import { Box, Stack, styled, useTheme } from '@mui/material';
import DataGrid, { GridColDef } from '@verticeone/design-system/src/components/DataGrid';
import Text from '@verticeone/design-system/src/components/Text/Text';
import { useTranslation } from 'react-i18next';
import { GridRowModel } from '@mui/x-data-grid-pro';
import { getTextVariantStyle } from '@verticeone/design-system/src/components/Text';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import ChipButton from '@verticeone/design-system/src/components/ChipButton';
import { differenceInDays } from 'date-fns/fp';
import { AWS_BRAND_COLOR } from '../../constants';
import useDurationFormatter from '../../hooks/useDurationFormater';

const TABLE_MAX_HEIGHT = 600;
const HEADER_HEIGHT = 42;
const ROW_HEIGHT = 60;

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  borderRadius: '0',
  border: 'none',

  '.MuiDataGrid-row': {
    backgroundColor: theme.palette.input.bg,
  },

  '.MuiDataGrid-cell': {
    ...getTextVariantStyle({ variant: 'body-regular', size: 'M' }),
  },
}));

type ExpiringReservedInstancesTableProps = {
  data: ExpiringReservedInstance[];
};

const now = new Date();

const ExpiringReservedInstancesTable = ({ data }: ExpiringReservedInstancesTableProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EXPIRING_RESERVED_INSTANCES' });
  const { locale } = useLocaleContext();
  const { palette } = useTheme();
  const formatDuration = useDurationFormatter();

  const dateFormatter = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  const tableHeight = useMemo(() => HEADER_HEIGHT + data.length * ROW_HEIGHT, [data]);

  const getRowId = (row: GridRowModel) => row.id;

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: t('COLUMN.RESERVED_INSTANCES_ID'),
      editable: false,
      flex: 1,
    },
    {
      field: 'instanceType',
      headerName: t('COLUMN.INSTANCE_TYPE'),
      renderCell: ({ value, row }) => {
        return (
          <Stack gap={1}>
            <Text variant="body-regular" size="M">{`${value} (${row.offeringClass})`}</Text>
            <Text variant="button" size="XS" color="text3">
              {row.productDescription}
            </Text>
          </Stack>
        );
      },
      editable: false,
      minWidth: 180,
      flex: 1,
    },
    {
      field: 'instanceCount',
      headerName: t('COLUMN.INSTANCE_COUNT'),
      editable: false,
      flex: 1,
    },
    {
      field: 'duration',
      headerName: t('COLUMN.DURATION'),
      editable: false,
      valueFormatter: ({ value }) => formatDuration(value),
      flex: 1,
    },
    {
      field: 'offeringType',
      headerName: t('COLUMN.OFFERING_TYPE'),
      editable: false,
      flex: 1,
    },
    {
      field: 'scope',
      headerName: t('COLUMN.SCOPE'),
      editable: false,
      flex: 1,
    },
    {
      field: 'start',
      headerName: t('COLUMN.START'),
      renderCell: ({ value }) => {
        return dateFormatter.format(new Date(value));
      },
      editable: false,
      flex: 1,
    },
    {
      field: 'end',
      headerName: t('COLUMN.END'),
      renderCell: ({ value, row }) => {
        const endDate = new Date(value);
        const daysRemaining = differenceInDays(now, endDate);
        return (
          <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1} width="100%">
            <Text variant="body-regular" size="M">
              {dateFormatter.format(endDate)}
            </Text>
            <ChipButton
              color={daysRemaining < 30 ? 'warning' : 'neutral'}
              isActive={false}
              variant="ghost"
              sx={{ flexShrink: 0 }}
            >
              {t('DAY', { count: daysRemaining })}
            </ChipButton>
          </Stack>
        );
      },
      editable: false,
      flex: 1,
      minWidth: 250,
    },
  ];

  return (
    <Box bgcolor={palette.core.color1} height={tableHeight > TABLE_MAX_HEIGHT ? TABLE_MAX_HEIGHT : 'auto'}>
      <StyledDataGrid
        rows={data || []}
        columns={columns}
        getRowId={getRowId}
        sortingMode="client"
        hideFooter
        size="XL"
        headerSize="S"
        rowHeight={ROW_HEIGHT}
        columnHeaderHeight={HEADER_HEIGHT}
        showCellVerticalBorder
        showColumnVerticalBorder
        disableRowSelectionOnClick
        autoHeight={tableHeight <= TABLE_MAX_HEIGHT}
        initialState={{
          sorting: {
            sortModel: [{ field: 'end', sort: 'asc' }],
          },
        }}
        color={AWS_BRAND_COLOR}
      />
    </Box>
  );
};

export default ExpiringReservedInstancesTable;
