import React, { useContext, FC } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SearchInput from '@vertice/components/src/SearchInput';
import { VendorListFilterContext } from '../../VendorListFilterContext';

const VendorSearchFilter: FC = () => {
  const { t } = useTranslation();
  const { search, setSearch } = useContext(VendorListFilterContext);

  return (
    <Stack direction="column" width={300}>
      <SearchInput
        value={search ?? ''}
        placeholder={t('VENDORS.SEARCH')}
        onSearch={setSearch}
        testId={'vendor-search-filter'}
        color="primary"
      />
    </Stack>
  );
};

export default VendorSearchFilter;
