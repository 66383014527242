import React, { useMemo } from 'react';
import { useTheme } from '@mui/material';
import Highcharts, { OptionsStackingValue, SeriesOptionsType } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { cssObjectToString } from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/theme';
import { range, sumBy } from 'lodash';

type ScoreBarChartItem = {
  name: string;
  value: number;
  color: string;
};

type ScoreBarChartProps = {
  values: ScoreBarChartItem[];
};

const ScoreBarChart = ({ values }: ScoreBarChartProps) => {
  const { palette } = useTheme();

  const chartSeries: SeriesOptionsType[] = useMemo(
    () =>
      values.map((item) => ({
        name: item.name,
        data: [item.value],
        color: item.color,
        type: 'bar',
      })),
    [values]
  );

  const totalValue = useMemo(() => sumBy(values, (item) => item.value), [values]);
  const tickPositions = useMemo(() => {
    let step = 1;
    if (totalValue >= 50) {
      step = Math.ceil(totalValue / 100) * 10;
    } else if (totalValue > 10) {
      step = 5;
    }

    return range(0, totalValue, step).concat(totalValue);
  }, [totalValue]);

  const optionOverrides = useMemo(
    () => ({
      chart: {
        type: 'bar',
        height: 84,
        spacing: [0, 0, 0, 0],
        marginLeft: 35,
        marginRight: 40,
        plotBackgroundColor: palette.core.bg,
        backgroundColor: palette.core.bg,
      },
      plotOptions: {
        series: {
          stacking: 'normal' as OptionsStackingValue,
          pointWidth: 24,
          dataLabels: {
            enabled: false,
          },
          borderRadius: 8,
          borderWidth: 2,
          borderColor: palette.core.bg,
          enableMouseTracking: false,
        },
      },
      xAxis: {
        labels: {
          enabled: false,
        },
      },
      yAxis: {
        max: totalValue,
        tickPositions: tickPositions,
        plotLines: [],
        labels: {
          x: -20, // half of the label wrapper width
          y: 10,
          formatter: ({ value }: { value: number | string }) => {
            const wrapperStyle = {
              width: '40px', // double of the label.x
              display: 'flex',
              justifyContent: 'center',
            };

            const labelStyle = {
              borderRadius: '8px',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: palette.core.color3,
              backgroundColor: palette.core.bg,
              padding: '1px 7px',
            };

            return `<div style="${cssObjectToString(wrapperStyle)}"><span style="${cssObjectToString(
              labelStyle
            )}">${value}</span></div>`;
          },
        },
      },
      series: chartSeries.reverse(),
      tooltip: { enabled: false },
    }),
    [chartSeries, totalValue, palette, tickPositions]
  );

  const options = useStyledHighcharts(optionOverrides);

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default ScoreBarChart;
