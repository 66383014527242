import { Stack } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

export type DepartmentCellProps = {
  viewOnContract: ViewOnContract;
};

export const departmentCellValueGetter = (viewOnContract: ViewOnContract): string | undefined => {
  const { computed, contract } = viewOnContract;
  const departmentId = contract.classification?.departmentId;
  if (departmentId) {
    return computed?.labels?.[departmentId] || departmentId;
  }
};

export const DepartmentCell = ({ viewOnContract }: DepartmentCellProps) => {
  const departmentLabel = departmentCellValueGetter(viewOnContract);

  if (departmentLabel === undefined) {
    return (
      <Text variant="body-regular" size="M" color="inactive2">
        —
      </Text>
    );
  }
  return (
    <Stack direction="row" gap={1}>
      <Text variant="body-regular">{departmentLabel}</Text>
    </Stack>
  );
};
