import React, { ReactNode } from 'react';
import { Stack } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';

type TopSmallNonClickableCaptionProps = {
  children: ReactNode;
  // Required to so that we can place it to <Fade> and animate it
  style?: React.CSSProperties;
};

const TopSmallNonClickableCaption = React.forwardRef<HTMLDivElement, TopSmallNonClickableCaptionProps>(
  ({ children, style }, ref) => (
    <Stack
      ref={ref}
      style={style}
      sx={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        pointerEvents: 'none',
        textAlign: 'center',
      }}
    >
      <Text variant="body-regular" size="XS" color="text3">
        {children}
      </Text>
    </Stack>
  )
);

export default TopSmallNonClickableCaption;
