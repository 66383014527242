import React, { forwardRef } from 'react';
import { Grid, Stack, styled, useTheme } from '@mui/material';
import { CalendarTodayOutlined } from '@mui/icons-material';
import { StatsBody, Title, Value } from '@vertice/core/src/components/StatsBar';
import ChipButton from '@verticeone/design-system/src/components/ChipButton';
import Text from '@verticeone/design-system/src/components/Text';
import { AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';
import { useFormatCurrency } from '@vertice/core/src/utils/formatting/currency';
import { useFormatPercentage } from '@vertice/core/src/utils/formatting/percentage';
import { useTranslation } from 'react-i18next';
import { useCloudAccounts } from '../CloudAccountsContext';
import { format } from 'date-fns';
import { CloudAccountsStats } from './type';
import Placeholder from '@verticeone/design-system/src/components/Placeholder';
import Loader from '@verticeone/design-system/src/components/Loader';
import getTextVariantStyle from '@verticeone/design-system/src/components/Text/utils/getTextVariantStyle';
import IconWrapper from '@verticeone/design-system/src/components/IconWrapper';
import Tooltip from '@verticeone/design-system/src/components/Tooltip';
import { useDateRangeFormatter } from '../utils';

interface StatsProps {
  data?: CloudAccountsStats;
  isLoading: boolean;
}

const StatsContainer = styled(Stack)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.core.bg,
}));

type LoadingOrValueProps = {
  isLoading: boolean;
  value: React.ReactNode;
  height?: number;
};

const LoadingOrValue = ({ isLoading, value, height = 28 }: LoadingOrValueProps) => (
  <Value>{isLoading ? <Placeholder width="80px" height={height} /> : value}</Value>
);

type PercentageChipProps = {
  isLoading: boolean;
  percentage: number;
};

const PercentageChip = forwardRef<HTMLButtonElement, PercentageChipProps>(
  ({ isLoading, percentage, ...otherProps }, ref) => {
    const formatPercentage = useFormatPercentage();

    return (
      <ChipButton variant="ghost" size="S" isActive={false} {...otherProps} ref={ref}>
        {isLoading && (
          <Stack position="absolute" left="0" right="0" top="0" bottom="0">
            <Loader
              className="loader-spinner"
              thickness={7}
              color="neutral"
              size={getTextVariantStyle({ variant: 'button', size: 'S' }).fontSize}
            />
          </Stack>
        )}
        <Stack visibility={isLoading ? 'hidden' : 'visible'} width={isLoading ? '40px' : 'auto'}>
          {formatPercentage(percentage || 0, { maximumFractionDigits: 0, signDisplay: 'exceptZero' })}{' '}
        </Stack>
      </ChipButton>
    );
  }
);

const BaseCloudAccountsStats = ({ data, isLoading }: StatsProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.ACCOUNTS.STATS' });
  const { palette } = useTheme();
  const formatCurrency = useFormatCurrency();
  const formatDateRange = useDateRangeFormatter();
  const {
    period: { periodOneStart, periodOneEnd, periodTwoStart, periodTwoEnd },
  } = useCloudAccounts();

  return (
    <Grid
      container
      item
      xs={12}
      spacing="1px"
      sx={{
        backgroundColor: palette.core.color3,
      }}
    >
      <Grid item md={6} xs={12}>
        <StatsContainer padding={6}>
          <StatsBody
            gap={4}
            isLoading={false}
            title={<Title tooltip={{ content: t('COSTS.TOOLTIP_INFO') }}>{t('COSTS.TITLE')}</Title>}
            value={
              <Stack gap={2}>
                <Stack direction="row" gap={2} alignItems="center">
                  <LoadingOrValue
                    isLoading={isLoading}
                    value={formatCurrency(data?.cost || 0, {
                      maximumFractionDigits: 2,
                      currency: AWS_DEFAULT_CURRENCY,
                    })}
                  />
                  <Stack gap={2} direction="row" alignItems="center">
                    <Tooltip
                      content={t('VS_PERIOD', {
                        period: `${format(new Date(periodTwoStart), 'PP')} - ${format(new Date(periodTwoEnd), 'PP')}`,
                      })}
                      size="S"
                    >
                      <PercentageChip isLoading={isLoading} percentage={data?.percentage || 0} />
                    </Tooltip>
                  </Stack>
                </Stack>
                <Stack direction="row" gap={2} alignItems="center">
                  <IconWrapper icon={CalendarTodayOutlined} size="S" htmlColor={palette.text.color2} />
                  <Text variant="body-bold" size="S" color="text2" testId="stats-bar-current-period">
                    {formatDateRange(periodOneStart, periodOneEnd)}
                  </Text>
                </Stack>
              </Stack>
            }
          />
        </StatsContainer>
      </Grid>
      <Grid item md={6} xs={12}>
        <StatsContainer padding={6}>
          <StatsBody
            gap={4}
            isLoading={false}
            title={<Title tooltip={{ content: t('PREVIOUS_COST.TOOLTIP_INFO') }}>{t('PREVIOUS_COST.TITLE')}</Title>}
            value={
              <Stack gap={2}>
                <LoadingOrValue
                  isLoading={isLoading}
                  value={formatCurrency(data?.previousCost || 0, {
                    maximumFractionDigits: 2,
                    currency: AWS_DEFAULT_CURRENCY,
                  })}
                />
                <Stack direction="row" gap={2} alignItems="center">
                  <IconWrapper icon={CalendarTodayOutlined} size="S" htmlColor={palette.text.color2} />
                  <Text variant="body-bold" size="S" color="text2" testId="stats-bar-previous-period">
                    {formatDateRange(periodTwoStart, periodTwoEnd)}
                  </Text>
                </Stack>
              </Stack>
            }
          />
        </StatsContainer>
      </Grid>
    </Grid>
  );
};

export default BaseCloudAccountsStats;
