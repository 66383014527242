import {
  FlowDefinition,
  Process,
  ProcessDefinition,
  TaskConfiguration,
} from '@vertice/core/src/modules/intelligentWorkflows/definitionsTypes';
import {
  WorkflowModel,
  WorkflowStartNode,
  WorkflowEndNode,
  WorkflowTaskNode,
  WorkflowEdge,
  WorkflowGatewayNode,
  WorkflowState,
} from './types';
import { resolveTaskNodeType } from './resolveTaskNodeType';

const convertStartNodes = (startNodes: Process['start'], state?: WorkflowState): WorkflowStartNode[] =>
  startNodes.map(({ start }) => ({
    id: start.id,
    name: start.name,
    kind: 'start',
    parentRequest: state?.parentRequest,
  }));

const convertEndNodes = (endNodes: Process['end']): WorkflowEndNode[] =>
  endNodes.map(({ end }) => ({
    id: end.id,
    name: end.name,
    kind: 'end',
  }));

const convertTaskNodes = (taskNodes: Process['tasks'], state?: WorkflowState): WorkflowTaskNode[] =>
  taskNodes?.map(({ task }) => ({
    id: task.id,
    name: task.name,
    kind: 'task',
    state: state?.tasks[task.id],
    type: resolveTaskNodeType(state?.tasks[task.id], task.configurations as TaskConfiguration[]),
    requestRoute: state?.requestRoute,
  })) || [];

const convertGatewayNodes = (gatewayNodes: Process['gateways']): WorkflowGatewayNode[] =>
  gatewayNodes?.map(({ gateway }) => ({
    id: gateway.id,
    name: gateway.name,
    kind: 'gateway',
  })) || [];

const convertEdges = (flow?: FlowDefinition): WorkflowEdge[] => {
  return flow?.flow.edges?.map(({ edge }) => edge) || [];
};

export const processDefinitionToModel = (
  processDefinition: ProcessDefinition,
  state?: WorkflowState
): WorkflowModel => {
  const processDefinitionKind: ProcessDefinition['kind'] = 'ProcessEngine:ProcessDefinition';

  if (processDefinition.kind !== processDefinitionKind) {
    throw new Error(`Invalid process definition kind: ${processDefinition.kind}`);
  }

  return {
    nodes: [
      ...convertStartNodes(processDefinition.process.start, state),
      ...convertEndNodes(processDefinition.process.end),
      ...convertTaskNodes(processDefinition.process.tasks, state),
      ...convertGatewayNodes(processDefinition.process.gateways),
    ],
    edges: convertEdges(processDefinition.process.flow),
  };
};
