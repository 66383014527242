import { useMemo } from 'react';
import LoadableAdvanced from '@vertice/utils/src/loadableAdvanced';
import {
  useAnalyticsAccountsQuery,
  ViewResult,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import useDeferredQuery from '@vertice/core/src/utils/api/useDeferredQuery';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { prepareStatsData, prepareTableData } from './utils';
import { CloudAccountsData } from './types';

export type TableRow = {
  accountId: string;
  accountName: string;
  cost: number;
  previousCost: number;
  difference: number | null;
  charges: number;
  credit: number;
  tax: number;
  previousCharges: number;
  previousCredit: number;
  previousTax: number;
};

type AccountsDataProps = {
  periodOneStart: string;
  periodOneEnd: string;
  periodTwoStart: string;
  periodTwoEnd: string;
};

const useAccountsData = ({
  periodOneStart,
  periodOneEnd,
  periodTwoStart,
  periodTwoEnd,
}: AccountsDataProps): LoadableAdvanced<CloudAccountsData<TableRow>> => {
  const { accountId } = useAccountContext();

  const {
    data: rawData,
    error,
    isLoading,
  } = useDeferredQuery(
    useAnalyticsAccountsQuery,
    {
      accountId,
      periodOneStart,
      periodOneEnd,
      periodTwoStart,
      periodTwoEnd,
    },
    { skip: !accountId, pollingInterval: 5000 },
    ({ athenaViewQuery }) =>
      athenaViewQuery?.__typename === 'DeferredQueryResult' ? undefined : { result: athenaViewQuery }
  );

  const computedTableData = useMemo(
    () =>
      prepareTableData<TableRow>(
        {
          account_id: 'accountId',
          account_name: 'accountName',
          cost_period_one: 'cost',
          cost_period_two: 'previousCost',
          cost_change: 'difference',
          charges_period_one: 'charges',
          credit_period_one: 'credit',
          tax_period_one: 'tax',
          charges_period_two: 'previousCharges',
          credit_period_two: 'previousCredit',
          tax_period_two: 'previousTax',
        },
        rawData as ViewResult
      )?.map((data) => ({ ...data, accountName: data.accountName || data.accountId })),
    [rawData]
  );

  const computedStats = useMemo(() => prepareStatsData<TableRow>(computedTableData), [computedTableData]);

  return {
    error,
    isEmpty: Boolean(computedTableData && computedTableData?.length === 0),
    isLoading,
    data: {
      tableData: computedTableData,
      stats: computedStats,
    },
  };
};

export default useAccountsData;
