import React, { createContext, useContext, useMemo, useState } from 'react';
import { type GridApiPro, GridRowModes, GridValidRowModel, useGridApiRef } from '@mui/x-data-grid-pro';
import { type ContractYear, useContractCreate } from '../useContractCreate';
import { useContractUpdate } from '../useContractUpdate';
import { useContractDelete } from '../useContractDelete';
import { useSimpleDialogContext } from '@verticeone/design-system/src/components/Dialog/SimpleDialog';
import { randomId } from '@mui/x-data-grid-generator';
import { useContractValidator } from '../useContractValidator';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

type ContractContextProps = {
  children: React.ReactNode;
};

type ContractContextType = {
  apiRef: React.MutableRefObject<GridApiPro>;
  isEditing: boolean;
  isSaving: boolean;
  isDeleting: boolean;
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  actions: {
    startEdit: () => void;
    stopEdit: () => void;
    create: () => void;
    update: (contractId: string) => void;
    delete: (contractId: string) => void;
    discard: () => void;
    addRow: () => void;
    removeRow: (rowId: string) => void;
  };
};

const ContractGridContext = createContext<ContractContextType>({} as ContractContextType);

const ContractGridContextProvider = ({ children }: ContractContextProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EDP_CONTRACT_GRID' });
  const { getConfirmation } = useSimpleDialogContext();
  const apiRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [initRows, setInitRows] = useState<Array<GridValidRowModel> | null>(null);
  const validator = useContractValidator();
  const { mutate: deleteContract } = useContractDelete({
    onSettled: () => setIsDeleting(false),
  });
  const { mutate: createContract } = useContractCreate({
    onSettled: () => setIsSaving(false),
    onSuccess: () => actions.stopEdit(),
  });
  const { mutate: updateContract } = useContractUpdate({
    onSettled: () => setIsSaving(false),
    onSuccess: () => actions.stopEdit(),
  });

  const actions = useMemo(
    () => ({
      startEdit: () => {
        setIsEditing(true);
        setIsExpanded(true);
        setInitRows(Array.from(apiRef.current.getRowModels().values()));
        apiRef.current.getAllRowIds().forEach((id) => {
          if (apiRef.current.getRowMode(id) === GridRowModes.View) {
            apiRef.current.startRowEditMode({ id });
          }
        });
      },
      stopEdit: () => {
        setIsEditing(false);
        apiRef.current.getAllRowIds().forEach((id) => {
          if (apiRef.current.getRowMode(id) === GridRowModes.Edit) {
            apiRef.current.stopRowEditMode({ id });
          }
        });
      },
      create: () => {
        setIsSaving(true);
        const years = Array.from(apiRef.current.getRowModels().values()) as Array<ContractYear>;

        void validator(years).then(({ validateContractModel }) => {
          if (validateContractModel?.__typename === 'EDPValidModel' && validateContractModel.success === true) {
            return createContract(years);
          }

          setIsSaving(false);
          if (validateContractModel?.__typename === 'EDPValidationError') {
            const term = validateContractModel.context ? JSON.parse(validateContractModel.context).name : null;
            const field = t(`FIELD.${validateContractModel.fieldName.toUpperCase()}`);
            const message = term
              ? t('ERROR.ERROR_IN', { term })
              : t(`ERROR.${validateContractModel.code}`, { term, field });
            const description = term ? field : undefined;

            enqueueSnackbar({ variant: 'error', message, description });
          }

          if (validateContractModel?.__typename === 'ErroredQueryResult') {
            enqueueSnackbar({ variant: 'error', message: validateContractModel.error });
          }
        });
      },
      update: (contractId: string) => {
        setIsSaving(true);
        const years = Array.from(apiRef.current.getRowModels().values()) as Array<ContractYear>;

        void validator(years).then(({ validateContractModel }) => {
          if (validateContractModel?.__typename === 'EDPValidModel' && validateContractModel.success === true) {
            return updateContract({ contractId, years });
          }

          setIsSaving(false);
          if (validateContractModel?.__typename === 'EDPValidationError') {
            const term = validateContractModel.context ? JSON.parse(validateContractModel.context).name : null;
            const field = t(`FIELD.${validateContractModel.fieldName.toUpperCase()}`);
            const message = term
              ? t('ERROR.ERROR_IN', { term })
              : t(`ERROR.${validateContractModel.code}`, { term, field });
            const description = term ? t(`ERROR.${validateContractModel.code}`, { term, field }) : undefined;

            enqueueSnackbar({ variant: 'error', message, description });
          }

          if (validateContractModel?.__typename === 'ErroredQueryResult') {
            enqueueSnackbar({ variant: 'error', message: validateContractModel.error });
          }
        });
      },
      delete: (contractId: string) => {
        void getConfirmation({
          title: t('DELETE_CONTRACT.TITLE'),
          description: t('DELETE_CONTRACT.DESCRIPTION'),
          okButton: {
            label: t('BUTTONS.DELETE'),
            color: 'tertiary',
          },
        }).then(({ status }) => {
          if (status === 'ok') {
            setIsDeleting(true);
            deleteContract(contractId);
          }
        });
      },
      addRow: () => {
        const id = randomId();
        const models = Array.from(apiRef.current.getRowModels().values());
        apiRef.current.setRows([
          ...models,
          {
            id,
            startDate: null,
            endDate: null,
            name: '',
            commitment: null,
            discount: null,
          },
        ]);
        apiRef.current.startRowEditMode({ id });
      },
      removeRow: (rowId: string) => {
        apiRef.current.updateRows([{ id: rowId, _action: 'delete' }]);
      },
      discard: () => {
        setIsEditing(false);

        if (initRows) {
          apiRef.current.setRows(initRows);
        }

        apiRef.current.getAllRowIds().forEach((id) => {
          if (apiRef.current.getRowMode(id) === GridRowModes.Edit) {
            apiRef.current.stopRowEditMode({
              id,
              ignoreModifications: true,
            });
          }
        });
      },
    }),
    [apiRef, createContract, deleteContract, enqueueSnackbar, getConfirmation, initRows, t, updateContract, validator]
  );

  const value: ContractContextType = useMemo(
    () => ({
      apiRef,
      isEditing,
      isSaving,
      isExpanded,
      isDeleting,
      setIsExpanded,
      actions,
    }),
    [apiRef, isEditing, isSaving, isExpanded, isDeleting, actions]
  );

  return <ContractGridContext.Provider value={value}>{children}</ContractGridContext.Provider>;
};

export const useContractGridContext = () => useContext(ContractGridContext);

export default ContractGridContextProvider;
