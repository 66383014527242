import type { TooltipFormatterContextObject } from 'highcharts';
import { sortTooltipPointsFunction } from '@vertice/core/src/components/charts/components/Tooltip/sortTooltipPointsUtils';
import extractTooltipContent from '@vertice/core/src/components/charts/components/Tooltip/extractTooltipContent';
import { ifExpression } from '@vertice/core/src/utils/ifExpression';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';

export const useFormatNumber = () => {
  const { locale } = useLocaleContext();

  return (value: number) =>
    new Intl.NumberFormat(locale, {
      maximumFractionDigits: 2,
    }).format(value);
};

const useFormatDate = () => {
  const { locale } = useLocaleContext();

  return (value: string) =>
    new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }).format(new Date(value));
};
export const useTooltipExtractor = () => {
  const formatNumber = useFormatNumber();
  const formatDate = useFormatDate();

  return (contextObject: TooltipFormatterContextObject, usedCategories: string[]) => {
    const tooltipSort = sortTooltipPointsFunction(usedCategories, true);
    const tooltip = extractTooltipContent(tooltipSort, (value) => formatNumber(value ?? 0))(contextObject);

    return {
      ...tooltip,
      ...ifExpression(!!contextObject?.x, {
        date: formatDate(contextObject.x as string),
      }),
    };
  };
};
