import { ReactNode } from 'react';
import { AccordionDetails as MuiAccordionDetails, accordionDetailsClasses } from '@mui/material';
import styled from '@mui/material/styles/styled';
import { Theme } from '@mui/material/styles';
import { DesignSystemSize } from '../../types';
import { baseSizes } from '../../guidelines/Sizing/sizings';
import { getTextVariantStyle } from '../Text';
import { useAccordionContext } from './AccordionContext';

type StyledAccordionDetailsProps = {
  theme: Theme;
  $size: DesignSystemSize;
};

const StyledBaseAccordionDetails = styled(MuiAccordionDetails)(({ theme, $size }: StyledAccordionDetailsProps) => ({
  [`&.${accordionDetailsClasses.root}`]: {
    padding: baseSizes[$size],

    borderRadius: '0 0 8px 8px',

    ...getTextVariantStyle({ variant: 'body-regular', size: $size }),
    color: theme.palette.text.color2,

    backgroundColor: theme.palette.core.color1,
  },
}));

const StyledOutlinedAccordionDetails = styled(StyledBaseAccordionDetails)(({ theme }: StyledAccordionDetailsProps) => ({
  [`&.${accordionDetailsClasses.root}`]: {
    border: `1px solid ${theme.palette.core.color3}`,
    borderTop: 'none',
  },
}));

const StyledLabelAccordionDetails = styled(StyledBaseAccordionDetails)(
  ({ theme, $size }: StyledAccordionDetailsProps) => ({
    [`&.${accordionDetailsClasses.root}`]: {
      padding: baseSizes[$size],
      paddingLeft: 0,
      paddingRight: 0,
      backgroundColor: 'transparent',
    },
  })
);

const StyledSolidAccordionDetails = StyledBaseAccordionDetails;

type Props = {
  children: ReactNode;
};

const StyledAccordionDetailsMap = {
  outlined: StyledOutlinedAccordionDetails,
  label: StyledLabelAccordionDetails,
  solid: StyledSolidAccordionDetails,
} as const;

const AccordionDetails = ({ children }: Props) => {
  const { variant, size } = useAccordionContext();
  const Component = StyledAccordionDetailsMap[variant];

  return <Component $size={size}>{children}</Component>;
};

export default AccordionDetails;
