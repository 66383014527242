import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { Typography } from '@mui/material';
import { LaptopOutlined } from '@mui/icons-material';
import styles from './DragDropArea.module.scss';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { validateFile } from '@vertice/utils';

interface DragDropAreaProps {
  handleFile: (file: FileWithPath) => void;
}

/** @deprecated use Fileupload from Design System */
const DragDropArea: FC<DragDropAreaProps> = ({ handleFile }) => {
  const { t } = useTranslation();

  const onDropAccepted = (newFiles: FileWithPath[]) => {
    const file = newFiles[0];
    const invalidFileMessage = validateFile(file, {
      extensions: ['.xls', '.xlsx', '.doc', '.docx', '.pdf'],
      mimeTypes: [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
      ],
    });

    if (invalidFileMessage) {
      // eslint-disable-next-line no-alert
      alert(invalidFileMessage);
      return;
    }

    handleFile(file);
  };

  const { getRootProps, getInputProps } = useDropzone({ multiple: false, onDropAccepted });

  return (
    <div className={styles['file-uploader']} {...getRootProps()}>
      <input {...getInputProps()} />
      <div className={styles['drag-drop-area']}>
        <Typography variant="body-regular-s" className={styles['drag-drop-text']}>
          {t('LEGAL_INFO.DRAG_DROP_PLACEHOLDER')}
        </Typography>

        <div className={styles['laptop-icon-wrap']}>
          <LaptopOutlined className={styles['laptop-icon']} data-testid="laptop-icon" />
        </div>

        <Typography variant="caption-s" color="var(--tokens-color-light-text-2)">
          {t('LEGAL_INFO.BROWSE')}
        </Typography>
      </div>
    </div>
  );
};

export default DragDropArea;
