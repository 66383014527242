import { AddExistingContractFormData, isAddExistingContractFormData } from '../AddExistingContractWizard/types';
import {
  BillingFrequency,
  ContractCreate,
  ContractPartContractual,
  ContractPartContractualFinancial,
  ContractPartContractualLifecycle,
  RollFrequency,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import getCostModelBase from '@vertice/core/src/modules/saas/contract/utils/getCostModelBase';
import { ExternalType, FormDataProductItem } from '../types';
import keepOnlyLocalDatePart from '@vertice/core/src/utils/dates/keepOnlyLocalDatePart';
import omitEmptyValues from '@vertice/core/src/utils/objects/omitEmptyValues';
import { NewPurchaseFormData } from '../NewPurchaseWizard/types';
import { isRenewalFormData, RenewalFormData } from '../RenewalWizard/types';

export const formDataToExternalContract = (
  formData: AddExistingContractFormData | NewPurchaseFormData | RenewalFormData
) => {
  const externalContract: Partial<ExternalType> = {
    legalReviewRequired: formData.legalReviewRequired,
    securityReviewRequired: formData.securityReviewRequired,
    isPIIHeldExpected: formData.piiExpectedToBeHeld,
    contactVendorDirectly: formData.contactVendorDirectly,
    multiYear: formData.discussMultiYearDeals,
    caseStudies: formData.discussCaseStudies,
    plannedGrowth: formData.discussPlannedGrowth,
    discussCompetitors: formData.discussCompetitors,
    signatoryCustomer: formData.signatoryName,
    signatoryEmail: formData.signatoryEmail,
  };

  if (isAddExistingContractFormData(formData) || isRenewalFormData(formData)) {
    externalContract.vendorAccountManager = formData.vendorContactName;
    externalContract.vendorAccountManagerEmail = formData.vendorContactEmail;
  }

  return externalContract;
};

export const formDataToExistingContractCreate = (formData: AddExistingContractFormData): ContractCreate => {
  return {
    record: {
      contractCategory: 'SAAS',
      contractOrigin: 'EXISTING',
    },
    parts: {
      model: {
        cost: {
          configuration: {
            withoutVertice: {
              ...getCostModelBase(),
              model: omitEmptyValues({
                annual_cost: formData.totalAnnualCost,
              }),
            },
            withVertice: getCostModelBase(),
          },
        },
      },
      contractual: omitEmptyValues({
        vendor: omitEmptyValues({
          vendorType: formData.vendor?.type ?? 'PREDEFINED',
          vendorId: formData.vendor?.id || undefined,
          vendorName: formData.vendor?.name ?? undefined,
          vendorContact: omitEmptyValues({
            name: formData.vendorContactName,
            email: formData.vendorContactEmail,
          }),
        }),
        products: formDataProductsToContractProducts(formData.products),
        financial: omitEmptyValues({
          baseCurrency: formData.contractCurrency,
          billingFrequency: getBillingFrequency(formData.billingFrequency),
        }),
        lifecycle: omitEmptyValues({
          startDate: keepOnlyLocalDatePart(formData.startDate) ?? undefined,
          renewalDate: keepOnlyLocalDatePart(formData.renewalDate) ?? undefined,
          autoRenewalDeadline: keepOnlyLocalDatePart(formData.autoRenewalDate) ?? undefined,
          rollFrequency: getRollingFrequency(formData.rollingFrequency),
        }),
        signatory: omitEmptyValues({
          signingDate: keepOnlyLocalDatePart(formData.signDate || formData.startDate) ?? undefined,
          signer: omitEmptyValues({
            name: formData.signatoryName,
            email: formData.signatoryEmail,
          }),
          signingEntity: omitEmptyValues({
            name: formData.signingEntity,
          }),
        }),
      }),
    },
    classification: omitEmptyValues({
      departmentId: formData?.departmentId ?? undefined,
    }),
  };
};

const formDataProductsToContractProducts = (
  formProducts: FormDataProductItem[]
): ContractPartContractual['products'] | undefined => {
  if (formProducts.length === 0) {
    return undefined;
  }

  return formProducts.map((p) => ({
    licenseCount: p.numberOfLicences ?? undefined,
    productId: p.productId,
    annualCost: p.annualCost ?? undefined,
  }));
};

const getBillingFrequency = (
  billingFrequency?: BillingFrequency | null
): ContractPartContractualFinancial['billingFrequency'] => {
  if (!billingFrequency) {
    return undefined;
  }
  return billingFrequency;
};

const getRollingFrequency = (
  rollingFrequency?: RollFrequency | null
): ContractPartContractualLifecycle['rollFrequency'] => {
  if (!rollingFrequency) {
    return undefined;
  }
  return rollingFrequency;
};
