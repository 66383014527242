import React, { useState } from 'react';
import Card, {
  CardHeader,
  CardHeaderActions,
  CardHeaderDescription,
  CardHeaderTitle,
} from '@verticeone/design-system/src/components/Card';
import { useTranslation } from 'react-i18next';
import CompletedContractsTable from './CompletedContractsTable';
import DepartmentFilter from './DepartmentFilter';

const CompletedContractsCard = () => {
  const { t } = useTranslation();
  const [selectedDepartment, setSelectedDepartment] = useState<string | undefined>();

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle text={t('SAAS.SAVINGS.COMPLETED_CONTRACTS_CARD.TITLE')} />
        <CardHeaderDescription>{t('SAAS.SAVINGS.COMPLETED_CONTRACTS_CARD.DESCRIPTION')}</CardHeaderDescription>
        <CardHeaderActions>
          <DepartmentFilter selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} />
        </CardHeaderActions>
      </CardHeader>
      <CompletedContractsTable department={selectedDepartment} />
    </Card>
  );
};

export default CompletedContractsCard;
