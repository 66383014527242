import React, { FC, useState } from 'react';
import {
  Contract,
  type CreateContractApiResponse,
  useCreateContractMutation,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { useTranslation } from 'react-i18next';
import Button from '@verticeone/design-system/src/components/Button';
import { VerticeVWhiteIcon } from '@verticeone/design-system/src/assets';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { Link as RouterLink } from 'react-router-dom';
import {
  Product,
  useCreateRenewalRequest,
  Vendor,
} from '../../../../../intelligentWorkflows/hooks/useCreateRenewalRequest';
import { Stack } from '@mui/material';
import Divider from '@verticeone/design-system/src/components/Divider';
import Text from '@verticeone/design-system/src/components/Text';
import { InitiateRequestBetaDialog } from '../../../../../intelligentWorkflows/request/InitiateRequestBetaDialog/InitiateRequestBetaDialog';
import { useSnackbar } from 'notistack';
import {
  ContractTab,
  useContractTabNavigationContext,
} from '@vertice/core/src/modules/saas/contract/ContractTabNavigationContext';
import { deriveRenewedOutsideContractFromCurrent } from './ContractNotYetRenewedCard';
import { NonSaasRenewalAlert } from '@vertice/core/src/modules/saas/contract/components/NonSaasRenewalAlert';
import { useSimpleDialogContext } from '@verticeone/design-system/src/components/Dialog/SimpleDialog';

export const ContractNotYetRenewedContentActions: FC<{ contract: Contract }> = ({ contract }) => {
  const { t } = useTranslation();

  const routes = useRoutes();
  const { navigate, generatePath } = useRouteNavigate();
  const tabNavigationContext = useContractTabNavigationContext();

  const { accountId, isFeatureEnabled, getFeature } = useAccountContext();
  const [createRequest, isCreatingRequest] = useCreateRenewalRequest();
  const { enqueueSnackbar } = useSnackbar();

  const [createContract, { isLoading: isCreatingRenewalOutsideVertice }] = useCreateContractMutation();

  const isIWEnabled = isFeatureEnabled(FEATURES.INTELLIGENT_WORKFLOWS);
  const [betaDialogOpened, setBetaDialogOpened] = useState(false);
  const handleCloseBetaDialog = () => setBetaDialogOpened(false);

  const { getConfirmation } = useSimpleDialogContext();

  const renewalIntakePath = generatePath(routes.CONTRACTS.DETAIL.RENEWAL_INTAKE.ABSOLUTE_PATH, {
    contractId: contract.record.contractId,
  });

  const createRenewalRequest = async () => {
    const pollingSuccessful = await createRequest({
      contractId: contract.record.contractId!,
      vendor: contractVendorToRenewalRequestVendor(contract),
      products: contractProductsToRenewalRequestProducts(contract),
      source: `urn:verticeone:vertice:${accountId}:cfa:page/contract/${contract.record.contractId}`,
    });

    if (pollingSuccessful) {
      enqueueSnackbar(t('ENTITIES.WORKFLOW_REQUEST.SNACKBARS.CREATED'), { variant: 'success' });
    }
    navigate(routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL, {
      requestId: pollingSuccessful!,
    });
  };

  const handleInitiateRenewalRequest = async () => {
    const workflowsFeature = getFeature(FEATURES.INTELLIGENT_WORKFLOWS);
    if (workflowsFeature?.properties?.beta === true) {
      setBetaDialogOpened(true);
      return;
    }

    await createRenewalRequest();
  };

  const handleRenewOutsideVerticeButtonClick = async () => {
    const confirmResult = await getConfirmation({
      title: t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.RENEW_OUTSIDE_VERTICE_QUESTION'),
      description: t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.RENEW_OUTSIDE_VERTICE_CONFIRMATION'),
      okButton: { label: t('DIALOG.BUTTONS.CONFIRM') },
    });

    if (confirmResult.status !== 'ok') {
      return;
    }

    const creationResult = await createContract({
      accountId,
      createContractRequest: {
        contract: deriveRenewedOutsideContractFromCurrent(contract),
      },
    });

    const successResult = (creationResult as { data: CreateContractApiResponse }).data;
    if (successResult) {
      tabNavigationContext.setTabId(ContractTab.REQUEST_DETAILS);
      navigate(routes.CONTRACTS.DETAIL.EDIT, {
        contractId: successResult.viewOnContract.contract.record.contractId,
      });
    }
  };

  return (
    <>
      {isIWEnabled ? (
        <Button
          fullWidth
          variant="solid"
          color="primary"
          isCaption
          size="S"
          onClick={handleInitiateRenewalRequest}
          disabled={isCreatingRequest && !betaDialogOpened}
          isLoading={isCreatingRequest && !betaDialogOpened}
        >
          {t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.INITIATE_RENEWAL')}
        </Button>
      ) : (
        <Stack gap={4} width="100%">
          <Stack gap={1}>
            <Button
              fullWidth
              variant="solid"
              color="primary"
              isCaption
              size="S"
              component={RouterLink}
              to={renewalIntakePath}
              disabled={isCreatingRenewalOutsideVertice}
            >
              <VerticeVWhiteIcon />
              {t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.RENEW_WITH_VERTICE')}
            </Button>
            <NonSaasRenewalAlert contract={contract} />
          </Stack>
          <Divider>
            <Text variant="label" color="text3" size="XXS">
              {t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.OR')}
            </Text>
          </Divider>
          <Button
            fullWidth
            variant="plain"
            color="primary"
            size="M"
            onClick={handleRenewOutsideVerticeButtonClick}
            disabled={isCreatingRenewalOutsideVertice}
            isLoading={isCreatingRenewalOutsideVertice}
          >
            {t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.RENEW_OUTSIDE_VERTICE_BUTTON')}
          </Button>
        </Stack>
      )}
      <InitiateRequestBetaDialog
        isOpened={betaDialogOpened}
        onClose={handleCloseBetaDialog}
        onIWRequestClick={createRenewalRequest}
        requestType="RENEWAL"
        legacyRequestLinkRoute={renewalIntakePath}
        isLoading={isCreatingRequest}
      />
    </>
  );
};

const contractProductsToRenewalRequestProducts = (contract: Contract): Product[] => {
  return (
    contract?.parts?.contractual?.products?.map((product) => ({
      id: product.productId ?? undefined,
      name: product.productName ?? undefined,
      productId: product.productId ?? undefined,
      annualCost: product.annualCost ?? undefined,
      numberOfLicences: product.licenseCount ?? undefined,
    })) ?? []
  );
};

const contractVendorToRenewalRequestVendor = (contract: Contract): Vendor => {
  return {
    id: contract?.parts?.contractual?.vendor!.vendorId ?? null,
    name: contract?.parts?.contractual?.vendor!.vendorName ?? null,
    type: contract?.parts?.contractual?.vendor!.vendorType,
  };
};
