import React, { useMemo } from 'react';
import {
  ApplicationStatus,
  useGetProvidersQuery,
  DataHealthCheckSeverity,
  DataHealthCheckStatus,
} from '@vertice/slices/src/graphql/insight/generated/insightGraphQL';
import { useListApplicationsWithMeasureReportsQuery } from '@vertice/slices/src/graphql/bffeUsage/generated/usageGraphQL';
import {
  ApplicationWithMeasureReportsListItem,
  ApplicationDataHealth,
  ApplicationDataHealthCheck,
} from '@vertice/slices/src/graphql/bffeUsage/derivedTypes';

import { ProviderStatus } from '@vertice/slices/src/graphql/insight/generated/insightGraphQL';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

const initialRowSortingFunction = (
  a: ApplicationWithMeasureReportsListItem,
  b: ApplicationWithMeasureReportsListItem
) => {
  const aVendorName = a.matches?.find((match) => match.entityType === 'Vendor')?.entityName || '';
  const bVendorName = b.matches?.find((match) => match.entityType === 'Vendor')?.entityName || '';

  if (aVendorName === bVendorName) {
    return a.name?.localeCompare(b.name || '') || 0;
  }

  return aVendorName.localeCompare(bVendorName);
};

export const getUtilization = (applicationWithMeasures: ApplicationWithMeasureReportsListItem): number | null =>
  applicationWithMeasures.license?.type === 'SEAT_BASED' &&
  applicationWithMeasures.license?.quantity &&
  applicationWithMeasures.license.quantity > 0
    ? parseInt(applicationWithMeasures.measureReports?.uniqueUserAccessCountLast28d?.at(0)?.value || '0', 10) /
      applicationWithMeasures.license.quantity
    : null;

export const getActivityFrequency = (
  applicationWithMeasures: ApplicationWithMeasureReportsListItem
): string | null | undefined => {
  const activityFrequency = applicationWithMeasures.measureReports?.activityFrequencyLast56d;

  if (!activityFrequency || !activityFrequency.length) {
    return null;
  }

  const mostActiveBin = activityFrequency.reduce((prev, current) =>
    prev && parseInt(prev.value, 10) > parseInt(current.value, 10) ? prev : current
  );

  if (mostActiveBin?.value === '0') return null;

  return mostActiveBin?.binStart;
};

type useGetApplicationsWithVendorsProps = {
  accountId?: string;
  status?: ApplicationStatus;
  showGoogleSocial?: boolean;
};

type useGetApplicationsWithVendorsReturn = {
  applications: ApplicationWithMeasureReportsListItem[] | undefined;
  healthyApplicationsCount: number;
  unhealthyApplicationsCount: number;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
};

type useGetApplicationsWithVendorsOptions = {
  skip?: boolean;
};

export const hasWarnings = (dataHealth: ApplicationDataHealth): boolean =>
  dataHealth?.checks?.some(isCheckWarning) || false;
export const hasInfo = (dataHealth: ApplicationDataHealth): boolean => dataHealth?.checks?.some(isCheckInfo) || false;

export const isCheckWarning = (check: ApplicationDataHealthCheck): boolean =>
  check.severity === DataHealthCheckSeverity.Warning && check.status === DataHealthCheckStatus.Failed;

export const isCheckInfo = (check: ApplicationDataHealthCheck): boolean =>
  check.severity === DataHealthCheckSeverity.Info && check.status === DataHealthCheckStatus.Failed;

export const useGetApplicationsWithVendors = (
  { accountId, status = ApplicationStatus.Discovered, showGoogleSocial = true }: useGetApplicationsWithVendorsProps,
  { skip = false }: useGetApplicationsWithVendorsOptions
): useGetApplicationsWithVendorsReturn => {
  const { someProviderEnabled, isLoading: providersLoading } = useHasEnabledSSOProvider();
  const [currentGoogleSocialStatus, setCurrentGoogleSocialStatus] = React.useState<boolean>(showGoogleSocial);

  const {
    data: applicationsWithMeasures,
    isError,
    isLoading: applicationsLoading,
  } = useListApplicationsWithMeasureReportsQuery(
    {
      accountId: accountId!,
      nextToken: null,
      limit: 500,
      statuses: [status],
    },
    { skip }
  );

  //   we only work with applications that have a vendor
  const applicationsWithCounts = useMemo(() => {
    const applications: ApplicationWithMeasureReportsListItem[] | undefined =
      isError || !someProviderEnabled
        ? undefined
        : applicationsWithMeasures?.listApplicationsWithMeasure?.items
            ?.filter(
              (application) =>
                (currentGoogleSocialStatus || // if 'false', Google Social is only apps are filtered out
                  application.sources?.length === 0 || // header rows
                  application.sources?.some((source) => source.id !== 'Provider:Google:Workspace:SocialLogin')) &&
                application.matches?.find((match) => match.entityType === 'Vendor')
            )
            .map(
              (application): ApplicationWithMeasureReportsListItem => ({
                ...application,
                hasWarnings: hasWarnings(application.dataHealth),
                hasInfo: hasInfo(application.dataHealth),
              })
            )
            .sort(initialRowSortingFunction);

    return {
      applications,
      healthyApplicationsCount: applications?.filter((application) => !application.hasWarnings).length,
      unhealthyApplicationsCount: applications?.filter((application) => application.hasWarnings).length,
    };
  }, [isError, applicationsWithMeasures, currentGoogleSocialStatus, someProviderEnabled]);

  if (showGoogleSocial !== currentGoogleSocialStatus) {
    setCurrentGoogleSocialStatus(showGoogleSocial);
  }

  return {
    applications: applicationsWithCounts.applications,
    healthyApplicationsCount: applicationsWithCounts.healthyApplicationsCount || 0,
    unhealthyApplicationsCount: applicationsWithCounts.unhealthyApplicationsCount || 0,
    isLoading: applicationsLoading || providersLoading || someProviderEnabled === undefined,
    isSuccess: applicationsWithCounts !== undefined,
    isError,
  };
};

export const useHasEnabledSSOProvider = () => {
  const { accountId } = useAccountContext();
  const { data, isLoading } = useGetProvidersQuery({ accountId: accountId! }, { skip: !accountId });

  return {
    isLoading,
    someProviderEnabled: data?.providers?.some((provider) => provider.status === ProviderStatus.Active) || false,
  };
};
