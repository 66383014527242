import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import {
  useOptimizationCheckQuery,
  useLatestOptimizationTestsQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import format from 'date-fns/format';
import { DATE_FORMAT, getTableData, getTestTableData } from '../../utils/graphDataUtils';
import { addMonths, startOfMonth } from 'date-fns';
import { useMemo } from 'react';
import { chain, sumBy } from 'lodash';
import useDeferredQuery from '@vertice/core/src/utils/api/useDeferredQuery';
import LoadableAdvanced from '@vertice/utils/src/loadableAdvanced';
import { RegionData, RegionItem } from '../shared/ReservedInstancesTable/types';

const TARGET = 0.8;

const startDate = addMonths(startOfMonth(new Date()), -3);

export type ReservedInstancesData = {
  groupedByRegion?: RegionData[];
  target?: number;
  values?: [number, number][];
};

type ExtractedCheckData = {
  region: string;
  instance_family: string;
  availability_zone: string;
  on_demand_cost: number;
  ri_coverage: number;
  sp_coverage: number;
  total_usage: number;
  proposed_ec2_sp_cost: number;
  proposed_cost_3yr_all_upfront_standard: number;
};

const useEC2CoverageData = (test_code: string): LoadableAdvanced<ReservedInstancesData> => {
  const { accountId } = useSelector(getAccount);

  const { data: rawCoverageDailyData, error: coverageError } = useDeferredQuery(
    useOptimizationCheckQuery,
    {
      accountId: accountId!,
      // Acceptable timezone inaccuracy: We're sending local timezone date to UTC endpoint
      startDate: format(startDate, DATE_FORMAT),
      checkCode: 'EC2_AVG_DAILY_COVERAGE',
    },
    { skip: !accountId, pollingInterval: 5000 },
    ({ checkQuery }) => checkQuery
  );

  /**
   * We need the test result to match check result items with the proposed costs (provided by recommendation).
   * The number of items in the check is greater that the number of items in the associated test and recommendation.
   * The items with coverage above the target are filtered out in tests and recommendations.
   */
  const { data: rawTestData, error: testError } = useLatestOptimizationTestsQuery({
    accountId: accountId!,
    testCode: test_code,
  });

  const coverageKey = test_code === 'VT-12' ? 'sp_coverage' : 'ri_coverage';

  const computedChartData = useMemo(() => {
    if (!rawCoverageDailyData || rawCoverageDailyData.length === 0) return undefined;

    const allMonthsData = chain(rawCoverageDailyData)
      .flatMap(({ values }) => values)
      .flatMap(
        ({ checkResult }) =>
          getTableData(checkResult, {
            time: 'timestamp',
            [coverageKey]: 'coverage',
          }) as { time: number; coverage: number }[]
      )
      .uniq()
      .orderBy('timestamp', 'asc')
      .map((item) => Object.values(item) as [number, number])
      .value();

    return {
      target: TARGET,
      values: allMonthsData,
    };
  }, [coverageKey, rawCoverageDailyData]);

  const computedTableData = useMemo(() => {
    if (!rawTestData || rawTestData.monitoringLatestQuery?.__typename !== 'MonitoringResult') return undefined;

    const testItems = rawTestData.monitoringLatestQuery?.items;
    if (!testItems || testItems.length === 0) return undefined;

    const testResult = testItems[0].results;
    if (testResult?.__typename !== 'MulticlassBinnedTestResult') return undefined;

    const data = getTestTableData(testResult) as ExtractedCheckData[];

    const getProposedCost = (item: ExtractedCheckData) => {
      const proposedCostValue =
        test_code === 'VT-12'
          ? Number(item.proposed_ec2_sp_cost)
          : test_code === 'VT-18'
          ? Number(item.proposed_cost_3yr_all_upfront_standard)
          : undefined;

      return proposedCostValue ?? item.on_demand_cost;
    };

    return {
      groupedByRegion: chain(data)
        .groupBy('region')
        .map((allInRegion, region) => {
          const totalCost = sumBy(allInRegion, ({ on_demand_cost }) => on_demand_cost);
          const totalProposedCost = sumBy(allInRegion, getProposedCost);
          return {
            id: region,
            region: region,
            cost: totalCost,
            saving: totalCost - totalProposedCost,
            items: chain(allInRegion)
              .groupBy((item) => `${item.instance_family}_${item.availability_zone}`)
              .map((groupedItems, group) => {
                const groupCost = sumBy(groupedItems, ({ on_demand_cost }) => on_demand_cost);
                const groupProposedCost = sumBy(groupedItems, getProposedCost);
                const totalUsage = sumBy(groupedItems, ({ total_usage }) => total_usage);
                const riCoverage =
                  sumBy(groupedItems, ({ ri_coverage, total_usage }) => ri_coverage * total_usage) / totalUsage;
                const spCoverage =
                  sumBy(groupedItems, ({ sp_coverage, total_usage }) => sp_coverage * total_usage) / totalUsage;
                let coverage = riCoverage;

                switch (test_code) {
                  case 'VT-12':
                    coverage = spCoverage;
                    break;
                  case 'VT-18':
                    coverage = riCoverage;
                    break;
                }

                return {
                  id: `${region}_${group}`,
                  region: region,
                  instanceFamily: groupedItems[0].instance_family,
                  availabilityZone: groupedItems[0].availability_zone,
                  cost: groupCost,
                  saving: groupCost - groupProposedCost,
                  coverage: coverage,
                  totalCoverage: riCoverage + spCoverage,
                } as RegionItem;
              })
              .orderBy('saving', 'desc')
              .value(),
          };
        })
        .orderBy('saving', 'desc')
        .value(),
    };
  }, [rawTestData, test_code]);

  return {
    error: coverageError ?? testError,
    isEmpty: Boolean(
      rawCoverageDailyData && (rawCoverageDailyData.length === 0 || rawCoverageDailyData[0]?.values?.length === 0)
    ),
    isLoading: !computedChartData || !computedTableData,
    data: { ...computedChartData, ...computedTableData },
  };
};

export default useEC2CoverageData;
