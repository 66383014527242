import { camelCase } from 'lodash';
import { OptimizationTestsTableState } from './OptimizationTestsTable';
import useQueryParams from '@vertice/hooks/src/useQueryParams';

enum SearchParamKey {
  EXPANDED_TESTS = 'expandedTests',
}

/**
 * Stores the state of the OptimizationTestsTable in the URL query params
 * @param paramPrefix optional prefix for the query params if you need multiple instances of the store
 * @example
 * const { state, onStateChange } = useOptimizationTestsTableSearchParamsStateStore();
 * // => Stores params like `expandedTests=VT-1,VT-2,VT-3`
 * const { state, onStateChange } = useOptimizationTestsTableSearchParamsStateStore('fooBar');
 * // => Stores params like `fooBarExpandedTests=VT-1,VT-2,VT-3`
 */
const useOptimizationTestsTableSearchParamsStateStore = (
  paramPrefix = ''
): {
  state: OptimizationTestsTableState;
  onStateChange: (state: OptimizationTestsTableState) => void;
} => {
  const { queryParams, updateQueryParams } = useQueryParams();
  const makeParamKey = (name: string) => camelCase(`${paramPrefix} ${name}`);
  return {
    state: {
      expandedTests: queryParams.get(makeParamKey(SearchParamKey.EXPANDED_TESTS))?.split(',') ?? [],
    },
    onStateChange: (state: OptimizationTestsTableState) => {
      updateQueryParams({
        [makeParamKey(SearchParamKey.EXPANDED_TESTS)]: state.expandedTests.join(','),
      });
    },
  };
};

export default useOptimizationTestsTableSearchParamsStateStore;
