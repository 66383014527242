import type { Components, Theme } from '@mui/material/styles';
import type {} from '@mui/lab/themeAugmentation';

const muiComponentsOverridesOld: Components<Theme> = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: '.8rem',
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...(ownerState.gutterBottom && { marginBottom: '0.75em' }),
      }),
    },
  },
  MuiButton: {
    variants: [
      {
        props: { variant: 'contained' },
        style: {
          animationTimingFunction: 'ease-out',
          animationDuration: '100ms',
          backgroundColor: 'var(--tokens-color-light-primary-1)',
          borderRadius: '10px',
          padding: '12px 20px',
          letterSpacing: '0.05em',
          fontWeight: 600,
          textAlign: 'center',
          border: 0,
          '&:hover': {
            backgroundColor: '#5100D6',
          },
          '&:active': {
            backgroundColor: 'var(--tokens-color-light-primary-1-hover)',
          },
          '&:disabled': {
            backgroundColor: 'var(--tokens-color-light-core-1)',
            color: 'var(--tokens-color-light-core-5)',
          },
        },
      },
      {
        props: { size: 'medium' },
        style: {
          fontSize: '12px',
          lineHeight: '15px',
          height: '48px',
        },
      },
      {
        props: { size: 'small' },
        style: {
          fontSize: '12px',
          lineHeight: '15px',
          height: '36px',
        },
      },
      {
        props: { variant: 'contained', color: 'neutral', size: 'medium' },
        style: ({ theme }) => ({
          animationTimingFunction: 'ease-out',
          animationDuration: '100ms',
          backgroundColor: theme.palette.neutral.color2,
          borderRadius: '8px',
          padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
          fontSize: '16px',
          lineHeight: '100%',
          fontWeight: 600,
          textAlign: 'center',
          height: '32px',
          gap: theme.spacing(2),
          border: 0,
          '&:hover': {
            backgroundColor: theme.palette.neutral.hover.color1,
          },
          '&:active': {
            backgroundColor: theme.palette.neutral.hover.color1,
          },
          '&:disabled': {
            backgroundColor: theme.palette.inactive.color3,
            color: theme.palette.inactive.color2,
          },
        }),
      },
      {
        props: { variant: 'contained', color: 'secondary' },
        style: {
          backgroundColor: 'var(--tokens-color-light-secondary-1)',
          borderRadius: '10px',
          padding: '12px 20px',
          letterSpacing: '0.05em',
          fontWeight: 600,
          textAlign: 'center',
          color: 'var(--tokens-color-light-core-6)',
          '&:hover': {
            backgroundColor: '#00CAE5',
          },
          '&:active': {
            backgroundColor: 'var(--tokens-color-light-secondary-3)',
            color: 'var(--tokens-color-light-text-5)',
          },
          '&:disabled': {
            color: 'var(--tokens-color-light-core-6)',
            backgroundColor: 'var(--tokens-color-light-primary-3)',
          },
        },
      },
      {
        props: { variant: 'outlined' },
        style: {
          animationTimingFunction: 'ease-out',
          animationDuration: '100ms',
          borderRadius: '10px',
          padding: '12px 20px',
          letterSpacing: '0.05em',
          fontWeight: 600,
          textAlign: 'center',
          backgroundColor: 'transparent',
          color: 'var(--tokens-color-light-core-6)',
          '&:hover': {
            backgroundColor: 'transparent',
            color: 'var(--tokens-color-light-primary-1)',
            borderColor: 'var(--tokens-color-light-secondary-2)',
          },
          '&:active': {
            backgroundColor: 'var(--tokens-color-light-secondary-2)',
            color: 'var(--tokens-color-light-primary-1-hover)',
            border: 0,
          },
          '&:disabled': {
            color: 'var(--tokens-color-light-text-3)',
            borderColor: 'var(--tokens-color-light-text-3)',
          },
        },
      },
    ],
  },
  MuiSwitch: {
    defaultProps: {
      size: 'XL',
    },
    styleOverrides: {
      switchBase: {
        ':hover': {
          backgroundColor: 'transparent',
        },
        '.Mui-checked&:hover': {
          backgroundColor: 'transparent',
        },
      },

      thumb: {
        boxSizing: 'border-box',
        background: '#FFFFFF',
      },

      track: {
        background: 'var(--tokens-color-light-core-3)',
        borderRadius: '24px',
        opacity: 1,
        '.Mui-checked.Mui-checked:not(.Mui-disabled) + &': {
          background: 'var(--global-gradient-light-blue-purple)',
          opacity: 1,
        },
        '.MuiSwitch-root:hover .Mui-checked:not(.Mui-disabled) + &': {
          background:
            'linear-gradient(to right, var(--tokens-color-light-primary-3), var(--tokens-color-light-primary-3))',
        },
        '.MuiSwitch-root:hover .Mui-disabled + &': {
          background: 'var(--tokens-color-light-core-3)',
          opacity: 1,
        },
        '.MuiSwitch-switchBase.Mui-disabled+&': {
          backgroundColor: 'var(--tokens-color-light-core-3)',
          opacity: 1,
        },
        '.MuiSwitch-switchBase.Mui-checked.Mui-disabled+&': {
          backgroundColor: 'var(--tokens-color-light-core-3)',
          opacity: 1,
        },
        '.MuiSwitch-root:hover &': {
          background: 'var(--tokens-color-light-text-3)',
        },
        '.MuiSwitch-root:focus .Mui-checked:not(.Mui-disabled) + &': {
          background: 'var(--global-gradient-light-blue-purple)',
        },
        '.MuiSwitch-root:active .Mui-checked:not(.Mui-disabled) + &': {
          background: 'var(--global-gradient-light-blue-purple)',
        },
        '.MuiSwitch-root:focus .MuiSwitch-switchBase.Mui-checked:not(.Mui-disabled) + &': {
          border: '4px solid var(--tokens-color-light-primary-2)',
        },
        '.MuiSwitch-root:active .MuiSwitch-switchBase.Mui-checked:not(.Mui-disabled) + &': {
          border: '4px solid var(--tokens-color-light-primary-2)',
        },
        '.MuiSwitch-root:focus .MuiSwitch-switchBase:not(.Mui-disabled) + &': {
          border: '4px solid var(--tokens-color-light-core-3)',
        },
        '.MuiSwitch-root:active .MuiSwitch-switchBase:not(.Mui-disabled) + &': {
          border: '4px solid var(--tokens-color-light-core-3)',
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: '10px',
      },
      input: {
        height: '24px',
        padding: '12px',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      input: {
        height: '24px',
        padding: '12px',
        fontWeight: 500,
        fontSize: '16px',
        fontStyle: 'normal',
        lineHeight: '24px',
      },
    },
  },

  // was implemented before design
  MuiLink: {
    defaultProps: {
      underline: 'hover',
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: 'h6',
      },
    },
    styleOverrides: {
      action: {
        marginTop: '-4px',
        marginRight: '-4px',
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: '6px',
        boxShadow: 'rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px',
        backgroundImage: 'none',
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: 'none',
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: '6px',
      },
    },
  },
};

export default muiComponentsOverridesOld;
