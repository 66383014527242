import Text from '@verticeone/design-system/src/components/Text';
import { t } from 'i18next';
import { Stack, useTheme } from '@mui/material';
import ChipButton from '@verticeone/design-system/src/components/ChipButton';
import { SECOND_LINE_TEST_ID } from './Deadline';
import { getTimeLeft } from './utils';
import dayjs from 'dayjs';

type AdditionalInfoProps = {
  date: string;
  endDate?: boolean;
};

const AdditionalInfo = ({ date: dateString, endDate }: AdditionalInfoProps) => {
  const { palette } = useTheme();

  const date = dayjs(dateString);
  const timeLeft = getTimeLeft(date);
  const timeleftLocalized = (timeLeft || '')
    .replace('{overdue}', t('CONTRACTS.GRID.OVERDUE'))
    .replace('{today}', t('CONTRACTS.GRID.TODAY'))
    .replace('{day_left}', t('CONTRACTS.GRID.DAY_LEFT'))
    .replace('{days_left}', t('CONTRACTS.GRID.DAYS_LEFT'))
    .replace('{weeks_left}', t('CONTRACTS.GRID.WEEKS_LEFT'));

  return endDate ? (
    <Text
      testId={SECOND_LINE_TEST_ID}
      variant="body-bold"
      size="S"
      color={palette.mode === 'light' ? 'text3' : 'text2'}
    >
      {t('CONTRACTS.GRID.END_DATE')}
    </Text>
  ) : (
    timeleftLocalized && (
      <>
        {palette.mode === 'light' ? (
          <Text size="S" variant="caption" color="tertiary2">
            {timeleftLocalized}
          </Text>
        ) : (
          <Stack direction="row">
            <ChipButton isActive={false} variant="outline" color="transparent" size="S">
              {timeleftLocalized}
            </ChipButton>
          </Stack>
        )}
      </>
    )
  );
};

export default AdditionalInfo;
