import { FC } from 'react';
import { Stack, useTheme } from '@mui/material';
import Avatar, { AvatarColors, AvatarShape, StyledAvatar } from './Avatar';
import { DesignSystemSize, TestProps } from '../../types';
import { testProps } from '../../utils/testProperties';
import { baseSizes } from '../../guidelines/Sizing/sizings';
import Text from '../Text';
import Tooltip from '../Tooltip';

export type AvatarStackProps = TestProps & {
  size?: DesignSystemSize;
  shape?: AvatarShape;
  items: {
    disabled?: boolean;
    color?: AvatarColors;
    personImageSrc?: string;
    personName?: string;
    personId: string;
  }[];
};

const MAX_ITEMS_IN_STACK = 4;

export const borderSizes: Record<DesignSystemSize, string> = {
  XL: '4px',
  L: '4px',
  M: '3px',
  S: '3px',
  XS: '2px',
  XXS: '2px',
};

export const AvatarStack: FC<AvatarStackProps> = ({ items, testId, size = 'M', shape = 'circle', ...commonProps }) => {
  const { palette } = useTheme();
  const hiddenItems = items.slice(MAX_ITEMS_IN_STACK);
  const hiddenItemsNames = hiddenItems
    .map((i) => i.personName?.replace(' ', '\u00A0'))
    .filter(Boolean)
    .join(', ');

  return (
    <Stack direction="row" {...testProps(testId, 'avatar-stack')}>
      {items.map((item, index) =>
        index >= MAX_ITEMS_IN_STACK ? null : (
          <Stack sx={{ width: `calc(${baseSizes[size]} * 1.8)` }} zIndex={1000 - index} key={item.personId}>
            <Avatar
              size={size}
              shape={shape}
              {...item}
              {...commonProps}
              sx={{ boxShadow: `${palette.background.default} 0px 0px 0px ${borderSizes[size]}` }}
            />
          </Stack>
        )
      )}
      {hiddenItems.length > 0 && (
        <Stack sx={{ width: `calc(${baseSizes[size]} * 1.8)` }} zIndex={1000 - items.length}>
          <Tooltip content={hiddenItemsNames} size={size} minified>
            <StyledAvatar
              $backgroundColor={palette.neutral.color4}
              $foregroundColor={palette.neutral.color2}
              $shape={shape}
              $size={size}
              sx={{ boxShadow: `${palette.neutral.color3} 0px 0px 0px 1px` }}
            >
              <Text variant="button" size={size} title={hiddenItemsNames}>
                +{items.length - MAX_ITEMS_IN_STACK}
              </Text>
            </StyledAvatar>
          </Tooltip>
        </Stack>
      )}
    </Stack>
  );
};
