import { forwardRef } from 'react';
import { styled } from '@mui/material';
import { ReportProblemOutlined } from '@mui/icons-material';

import IconWrapper from '@verticeone/design-system/src/components/IconWrapper';

const WarningIcon = styled(
  forwardRef<SVGSVGElement>((props, ref) => (
    <IconWrapper {...props} ref={ref} icon={ReportProblemOutlined} size="S" color="warning" />
  ))
)(({ theme }) => ({
  backgroundColor: theme.palette.warning.color2,
  borderRadius: '10%',
  color: theme.palette.warning.contrastText,
  padding: 2,
}));

export default WarningIcon;
