import { useMemo } from 'react';
import { orderBy, chain } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Theme, useTheme } from '@mui/material';
import { useAccountDepartments } from '@vertice/core/src/hooks/useAccountDepartments';
import { useAccountContractsStats } from '@vertice/core/src/hooks/useAccountContractsStats';
import { useDashboardContext } from '../DashboardContextProvider';

const THRESHOLD = 5;

export type PieChartItem = {
  title: string;
  color: string;
  value: number;
  currency: string;
};

export const useDepartmentChartData = () => {
  const { isLoading: departmentsLoading, data: departments } = useAccountDepartments();
  const { isLoading: contractsLoading, data: contracts } = useAccountContractsStats();

  const { t } = useTranslation();
  const theme = useTheme();

  const { contractCategory } = useDashboardContext();

  const data = useMemo(() => {
    if (!contracts || !departments) {
      return [];
    }
    const departmentLabels = chain(departments)
      .keyBy('departmentId')
      .mapValues(({ name }) => name)
      .value();

    const statsForCategory = contracts?.stats.categories[contractCategory];

    const departmentStats = orderBy(
      statsForCategory?.departments || [],
      [({ totalAnnualCost }) => totalAnnualCost?.amount],
      ['desc']
    );

    const items: PieChartItem[] = [];
    for (const { departmentId, totalAnnualCost } of departmentStats) {
      if (!departmentId || !totalAnnualCost) {
        continue;
      }

      if (items.length > THRESHOLD) {
        items[items.length - 1].value += totalAnnualCost.amount;
      } else {
        items.push({
          color: getColor(items.length, theme),
          title: items.length !== THRESHOLD ? departmentLabels[departmentId] || departmentId : t('DASHBOARD.OTHERS'),
          value: totalAnnualCost.amount,
          currency: totalAnnualCost.currency,
        });
      }
    }

    return items;
  }, [t, theme, contracts, departments, contractCategory]);

  return {
    isLoading: contractsLoading || departmentsLoading,
    data,
  };
};

const getColor = (index: number, { palette }: Theme) => {
  switch (index) {
    case 0:
      return palette.visualization.divergent.secondary['-30'];
    case 1:
      return palette.visualization.divergent.primary['-30'];
    case 2:
      return palette.visualization.divergent.primary['+30'];
    case 3:
      return palette.visualization.divergent.secondary['+30'];
    case 4:
      return palette.visualization.divergent.tertiary['-30'];
    default:
      return palette.core.color3;
  }
};
