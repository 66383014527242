import React from 'react';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { DesignSystemVariant, DesignSystemColor, DesignSystemSize } from '../../types';
import Tooltip from '../Tooltip';
import ChipButton from './ChipButton';
import styled from '@mui/material/styles/styled';

const AggregatorChipButton = styled(ChipButton)({
  flexShrink: 0,
});

type Props = {
  values: string[];
  size: DesignSystemSize;
  color?: DesignSystemColor;
  variant?: DesignSystemVariant;
  disabled?: boolean;
  sx?: SxProps<Theme>;
};

export const ValuesAggregatorChip = ({ values, size, color = 'neutral', variant = 'outline', disabled, sx }: Props) => {
  return (
    <Tooltip size={size} noArrow minified title={values.join(', ')}>
      <AggregatorChipButton size={size} color={color} variant={variant} disabled={disabled} sx={sx} isActive={false}>
        +{values.length}
      </AggregatorChipButton>
    </Tooltip>
  );
};
