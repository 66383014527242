import { useTranslation } from 'react-i18next';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-pro';

import { UserIdentity } from '@vertice/slices';
import { GridSelect } from '@verticeone/design-system/src/components/DataGrid';
import { SlackConnections } from '../../../common';

type SlackUserSelectCellProps = {
  connectedUsers?: UserIdentity[];
} & GridRenderCellParams<SlackConnections>;

export const SlackUserSelectCell = ({ connectedUsers, ...params }: SlackUserSelectCellProps) => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();

  const {
    row: { userId, slackUsers },
    field,
  } = params;

  const options = slackUsers
    .filter((user) => user.status === 'ACTIVE')
    .filter((user) => !connectedUsers?.some((connectedUser) => connectedUser.identityId === user.userId))
    .map((user) => {
      // TODO: Remove separator once the Select component supports groups
      return user.userId === 'SEPARATOR'
        ? { label: '--------------------', value: user.email }
        : { label: `${user.email} | ${user.userName}`, value: user.email };
    });

  return (
    <GridSelect
      options={options}
      onChange={(option) => {
        void apiRef.current.setEditCellValue({ id: userId, field, value: option?.value });
      }}
      isMulti={false}
      placeholder={t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.SLACK_SELECT_PLACEHOLDER')}
    />
  );
};
