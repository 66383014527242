import React from 'react';
import { Link } from 'react-router-dom';
import { Divider, Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { VerticeVIcon } from '@vertice/assets';
import Text from '@verticeone/design-system/src/components/Text';
import { Vendor } from '@vertice/slices';
import Card from '@verticeone/design-system/src/components/Card';
import VendorLogo from '@vertice/core/src/components/VendorLogo/VendorLogo';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { ContractCounts } from '../types';

const PRODUCTS_TEXT_LIMIT = 28;

type VendorProps = {
  vendor: Vendor;
  contractCounts?: ContractCounts;
  requestsCount?: number;
  addedProducts?: string[];
};

const BottomLink = styled(Link)(() => {
  return {
    display: 'flex',
    height: '50px',
    textDecoration: 'unset',
    color: 'unset',
    justifyContent: 'center',
    alignItems: 'center',
  };
});

const VendorItem: React.FC<VendorProps> = ({ vendor, contractCounts, requestsCount, addedProducts }) => {
  const { id, name, category } = vendor;
  const { t } = useTranslation();
  const routes = useRoutes();
  const { generatePath } = useRouteNavigate();

  let productsLength = 0;
  let products = '';
  const productsArr: string[] = addedProducts || vendor.products?.map((item) => item.name || '') || [];
  productsArr.every((productName: string, i) => {
    productsLength += productName?.length || 0;

    if (productsLength < PRODUCTS_TEXT_LIMIT) {
      products += productName ? (i > 0 ? ', ' : '') + productName : '';
      return true;
    }

    if (i === 0) {
      products = t('VENDORS.PRODUCT', { count: productsArr.length });
      return false;
    }

    products += ' ' + t('VENDORS.AND_MORE', { productsCount: productsArr.length - i });
    return false;
  });

  return (
    <Card sx={{ width: 202 }}>
      <Stack position="relative" p={4} gap={2} height={160}>
        <VendorLogo vendorId={id} size={40} />
        <Stack gap={1}>
          <Text variant="caption" size="M" color="text1">
            {name}
          </Text>
          <Text maxHeight="36px" overflow="hidden" variant="heading" size="XXS" color="text3">
            {products}
          </Text>
        </Stack>
        <Text position="absolute" bottom={10} variant="body-regular" size="XS" color="text3">
          {category}
        </Text>
      </Stack>
      <Divider />
      <BottomLink to={generatePath(routes.VENDORS.VENDOR.PATH, { id: id! })}>
        {!requestsCount && (!contractCounts || !Object.values(contractCounts).some(Boolean)) ? (
          <Text variant="body-bold" size="S">
            {t('VENDORS.EXPLORE')}
          </Text>
        ) : (
          <Stack direction="row" alignItems="center" gap={1.25}>
            <VerticeVIcon />
            <Stack alignItems="center">
              {!!contractCounts?.existing && (
                <Text variant="body-bold" size="XS">
                  {t('VENDORS.EXISTING', { count: contractCounts.existing })}
                </Text>
              )}
              {!!contractCounts?.purchaseInProgress && (
                <Text variant="body-bold" size="XS">
                  {t('VENDORS.PURCHASE_IN_PROGRESS', { count: contractCounts.purchaseInProgress })}
                </Text>
              )}
              {!!requestsCount && (
                <Text variant="body-bold" size="XS">
                  {t('VENDORS.REQUEST_IN_PROGRESS', { count: requestsCount })}
                </Text>
              )}
            </Stack>
          </Stack>
        )}
      </BottomLink>
    </Card>
  );
};

export default VendorItem;
