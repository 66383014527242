import { css } from '@mui/material';
import { DesignSystemSize } from '../../types';

export const tableCellSpacing: Record<DesignSystemSize, { height: number; padding: number }> = {
  XL: {
    height: 15,
    padding: 5,
  },
  L: {
    height: 13.5,
    padding: 4.5,
  },
  M: {
    height: 12,
    padding: 4,
  },
  S: {
    height: 10.5,
    padding: 3.5,
  },
  XS: {
    height: 9,
    padding: 3,
  },
  XXS: {
    height: 7.5,
    padding: 2.5,
  },
};

export const tableContainerBase = css(({ theme }) => ({}));
