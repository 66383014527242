import React from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Radio, Stack } from '@mui/material';
import { USER_ROLES, UserRoleType } from '@vertice/core/src/constants/userRoles';
import clsx from 'clsx';
import IconBullet, { BulletType, TypeOfBulletType } from '../IconBullet';
import Text from '@verticeone/design-system/src/components/Text';
import styles from './RoleSelector.module.scss';

interface RoleSelectorWithRolesProps {
  roles: UserRoleType[];
  selected?: UserRoleType | null;
  disabled?: boolean;
  onRoleChanged?: (value: UserRoleType) => void;
}

const RoleSelectorWithRoles: React.FC<RoleSelectorWithRolesProps> = ({
  roles,
  selected,
  disabled = false,
  onRoleChanged,
}) => {
  const { t } = useTranslation();

  const handleChange = (value: string) => () => {
    if (!disabled && onRoleChanged) {
      onRoleChanged(value as UserRoleType);
    }
  };

  const adminDetails = [
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.ADMIN.DESCRIPTION_1') },
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.ADMIN.DESCRIPTION_2') },
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.ADMIN.DESCRIPTION_3') },
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.ADMIN.DESCRIPTION_4') },
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.ADMIN.DESCRIPTION_5') },
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.ADMIN.DESCRIPTION_6') },
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.ADMIN.DESCRIPTION_7') },
  ];
  const powerUserDetails = [
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.POWER_USER.DESCRIPTION_1') },
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.POWER_USER.DESCRIPTION_2') },
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.POWER_USER.DESCRIPTION_3') },
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.POWER_USER.DESCRIPTION_4') },
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.POWER_USER.DESCRIPTION_5') },
  ];
  const userDetails = [
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.USER.DESCRIPTION_1') },
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.USER.DESCRIPTION_2') },
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.USER.DESCRIPTION_3') },
  ];

  const roleDetails = {
    [USER_ROLES.admin]: adminDetails,
    [USER_ROLES.powerUser]: powerUserDetails,
    [USER_ROLES.user]: userDetails,
  };

  const roleTitles = {
    [USER_ROLES.admin]: t('PREFERENCES.NEW_USER.ROLES_V2.ADMIN.TITLE'),
    [USER_ROLES.powerUser]: t('PREFERENCES.NEW_USER.ROLES_V2.POWER_USER.TITLE'),
    [USER_ROLES.user]: t('PREFERENCES.NEW_USER.ROLES_V2.USER.TITLE'),
  };

  return (
    <Stack className={styles['role-selector']}>
      {roles.map((role: UserRoleType) => (
        <Accordion key={role} disableGutters expanded onChange={handleChange(role)}>
          <AccordionSummary expandIcon={null}>
            <FormControlLabel
              className={styles['role-selector-item']}
              value={role}
              onChange={handleChange(role)}
              onClick={(event) => {
                event.stopPropagation();
              }}
              data-test-id="roleLabel"
              disabled={disabled}
              control={
                <Radio
                  checked={selected === role}
                  name="role"
                  className={clsx(styles['role-selector-radio'], {
                    [styles['role-selector-radio--disabled']]: disabled,
                  })}
                />
              }
              label={
                <Text variant="button" size="M" color={disabled ? 'text3' : 'text2'}>
                  {roleTitles[role]}
                </Text>
              }
            />
          </AccordionSummary>
          <AccordionDetails>
            <ul className={styles['role-selector-description']}>
              {role &&
                roleDetails[role].map((detail, index) => (
                  <li key={role + index} className={styles['role-selector-description-line']}>
                    <IconBullet type={detail.icon as TypeOfBulletType} size={24} />
                    <Text variant="body-regular" size="M">
                      {detail.text}
                    </Text>
                  </li>
                ))}
            </ul>
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  );
};

export default RoleSelectorWithRoles;
