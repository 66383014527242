import React from 'react';
import { useController } from 'react-hook-form';
import { Stack, useTheme } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import Checkbox, { CheckboxProps } from '@verticeone/design-system/src/components/Checkbox';
import FormControlLabel from '@verticeone/design-system/src/components/FormControlLabel';

export type FormChecklistItemFieldProps = CheckboxProps & {
  name: string;
  label?: string;
  description?: string;
  required?: boolean;
};

const FormChecklistItemField = ({
  required,
  name,
  label,
  description,
  size = 'M',
  ...otherProps
}: FormChecklistItemFieldProps) => {
  const {
    field: { ref, value, ...field },
  } = useController({ name, rules: { required } });

  const { palette } = useTheme();

  return (
    <FormControlLabel
      checked={value ?? false}
      control={<Checkbox inputRef={ref} size={size} {...field} {...otherProps} />}
      label={
        <Stack gap="2px">
          <Text variant="button" size={size}>
            {label}
          </Text>
          <Text variant="body-regular" size={size} color={palette.text.color3}>
            {description}
          </Text>
        </Stack>
      }
    />
  );
};

export default FormChecklistItemField;
