import { useEffect } from 'react';
import smartlookClient from 'smartlook-client';
import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import { useAuthentication } from '@verticeone/auth/src';

interface useSmartlookProps {
  projectKey?: string;
  enable: boolean;
}

const useSmartlook = ({ projectKey, enable }: useSmartlookProps) => {
  const { user } = useAuthentication();
  const { accountType, accountId } = useSelector(getAccount);

  useEffect(() => {
    if (enable && projectKey) {
      if (
        !smartlookClient.initialized() &&
        accountId &&
        accountType !== 'DEMO' &&
        user?.email &&
        !user.email.endsWith('@vertice.one')
      ) {
        smartlookClient.init(projectKey, { cookies: false }); // See https://help.smartlook.com/docs/cookies-smartlook
        smartlookClient.identify(user.userId, { accountId, userId: user.userId });
      }
    }
  }, [projectKey, enable, accountType, user, accountId]);
};

export default useSmartlook;
