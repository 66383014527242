import React, { useId, useMemo, useState } from 'react';
import Card, {
  CardHeader,
  CardHeaderDescription,
  CardHeaderTitle,
} from '@verticeone/design-system/src/components/Card';
import { useTranslation } from 'react-i18next';
import CardDivider from '../shared/CardDivider';
import ScoreBar from './ScoreBar/ScoreBar';
import OptimizationTestsTable from './OptimizationTestsTable';
import { useTestStats } from '../../hooks/useTestStats';
import { getMergedLoadingStatus } from '@vertice/utils/src/loadableAdvanced';
import { LoadableContentWrapper } from '../../components/LoadableContentWrapper';
import useOptimizationTestsTableSearchParamsStateStore from './OptimizationTestsTable/useOptimizationTestsTableSearchParamsStateStore';
import { useOptimizationTestsData } from './OptimizationTestsTable/useOptimizationTestsData';
import Stack from '@mui/material/Stack';
import ToggleSwitch from '@verticeone/design-system/src/components/ToggleSwitch';
import Text from '@verticeone/design-system/src/components/Text';
import { testProps } from '@verticeone/design-system/src/utils/testProperties';

const OptimizationTestsCard = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.OPTIMIZATION_TESTS' });
  const [showPendingTests, setShowPendingTests] = useState(true);
  const togglePendingTestsId = useId();
  const togglePeriodId = useId();
  const [isAnnualPeriod, setIsAnnualPeriod] = useState(true);

  const testStatsQuery = useTestStats();
  const optimizationTestsQuery = useOptimizationTestsData();

  const loadingStatus = getMergedLoadingStatus(testStatsQuery, optimizationTestsQuery);
  const tableStateStore = useOptimizationTestsTableSearchParamsStateStore();

  const data = useMemo(
    () =>
      showPendingTests
        ? optimizationTestsQuery.data
        : optimizationTestsQuery.data?.filter((test) => test.type !== 'pending'),
    [optimizationTestsQuery.data, showPendingTests]
  );

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle text={t('TITLE')} tag="h2" />
        <CardHeaderDescription>{t('DESCRIPTION')}</CardHeaderDescription>
      </CardHeader>
      <LoadableContentWrapper {...loadingStatus}>
        <ScoreBar testStats={testStatsQuery.data!} isAnnualPeriod={isAnnualPeriod} />
        <Stack
          paddingLeft={6}
          paddingBottom={6}
          paddingRight={6}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
        >
          <Stack gap={2} alignItems="center" direction="row">
            <ToggleSwitch
              id={togglePeriodId}
              size="S"
              color="tertiary"
              checked={isAnnualPeriod}
              onChange={() => setIsAnnualPeriod(!isAnnualPeriod)}
              {...testProps('monthly-annual', 'switch')}
            />
            <Text variant="body-regular" tag="label" htmlFor={togglePeriodId}>
              {t('FILTER.PERIOD')}
            </Text>
          </Stack>
          <Stack gap={2} alignItems="center" direction="row">
            <ToggleSwitch
              id={togglePendingTestsId}
              size="S"
              color="tertiary"
              checked={showPendingTests}
              onChange={() => setShowPendingTests(!showPendingTests)}
              {...testProps('pending-tests', 'switch')}
            />
            <Text variant="body-regular" tag="label" htmlFor={togglePendingTestsId}>
              {t('FILTER.SHOW_PENDING_TESTS')}
            </Text>
          </Stack>
        </Stack>
        <CardDivider />
        <OptimizationTestsTable
          data={data!}
          state={tableStateStore.state}
          onStateChange={tableStateStore.onStateChange}
          isAnnualPeriod={isAnnualPeriod}
        />
      </LoadableContentWrapper>
    </Card>
  );
};

export default OptimizationTestsCard;
