import React, { useMemo } from 'react';
import { Stack, useTheme } from '@mui/material';
import ScoreBarChart from './ScoreBarChart';
import ScoreBarStats from './ScoreBarStats';
import { TestStats } from 'modules/cloud/hooks/useTestStats';
import { AWS_BRAND_COLOR } from '../../../constants';

const ScoreBar = ({ testStats, isAnnualPeriod }: { testStats: TestStats; isAnnualPeriod?: boolean }) => {
  const { palette } = useTheme();

  const scoreBarData = useMemo(
    () =>
      testStats
        ? [
            { name: 'successful', value: testStats.passed, color: palette.core.color4 },
            { name: 'failed', value: testStats.failed, color: palette[AWS_BRAND_COLOR].color2 },
            { name: 'not-run', value: testStats.notRun, color: palette.core.color2 },
          ]
        : null,
    [testStats, palette]
  );
  const dataAvailable = Boolean(testStats?.passed || testStats?.failed || testStats?.notRun);

  return testStats && scoreBarData ? (
    <Stack py={6}>
      <ScoreBarStats
        isAnnualPeriod={isAnnualPeriod}
        passed={testStats.passed}
        failed={testStats.failed}
        notRun={testStats.notRun}
      />
      {dataAvailable && <ScoreBarChart values={scoreBarData} />}
    </Stack>
  ) : null;
};

export default ScoreBar;
