import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadableContentWrapper } from '../../components/LoadableContentWrapper';
import useEC2ProcessorUsageData from './useEC2ProcessorUsageData';
import EC2ProcessorUsageGraph from './EC2ProcessorUsageGraph';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import Card, {
  CardHeader,
  CardHeaderSubtitle,
  CardHeaderTitle,
  CardHeaderDescription,
} from '@verticeone/design-system/src/components/Card';
import { CalendarTodayOutlined } from '@mui/icons-material';

type EC2ProcessorUsageCardProps = {
  sx?: SxProps<Theme>;
};

const EC2ProcessorUsageCard = (props: EC2ProcessorUsageCardProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { data, ...loadingStatus } = useEC2ProcessorUsageData();

  return (
    <Card {...props}>
      <CardHeader size="S" noDivider>
        <CardHeaderTitle text={t('EC2_PROCESSOR_USAGE.TITLE')} tag="h2" />
        <CardHeaderSubtitle text={t('LAST_MONTH')} icon={CalendarTodayOutlined} />
        <CardHeaderDescription>{t('EC2_PROCESSOR_USAGE.DESCRIPTION')}</CardHeaderDescription>
      </CardHeader>
      <LoadableContentWrapper {...loadingStatus} pb={6}>
        <EC2ProcessorUsageGraph data={data!} />
      </LoadableContentWrapper>
    </Card>
  );
};

export default EC2ProcessorUsageCard;
