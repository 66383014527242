import LoadableAdvanced from '@vertice/utils/src/loadableAdvanced';
import useRecommendationsData, { Recommendation } from '../../../hooks/useRecommendationsData';
import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices';
import {
  MonitoringValue,
  useLatestOptimizationTestsQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import testsByCodeRaw from '@vertice/i18n/src/i18n/cloud/testsByCode_en.json';
import { formatResult } from './resultFormatting/formatResult';
import { sumBy, some, chain, maxBy } from 'lodash';
import { FormattedResult } from './resultFormatting/types';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';

const testsByCode = testsByCodeRaw as Record<string, TestJsonRecord>;

type TestJsonRecord = {
  CODE: string;
  NAME: string;
  DESCRIPTION?: string;
};

type OptimizationTestRowBase = {
  code: string;
  name: string;
  description?: string;
};

export type ImplementedOptimizationTestRow = OptimizationTestRowBase & {
  type: 'implemented';
  breaching: boolean;
  category: string;
  result: FormattedResult;
  recommendations: Recommendation[];
  recommendationsValue: number;
  recommendationsCount: number;
};

export type PendingOptimizationTestRow = OptimizationTestRowBase & {
  type: 'pending';
};

export type OptimizationTestRow = ImplementedOptimizationTestRow | PendingOptimizationTestRow;

type TestCode = keyof typeof testsByCode;

/** Fetches optimization tests and transforms it into a form appropriate for use in DataGrid. */
export const useOptimizationTestsData = (): LoadableAdvanced<OptimizationTestRow[]> => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.OPTIMIZATION_TESTS.RESULTS' });

  const recommendations = useRecommendationsData();

  const { accountId } = useSelector(getAccount);
  const { locale } = useLocaleContext();

  const { data: rawData, error: optimizationTestsError } = useLatestOptimizationTestsQuery({
    accountId: accountId!,
  });

  return useMemo(() => {
    const error = recommendations.error || optimizationTestsError;
    if (!rawData || rawData.monitoringLatestQuery?.__typename !== 'MonitoringResult') {
      return {
        isLoading: true,
        error,
        isEmpty: false,
      };
    }

    const transformedTranslationsData = Object.values(testsByCode).map((item) => ({
      code: item.CODE,
      name: item.NAME,
      ...(item.DESCRIPTION
        ? {
            description: item.DESCRIPTION,
          }
        : {}),
    }));
    const dataTestsByCode: Record<TestCode, OptimizationTestRow> = chain(transformedTranslationsData)
      .map((row) => ({
        ...row,
        type: 'pending' as const,
      }))
      .keyBy('code')
      .value();

    let items = rawData.monitoringLatestQuery?.items;

    items?.forEach((item) => {
      const textationItem = dataTestsByCode[item.code];
      const recommendationsForTheTest = recommendations.byTestCode?.[item.code] ?? [];
      const isBreaching: boolean =
        (item.breaching && some(recommendationsForTheTest, ({ breaching }) => breaching)) ?? false;

      const optimizationTestRow: OptimizationTestRow = {
        type: 'implemented',
        breaching: isBreaching,
        code: item.code,
        category: item.tags?.find(([key]) => key === 'Category')?.[1] ?? '',
        name: textationItem?.name ?? item.code,
        description: textationItem?.description,
        result: formatResult(item as MonitoringValue, isBreaching, { locale, t }),
        recommendationsCount: sumBy(recommendationsForTheTest, ({ breaching }) => (breaching ? 1 : 0)),
        recommendationsValue: maxBy(recommendationsForTheTest, ({ saving }) => saving)?.saving ?? 0,
        recommendations: recommendationsForTheTest,
      };

      dataTestsByCode[optimizationTestRow.code] = optimizationTestRow;
    });

    const data = Object.values(dataTestsByCode);

    return {
      isLoading: false,
      error,
      isEmpty: data.length === 0,
      data,
    };
  }, [recommendations.error, recommendations.byTestCode, optimizationTestsError, rawData, locale, t]);
};
