import React, { ReactNode, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import Button from '@verticeone/design-system/src/components/Button';
import Text from '@verticeone/design-system/src/components/Text';

import { useSimpleDialogContext } from '@verticeone/design-system/src/components/Dialog/SimpleDialog';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import {
  ServiceCatalogResource,
  useUpdateServiceCatalogServiceResourceMutation,
} from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { WorkflowVersion } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useIsDefaultWorkflow } from './utils';

type UseSetAsDefaultProps = {
  activeVersion?: WorkflowVersion;
  service?: ServiceCatalogResource;
};

const ConfirmationWorkflowName = ({ children }: { children?: ReactNode }) => {
  return (
    <Stack paddingY={2}>
      <Text variant="body-bold">{children}</Text>
    </Stack>
  );
};

export const SetAsDefaultButton = ({ activeVersion, service }: UseSetAsDefaultProps) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const { getConfirmation } = useSimpleDialogContext();
  const isDefault = useIsDefaultWorkflow(activeVersion, service);

  const isDisabled = activeVersion === undefined || service === undefined;

  const confirmationDescription = useMemo(
    () => (
      <Stack gap={8}>
        <Text variant="body-regular">
          {t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.SET_AS_DEFAULT_CONFIRMATION.DESCRIPTION')}
        </Text>
        <Stack textAlign="center" style={{ lineHeight: 0.5 }}>
          <Text variant="body-regular">
            <Trans
              i18nKey="INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.SET_AS_DEFAULT_CONFIRMATION.DESCRIPTION_QUESTION"
              components={{ workflow: <ConfirmationWorkflowName /> }}
              values={{
                workflowName: activeVersion?.workflowName,
                requestTypeName: service?.name,
              }}
            />
          </Text>
        </Stack>
      </Stack>
    ),
    [activeVersion?.workflowName, service?.name, t]
  );

  const [updateService, { isLoading: isUpdatingService }] = useUpdateServiceCatalogServiceResourceMutation();

  const handleSetAsDefault = async () => {
    const confirmResult = await getConfirmation({
      title: t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.SET_AS_DEFAULT_CONFIRMATION.TITLE', {
        requestTypeName: service?.name,
      }),
      description: confirmationDescription,
      okButton: {
        label: t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.SET_AS_DEFAULT_CONFIRMATION.CONFIRM'),
        color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
      },
    });

    if (confirmResult.status === 'cancel') {
      return;
    }

    if (service !== undefined && activeVersion !== undefined) {
      void updateService({
        accountId,
        resourceUrn: service.urn,
        updateServiceCatalogResourceService: {
          handlerUrn: activeVersion.workflowRef,
        },
      });
    }
  };

  if (isDefault) {
    return null;
  }

  return (
    <Button
      variant="solid"
      size="S"
      color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
      onClick={handleSetAsDefault}
      disabled={isDisabled || isUpdatingService}
      isLoading={isUpdatingService}
    >
      {t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.ACTIONS.SET_AS_DEFAULT')}
    </Button>
  );
};
