import React, { useMemo } from 'react';
import { Stack, useTheme } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import { LoadableTextComponent } from '@vertice/core/src/components/LoadableTextComponent';
import { AWS_BRAND_COLOR } from '../../../constants';

type StatsItemProps = {
  title: string;
  value: number | string;
  colorVariant: 'highlighted' | 'neutral';
  valueSize: 'M' | 'L';
  isLoading?: boolean;
};

const StatsItem = ({ title, value, colorVariant, valueSize, isLoading = false, ...otherProps }: StatsItemProps) => {
  const { palette } = useTheme();

  const colorMap = useMemo(
    () => ({
      highlighted: {
        title: palette[AWS_BRAND_COLOR].color1,
        value: palette[AWS_BRAND_COLOR].color1,
      },
      neutral: {
        title: palette.text.color2,
        value: palette.text.color1,
      },
    }),
    [palette]
  );

  return (
    <Stack textAlign="start" flexGrow={1} {...otherProps}>
      <Text variant="body-bold" size="S" color={colorMap[colorVariant].title} data-type="title">
        {title}
      </Text>
      <LoadableTextComponent isLoading={isLoading}>
        <Text variant="heading" size={valueSize} color={colorMap[colorVariant].value} data-type="value">
          {value}
        </Text>
      </LoadableTextComponent>
    </Stack>
  );
};

export default StatsItem;
