import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Stack } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import Loader from '@verticeone/design-system/src/components/Loader';
import { useTranslation } from 'react-i18next';
import { useListVendorProductsQuery } from '@vertice/slices';
import VendorInfoCard from '../../modules/saas/vendor/cards/VendorInfoCard/VendorInfoCard';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import VendorPageHeader from './VendorPageHeader';
import VendorDiligenceInsights from '@vertice/core/src/modules/vendor/VendorDiligenceInsights/VendorDiligenceInsights';
import VendorContractsCard from '../../modules/saas/vendor/cards/VendorContractsCard';
import VendorPricingCard from '../../modules/saas/vendor/cards/VendorPricingCard';
import { FEATURES } from '@vertice/core/src/modules/features/constants';

function Vendor() {
  const { t } = useTranslation();
  const { id } = useParams();
  const vendorId = id!;
  const { accountId } = useAccountContext();

  const { isEnabled } = useFeatures();
  const isVendorLegalEnabled = isEnabled(FEATURES.VENDOR_LEGAL);

  const { data: { vendor } = {}, isLoading: isLoadingVendorProducts } = useListVendorProductsQuery(
    { vendorId },
    { skip: !vendorId }
  );

  if (isLoadingVendorProducts) {
    return <Loader />;
  }

  if (!vendor) {
    return (
      <Text variant="heading" size="XS" gutterBottom display="inline">
        {t('VENDOR.NO_VENDOR')}
      </Text>
    );
  }

  return (
    <Stack p={10} gap={7}>
      <VendorPageHeader vendorId={vendorId} vendorName={vendor.name} vendorCategory={vendor.category ?? undefined} />

      {vendorId && <VendorContractsCard vendorId={vendorId} />}

      <Grid container alignItems="flex-start">
        <Grid item xs={isVendorLegalEnabled ? 8 : 12} pr={isVendorLegalEnabled ? '40px' : '0px'}>
          <Grid container gap={7}>
            <Grid item xs={isVendorLegalEnabled ? 12 : 4}>
              <VendorInfoCard
                description={vendor.description}
                yearFounded={vendor.yearFounded}
                hq={vendor.hq}
                website={vendor.website}
              />
            </Grid>
            <Grid item xs={isVendorLegalEnabled ? 12 : 8} pl={isVendorLegalEnabled ? '0px' : '40px'}>
              <VendorPricingCard
                simplicity={vendor.simplicityRating}
                transparency={vendor.transparencyRating}
                parity={vendor.parityRating}
              />
            </Grid>
          </Grid>
        </Grid>
        {isVendorLegalEnabled && (
          <Grid item xs={4}>
            <VendorDiligenceInsights accountId={accountId!} vendorId={vendorId} />
          </Grid>
        )}
      </Grid>
    </Stack>
  );
}

export default Vendor;
