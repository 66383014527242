import React from 'react';
import { CloseOutlined } from '@mui/icons-material';
import IconButton from '../IconButton';
import { DesignSystemSize } from '../../types';

type CardCloseButtonProps = {
  onClick?: () => void;
  size?: DesignSystemSize;
};

export const CardCloseButton: React.FC<CardCloseButtonProps> = ({ onClick, size = 'M' }) => (
  <IconButton size={size} variant="ghost" icon={CloseOutlined} onClick={onClick} />
);
