import React from 'react';
import { GridSelect } from '@verticeone/design-system/src/components/DataGrid';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import useUserSelectOptions from './useUserSelectOptions';
import { User } from '../types';

const OwnerEditCell = ({ id, field, value, row }: GridRenderCellParams) => {
  const apiRef = useGridApiContext();
  const filteredUsers = useUserSelectOptions({ userIdsToExclude: row.watchers.map((user: User) => user.userId) });

  return (
    <GridSelect
      value={value}
      options={filteredUsers}
      isMulti={false}
      isClearable={true}
      getOptionValue={(option) => (option as User).userId}
      getOptionLabel={(option) => (option as User).name}
      onChange={(newValue) => {
        void apiRef.current.setEditCellValue({ id, field, value: newValue });
      }}
    />
  );
};

export default OwnerEditCell;
