import { useEffect, RefObject, useCallback, useState } from 'react';

export type UseScrollElement<T extends HTMLElement> =
  | {
      element: RefObject<T>;
      container: RefObject<T>;
      condition: boolean;
    }
  | {
      element?: undefined;
      container: RefObject<T>;
      condition: boolean;
    };

/**
 * useScrollElement
 * @description This hook can be used to either scroll to the end of a container when a condition is met
 * or to scroll to a specific element within a container when a condition is met.
 * @param UseScrollElement<T> { container, element, condition }
 * @returns isScrolledToBottom: boolean, scrollToBottom: () => void
 */
export const useScrollElement = <T extends HTMLElement>({ container, element, condition }: UseScrollElement<T>) => {
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);

  const scrollToBottom = useCallback(
    (behavior?: ScrollBehavior) => {
      if (!container.current) return;

      const commentsContainerHeight = container.current.clientHeight;
      const commentsContainerScrollHeight = container.current.scrollHeight;

      if (element?.current) {
        const offset = element?.current.offsetTop;

        container.current.scroll({
          behavior,
          top: commentsContainerScrollHeight + offset,
        });
      } else {
        container.current.scroll({
          behavior,
          top: commentsContainerScrollHeight - commentsContainerHeight,
        });
      }
    },
    [container, element]
  );

  useEffect(() => {
    if (!condition) return;

    scrollToBottom();
  }, [scrollToBottom, condition]);

  useEffect(() => {
    const ref = container.current;
    if (!ref) return;

    const handleScroll = () => {
      if (!ref) return;

      setScrollTop(ref.scrollTop + ref.clientHeight);
      setScrollHeight(ref.scrollHeight);
    };

    ref.addEventListener('scroll', handleScroll);

    return () => {
      ref.removeEventListener('scroll', handleScroll);
    };
  }, [container, element]);

  return {
    isScrolledToBottom: scrollTop === scrollHeight,
    scrollToBottom,
  };
};
