import { styled } from '@mui/material';
import { Stage, stageColor } from '@vertice/core/src/modules/saas/schedule/components/ScheduleView/useScheduleView';

const StageIndicator = styled('div')<{ stage: Stage }>(({ stage }) => ({
  width: '11px',
  height: '11px',
  marginRight: '8px',
  borderRadius: '3px',
  backgroundColor: stageColor(stage),
}));

export default StageIndicator;
