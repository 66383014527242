import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { useCallback } from 'react';

export type FormatNumberOptions = {
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  locale: string;
};

export const formatNumber = (
  value: number,
  { minimumFractionDigits, maximumFractionDigits, locale }: FormatNumberOptions
) =>
  new Intl.NumberFormat(locale, {
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value);

export const useFormatNumber = () => {
  const { locale } = useLocaleContext();
  return useCallback(
    (value: number, options: Omit<FormatNumberOptions, 'locale'> = {}) => formatNumber(value, { ...options, locale }),
    [locale]
  );
};
