import React from 'react';
import Button, { ButtonProps, DefaultComponentType } from '../Button';
import ChevronRight from '@mui/icons-material/ChevronRightTwoTone';

type Props<C extends React.ElementType = DefaultComponentType> = Omit<
  ButtonProps<C>,
  'color' | 'size' | 'variant' | 'children'
> & {
  text: React.ReactNode;
};

const NoDataButton = ({ text, onClick, disabled = false }: Props) => (
  <Button color="neutral" size="M" onClick={onClick} variant="solid" disabled={disabled}>
    {text}
    <ChevronRight opacity={0.6} />
  </Button>
);

export default NoDataButton;
