import { TagAssignment, useLazyListTagAssignmentsPaginatedQuery } from '@vertice/slices';
import { getUserRef } from '../modules/saas/contract/utils/refUtils';
import {
  AllPowerUsersApprovalConfig,
  SingleApproverApprovalConfig,
  ThresholdBasedApprovalConfig,
  useLazyGetAccountConfigQuery,
} from '@vertice/slices/src/openapi/codegen/contractWorkflowsV2Api';
import { useAccountContext } from '../contexts/AccountContext';
import { isContractTagAssignment, isOwnersDepartmentTagAssignment } from '../modules/saas/contract/utils/tagUtils';

type UserAssignments = {
  departmentOwner: boolean;
  contractOwner: boolean;
  contractApprover: boolean;
};

export type UseUserAssignmentsReturn = {
  getUserAssignments: (userId: string) => Promise<UserAssignments>;
};

type ApprovalConfiguration = AllPowerUsersApprovalConfig | SingleApproverApprovalConfig | ThresholdBasedApprovalConfig;

const isUserApprover = (userId: string, approvalConfiguration?: ApprovalConfiguration) => {
  if (!approvalConfiguration) {
    return false;
  }

  if (approvalConfiguration.type === 'SingleApproverApprovalConfig') {
    return approvalConfiguration.approver === userId;
  }

  if (approvalConfiguration.type === 'ThresholdBasedApprovalConfig') {
    return approvalConfiguration.approvers.some((approver) => approver.userId === userId);
  }

  return false;
};

const isUserContractOwner = (tagAssignments?: TagAssignment[]) => {
  if (!tagAssignments) {
    return false;
  }

  return tagAssignments.some((tagAssignment) => {
    return isContractTagAssignment(tagAssignment);
  });
};

const isUserDepartmentOwner = (tagAssignments?: TagAssignment[]) => {
  if (!tagAssignments) {
    return false;
  }

  return tagAssignments.some((tagAssignment) => {
    return isOwnersDepartmentTagAssignment(tagAssignment);
  });
};

const useUserAssignments = (): UseUserAssignmentsReturn => {
  const { accountId } = useAccountContext();
  const [getAccountConfig] = useLazyGetAccountConfigQuery();
  const [listTagAssignments] = useLazyListTagAssignmentsPaginatedQuery();

  const getUserAssignments = async (userId: string) => {
    const accountConfigQuery = getAccountConfig({ accountId });
    const userTagAssignmentsQuery = listTagAssignments({
      accountId: accountId,
      ref: getUserRef(userId),
    });

    const [accountConfig, userTagAssignments] = await Promise.all([accountConfigQuery, userTagAssignmentsQuery]);

    return {
      contractOwner: isUserContractOwner(userTagAssignments.data?.tagAssignments),
      departmentOwner: isUserDepartmentOwner(userTagAssignments.data?.tagAssignments),
      contractApprover: isUserApprover(userId, accountConfig.data?.config?.approvalConfiguration),
    };
  };

  return {
    getUserAssignments,
  };
};

export default useUserAssignments;
