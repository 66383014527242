import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridColDef,
  GridRenderCellParams,
  GridSortItem,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import DataGrid from '@vertice/components/src/DataGrid';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Text from '@verticeone/design-system/src/components/Text';
import Button from '@verticeone/design-system/src/components/Button';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@verticeone/design-system/src/components/Table';
import { Stack, styled, useTheme } from '@mui/material';
import ChipButton from '@verticeone/design-system/src/components/ChipButton';
import Tooltip from '@verticeone/design-system/src/components/Tooltip';
import { AWS_BRAND_COLOR, AWS_DEFAULT_CURRENCY } from '../../../constants';
import DetailPanelToggle from './DetailPanelToggle';
import IconWrapper from '@verticeone/design-system/src/components/IconWrapper/IconWrapper';
import ChevronRightIcon from '@mui/icons-material/ArrowForwardIos';
import { isNil } from 'lodash';
import { generatePath, Link } from 'react-router-dom';
import { ROUTES } from '@vertice/utils/src/constants/cfa/constants';
import useInitialScroll from '@verticeone/design-system/src/components/DataGrid/useInitialScroll';
import testCodeComparator from '../../../utils/testCodeComparator';
import {
  ImplementedOptimizationTestRow,
  OptimizationTestRow,
  PendingOptimizationTestRow,
} from './useOptimizationTestsData';
import { useCloudContext } from '../../../CloudContext';
import { useFormatCurrency } from '@vertice/core/src/utils/formatting/currency';
import { STATUS_CHIP } from './constants';
import { RecommendationStatus } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';

const CellRenderer = ({ value }: GridRenderCellParams<any, any, any>) => (
  <Text variant="body-regular" size="M" title={value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
    {value ?? '-'}
  </Text>
);

const ObfuscatedCellRenderer = ({ value }: GridRenderCellParams<any, any, any>) => (
  <Text
    variant="body-regular"
    size="M"
    title={value}
    sx={{ overflow: 'hidden', textOverflow: 'ellipsis', filter: 'blur(6px)' }}
  >
    {value}
  </Text>
);

const SpacerCell = styled(TableCell)(({ theme: { palette } }) => ({
  width: 43,
  '&&&': {
    borderRightColor: palette.core.color2,
  },
}));

const CellCaption = (props: { children: ReactNode }) => {
  const { palette } = useTheme();
  return <Text variant="caption" size="S" tag="div" color={palette.text.color3} {...props} />;
};

const EffortChip = ({ effort }: { effort?: string }) => {
  const { palette } = useTheme();

  const colorByEffort: { [key: string]: string } = {
    Low: palette.global.color.magenta['50'].main,
    Medium: palette.global.color.magenta['70'].main,
    High: palette.global.color.magenta['90'].main,
  } as const;

  return effort ? (
    <ChipButton
      isActive={false}
      size="S"
      variant="solid"
      color="success"
      sx={{
        backgroundColor: colorByEffort[effort],
      }}
    >
      {effort}
    </ChipButton>
  ) : null;
};

const LAST_COLUMN_WIDTH = 200;
const RECOMMENDATIONS_COUNT_WIDTH = 180;

const ImplementedTestDetailPanel = ({
  row,
  isAnnualPeriod,
}: {
  row: ImplementedOptimizationTestRow;
  isAnnualPeriod?: boolean;
}) => {
  const { palette } = useTheme();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.OPTIMIZATION_TESTS.COLUMNS' });
  const formatCurrency = useFormatCurrency();

  return (
    <TableContainer
      sx={{
        backgroundColor: palette.input.color1,
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderRadius: 0,
      }}
    >
      <Table size="M" color="neutral">
        <TableBody>
          <TableRow>
            <SpacerCell />
            <TableCell sx={{ padding: '16px 12px', height: 'auto' }} colSpan={2}>
              <CellCaption>{t('DESCRIPTION')}</CellCaption>
              <Text variant="body-regular" size="M" tag="div" whiteSpace="pre-line">
                {row.description}
              </Text>
            </TableCell>
            <TableCell sx={{ textAlign: 'right', width: `${LAST_COLUMN_WIDTH}px` }}>
              <Stack spacing={1} alignItems="end">
                <CellCaption>{t('TEST_RESULT')}</CellCaption>
                <Tooltip size="S" content={row.result.tooltip}>
                  <ChipButton color={row.result.color} isActive={false} variant="ghost">
                    {row.result.value}
                  </ChipButton>
                </Tooltip>
              </Stack>
            </TableCell>
          </TableRow>
          {row.recommendations.map(({ code, name, saving, status, effort }) => (
            <TableRow key={code}>
              <SpacerCell />
              <TableCell sx={{ padding: '16px', height: 'auto' }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Stack>
                    <CellCaption>{t('RECOMMENDATION')}</CellCaption>
                    <Text variant="body-regular" size="M" tag="div">
                      {name}
                    </Text>
                  </Stack>
                  <Stack>
                    <EffortChip effort={effort} />
                  </Stack>
                </Stack>
              </TableCell>
              <TableCell
                sx={{ padding: '16px', height: 'auto', width: RECOMMENDATIONS_COUNT_WIDTH, textAlign: 'right' }}
              >
                {status && status !== RecommendationStatus.EmptyResults && (
                  <Stack gap={1}>
                    <CellCaption>{t('PLANS_TO_IMPLEMENT')}</CellCaption>
                    <div>
                      <ChipButton isActive={false} size="S" variant="ghost" color={STATUS_CHIP[status].color}>
                        <Trans key={code} i18nKey={STATUS_CHIP[status].label} />
                      </ChipButton>
                    </div>
                  </Stack>
                )}
              </TableCell>
              <TableCell sx={{ padding: '16px', height: 'auto', width: `${LAST_COLUMN_WIDTH}px`, textAlign: 'right' }}>
                {!isNil(saving) && (
                  <Text variant="body-regular" size="M" tag="div" testId="currency-data">
                    {formatCurrency(saving * (isAnnualPeriod ? 12 : 1), {
                      currency: AWS_DEFAULT_CURRENCY,
                      maximumFractionDigits: 0,
                    })}
                  </Text>
                )}
                <Button
                  component={Link}
                  to={generatePath(ROUTES.CLOUD_OPTIMIZATION_RECOMMENDATION_CODE, { code })}
                  variant="plain"
                  color="tertiary"
                  sx={{ padding: '0', '&:hover': { padding: 0 } }}
                >
                  {t('VIEW')}
                  <IconWrapper icon={ChevronRightIcon}></IconWrapper>
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const PendingTestDetailPanel = ({ row }: { row: PendingOptimizationTestRow }) => {
  const { palette } = useTheme();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.OPTIMIZATION_TESTS.COLUMNS' });
  return (
    <TableContainer
      sx={{
        backgroundColor: palette.input.color1,
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderRadius: 0,
      }}
    >
      <Table size="M" color="neutral">
        <TableBody>
          <TableRow>
            <SpacerCell />
            <TableCell sx={{ padding: '16px', height: 'auto' }} colSpan={2}>
              <CellCaption>{t('DESCRIPTION')}</CellCaption>
              <Text variant="body-regular" size="M" tag="div" whiteSpace="pre-line">
                {row.description}
              </Text>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const PendingChip = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.OPTIMIZATION_TESTS.RESULTS' });

  return (
    <ChipButton color="neutral" variant="ghost" isActive={false}>
      {t('PENDING')}
    </ChipButton>
  );
};

const EffortChips = ({ row }: { row: OptimizationTestRow }) => {
  if (row.type === 'implemented') {
    const effortPriority: { [key: string]: number } = {
      Low: 1,
      Medium: 2,
      High: 3,
    };

    const efforts = row.recommendations
      .map(({ effort }) => effort)
      .filter(Boolean)
      .sort((a, b) => effortPriority[a!] - effortPriority[b!]);

    return (
      <Stack gap={2} alignItems="center" direction="row">
        {efforts.map((effort) => (
          <EffortChip key={effort} effort={effort} />
        ))}
      </Stack>
    );
  }

  return null;
};

export type OptimizationTestsTableState = {
  expandedTests: string[];
};

const replaceWithHashedStrings = (sentence?: string) => {
  if (!sentence) {
    return '';
  }

  return sentence
    .split(' ')
    .map((word) =>
      Array.from(word, (char, i) => {
        const isUpperCase = char === char.toUpperCase();
        const base = isUpperCase ? 65 : 97; // ASCII value for 'A' or 'a'
        return String.fromCharCode(((char.charCodeAt(0) + i) % 26) + base);
      }).join('')
    )
    .join(' ');
};

const OptimizationTestsTable = ({
  data,
  state = { expandedTests: [] },
  onStateChange,
  isAnnualPeriod,
}: {
  data: OptimizationTestRow[];
  state: OptimizationTestsTableState;
  onStateChange?: (state: OptimizationTestsTableState) => void;
  isAnnualPeriod?: boolean;
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.OPTIMIZATION_TESTS.COLUMNS' });
  const formatCurrency = useFormatCurrency();
  const apiRef = useGridApiRef();
  const { subFeatures } = useCloudContext();

  const handleExpandedRowIdsChange = (codes: (string | number)[]) => {
    onStateChange?.({ expandedTests: codes as string[] });
  };

  const getFirstExpandedTestId = useCallback(
    (sortedRowIds: string[]) => sortedRowIds.find((id) => state.expandedTests.includes(id as string)),
    [state.expandedTests]
  );
  useInitialScroll(apiRef, getFirstExpandedTestId);

  const spendAuditVisibleTests = (subFeatures.visibleTests as string).split(',').map((test) => test.trim());
  const canViewFullVersion = !subFeatures.visibleTests;

  const obfuscateData = useCallback(
    (dataToBeObfuscated: OptimizationTestRow[]) =>
      dataToBeObfuscated.map((row) => {
        if (spendAuditVisibleTests.includes(row.code)) {
          // If the test is visible, return the original data
          return row;
        }

        const obfuscatedName = replaceWithHashedStrings(row.name);
        const obfuscatedDescription = replaceWithHashedStrings(row.description);

        if (row.type === 'implemented') {
          return {
            ...row,
            name: obfuscatedName,
            description: obfuscatedDescription,
            recommendations: row.recommendations.map((rec) => ({
              ...rec,
              name: replaceWithHashedStrings(rec.name),
            })),
          };
        }

        return {
          ...row,
          name: obfuscatedName,
          description: obfuscatedDescription,
        };
      }),
    [spendAuditVisibleTests]
  );

  const obfuscatedData = useMemo(
    () => (canViewFullVersion ? [] : obfuscateData(data)),
    [canViewFullVersion, data, obfuscateData]
  );

  const renderObfuscatedOrVisibleCell = (props: GridRenderCellParams<any, any, any>) => {
    const isVisible = canViewFullVersion || spendAuditVisibleTests.includes(props.row.code);
    return isVisible ? <CellRenderer {...props} /> : <ObfuscatedCellRenderer {...props} />;
  };

  const columns: GridColDef<OptimizationTestRow>[] = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      minWidth: 43,
      renderCell: ({ id, value, row }) => <DetailPanelToggle id={id} value={value} />,
    },
    {
      field: 'code',
      headerName: '',
      editable: false,
      renderCell: renderObfuscatedOrVisibleCell,
      sortComparator: testCodeComparator,
    },
    {
      field: 'name',
      headerName: t('NAME'),
      renderCell: renderObfuscatedOrVisibleCell,
      flex: 1,
    },
    {
      field: 'category',
      headerName: t('CATEGORY'),
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        const isExpanded = state.expandedTests.includes(row.code);

        return (
          <Stack direction="row" gap={2} flex={1} justifyContent="space-between">
            <CellRenderer {...params} />
            {!isExpanded ? <EffortChips row={row} /> : null}
          </Stack>
        );
      },
    },
    {
      field: 'recommendationsValue',
      headerName: t('RECOMMENDATIONS_VALUE'),
      align: 'right',
      headerAlign: 'right',
      width: LAST_COLUMN_WIDTH,
      renderCell: ({ row, value }) => {
        if (row.type === 'implemented') {
          const savingsCount = row.recommendations.length;
          const savingsIgnoredCount = row.recommendations.filter(
            ({ status }) => status === RecommendationStatus.Cancelled
          ).length;

          const color = savingsIgnoredCount ? 'text3' : 'text1';

          return (
            <Stack gap={2} alignItems="flex-end">
              <Text variant="body-bold" size="M" testId="currency-data" color={color}>
                {formatCurrency(value * (isAnnualPeriod ? 12 : 1), {
                  currency: AWS_DEFAULT_CURRENCY,
                  maximumFractionDigits: 0,
                })}
              </Text>
              {savingsIgnoredCount ? (
                <Text variant="body-regular" size="M" testId="currency-data" color={color}>
                  {savingsCount !== savingsIgnoredCount ? t('SOME_SAVINGS_IGNORED') : t('SAVINGS_IGNORED')}
                </Text>
              ) : null}
            </Stack>
          );
        }
        return <PendingChip />;
      },
    },
  ];

  const sortModel: GridSortItem[] = [{ field: 'recommendationsValue', sort: 'desc' }];

  if (canViewFullVersion) {
    sortModel.push({ field: 'name', sort: 'asc' });
  } else {
    sortModel.push({ field: 'code', sort: 'asc' });
  }

  return (
    <DataGrid<OptimizationTestRow>
      apiRef={apiRef}
      sx={{ border: 'none', borderRadius: 0 }}
      rows={canViewFullVersion ? data : obfuscatedData}
      columns={columns}
      getRowId={({ code }) => code}
      sortingMode="client"
      hideFooter
      rowHeight={60}
      showCellVerticalBorder
      showColumnVerticalBorder
      autoHeight={true}
      detailPanelExpandedRowIds={
        canViewFullVersion
          ? state.expandedTests
          : state.expandedTests.filter((test) => spendAuditVisibleTests.includes(test))
      }
      onDetailPanelExpandedRowIdsChange={handleExpandedRowIdsChange}
      getDetailPanelContent={({ row }) => {
        if (canViewFullVersion || spendAuditVisibleTests.includes(row.code)) {
          return row.type === 'implemented' ? (
            <ImplementedTestDetailPanel row={row} isAnnualPeriod={isAnnualPeriod} />
          ) : (
            <PendingTestDetailPanel row={row} />
          );
        }

        return;
      }}
      getDetailPanelHeight={() => 'auto'} // Height based on the content.
      disableRowSelectionOnClick
      initialState={{
        sorting: {
          sortModel,
        },
      }}
      color={AWS_BRAND_COLOR}
    />
  );
};

export default OptimizationTestsTable;
