import React from 'react';
import Card, { CardHeader, CardHeaderSubtitle, CardHeaderTitle } from '@verticeone/design-system/src/components/Card';
import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HistoricalSpendByEC2ProcessorGraph from './HistoricalSpendByEC2ProcessorGraph';
import useHistoricalSpendByEC2ProcessorData from './useHistoricalSpendByEC2ProcessorData';
import { LoadableContentWrapper } from '../../components/LoadableContentWrapper';
import { CalendarTodayOutlined } from '@mui/icons-material';

const HistoricalSpendByEC2ProcessorCard = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { palette } = useTheme();
  const { data, ...loadingStatus } = useHistoricalSpendByEC2ProcessorData();

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle text={t('HISTORICAL_SPEND_BY_EC2_PROCESSOR.TITLE')} tag="h2" />
        <CardHeaderSubtitle text={t('LAST_X_MONTHS', { monthCount: 12 })} icon={CalendarTodayOutlined} />
      </CardHeader>
      <Box bgcolor={palette.core.color1}>
        <LoadableContentWrapper {...loadingStatus} pt="22px" pb={6}>
          <HistoricalSpendByEC2ProcessorGraph data={data!} />
        </LoadableContentWrapper>
      </Box>
    </Card>
  );
};

export default HistoricalSpendByEC2ProcessorCard;
