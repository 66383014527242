import React, { useState } from 'react';
import { useRevokeAccountIntegrationMutation } from '@vertice/slices';
import { ONELOGIN_INTEGRATION_ID, STEPS } from './Steps/common';
import { CollectStep } from './Steps/CollectStep';
import { ConnectionCheckStep } from './Steps/ConnectionCheckStep';
import { StartStep } from './Steps/StartStep';
import { ConnectionSuccessStep } from './Steps/ConnectionSuccessStep';
import { ConnectionErrorStep } from './Steps/ConnectionErrorStep';
import Wizard from '../components/Wizard';
import DataSourceManagementStep from '../components/DataSourceManagementStep';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

const OneLoginSetupWizard = () => {
  const { accountId } = useAccountContext();
  const [step, setStep] = useState(STEPS.START);
  const [domain, setDomain] = useState('');
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [testingInProgress, setTestingInProgress] = useState(false);

  const [revokeAccountIntegration] = useRevokeAccountIntegrationMutation();

  const handleCancellation = async () => {
    if (!accountId || step === STEPS.START) return;
    await revokeAccountIntegration({ accountId, integrationId: ONELOGIN_INTEGRATION_ID });
  };

  return (
    <Wizard activeStep={step} onCancel={handleCancellation}>
      <StartStep stepValue={STEPS.START} setStep={setStep} />
      <DataSourceManagementStep
        stepValue={STEPS.DATA_SOURCES}
        setStep={setStep}
        nextStep={STEPS.COLLECT_API_KEYS}
        previousStep={STEPS.START}
        accountId={accountId!}
        usersDataSourceCode="ONELOGIN_USER_BASE"
        applicationDataSourceCodes={['ONELOGIN_APPLICATIONS', 'ONELOGIN_SSO_ACCESS_LOG']}
      />
      <CollectStep
        stepValue={STEPS.COLLECT_API_KEYS}
        setStep={setStep}
        setDomain={setDomain}
        domain={domain}
        clientId={clientId}
        setClientId={setClientId}
        clientSecret={clientSecret}
        setClientSecret={setClientSecret}
        accountId={accountId!}
        testingInProgress={testingInProgress}
        setTestingInProgress={setTestingInProgress}
      />
      <ConnectionCheckStep
        stepValue={STEPS.CHECK_CONNECTION}
        setStep={setStep}
        accountId={accountId!}
        setTestingInProgress={setTestingInProgress}
        testingInProgress={testingInProgress}
      />
      <ConnectionErrorStep
        stepValue={STEPS.CONNECTION_ERROR}
        setStep={setStep}
        accountId={accountId!}
        testingInProgress={testingInProgress}
        setTestingInProgress={setTestingInProgress}
      />
      <ConnectionSuccessStep
        stepValue={STEPS.CONNECTION_SUCCESS}
        setStep={setStep}
        accountId={accountId!}
        testingInProgress={testingInProgress}
        setTestingInProgress={setTestingInProgress}
      />
    </Wizard>
  );
};

export default OneLoginSetupWizard;
