import { ComponentType } from 'react';
import { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import { TextFieldProps } from './styledVariants/common';
import GhostTextField from './styledVariants/GhostTextField';
import SolidTextField from './styledVariants/SolidTextField';
import OutlinedTextField from './styledVariants/OutlinedTextField';
import { TextFieldVariant } from './TextField';

const variantDefinitions: Record<
  TextFieldVariant,
  { component: ComponentType<TextFieldProps>; muiVariant: NonNullable<MuiTextFieldProps['variant']> }
> = {
  ghost: { component: GhostTextField, muiVariant: 'standard' },
  solid: { component: SolidTextField, muiVariant: 'filled' },
  outlined: { component: OutlinedTextField, muiVariant: 'outlined' },
};

export default variantDefinitions;
