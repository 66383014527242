import React, { FC, ReactElement, cloneElement } from 'react';
import useAddEditInviteContactModal from './useAddEditInviteContactModal';

interface WithAddingContactProps {
  children: ReactElement;
  addLabel?: string;
}
const WithAddingContact: FC<WithAddingContactProps> = ({ children, addLabel, ...rest }) => {
  const { modal, handleAddRequest, addedContact } = useAddEditInviteContactModal();

  return (
    <>
      {cloneElement(children, {
        addedContact,
        dataGridExtraProps: {
          hideFooter: false,
          editMode: 'row',
          componentsProps: {
            footer: { addLabel, onAddItem: handleAddRequest },
          },
          experimentalFeatures: { newEditingApi: true },
        },
        ...rest,
      })}
      {modal}
    </>
  );
};

export default WithAddingContact;
