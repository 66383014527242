import Select, { SelectProps } from '@verticeone/design-system/src/components/Select';
import React, { useMemo } from 'react';
import { UserOption } from './types';
import { getFullName } from '@vertice/core/src/utils/formatting/user';
import { GroupBase } from '@verticeone/design-system/src/components/SelectCommon/types';
import { orderBy } from 'lodash';

export type UserSelectProps<IsMulti extends boolean> = Pick<
  SelectProps<UserOption, IsMulti, GroupBase<UserOption>>,
  | 'color'
  | 'error'
  | 'id'
  | 'isClearable'
  | 'isDisabled'
  | 'isLoading'
  | 'isMulti'
  | 'onBlur'
  | 'onChange'
  | 'placeholder'
  | 'ref'
  | 'size'
  | 'variant'
> & {
  oneLine?: boolean;
  availableUsers: UserOption[];
  selectedUsers: UserOption[];
  onAddUserClick?: () => void;
};

const formatUserLabel = (user: UserOption) => getFullName(user) || user.email;

/**
 * Component for selecting single or multiple users
 *
 * Features:
 *  - unified user formatting
 *  - unified sorting
 *
 * Planned features:
 *  - avatars
 *  - "+ Add user" button
 */
const UserSelect = <IsMulti extends boolean>({
  availableUsers,
  selectedUsers,
  oneLine = false,
  ...otherProps
}: UserSelectProps<IsMulti>) => {
  const sortedOptions = useMemo(() => orderBy(availableUsers, (u) => formatUserLabel(u), 'asc'), [availableUsers]);
  return (
    <Select<UserOption, IsMulti>
      options={sortedOptions}
      value={selectedUsers}
      getOptionLabel={formatUserLabel}
      getOptionValue={(o) => o.id}
      maxMultiChips={oneLine ? 2 : undefined}
      menuPortalTarget={document.body}
      menuPlacement="auto"
      menuPosition="absolute"
      sx={{ minWidth: '150px' }}
      {...otherProps}
    />
  );
};

export default UserSelect;
