import React, { useMemo } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import StatsItem from './StatsItem';
import { sum } from 'lodash';
import useRecommendationsData from '../../../hooks/useRecommendationsData';
import { AWS_DEFAULT_CURRENCY } from '../../../constants';
import { useFormatCurrency } from '@vertice/core/src/utils/formatting/currency';
import { testProps } from '@verticeone/design-system/src/utils/testProperties';

type ScoreBarStatsProps = {
  passed: number;
  failed: number;
  notRun: number;
  isAnnualPeriod?: boolean;
};

const ScoreBarStats = ({ passed, failed, notRun, isAnnualPeriod }: ScoreBarStatsProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.OPTIMIZATION_TESTS.SCORE_BAR' });
  const total = useMemo(() => sum([passed, failed, notRun]), [passed, failed, notRun]);
  const { stats, isLoading } = useRecommendationsData();
  const formatCurrency = useFormatCurrency();
  const multiplier = isAnnualPeriod ? 12 : 1;

  const formattedSavings = useMemo(
    () =>
      formatCurrency((stats?.totalMonthlyRecValue ?? 0) * multiplier, {
        currency: AWS_DEFAULT_CURRENCY,
        maximumFractionDigits: 0,
      }),
    [formatCurrency, stats?.totalMonthlyRecValue, multiplier]
  );

  const formatIgnoredSavings = useMemo(
    () =>
      formatCurrency((stats?.totalIgnoredRecValue ?? 0) * multiplier, {
        currency: AWS_DEFAULT_CURRENCY,
        maximumFractionDigits: 0,
      }),
    [formatCurrency, stats?.totalIgnoredRecValue, multiplier]
  );

  return (
    <Stack direction="row" gap={2} pl="35px" pr="40px">
      <StatsItem
        title={t('TESTS_RUN')}
        value={total}
        colorVariant="neutral"
        valueSize="L"
        {...testProps('testsRun', 'button')}
      />
      <StatsItem
        title={t('WELL_OPTIMIZED')}
        value={passed}
        colorVariant="neutral"
        valueSize="M"
        {...testProps('wellOptimized', 'button')}
      />
      <StatsItem
        title={t('OPTIMIZATIONS_DISCOVERED')}
        value={failed}
        colorVariant="highlighted"
        valueSize="M"
        {...testProps('optimizationsDiscovered', 'button')}
      />
      <StatsItem
        title={isAnnualPeriod ? t('ESTIMATED_ANNUAL_SAVINGS') : t('ESTIMATED_MONTHLY_SAVINGS')}
        value={formattedSavings}
        colorVariant="neutral"
        valueSize="M"
        isLoading={isLoading}
        {...testProps('estimatedSavings', 'button')}
      />
      {stats?.totalIgnoredRecValue ? (
        <StatsItem
          title={isAnnualPeriod ? t('ESTIMATED_ANNUAL_IGNORED_SAVINGS') : t('ESTIMATED_MONTHLY_IGNORED_SAVINGS')}
          value={formatIgnoredSavings}
          colorVariant="neutral"
          valueSize="M"
          {...testProps('ignoredSavings', 'button')}
        />
      ) : null}
    </Stack>
  );
};

export default ScoreBarStats;
