import React from 'react';
import {
  gridFilteredDescendantCountLookupSelector,
  GridRenderCellParams,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { Box, Stack } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import { useTranslation } from 'react-i18next';
import { ExpandMore, ChevronRight } from '@mui/icons-material';
import IconButton, { IconButtonProps } from '@verticeone/design-system/src/components/IconButton';
import { RegionCode, regionsCodeToNameMap } from '@vertice/dashboard/src/modules/cloud/regions';
import { GridGroupNode } from '@mui/x-data-grid/models/gridRows';

type RegionContentProps = {
  isOpen: boolean;
  row: GridRenderCellParams['row'];
};

const CollapseButton = ({ isOpen, ...props }: Partial<IconButtonProps> & { isOpen: boolean }) => {
  return (
    <IconButton
      {...props}
      size="M"
      variant="plain"
      aria-label={isOpen ? 'Close' : 'Open'}
      sx={{ margin: '0 -6px' }}
      icon={isOpen ? ExpandMore : ChevronRight}
    />
  );
};

const RegionContent = ({ row, isOpen }: RegionContentProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RESERVED_INSTANCES' });

  return (
    <Stack direction="row" alignItems="center">
      <CollapseButton isOpen={isOpen} />
      <Text variant="body-bold" size="M">{`${t('REGION')}: ${regionsCodeToNameMap[row.region as RegionCode]}`}</Text>
    </Stack>
  );
};

const TypeContent = (props: GridRenderCellParams) => {
  const { row } = props;

  return (
    <Text variant="body-regular" size="M">
      {row.instanceFamily}
    </Text>
  );
};

const RegionTypeCell = (props: GridRenderCellParams) => {
  const { id, field, rowNode, row } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleCollapseButtonClick = () => {
    'childrenExpanded' in rowNode && apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
  };

  return (
    <Box
      sx={{
        ml: rowNode.depth * 4,
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        flex: 1,
      }}
      onClick={handleCollapseButtonClick}
    >
      {filteredDescendantCount > 0 ? (
        <RegionContent row={row} isOpen={!!(rowNode as GridGroupNode).childrenExpanded} />
      ) : (
        <TypeContent {...props} />
      )}
    </Box>
  );
};

export default RegionTypeCell;
