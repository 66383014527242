import React from 'react';
import styled from '@mui/material/styles/styled';

type CardHeaderActionPaddingProps = {
  children: React.ReactNode;
};

export const StyledCardHeaderActionPadding = styled('div')({});

const CardHeaderActionPadding = ({ children }: CardHeaderActionPaddingProps) => (
  <StyledCardHeaderActionPadding>{children}</StyledCardHeaderActionPadding>
);

export default CardHeaderActionPadding;
