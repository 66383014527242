import { FC } from 'react';
import { Stack, useTheme } from '@mui/material';
import { ChevronLeftOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import Text from '@verticeone/design-system/src/components/Text';
import IconButton from '@verticeone/design-system/src/components/IconButton';
import Button from '@verticeone/design-system/src/components/Button';
import Placeholder from '@verticeone/design-system/src/components/Placeholder';

import { useGetWorkflowVersionQuery } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { WorkflowViewer } from '@vertice/core/src/modules/intelligentWorkflows/workflowSchema';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { Definitions } from '@vertice/core/src/modules/intelligentWorkflows/definitionsTypes';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';

export const WorkflowEditorPage: FC = () => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const routes = useRoutes();
  const { generatePath } = useRouteNavigate();

  const params = useParams() as { workflowId: string; versionId: string };

  const { data: workflowVersionData } = useGetWorkflowVersionQuery({
    accountId,
    workflowId: params.workflowId,
    workflowVersion: params.versionId,
  });

  const workflowDefinition = workflowVersionData?.workflowDefinition as Definitions;

  const backPath = generatePath(routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.DETAIL.ABSOLUTE_PATH, {
    workflowId: params.workflowId,
  });

  return (
    <Stack height={'100%'}>
      <Stack
        paddingTop={10}
        paddingRight={8}
        paddingBottom={4}
        paddingLeft={6}
        bgcolor={palette.background.default}
        direction="row"
        justifyContent="space-between"
      >
        <Stack direction="row" gap={4}>
          <IconButton icon={ChevronLeftOutlined} size="S" variant="outline" component={Link} to={backPath} />
          {workflowVersionData ? (
            <Text variant="heading" size="M">
              {workflowVersionData?.workflowName}
            </Text>
          ) : (
            <Placeholder width={300} height={32} />
          )}
        </Stack>
        <Stack direction="row" gap={1}>
          <Button variant="solid" size="S" component={Link} to={backPath}>
            {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.ACTIONS.CLOSE')}
          </Button>{' '}
          <Button color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} variant="solid" size="S" component={Link} to={backPath}>
            {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.ACTIONS.SAVE_AND_CLOSE')}
          </Button>
        </Stack>
      </Stack>
      <Stack flexGrow={1}>
        {/*to be extended in the future to allow for editing of the workflow*/}
        {workflowDefinition ? <WorkflowViewer definitions={workflowDefinition} /> : <Placeholder height="100%" />}
      </Stack>
    </Stack>
  );
};
