import { PropsWithChildren, forwardRef } from 'react';
import { TextFieldAreaProps } from './constants';

import OutlinedWrapper from './Outlined';
import SolidWrapper from './Solid';
import GhostWrapper from './Ghost';

const TextFieldAreaWrapper = forwardRef<HTMLDivElement, PropsWithChildren<TextFieldAreaProps>>(
  ({ children, autosize = false, ...otherProps }, ref) => {
    const Wrapper = {
      outline: OutlinedWrapper,
      solid: SolidWrapper,
      ghost: GhostWrapper,
    }[otherProps.variant];

    return (
      <Wrapper {...otherProps} autosize={autosize} ref={ref}>
        {children}
      </Wrapper>
    );
  }
);

export default TextFieldAreaWrapper;
