import { useTranslation } from 'react-i18next';
import { IntegrationEntraIcon } from '@vertice/assets';
import { INTEGRATION_ROUTES, ROUTES } from '@vertice/utils/src/constants/cfa/constants';
import UsageStatsSetupCard from '../UsageStatsSetupCard';

type EntraSetupCardProps = {
  providerId: string;
};

export const EntraSetupCard = ({ providerId }: EntraSetupCardProps) => {
  const { t } = useTranslation();

  return (
    <UsageStatsSetupCard
      providerId={providerId}
      providerName="Microsoft Entra ID"
      providerIcon={<IntegrationEntraIcon />}
      description={t('PREFERENCES.INTEGRATIONS.ENTRA.DESCRIPTION')}
      wizardRoute={`${ROUTES.PREFERENCES}/${INTEGRATION_ROUTES.BASE_PATH}/${INTEGRATION_ROUTES.ENTRA_SETUP}`}
    />
  );
};
