import React from 'react';
import { CardHeader, CardHeaderProps } from '../Card/CardHeader';
import { useDrawer } from './DrawerContext';

export const DrawerHeader = ({ children, ...otherProps }: CardHeaderProps) => {
  const { density, size } = useDrawer();

  return (
    <CardHeader size={size} density={density} {...otherProps}>
      {children}
    </CardHeader>
  );
};

export default DrawerHeader;
