import React, { forwardRef, useContext } from 'react';
import type { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import type { DesignSystemColor, DesignSystemSize, CustomizedProps, DesignSystemVariant, TestProps } from '../../types';
import ButtonGroupContext from '../ButtonGroup/ButtonGroupContext';
import BaseChipButton, { useStyles, UseStylesProps } from './BaseChipButton';
import { testProps } from '../../utils/testProperties';
import { Stack, styled, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

type SharedButtonProps = CustomizedProps & Omit<MuiButtonProps, 'size' | 'variant' | 'color' | 'disabled'>;

export type ChipButtonProps = TestProps & {
  size?: DesignSystemSize;
  color?: DesignSystemColor;
  variant?: DesignSystemVariant;
  disabled?: boolean;
  isActive?: boolean;
  label?: string;
  sx?: SxProps<Theme>;
} & SharedButtonProps;

const Label = styled(Stack)({
  opacity: 0.6,
});

const ChipButton = forwardRef<HTMLButtonElement, ChipButtonProps>(
  ({ size, color, variant, disabled, isActive = true, children, testId, label, ...otherProps }, ref) => {
    const buttonGroupContext = useContext(ButtonGroupContext);
    const defaultProps: UseStylesProps = {
      size: size ?? buttonGroupContext?.size ?? 'M',
      color: color ?? buttonGroupContext?.color ?? 'neutral',
      variant: variant ?? buttonGroupContext?.variant ?? 'outline',
      isActive,
      disabled: Boolean(disabled),
    };

    const styles = useStyles(defaultProps);

    return (
      <BaseChipButton
        as={isActive ? undefined : 'span'}
        $styles={styles}
        role={isActive ? 'button' : undefined}
        disabled={buttonGroupContext?.disabled || disabled || false}
        {...testProps(testId, 'chip-button')}
        {...otherProps}
        ref={ref}
      >
        {label ? <Label>{label}</Label> : null}
        {children}
      </BaseChipButton>
    );
  }
);

export default ChipButton;
