import { maxBy, minBy } from 'lodash';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';

import { QUERY_KEY as CONTRACT_LIST_QUERY_KEY } from './useContractListData';
import { fetchCloudOptimization } from '../CloudClientProvider';
import { useTranslation } from 'react-i18next';

export type ContractYear = {
  commitment: number;
  discount: number;
  endDate: string;
  startDate: string;
};

export type Contract = {
  contractId: string;
  years: Array<ContractYear>;
};

type UseContractUpdateProps = Partial<Record<'onSuccess' | 'onError' | 'onSettled', () => void>>;

export const QUERY_KEY = 'EDPContractUpdate' as const;

const EDPContractUpdateQuery = graphql(`
  mutation EDPContractUpdate($accountId: ID!, $identifier: ID!, $costModel: CostModelInput!) {
    updateContract(params: { accountId: $accountId, identifier: $identifier, costModel: $costModel }) {
      ... on EDPContract {
        __typename
        identifier
        costModel {
          startDate
          endDate
          costAllocationSpans {
            startDate
            endDate
            commitment
            discount
          }
        }
      }
      ... on ErroredQueryResult {
        __typename
        error
      }
    }
  }
`);

export const useContractUpdate = (options?: UseContractUpdateProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EDP_CONTRACT_GRID' });
  const { accountId } = useAccountContext();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (contract: Contract) => {
      return fetchCloudOptimization(EDPContractUpdateQuery, {
        accountId,
        identifier: contract.contractId,
        costModel: {
          startDate: minBy(contract.years, (year) => new Date(year.startDate).getTime())!.startDate,
          endDate: maxBy(contract.years, (year) => new Date(year.endDate).getTime())!.endDate,
          costAllocationSpans: contract.years.map((year, index) => ({
            startDate: year.startDate,
            endDate: year.endDate,
            commitment: year.commitment,
            discount: year.discount,
            name: `Term ${index + 1}`,
          })),
        },
      });
    },
    onSettled: () => {
      options?.onSettled?.();
    },
    onSuccess: (data) => {
      enqueueSnackbar({
        variant: 'success',
        message: t('ALERT.CONTRACT_SAVED'),
      });
      void queryClient.invalidateQueries({ queryKey: [CONTRACT_LIST_QUERY_KEY] });
      options?.onSuccess?.();
    },
    onError: (errors: Array<Error>) => {
      errors.forEach((e) =>
        enqueueSnackbar({
          variant: 'error',
          message: e.message,
        })
      );
      options?.onError?.();
    },
  });
};
