import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import format from 'date-fns/format';
import { addMonths, startOfMonth } from 'date-fns';
import {
  CheckDataPoint,
  useOptimizationCheckQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useMemo } from 'react';
import useDeferredQuery from '@vertice/core/src/utils/api/useDeferredQuery';
import { DATE_FORMAT, fillMissingMonths, getTableData } from '../../utils/graphDataUtils';
import LoadableAdvanced from '@vertice/utils/src/loadableAdvanced';
import { chain, flatMap, sumBy, uniq } from 'lodash';
import { AWS_DEFAULT_CURRENCY } from '../../constants';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';
import toConstantCase from '@vertice/core/src/utils/strings/toConstantCase';

const startDate = addMonths(startOfMonth(new Date()), -11);

export type HistoricalSpendByEC2ProcessorData = {
  currency: string;
  series: { id: string; data: (number | null)[] }[];

  /** The list of used series in the required order */
  usedCategories: string[];
  months: string[];
};

const splitIntoSeries = (items: { time: string; values: Record<string, number | null> }[]) => {
  const usedCategories = uniq(flatMap(items, ({ values }) => Object.keys(values)));
  return {
    usedCategories,
    series: usedCategories.map((category) => ({
      id: category,
      data: items.map(({ values: valuePerCategory }) => valuePerCategory[category] ?? null),
    })),
  };
};

export const prepareData = (
  rawData: CheckDataPoint[] | undefined,
  t: TFunction
): HistoricalSpendByEC2ProcessorData | undefined => {
  if (!rawData || rawData.length === 0) return undefined;

  const allMonthsData = chain(rawData)
    .flatMap(({ values }) => values)
    .map(({ dateStart, checkResult }) => ({
      // 2023-01-01T00:00:00 -> 2023-01-01
      time: format(new Date(dateStart), DATE_FORMAT),
      values: chain(
        getTableData(checkResult, {
          processor: 'processor',
          sum_cost: 'cost',
        }) as { processor: string; cost: number }[]
      )
        .groupBy(({ processor }) => processor)
        .mapValues((processorItems) => sumBy(processorItems, ({ cost }) => cost))
        .value(),
    }))
    .orderBy('time', 'asc')
    .thru((items) => (items.length > 0 ? fillMissingMonths(items, new Date(items[0].time), {}) : items))
    .value();

  const { series: seriesRaw, usedCategories } = splitIntoSeries(allMonthsData);

  const series = seriesRaw.map((serie) => ({
    ...serie,
    name: t(`EC2_PROCESSOR_USAGE.PROCESSORS.${toConstantCase(serie.id)}`) ?? serie.id,
  }));

  return {
    series,
    usedCategories,
    months: allMonthsData.map(({ time }) => time),
    currency: AWS_DEFAULT_CURRENCY,
  };
};

const useHistoricalSpendByEC2ProcessorData = (): LoadableAdvanced<HistoricalSpendByEC2ProcessorData> => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { accountId } = useSelector(getAccount);
  const { data: rawData, error: dataError } = useDeferredQuery(
    useOptimizationCheckQuery,
    {
      accountId: accountId!,
      // Acceptable timezone inaccuracy: We're sending local timezone date to UTC endpoint
      startDate: format(startDate, DATE_FORMAT),
      checkCode: 'EC2_AMD_GRAVITON_INTEL_COST',
    },
    { skip: !accountId, pollingInterval: 5000 },
    ({ checkQuery }) => checkQuery
  );

  const computed = useMemo(() => prepareData(rawData, t), [rawData, t]);

  return {
    error: dataError,
    isEmpty: Boolean(rawData && rawData.length === 0),
    isLoading: !computed,
    data: computed,
  };
};

export default useHistoricalSpendByEC2ProcessorData;
