import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import {
  ForecastPrecisionType,
  useSpendByServiceLineQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import format from 'date-fns/format';
import { addMonths, startOfMonth } from 'date-fns';
import useDeferredQuery from '@vertice/core/src/utils/api/useDeferredQuery';
import { TreeItem } from '../../components/HierarchyTree/types';
import { DATE_FORMAT, findFirstCurrency, getHierarchyData } from '../../utils/graphDataUtils';
import { chain } from 'lodash';
import LoadableAdvanced from '@vertice/utils/src/loadableAdvanced';

const monthBeginning = startOfMonth(new Date());
const startDate = addMonths(monthBeginning, -1);
const previousStartDate = addMonths(monthBeginning, -2);

export type ItemType = 'root' | 'category' | 'product';

export type HierarchyItem = TreeItem & {
  name?: string;
  type: ItemType;
  value: number;
  previousValue?: number;
};

export type HierarchyData = {
  currency?: string;
  hierarchy: HierarchyItem;
};

const useHierarchyGraphData = (): LoadableAdvanced<HierarchyData> => {
  const { accountId } = useSelector(getAccount);

  const { data: rawData, error: dataError } = useDeferredQuery(
    useSpendByServiceLineQuery,
    {
      accountId: accountId!,
      startDate: format(startDate, DATE_FORMAT),
      endDate: format(monthBeginning, DATE_FORMAT),
      precision: ForecastPrecisionType.Month,
    },
    { skip: !accountId, pollingInterval: 5000 },
    ({ costUsageQuery }) => costUsageQuery
  );

  const { data: rawPreviousData, error: previousDataError } = useDeferredQuery(
    useSpendByServiceLineQuery,
    {
      accountId: accountId!,
      startDate: format(previousStartDate, DATE_FORMAT),
      endDate: format(startDate, DATE_FORMAT),
      precision: ForecastPrecisionType.Month,
    },
    { skip: !accountId, pollingInterval: 5000 },
    ({ costUsageQuery }) => costUsageQuery
  );

  const computed = useMemo(() => {
    if (!rawData || !rawPreviousData) return undefined;

    const previousCategoriesWithProducts = getHierarchyData(rawPreviousData);
    const categoriesWithProducts = getHierarchyData(rawData);

    // Merge item hierarchy of current billing period with values of previous billing period
    const mergedCategories = chain(categoriesWithProducts)
      .filter((category) => Boolean(category.value)) // filters out categories with zero value
      .map((category, categoryIndex) => {
        const prevCategory = previousCategoriesWithProducts.find((prev) => prev.name === category.name);
        return {
          id: categoryIndex.toString(),
          name: category.name,
          value: category.value,
          type: 'category' as ItemType,
          previousValue: prevCategory?.value,
          children: chain(category.children)
            .filter((product) => Boolean(product.value)) // filters out products with zero value
            .map((product, productIndex) => {
              const prevProduct = prevCategory?.children.find((prev) => prev.name === product.name);
              return {
                id: categoryIndex + '-' + productIndex,
                name: product.name,
                value: product.value,
                type: 'product' as ItemType,
                previousValue: prevProduct?.value,
              };
            })
            .orderBy('value', 'desc')
            .value(),
        };
      })
      .orderBy('value', 'desc')
      .value();

    return {
      currency: findFirstCurrency(rawData),
      hierarchy: {
        id: 'aws',
        type: 'root' as ItemType,
        value: categoriesWithProducts.reduce((sum, { value }) => sum + value, 0),
        previousValue: previousCategoriesWithProducts.reduce((sum, { value }) => sum + value, 0),
        collapsible: false,
        children: mergedCategories,
      },
    };
  }, [rawData, rawPreviousData]);

  return {
    error: dataError || previousDataError,
    isEmpty: Boolean(rawData && rawData.length === 0),
    isLoading: !computed,
    data: computed,
  };
};

export default useHierarchyGraphData;
