import React, { BaseSyntheticEvent } from 'react';
import { Stack } from '@mui/material';
import { KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined } from '@mui/icons-material';
import IconButton from '@verticeone/design-system/src/components/IconButton';
import Text from '@verticeone/design-system/src/components/Text';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';

interface ExpandableHeaderProps {
  headerText: string;
  dateRangeText?: string;
  isExpanded: boolean;
  toggleExpand: () => void;
}

const ExpandableHeader: React.FC<ExpandableHeaderProps> = ({ headerText, dateRangeText, isExpanded, toggleExpand }) => (
  <Stack direction="row" gap={2} alignItems="center">
    <Stack direction="column" alignItems="flex-end">
      <Text variant="body-bold" size="M" color={AWS_BRAND_COLOR}>
        {headerText}
      </Text>
      <Text variant="body-regular" size="S">
        {dateRangeText}
      </Text>
    </Stack>
    <IconButton
      size="XXS"
      variant="solid"
      onClick={(e: BaseSyntheticEvent) => {
        e.stopPropagation();
        toggleExpand();
      }}
      icon={isExpanded ? KeyboardArrowLeftOutlined : KeyboardArrowRightOutlined}
    />
  </Stack>
);

export default ExpandableHeader;
