import Button from '@verticeone/design-system/src/components/Button';
import Text from '@verticeone/design-system/src/components/Text';

type FilterButtonProps = {
  label: string;
  count: number;
  active: boolean;
  disabled?: boolean;
  onClick: () => void;
};

export const FilterButton = ({ label, active, onClick, count, disabled = false }: FilterButtonProps) => {
  return (
    <Button
      variant={active ? 'solid' : 'ghost'}
      size="S"
      color="neutral"
      onClick={onClick}
      aria-pressed={active}
      disabled={disabled}
    >
      <Text variant="button" size="S">
        {label}
      </Text>
      <Text variant="button" size="S" sx={{ opacity: 0.4 }}>
        {count}
      </Text>
    </Button>
  );
};
