import type { Components, Theme } from '@mui/material/styles';
import { ComponentsProps } from '@mui/material/styles/props';

/**
 *  Use only in justified cases for default props overrides.
 */

type Keys = keyof Components<Theme>;
// return type compatible with Components<Theme> but with only defaultProps for each component
type OnlyDefaultProps = {
  [Name in Keys]?: {
    defaultProps?: ComponentsProps[Name];
  };
};

const muiComponentsOverridesNew: OnlyDefaultProps = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true, // No button ripple anywhere
    },
  },
};

export default muiComponentsOverridesNew;
