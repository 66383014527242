import React from 'react';
import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Breadcrumbs, { BreadcrumbLink } from '@verticeone/design-system/src/components/Breadcrumbs';
import { ROUTES } from '@vertice/utils/src/constants/cfa/constants';
import Text from '@verticeone/design-system/src/components/Text';

import { useRecommendationData } from './RecommendationProvider';
import { format, differenceInHours, differenceInDays } from 'date-fns';

const LastEvaluated = () => {
  const {
    item: { updatedAt },
  } = useRecommendationData();
  const { t } = useTranslation();
  const lastEvaluated = updatedAt ? format(new Date(updatedAt), 'HH:mm - dd/MM/yyyy') : null;

  const getTimeDifference = (dateTime: string | null): string => {
    if (!dateTime) return '';

    const now = new Date();
    const hoursDifference = differenceInHours(now, new Date(dateTime));
    if (hoursDifference < 24) {
      return `(${t('CLOUD.CLOUD_OPTIMIZATION.HOURS_AGO', { count: hoursDifference })})`;
    }

    const daysDifference = differenceInDays(now, new Date(dateTime));
    return `(${t('CLOUD.CLOUD_OPTIMIZATION.DAYS_AGO', { count: daysDifference })})`;
  };

  if (!lastEvaluated) {
    return null;
  }

  return (
    <Text variant="body-regular" size="M" color="text2">
      {t('CLOUD.CLOUD_OPTIMIZATION.LAST_EVALUATED')}: {lastEvaluated} {getTimeDifference(updatedAt)}
    </Text>
  );
};

const Header = () => {
  const { item } = useRecommendationData();
  const { t } = useTranslation();
  const { t: tRecommendations } = useTranslation('cloudRecommendations', { keyPrefix: item.code });

  const scope = item.tags?.map(([tagName, value]) => (tagName === 'Scope' ? [value] : [])).flat();

  return (
    <Stack gap={1}>
      <Breadcrumbs variant="slash" size="S">
        <BreadcrumbLink>{t('CLOUD.NAVBAR.CLOUD')}</BreadcrumbLink>
        <BreadcrumbLink component={Link} to={ROUTES.CLOUD_OPTIMIZATION}>
          {t('CLOUD.CLOUD_OPTIMIZATION.BREADCRUMBS_TITLE')}
        </BreadcrumbLink>
        <BreadcrumbLink disabled>{item.code}</BreadcrumbLink>
      </Breadcrumbs>
      <Stack gap={2}>
        <Text variant="heading" size="M" color="text1" tag="h1">
          {tRecommendations('NAME')}
        </Text>
        <Stack justifyContent="space-between" direction="row">
          <Text variant="body-regular" size="M" color="text2">
            {scope}
          </Text>
          <LastEvaluated />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Header;
