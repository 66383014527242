import { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';

/**
 * Only horizontal variant with core3 color is implemented ATM
 */

const StyledDivider = styled('div')(({ theme, children }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: children ? 4 : 0,
  '&::before, &::after': {
    flex: '1',
    content: '""',
    padding: '0.5px',
    backgroundColor: theme.palette.core.color3,
  },
}));

type DividerProps = {
  children?: ReactNode;
};

const Divider: FC<DividerProps> = ({ children }) => {
  return <StyledDivider>{children}</StyledDivider>;
};

export default Divider;
