import React, { FC, ReactNode } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';

type NodeControlsProps = NodeProps & { content: ReactNode };

// invisible handle
export const NodeControls: FC<NodeControlsProps> = ({ content }) => {
  const handleStyle = {
    width: 0,
    height: 0,
    minWidth: 0,
    minHeight: 0,
    opacity: 0,
  };

  return (
    <div>
      {content}
      <Handle type="target" position={Position.Left} style={handleStyle} />
      <Handle type="source" position={Position.Right} style={handleStyle} />
    </div>
  );
};

export default Node;
