import { FormControl, Stack } from '@mui/material';
import TextFieldCaption from '@verticeone/design-system/src/components/TextFieldCaption';
import Text from '@verticeone/design-system/src/components/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
import { useIsContractFieldVisible } from '../../hooks/fieldsRulesHooks';
import { useFormState, useWatch } from 'react-hook-form';
import { ContractFormData } from '../../types';
import { getStartedMonthsBetweenDates } from '../../computed';

const ContractLengthComputedField = () => {
  const { t } = useTranslation();

  const startDate = useWatch<ContractFormData>({ name: 'parts.contractual.lifecycle.startDate' });
  const renewalDate = useWatch<ContractFormData>({ name: 'parts.contractual.lifecycle.renewalDate' });
  const modelContractLength = useWatch<ContractFormData>({ name: 'parts.overview.contractLength' });
  const isVisible = useIsContractFieldVisible('contractLength');

  const { dirtyFields } = useFormState();
  const datesUpdated =
    !isNil(dirtyFields.parts?.contractual?.lifecycle?.startDate) ||
    !isNil(dirtyFields.parts?.contractual?.lifecycle?.renewalDate);
  const recomputeContractLength = isNil(modelContractLength) || datesUpdated;

  if (!isVisible) return null;

  const contractLength = recomputeContractLength
    ? getStartedMonthsBetweenDates(startDate ?? undefined, renewalDate ?? undefined)
    : modelContractLength;

  return (
    <FormControl variant="outlined" fullWidth>
      <Stack gap={1}>
        <TextFieldCaption htmlFor="contractLength" label={t('ENTITIES.CONTRACT.LABELS.CONTRACT_LENGTH')} size="XS" />
        <Text id="contractLength" variant="body-regular">
          {isNil(contractLength)
            ? t('COMMON.NA')
            : `${contractLength} ${t('UNITS.MONTH', { count: contractLength ?? 0 })}`}
        </Text>
      </Stack>
    </FormControl>
  );
};

export default ContractLengthComputedField;
