import React, { FC, useState, ReactElement, cloneElement, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridValueGetterParams,
  GridRowParams,
  MuiEvent,
  GridEventListener,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { Stack } from '@mui/material';
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from '@mui/icons-material';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { ConfirmationDialog } from '@vertice/components';
import IconButton from '@verticeone/design-system/src/components/IconButton';
import useSelectedProducts from './useSelectedProducts';
import { useVendorProductsContext } from './VendorProductsContextProvider';
import { ProductItem } from './types';
import { randomId } from '@mui/x-data-grid-generator';

interface WithActionsProps {
  children: ReactElement;
  excludeDeleteAction?: boolean;
  selectedProducts: ProductItem[];
  setSelectedProducts: (newProducts: ProductItem[]) => void;
}

const WithActions: FC<WithActionsProps> = ({
  children,
  excludeDeleteAction,
  selectedProducts,
  setSelectedProducts,
  ...rest
}) => {
  const { t } = useTranslation();
  const { vendorProducts } = useVendorProductsContext();

  const [tempoProducts, setTempoProducts] = useState<ProductItem[]>([]);
  const [activeProductId, setActiveProductId] = useState('');

  const { addProduct, updateProduct, removeProduct } = useSelectedProducts({ selectedProducts, setSelectedProducts });
  const productsIds = selectedProducts?.map((prod: ProductItem) => prod.id) || [];
  const productsToUse = [
    ...(selectedProducts || []),
    ...tempoProducts.filter((prod) => !productsIds.includes(prod.id)),
  ];

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [cannotAddProductOpen, setCannotAddProductOpen] = useState(false);

  const onAddRow = () => {
    const id = randomId();
    setTempoProducts((oldRows) => [
      ...oldRows,
      { id, name: '', productId: '', annualCost: null, numberOfLicences: null, licenseType: null, isNew: true },
    ]);
    setRowModesModel((currentModel) => ({
      ...currentModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'productId' },
    }));
  };

  const onRowEditStart = (params: GridRowParams, event: MuiEvent<SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const onRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    if (newRow.productId) {
      const productData = {
        id: newRow.id,
        productId: newRow.productId,
        annualCost: newRow.annualCost,
        numberOfLicences: newRow.numberOfLicences,
        licenseType: newRow.licenseType
          ? {
              id: newRow.licenseType.id,
              label: newRow.licenseType.label,
            }
          : null,
        name: vendorProducts.find((prod) => prod.id === newRow.productId)?.name || '',
      };

      if (newRow.isNew) {
        addProduct(productData);
      } else {
        updateProduct(productData);
      }
    }
    setTempoProducts([]);
    return newRow;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setActiveProductId(id as string);
    setConfirmDeleteOpen(true);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setTempoProducts([]);
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const handleConfirmedDelete = async () => {
    if (activeProductId) {
      removeProduct(activeProductId);
      setActiveProductId('');
    }
    setConfirmDeleteOpen(false);
  };

  const handleCanceledDelete = () => {
    setConfirmDeleteOpen(false);
  };

  const ActionsCell = (params: GridValueGetterParams<ProductItem, ProductItem>) => {
    const apiRef = useGridApiContext();
    const isRowInEditMode = rowModesModel[params.row.id]?.mode === GridRowModes.Edit;
    const save = () => {
      if (apiRef.current.getRowWithUpdatedValues(params.row.id, 'productId').productId) {
        setRowModesModel({ ...rowModesModel, [params.row.id]: { mode: GridRowModes.View } });
      } else {
        setCannotAddProductOpen(true);
      }
    };

    return (
      <Stack direction="row" alignItems="center" gap={1}>
        {isRowInEditMode ? (
          <>
            <IconButton
              variant="ghost"
              onClick={save}
              icon={CheckOutlined}
              //disabled={saveDisabled}
            />
            <IconButton variant="ghost" onClick={handleCancelClick(params.row.id)} icon={CloseOutlined} />
          </>
        ) : (
          <>
            <IconButton variant="ghost" onClick={handleEditClick(params.row.id)} icon={EditOutlined} />
            <IconButton variant="ghost" onClick={handleDeleteClick(params.row.id)} icon={DeleteOutlined} />
          </>
        )}
      </Stack>
    );
  };

  const actionsColumn = {
    field: 'actions',
    headerName: '',
    sortable: false,
    disableColumnMenu: true,
    renderCell: ActionsCell,
    width: 84,
    align: 'center',
  };

  const addButtonDisabled = vendorProducts.length === selectedProducts.length || !!tempoProducts.length;

  return (
    <>
      {cloneElement(children, {
        extraColumns: [actionsColumn],
        dataGridExtraProps: {
          rows: productsToUse,
          hideFooter: false,
          editMode: 'row',
          isCellEditable: () => true,
          rowModesModel,
          onRowEditStart,
          onRowEditStop,
          processRowUpdate,
          componentsProps: {
            footer: {
              disabledButton: addButtonDisabled,
              onAddClick: onAddRow,
              color: children.props?.dataGridExtraProps?.color,
            },
          },
          ...children.props?.dataGridExtraProps,
        },
        ...rest,
      })}
      <ConfirmationDialog
        isOpen={confirmDeleteOpen}
        headerText={t('INTAKE_FORM.PRODUCTS_TABLE.REMOVE_PRODUCT_DIALOG.HEADING')}
        bodySecondaryText={t('INTAKE_FORM.PRODUCTS_TABLE.REMOVE_PRODUCT_DIALOG.MESSAGE')}
        secondaryButtonText={t('INTAKE_FORM.PRODUCTS_TABLE.REMOVE_PRODUCT_DIALOG.CANCEL')}
        secondaryButtonAction={handleCanceledDelete}
        primaryButtonText={t('INTAKE_FORM.PRODUCTS_TABLE.REMOVE_PRODUCT_DIALOG.YES')}
        primaryButtonAction={handleConfirmedDelete}
      />
      <ConfirmationDialog
        isOpen={cannotAddProductOpen}
        bodySecondaryText={t('INTAKE_FORM.PRODUCTS_TABLE.CANNOT_ADD_DIALOG.MESSAGE')}
        // secondaryButtonText={t('CANCEL')}
        // secondaryButtonAction={handleCanceledDelete}
        primaryButtonText={t('INTAKE_FORM.PRODUCTS_TABLE.CANNOT_ADD_DIALOG.OK')}
        primaryButtonAction={() => setCannotAddProductOpen(false)}
      />
    </>
  );
};

export default WithActions;
