import React, { forwardRef } from 'react';
import styled from '@mui/material/styles/styled';
import { useTranslation } from 'react-i18next';
import Text from '../../Text';
import i18n from '../../../translate';

const DividerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  backgroundColor: 'transparent',
  padding: 0,
  height: '1px',
  position: 'relative',
}));

const DividerLine = styled('div')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  borderBottom: `1px solid ${theme.palette.secondary.color2}`,
  marginRight: theme.spacing(1),
}));

const DividerText = styled('span')(({ theme }) => ({
  color: theme.palette.secondary.color2,
  fontWeight: 'bold',
  marginRight: theme.spacing(1),
}));

const NewMessageDivider = forwardRef((_, ref: React.Ref<HTMLDivElement>) => {
  const { t } = useTranslation(undefined, { i18n });

  return (
    <DividerContainer ref={ref}>
      <DividerLine />
      <DividerText>
        <Text size="XS" variant="label">
          {t('NEW')}
        </Text>
      </DividerText>
    </DividerContainer>
  );
});

export default NewMessageDivider;
