import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import { useDebouncedCallback } from 'use-debounce';
import { Stack, styled } from '@mui/material';
import Highcharts from 'highcharts';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import { ChartPortal, fromChartPortal } from '@vertice/core/src/components/charts/components/ChartPortal';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import type { HighchartsPoint } from '@vertice/core/src/components/charts/highcharts-specific/types';
import { useChartRef } from '@vertice/core/src/components/charts/highcharts-specific/utils/useChartRef';
import { useChartPortalReposition } from '@vertice/core/src/components/charts/highcharts-specific/utils/useChartPortalReposition';
import Text from '@verticeone/design-system/src/components/Text';
import ColorSquare from '@vertice/core/src/components/charts/components/ColorSquare';
import { useFormatPercentage } from '@vertice/core/src/utils/formatting/percentage';
import Tile from './Tile';

type RiAgentInventoryDonutChartProps = {
  name: string;
  categories: Array<{
    color: string;
    name: string;
    y: number;
  }>;
};

const MIN_CHART_WIDTH = 200;

const BoxWrapper = styled(Tile)(({ theme }) => ({
  width: `max(calc(33.3% - ${theme.spacing(6)}), calc(${MIN_CHART_WIDTH}px + ${theme.spacing(13)}))`,
  overflow: 'initial',
}));

const getDefaultOptions = (dataPoints: HighchartsPoint[], boxInnerWidth: number): Highcharts.Options => ({
  chart: {
    type: 'pie',
    plotBackgroundColor: 'transparent',
    backgroundColor: 'transparent',
    plotBorderWidth: 0,
    margin: [0, 0, 0, 0],
    spacing: [0, 0, 0, 0],
    height: Math.max(MIN_CHART_WIDTH, boxInnerWidth),
    width: Math.max(MIN_CHART_WIDTH, boxInnerWidth),
  },
  plotOptions: {
    pie: {
      size: Math.max(MIN_CHART_WIDTH, boxInnerWidth),
      innerSize: Math.max(MIN_CHART_WIDTH, boxInnerWidth) - 32,
    },
  },
  tooltip: {
    enabled: false,
  },
  subtitle: {
    useHTML: true,
    floating: true,
    verticalAlign: 'middle',
  },
  series: [
    {
      type: 'pie',
      data: dataPoints,
      dataLabels: {
        enabled: false,
      },
    },
  ],
});

const RiAgentInventoryDonutChart = ({ name, categories }: RiAgentInventoryDonutChartProps) => {
  const [boxInnerWidth, setBoxInnerWidth] = useState(0);
  const [chart, saveChartRef] = useChartRef();
  const boxRef = useRef<HTMLDivElement | null>(null);
  const applyStyledHighcharts = useStyledHighcharts();
  const formatPercentage = useFormatPercentage();

  const computeBoxInnerWidth = useCallback(() => {
    if (boxRef.current) {
      setBoxInnerWidth(
        boxRef.current.clientWidth -
          parseFloat(getComputedStyle(boxRef.current).paddingLeft) -
          parseFloat(getComputedStyle(boxRef.current).paddingRight)
      );
    }
    // eslint-disable-next-line
  }, [boxRef.current, setBoxInnerWidth]);

  const computeBoxInnerWidthWithDebounce = useDebouncedCallback(computeBoxInnerWidth, 500);

  useEffect(() => {
    computeBoxInnerWidth();
    window.addEventListener('resize', computeBoxInnerWidthWithDebounce);

    return () => {
      window.removeEventListener('resize', computeBoxInnerWidthWithDebounce);
    };
  }, [computeBoxInnerWidth, computeBoxInnerWidthWithDebounce]);

  const options = useMemo(() => {
    return buildOptions([
      applyStyledHighcharts,
      mergeOptions(getDefaultOptions(categories.filter((item) => Boolean(item.y)).reverse(), boxInnerWidth)),
      mergeOptions({
        subtitle: {
          text: fromChartPortal({ chart, portalKey: 'title' }),
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                return fromChartPortal({ chart, portalKey: `${(this as any).key}` });
              },
            },
          },
        },
      }),
    ]);
  }, [categories, applyStyledHighcharts, chart, boxInnerWidth]);

  useChartPortalReposition(chart, [options]);

  return (
    <BoxWrapper ref={boxRef}>
      <HighchartsReact highcharts={Highcharts} options={options} callback={saveChartRef} />
      <ChartPortal chart={chart} portalKey="title">
        <Text
          tag="h2"
          variant="heading"
          size="S"
          color="text1"
          children={name}
          textAlign="center"
          display="flex"
          maxWidth={boxInnerWidth}
        />
      </ChartPortal>
      <Stack gap={2}>
        {categories.map((category) => (
          <Stack key={category.name} direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" alignItems="center" gap={1}>
              <ColorSquare color={category.color} />
              <Text variant="body-regular" size="S" color="text1">
                {category.name}
              </Text>
            </Stack>
            <Text variant="body-bold" size="S" color="text1">
              {formatPercentage(category.y / 100)}
            </Text>
          </Stack>
        ))}
      </Stack>
    </BoxWrapper>
  );
};

export default RiAgentInventoryDonutChart;
