import React from 'react';
import type { DesignSystemSize } from '../../types';

type BreadcrumbsContextProps = {
  size?: DesignSystemSize;
};

const BreadcrumbsContext = React.createContext<BreadcrumbsContextProps>({});

export default BreadcrumbsContext;
