import React from 'react';
import styled from '@mui/material/styles/styled';
import ChevronRightIcon from '@mui/icons-material/ArrowForwardIos';
import type { LinkProps as MuiLinkProps } from '@mui/material/Link';
import type { DesignSystemColor, DesignSystemSize, CustomizedProps, TestProps } from '../../types';
import BaseLink from './BaseLink';
import IconWrapper from '../IconWrapper/IconWrapper';
import { testProps } from '../../utils/testProperties';

export type DefaultComponentType = 'a';

type SharedLinkProps<C extends React.ElementType = DefaultComponentType> = CustomizedProps &
  Omit<MuiLinkProps<C>, 'size' | 'color' | 'disabled'>;

export type LinkProps<C extends React.ElementType = DefaultComponentType> = TestProps & {
  size?: DesignSystemSize;
  color?: DesignSystemColor;
  disabled?: boolean;
  component?: C;
  withArrow?: boolean;
} & SharedLinkProps<C>;

const StyledIconWrapper = styled(IconWrapper)({
  opacity: '0.4',
});

const Link = <C extends React.ElementType = DefaultComponentType>({
  href,
  size = 'M',
  color = 'neutral',
  disabled = false,
  testId,
  children,
  withArrow = false,
  ...otherProps
}: LinkProps<C>) => (
  <BaseLink
    {...testProps(testId, 'link')}
    {...otherProps}
    $size={size}
    $color={color}
    aria-disabled={disabled ? true : undefined}
    href={disabled ? undefined : href}
    // underline to be controlled by the styles (text variant "link")
    underline="none"
  >
    {children}
    {withArrow && <StyledIconWrapper size={size} icon={ChevronRightIcon} />}
  </BaseLink>
);

export default Link;
