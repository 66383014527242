import React from 'react';
import MuiTable, { type TableProps as MuiTableProps } from '@mui/material/Table/Table';
import { TableContext } from './TableContext';
import styled from '@mui/material/styles/styled';
import { DesignSystemColor, DesignSystemSize, TestProps } from '../../types';
import { testProps } from '../../utils/testProperties';

export type TableProps = Omit<MuiTableProps, 'size'> &
  TestProps & {
    size?: DesignSystemSize;
    color?: DesignSystemColor;
  };

const StyledTable = styled(MuiTable)({
  width: '100%',
});

const Table = ({ children, size = 'M', color = 'neutral', testId, ...otherProps }: TableProps) => (
  <TableContext.Provider value={{ size, color }}>
    <StyledTable stickyHeader {...otherProps} {...testProps(testId, 'table')}>
      {children}
    </StyledTable>
  </TableContext.Provider>
);

export default Table;
