import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import {
  ForecastPrecisionType,
  useSpendByServiceLineQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import format from 'date-fns/format';
import { addMonths, startOfMonth } from 'date-fns';
import { useMemo } from 'react';
import { chain } from 'lodash';
import useDeferredQuery from '@vertice/core/src/utils/api/useDeferredQuery';
import { DATE_FORMAT, findFirstCurrency, sumPositiveTimeStreamValues } from '../../utils/graphDataUtils';
import LoadableAdvanced from '@vertice/utils/src/loadableAdvanced';
import { WithDrilldown, Point } from '@vertice/core/src/components/charts/chart-lib-agnostic/types';

const startDate = addMonths(startOfMonth(new Date()), -11);

export type SpendByServiceLineData = {
  currency?: string;
  costByServiceLine: WithDrilldown<Point>[];
};

const useSpendByServiceLineData = (): LoadableAdvanced<SpendByServiceLineData> => {
  const { accountId } = useSelector(getAccount);
  const { data: rawData, error } = useDeferredQuery(
    useSpendByServiceLineQuery,
    {
      accountId: accountId!,
      // Acceptable timezone inaccuracy: We're sending local timezone date to UTC endpoint
      startDate: format(startDate, DATE_FORMAT),
      precision: ForecastPrecisionType.Month,
    },
    { skip: !accountId, pollingInterval: 5000 },
    ({ costUsageQuery }) => costUsageQuery
  );

  const computed = useMemo(() => {
    if (!rawData) return undefined;
    return {
      currency: findFirstCurrency(rawData),
      costByServiceLine: chain(rawData)
        .flatMap((x) => x.values)
        .groupBy((x) => x.dimensions[0])
        .map((allInCategory, serviceLine) => ({
          name: serviceLine,
          y: sumPositiveTimeStreamValues(allInCategory),
          drilldown: chain(allInCategory)
            .groupBy((x) => x.dimensions[1])
            .map((allWithProduct, product) => ({
              name: product,
              y: sumPositiveTimeStreamValues(allWithProduct),
            }))
            .value(),
        }))
        .value(),
    };
  }, [rawData]);

  return {
    error: error,
    isEmpty: Boolean(rawData && rawData.length === 0),
    isLoading: !computed,
    data: computed,
  };
};

export default useSpendByServiceLineData;
