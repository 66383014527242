import React, { useMemo } from 'react';
import useContractLineageData from './useContractLineageData';
import { GridColDef } from '@mui/x-data-grid-pro';
import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { ContractListColumn } from '../ContractList/types';
import { useGetColumnBuilder } from '../ContractList/useGetColumnBuilder';
import DataGrid from '@verticeone/design-system/src/components/DataGrid';
import { StageIndicator } from '../StageIndicator/StageIndicator';
import { useTranslation } from 'react-i18next';
import NoRowsOverlay from '../NoRowsOverlay';
import NavigationCell from './NavigationCell';
import { AdditionalInfoFlag } from '../StageIndicator/AdditionalDetails';

const LinkedContractsList = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useContractLineageData();

  const { buildColumn } = useGetColumnBuilder();
  const columns: GridColDef<ViewOnContract>[] = useMemo(() => {
    const commonColumnConfig = {
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 100,
    };

    if (!buildColumn) {
      return [];
    }

    return [
      { ...buildColumn(ContractListColumn.ANNUAL_COST), ...commonColumnConfig },
      { ...buildColumn(ContractListColumn.SAVINGS), ...commonColumnConfig, minWidth: 150 },
      {
        ...buildColumn(ContractListColumn.STAGE),
        ...commonColumnConfig,
        minWidth: 180,
        renderCell: ({ row }) => (
          <StageIndicator
            contract={row.contract}
            flags={[AdditionalInfoFlag.PLANNED_FOR_EXPIRATION]} // Only 'Planned for expiration' flag should be visible
          />
        ),
      },
      { ...buildColumn(ContractListColumn.DEADLINE), ...commonColumnConfig },
      {
        field: 'navigation',
        headerAlign: 'center',
        headerName: '',
        width: 100,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ row }) => <NavigationCell contract={row.contract} />,
      },
    ] as GridColDef<ViewOnContract>[];
  }, [buildColumn]);

  return (
    <DataGrid<ViewOnContract>
      rows={data ?? []}
      loading={isLoading}
      loadingStyle="skeleton"
      loadingRowCount={3}
      columns={columns}
      getRowId={(row) => row.contract.record.contractId}
      sortingMode="client"
      disableRowSelectionOnClick
      getRowHeight={() => 60}
      autoHeight
      rowSelection={false}
      slots={{
        noRowsOverlay: () => <NoRowsOverlay text={t('SAAS.LINKED_CONTRACTS.NO_DATA')} />,
      }}
    />
  );
};

export default LinkedContractsList;
