import React, { forwardRef, PropsWithChildren } from 'react';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import { ifExpression } from '../../../utils/ifExpression';
import { labelContentGap, type StyledComponentProps, type TextFieldAreaProps } from './constants';
import Label, { getHoverState, StyledLabel } from './Label';

const StyledGhostWrapper = styled(Stack)<Omit<StyledComponentProps, '$size'>>(
  ({ theme, $color, $disabled, $hasError }) => ({
    '.textarea': {
      border: 'none',
      outline: 'none',
    },
    '&:hover, &:focus-within': ifExpression(!$disabled && !$hasError, {
      [StyledLabel]: getHoverState({ theme, $color, $disabled }),
    }),
    ...ifExpression($hasError && !$disabled, {
      [StyledLabel]: getHoverState({ theme, $color: 'error', $disabled }),
    }),
  })
);

const GhostWrapper = forwardRef<HTMLDivElement, PropsWithChildren<TextFieldAreaProps>>((props, ref) => (
  <StyledGhostWrapper
    gap={labelContentGap[props.size]}
    $color={props.color}
    $disabled={!!props.disabled}
    $hasError={!!props.hasError}
    $hasLabel={!!props.label}
  >
    <Stack ref={ref}>
      <Label {...props} />
    </Stack>
    {props.children}
  </StyledGhostWrapper>
));

export default GhostWrapper;
