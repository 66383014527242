import React, { useContext } from 'react';
import type { Theme } from '@mui/material/styles';
import styled from '@mui/material/styles/styled';
import MuiTableHead, { type TableHeadProps as MuiTableHeadProps } from '@mui/material/TableHead/TableHead';
import { getTextVariantStyle, type TextPropsCombination } from '../Text/utils';
import { TableContext, type TableContextProps } from './TableContext';
import { TableCellWrapper } from './TableCell';
import { testProps } from '../../utils/testProperties';
import { TestProps } from '../../types';

export type TableHeadProps = MuiTableHeadProps & TestProps;

type StyledTableHeadProps = {
  theme: Theme;
  $size: TextPropsCombination['size'];
  $color: TableContextProps['color'];
};

export const StyledTableHead = styled(MuiTableHead)<StyledTableHeadProps>(({ theme, $color, $size: size }) => ({
  // It is not supported to set shadow/background to the whole table head. We need to set it per each cell.

  [TableCellWrapper]: {
    backgroundColor: theme.palette.input.color1,
    color: theme.palette[$color].color2,
    boxShadow: theme.palette.global.getShadow({ color: 'core', type: 'soft', depth: '2z', distance: '20' }),
    // Clip the shadow so that it doesn't bleed into neighbour cells
    clipPath: 'inset(0 0 -20px 0)',
    ...getTextVariantStyle({ variant: 'body-regular', size } as TextPropsCombination),
  },
}));

const TableHead = ({ children, testId, ...otherProps }: TableHeadProps) => {
  const { size, color } = useContext(TableContext);

  return (
    <StyledTableHead {...otherProps} {...testProps(testId, 'table-head')} $size={size} $color={color}>
      {children}
    </StyledTableHead>
  );
};

export default TableHead;
