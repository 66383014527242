import { Stack } from '@mui/material';
import React, { useId } from 'react';
import TextFieldCaption from '@verticeone/design-system/src/components/TextFieldCaption';
import FormControl from '@mui/material/FormControl';
import {
  useIsContractFieldEditable,
  useIsContractFieldRequired,
  useIsContractFieldVisible,
} from '../../hooks/fieldsRulesHooks';
import { useTranslation } from 'react-i18next';
import { ContractFormData, FormPlusComputedFieldPaths } from '../../types';
import useFormatContractCurrency from '../../hooks/useFormatContractCurrency';
import { useWatch } from 'react-hook-form';
import LargeValue from '../LargeValue';
import FormDecimalNumberField from '../../../../forms/fields/FormDecimalNumberField';

const NegotiatedCostContractFormEntry = () => {
  const { t } = useTranslation();
  const formatContractCurrency = useFormatContractCurrency();
  const id = useId();

  const annualCostWithVertice = useWatch<ContractFormData>({ name: 'parts.overview.annualCostWithVertice' });
  const totalCostValueWithVertice = useWatch<ContractFormData>({ name: 'parts.overview.totalCostValueWithVertice' });

  const modelAnnualCostWithVerticePath: FormPlusComputedFieldPaths =
    'parts.model.cost.configuration.withVertice.model.annual_cost';
  const isModelAnnualCostWithVerticeVisible = useIsContractFieldVisible(modelAnnualCostWithVerticePath);
  const isModelAnnualCostWithVerticeRequired = useIsContractFieldRequired(modelAnnualCostWithVerticePath);
  const isModelAnnualCostWithVerticeEditable = useIsContractFieldEditable(modelAnnualCostWithVerticePath);

  if (!isModelAnnualCostWithVerticeVisible) return null;

  return (
    <FormControl variant="outlined" fullWidth>
      <Stack gap={1}>
        <TextFieldCaption
          htmlFor={id}
          label={t('ENTITIES.CONTRACT.LABELS.NEGOTIATED_COST')}
          size="XS"
          required={isModelAnnualCostWithVerticeEditable && isModelAnnualCostWithVerticeRequired}
          tooltip={{
            title: t('ENTITIES.CONTRACT.LABELS.NEGOTIATED_COST'),
            content: t('ENTITIES.CONTRACT.TOOLTIPS.NEGOTIATED_COST'),
          }}
        />
        {isModelAnnualCostWithVerticeEditable ? (
          <FormDecimalNumberField
            name={modelAnnualCostWithVerticePath}
            id={id}
            required={isModelAnnualCostWithVerticeRequired}
          />
        ) : (
          <LargeValue
            id={id}
            primaryValue={formatContractCurrency(annualCostWithVertice)}
            primaryTestId={'annualCostWithVerticeValue'}
            primaryColor="text1"
            secondaryValue={
              totalCostValueWithVertice
                ? t('ENTITIES.CONTRACT.LABELS.N_TCV', { value: formatContractCurrency(totalCostValueWithVertice) })
                : undefined
            }
            secondaryTestId={'totalCostValueWithVerticeValue'}
          />
        )}
      </Stack>
    </FormControl>
  );
};

export default NegotiatedCostContractFormEntry;
