import { useFormatDate } from '@vertice/core/src/utils/formatting/date';

type DateCellProps = {
  date: string;
};

export const DateCell = ({ date: dateString }: DateCellProps) => {
  const formatDate = useFormatDate();
  return formatDate(dateString);
};
