import React, { FC } from 'react';
import ProductsListBase, { ProductListProps as ProductsListBaseProps } from './ProductList';
import WithActions from './WithActions';
import VendorProductsContextProvider from './VendorProductsContextProvider';
import { ProductItem } from './types';
import { DesignSystemColor } from '@verticeone/design-system/src/types';

interface ProductsListProps extends ProductsListBaseProps {
  withActions?: boolean;
  excludeDeleteAction?: boolean;
  allowSavingFor?: Array<string>;
  currency?: string;
  vendorId?: string;
  selectedProducts: ProductItem[];
  setSelectedProducts: (newProducts: ProductItem[]) => void;
  color?: DesignSystemColor;
}

export const ProductsList: FC<ProductsListProps> = ({
  withActions,
  excludeDeleteAction,
  allowSavingFor,
  currency,
  vendorId,
  selectedProducts,
  setSelectedProducts,
  color = 'primary',
  dataGridExtraProps,
  ...rest
}) => {
  const listBase = (
    <ProductsListBase
      selectedProducts={selectedProducts}
      readOnlyMode={!withActions}
      dataGridExtraProps={{ color, ...dataGridExtraProps }}
      {...rest}
    />
  );
  return (
    <VendorProductsContextProvider currency={currency} vendorId={vendorId} selectedProducts={selectedProducts}>
      {withActions ? (
        <WithActions {...{ excludeDeleteAction, allowSavingFor, selectedProducts, setSelectedProducts }}>
          {listBase}
        </WithActions>
      ) : (
        listBase
      )}
    </VendorProductsContextProvider>
  );
};
