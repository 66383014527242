import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices';
import { useTranslation } from 'react-i18next';
import {
  type MonitoringValueCheckResult,
  useLatestOptimizationCheckQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';
import { productKeyToTitleMap } from '@vertice/dashboard/src/pages/Cloud/CloudRIOptimiser/utils';
import type { DataEnhanced, UseData } from '../../../types';
import { useCreateLabel } from '../../../utils';

type EC2Data = {
  region: string;
  instanceFamily: string;
  operatingSystem: string;
  preInstalledSw: string;
  tenancy: 'Shared' | 'Dedicated';
  licenseModel: string;
  sizeFlexibilityGroup: string;
  instanceSize: string;
  riInstanceNormalizationFactor: number;
  flexGroupMinSize: string;
  sizeFlexible: boolean;
  riCoverage: number;
  newRiCoverage: number;
  spCoverage: number;
  proposedNumberOfInstances: number;
  riUnits: number;
  spUnits: number;
  newRiUnits: number;
  totalUnits: number;
  onDemandUsage: number;
  newOnDemandUsage: number;
  reservedUsage: number;
  newReservedUsage: number;
  addedUnusedUsage: number;
  spUsage: number;
  onDemandCost: number;
  newOnDemandCost: number;
  reservedCost: number;
  newReservedCost: number;
  totalCost: number;
  savingsCost: number;
  spCost: number;
  savingsPercent: number;
  onDemandRate: number;
  effectiveRate: number;
  reservedUtilization: number;
  missingRiLookup: boolean;
  from: string;
  to: string;
};

export type EC2DataEnhanced = DataEnhanced<EC2Data>;

export const useEC2Data = (): UseData<EC2Data> => {
  const createLabel = useCreateLabel();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.OPTIMIZE' });
  const { accountId } = useSelector(getAccount);

  const { data, isFetching } = useLatestOptimizationCheckQuery(
    {
      accountId: accountId!,
      checkCode: 'EC2_RI_COVERAGE_LAST_DAYS',
    },
    {
      skip: !accountId,
    }
  );

  const computedData = useMemo(() => {
    if (
      data?.monitoringLatestQuery?.__typename !== 'MonitoringResult' ||
      data.monitoringLatestQuery?.items?.length === 0
    ) {
      return undefined;
    }

    return getTableData(
      // The array of all data is under the 'result' property, not under 'items'.
      // The 'items' property will always have only one array item.
      data.monitoringLatestQuery?.items?.[0]?.results as MonitoringValueCheckResult,
      {
        region: 'region',
        instance_family: 'instanceFamily',
        operating_system: 'operatingSystem',
        pre_installed_sw: 'preInstalledSw',
        tenancy: 'tenancy',
        license_model: 'licenseModel',
        size_flexibility_group: 'sizeFlexibilityGroup',
        instance_size: 'instanceSize',
        ri_instance_normalization_factor: 'riInstanceNormalizationFactor',
        flex_group_min_size: 'flexGroupMinSize',
        size_flexible: 'sizeFlexible',
        ri_coverage: 'riCoverage',
        new_ri_coverage: 'newRiCoverage',
        sp_coverage: 'spCoverage',
        proposed_number_of_instances: 'proposedNumberOfInstances',
        ri_units: 'riUnits',
        new_ri_units: 'newRiUnits',
        total_units: 'totalUnits',
        sp_units: 'spUnits',
        on_demand_usage: 'onDemandUsage',
        new_on_demand_usage: 'newOnDemandUsage',
        reserved_usage: 'reservedUsage',
        new_reserved_usage: 'newReservedUsage',
        added_unused_usage: 'addedUnusedUsage',
        sp_usage: 'spUsage',
        on_demand_cost: 'onDemandCost',
        new_on_demand_cost: 'newOnDemandCost',
        reserved_cost: 'reservedCost',
        new_reserved_cost: 'newReservedCost',
        total_cost: 'totalCost',
        savings_cost: 'savingsCost',
        sp_cost: 'spCost',
        savings_percent: 'savingsPercent',
        on_demand_rate: 'onDemandRate',
        effective_rate: 'effectiveRate',
        reserved_utilization: 'reservedUtilization',
        missing_ri_lookup: 'missingRiLookup',
        from: 'from',
        to: 'to',
      }
    ) as EC2Data[];
  }, [data]);

  return {
    isFetching,
    data: computedData?.map(
      (item): EC2DataEnhanced => ({
        ...item,
        id: `${item.instanceFamily}-${item.operatingSystem}-${item.region}`,
        isOverCommitted: item.riCoverage > item.newRiCoverage + 0.1,
        isUnderCommitted: item.riCoverage < item.newRiCoverage - 0.1,
        chips: {
          showCount: 4,
          items: [
            createLabel('PRODUCT', productKeyToTitleMap.ec2),
            createLabel('REGION', item.region),
            createLabel('OPERATING_SYSTEM', item.operatingSystem),
            createLabel(
              'INSTALLED_SOFTWARE',
              t(`CHIPS.PRE_INSTALLED_SOFTWARE_${item.preInstalledSw.toUpperCase()}`, {
                defaultValue: item.preInstalledSw,
              })
            ),
            createLabel('TENANCY', t(`CHIPS.TENANCY.${item.tenancy.toUpperCase()}`)),
            createLabel('SIZE_FLEXIBILITY', t(`CHIPS.SIZE_FLEXIBILITY.${item.sizeFlexible ? 'TRUE' : 'FALSE'}`)),
          ],
        },
      })
    ),
  };
};
