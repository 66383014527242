import React from 'react';
import { GoogleIcon } from '@vertice/assets';
import { useTranslation } from 'react-i18next';
import { INTEGRATION_ROUTES, ROUTES } from '@vertice/utils/src/constants/cfa/constants';
import UsageStatsSetupCard from '../UsageStatsSetupCard';

interface GoogleSetupCardProps {
  providerId: string;
}

const GoogleSetupCard = ({ providerId }: GoogleSetupCardProps) => {
  const { t } = useTranslation();

  return (
    <UsageStatsSetupCard
      providerId={providerId}
      providerName="Google"
      providerIcon={<GoogleIcon />}
      description={t('PREFERENCES.INTEGRATIONS.GOOGLE.DESCRIPTION')}
      wizardRoute={`${ROUTES.PREFERENCES}/${INTEGRATION_ROUTES.BASE_PATH}/${INTEGRATION_ROUTES.GOOGLE_SETUP}`}
    />
  );
};

export default GoogleSetupCard;
