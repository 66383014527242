import { TaskRow } from '../types';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useMemo } from 'react';
import { useListTasksPaginatedQuery } from '@vertice/slices/src/api/enhancedBffeWorkflowsAPI';
import { useAccountUsersById } from '@vertice/core/src/hooks/useAccountUsersById';
import { useLoggedUser } from '@verticeone/auth/src';
import { TaskStatus } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { getTaskRows } from '../taskRows';

export const useTasks = (onlyMyTasks: boolean, closed: boolean, searchValue: string) => {
  const { accountId } = useAccountContext();
  const { userId: loggedUserId } = useLoggedUser();

  const { data: tasksData, isLoading: isLoadingTasksData } = useListTasksPaginatedQuery({
    accountId,
  });
  const { usersById, isLoadingUsers } = useAccountUsersById();

  const isLoadingTasks = isLoadingUsers || isLoadingTasksData;

  const allTasksRows: TaskRow[] = useMemo(
    () => getTaskRows(tasksData?.items || [], usersById),
    [tasksData?.items, usersById]
  );

  const filteredTaskRows = useMemo(
    () =>
      allTasksRows
        .filter(
          (task) =>
            searchValue === '' ||
            task.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
            task.requestName?.toLowerCase().includes(searchValue.toLowerCase())
        )
        .filter((task) => closed === Array<TaskStatus>('COMPLETED', 'TERMINATED', 'FAILED').includes(task.status))
        .filter((task) => !onlyMyTasks || task.assignees.some((assignee) => assignee.id === loggedUserId)),
    [allTasksRows, closed, onlyMyTasks, searchValue, loggedUserId]
  );

  return { allTasksRows, filteredTaskRows, isLoading: isLoadingTasks };
};
