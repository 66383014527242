import { Box, CardProps } from '@mui/material';
import ContractCategorySelect from '../../../../pages/Dashboard/components/ContractCategorySelect';
import { DepartmentCosts } from '../../../../pages/Dashboard/components/DepartmentCosts';
import Card from '@verticeone/design-system/src/components/Card';
import React from 'react';
import { useDashboardContext } from '../../../../pages/Dashboard/DashboardContextProvider';

export type DepartmentsCardProps = CardProps;

export const DepartmentsCard = (props: DepartmentsCardProps) => {
  const { allowCategoryChange } = useDashboardContext();

  return (
    <Card p={6} gap={6} {...props}>
      {allowCategoryChange && (
        <Box width={256} alignSelf="flex-end">
          <ContractCategorySelect />
        </Box>
      )}
      <Box flexGrow={1} justifyContent="center">
        <DepartmentCosts />
      </Box>
    </Card>
  );
};
