import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import { useLatestOptimizationCheckQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useMemo } from 'react';
import LoadableAdvanced from '@vertice/utils/src/loadableAdvanced';
import { UnattachedIPv4Data } from './types';
import { prepareUnattachedIPv4Data } from './utils';
import useAccountNameMap from '../../hooks/useAccountNameMap';

const CODE = 'IP4_ADDRESSES';

const useUnattachedIPv4Data = (): LoadableAdvanced<UnattachedIPv4Data> => {
  const { accountId } = useSelector(getAccount);
  const { accountNameMap, error: accountError } = useAccountNameMap();

  const {
    data: rawData,
    error: dataError,
    isLoading,
  } = useLatestOptimizationCheckQuery(
    {
      accountId: accountId!,
      checkCode: CODE,
    },
    { skip: !accountId }
  );

  const computed = useMemo(() => prepareUnattachedIPv4Data(rawData, accountNameMap), [rawData, accountNameMap]);

  return {
    error: dataError || accountError,
    isEmpty: Boolean(!computed || computed.items.length === 0),
    isLoading,
    data: computed,
  };
};

export default useUnattachedIPv4Data;
