import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import useDeferredQuery from '@vertice/core/src/utils/api/useDeferredQuery';
import { usePriceListQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import LoadableAdvanced from '@vertice/utils/src/loadableAdvanced';
import { useMemo } from 'react';
import { getTableData } from '../../../utils/graphDataUtils';
import { termStringifier as ts } from '../../../utils/termStringifier';
import { ReservedInstance } from '../useRIUtilizationData';
import { productToDBEMap, ProductType } from './products';

export type RdsPriceList = {
  region: string;
  instanceFamily: string;
  size: string;
  databaseEngine: string;
  onDemandPrice: number;
  deploymentOption: string;
  license: string;
  databaseEdition: string | null;
  normalizationSizeFactor: number;
}[];

type InstanceFamilyFilterItem = {
  name: string;
  sizes: string[];
};

type RegionFilterItem = {
  region: string;
  instanceFamilies: InstanceFamilyFilterItem[];
};

export type Filter = RegionFilterItem[];

type RdsPriceListLazyQueryProps = {
  filter?: Filter;
};

export const stringifyInstanceFamilyFilterItem = ({ name, sizes }: InstanceFamilyFilterItem) => {
  // prettier-ignore
  return ts.and([
    ts.eq('instance_family', name),
    ts.or(sizes.map((size) => ts.eq('instance_size', size)))
  ]);
};

export const stringifyFilterItem = ({ region, instanceFamilies }: RegionFilterItem) => {
  // prettier-ignore
  return ts.and([
    ts.eq('region', region),
    ts.or(instanceFamilies.map(stringifyInstanceFamilyFilterItem))
  ]);
};

export const stringifyFilter = (filter?: Filter) => {
  return filter ? ts.or(filter.map(stringifyFilterItem)) : undefined;
};

export const enhanceDataWithOnDemandPrice = (preprocessedData: ReservedInstance[], priceList: RdsPriceList) => {
  return preprocessedData.map((item) => {
    const priceListItem = priceList.find((priceItem) => {
      const { databaseEngine, licenseModel } = productToDBEMap[item.product as ProductType] ?? {};
      const deploymentOption = item.multiAZ === true ? 'Multi-AZ' : 'Single-AZ';

      return (
        item.region === priceItem.region &&
        item.instanceFamily === priceItem.instanceFamily &&
        item.size === priceItem.size &&
        databaseEngine === priceItem.databaseEngine &&
        licenseModel === priceItem.license &&
        deploymentOption === priceItem.deploymentOption
      );
    });

    return {
      ...item,
      hourlyOnDemandRate: priceListItem?.onDemandPrice,
      normalizedUnits: priceListItem?.normalizationSizeFactor || item.normalizedUnits,
    };
  });
};

const useRdsPriceList = ({ filter }: RdsPriceListLazyQueryProps): LoadableAdvanced<RdsPriceList> => {
  const { accountId } = useSelector(getAccount);
  const stringifiedFilter = useMemo(() => stringifyFilter(filter), [filter]);
  const skip = !accountId || !stringifiedFilter;

  const { data, error, isLoading } = useDeferredQuery(
    usePriceListQuery,
    {
      accountId: accountId!,
      pricelist: 'aws_pricing_rds',
      filter: ts.and([stringifiedFilter]),
    },
    { skip: skip, pollingInterval: 5000 },
    ({ priceListQuery }) => priceListQuery
  );

  const pricelistData = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return getTableData(data, {
      region: 'region',
      instance_family: 'instanceFamily',
      instance_size: 'size',
      database_engine: 'databaseEngine',
      on_demand_hours: 'onDemandPrice',
      deployment_option: 'deploymentOption',
      license_model: 'license',
      database_edition: 'databaseEdition',
      normalization_size_factor: 'normalizationSizeFactor',
    }) as RdsPriceList;
  }, [data]);

  return {
    error: error,
    isEmpty: Boolean(pricelistData && pricelistData.length === 0),
    isLoading: isLoading && !skip,
    data: pricelistData,
  };
};

export default useRdsPriceList;
