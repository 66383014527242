import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GridRenderCellParams, useGridApiRef } from '@mui/x-data-grid-pro';
import { chain } from 'lodash';

import {
  useListSlackUsersQuery,
  useListAccountUsersQuery,
  useListSlackUserIdentitiesQuery,
  UserWithSuggestion,
  useListUserSuggestionsQuery,
  SlackUser,
} from '@vertice/slices';
import { useEditableDataGrid } from '@vertice/core/src/components/EditableDataGrid/useEditableDataGrid';
import { EditableDataGridContextProvider } from '@vertice/core/src/components/EditableDataGrid/EditableDataGridContext';
import DataGrid, { GridColDef } from '@verticeone/design-system/src/components/DataGrid';
import {
  getConnectedUser,
  SLACK_ENTITY_TYPE,
  SLACK_USER_FIELD,
  SlackConnections,
  SlackConnectionStatus,
} from '../../../common';
import { VerticeUserCell } from './VerticeUserCell';
import { SlackUserCell } from './SlackUserCell';
import { SlackUserSelectCell } from './SlackUserSelectCell';
import { StatusCell } from './StatusCell';
import { ActionCell } from './ActionCell';

type SlackUsersGridProps = {
  accountId: string;
};

const getSuggestedUsers = (
  userId: string,
  usersSuggestionData?: UserWithSuggestion[],
  slackUsersData?: SlackUser[]
) => {
  const usersWithSuggestions = usersSuggestionData
    ?.filter((user) => user.discoverySuggestions && user.discoverySuggestions.length > 0)
    .filter((user) =>
      user.discoverySuggestions?.find(
        (suggestions) => suggestions.filter((suggestion) => suggestion.entityType === SLACK_ENTITY_TYPE).length > 0
      )
    );
  const suggestedUsersData = usersWithSuggestions?.filter((user) => user.userId === userId);
  const suggestedUsersEntityIds = suggestedUsersData
    ?.map((user) => user.discoverySuggestions?.map((suggestion) => suggestion.map((s) => s.entityId)))
    .flat()
    .flat();

  return slackUsersData?.filter((user) => suggestedUsersEntityIds?.includes(user.userId));
};

export const SlackUsersGrid = ({ accountId }: SlackUsersGridProps) => {
  const { t } = useTranslation();
  const apiRef = useGridApiRef();

  const [pollingInterval, setPollingInterval] = useState(0);

  const { data: verticeUsersData, isLoading: isLoadingVerticeUsersData } = useListAccountUsersQuery({ accountId });
  const { data: slackUsersData, isLoading: isLoadingSlackUsersData } = useListSlackUsersQuery({ accountId });
  const {
    data: slackConnectedUserIdentitiesData,
    isLoading: isLoadingSlackConnectedUserIdentitiesData,
    refetch: refetchSlackConnectedUserIdentitiesData,
  } = useListSlackUserIdentitiesQuery(
    {
      accountId,
    },
    { pollingInterval: pollingInterval > 0 ? pollingInterval : undefined }
  );
  const { data: userSuggestionsData } = useListUserSuggestionsQuery({ accountId });

  const hasPendingConnectedUserIdentities = slackConnectedUserIdentitiesData?.items?.some(
    (user) => user.status === SlackConnectionStatus.PENDING
  );

  useEffect(() => {
    if (hasPendingConnectedUserIdentities !== undefined) {
      setPollingInterval(hasPendingConnectedUserIdentities ? 1000 : 0);
    }
  }, [hasPendingConnectedUserIdentities]);

  const editableDataGrid = useEditableDataGrid<SlackConnections>({
    apiRef,
    defaultMode: 'confirm',
    isRowEditable: (row) =>
      getConnectedUser(row.userId, slackConnectedUserIdentitiesData?.items)?.status !== SlackConnectionStatus.ACTIVE,
    isRowDeletable: () => false,
  });

  const columns: GridColDef<SlackConnections>[] = useMemo(
    () => [
      {
        field: 'verticeUser',
        headerName: t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.USERS_GRID_COL_VERTICE'),
        flex: 1,
        minWidth: 200,
        valueGetter: (params: GridRenderCellParams<SlackConnections>) => params.row.email,
        renderCell: (params) => <VerticeUserCell {...params} />,
      },
      {
        field: SLACK_USER_FIELD,
        headerName: t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.USERS_GRID_COL_SLACK'),
        flex: 1,
        minWidth: 200,
        editable: true,
        renderCell: (params) => <SlackUserCell connectedUsers={slackConnectedUserIdentitiesData?.items} {...params} />,
        renderEditCell: (params) => (
          <SlackUserSelectCell connectedUsers={slackConnectedUserIdentitiesData?.items} {...params} />
        ),
      },
      {
        field: 'status',
        headerName: t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.USERS_GRID_COL_STATUS'),
        flex: 1,
        maxWidth: 142,
        renderCell: (params) => <StatusCell connectedUsers={slackConnectedUserIdentitiesData?.items} {...params} />,
      },
      {
        field: 'action',
        headerName: '',
        flex: 1,
        maxWidth: 120,
        renderCell: (params) => (
          <ActionCell
            accountId={accountId}
            connectedUsers={slackConnectedUserIdentitiesData?.items}
            refetchConnectedUsers={refetchSlackConnectedUserIdentitiesData}
            {...params}
          />
        ),
      },
      ...(editableDataGrid.actionsColumn ? [editableDataGrid.actionsColumn] : []),
    ],
    [
      t,
      editableDataGrid.actionsColumn,
      slackConnectedUserIdentitiesData?.items,
      accountId,
      refetchSlackConnectedUserIdentitiesData,
    ]
  );

  const rows = useMemo(
    () =>
      chain(verticeUsersData?.users)
        .filter((user) => user.userStatus === 'ACTIVE')
        .orderBy(({ email }) => email?.toLocaleLowerCase(), 'asc')
        .map((user) => {
          const suggestedUsers =
            getSuggestedUsers(user.userId, userSuggestionsData?.items, slackUsersData?.items) ?? [];
          const hasSuggestedUsers = suggestedUsers.length > 0;

          return {
            ...user,
            slackUsers: [
              ...suggestedUsers,
              // TODO: Remove separator once the Select component supports groups
              hasSuggestedUsers
                ? { accountId: accountId, userId: 'SEPARATOR', userName: '', email: '', status: 'ACTIVE' }
                : { accountId: accountId, userId: 'SEPARATOR', userName: '', email: '', status: 'INACTIVE' },
              ...chain(slackUsersData?.items)
                .orderBy(({ email }) => email?.toLocaleLowerCase(), 'asc')
                .filter((item) =>
                  !hasSuggestedUsers
                    ? true
                    : suggestedUsers.some((suggestedItem) => suggestedItem.userId !== item.userId)
                )
                .value(),
            ],
          };
        })
        .value(),
    [accountId, slackUsersData?.items, userSuggestionsData?.items, verticeUsersData?.users]
  );

  return (
    <EditableDataGridContextProvider value={editableDataGrid.context}>
      <DataGrid
        {...editableDataGrid.dataGridProps}
        columns={columns}
        rows={rows}
        apiRef={apiRef}
        autoHeight
        disableColumnMenu
        disableColumnReorder
        getRowId={(row) => row.userId}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 25 },
          },
        }}
        loadingStyle="skeleton"
        loading={isLoadingVerticeUsersData || isLoadingSlackUsersData || isLoadingSlackConnectedUserIdentitiesData}
        noBorder
        noBorderRadius
        pagination
        slots={editableDataGrid.dataGridProps.slots}
      />
    </EditableDataGridContextProvider>
  );
};
