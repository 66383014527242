import { FC } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Accordion, { AccordionDetails, AccordionSummary } from '@verticeone/design-system/src/components/Accordion';
import { Comments } from '@verticeone/design-system/src/components/Comments';
import { useConversations } from '../../useConversations';

type ConversationProps = {
  requestId: string;
};

export const Conversation: FC<ConversationProps> = ({ requestId }) => {
  const { cursor, messages, isMessagePending, isLoading, sendMessage, updateCursor } = useConversations(requestId);
  const { t } = useTranslation();

  return (
    <Accordion size="XS" variant="label" defaultExpanded>
      <AccordionSummary title={t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.SECTIONS.COMMENTS')} />
      <AccordionDetails>
        <Stack height={800}>
          <Comments
            comments={messages}
            cursor={cursor}
            onSendComment={sendMessage}
            onUpdateCursor={updateCursor}
            isLoading={isLoading}
            isSending={isMessagePending}
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
