import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';
import { TEST_USER_CALENDLY_URL } from '@vertice/utils/src/constants/cfa/constants';
import { Loader } from '@vertice/components';
import { Account, useGetAccountQuery, useGetUserPublicInformationQuery } from '@vertice/slices';
import { concatenateName } from '@vertice/utils';
import styles from './AccountManagerSection.module.scss';
import { AuthType, useAuthentication } from '@verticeone/auth/src';
import Text from '@verticeone/design-system/src/components/Text';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

const LazyCalendlyButton = lazy(() => import('../../components/CalendlyButton/CalendlyButton'));

const AccountManagerSection = () => {
  const { t } = useTranslation();

  const { accountId } = useAccountContext();

  const { data: accountDetails, isLoading: isCompanyDataLoading } = useGetAccountQuery(
    { accountId: accountId! },
    { skip: !accountId }
  ) as { data: Account; isLoading: boolean };

  const { type: authType } = useAuthentication();

  const {
    data: managerProfile,
    isUninitialized: isManagerProfileUninitialized,
    isLoading: isManagerProfileLoading,
  } = useGetUserPublicInformationQuery(
    { userId: (accountDetails as Account)?.associatedSuccessManager! },
    { skip: !accountDetails || !(accountDetails as Account)?.associatedSuccessManager }
  );

  const calendlyUrl =
    window._configEnv.REACT_APP_PROFILE === 'production'
      ? managerProfile?.userProperties?.calendlyUrl
      : managerProfile?.userProperties?.calendlyUrl || TEST_USER_CALENDLY_URL;

  const loading = isCompanyDataLoading || isManagerProfileLoading || isManagerProfileUninitialized;

  return (
    <Stack gap={2}>
      <Text variant="heading" size="S">
        {t('KNOWLEDGE_BASE.YOUR_CSM')}
      </Text>
      <Box className={styles['account-manager-section']}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Box className={styles.contacts}>
              <Text variant="heading" size="S">
                {concatenateName(managerProfile?.firstName, managerProfile?.lastName)}
              </Text>
              {managerProfile?.email && (
                <Text variant="button" size="S">
                  <a className={styles.email} href={'mailto:' + managerProfile.email}>
                    {managerProfile.email}
                  </a>
                </Text>
              )}
            </Box>
            <Text variant="body-regular" size="M" className={styles.intro}>
              {t('KNOWLEDGE_BASE.AM_TEXT')}
            </Text>
            {calendlyUrl && authType !== AuthType.ASSUME_ROLE && (
              <Suspense fallback={null}>
                <LazyCalendlyButton calendlyUrl={calendlyUrl} sx={{ mb: 2 }} className={styles['schedule-button']}>
                  {t('KNOWLEDGE_BASE.SCHEDULE_CALL')}
                </LazyCalendlyButton>
              </Suspense>
            )}
          </>
        )}
      </Box>
    </Stack>
  );
};

export default AccountManagerSection;
