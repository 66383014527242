import React, { createContext, ReactElement, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { Box, Stack, styled } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import Button from '@verticeone/design-system/src/components/Button';
import { Dialog, DialogCloseButton, DialogWrapper } from '../Dialog';

type WizardOptions = {
  fullWidth: boolean;
};

type WizardProps = {
  initialStep: string;
  onClose?: () => void;
  closeButtonText?: string;
  options?: Partial<WizardOptions>;
  children: ReactNode[];
};

type WizardContextType = null | {
  goToStep: (stepId: string) => void;
  goBack: undefined | (() => void);
  previousSteps: string[];
  wizardOverlayWrapperRef: React.RefObject<Element>;
};

export const WizardContext = createContext<WizardContextType>(null);

export const useWizard = () => {
  const context = React.useContext(WizardContext);
  if (context === null) {
    throw new Error('useWizard must be used within a Wizard context');
  }
  return context;
};

const WizardOverlayWrapper = styled(Box)(({ theme }) => ({
  left: 0,
  right: 0,
  top: 0,
  zIndex: 1,
  position: 'absolute',
}));

export const Wizard = ({ children, initialStep, onClose, closeButtonText, options }: WizardProps) => {
  const [activeStep, setActiveStep] = useState<string>(initialStep);
  const [previousSteps, setPreviousSteps] = useState<string[]>([]);

  const goToStep = useCallback(
    (stepId: string) => {
      setPreviousSteps([...previousSteps, activeStep]);
      setActiveStep(stepId);
    },
    [activeStep, previousSteps]
  );
  const goBack = useMemo(() => {
    const previousStep = previousSteps[previousSteps.length - 1];
    if (previousStep) {
      return () => {
        setPreviousSteps(previousSteps.slice(0, previousSteps.length - 1));
        setActiveStep(previousStep);
      };
    }
    return undefined;
  }, [previousSteps]);

  const wizardOverlayWrapperRef = useRef<HTMLDivElement>(null);

  const wizardContextValue = useMemo(
    () => ({ goToStep, goBack, previousSteps, wizardOverlayWrapperRef }),
    [goBack, goToStep, previousSteps]
  );

  const currentStepContent = React.Children.toArray(children).find(
    (child) => (child as ReactElement).props.stepId === activeStep
  ) as ReactElement | null;

  return (
    <DialogWrapper>
      <WizardContext.Provider value={wizardContextValue}>
        <DialogCloseButton onClick={onClose} title={closeButtonText} />
        <Dialog fullWidth={currentStepContent?.props?.fullWidth || options?.fullWidth}>
          {currentStepContent ?? (
            <Stack p={10} direction="column" gap={5}>
              <Text variant="heading" size="M">
                {`Unknown step "${activeStep}"`}
              </Text>
              <Button variant="solid" color="primary" onClick={() => goToStep(initialStep)}>
                Back
              </Button>
            </Stack>
          )}
        </Dialog>
      </WizardContext.Provider>
      <WizardOverlayWrapper ref={wizardOverlayWrapperRef} />
    </DialogWrapper>
  );
};
