import { z } from 'zod';
import { formDataProductItemSchema, vendorSchema } from '../shared/schemas';
import { t } from 'i18next';
import { emailOrEmpty } from '@vertice/core/src/utils/validation/sharedSchemas';

export const zodSchema = z.object({
  renewalType: z.enum(['UPGRADE', 'FLAT_RENEWAL', 'DOWNGRADE']),
  vendor: vendorSchema,
  products: z.array(formDataProductItemSchema),

  targetSignDate: z.string(),
  contractCurrency: z.string(),
  approvedBudget: z.number().optional().nullish(),
  desiredContractLength: z.number().optional(),

  legalReviewRequired: z.boolean().optional(),
  securityReviewRequired: z.boolean().optional(),
  piiExpectedToBeHeld: z.boolean().optional(),

  additionalNotes: z.string().optional().nullish(),

  mainVendorContact: z.boolean().optional(),
  mainVendorContactName: z.string().optional(),
  mainVendorContactEmail: emailOrEmpty(t),

  contactVendorDirectly: z.boolean().optional(),
  discussMultiYearDeals: z.boolean().optional(),
  discussPlannedGrowth: z.boolean().optional(),
  discussCompetitors: z.boolean().optional(),
  discussCaseStudies: z.boolean().optional(),
});

export type FormData = z.infer<typeof zodSchema>;
