import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useListRequestTasksQuery } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { getTaskRows } from '../../task/taskRows';
import { useAccountUsersById } from '@vertice/core/src/hooks/useAccountUsersById';
import { useMemo } from 'react';

export const useRequestTasks = (requestId?: string, includeServiceTasks = false) => {
  const { accountId } = useAccountContext();
  const skip = !requestId;
  const {
    data: tasksData,
    isLoading: isLoadingTasks,
    isFetching: isFetchingTasks,
    refetch: refetchTasks,
  } = useListRequestTasksQuery({ accountId, requestId: requestId! }, { skip });
  const { usersById, isLoadingUsers } = useAccountUsersById({ skip });

  const relevantTasks = useMemo(
    () => (tasksData?.items || []).filter((task) => includeServiceTasks || task.type !== 'SERVICE'),
    [tasksData?.items, includeServiceTasks]
  );
  const allTasks = useMemo(() => getTaskRows(relevantTasks, usersById), [relevantTasks, usersById]);
  const activeTasks = allTasks.filter((task) => task.status === 'ACTIVE');

  return {
    allTasks,
    activeTasks,
    refetchTasks,
    isLoadingTasks: isLoadingUsers || isLoadingTasks,
    isFetchingTasks,
  };
};
