import React, { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';
import { AddOutlined } from '@mui/icons-material';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { Card, CardHeader } from '@verticeone/design-system/src/components/Card';
import Button from '@verticeone/design-system/src/components/Button';
import Text from '@verticeone/design-system/src/components/Text';
import Divider from '@verticeone/design-system/src/components/Divider';
import ChipButton from '@verticeone/design-system/src/components/ChipButton';
import type { DesignSystemColor } from '@verticeone/design-system/src/types';

import ContractListGrid from './Grid';
import { useContractGridContext } from './ContractContext';
import { DeleteContract, DiscardChanges, EditContract, SaveContract } from './buttons';
import { useDateRangeFormatter } from '../../../CloudAnalytics/tabs/CloudAccountsTab/utils';
import type { Contract } from '../useContractListData';

dayjs.extend(isBetween);

type LayoutProps = {
  contract: Contract;
  expandGrid: boolean;
  contractNo: number;
};

type Status = {
  isSet: boolean;
  color: DesignSystemColor;
  textKey: 'LIVE' | 'EXPIRED';
};

const Layout = ({ contract: { id, startDate, endDate, years, state }, expandGrid, contractNo }: LayoutProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EDP_CONTRACT_GRID' });
  const formatDateRange = useDateRangeFormatter();
  const { actions, isExpanded, setIsExpanded, isEditing } = useContractGridContext();
  const boxRef = useRef<HTMLDivElement>();
  const status = useMemo<Status>(() => {
    const isActive = dayjs().isBetween(startDate, endDate);

    return {
      isSet: !!startDate && !!endDate,
      color: isActive ? 'tertiary' : 'neutral',
      textKey: isActive ? 'LIVE' : 'EXPIRED',
    };
  }, [endDate, startDate]);

  useLayoutEffect(() => {
    setIsExpanded(expandGrid);
  }, [expandGrid, setIsExpanded]);

  useEffect(() => {
    if (state === 'new') {
      actions.startEdit();
    }
  }, [state, actions]);

  return (
    <Card>
      <CardHeader size="S" density="high" noDivider>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="column" gap={1}>
            <Stack alignItems="center" direction="row" gap={1}>
              <Text variant="body-bold" size="M" color="text1">
                {t('HEADER', { contractNo })}
              </Text>
              {status.isSet && (
                <ChipButton variant="ghost" color={status.color} isActive={false} size="XS">
                  {t(`STATUS.${status.textKey}`)}
                </ChipButton>
              )}
            </Stack>
            {startDate && endDate && (
              <Text variant="body-bold" size="XS" color="text3">
                {formatDateRange(startDate, endDate)}
              </Text>
            )}
          </Stack>
          <Stack direction="row" gap={1.5}>
            <DeleteContract contractId={id} />
            <DiscardChanges contractId={id} state={state} />
            <EditContract />
            <SaveContract contractId={id} state={state} />
          </Stack>
        </Stack>
      </CardHeader>
      <Stack direction="column" alignItems="flex-start">
        <Button variant="plain" size="S" color="neutral" onClick={() => setIsExpanded((prev) => !prev)}>
          {t(isExpanded ? 'HIDE_DETAILS' : 'SHOW_DETAILS')}
        </Button>
      </Stack>
      <Box ref={boxRef} maxHeight={isExpanded ? 'unset' : 0} sx={{ transition: 'max-height 0.25s ease-out' }}>
        <ContractListGrid data={years} />
        {isEditing && (
          <>
            <Divider />
            <Box p={4}>
              <Button variant="ghost" size="S" color="tertiary" onClick={actions.addRow}>
                <AddOutlined /> {t('BUTTONS.ADD_YEAR')}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Card>
  );
};

export default Layout;
