import { Children, isValidElement, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Paper, Stack, styled } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';

import backgroundGlowImage from '@vertice/core/src/components/Dialog/assets/background_glow.svg';
import Button from '@verticeone/design-system/src/components/Button';
import styles from './Wizard.module.scss';

const DialogGlow = styled(Stack)({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundImage: `url(${backgroundGlowImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});

type WizardProps = {
  children: ReactNode | ReactNode[];
  activeStep?: string;
  onCancel?: EventHandler;
};

type EventHandler = () => Promise<void>;

export type WizardStepProps = {
  stepValue: string;
  setStep: (step: string) => void;
};

export type TestIntegrationParams = {
  users?: boolean;
};

const Wizard = ({ children, activeStep, onCancel: onCancelListener }: WizardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onCancel = () => {
    let chain = Promise.resolve();

    if (onCancelListener) {
      chain = chain.then(onCancelListener);
    }

    // All errors from upstream listeners are ignored, so that's why we use finally
    chain.finally(() => {
      navigate(-1);
    });
  };
  const stepContent = Children.toArray(children).find((child) =>
    isValidElement<WizardStepProps>(child) ? child.props.stepValue === activeStep : false
  );

  return (
    <>
      <Grid container className={styles.root}>
        <Grid item xs={2}>
          <Button variant={'outline'} size="M" onClick={onCancel}>
            <ArrowBackIos />
            {t('PREFERENCES.INTEGRATIONS.CANCEL_SETUP')}
          </Button>
        </Grid>
        <Grid item xs={8} className={styles.content}>
          {stepContent && <Paper className={styles.paper}>{stepContent}</Paper>}
        </Grid>
        <Grid item xs={2} />
      </Grid>
      <DialogGlow />
    </>
  );
};

export default Wizard;
