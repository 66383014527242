import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import dayjsUtcPlugin from 'dayjs/plugin/utc';
import dayjsTimezone from 'dayjs/plugin/timezone';
import {
  DateRange,
  DateRangeValidationError,
  PickersShortcutsItem,
  DateRangePicker as MuiDateRangePicker,
  SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro';
import { PickerChangeHandlerContext } from '@mui/x-date-pickers/models';

import { CustomizedProps, DesignSystemColor, DesignSystemSize, TestProps } from '../../types';
import CustomShortcuts from './components/CustomShortcuts';
import CustomCalendarHeader from './components/CustomCalendarHeader';
import CustomDateRangePickerDay from './components/CustomDateRangePickerDay';
import StaticDateRangePickerProvider from './context/StaticDateRangePickerContext';
import { StaticDateRangePickerVariant } from './types';
import CustomLayout from './components/CustomLayout/CustomLayout';
import { checkDateFormatAndWarn } from '../DatePicker/utils';
import { CustomLayoutProps, LayoutProps } from './components/CustomLayout/types';

dayjs.extend(dayjsUtcPlugin);
dayjs.extend(dayjsTimezone);

type ChangeContext = PickerChangeHandlerContext<DateRangeValidationError | string | null>;

export type DateRangePickerProps = TestProps &
  CustomizedProps & {
    // IMPLEMENTATION NOTE:
    // We don't want to leak the Dayjs type to the outside world, we always use YYYY-MM-DD strings.
    /** Date from in the format of YYYY-MM-DD */
    dateFrom?: string | null;
    /** Date to in the format of YYYY-MM-DD */
    dateTo?: string | null;
    /** Called when value changes & user clicks on accept button */
    onChange?: (dateFrom: string | null, dateTo: string | null, context: ChangeContext | null) => void;
    /** Minimum date allowed to be picked, in the format of YYYY-MM-DD */
    minDate?: string;
    /** Maximum date allowed to be picked, in the format of YYYY-MM-DD */
    maxDate?: string;
    id?: string;
    color?: DesignSystemColor;
    variant?: StaticDateRangePickerVariant;
    label?: string;
    size?: DesignSystemSize;
    shortcutsItems?: PickersShortcutsItem<DateRange<Dayjs>>[];
  };

const CustomSingleInputDateRangeField = styled(SingleInputDateRangeField)({
  width: '100%',
  '& fieldset': {
    display: 'none',
  },
});

const stringToDate = (date: string | Dayjs) => dayjs(date, { utc: true });
const parseNullableDate = (value: string | null | Dayjs): Dayjs | null => (value ? stringToDate(value) : null);

const DateRangePicker = ({
  dateFrom = null,
  dateTo = null,
  color = 'primary',
  variant = 'double',
  minDate,
  maxDate,
  size = 'M',
  testId,
  onChange,
  shortcutsItems,
}: DateRangePickerProps) => {
  const [localValues, setLocalValues] = useState<[Dayjs | null, Dayjs | null, ChangeContext | null]>([
    parseNullableDate(dateFrom),
    parseNullableDate(dateTo),
    null,
  ]);
  const [localDateFrom, localDateTo] = localValues;

  useEffect(() => {
    const from = parseNullableDate(dateFrom);
    const to = parseNullableDate(dateTo);

    setLocalValues((prev) => {
      if (!from?.isSame(prev[0]) || !to?.isSame(prev[1])) {
        return [from, to, prev[2]];
      }
      return prev;
    });
  }, [dateFrom, dateTo]);

  useEffect(() => {
    checkDateFormatAndWarn(dateFrom, 'dateFrom');
    checkDateFormatAndWarn(dateTo, 'dateTo');
    checkDateFormatAndWarn(minDate, 'minDate');
    checkDateFormatAndWarn(maxDate, 'maxDate');
  }, [dateFrom, dateTo, minDate, maxDate]);

  const handleChangeDateFrom = (newValue: Dayjs | null, context: PickerChangeHandlerContext<string | null>) => {
    if (!context.validationError) {
      setLocalValues([parseNullableDate(newValue), localDateTo, context]);
    }
  };

  const handleChangeDateTo = (newValue: Dayjs | null, context: PickerChangeHandlerContext<string | null>) => {
    if (!context.validationError) {
      setLocalValues([localDateFrom, parseNullableDate(newValue), context]);
    }
  };

  return (
    <StaticDateRangePickerProvider
      color={color}
      size={size}
      minDate={minDate ? stringToDate(minDate) : undefined}
      maxDate={maxDate ? stringToDate(maxDate) : undefined}
      dateFormat="DD/MM/YYYY"
    >
      <MuiDateRangePicker<Dayjs>
        calendars={variant === 'single' ? 1 : 2}
        shouldDisableDate={(day) => {
          if (minDate && day.isBefore(stringToDate(minDate), 'day')) {
            return true;
          }
          return !!(maxDate && day.isAfter(stringToDate(maxDate), 'day'));
        }}
        value={[localDateFrom, localDateTo]}
        dayOfWeekFormatter={(date) => date.format('dd')}
        onChange={([from, to], context) => {
          setLocalValues([from, to, context]);
          onChange?.(from?.format('YYYY-MM-DD') || null, to?.format('YYYY-MM-DD') || null, context);
        }}
        slots={{
          shortcuts: CustomShortcuts,
          calendarHeader: CustomCalendarHeader,
          layout: CustomLayout as React.ComponentType<LayoutProps>,
          day: CustomDateRangePickerDay,
          field: CustomSingleInputDateRangeField,
        }}
        slotProps={{
          actionBar: { hidden: true },
          layout: {
            localValues: [localDateFrom, localDateTo],
            onChangeDateFrom: handleChangeDateFrom,
            onChangeDateTo: handleChangeDateTo,
            testId,
            variant,
          } as CustomLayoutProps,
          toolbar: { hidden: true },
          shortcuts: {
            items: shortcutsItems,
          },
          field: {
            timezone: 'UTC',
          },
        }}
      />
    </StaticDateRangePickerProvider>
  );
};

export default DateRangePicker;
