import React from 'react';
import { IntegrationOktaIcon } from '@vertice/assets';
import { useTranslation } from 'react-i18next';
import { INTEGRATION_ROUTES, ROUTES } from '@vertice/utils/src/constants/cfa/constants';
import UsageStatsSetupCard from '../UsageStatsSetupCard';

interface OktaSetupCardProps {
  providerId: string;
}

const OktaSetupCard = ({ providerId }: OktaSetupCardProps) => {
  const { t } = useTranslation();

  return (
    <UsageStatsSetupCard
      providerId={providerId}
      providerName="Okta"
      providerIcon={<IntegrationOktaIcon />}
      description={t('PREFERENCES.INTEGRATIONS.OKTA.DESCRIPTION')}
      wizardRoute={`${ROUTES.PREFERENCES}/${INTEGRATION_ROUTES.BASE_PATH}/${INTEGRATION_ROUTES.OKTA_SETUP}`}
    />
  );
};

export default OktaSetupCard;
