import { default as MuiMenuItem, MenuItemProps as MuiMenuItemProps } from '@mui/material/MenuItem';
import styled from '@mui/material/styles/styled';
import { FC, ReactNode } from 'react';
import { useMenuContext } from './MenuContext';
import { MenuColor } from './types';
import Text from '../Text';
import Placeholder from '../Placeholder';
import { Stack } from '@mui/material';
import { StyledCheckboxWrapper } from '../Checkbox/Checkbox';

const StyledMenuItem = styled(MuiMenuItem)<{ $color: MenuColor; $isError?: boolean }>(
  ({ $color, $isError, theme: { palette } }) => ({
    '&:not(:last-child)': {
      boxShadow: `0px -1px 0px 0px inset ${palette.core.color3}`,
    },
    '&:hover:not(:last-child)': {
      backgroundColor: palette.input.color1,
      color: palette.text.color1,
    },
    '&.Mui-selected': {
      backgroundColor: palette[$color].color3,
      color: palette[$color].color2,
      boxShadow: `0px -1px 0px 0px inset ${palette[$color].color2}`,
    },
    '&.Mui-focusVisible, &.Mui-focusVisible.Mui-selected': {
      boxShadow: `0px 0px 0px 1px inset ${palette.core.color6}, 0px 0px 0px 4px inset ${palette[$color].color3}`,
      boxSize: 'content-box',
      backgroundColor: palette.input.color1,
      color: palette.text.color1,
    },
    '&.Mui-disabled': $isError
      ? {
          color: palette.error.color2,
          boxShadow: `0px 0px 0px 1px inset ${palette.error.color2}`,
          opacity: 1,
        }
      : {
          backgroundColor: palette.core.color3,
          color: palette.core.color5,
          opacity: 1,
        },
    '.MuiRadio-root': {
      width: 0,
      height: 0,
      margin: 0,
    },
    [StyledCheckboxWrapper]: {
      padding: 0,
      marginTop: '-1px',
    },
  })
);

type MenuItemProps = MuiMenuItemProps & {
  isError?: boolean;
  isLoading?: boolean;
  icon?: ReactNode;
};

export const MenuItem: FC<MenuItemProps> = ({ children, isError, isLoading, disabled, icon, ...restProps }) => {
  const { color } = useMenuContext();

  if (isLoading) {
    return (
      <StyledMenuItem $color={color} sx={{ pointerEvents: 'none' }}>
        <Placeholder variant="rounded" width="100%" height={21.7} />
      </StyledMenuItem>
    );
  }

  return (
    <StyledMenuItem $color={color} $isError={isError} disabled={disabled || isError} {...restProps}>
      <Text variant="body-regular">
        <Stack direction="row" alignItems="center" gap={0}>
          <Stack direction="row" alignItems="center" gap={2}>
            {children}
          </Stack>
          {icon}
        </Stack>
      </Text>
    </StyledMenuItem>
  );
};

export default MenuItem;
