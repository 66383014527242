import React, { FC, useState } from 'react';
import { createRequiredContext } from '@verticeone/design-system/src/utils/contexts';
import { OptionCellDialogContext, FieldItem } from './types';
import { useCustomFieldsListData } from './useCustomFieldsListData';

type CustomFieldsListContextProviderProps = {
  children: string | JSX.Element | JSX.Element[];
};

type CustomFieldsListContextType = {
  fields: FieldItem[];
  isLoading: boolean;
  isSaveInProgress: boolean;
  addField: (newField: FieldItem) => Promise<void>;
  updateField: (updatedField: FieldItem) => Promise<void>;
  dialogContext: OptionCellDialogContext;
  setDialogContext: (dialogContext: OptionCellDialogContext) => void;
};

const { CustomFieldsListContextProvider: RawCustomFieldsListContextProvider, useCustomFieldsListContext } =
  createRequiredContext<CustomFieldsListContextType, 'CustomFieldsList'>('CustomFieldsList');

export { useCustomFieldsListContext };

const CustomFieldsListContextProvider: FC<CustomFieldsListContextProviderProps> = ({ children }) => {
  const { data: fields, isLoading, isSaveInProgress, addField, updateField } = useCustomFieldsListData();
  const [dialogContext, setDialogContext] = useState<OptionCellDialogContext>();

  const context: CustomFieldsListContextType = {
    fields: fields || [],
    isLoading,
    isSaveInProgress,
    addField,
    updateField,
    dialogContext,
    setDialogContext,
  };

  return <RawCustomFieldsListContextProvider value={context}>{children}</RawCustomFieldsListContextProvider>;
};

export default CustomFieldsListContextProvider;
