import React from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import Text from '@verticeone/design-system/src/components/Text';
import { Product } from '@vertice/slices';
import Placeholder from '@verticeone/design-system/src/components/Placeholder';
import { useVendorProductsContext } from '../VendorProductsContextProvider';
import { keyBy } from 'lodash';

const NameCell = (params: GridCellParams<any, Product>) => {
  const { vendorProducts } = useVendorProductsContext();
  const vendorProductsMap = keyBy(vendorProducts, 'id');

  const productName = vendorProductsMap[params.row.productId]?.name;
  if (productName === undefined) {
    return <Placeholder width={100} />;
  }
  return <Text variant="body-regular">{productName}</Text>;
};

export default NameCell;
