import Stack from '@mui/material/Stack';
import useContractDocuments from '@vertice/core/src/modules/saas/contract/components/DocumentUpload/useContractDocuments';
import { Fileupload, FileList } from '@verticeone/design-system/src/components/Fileupload';
import Text from '@verticeone/design-system/src/components/Text';
import { useTranslation } from 'react-i18next';

export const ContractFileList = () => {
  const { files, downloadFile } = useContractDocuments();
  const { t } = useTranslation();

  if (!files || !files.length) return null;

  return (
    <Fileupload files={files}>
      <Text variant="caption" size="XS" color="text2" mb={1}>
        {t('APPLICATIONS.USAGE.LICENSE_DIALOG.CONTRACT_DOCUMENTS')}
      </Text>
      <Stack gap={4}>
        <FileList
          size="M"
          onFileNameClick={downloadFile}
          hideErrorFiles
          dynamicFileNameLength
          hiddenColumns={['size', 'remove']}
        />
      </Stack>
    </Fileupload>
  );
};
