import { Box, Stack } from '@mui/material';
import styles from './common.module.scss';
import { DiscoveryAndUsage } from './DiscoveryAndUsage';
import SecurityAccess from './SecurityAccess';
import CloudCostOptimization from './CloudCostOptimization';
import Accounting from './Accounting';
import { Alerting } from './Alerting';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';

const Integrations = () => {
  const { isEnabled, getFeature } = useFeatures();
  const usageAnalyticsFeatureEnabled = isEnabled(FEATURES.USAGE_ANALYTICS);
  const ssoFeatureEnabled = isEnabled(FEATURES.SSO);
  const awsFeatureEnabled = isEnabled(FEATURES.AWS_COST_INSIGHT);
  const integrationsFeatureEnabled = isEnabled(FEATURES.INTEGRATIONS);
  const slackFeatureEnabled = isEnabled(FEATURES.INTEGRATION_SLACK);

  const mergeIntegrationFeature = getFeature(FEATURES.INTEGRATION_MERGE);
  const mergeIntegrationProperties = mergeIntegrationFeature?.properties;
  const mergeAccountingIntegrationAllowed = mergeIntegrationFeature && mergeIntegrationProperties?.accounting;

  return (
    <Stack gap={8}>
      {usageAnalyticsFeatureEnabled && (
        <Box className={styles.section}>
          <DiscoveryAndUsage />
        </Box>
      )}
      {ssoFeatureEnabled && (
        <Box className={styles.section}>
          <SecurityAccess />
        </Box>
      )}
      {awsFeatureEnabled && (
        <Box className={styles.section}>
          <CloudCostOptimization />
        </Box>
      )}
      {integrationsFeatureEnabled && mergeAccountingIntegrationAllowed && (
        <Box className={styles.section}>
          <Accounting />
        </Box>
      )}
      {slackFeatureEnabled && (
        <Box className={styles.section}>
          <Alerting />
        </Box>
      )}
    </Stack>
  );
};

export default Integrations;
