import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-pro';

import { useAdminConnectUserIdentityMutation, useRevokeUserAuthorizationMutation, UserIdentity } from '@vertice/slices';
import Button from '@verticeone/design-system/src/components/Button';
import { getConnectedUser, SLACK_USER_FIELD, SlackConnections, SlackConnectionStatus } from '../../../common';
import { UnlinkUserDialog } from './UnlinkUserDialog';

type ActionCellProps = {
  accountId: string;
  connectedUsers?: UserIdentity[];
  refetchConnectedUsers: () => void;
} & GridRenderCellParams<SlackConnections>;

export const ActionCell = ({ accountId, connectedUsers, refetchConnectedUsers, ...params }: ActionCellProps) => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();

  const {
    row: { userId },
  } = params;

  const [connectUser, { isLoading: isConnectUserLoading }] = useAdminConnectUserIdentityMutation();
  const [disconnectUser, { isLoading: isDisconnectUserLoading }] = useRevokeUserAuthorizationMutation();

  const [isUnlinkDialogOpened, setIsUnlinkDialogOpened] = useState(false);

  const selectedSlackUserEmail = apiRef.current.getCellValue(userId, SLACK_USER_FIELD);
  const connectionStatus = getConnectedUser(userId, connectedUsers)?.status;
  const isUserConnected = connectionStatus === SlackConnectionStatus.ACTIVE;
  const isUserPending = connectionStatus === SlackConnectionStatus.PENDING;

  const handleConnect = () => {
    void (async () => {
      await connectUser({
        accountId,
        userId,
        body: {
          userEmail: selectedSlackUserEmail,
        },
      });
      refetchConnectedUsers();
    })();
  };

  const handleDisconnect = () => {
    void (async () => {
      await disconnectUser({
        accountId,
        userId,
        body: {},
      });
      refetchConnectedUsers();
      setIsUnlinkDialogOpened(false);
    })();
  };

  return (
    <Stack flex={1} direction="row" justifyContent="center" gap={1}>
      <Button
        variant="outline"
        size="S"
        color="neutral"
        onClick={isUserConnected ? () => setIsUnlinkDialogOpened(true) : handleConnect}
        isLoading={isConnectUserLoading || isUserPending}
        disabled={connectionStatus !== SlackConnectionStatus.ACTIVE && !selectedSlackUserEmail}
      >
        {isUserConnected
          ? t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.UNLINK')
          : t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.LINK')}
      </Button>
      <UnlinkUserDialog
        isOpened={isUnlinkDialogOpened}
        isLoading={isDisconnectUserLoading}
        onSubmit={handleDisconnect}
        onClose={() => setIsUnlinkDialogOpened(false)}
      />
    </Stack>
  );
};
