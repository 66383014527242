import type { DesignSystemSize } from '../../../types';
import { Property } from 'csstype';

export type TextVariants =
  | 'display'
  | 'heading'
  | 'body-regular'
  | 'body-bold'
  | 'button'
  | 'link'
  | 'label'
  | 'caption'
  | 'code';

export type TextPropsCombination =
  | { variant: Exclude<TextVariants, 'code' | 'display'>; size?: DesignSystemSize }
  | { variant: Extract<TextVariants, 'code'>; size?: Extract<DesignSystemSize, 'M' | 'S'> }
  | { variant: Extract<TextVariants, 'display'>; size?: Extract<DesignSystemSize, 'L' | 'M' | 'S'> };

// prettier-ignore
type TextVariantProperties =
  & (Record<Exclude<TextVariants, 'code' | 'display'>, Record<DesignSystemSize, VariantProperties>>)
  & (Record<Extract<TextVariants, 'code'>, Record<Extract<DesignSystemSize, 'M' | 'S'>, VariantProperties>>)
  & (Record<Extract<TextVariants, 'display'>, Record<Extract<DesignSystemSize, 'L' | 'M' | 'S'>, VariantProperties>>);

type ExtraProperties = {
  textTransform?: Property.TextTransform;
  textDecoration?: Property.TextDecoration;
  fontFamily?: Property.FontFamily;
};

type VariantProperties = {
  fontWeight: Property.FontWeight;
  fontSize: Property.FontSize;
  lineHeight: Property.LineHeight;
  letterSpacing: Property.LetterSpacing;
} & ExtraProperties;

const makeVariantProperties = (
  fontWeight: number,
  fontSize: string,
  lineHeight: string,
  letterSpacing: string,
  extra?: ExtraProperties
): VariantProperties => ({
  fontWeight,
  fontSize,
  lineHeight,
  letterSpacing,
  fontFamily: 'Inter, sans-serif, serif',
  ...(extra ?? {}),
});

const fontWeightSemiBold = 600;
const fontWeightMedium = 500;
const fontWeightRegular = 400;

const textVariantProperties: TextVariantProperties = {
  code: {
    M: makeVariantProperties(fontWeightMedium, '1rem', '100%', '0em', { fontFamily: 'JetBrains-Mono' }),
    S: makeVariantProperties(fontWeightMedium, '0.875rem', '100%', '0em', { fontFamily: 'JetBrains-Mono' }),
  },
  display: {
    L: makeVariantProperties(fontWeightSemiBold, '4.5rem', '105%', '-0.03em'),
    M: makeVariantProperties(fontWeightSemiBold, '3.75rem', '105%', '-0.03em'),
    S: makeVariantProperties(fontWeightSemiBold, '3rem', '105%', '-0.03em'),
  },
  heading: {
    XL: makeVariantProperties(fontWeightSemiBold, '2.5rem', '115%', '-0.03em'),
    L: makeVariantProperties(fontWeightSemiBold, '2rem', '115%', '-0.02em'),
    M: makeVariantProperties(fontWeightSemiBold, '1.5rem', '120%', '-0.02em'),
    S: makeVariantProperties(fontWeightMedium, '1.25rem', '120%', '-0.01em'),
    XS: makeVariantProperties(fontWeightMedium, '1rem', '120%', '0em'),
    XXS: makeVariantProperties(fontWeightMedium, '0.875rem', '120%', '0.01em'),
  },
  'body-regular': {
    XL: makeVariantProperties(fontWeightRegular, '1.25rem', '125%', '-0.01em'),
    L: makeVariantProperties(fontWeightRegular, '1.125rem', '125%', '-0.01em'),
    M: makeVariantProperties(fontWeightRegular, '1rem', '130%', '0em'),
    S: makeVariantProperties(fontWeightRegular, '0.875rem', '120%', '0em'),
    XS: makeVariantProperties(fontWeightRegular, '0.75rem', '135%', '0.01em'),
    XXS: makeVariantProperties(fontWeightRegular, '0.625rem', '135%', '0.01em'),
  },
  'body-bold': {
    XL: makeVariantProperties(fontWeightSemiBold, '1.25rem', '125%', '-0.01em'),
    L: makeVariantProperties(fontWeightSemiBold, '1.125rem', '125%', '-0.01em'),
    M: makeVariantProperties(fontWeightSemiBold, '1rem', '130%', '0em'),
    S: makeVariantProperties(fontWeightSemiBold, '0.875rem', '120%', '0em'),
    XS: makeVariantProperties(fontWeightSemiBold, '0.75rem', '135%', '0.01em'),
    XXS: makeVariantProperties(fontWeightSemiBold, '0.625rem', '135%', '0.01em'),
  },
  button: {
    XL: makeVariantProperties(fontWeightSemiBold, '1.25rem', '100%', '-0.01em'),
    L: makeVariantProperties(fontWeightSemiBold, '1.125rem', '100%', '-0.01em'),
    M: makeVariantProperties(fontWeightSemiBold, '1rem', '100%', '0em'),
    S: makeVariantProperties(fontWeightSemiBold, '0.875rem', '100%', '0em'),
    XS: makeVariantProperties(fontWeightSemiBold, '0.75rem', '100%', '0.01em'),
    XXS: makeVariantProperties(fontWeightSemiBold, '0.625rem', '100%', '0.01em'),
  },
  link: {
    XL: makeVariantProperties(fontWeightRegular, '1.25rem', '125%', '-0.01em', { textDecoration: 'underline' }),
    L: makeVariantProperties(fontWeightRegular, '1.125rem', '125%', '-0.01em', { textDecoration: 'underline' }),
    M: makeVariantProperties(fontWeightRegular, '1rem', '130%', '0em', { textDecoration: 'underline' }),
    S: makeVariantProperties(fontWeightRegular, '0.875rem', '120%', '0em', { textDecoration: 'underline' }),
    XS: makeVariantProperties(fontWeightRegular, '0.75rem', '135%', '0.01em', { textDecoration: 'underline' }),
    XXS: makeVariantProperties(fontWeightRegular, '0.625rem', '135%', '0.01em', { textDecoration: 'underline' }),
  },
  label: {
    XL: makeVariantProperties(fontWeightSemiBold, '1.25rem', '100%', '0.1em', { textTransform: 'uppercase' }),
    L: makeVariantProperties(fontWeightSemiBold, '1.125rem', '100%', '0.1em', { textTransform: 'uppercase' }),
    M: makeVariantProperties(fontWeightSemiBold, '1rem', '100%', '0.1em', { textTransform: 'uppercase' }),
    S: makeVariantProperties(fontWeightSemiBold, '0.875rem', '100%', '0.1em', { textTransform: 'uppercase' }),
    XS: makeVariantProperties(fontWeightSemiBold, '0.75rem', '100%', '0.1em', { textTransform: 'uppercase' }),
    XXS: makeVariantProperties(fontWeightSemiBold, '0.625rem', '100%', '0.1em', { textTransform: 'uppercase' }),
  },
  caption: {
    XL: makeVariantProperties(fontWeightSemiBold, '1.25rem', '100%', '-0.01em'),
    L: makeVariantProperties(fontWeightSemiBold, '1.125rem', '100%', '-0.01em'),
    M: makeVariantProperties(fontWeightSemiBold, '1rem', '100%', '0em'),
    S: makeVariantProperties(fontWeightSemiBold, '0.875rem', '100%', '0em'),
    XS: makeVariantProperties(fontWeightSemiBold, '0.75rem', '100%', '0.01em'),
    XXS: makeVariantProperties(fontWeightSemiBold, '0.625rem', '100%', '0.01em'),
  },
};

export const getTextVariantStyle = ({ variant, size }: TextPropsCombination) => {
  if (variant === 'code' && (size === 'M' || size === 'S')) {
    return textVariantProperties[variant][size];
  }

  if (variant === 'display' && (size === 'L' || size === 'M' || size === 'S')) {
    return textVariantProperties[variant][size];
  }

  if (variant !== 'display' && variant !== 'code' && size) {
    return textVariantProperties[variant][size];
  }

  throw new Error(`Invalid combination of variant '${variant}' and size '${size}'.`);
};

export default getTextVariantStyle;
