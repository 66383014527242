import React, { FC } from 'react';
import Card, { CardHeader, CardHeaderTitle } from '@verticeone/design-system/src/components/Card';
import DataGrid from '@verticeone/design-system/src/components/DataGrid';
import { WorkflowRow } from './types';
import NoData from '@verticeone/design-system/src/components/NoData';
import { useGetServiceWorkflows, useWorkflowsColumns } from './workflowsHooks';
import { WorkflowsListSkeleton } from './ServiceWorkflowTableSkeleton';
import { useTranslation } from 'react-i18next';

type WorkflowTypeTableProps = {
  serviceName: string;
  serviceRef?: string;
  defaultWorkflowRef?: string;
};

export const ServiceWorkflowTable: FC<WorkflowTypeTableProps> = ({ serviceName, serviceRef, defaultWorkflowRef }) => {
  const { t } = useTranslation();
  const columns = useWorkflowsColumns();
  const serviceWorkflowData = useGetServiceWorkflows(serviceRef, defaultWorkflowRef);

  if (serviceWorkflowData.workflows === undefined) {
    return <WorkflowsListSkeleton serviceName={serviceName} />;
  }

  return (
    <Card>
      <CardHeader size="S" density="medium">
        <CardHeaderTitle text={serviceName} />
      </CardHeader>
      <DataGrid<WorkflowRow>
        color="neutral"
        columns={columns}
        rows={serviceWorkflowData.workflows || []}
        sortingMode="client"
        disableRowSelectionOnClick
        getRowHeight={() => 60}
        autoHeight
        noBorder
        noBorderRadius
        noRowsOverlayHeight={200}
        slots={{
          noRowsOverlay: () => <NoData header={t(`INTELLIGENT_WORKFLOWS.WORKFLOWS_LIST.NO_WORKFLOWS`)} />,
        }}
        initialState={{
          sorting: {
            sortModel: [
              { field: 'isDefault', sort: 'desc' },
              { field: 'workflowName', sort: 'asc' },
            ],
          },
          columns: {
            columnVisibilityModel: {
              isDefault: false,
            },
          },
        }}
      ></DataGrid>
    </Card>
  );
};
