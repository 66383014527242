import {
  GridApiPro,
  GridColDef,
  GridEventListener,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  MuiEvent,
} from '@mui/x-data-grid-pro';
import { MutableRefObject, SyntheticEvent, useMemo, useState } from 'react';
import { ActionsCell } from './ActionsCell';
import { EditabilityMode } from './types';
import { EditableDataGridContextType } from './EditableDataGridContext';
import { DataGridProps } from '@verticeone/design-system/src/components/DataGrid';
import { GridValidRowModel } from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';
import { AddItemFooter, AddItemFooterProps } from './AddItemFooter';
import { DeepPartial } from 'react-hook-form';

export type UseEditableDataGridParams<R> = {
  apiRef: MutableRefObject<GridApiPro>;
  defaultMode: EditabilityMode;
  validateRow?: (row: R) => { isValid: true; row?: R } | { isValid: false; message: string };
  isRowEditable?: (row: R) => boolean;
  isRowDeletable?: (row: R) => boolean;
  onErrorsChanged?: (errors: string[]) => void;
  onAddRow?: (row: R) => void;
  onUpdateRow?: (row: R) => void;
  onDeleteRow?: (id: GridRowId) => void;

  // Add Button props
  withAddButton?: boolean;
  addItemButtonLabel?: string;
  createTmpAddRow?: (id: string) => DeepPartial<R>;
};

export type UseEditableDataGridReturn<R extends GridValidRowModel> = {
  dataGridProps: Partial<DataGridProps<R>>;
  context: EditableDataGridContextType;
  actionsColumn?: GridColDef;
  tmpAddRow: null | R;
};

export const useEditableDataGrid = <R extends GridValidRowModel>({
  defaultMode,
  onErrorsChanged,
  apiRef,
  validateRow,
  isRowEditable,
  isRowDeletable,
  onAddRow,
  onUpdateRow,
  onDeleteRow,
  createTmpAddRow,
  withAddButton,
  addItemButtonLabel,
}: UseEditableDataGridParams<R>): UseEditableDataGridReturn<R> => {
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const [tmpAddRow, setTmpAddRow] = useState<R | null>(null);
  const tmpRowId = useMemo(() => randomId(), []);

  const setRowMode = (id: GridRowId, mode: GridRowModes, flags?: 'ignoreModifications') => {
    setRowModesModel((prev) => ({
      ...prev,
      [id]: { mode, ignoreModifications: flags === 'ignoreModifications' },
    }));
  };

  // OVERRIDES OF THE DEFAULT BEHAVIORS

  const handleBuiltinRowEditStart = (params: GridRowParams, event: MuiEvent<SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const handleBuiltinRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  // ROW ACTIONS + VALIDATION + SAVING

  const handleRowEditClick = (id: GridRowId) => {
    setRowMode(id, GridRowModes.Edit);
  };

  const handleRowCancelClick = (id: GridRowId) => {
    setTmpAddRow(null);
    setRowMode(id, GridRowModes.View, 'ignoreModifications');
  };

  const handleRowDeleteClick = (id: GridRowId) => {
    setRowMode(id, GridRowModes.View);
    onDeleteRow?.(id);
  };

  const handleRowSaveClick = (id: GridRowId) => {
    const updatedRow = apiRef.current.getRowWithUpdatedValues(id, '' /* not necessary in row-editing mode */);

    if (validateRow) {
      const validationResult = validateRow(updatedRow as R);
      if (!validationResult.isValid) {
        onErrorsChanged?.([validationResult.message]);
        return;
      } else {
        onErrorsChanged?.([]);
        setRowMode(id, GridRowModes.View);
      }
    } else {
      setRowMode(id, GridRowModes.View);
    }

    // The saving process then continues with the processRowUpdateOrCreate function below.
  };

  const processRowUpdateOrCreate = (updatedRow: R) => {
    // Validator can be used to refine the result as well...
    let effectiveUpdatedRow = updatedRow;

    if (validateRow) {
      const validationResult = validateRow(updatedRow as R);
      if (validationResult.isValid && validationResult.row) {
        effectiveUpdatedRow = validationResult.row;
      }
    }

    const effectiveUpdatedRowId = apiRef.current.getRowId(effectiveUpdatedRow);

    setTimeout(() => {
      if (effectiveUpdatedRowId === tmpRowId) {
        onAddRow?.(effectiveUpdatedRow);
      } else {
        onUpdateRow?.(effectiveUpdatedRow);
      }
      setTmpAddRow(null);
    });

    return effectiveUpdatedRow;
  };

  const handleAddRowClick = () => {
    setTmpAddRow(createTmpAddRow?.(tmpRowId) as R);
    setRowMode(tmpRowId, GridRowModes.Edit);
  };

  const actionsColumn: GridColDef = {
    field: 'actions',
    headerName: '',
    sortable: false,
    disableColumnMenu: true,
    renderCell: ActionsCell,
    width: 84,
    align: 'center',
  };

  return {
    dataGridProps: {
      editMode: 'row',
      isCellEditable: () => true,
      disableRowSelectionOnClick: true,
      onRowEditStart: handleBuiltinRowEditStart,
      onRowEditStop: handleBuiltinRowEditStop,
      rowModesModel,
      processRowUpdate: processRowUpdateOrCreate,
      slots: {
        footer: withAddButton ? AddItemFooter : undefined,
      },
      slotProps: {
        footer: withAddButton
          ? ({
              label: addItemButtonLabel,
              onClick: handleAddRowClick,
              disabled: tmpAddRow !== null,
            } as AddItemFooterProps)
          : undefined,
      },
      hideFooter: withAddButton ? false : undefined,
    },
    context: {
      mode: defaultMode,
      apiRef,
      setRowMode,
      handleRowEditClick,
      handleRowCancelClick,
      handleRowSaveClick,
      handleRowDeleteClick,
      isRowEditable: isRowEditable as (row: unknown) => boolean,
      isRowDeletable: isRowDeletable as (row: unknown) => boolean,
    },
    actionsColumn,
    tmpAddRow,
  };
};
