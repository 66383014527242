import { useAccountContext } from '../../../contexts/AccountContext';
import { AccountUser, useListAccountUsersQuery } from '@vertice/slices';
import { useCallback, useMemo } from 'react';
import { keyBy } from 'lodash';
import { UserOption } from '../../user/UserSelect/types';
import { parseUserRef } from '../../../hooks/workflows/refUtils';
import { isNotNil } from '../../../utils/validation/isNotNil';
import safePick from '../../../utils/objects/safePick';

const accountUserToSelectUser = (user: AccountUser): UserOption => ({
  id: user.userId,
  email: user.email ?? '',
  ...safePick(user, 'firstName', 'middleName', 'lastName'),
});

const useGetAssignableUsers = () => {
  const { accountId } = useAccountContext();
  const { data: accountUsersResponse, isLoading: usersLoading } = useListAccountUsersQuery(
    { accountId: accountId! },
    { skip: !accountId }
  );

  const usersById = useMemo(
    () => (accountUsersResponse ? keyBy(accountUsersResponse.users, 'userId') : {}),
    [accountUsersResponse]
  );

  const resolveUsersFromRefs = useCallback(
    (usersRefs: string[]): UserOption[] =>
      usersRefs
        .map((userRef) => {
          const { userId } = parseUserRef(userRef);
          const maybeUser = usersById[userId];
          return maybeUser ? accountUserToSelectUser(maybeUser) : null;
        })
        .filter(isNotNil),
    [usersById]
  );

  return useMemo(
    () => ({
      assignableUsers: (accountUsersResponse?.users ?? []).map(accountUserToSelectUser),
      isLoadingAssignableUsers: usersLoading,
      resolveUsersFromRefs,
    }),
    [accountUsersResponse?.users, resolveUsersFromRefs, usersLoading]
  );
};

export default useGetAssignableUsers;
