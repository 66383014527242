import { LatestOptimizationCheckQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { getTableData } from '../../utils/graphDataUtils';
import { chain } from 'lodash';
import { AWS_DEFAULT_CURRENCY } from '../../constants';
import { TableDataOfUnattachedIPv4 } from './types';
import { AccountNameMap } from '../../hooks/useAccountNameMap';

export type IPv4DataResult = {
  currency: string;
  items: TableDataOfUnattachedIPv4[];
};

export const prepareUnattachedIPv4Data = (
  rawData: LatestOptimizationCheckQuery | undefined,
  accountNameMap?: AccountNameMap
): IPv4DataResult | undefined => {
  if (
    !rawData ||
    rawData?.monitoringLatestQuery?.__typename !== 'MonitoringResult' ||
    !rawData.monitoringLatestQuery.items ||
    rawData.monitoringLatestQuery.items.length === 0 ||
    rawData.monitoringLatestQuery.items[0].results?.__typename !== 'MonitoringValueCheckResult'
  ) {
    return undefined;
  }

  const monitoringValueResult = rawData.monitoringLatestQuery.items[0].results;

  const tableData = getTableData(monitoringValueResult, {
    resource_id: 'resourceId',
    region: 'region',
    account_id: 'accountId',
    type: 'type',
    usage: 'usage',
    cost: 'cost',
    address_type: 'addressType',
    usage_type: 'usageType',
  }) as TableDataOfUnattachedIPv4[];

  const filteredItems = tableData.filter(
    (item) => ['ElasticIP', 'PublicIPv4'].includes(item.addressType) && item.usageType === 'Idle' && item.cost >= 1
  );
  const items = chain(filteredItems)
    .map((item) => {
      return {
        ...item,
        // translate account id to account name
        accountName: accountNameMap ? accountNameMap[item.accountId] : item.accountId,
      };
    })
    .orderBy('cost', 'desc')
    .value();

  return {
    currency: AWS_DEFAULT_CURRENCY,
    items,
  };
};
