import { useContext } from 'react';
import { ContractListFilterContext } from '../../../../contexts/ContractListFilterContext';
import { Stack } from '@mui/material';
import { Filter } from '../types';
import { SearchTextField } from './SearchTextField';

type GenericTextFilterProps = {
  name: Filter;
  placeholder?: string;
  testId?: string;
  width?: number;
};

const GenericTextFilter = ({ name, placeholder, testId, width = 240 }: GenericTextFilterProps) => {
  const { filterValues, setFilterValue } = useContext(ContractListFilterContext);
  const setLastSearch = setFilterValue(name);

  return (
    <Stack direction="column" width={width}>
      <SearchTextField
        value={filterValues?.[name] ?? ''}
        placeholder={placeholder}
        onChange={(e) => setLastSearch(e.target.value)}
        onClear={() => setLastSearch('')}
        testId={testId}
      />
    </Stack>
  );
};

export default GenericTextFilter;
