import { useExchangeCurrency } from './useExchangeCurrency';
import { useAccountSettings } from '../../hooks/useAccountSettings';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';

/**
 * The hook relies on account setting and exchange rates to be available before it's actually used
 * so the loading states are not handled
 **/

export const useExchangeToAccountCurrency = () => {
  const { exchangeCurrency } = useExchangeCurrency();
  const { data: accountSettings } = useAccountSettings();

  const accountCurrency = accountSettings?.preferredCurrency || DEFAULT_CURRENCY;

  const exchangeToAccountCurrency = (value: number, sourceCurrency: string) =>
    exchangeCurrency({ value, sourceCurrency, targetCurrency: accountCurrency }) || 0;

  return {
    exchangeToAccountCurrency,
    accountCurrency,
  };
};
