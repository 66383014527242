import React from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ROUTES } from '@vertice/utils/src/constants/cfa/constants';
import authStyle from '../../Auth.module.scss';
import style from './RedirectButton.module.scss';

type RedirectButtonProps = {
  to: (typeof ROUTES)[keyof typeof ROUTES];
  children: React.ReactNode;
};

const RedirectButton = ({ to, children }: RedirectButtonProps) => {
  const location = useLocation();
  const from = location.state || {};

  return (
    <div className={style.ResetPasswordWrapper}>
      <Link to={to} state={{ from }} replace className={clsx('uppercase', authStyle.navigation)}>
        <Typography variant="label-xs">{children}</Typography>
      </Link>
    </div>
  );
};

export default RedirectButton;
