import React, { useState } from 'react';
import type { LexicalCommand } from 'lexical';
import { FormatListBulleted, FormatListNumbered, type SvgIconComponent } from '@mui/icons-material';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import Button from '../../../IconButton';
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND } from '@lexical/list';
import { useToolbar } from '../ToolbarContext';

type Types = 'ul' | 'ol';

type FormatParagraphProps = {
  types: Array<Types>;
};

const typesDict: Record<Types, { command: LexicalCommand<void>; icon: SvgIconComponent }> = {
  ul: {
    command: INSERT_UNORDERED_LIST_COMMAND,
    icon: FormatListBulleted,
  },
  ol: {
    command: INSERT_ORDERED_LIST_COMMAND,
    icon: FormatListNumbered,
  },
};

const Paragraph = ({ types }: FormatParagraphProps) => {
  const { size, disabled } = useToolbar();
  const [editor] = useLexicalComposerContext();
  const [selectedType, setSelectedType] = useState<Types | null>();

  const handleChange = (type: Types) => () => {
    if (selectedType !== type) {
      setSelectedType(type);
      editor.dispatchCommand(typesDict[type].command, undefined);
    } else {
      setSelectedType(null);
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  return (
    <>
      {types.map((type) => (
        <Button
          size={size}
          disabled={disabled}
          key={type}
          variant="plain"
          onClick={handleChange(type)}
          isActive={selectedType === type}
          icon={typesDict[type].icon}
        />
      ))}
    </>
  );
};

export default Paragraph;
