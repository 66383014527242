import React, { useContext } from 'react';
import styled from '@mui/material/styles/styled';
import type { Theme } from '@mui/material/styles/createTheme';
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import Text from '../Text';

import BreadcrumbsContext from './BreadcrumbsContext';

export type BreadcrumbLinkProps<D extends React.ElementType = 'a'> = Omit<
  MuiLinkProps<D>,
  'active' | 'disabled' | 'truncated'
> & {
  active?: boolean;
  disabled?: boolean;
  truncated?: boolean;
  component?: D;
};

type StyledBreadcrumbLinkProps = {
  theme: Theme;
  $disabled: boolean;
  $active: boolean;
};

const StyledBreadcrumbLink = styled((props) => <MuiLink {...props} />)<StyledBreadcrumbLinkProps>(
  ({ theme, $disabled, $active }) => ({
    textDecoration: 'none',

    color: (() => {
      if ($disabled) {
        return theme.palette.inactive.color2;
      }
      if ($active) {
        return theme.palette.secondary.color1;
      }
      return theme.palette.neutral.color1;
    })(),
    cursor: 'default',
    '&[href]': {
      cursor: !$disabled ? 'pointer' : 'default',
    },
    '&[href]:hover': {
      cursor: !$disabled ? 'pointer' : 'default',
      color: theme.palette.neutral.hover.color1,
      textDecoration: 'underline',
      textDecorationColor: 'currentColor',
    },
    '&:not([href]):hover': {
      textDecoration: 'none',
    },
  })
);

const BreadcrumbLink = <C extends React.ElementType = 'a'>({
  active = false,
  disabled = false,
  children,
  truncated,
  ...otherProps
}: BreadcrumbLinkProps<C>) => {
  const { size } = useContext(BreadcrumbsContext);

  return (
    <StyledBreadcrumbLink {...otherProps} $active={active} $disabled={disabled}>
      <Text variant="body-regular" size={size}>
        {truncated ? '...' : children}
      </Text>
    </StyledBreadcrumbLink>
  );
};

export default BreadcrumbLink;
