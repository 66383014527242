import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { OutlinedInput, Stack } from '@mui/material';
import { emailRegexp, phoneRegexp } from '@vertice/utils';
import { Label } from '@vertice/components/src/Label';
import { AccountUser, UserSettings } from '@vertice/slices';
import { UserRoleType } from '@vertice/core/src/constants/userRoles';
import useUpdateUser, { UpdateUserResponse } from '@vertice/hooks/src/useUpdateUser';
import useCreateUser, { CreateUserResponse } from '@vertice/hooks/src/useCreateUser';
import TimezoneAutocomplete from '../TimezoneAutocomplete/TimezoneAutocomplete';
import styles from './UserForm.module.scss';
import { AppTypeContext } from '../contexts/AppTypeContext';
import { getDefaultUserRole } from './utils';
import { RoleSelector } from './RoleSelector';
import useLoggedUserAccountRoles from '@vertice/dashboard/src/hooks/useLoggedUserAccountRoles';

interface UserFormProps {
  formId: string;
  userData?: AccountUser;
  userSettings?: UserSettings;
  showTimeZone?: boolean;
  onUserSaved?: (contact: AccountUser) => void;
  setRequestInProgress?: (progress: boolean) => void;
}

interface UserFormFormType {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  jobTitle: string;
  userRole: string;
}

export interface FormType {
  settings: UserSettings;
  userData: UserFormFormType;
}

const UserForm: FC<UserFormProps> = ({
  formId,
  userData,
  userSettings,
  showTimeZone,
  onUserSaved,
  setRequestInProgress,
}) => {
  const { t } = useTranslation();
  const { updateUser } = useUpdateUser();
  const { createUser, defaultUserSettings } = useCreateUser();
  const { isIAT } = useContext(AppTypeContext);
  const { isUserAdmin } = useLoggedUserAccountRoles();

  const defaultValues = {
    settings: {
      timeZone: userSettings?.timeZone || '',
    },
    userData: {
      firstName: userData?.firstName || '',
      lastName: userData?.lastName || '',
      email: userData?.email || '',
      phoneNumber: userData?.phoneNumber || '',
      jobTitle: userData?.accountUserProperties?.jobTitle || '',
      userRole: getDefaultUserRole(userData),
    },
  };

  const methods = useForm<FormType>({ defaultValues });
  const { register, handleSubmit, control, setValue, watch } = methods;
  const role = watch('userData.userRole');

  const isEditMode = !!userData;

  const setProgress = (progress: boolean) => {
    if (setRequestInProgress) {
      setRequestInProgress(progress);
    }
  };

  const createUserWithData = async (formData: FormType) => {
    const { firstName, lastName, email, phoneNumber, jobTitle, userRole } = formData.userData;

    const userSettingsData = defaultUserSettings as UserSettings;
    if (formData.settings?.timeZone) {
      userSettingsData.timeZone = formData.settings.timeZone;
    }

    return createUser({
      firstName,
      lastName,
      email,
      ...(phoneNumber.trim().length && { phoneNumber }),
      role: userRole,
      jobTitle,
      userSettings: userSettingsData,
    });
  };

  const editUserWithData = async (formData: FormType) => {
    const { firstName, lastName, email, phoneNumber, jobTitle, userRole } = formData.userData;

    if (!userData) {
      return {};
    }

    return updateUser({
      userId: userData.userId,
      firstName,
      lastName,
      email,
      ...(phoneNumber.trim().length && { phoneNumber }),
      role: userRole,
      jobTitle,
      ...(formData.settings?.timeZone && { userSettings: formData.settings }),
    });
  };

  const onSubmit: SubmitHandler<FormType> = async (formData) => {
    const { firstName, lastName, email, phoneNumber, jobTitle } = formData.userData;

    setProgress(true);
    let response;
    if (userData) {
      response = await editUserWithData(formData);
    } else {
      response = await createUserWithData(formData);
    }
    setProgress(false);

    const successResponse = response as CreateUserResponse | UpdateUserResponse;
    if (successResponse?.data && onUserSaved) {
      onUserSaved({
        userId: successResponse.data?.userId!,
        userName: email,
        firstName,
        lastName,
        email,
        phoneNumber,
        accountUserProperties: {
          jobTitle,
        },
      });
    }
  };

  return (
    <Stack direction="row" className={styles['user-form']}>
      <Stack className={styles['user-form-column']}>
        <form id={formId} className={styles['user-form-content']} onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Label required htmlFor="userData.firstName" text={t('PREFERENCES.NEW_USER.LABELS.FIRST_NAME')} />
            <OutlinedInput
              className={styles['user-form-input']}
              fullWidth
              {...register('userData.firstName', { required: true })}
              id="userData.firstName"
            />
          </div>
          <div>
            <Label required htmlFor="userData.lastName" text={t('PREFERENCES.NEW_USER.LABELS.LAST_NAME')} />
            <OutlinedInput
              className={styles['user-form-input']}
              fullWidth
              {...register('userData.lastName', { required: true })}
              id="userData.lastName"
            />
          </div>
          <div>
            <Label required htmlFor="userData.email" text={t('PREFERENCES.NEW_USER.LABELS.EMAIL')} />
            <OutlinedInput
              className={styles['user-form-input']}
              fullWidth
              disabled={isEditMode}
              {...register('userData.email', { required: true, pattern: emailRegexp })}
              id="userData.email"
            />
          </div>

          <div>
            <Label htmlFor="userData.phoneNumber" text={t('PREFERENCES.NEW_USER.LABELS.PHONE_NUMBER')} />
            <OutlinedInput
              className={styles['user-form-input']}
              fullWidth
              {...register('userData.phoneNumber', { pattern: phoneRegexp })}
              id="userData.phoneNumber"
            />
          </div>

          {showTimeZone && (
            <div>
              <Label htmlFor="settings.timeZone" text={t('PREFERENCES.NEW_USER.LABELS.TIME_ZONE')} />
              <Controller
                render={({ field: { ref, value, onChange } }) => (
                  <TimezoneAutocomplete ref={ref} value={value} onChange={onChange} />
                )}
                name="settings.timeZone"
                control={control}
              />
            </div>
          )}

          <div>
            <Label required htmlFor="userData.jobTitle" text={t('PREFERENCES.NEW_USER.LABELS.JOB_TITLE')} />
            <OutlinedInput
              className={styles['user-form-input']}
              fullWidth
              {...register('userData.jobTitle', { required: true })}
              id="userData.jobTitle"
            />
          </div>
        </form>
      </Stack>

      <Stack className={styles['user-form-column']}>
        <div>
          <Label required text={t('PREFERENCES.NEW_USER.LABELS.ROLE_TYPE')} />
          <RoleSelector
            role={role as UserRoleType}
            onRoleChanged={(value: UserRoleType) => setValue('userData.userRole', value)}
            disabled={!isIAT && !isUserAdmin && isEditMode}
          />
        </div>
      </Stack>
    </Stack>
  );
};

export default UserForm;
