import React from 'react';
import { isNumber } from 'lodash';
import { Stack } from '@mui/material';
import { MuiEvent } from '@mui/x-data-grid-pro';
import type { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { DeleteOutline } from '@mui/icons-material';
import Text from '@verticeone/design-system/src/components/Text';
import DataGrid from '@verticeone/design-system/src/components/DataGrid';
import { DateRangePicker } from '@verticeone/design-system/src/components/DateRangePicker';
import { useFormatCurrency } from '@vertice/core/src/utils/formatting/currency';
import { useFormatPercentage } from '@vertice/core/src/utils/formatting/percentage';
import { useDateRangeFormatter } from '@vertice/dashboard/src/pages/Cloud/CloudAnalytics/tabs/CloudAccountsTab/utils';
import TextField from '@verticeone/design-system/src/components/TextField';
import Button from '@verticeone/design-system/src/components/Button';

import { useContractGridContext } from './ContractContext';
import { ContractYear } from '../useContractListData';
import { useTranslation } from 'react-i18next';

type ContractListGridProps = {
  data: Array<ContractYear>;
};

const ContractListGrid = ({ data }: ContractListGridProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EDP_CONTRACT_GRID.GRID' });
  const { apiRef, isEditing, actions } = useContractGridContext();
  const formatDateRange = useDateRangeFormatter();
  const formatCurrency = useFormatCurrency();
  const formatPercentage = useFormatPercentage();

  const handlePreventDefault = (_: any, event: MuiEvent) => {
    event.defaultMuiPrevented = true;
  };

  const tableColumns: Array<GridColDef<Partial<ContractYear>>> = [
    {
      field: 'year',
      headerName: t('COLUMN.YEAR'),
      editable: true,
      flex: 2,
      renderCell: ({ row }) => (
        <Stack gap={1} direction="row">
          {row.name && (
            <Text variant="body-regular" size="M" color="text1">
              {row.name}
            </Text>
          )}
          {row.startDate && row.endDate && (
            <Text variant="body-regular" size="M" color="text2">
              ({formatDateRange(row.startDate, row.endDate)})
            </Text>
          )}
        </Stack>
      ),
      renderEditCell: ({ id, field, row, api }) => (
        <DateRangePicker
          dateFrom={row.startDate}
          dateTo={row.endDate}
          onChange={(startDate, endDate) => {
            void api.updateRows([{ id, startDate, endDate }]);
            void api.setEditCellValue({ id, field, value: [startDate, endDate].filter((i) => !!i).join('_') });
          }}
        />
      ),
      valueGetter: ({ row }) => [row.startDate, row.endDate].filter((i) => !!i).join('_'),
    },
    {
      field: 'commitment',
      headerName: t('COLUMN.COMMITMENT'),
      editable: true,
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ value }) => (
        <Text variant="body-regular" size="M" color="text1">
          {formatCurrency(value ?? 0, { maximumFractionDigits: 0 })}
        </Text>
      ),
      renderEditCell: ({ id, field, api, value }) => (
        <TextField
          sx={{ width: '100%', input: { padding: '10px !important', textAlign: 'right' } }}
          type="number"
          variant="ghost"
          hiddenLabel
          value={value}
          placeholder="$0"
          onChange={(e) => {
            void api.updateRows([{ id, commitment: e.target.value }]);
            void api.setEditCellValue({ id, field, value: e.target.value });
          }}
        />
      ),
      valueGetter: ({ value }) => value ?? '',
    },
    {
      field: 'discount',
      headerName: t('COLUMN.DISCOUNT'),
      editable: true,
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ value }) => (
        <Text variant="body-regular" size="M" color="text1">
          {formatPercentage(value ?? 0)}
        </Text>
      ),
      renderEditCell: ({ id, field, api, value }) => (
        <TextField
          sx={{ width: '100%', input: { padding: '10px !important', textAlign: 'right' } }}
          type="number"
          variant="ghost"
          hiddenLabel
          placeholder="0%"
          value={isNumber(value) ? value * 100 : ''}
          onChange={(e) => {
            void api.updateRows([{ id, discount: parseFloat(e.target.value) / 100 }]);
            void api.setEditCellValue({ id, field, value: parseFloat(e.target.value) / 100 });
          }}
        />
      ),
      valueGetter: ({ value }) => value ?? '',
    },
    {
      field: 'delete',
      headerName: '',
      width: 70,
      align: 'center',
      renderCell: ({ row }) => (
        <Button size="M" color="neutral" onClick={() => actions.removeRow(row.id!)}>
          <DeleteOutline />
        </Button>
      ),
    },
  ];

  return (
    <DataGrid
      noBorder
      noBorderRadius
      color="neutral"
      editMode="row"
      initialState={{
        sorting: {
          sortModel: [{ field: 'startDate', sort: 'desc' }],
        },
      }}
      apiRef={apiRef}
      columns={tableColumns.filter(({ field }) => (isEditing ? true : field !== 'delete'))}
      rows={data}
      onRowEditStop={handlePreventDefault}
      onCellDoubleClick={handlePreventDefault}
    />
  );
};

export default ContractListGrid;
