import MuiFormControlLabel, { FormControlLabelProps as MuiFormControlLabelProps } from '@mui/material/FormControlLabel';
import { DesignSystemSize } from '../../types';
import { sizePaddingMap as checkboxSizePaddingMap } from '../Checkbox/Checkbox';

export type FormControlLabelProps = Omit<MuiFormControlLabelProps, 'size'> & {
  size?: DesignSystemSize;
};

const FormControlLabel = ({ size = 'M', ...otherProps }: FormControlLabelProps) => {
  return <MuiFormControlLabel sx={{ marginLeft: -checkboxSizePaddingMap[size], gap: '6px' }} {...otherProps} />;
};

export default FormControlLabel;
