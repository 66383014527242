import React, { useMemo } from 'react';
import Card, { CardHeader } from '@verticeone/design-system/src/components/Card';
import { Grid, Stack } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { ContractContextProvider, useContractContext } from '@vertice/core/src/modules/saas/contract/ContractContext';
import { useTranslation } from 'react-i18next';
import ContractSummaryMainSection from './ContractSummaryMainSection';
import TabsBottomLineWrapper from '@verticeone/design-system/src/components/Tabs/TabsBottomLineWrapper';
import Tabs from '@verticeone/design-system/src/components/Tabs';
import Tab from '@verticeone/design-system/src/components/Tab';
import { isContractCompleted, isContractWithVertice } from '@vertice/core/src/modules/saas/contract/computed';
import SavingsBreakdownTab from '@vertice/core/src/modules/saas/contract/components/SavingsBreakdownTab';
import ProductsTab from '@vertice/core/src/modules/saas/contract/components/ProductsTab';
import SummaryDetailsTab from './SummaryDetailsTab';
import DocumentsTab from '@vertice/core/src/modules/saas/contract/components/DocumentsTab';
import ContractSummaryCardHeader from './ContractSummaryCardHeader';
import WorkflowTab from '@vertice/core/src/modules/saas/contract/components/WorkflowTab';
import useFormUpdateActions from '@vertice/core/src/modules/saas/contract/hooks/useFormUpdateActions';
import { ContractInvoices } from '@vertice/core/src/modules/contractInvoices';
import LinkedContractsTab from '@vertice/core/src/modules/saas/contract/components/LinkedContractsTab';
import useContractTabNavigation, {
  TabDefinition,
} from '@vertice/core/src/modules/saas/contract/hooks/useContractTabNavigation';
import { ContractTab } from '@vertice/core/src/modules/saas/contract/ContractTabNavigationContext';
import CustomTab from '@vertice/core/src/modules/saas/contract/components/CustomTab';
import { useCanSeeCustomFields } from '@vertice/core/src/modules/saas/contract/components/CustomFields';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

export const ContractSummaryCardSkeleton = () => {
  return (
    <Card>
      <CardHeader size="S" noDivider>
        <Skeleton variant="text" animation="wave" sx={{ width: 200 }} height={40} />
      </CardHeader>
      <Grid container p={6} spacing={8}>
        {Array.from({ length: 9 }).map((_, i) => (
          <Grid item xs={12} xl={4} key={i}>
            <Skeleton variant="text" animation="wave" sx={{ width: '60%' }} />
            <Skeleton variant="text" animation="wave" sx={{ backgroundColor: 'rgba(0,0,0,0.05)' }} height="40px" />
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

const ContractSummaryCards = () => {
  const contractContext = useContractContext('REQUIRE_FETCHED');
  const { t } = useTranslation();
  const { handleSubmit } = useFormUpdateActions(contractContext);
  const { isFeatureEnabled } = useAccountContext();

  const showConcessionTab = useMemo(() => {
    const contract = contractContext.fetchedContract.contract;
    return contract ? isContractWithVertice(contract) && isContractCompleted(contract) : false;
  }, [contractContext.fetchedContract.contract]);

  const showCustomTab = useCanSeeCustomFields();

  const tabs: TabDefinition[] = useMemo(
    () => [
      {
        id: ContractTab.REQUEST_DETAILS,
        label: t('SAAS.CONTRACT_SUMMARY_CARD.TABS.DETAILS'),
        element: <SummaryDetailsTab />,
        isVisible: true,
      },
      {
        id: ContractTab.DOCUMENTS,
        label: t('SAAS.CONTRACT_SUMMARY_CARD.TABS.DOCUMENTS'),
        element: <DocumentsTab />,
        isVisible: true,
      },
      {
        id: ContractTab.SAVINGS_BREAKDOWN,
        label: t('SAAS.CONTRACT_SUMMARY_CARD.TABS.SAVINGS_BREAKDOWN'),
        element: <SavingsBreakdownTab />,
        isVisible: showConcessionTab,
      },
      {
        id: ContractTab.PRODUCTS,
        label: t('SAAS.CONTRACT_SUMMARY_CARD.TABS.PRODUCTS'),
        element: <ProductsTab />,
        isVisible: true,
      },
      {
        id: ContractTab.WORKFLOW,
        label: t('SAAS.CONTRACT_SUMMARY_CARD.TABS.WORKFLOW'),
        element: <WorkflowTab />,
        isVisible: true,
      },
      {
        id: ContractTab.LINKED_CONTRACTS,
        label: t('SAAS.CONTRACT_SUMMARY_CARD.TABS.LINKED_CONTRACTS'),
        element: <LinkedContractsTab />,
        isVisible: true,
      },
      {
        id: ContractTab.CUSTOM,
        label: t('SAAS.CONTRACT_SUMMARY_CARD.TABS.CUSTOM'),
        element: <CustomTab />,
        isVisible: showCustomTab,
      },
    ],
    [showConcessionTab, showCustomTab, t]
  );

  const { visibleTabs, activeTab, setActiveTab, scrollTargetRef } = useContractTabNavigation({ tabs });

  return (
    <Stack gap={4}>
      <Card>
        <ContractContextProvider value={contractContext}>
          <Stack component="form" onSubmit={handleSubmit}>
            <ContractSummaryCardHeader />
            <ContractSummaryMainSection />
            <TabsBottomLineWrapper paddingX={6}>
              <Tabs
                ref={scrollTargetRef}
                variant="outlined"
                value={activeTab.id}
                onChange={(_, value) => setActiveTab(value)}
                scrollButtons="auto"
              >
                {visibleTabs.map((tab) => (
                  <Tab key={tab.id} value={tab.id} label={tab.label} />
                ))}
              </Tabs>
            </TabsBottomLineWrapper>
            {visibleTabs.find((tab) => tab.id === activeTab.id)?.element}
          </Stack>
        </ContractContextProvider>
      </Card>
      {!contractContext.editMode.isEditing && isFeatureEnabled(FEATURES.INTEGRATIONS) && <ContractInvoices />}
    </Stack>
  );
};

export default ContractSummaryCards;
