import {
  pickersLayoutClasses,
  PickersLayoutContentWrapper,
  PickersLayoutRoot,
  usePickerLayout,
} from '@mui/x-date-pickers-pro';
import { Stack, SxProps } from '@mui/material';
import { SIZE_DEFINITION } from '../../constants';
import Divider from '../../../Divider';
import * as React from 'react';
import DateRangeInput from '../DateRangeInput/DateRangeInput';
import { useStaticDateRangePickerContext } from '../../context/StaticDateRangePickerContext';
import { testProps } from '../../../../utils/testProperties';
import { CustomLayoutProps } from './types';
import { Theme } from '@mui/material/styles';
import InfoText from '../InfoText';

type CustomSingleLayoutProps = CustomLayoutProps & {
  sx?: SxProps<Theme>;
};

const CustomSingleLayout = (props: CustomSingleLayoutProps) => {
  const { size } = useStaticDateRangePickerContext();
  const { content, actionBar } = usePickerLayout(props);
  const { localValues, onChangeDateFrom, onChangeDateTo, testId, sx, infoText } = props;
  const [dateFrom, dateTo] = localValues || [null, null];

  return (
    <PickersLayoutRoot
      ownerState={props}
      {...testProps(testId, 'date-range-picker')}
      sx={{
        width: 'min-content',
        ...sx,
      }}
    >
      <PickersLayoutContentWrapper className={pickersLayoutClasses.contentWrapper}>
        {infoText && (
          <>
            <InfoText>{infoText}</InfoText>
            <Divider />
          </>
        )}
        <Stack padding={SIZE_DEFINITION[size].defaultPadding}>
          <DateRangeInput
            dateFrom={dateFrom}
            dateTo={dateTo}
            onChangeDateFrom={onChangeDateFrom}
            onChangeDateTo={onChangeDateTo}
            alignItems="center"
          />
        </Stack>
        <Divider />
        {content}
        <Divider />
        <Stack padding={SIZE_DEFINITION[size].defaultPadding} direction="row" justifyContent="flex-end">
          {actionBar}
        </Stack>
      </PickersLayoutContentWrapper>
    </PickersLayoutRoot>
  );
};

export default CustomSingleLayout;
