import React, { FC } from 'react';
import { Stack } from '@mui/material';

import Placeholder from '@verticeone/design-system/src/components/Placeholder';
import Card from '@verticeone/design-system/src/components/Card';

import { WorkflowVersion } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { WorkflowViewer } from '@vertice/core/src/modules/intelligentWorkflows/workflowSchema';
import { Definitions } from '@vertice/core/src/modules/intelligentWorkflows/definitionsTypes';

export type WorkflowVersionSchemaProps = {
  activeVersion?: WorkflowVersion;
};

export const WorkflowVersionSchema: FC<WorkflowVersionSchemaProps> = ({ activeVersion }) => {
  if (!activeVersion) {
    return null;
  }

  return (
    <Card>
      <Stack height={480} width="100%">
        {activeVersion?.workflowDefinition ? (
          <WorkflowViewer definitions={activeVersion?.workflowDefinition as Definitions} />
        ) : (
          <Placeholder height="100%" />
        )}
      </Stack>
    </Card>
  );
};
