import React from 'react';
import { Trans } from 'react-i18next';
import { Stack } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import Tooltip from '@verticeone/design-system/src/components/Tooltip';

import GenericTable from '../../../GenericTable/GenericTable';
import { IntFormatter } from '../../../GenericTable';
import GenericCard from '../../../GenericCard';

const MostCostlyEbsVolumesCard = () => {
  return (
    <GenericCard translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-31.TABLE" show={['title']}>
      <GenericTable
        code="VT-31"
        testId="most-costly-ebs-volumes-table"
        limit={10}
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-31.TABLE"
        columns={
          [
            'account_id',
            'resource_id',
            'usage_type',
            'target_type',
            'sum_cost',
            'proposed_cost',
            'savings',
            'savings_percentage',
          ] as const
        }
        getRowId={(row) => `${row.account_id}-${row.resource_id}-${row.target_type}-${row.usage_type}`}
        defaultSortColumns={{ field: 'savings', sort: 'desc' }}
        renderCell={{
          proposed_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          savings: (value, row) => (
            <Stack gap={2} direction="row">
              <IntFormatter value={value as number} formatStyle="currency" />
              <span>
                (<IntFormatter value={row.savings_percentage as number} formatStyle="percent" />)
              </span>
            </Stack>
          ),
          sum_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          resource_id: (value) => {
            return value ? (
              value
            ) : (
              <Stack direction="row" alignItems="center" gap={1}>
                <Tooltip
                  content={
                    <Trans i18nKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-31.TABLE.TOOLTIP.RESOURCE_ID.NO_UNIQUE_ID" />
                  }
                >
                  <Text variant="body-regular">
                    <Trans i18nKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-31.TABLE.TOOLTIP.RESOURCE_ID.NA" />
                  </Text>
                </Tooltip>
              </Stack>
            );
          },
        }}
        columnSettings={{
          account_id: { minWidth: 180, flex: 1 },
          resource_id: { minWidth: 200, flex: 1 },
          usage_type: { minWidth: 130, flex: 1 },
          target_type: { minWidth: 170, flex: 1 },
          sum_cost: { minWidth: 120, flex: 1 },
          savings: { minWidth: 180, flex: 1 },
          proposed_cost: {
            minWidth: 170,
            flex: 1,
          },
        }}
        columnVisibilityModel={{
          savings_percentage: false,
        }}
      />
    </GenericCard>
  );
};

export default MostCostlyEbsVolumesCard;
