import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license-pro';
import { MUI_PRO_LICENSE_KEY } from '@vertice/utils';
import App from './App';
import { store } from './redux/store';

LicenseInfo.setLicenseKey(MUI_PRO_LICENSE_KEY);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);
