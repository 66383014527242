import { useFormatCurrency } from '@vertice/core/src/utils/formatting/currency';
import Text from '@verticeone/design-system/src/components/Text';

export type BoldMoneyCellProps = { value: number; currency: string };

const BoldMoneyCell = ({ value, currency }: BoldMoneyCellProps) => {
  const formatCurrency = useFormatCurrency();
  return <Text variant="body-bold">{formatCurrency(value, { currency, maximumFractionDigits: 0 })}</Text>;
};

export default BoldMoneyCell;
