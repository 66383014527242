import React from 'react';
import { CircularProgress, type CircularProgressProps, Stack } from '@mui/material';
import { sizeMap as IconButtonSizeMap } from '../IconButton/IconButton';
import type { DesignSystemColor, DesignSystemSize } from '../../types';
import styled from '@mui/material/styles/styled';
import useTheme from '@mui/material/styles/useTheme';

type CircularProgressBarComponentProps = {
  color: DesignSystemColor;
} & Omit<CircularProgressProps, 'color'>;

type CircularProgressBarProps = {
  children: React.ReactNode;
  percent: number;
  size: DesignSystemSize;
  variant: 'heading' | 'body' | 'icon-button';
} & Omit<CircularProgressBarComponentProps, 'size' | 'variant'>;

const StyledCircularProgress = styled(CircularProgress)<{ $color: string }>(({ $color }) => ({
  color: $color,
}));

const CircularProgressBarComponent = ({ sx, color, ...otherProps }: CircularProgressBarComponentProps) => {
  const theme = useTheme();

  return (
    <>
      <StyledCircularProgress
        {...otherProps}
        sx={{
          ...(sx ?? {}),
          position: 'absolute',
          left: 0,
          top: 0,
          opacity: 0.1,
        }}
        variant="determinate"
        value={100}
        $color={theme.palette[color].color3}
      />
      <StyledCircularProgress {...otherProps} variant="determinate" $color={theme.palette[color].color2} />
    </>
  );
};

//TODO: implement the circular progress bar from the design system with the size and variant props.

// Wrapper with a circular progress bar around the children
export const CircularProgressBar = ({ children, percent, color, size, variant }: CircularProgressBarProps) => {
  const sizeVariantMap = {
    heading: {
      XL: 160,
      L: 128,
      M: 96,
      S: 80,
      XS: 64,
      XXS: 48,
    },
    'icon-button': IconButtonSizeMap,
    body: {
      XL: 20,
      L: 18,
      M: 16,
      S: 14,
      XS: 12,
      XXS: 10,
    },
  };

  return (
    <Stack position="relative" width={sizeVariantMap[variant][size]} height={sizeVariantMap[variant][size]}>
      <CircularProgressBarComponent value={percent} size={sizeVariantMap[variant][size]} thickness={3} color={color} />
      <Stack
        position="absolute"
        left={0}
        right={0}
        top={0}
        bottom={0}
        width="100%"
        height="100%"
        borderRadius="50%"
        padding="4px"
      >
        <Stack
          borderRadius="50%"
          overflow="hidden"
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          {children}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CircularProgressBar;
