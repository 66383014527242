import { FileType } from '@verticeone/design-system/src/components/Fileupload/supportedFileTypes';

const maxFileNameLength = 128;
const bytesInMegabyte = 1048576;
export const maxFileBytesSize = 10 * bytesInMegabyte; // 10MB

export const acceptedTypes: Array<FileType> = ['txt', 'doc', 'xls', 'ppt', 'pdf', 'jpg', 'png'];

enum CustomErrorCode {
  nameInvalidCharacter = 'name-invalid-character',
  nameTooLong = 'name-too-long',
}

export const customErrorCodes = Object.values(CustomErrorCode);

export const validator = (file: File) => {
  const errors = [];

  if (file.name.length > maxFileNameLength) {
    errors.push({
      message: `Max allowed file name length is ${maxFileNameLength} characters (including extension).`,
      code: CustomErrorCode.nameTooLong,
    });
  }

  if (file.name.includes('+')) {
    errors.push({
      message: `File name doesn't support following character: +`,
      code: CustomErrorCode.nameInvalidCharacter,
    });
  }

  return errors.length ? errors : null;
};
