import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Breadcrumbs, { BreadcrumbLink } from '@verticeone/design-system/src/components/Breadcrumbs';
import { Link, Link as RouterLink } from 'react-router-dom';
import Text from '@verticeone/design-system/src/components/Text';
import VendorLogo from '@vertice/core/src/components/VendorLogo/VendorLogo';
import { Skeleton, useTheme } from '@mui/material';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import Button from '@verticeone/design-system/src/components/Button';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { InitiatePurchaseRequestBetaDialog } from './InitiatePurchaseRequestBetaDialog';
import { useCreatePurchaseRequest } from './hooks/useCreatePurchaseRequest';

export type VendorPageHeaderProps = {
  vendorId: string;
  vendorName?: string;
  vendorCategory?: string;
};

const VendorPageHeader = ({ vendorId, vendorName, vendorCategory }: VendorPageHeaderProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { generatePath } = useRouteNavigate();
  const routes = useRoutes();
  const { isEnabled, getFeature } = useFeatures();
  const {
    createPurchaseRequest,
    isLoading: isCreatingIWPurchaseRequest,
    legacyIntakeForm,
  } = useCreatePurchaseRequest();

  const {
    vendorAddExistingContractRoute,
    newPurchaseRoute,
    isLoading: isIntakeFormLoading,
    userCanAddExistingContract,
  } = legacyIntakeForm;

  const [purchaseBetaModalOpened, setPurchaseBetaModalOpened] = useState(false);
  const handleClosePurchaseBetaModal = () => setPurchaseBetaModalOpened(false);

  const handleCreateIWPurchaseRequestClick = async () => {
    const IWFeature = getFeature(FEATURES.INTELLIGENT_WORKFLOWS);
    if (IWFeature?.properties?.beta === true) {
      setPurchaseBetaModalOpened(true);
      return;
    }

    await createPurchaseRequest(vendorId, vendorName);
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" gap={2}>
        <Stack gap={1}>
          <Breadcrumbs variant="slash" size="S">
            <BreadcrumbLink>{t('SAAS.NAVBAR.SAAS')}</BreadcrumbLink>
            <BreadcrumbLink component={RouterLink} to={generatePath(routes.VENDORS.PATH)}>
              {t('SAAS.NAVBAR.VENDORS')}
            </BreadcrumbLink>
            <BreadcrumbLink disabled>{vendorName ?? <Skeleton width="6em" />}</BreadcrumbLink>
          </Breadcrumbs>
          <Stack direction="row" alignItems="center" gap={2}>
            <VendorLogo vendorId={vendorId} size={48} bg={palette.core.bg} />
            <Stack>
              <Text variant="heading" size="M" tag="h1">
                {vendorName}
              </Text>
              <Text variant="body-regular" size="M" color="text3">
                {vendorCategory}
              </Text>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row" gap={2}>
          {userCanAddExistingContract && (
            <Button
              component={Link}
              to={vendorAddExistingContractRoute}
              variant="outline"
              disabled={isIntakeFormLoading}
            >
              {t('VENDOR.ADD_EXISTING')}
            </Button>
          )}
          {isEnabled(FEATURES.INTELLIGENT_WORKFLOWS) ? (
            <Button
              variant="solid"
              color="primary"
              disabled={isCreatingIWPurchaseRequest}
              isLoading={isCreatingIWPurchaseRequest}
              onClick={handleCreateIWPurchaseRequestClick}
            >
              {t('VENDOR.REQUEST')}
            </Button>
          ) : (
            <Button
              component={Link}
              to={newPurchaseRoute}
              variant="solid"
              color="primary"
              disabled={isIntakeFormLoading}
            >
              {t('VENDOR.REQUEST')}
            </Button>
          )}
        </Stack>
      </Stack>
      <InitiatePurchaseRequestBetaDialog
        isOpened={purchaseBetaModalOpened}
        onClose={handleClosePurchaseBetaModal}
        vendorId={vendorId}
        vendorName={vendorName}
      />
    </>
  );
};

export default VendorPageHeader;
