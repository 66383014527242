import { MonitoringValue } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { FormattedResult, FormattingContext } from './types';
import { summaryFormatter } from './valueFormatters';
import { basicColorGetter } from './colorGetters';
import { summaryTooltipFormatter } from './tooltipFormatters';

export const formatResult = (item: MonitoringValue, breaching: boolean, ctx: FormattingContext): FormattedResult => {
  return {
    value: summaryFormatter(item, ctx),
    color: basicColorGetter(breaching),
    tooltip: summaryTooltipFormatter(item, ctx),
  };
};
