import { Children, isValidElement, PropsWithChildren, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Stack, useTheme } from '@mui/material';

import { SlackColorIcon } from '@vertice/assets';
import { useDeactivateAccountIntegrationMutation, useRevokeAccountIntegrationMutation } from '@vertice/slices';
import Button from '@verticeone/design-system/src/components/Button';
import Text from '@verticeone/design-system/src/components/Text';
import IconWrapper from '@verticeone/design-system/src/components/IconWrapper';
import ChipButton from '@verticeone/design-system/src/components/ChipButton';
import Breadcrumbs, { BreadcrumbLink } from '@verticeone/design-system/src/components/Breadcrumbs';
import { WizardStepProps } from '../../components/Wizard';
import { INTEGRATIONS_ROUTE, isValidResponse, SLACK_INTEGRATION_ID, STEPS } from '../common';
import { DisconnectSlackDialog } from './DisconnectSlackDialog';

type IntegrationSetupProps = {
  activeStep?: string;
  accountId: string;
} & PropsWithChildren;

export const IntegrationSetup = ({ activeStep, accountId, children }: IntegrationSetupProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const navigate = useNavigate();

  const [isDisconnectSlackDialogOpened, setIsDisconnectSlackDialogOpened] = useState(false);

  const [deactivateIntegration, { isLoading: isIntegrationDeactivationInProgress }] =
    useDeactivateAccountIntegrationMutation();
  const [revokeIntegration, { isLoading: isIntegrationRevokeInProgress }] = useRevokeAccountIntegrationMutation();

  const handleRevoke = async () => {
    const response = await deactivateIntegration({
      accountId: accountId,
      integrationId: SLACK_INTEGRATION_ID,
    });

    if (isValidResponse(response)) {
      await revokeIntegration({ accountId: accountId, integrationId: SLACK_INTEGRATION_ID });
      setIsDisconnectSlackDialogOpened(false);
      navigate(INTEGRATIONS_ROUTE);
    }
  };

  const stepContent = Children.toArray(children).find((child) =>
    isValidElement<WizardStepProps>(child) ? child.props.stepValue === activeStep : false
  );

  return (
    <Stack flex={1} p={8} bgcolor={palette.core.color1}>
      <Stack gap={8}>
        <Stack gap={2}>
          <Breadcrumbs variant="slash" size="S">
            <BreadcrumbLink component={Link} to={INTEGRATIONS_ROUTE}>
              {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.BREADCRUMBS_TITLE')}
            </BreadcrumbLink>
            <BreadcrumbLink>{t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.BREADCRUMBS_SLACK')}</BreadcrumbLink>
          </Breadcrumbs>
          <Stack direction="row" gap={2} justifyContent="space-between">
            <Stack gap={2}>
              <Stack direction="row" alignItems="center" gap={2}>
                <IconWrapper icon={SlackColorIcon} />
                <Text variant="heading" size="M">
                  {t('PREFERENCES.INTEGRATIONS.SLACK.TITLE')}
                </Text>
                <ChipButton variant="ghost" size="M" color="neutral" isActive={false}>
                  {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CHIP_BUTTON')}
                </ChipButton>
              </Stack>
              <Text variant="body-regular" size="M" color="text2">
                {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.SUBTITLE')}
              </Text>
            </Stack>
            {activeStep === STEPS.CONNECTION && (
              <>
                <Button
                  variant="outline"
                  size="M"
                  color="neutral"
                  onClick={() => setIsDisconnectSlackDialogOpened(true)}
                >
                  {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.DISCONNECT')}
                </Button>
                <DisconnectSlackDialog
                  isOpened={isDisconnectSlackDialogOpened}
                  isLoading={isIntegrationDeactivationInProgress || isIntegrationRevokeInProgress}
                  onSubmit={handleRevoke}
                  onClose={() => setIsDisconnectSlackDialogOpened(false)}
                />
              </>
            )}
          </Stack>
        </Stack>
        {stepContent}
      </Stack>
    </Stack>
  );
};
