import { api } from '../baseApi';
export type Maybe<T> = T;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type ContractDocument = {
  __typename?: 'ContractDocument';
  additionalNotes?: Maybe<Scalars['String']>;
  annualCost?: Maybe<Scalars['String']>;
  annualCostCurrency?: Maybe<Scalars['String']>;
  autoRenewalDate?: Maybe<Scalars['String']>;
  contractLength?: Maybe<Scalars['Float']>;
  contractType?: Maybe<ContractType>;
  department?: Maybe<Department>;
  renewalDate?: Maybe<Scalars['String']>;
  rollingFrequency?: Maybe<Scalars['String']>;
  signDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  vendor?: Maybe<Vendor>;
};

export type ContractDocumentInput = {
  additionalNotes?: InputMaybe<Scalars['String']>;
  annualCost?: InputMaybe<Scalars['String']>;
  annualCostCurrency?: InputMaybe<Scalars['String']>;
  autoRenewalDate?: InputMaybe<Scalars['String']>;
  contractLength?: InputMaybe<Scalars['Float']>;
  contractType?: InputMaybe<ContractType>;
  departmentId?: InputMaybe<Scalars['ID']>;
  renewalDate?: InputMaybe<Scalars['String']>;
  rollingFrequency?: InputMaybe<Scalars['String']>;
  signDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};

export type ContractDraft = {
  __typename?: 'ContractDraft';
  accountId: Scalars['ID'];
  contract?: Maybe<ContractDocument>;
  id: Scalars['ID'];
  parentContractRef?: Maybe<Scalars['ID']>;
  purchaseType?: Maybe<PurchaseType>;
};

export type ContractDraftInput = {
  contract?: InputMaybe<ContractDocumentInput>;
  parentContractRef?: InputMaybe<Scalars['ID']>;
  purchaseType?: InputMaybe<PurchaseType>;
};

export enum ContractType {
  Fixed = 'FIXED',
  Rolling = 'ROLLING'
}

export type Department = {
  __typename?: 'Department';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createContractDraft?: Maybe<ContractDraft>;
  deleteContractDraft?: Maybe<ContractDraft>;
  linkContractDraftToContract?: Maybe<ContractDraft>;
  updateContractDraft?: Maybe<ContractDraft>;
};


export type MutationCreateContractDraftArgs = {
  accountId: Scalars['ID'];
  draftInput: ContractDraftInput;
};


export type MutationDeleteContractDraftArgs = {
  accountId: Scalars['ID'];
  draftId: Scalars['ID'];
};


export type MutationLinkContractDraftToContractArgs = {
  accountId: Scalars['ID'];
  contractId: Scalars['ID'];
  draftId: Scalars['ID'];
};


export type MutationUpdateContractDraftArgs = {
  accountId: Scalars['ID'];
  draftId: Scalars['ID'];
  draftInput: ContractDraftInput;
};

export type PaginatedContractDrafts = {
  __typename?: 'PaginatedContractDrafts';
  items?: Maybe<Array<Maybe<ContractDraft>>>;
  pagination?: Maybe<PaginationResult>;
};

export type PaginationResult = {
  __typename?: 'PaginationResult';
  finished?: Maybe<Scalars['Boolean']>;
  nextToken?: Maybe<Scalars['String']>;
  scannedCount?: Maybe<Scalars['Int']>;
};

export enum PurchaseType {
  Existing = 'EXISTING',
  Purchase = 'PURCHASE',
  Renewal = 'RENEWAL'
}

export type Query = {
  __typename?: 'Query';
  apiVersion?: Maybe<Scalars['String']>;
  getContractDraft?: Maybe<ContractDraft>;
  listOpenContractDrafts?: Maybe<Array<Maybe<ContractDraft>>>;
  listOpenContractDraftsPaginated?: Maybe<PaginatedContractDrafts>;
  shouldDeny?: Maybe<Scalars['Boolean']>;
};


export type QueryGetContractDraftArgs = {
  accountId: Scalars['ID'];
  draftId: Scalars['ID'];
};


export type QueryListOpenContractDraftsArgs = {
  accountId: Scalars['ID'];
};


export type QueryListOpenContractDraftsPaginatedArgs = {
  accountId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryShouldDenyArgs = {
  accountId: Scalars['ID'];
};

export type Vendor = {
  __typename?: 'Vendor';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type GetContractDraftQueryVariables = Exact<{
  accountId: Scalars['ID'];
  draftId: Scalars['ID'];
}>;


export type GetContractDraftQuery = { __typename?: 'Query', getContractDraft?: { __typename?: 'ContractDraft', id: string, purchaseType?: PurchaseType, parentContractRef?: string, contract?: { __typename?: 'ContractDocument', annualCost?: string, annualCostCurrency?: string, contractType?: ContractType, contractLength?: number, startDate?: string, signDate?: string, renewalDate?: string, autoRenewalDate?: string, rollingFrequency?: string, additionalNotes?: string, vendor?: { __typename?: 'Vendor', id: string, name?: string }, department?: { __typename?: 'Department', id: string, name?: string } } } };

export type GetContractDraftsQueryVariables = Exact<{
  accountId: Scalars['ID'];
}>;


export type GetContractDraftsQuery = { __typename?: 'Query', listOpenContractDrafts?: Array<{ __typename?: 'ContractDraft', id: string, purchaseType?: PurchaseType, parentContractRef?: string, contract?: { __typename?: 'ContractDocument', annualCost?: string, annualCostCurrency?: string, contractType?: ContractType, contractLength?: number, startDate?: string, signDate?: string, renewalDate?: string, additionalNotes?: string, vendor?: { __typename?: 'Vendor', id: string, name?: string }, department?: { __typename?: 'Department', id: string, name?: string } } }> };

export type CreateContractDraftMutationVariables = Exact<{
  accountId: Scalars['ID'];
  draftInput: ContractDraftInput;
}>;


export type CreateContractDraftMutation = { __typename?: 'Mutation', createContractDraft?: { __typename?: 'ContractDraft', id: string } };

export type UpdateContractDraftMutationVariables = Exact<{
  accountId: Scalars['ID'];
  draftId: Scalars['ID'];
  draftInput: ContractDraftInput;
}>;


export type UpdateContractDraftMutation = { __typename?: 'Mutation', updateContractDraft?: { __typename?: 'ContractDraft', id: string, purchaseType?: PurchaseType, parentContractRef?: string, contract?: { __typename?: 'ContractDocument', annualCost?: string, annualCostCurrency?: string, contractType?: ContractType, contractLength?: number, startDate?: string, signDate?: string, renewalDate?: string, additionalNotes?: string, vendor?: { __typename?: 'Vendor', id: string, name?: string }, department?: { __typename?: 'Department', id: string, name?: string } } } };

export type DeleteContractDraftMutationVariables = Exact<{
  accountId: Scalars['ID'];
  draftId: Scalars['ID'];
}>;


export type DeleteContractDraftMutation = { __typename?: 'Mutation', deleteContractDraft?: { __typename?: 'ContractDraft', id: string } };


export const GetContractDraftDocument = `
    query GetContractDraft($accountId: ID!, $draftId: ID!) {
  getContractDraft(accountId: $accountId, draftId: $draftId) {
    id
    purchaseType
    parentContractRef
    contract {
      annualCost
      annualCostCurrency
      vendor {
        id
        name
      }
      contractType
      contractLength
      startDate
      signDate
      renewalDate
      autoRenewalDate
      rollingFrequency
      additionalNotes
      department {
        id
        name
      }
    }
  }
}
    `;
export const GetContractDraftsDocument = `
    query GetContractDrafts($accountId: ID!) {
  listOpenContractDrafts(accountId: $accountId) {
    id
    purchaseType
    parentContractRef
    contract {
      annualCost
      annualCostCurrency
      vendor {
        id
        name
      }
      contractType
      contractLength
      startDate
      signDate
      renewalDate
      department {
        id
        name
      }
      additionalNotes
    }
  }
}
    `;
export const CreateContractDraftDocument = `
    mutation CreateContractDraft($accountId: ID!, $draftInput: ContractDraftInput!) {
  createContractDraft(accountId: $accountId, draftInput: $draftInput) {
    id
  }
}
    `;
export const UpdateContractDraftDocument = `
    mutation UpdateContractDraft($accountId: ID!, $draftId: ID!, $draftInput: ContractDraftInput!) {
  updateContractDraft(
    accountId: $accountId
    draftId: $draftId
    draftInput: $draftInput
  ) {
    id
    purchaseType
    parentContractRef
    contract {
      annualCost
      annualCostCurrency
      vendor {
        id
        name
      }
      contractType
      contractLength
      startDate
      signDate
      renewalDate
      additionalNotes
      department {
        id
        name
      }
    }
  }
}
    `;
export const DeleteContractDraftDocument = `
    mutation DeleteContractDraft($accountId: ID!, $draftId: ID!) {
  deleteContractDraft(accountId: $accountId, draftId: $draftId) {
    id
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetContractDraft: build.query<GetContractDraftQuery, GetContractDraftQueryVariables>({
      query: (variables) => ({ document: GetContractDraftDocument, variables })
    }),
    GetContractDrafts: build.query<GetContractDraftsQuery, GetContractDraftsQueryVariables>({
      query: (variables) => ({ document: GetContractDraftsDocument, variables })
    }),
    CreateContractDraft: build.mutation<CreateContractDraftMutation, CreateContractDraftMutationVariables>({
      query: (variables) => ({ document: CreateContractDraftDocument, variables })
    }),
    UpdateContractDraft: build.mutation<UpdateContractDraftMutation, UpdateContractDraftMutationVariables>({
      query: (variables) => ({ document: UpdateContractDraftDocument, variables })
    }),
    DeleteContractDraft: build.mutation<DeleteContractDraftMutation, DeleteContractDraftMutationVariables>({
      query: (variables) => ({ document: DeleteContractDraftDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetContractDraftQuery, useLazyGetContractDraftQuery, useGetContractDraftsQuery, useLazyGetContractDraftsQuery, useCreateContractDraftMutation, useUpdateContractDraftMutation, useDeleteContractDraftMutation } = injectedRtkApi;

