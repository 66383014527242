import BaseCloudAccountsGraph from './BaseCloudAccountsGraph';
import React from 'react';
import useAccountsGraphData from '../useAccountsGraphData';

const CloudAccountsGraph = () => {
  const { data: graphData, ...loadingStatus } = useAccountsGraphData();

  return <BaseCloudAccountsGraph data={graphData!} {...loadingStatus} />;
};

export default CloudAccountsGraph;
