import { v1 as uuid } from 'uuid';

export const COST_MODEL_CLASS = 'AnnualCostApproximation';
export const COST_MODEL_VERSION = '2023-09-14';

/**
 * Generates empty model base.
 */
const getCostModelBase = () => ({
  id: uuid(),
  class: COST_MODEL_CLASS,
  version: COST_MODEL_VERSION,
  model: {},
});

export default getCostModelBase;
