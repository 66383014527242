import { Stack, styled } from '@mui/material';

export const StyledStartEndNode = styled(Stack)(({ theme: { palette } }) => ({
  backgroundColor: palette.neutral.color2,
  color: palette.text.color5,
  paddingTop: 4,
  paddingBottom: 4,
  paddingLeft: 8,
  paddingRight: 8,
  borderRadius: 16,
  height: 24,
}));
