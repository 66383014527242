import { Stack, useTheme } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import IconWrapper from '@verticeone/design-system/src/components/IconWrapper';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@verticeone/design-system/src/components/Tooltip';

type ApplicationGridHeaderCellProps = {
  tooltipTitle?: string;
  tooltipContent?: string;
  children: React.ReactNode;
};

const ApplicationGridHeaderCell = ({ tooltipTitle, tooltipContent, children }: ApplicationGridHeaderCellProps) => {
  const { palette } = useTheme();

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Text variant="label" size="XS" color={palette.primary.color2}>
        {children}
      </Text>
      {tooltipTitle && tooltipContent && (
        <Tooltip title={tooltipTitle} content={tooltipContent}>
          <IconWrapper icon={InfoIcon} size="S" htmlColor={palette.primary.color2} />
        </Tooltip>
      )}
    </Stack>
  );
};

export default ApplicationGridHeaderCell;
