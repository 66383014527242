import React from 'react';
import NegotiatedCostContractFormEntry from './form/NegotiatedCostContractFormEntry';
import ProposedCostContractFormEntry from './form/ProposedCostContractFormEntry';
import SavingsVsInitialContractFormEntry from './form/SavingsVsInitialContractFormEntry';
import { useIsContractFieldVisible } from '../hooks/fieldsRulesHooks';
import AdditionalSavingsContractFormEntry from './form/AdditionalSavingsContractFormEntry';
import TotalSavingsContractFormEntry from './form/TotalSavingsContractFormEntry';
import Divider from '@verticeone/design-system/src/components/Divider';
import FutureSavingsContractFormEntry from './form/FutureSavingsContractFormEntry';
import ScopeReductionSavingsContractFormEntry from './form/ScopeReductionSavingsContractFormEntry';
import Grid from '@verticeone/design-system/src/components/Grid';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import Text from '@verticeone/design-system/src/components/Text';
import Button from '@verticeone/design-system/src/components/Button';
import ChevronRight from '@mui/icons-material/ChevronRightTwoTone';
import { ContractTab, useContractTabNavigationContext } from '../ContractTabNavigationContext';

const FinancialSection = () => {
  const { t } = useTranslation();
  const visibility = {
    savingsVsInitial: useIsContractFieldVisible('parts.overview.totalCostValueSavings'),
    additionalSavings: useIsContractFieldVisible('parts.overview.realizedConcessions'),
    futureSavings: useIsContractFieldVisible('parts.overview.unrealizedConcessions'),
    scopeReductionSavings: useIsContractFieldVisible('parts.overview.scopeReductionSavings'),
  };
  const tabNavigationContext = useContractTabNavigationContext();

  return (
    <Stack gap={6}>
      <Grid container direction="row" spacing={8} px={6}>
        <Grid item hideIfEmpty xs={12} md={6} lg={4}>
          <NegotiatedCostContractFormEntry />
        </Grid>
        <Grid item hideIfEmpty xs={12} md={6} lg={4}>
          <ProposedCostContractFormEntry />
        </Grid>
        <Grid item hideIfEmpty xs={12} md={6} lg={4}>
          <TotalSavingsContractFormEntry />
        </Grid>
      </Grid>

      <Divider />

      {(visibility.savingsVsInitial ||
        visibility.additionalSavings ||
        visibility.futureSavings ||
        visibility.scopeReductionSavings) && (
        <>
          <Stack
            direction="row"
            px={6}
            justifyContent="space-between"
            alignItems="center"
            // Compensate the Button's white padding without making the click-area smaller
            m="-6px -12px -6px 0"
          >
            <Text variant="caption" size="XS" color="text1">
              {t('ENTITIES.CONTRACT.LABELS.TOTAL_SAVINGS_BREAKDOWN')}
            </Text>
            <Button
              onClick={() => tabNavigationContext.setTabId(ContractTab.SAVINGS_BREAKDOWN, { scrollIntoView: true })}
              variant="plain"
              color="primary"
              size="XS"
            >
              {t('ENTITIES.CONTRACT.ACTIONS.VIEW_DETAILS')}
              <ChevronRight opacity={0.6} />
            </Button>
          </Stack>

          <Grid container direction="row" spacing={8} px={6}>
            <Grid item hideIfEmpty xs={12} md={6} lg={3}>
              <SavingsVsInitialContractFormEntry />
            </Grid>
            <Grid item hideIfEmpty xs={12} md={6} lg={3}>
              <AdditionalSavingsContractFormEntry />
            </Grid>
            <Grid item hideIfEmpty xs={12} md={6} lg={3}>
              <FutureSavingsContractFormEntry />
            </Grid>
            <Grid item hideIfEmpty xs={12} md={6} lg={3}>
              <ScopeReductionSavingsContractFormEntry />
            </Grid>
          </Grid>
          <Divider />
        </>
      )}
    </Stack>
  );
};

export default FinancialSection;
