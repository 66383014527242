import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

import Text from '@verticeone/design-system/src/components/Text';
import Button from '@verticeone/design-system/src/components/Button';
import IconWrapper from '@verticeone/design-system/src/components/IconWrapper';
import { useFormatDate, defaultFormatterOptions } from '@vertice/core/src/utils/formatting/date';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { VendorLogo } from '@vertice/components';

type ContractCardProps = {
  vendorId?: string;
  contract: Contract;
  selectedContract: string;
  setSelectedContract: Dispatch<SetStateAction<string>>;
};

const ContractCard = ({ vendorId, contract, selectedContract, setSelectedContract }: ContractCardProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const formatDate = useFormatDate();

  const vendorName = contract.parts.contractual?.vendor?.vendorName;
  const simplifiedStage = contract.materialized?.simplifiedStage;
  const renewalDate = formatDate(contract.parts.contractual?.lifecycle?.renewalDate, {
    formatterOptions: defaultFormatterOptions,
  });
  const endDate = formatDate(contract.parts.contractual?.lifecycle?.endDate, {
    formatterOptions: defaultFormatterOptions,
  });
  const isSelected = contract.record.lineageId === selectedContract;

  return (
    <Button
      onClick={() => setSelectedContract(contract.record.lineageId || '')}
      variant="ghost"
      color="neutral"
      sx={{
        justifyContent: 'flex-start',
        borderRadius: '12px',
        bgcolor: isSelected ? palette.core.color3 : palette.core.color1,
        boxSizing: 'border-box',
        border: isSelected ? `2px solid ${palette.core.color4}` : '2px solid transparent',
      }}
    >
      <VendorLogo vendorId={vendorId} size={16} />
      <Text size="M" variant="body-regular">
        {vendorName}
      </Text>
      <IconWrapper
        sx={{ width: '6px !important', height: '6px !important' }}
        icon={CircleIcon}
        htmlColor={palette.text.color4}
      />
      <Text size="M" variant="body-regular" color={palette.text.color2}>
        {t(`ENTITIES.CONTRACT.STAGES_ON_DETAIL.${simplifiedStage}`)}
      </Text>
      <IconWrapper
        sx={{ width: '6px !important', height: '6px !important' }}
        icon={CircleIcon}
        htmlColor={palette.text.color4}
      />
      <Text size="M" variant="body-regular" color={palette.text.color2}>
        {`${simplifiedStage === 'LIVE' ? `${t('APPLICATIONS.USAGE.CONTRACT_DIALOG.EXPIRY')} ` : ''}${
          renewalDate ? renewalDate : endDate
        }`}
      </Text>
    </Button>
  );
};

export default ContractCard;
