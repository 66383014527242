import React, { useCallback, useMemo } from 'react';
import type { TooltipFormatterContextObject } from 'highcharts';

import { formatPercentage } from '@vertice/core/src/utils/formatting/percentage';
import TooltipValueWithTimeInfo from '@vertice/core/src/components/charts/components/Tooltip/TooltipValueWithTimeInfo';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';

const useTooltip = () => {
  const { locale } = useLocaleContext();
  const tooltipXAxisValueFormatter = useMemo(
    () =>
      new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }),
    [locale]
  );

  return useCallback(
    (points: Array<TooltipFormatterContextObject> | undefined) =>
      points
        // Show tooltip only for coverage data
        ?.filter((point) => {
          const { type } = point.series;
          return type === 'line';
        })
        .map((point, pointIndex) => {
          const formattedValue = formatPercentage(Number(point.y!), { locale });
          return (
            <TooltipValueWithTimeInfo
              value={formattedValue}
              timeInfo={pointIndex === 0 ? tooltipXAxisValueFormatter.format(new Date(point.x!)) : undefined}
              key={point.series.name}
            />
          );
        }),
    [locale, tooltipXAxisValueFormatter]
  );
};

export default useTooltip;
