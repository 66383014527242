import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '@verticeone/design-system/src/components/Text';

const BeAwareParagraph = () => {
  const { t } = useTranslation();
  return (
    <Text component="p" variant="caption" size="XXS" color="text3">
      {t('LEGAL_INFO.BE_AWARE')}
    </Text>
  );
};

export default BeAwareParagraph;
