import IconButton from '@verticeone/design-system/src/components/IconButton';
import { useTranslation } from 'react-i18next';
import { Grow, Paper, Popper, useTheme } from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import React, { useCallback, useRef, useState } from 'react';
import { FilterVisibilityConfigurationForm } from './components/FilterVisibilityConfigurationForm';

export const CustomizeFilterButton = () => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const closePopper = useCallback(() => setOpen(false), [setOpen]);

  return (
    <>
      <IconButton
        icon={FilterAltOutlinedIcon}
        ref={anchorRef}
        variant="outline"
        color="neutral"
        onClick={() => setOpen(!open)}
        testId="customize-filter-button"
        aria-label={t('ENTITIES.FILTERS.CUSTOMIZE_FILTERS.TITLE')}
      />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
        style={{ zIndex: 100 }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper sx={{ borderRadius: 2, border: `1px solid ${palette.core.color3}` }}>
              <FilterVisibilityConfigurationForm onSaved={closePopper} onCancel={closePopper} />
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
