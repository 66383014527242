import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Stack, styled, useTheme } from '@mui/material';
import AssistantPhotoTwoToneIcon from '@mui/icons-material/AssistantPhotoTwoTone';
import TaskTwoToneIcon from '@mui/icons-material/TaskTwoTone';
import CelebrationTwoToneIcon from '@mui/icons-material/CelebrationTwoTone';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';

import Text from '@verticeone/design-system/src/components/Text';
import { CardHeader } from '@verticeone/design-system/src/components/Card';
import ChipButton from '@verticeone/design-system/src/components/ChipButton';
import Menu, { MenuItem } from '@verticeone/design-system/src/components/Menu';
import IconWrapper from '@verticeone/design-system/src/components/IconWrapper';
import { BannerContent } from '@vertice/core/src/components/Banner/BannerContent';
import type { DesignSystemDensity } from '@verticeone/design-system/src/types';
import {
  RecommendationStatus,
  useUpdateRecommendationsMutation,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { ArrowDropDownOutlined } from '@mui/icons-material';
import { useRecommendationData } from '../RecommendationProvider';
import RecommendationStatusChangeDialog from './RecommendationStatusChangeDialog';
import Loader from '@verticeone/design-system/src/components/Loader';
import { STATUS_CHIP } from '@vertice/dashboard/src/modules/cloud/cards/OptimizationTestsCard/OptimizationTestsTable/constants';
import { Can } from '@verticeone/auth/src';

type StatusHeaderProps = {
  status: RecommendationStatus;
  code: string;
  density?: DesignSystemDensity;
};

const StyledCardHeader = styled(CardHeader)<{ color: string }>(({ color }) => ({
  flexDirection: 'row',
  backgroundColor: color,
}));

const StyledIconWrapper = styled(IconWrapper)(({ theme }) => ({
  padding: '2px',
  backgroundColor: theme.palette.transparent.color4,
  color: theme.palette.core.bg,
  borderRadius: '4px',
  justifyContent: 'center',
  alignItems: 'center',
}));

const useStatusIcon = (status: RecommendationStatus) =>
  ({
    DISCOVERED: AssistantPhotoTwoToneIcon,
    INPROGRESS: TaskTwoToneIcon,
    FULFILLED: CelebrationTwoToneIcon,
    CANCELLED: RemoveCircleTwoToneIcon,
    EMPTY_RESULTS: AssistantPhotoTwoToneIcon,
  }[status]);

const useBgColor = (status: RecommendationStatus) => {
  const { palette } = useTheme();

  return {
    DISCOVERED: palette.primary.color2,
    INPROGRESS: palette.info.color1,
    FULFILLED: palette.success.color2,
    CANCELLED: palette.inactive.color1,
    EMPTY_RESULTS: palette.neutral.color1,
  }[status];
};

const CardHeaderStatus = ({ status, code, density = 'medium' }: StatusHeaderProps) => {
  const { palette } = useTheme();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.CLOUD_RECOMMENDATIONS' });
  const statusIcon = useStatusIcon(status);
  const bgColor = useBgColor(status);
  const [updateRecommendations] = useUpdateRecommendationsMutation();
  const { accountId } = useAccountContext();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { refetch } = useRecommendationData();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = async () => {
    setIsSaving(true);
    const todo = status === RecommendationStatus.Discovered;
    // update account settings
    await updateRecommendations({
      accountId: accountId!,
      recommendations: {
        code,
        recommendationSettings: {
          status: todo ? RecommendationStatus.Cancelled : RecommendationStatus.Discovered,
        },
      },
    });

    // waiting for new data to be fetched
    setTimeout(() => {
      refetch();
      setIsDialogOpen(true);
      setIsSaving(false);
    }, 2000);
  };

  const handleToggle = async () => {
    setAnchorEl(null);
    await handleChange();
  };

  return (
    <StyledCardHeader density={density} color={bgColor}>
      <BannerContent
        icon={<StyledIconWrapper size="L" icon={statusIcon} />}
        title={
          <Text variant="heading" size="XS" color={palette.global.color.white.main}>
            {t(`STATUS.${status}.TEXT`)}
          </Text>
        }
        badge={
          status !== RecommendationStatus.EmptyResults && (
            <Stack>
              <Can
                do="account:UpdateAccountSettings"
                on={`urn:verticeone:vertice:${accountId}:cco-aws:account/*`}
                passThrough
              >
                {(isAllowed) => (
                  <ChipButton
                    variant="ghost"
                    size="M"
                    color={STATUS_CHIP[status].color}
                    disabled={!isAllowed || isSaving}
                    onClick={handleClick}
                    id="status-button"
                  >
                    {isSaving && (
                      <Stack position="absolute" left="0" right="0" top="0" bottom="0">
                        <Loader color="neutral" thickness={7} size="12px" />
                      </Stack>
                    )}
                    <Stack visibility={isSaving ? 'hidden' : 'visible'}>
                      <Trans key={code} i18nKey={STATUS_CHIP[status].label} />
                    </Stack>
                    <ArrowDropDownOutlined />
                  </ChipButton>
                )}
              </Can>
              <Menu
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                autoFocus={false}
                testId={'status-menu'}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
              >
                <MenuItem onClick={handleToggle}>
                  {status === RecommendationStatus.Discovered
                    ? t('STATUS.CANCELLED.ACTION')
                    : t('STATUS.DISCOVERED.ACTION')}
                </MenuItem>
              </Menu>
            </Stack>
          )
        }
      />
      <RecommendationStatusChangeDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} />
    </StyledCardHeader>
  );
};

export default CardHeaderStatus;
