import { shouldShowRollingFrequency } from './utils';
import Text from '@verticeone/design-system/src/components/Text';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useTranslation } from 'react-i18next';

type RollingFrequencyProps = {
  contract: Contract;
  testId?: string;
  emptyValue?: string | null;
};

export const DEFAULT_TEST_ID = 'rolling-frequency-value';

const RollingFrequency = ({ contract, testId = DEFAULT_TEST_ID, emptyValue = null }: RollingFrequencyProps) => {
  const { t } = useTranslation();

  const rollingFrequency = contract.parts.contractual?.lifecycle?.rollFrequency;
  const rollingFrequencyDesc =
    rollingFrequency && rollingFrequency !== 'NO'
      ? t('CONTRACTS.ROLLING_CONTRACT_DEADLINE', {
          frequency: t(`CONTRACTS.ROLLING_CONTRACT_${rollingFrequency}`),
        })
      : t('COMMON.NO');

  return shouldShowRollingFrequency(contract) ? (
    <Text testId={testId} variant="body-regular" size="M" color="text1">
      {rollingFrequencyDesc}
    </Text>
  ) : (
    emptyValue
  );
};

export default RollingFrequency;
