import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Stack } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import { SLACK_INTEGRATION_ID } from './Slack/common';
import { SlackSetupCard } from './Slack/SlackSetupCard';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';

type CardWrapperProps = PropsWithChildren;

const CardWrapper = ({ children }: CardWrapperProps) => (
  <Grid container item xs={4} sx={{ minWidth: '400px' }}>
    {children}
  </Grid>
);

export const Alerting = () => {
  const { t } = useTranslation();
  const { isEnabled } = useFeatures();
  const slackFeatureEnabled = isEnabled(FEATURES.INTEGRATION_SLACK);

  return (
    <Stack gap={6}>
      <Text variant="heading" size="S">
        {t('PREFERENCES.INTEGRATIONS.ALERTS')}
      </Text>
      <Grid container direction="row" spacing={3}>
        {slackFeatureEnabled && (
          <CardWrapper>
            <SlackSetupCard providerId={SLACK_INTEGRATION_ID} />
          </CardWrapper>
        )}
      </Grid>
    </Stack>
  );
};
