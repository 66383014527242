import { rgba } from 'polished';
import type { DesignSystemColor } from '../../../types';
import type { Color, ColorVariants } from '../types';
import { colorPalette } from './colorPalette';

type ComputeBoxShadowProps = {
  x: number | string;
  y: number | string;
  blur: number | string;
  color: Color;
  spread: number | string;
};

export type GetShadow = {
  color: 'core' | Extract<DesignSystemColor, 'primary' | 'secondary' | 'warning' | 'error' | 'success'>;
  type: 'soft' | 'key' | 'spot';
  depth: '1z' | '2z' | '3z';
  distance: ColorVariants;
};

// prettier-ignore
const getBaseY = (type: GetShadow['type'], depth: GetShadow['depth']) => ({
  soft: {
    '1z': 1.25,
    '2z': 1.875,
    '3z': 2.5,
  },
  key: {
    '1z': 2.5,
    '2z': 3.75,
    '3z': 5,
  },
  spot: {
    '1z': 3.75,
    '2z': 5,
    '3z': 6.25,
  },
}[type][depth]);

// prettier-ignore
const getBaseBlur = (depth: GetShadow['depth']) => ({
  '1z': 1.25,
  '2z': 2.5,
  '3z': 5,
}[depth]);

// prettier-ignore
const getBaseSpread = (type: GetShadow['type'], depth: GetShadow['depth']) => ({
  soft: {
    '1z': -0.625,
    '2z': 0,
    '3z': 0.625,
  },
  key: {
    '1z': -1.25,
    '2z': 0,
    '3z': 1.25,
  },
  spot: {
    '1z': -1.875,
    '2z': 0,
    '3z': 1.875,
  },
})[type][depth];

// prettier-ignore
const getColor = (color: GetShadow['color'], axis: 'x' | 'y') => ({
  core: { x: colorPalette.gray['100'].main, y: colorPalette.gray['100'].main },
  primary: { x: colorPalette.purple['80'].main, y: colorPalette.purple['70'].main },
  secondary: { x: colorPalette.blue['70'].main, y: colorPalette.blue['60'].main },
  warning: { x: colorPalette.orange['70'].main, y: colorPalette.orange['60'].main },
  error: { x: colorPalette.red['70'].main, y: colorPalette.red['60'].main },
  success: { x: colorPalette.green['70'].main, y: colorPalette.green['60'].main },
}[color][axis]);

const getBaseColorAlpha = (
  type: GetShadow['type'],
  depth: GetShadow['depth'],
  distance: GetShadow['distance'],
  axis: 'x' | 'y'
): number => {
  const distanceIndex = parseInt(distance, 10) / 10 - 1;
  const multiplier = {
    '8x': Array.from({ length: 10 }, (_, i) => (13 + i * 8) / 100),
    '4x': Array.from({ length: 10 }, (_, i) => (9 + i * 4) / 100),
    '2x': Array.from({ length: 10 }, (_, i) => (7 + i * 2) / 100),
    '1x': Array.from({ length: 10 }, (_, i) => (6 + i) / 100),
    '1/2': Array.from({ length: 10 }, (_, i) => Math.ceil(i / 2 + 5) / 100),
    '1/4': Array.from({ length: 10 }, (_, i) => Math.ceil(i / 4 + 4.5) / 100),
  };

  return {
    soft: {
      '1z': { x: multiplier['1x'], y: multiplier['2x'] },
      '2z': { x: multiplier['1/2'], y: multiplier['1x'] },
      '3z': { x: multiplier['1/4'], y: multiplier['1/2'] },
    },
    key: {
      '1z': { x: multiplier['2x'], y: multiplier['4x'] },
      '2z': { x: multiplier['1x'], y: multiplier['2x'] },
      '3z': { x: multiplier['1/2'], y: multiplier['1x'] },
    },
    spot: {
      '1z': { x: multiplier['4x'], y: multiplier['8x'] },
      '2z': { x: multiplier['2x'], y: multiplier['4x'] },
      '3z': { x: multiplier['1x'], y: multiplier['2x'] },
    },
  }[type][depth][axis][distanceIndex];
};

const computeDistance = (distance: string, val: number): number => val * (parseInt(distance, 10) / 10);
const multiplierFn = (val: number, multiplier = 2): number => val * multiplier;

const computeBoxShadow = ({ x, y, blur, color, spread }: ComputeBoxShadowProps): string => {
  const transformNumberToPx = (val: number | string): string => (typeof val === 'number' ? `${val}px` : val);

  return [...[x, y, blur, spread].map(transformNumberToPx), color].join(' ');
};

/**
 * @example
 * const { palette } = useTheme();
 * return (
 *   <Box sx={{
 *     boxShadow: palette.global.getShadow({ color: 'core', type: 'soft', depth: '1z', distance: '70' })
 *   }}>
 *     Hello
 *   </Box>
 * )
 */
export const getShadow = ({ color, type, depth, distance }: GetShadow) => {
  const x = 0;
  const y = computeDistance(distance, getBaseY(type, depth));
  const blur = computeDistance(distance, getBaseBlur(depth));
  const spread = computeDistance(distance, getBaseSpread(type, depth));

  return [
    computeBoxShadow({
      x,
      y: multiplierFn(y, 2),
      blur: multiplierFn(blur, 2),
      spread: multiplierFn(spread, 2),
      color: rgba(getColor(color, 'x'), getBaseColorAlpha(type, depth, distance, 'x')) as Color,
    }),
    computeBoxShadow({
      x,
      y,
      blur,
      spread,
      color: rgba(getColor(color, 'y'), getBaseColorAlpha(type, depth, distance, 'y')) as Color,
    }),
  ].join(', ');
};
