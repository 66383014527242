import React from 'react';
import NoData from '@verticeone/design-system/src/components/NoData';
import { useTranslation } from 'react-i18next';
import { TimelineTwoTone } from '@mui/icons-material';
import { Stack, styled } from '@mui/material';

const StyledTimelineIcon = styled(TimelineTwoTone)(({ theme }) => ({
  color: theme.palette.error.color2,
}));

const EmptyStateNoData = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EMPTY_STATE' });

  return (
    <Stack minHeight="200px" direction="row" alignItems="center" justifyContent="center">
      <NoData
        icon={<StyledTimelineIcon />}
        header={t('NO_DATA_AVAILABLE_TITLE')}
        content={t('NO_DATA_AVAILABLE_TEXT')}
      />
    </Stack>
  );
};

export default EmptyStateNoData;
