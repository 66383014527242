import { WorkflowVersionRow } from './types';
import React, { useMemo } from 'react';
import { DataGridColDef } from '@verticeone/design-system/src/components/DataGrid';
import { Stack } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import { dateTimeFormatterOptions, useFormatDate } from '@vertice/core/src/utils/formatting/date';
import { useTranslation } from 'react-i18next';

export const useWorkflowVersionColumns = (): DataGridColDef<WorkflowVersionRow>[] => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();

  return useMemo(() => {
    const columns: DataGridColDef<WorkflowVersionRow>[] = [
      {
        field: 'versionName',
        headerName: '',
        flex: 1,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <Stack gap={2} direction="row">
            <Text variant="body-bold">{row.versionName}</Text>
          </Stack>
        ),
        valueGetter: ({ row }) => row.updatedAt, // value for sorting
      },
      {
        field: 'lastInvokedAt',
        headerName: t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.INITIATED_AT_COLUMN'),
        renderCell: ({ row }) => formatDate(row.lastInvokedAt, { formatterOptions: dateTimeFormatterOptions }),
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'activeCompletedCounts',
        headerName: t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.ACTIVE_COMPLETED_COLUMN'),
        disableColumnMenu: true,
        sortable: false,
        renderCell: ({ row }) => (
          <>
            <Text variant="body-regular">{row.activeInstances}</Text>
            <Text variant="body-regular" color="text3">
              /{row.completedInstances}
            </Text>
          </>
        ),
        flex: 1,
      },
      {
        field: 'createdAt',
        headerName: t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.CREATED_AT_COLUMN'),
        renderCell: ({ row }) => formatDate(row.createdAt, { formatterOptions: dateTimeFormatterOptions }),
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'actions',
        headerName: '',
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
        renderSkeletonCell: () => null,
      },
    ];
    return columns;
  }, [formatDate, t]);
};
