import React, { FC } from 'react';
import { NodeProps } from 'reactflow';
import { NodeControls } from './NodeControls';
import { WorkflowEndNode } from '../../model/types';
import Text from '@verticeone/design-system/src/components/Text';
import { StyledStartEndNode } from './Shared';
import { useTranslation } from 'react-i18next';

export const EndNodeComponent: FC<NodeProps<WorkflowEndNode>> = (props) => {
  const { t } = useTranslation();
  return (
    <NodeControls
      {...props}
      content={
        <StyledStartEndNode>
          <Text variant="button" size="M">
            {props.data.name || t('MODULES.WORKFLOW.NODES.END.DEFAULT_LABEL')}
          </Text>
        </StyledStartEndNode>
      }
    />
  );
};
