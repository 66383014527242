import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MoreVert } from '@mui/icons-material';

import IconButton from '@verticeone/design-system/src/components/IconButton';
import Text from '@verticeone/design-system/src/components/Text';
import Menu, { MenuItem } from '@verticeone/design-system/src/components/Menu';

import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { DataGridColDef } from '@verticeone/design-system/src/components/DataGrid';

import { WorkflowVersionRow } from './types';
import { dateTimeFormatterOptions, useFormatDate } from '@vertice/core/src/utils/formatting/date';
import { useDeleteWorkflowVersionMutation } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { enhancedBffeWorkflowsAPI, WORKFLOW_BFFE_VERSIONS } from '@vertice/slices';

const useInvalidateWorkflowVersions = () => {
  const dispatch = useDispatch();
  return useCallback(
    () => dispatch(enhancedBffeWorkflowsAPI.util.invalidateTags([WORKFLOW_BFFE_VERSIONS])),
    [dispatch]
  );
};

const DraftActionsButton = (props: WorkflowVersionRow) => {
  const { accountId } = useAccountContext();
  const { t } = useTranslation();
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();
  const [deleteWorkflow, { isLoading: isDeletingWorkflowVersion }] = useDeleteWorkflowVersionMutation();
  const invalidateWorkflowVersions = useInvalidateWorkflowVersions();

  const buttonRef = React.useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const goToEditor = () => {
    navigate(routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.DETAIL.VERSION.EDITOR, {
      workflowId: props.id,
      versionId: props.versionId,
    });
  };

  const handleDelete = () => {
    deleteWorkflow({ accountId, workflowId: props.id, workflowVersion: props.versionId })
      .then((response) => {
        if (!('error' in response)) {
          enqueueSnackbar(t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.DRAFT_DELETED'), {
            variant: 'success',
          });
          invalidateWorkflowVersions();
        }
      })
      .finally(() => {
        setIsMenuOpen(false);
      });
  };

  return (
    <>
      <IconButton icon={MoreVert} variant="plain" ref={buttonRef} onClick={() => setIsMenuOpen(true)} />
      <Menu open={isMenuOpen} anchorEl={buttonRef.current} onClose={() => setIsMenuOpen(false)}>
        <MenuItem onClick={goToEditor}>{t('ENTITIES.WORKFLOW_VERSION.ACTIONS.EDIT')}</MenuItem>
        <MenuItem onClick={handleDelete} disabled={isDeletingWorkflowVersion}>
          {t(
            isDeletingWorkflowVersion
              ? 'INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.DELETING'
              : 'ENTITIES.WORKFLOW_VERSION.ACTIONS.DELETE'
          )}
        </MenuItem>
      </Menu>
    </>
  );
};

export const useWorkflowVersionDraftColumns = (): DataGridColDef<WorkflowVersionRow>[] => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();

  return useMemo(() => {
    const columns: DataGridColDef<WorkflowVersionRow>[] = [
      {
        field: 'workflowName',
        headerName: '',
        flex: 1,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <Stack gap={2} direction="row">
            <Text variant="body-bold">{row.workflowName}</Text>
          </Stack>
        ),
        valueGetter: ({ row }) => row.updatedAt, // value for sorting
      },
      {
        field: 'createdAt',
        headerName: t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.CREATED_AT_COLUMN'),
        renderCell: ({ row }) => formatDate(row.createdAt, { formatterOptions: dateTimeFormatterOptions }),
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'updatedAt',
        headerName: t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.LAST_UPDATED_COLUMN'),
        renderCell: ({ row }) => formatDate(row.updatedAt, { formatterOptions: dateTimeFormatterOptions }),
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'actions',
        headerName: '',
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => <DraftActionsButton {...row} />,
        renderSkeletonCell: () => null,
      },
    ];
    return columns;
  }, [formatDate, t]);
};
