import React, { FC } from 'react';
import { parseRequestRef } from '../../../../../../hooks/workflows/refUtils';
import { Stack } from '@mui/material';
import Button from '@verticeone/design-system/src/components/Button';
import { TaskState, WorkflowTaskNodeType } from '../../../model/types';
import { useRouteNavigate } from '@verticeone/router/src';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAccountContext } from '../../../../../../contexts/AccountContext';

type TaskActionProps = {
  state?: TaskState;
  route?: string;
  taskNodeType?: WorkflowTaskNodeType;
};

export const TaskNavigateAction: FC<TaskActionProps> = ({ state, route, taskNodeType }) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const { generatePath } = useRouteNavigate();
  if (taskNodeType !== 'SERVICE') {
    return null;
  }

  if (!state?.requestRef || !route) {
    return null;
  }

  const requestId = parseRequestRef(state.requestRef).requestId;

  return (
    <Stack>
      <Button component={Link} to={generatePath(route, { requestId, account_id: accountId })}>
        {t('INTELLIGENT_WORKFLOWS.WORKFLOW_SCHEMA.GO_TO_PAGE')}
      </Button>
    </Stack>
  );
};
