import React from 'react';
import Text, { TextProps } from '../Text';
import { useDialog } from './DialogContext';

type DialogTextProps = Omit<TextProps, 'variant'> & {
  variant: 'body-regular' | 'body-bold';
};
const DialogText = (props: DialogTextProps) => {
  const { size, align } = useDialog();
  const textSize = props.size ?? size;
  const textAlign = props.align ?? align;

  return <Text {...props} size={textSize} textAlign={textAlign} color="text2" />;
};

export default DialogText;
