import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import Card, { CardHeader, CardHeaderTitle } from '@verticeone/design-system/src/components/Card';
import { DotsProgressBar } from '@vertice/components/src/DotsProgressBar';
import { ReactComponent as VerticeV } from '@vertice/dashboard/src/vendor/vertice_v.svg';
import { isNil } from 'lodash';
import { StyledCardHeaderTitleIcon } from '@verticeone/design-system/src/components/Card/CardHeaderTitle';
import styled from '@mui/material/styles/styled';

export const StyledCardHeaderTitle = styled(CardHeaderTitle)(({ theme }) => ({
  [`${StyledCardHeaderTitleIcon}`]: {
    backgroundColor: 'transparent',
  },
}));

type VendorPricingCardProps = {
  simplicity?: number;
  transparency?: number;
  parity?: number;
};

const VendorPricingCard = ({ simplicity, transparency, parity }: VendorPricingCardProps) => {
  const { t } = useTranslation();

  if (isNil(simplicity) || isNil(transparency) || isNil(parity)) {
    return null;
  }

  const categories = [
    {
      key: 'SIMPLICITY',
      value: simplicity,
    },
    {
      key: 'TRANSPARENCY',
      value: transparency,
    },
    {
      key: 'PARITY',
      value: parity,
    },
  ];

  return (
    <Card minWidth={374}>
      <CardHeader size="S" noDivider>
        <StyledCardHeaderTitle icon={VerticeV} text={t('VENDOR.PRICING.TITLE')} />
      </CardHeader>
      <Stack gap={7} px={6} pb={8}>
        {categories.map((category) => (
          <Stack key={category.key} gap={3}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Text variant="heading" size="XS" color="primary">
                {t(`VENDOR.PRICING.${category.key}.TITLE`)}
              </Text>
              <DotsProgressBar value={category.value} />
            </Stack>
            <Text variant="body-regular" size="XS">
              {t(`VENDOR.PRICING.${category.key}.DESCRIPTION`)}
            </Text>
          </Stack>
        ))}
      </Stack>
    </Card>
  );
};

export default VendorPricingCard;
