import { z } from 'zod';
import { zodSchema as purchaseIntakeSimpleFormSchema } from '../PurchaseIntakeSimpleForm/schema';

export const zodSchema = z.object({
  ...purchaseIntakeSimpleFormSchema.shape,

  department: z.string(),
  contractCurrency: z.string(),
  targetSignDate: z.string(),
  approvedBudget: z.number().optional().nullish(),
  signingEntity: z.string().optional(),
  desiredContractLength: z.number().optional().nullish(),

  legalReviewRequired: z.boolean().optional(),
  securityReviewRequired: z.boolean().optional(),
  piiExpectedToBeHeld: z.boolean().optional(),

  contactVendorDirectly: z.boolean().optional(),
  discussMultiYearDeals: z.boolean().optional(),
  discussPlannedGrowth: z.boolean().optional(),
  discussCompetitors: z.boolean().optional(),
  discussCaseStudies: z.boolean().optional(),
});

export type FormData = z.infer<typeof zodSchema>;
