import React from 'react';
import { useController, FieldValues } from 'react-hook-form';
import { CommonFormFieldProps } from '../../types';
import { ProductsList } from '@vertice/core/src/modules/saas/contract/components/ProductList';
import { ProductItem } from '@vertice/core/src/modules/saas/contract/components/ProductList/types';

type FormProductsFieldProps<FormDataType extends FieldValues> = CommonFormFieldProps<FormDataType> & {
  vendorId?: string;
  currency?: string;
  editable?: boolean;
  showAnnualCost?: boolean;
  showLicenseType?: boolean;
};

export type FormProductsFieldComponentType<FormDataType extends FieldValues> = (
  props: FormProductsFieldProps<FormDataType>
) => JSX.Element;

const FormProductsField = <FormDataType extends FieldValues = never>({
  name,
  required,
  editable,
  vendorId,
  currency,
  showAnnualCost,
  showLicenseType,
}: FormProductsFieldProps<FormDataType>) => {
  const {
    field: { ref, value, ...field },
  } = useController<FormDataType>({
    name,
    rules: {
      required,
    },
  });

  return (
    <ProductsList
      withActions={editable}
      showAnnualCost={showAnnualCost}
      showLicenseType={showLicenseType}
      selectedProducts={(value as ProductItem[]) || []}
      setSelectedProducts={(newValue) => {
        field.onChange(newValue);
      }}
      currency={currency}
      vendorId={vendorId}
    />
  );
};

export default FormProductsField;
