import React from 'react';
import { CardHeaderTitle } from '../Card';
import { CardHeaderTitleProps } from '../Card/CardHeaderTitle';
import { useDrawer } from './DrawerContext';
import Placeholder from '../Placeholder';

const DrawerHeaderTitle = (props: CardHeaderTitleProps) => {
  const { isLoading } = useDrawer();
  if (isLoading) return <Placeholder width="calc(100% - 50px)" />;

  return <CardHeaderTitle {...props} />;
};

export default DrawerHeaderTitle;
