import React from 'react';
import type { DesignSystemSize } from '../../types';
import { Align } from './types';

export type DialogContextProps = {
  open: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  size: DesignSystemSize;
  align?: Align;
};

const DialogContext = React.createContext<DialogContextProps>({
  open: false,
  size: 'M',
  align: 'left',
} as DialogContextProps);

export const DialogProvider = DialogContext.Provider;

export const useDialog = () => {
  const context = React.useContext(DialogContext);
  if (context === undefined) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  return context;
};
