import React, { FC } from 'react';
import { RequestRow } from './types';
import Text from '@verticeone/design-system/src/components/Text';
import ChipButton from '@verticeone/design-system/src/components/ChipButton';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const LegacyRequestNameCell: FC<{ name: RequestRow['requestName'] }> = ({ name }) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" gap={2}>
      <Text variant="body-regular">{name}</Text>
      <ChipButton variant="ghost" size="S">
        {t('ENTITIES.WORKFLOW_REQUEST.LABELS.LEGACY_REQUEST')}
      </ChipButton>
    </Stack>
  );
};
