import { FC } from 'react';
import Stack from '@mui/material/Stack';
import Text from '@verticeone/design-system/src/components/Text';
import { useTranslation } from 'react-i18next';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { StackProps } from '@mui/material';
import styled from '@mui/material/styles/styled';
import { isPlannedForExpiration } from '../../computed';
import WorkflowStageIndicator from './WorkflowStageIndicator';
import AdditionalDetails, { AdditionalInfoFlag } from './AdditionalDetails';

type StageIndicatorProps = StackProps & {
  contract: Contract;
  /**
   * You can control the visibility of additional info flags by listing them in the flags array.
   * If not specified, all flags are visible.
   */
  flags?: AdditionalInfoFlag[];
};

export const StyledStageText = styled(Text)(() => ({}));

export const StageIndicator: FC<StageIndicatorProps> = ({ contract, flags, ...otherProps }) => {
  const { t } = useTranslation();

  switch (contract.materialized?.simplifiedStage) {
    case 'DRAFT':
      return (
        <Stack {...otherProps}>
          <StyledStageText variant="body-regular" color="text3">
            {t(`ENTITIES.CONTRACT.STAGES.DRAFT`)}
          </StyledStageText>
          <AdditionalDetails
            nextContract={contract.materialized?.next ?? undefined}
            isPlannedForExpiration={isPlannedForExpiration(contract)}
            flags={flags}
          />
        </Stack>
      );
    case 'LIVE':
    case 'NOT_YET_LIVE':
    case 'EXPIRED_NATURAL':
    case 'EXPIRED_UNINTENTIONAL':
    case 'EXPIRED_PLANNED':
    case 'CANCELLED_FIXED_TERM':
    case 'CANCELLED_REQUEST':
    case 'TERMINATED':
    case 'DELETED':
      return (
        <Stack {...otherProps}>
          <StyledStageText variant="body-regular" color="text1" whiteSpace="nowrap">
            {t(`ENTITIES.CONTRACT.STAGES.${contract.materialized?.simplifiedStage}`)}
          </StyledStageText>
          <AdditionalDetails
            nextContract={contract.materialized?.next ?? undefined}
            isPlannedForExpiration={isPlannedForExpiration(contract)}
            flags={flags}
          />
        </Stack>
      );
    case 'WORKFLOW_IN_PROGRESS': // purchase
      return <WorkflowStageIndicator stage={contract.workflow?.stage} {...otherProps} />;
    default:
      return null;
  }
};
