import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import isEqual from 'lodash/isEqual';

import IconButton from '@verticeone/design-system/src/components/IconButton';

import { formatUserRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { UserTask } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';
import UserSelect, { UserSelectProps } from '@vertice/core/src/modules/user/UserSelect';
import useGetAssignableUsers from '@vertice/core/src/modules/intelligentWorkflows/hooks/useGetAssignableUsers';
import { Check, Close } from '@mui/icons-material';

export type AssigneeSelectProps = Pick<UserSelectProps<true>, 'isLoading' | 'color' | 'size' | 'error' | 'variant'> & {
  task: Pick<UserTask, 'assignment' | 'status'>;
  onChange: (assignment: string[]) => void;
};

const AssigneeSelect = ({ task, isLoading, onChange, ...otherProps }: AssigneeSelectProps) => {
  const { assignableUsers, isLoadingAssignableUsers, resolveUsersFromRefs } = useGetAssignableUsers();
  const originalUsers = useMemo(() => resolveUsersFromRefs(task.assignment), [resolveUsersFromRefs, task.assignment]);
  const [currentUsers, setCurrentUsers] = useState(originalUsers);

  const handleApprove = () => onChange(currentUsers.map((u) => formatUserRef(u.id)));
  const handleReject = () => setCurrentUsers(originalUsers);

  const hasOnlyInvalidAssignees = useMemo(
    () => originalUsers.length === 0 && task.assignment.length > 0,
    [originalUsers, task.assignment]
  );
  const { t } = useTranslation();

  return (
    <Stack direction="row" gap={2} alignItems="center">
      <UserSelect
        availableUsers={assignableUsers}
        selectedUsers={currentUsers}
        onChange={(users) => setCurrentUsers([...users])}
        isLoading={isLoadingAssignableUsers || isLoading}
        isDisabled={task.status === 'COMPLETED'}
        placeholder={t(
          hasOnlyInvalidAssignees
            ? 'ENTITIES.WORKFLOW_TASK.LABELS.INVALID_ASSIGNEE'
            : 'ENTITIES.WORKFLOW_TASK.LABELS.NO_ASSIGNEE'
        )}
        isMulti={true}
        oneLine
        error={originalUsers.length === 0}
        {...otherProps}
      />
      {!isEqual(currentUsers, originalUsers) && (
        <Stack direction="row" gap={1}>
          <IconButton variant="ghost" icon={Check} onClick={handleApprove} />
          <IconButton variant="ghost" icon={Close} onClick={handleReject} />
        </Stack>
      )}
    </Stack>
  );
};

export default AssigneeSelect;
