import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useQuery } from '@tanstack/react-query';
import { fetchCloudOptimization } from '../../CloudClientProvider';
import { getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';
import type { TableType } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import dayjs from 'dayjs';

const SpendVsCommitmentChartQuery = graphql(`
  query SpendVsCommitmentChart($accountId: String!) {
    athenaViewQuery(
      params: { accountId: $accountId, name: "cco_view_edp_spend_vs_commitment_v1", parameters: ["{accountId}"] }
    ) {
      ... on DataTableResult {
        __typename
        table(
          columns: [
            "edp_month_start"
            "target_commitment"
            "aws_infra_spend"
            "eligible_marketplace_spend"
            "non_eligible_marketplace_spend"
          ]
        ) {
          columns
          data
          dataTypes
        }
      }
      ... on DeferredQueryResult {
        __typename
      }
      ... on ErroredQueryResult {
        __typename
        error
      }
    }
  }
`);

type DataColumns =
  | 'edp_month_start'
  | 'target_commitment'
  | 'aws_infra_spend'
  | 'eligible_marketplace_spend'
  | 'non_eligible_marketplace_spend';

export type SpendVsCommitmentChartData = {
  months: string[];
  values: { id: string; data: Array<number | null>; type: string; lineWidth?: number; dashStyle?: string }[];
  usedCategories: string[];
};

export const useSpendVsCommitmentChartData = () => {
  const { accountId } = useAccountContext();

  return useQuery({
    queryKey: ['SpendVsCommitmentChart'],
    queryFn: () =>
      fetchCloudOptimization(SpendVsCommitmentChartQuery, {
        accountId,
      }),
    enabled: !!accountId,
    refetchInterval: (data) => {
      return data.state.data?.athenaViewQuery?.__typename === 'DeferredQueryResult' ? 2000 : false;
    },
    select: (data) => {
      if (data.athenaViewQuery?.__typename === 'DataTableResult') {
        const result = (getTableData(data.athenaViewQuery.table as TableType) as Record<DataColumns, number>[]) ?? null;

        return {
          months: result.map((item) => dayjs(item.edp_month_start).format()),
          values: [
            {
              id: 'commitment',
              data: result.map((item) => item.target_commitment),
              type: 'line',
              lineWidth: 1,
              dashStyle: 'Dash',
            },
            {
              id: 'nonEligibleMarketplaceSpend',
              data: result.map((item) => item.non_eligible_marketplace_spend),
              type: 'column',
            },
            {
              id: 'eligibleMarketplaceSpend',
              data: result.map((item) => item.eligible_marketplace_spend),
              type: 'column',
            },
            {
              id: 'awsInfraSpend',
              data: result.map((item) => item.aws_infra_spend),
              type: 'column',
            },
          ],
          usedCategories: ['commitment', 'nonEligibleMarketplaceSpend', 'eligibleMarketplaceSpend', 'awsInfraSpend'],
        };
      }

      return null;
    },
  });
};
