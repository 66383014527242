import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import Text from '@verticeone/design-system/src/components/Text';
import Tooltip from '@verticeone/design-system/src/components/Tooltip';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { VendorLogo } from '@vertice/components';
import { ApplicationWithMeasureReportsListItem } from '@vertice/slices/src/graphql/bffeUsage/derivedTypes';
import WarningIcon from '../UsageDetail/components/DataHealth/WarningIcon';
import InfoIcon from '../UsageDetail/components/DataHealth/InfoIcon';

const VendorCell = ({ matches, name, hasWarnings, hasInfo }: ApplicationWithMeasureReportsListItem) => {
  const { t } = useTranslation();
  const vendor = matches?.find((match) => match.entityType === 'Vendor');

  return (
    <Stack direction="row" height="100%" width="100%" alignItems="center" gap={4}>
      <Stack>
        <VendorLogo vendorId={vendor?.entityId} size={30} />
      </Stack>
      <Stack direction="column" sx={{ width: '100%' }}>
        <Text variant="body-regular" size="M">
          {name}
        </Text>
        <Text variant="caption" size="S">
          {vendor?.entityName}
        </Text>
      </Stack>
      <Stack direction="row" gap={1}>
        {hasWarnings && (
          <Tooltip title={t('APPLICATIONS.TOOLTIPS.DATA_HEALTH.DATA_WARNING')} placement="top" size="XS">
            <WarningIcon />
          </Tooltip>
        )}
        {!hasWarnings && hasInfo && (
          <Tooltip title={t('APPLICATIONS.TOOLTIPS.DATA_HEALTH.DATA_INFO')} placement="top" size="XS">
            <InfoIcon />
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
};

export default VendorCell;
