import { useMemo } from 'react';
import useAssumeRoleAuth from './assumeRole/useAssumeRoleAuth';
import useCfaAmplifyAuth from './amplify/useCfaAmplifyAuth';
import { AuthContextData } from '@verticeone/auth/src';

const useCfaAuth = (): AuthContextData => {
  const assumeRoleAuth = useAssumeRoleAuth();
  const amplifyAuth = useCfaAmplifyAuth();

  return useMemo(() => (assumeRoleAuth.user ? assumeRoleAuth : amplifyAuth), [amplifyAuth, assumeRoleAuth]);
};

export default useCfaAuth;
