import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { useCallback } from 'react';

export type FormatPercentageOptions = {
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  signDisplay?: 'auto' | 'never' | 'always' | 'exceptZero';
  locale: string;
};

export const formatPercentage = (
  value: number,
  { minimumFractionDigits, maximumFractionDigits, signDisplay, locale }: FormatPercentageOptions
) =>
  new Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits,
    maximumFractionDigits,
    signDisplay,
  }).format(value);

export const useFormatPercentage = () => {
  const { locale } = useLocaleContext();
  return useCallback(
    (value: number, options: Omit<FormatPercentageOptions, 'locale'> = {}) =>
      formatPercentage(value, { ...options, locale }),
    [locale]
  );
};
