import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import { useSearchParams } from 'react-router-dom';
import { parseBase64EncodedToken } from '@vertice/utils';
import {
  FeedbackLabel,
  Form,
  FormLabel,
  SubmitButton,
  PasswordInput,
  ConfirmPasswordInput,
} from '@vertice/components/src/Form';
import { EmailField } from '@vertice/core/src/components/EmailField';

import { Invitation } from '../models/invitation';
import PasswordCriteria from '../components/PasswordCriteria/PasswordCriteria';
import authStyles from '../Auth.module.scss';
import AuthContainer from '../components/AuthContainer/AuthContainer';
import { passwordValidationSchema } from '../useValidatePassword';

const SignUp = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const userEmailProvided = useMemo(
    () => Boolean(parseBase64EncodedToken<Invitation>(searchParams.get('token'))?.userEmail ?? false),
    [searchParams]
  );
  const [error, setError] = useState('');
  const [passwordMismatchError, setPasswordMismatchError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const defaultFormValues = {
    email: parseBase64EncodedToken<Invitation>(searchParams.get('token'))?.userEmail ?? '',
    password: '',
    confirmPassword: '',
  };

  const onSubmit = async (data: typeof defaultFormValues) => {
    setIsLoading(true);
    Auth.signUp({
      username: data.email,
      password: data.password,
      clientMetadata: {
        challengeToken: searchParams.get('token') || '',
      },
      autoSignIn: {
        enabled: true,
      },
    })
      .then(() => {
        searchParams.delete('token');
        setSearchParams(searchParams);
      })
      .catch((e) => {
        if (e && typeof e === 'object' && !!Object.prototype.hasOwnProperty.call(e, 'message')) {
          const errorObject = e as { message: string };
          if (errorObject?.message) {
            setError(errorObject.message.includes('FORBIDDEN') ? t('SIGN_UP.ERROR_FORBIDDEN') : errorObject.message);
          }
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handlePasswordConfirmValidationResult = (value: boolean) => {
    if (value) {
      setError('');
      setPasswordMismatchError(false);
    } else {
      setError(t('SIGN_UP.ERROR_PASSWORD_MISMATCH'));
      setPasswordMismatchError(true);
    }
  };

  return (
    <AuthContainer>
      <Typography variant="heading-s">{t('SIGN_UP.HEADER')}</Typography>
      <Form defaultValues={defaultFormValues} onSubmit={onSubmit} className={authStyles.form}>
        <div className={authStyles['form-wrapper']}>
          <FormControl fullWidth variant="outlined">
            <FormLabel for="email">{t('SIGN_UP.EMAIL')}</FormLabel>
            <EmailField<typeof defaultFormValues>
              id="email"
              name="email"
              autoFocus={!userEmailProvided}
              disabled={userEmailProvided}
              required
            />
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <FormLabel for="password" error={passwordMismatchError}>
              {t('SIGN_UP.PASSWORD')}
            </FormLabel>
            <PasswordInput<typeof defaultFormValues>
              id="password"
              required
              error={passwordMismatchError}
              autoFocus={userEmailProvided}
              validate={passwordValidationSchema}
            />
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <FormLabel for="confirmPassword" error={passwordMismatchError}>
              {t('SIGN_UP.CONFIRM_PASSWORD')}
            </FormLabel>
            <ConfirmPasswordInput<typeof defaultFormValues>
              id="confirmPassword"
              watchFor="password"
              error={passwordMismatchError}
              onValidationResult={handlePasswordConfirmValidationResult}
            />
          </FormControl>
          <FeedbackLabel error={error} />
          <PasswordCriteria />
        </div>
        <div className={authStyles.actions}>
          <SubmitButton isLoading={isLoading}>{t('SIGN_UP.SUBMIT')}</SubmitButton>
        </div>
      </Form>
    </AuthContainer>
  );
};

export default SignUp;
