import React from 'react';
import useAccountsData from '../Table/useAccountsData';
import { useCloudAccounts } from '../CloudAccountsContext';
import BaseCloudAccountsStats from './BaseCloudAccountsStats';

const CloudAccountsTable = () => {
  const { period } = useCloudAccounts();
  const { data, isLoading } = useAccountsData(period);
  return <BaseCloudAccountsStats data={data?.stats} isLoading={isLoading} />;
};

export default CloudAccountsTable;
