import React from 'react';
import { useInstanceData } from '../providers/InstanceProvider';
import { ShoppingCartProvider } from '../providers/ShoppingCartProvider';
import Drawer from '@verticeone/design-system/src/components/Drawer';

const OFFSET = 64;

type Props = {
  children?: React.ReactNode;
};

const BuyInstanceDrawer = ({ children }: Props) => {
  const { buyDrawer } = useInstanceData();

  return (
    <Drawer width={`calc(100% - ${OFFSET}px)`} open={buyDrawer.isOpened} onClose={buyDrawer.close}>
      <ShoppingCartProvider>{children}</ShoppingCartProvider>
    </Drawer>
  );
};

export default BuyInstanceDrawer;
