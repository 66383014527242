import { enhancedUsageGraphQL, APPLICATIONS_TAG as APPLICATIONS_TAG_FROM_USAGE } from '@vertice/slices';
import { ApplicationsBasicQuery, api } from './generated/insightGraphQL';
import { ArrayElement } from '@vertice/core/src/modules/applications/Applications/types';

const INSIGHT_TAG = 'Insight';
const APPLICATIONS_TAG = 'Applications';
const TRANSACTIONS_TAG = 'Transactions';
const VENDORS_TAG = 'Vendors';
const JOBS_TAG = 'Jobs';

export type ApplicationsBasic = NonNullable<ApplicationsBasicQuery['applicationsPaginated']>['items'];
export type BasicApplication = ArrayElement<ApplicationsBasic>;

export const enhancedInsightGraphQL = api.enhanceEndpoints({
  addTagTypes: [INSIGHT_TAG, APPLICATIONS_TAG, TRANSACTIONS_TAG, VENDORS_TAG, JOBS_TAG],
  endpoints: {
    GetProviders: {
      providesTags: [INSIGHT_TAG],
    },
    GetProvider: {
      providesTags: [INSIGHT_TAG],
    },
    ActivateProvider: {
      invalidatesTags: [INSIGHT_TAG],
    },
    DeactivateProvider: {
      invalidatesTags: [INSIGHT_TAG],
    },
    DiscoveredApplication: {
      invalidatesTags: [APPLICATIONS_TAG],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(enhancedUsageGraphQL.util.invalidateTags([APPLICATIONS_TAG_FROM_USAGE]));
      },
    },
    UpdateCustomerTransaction: {
      invalidatesTags: [TRANSACTIONS_TAG],
    },
    CustomerTransactions: {
      providesTags: [TRANSACTIONS_TAG],
    },
    DiscoveredCustomerVendor: {
      invalidatesTags: [VENDORS_TAG],
    },
    CustomerVendors: {
      providesTags: [VENDORS_TAG],
    },
    CustomerVendorsPaginated: {
      providesTags: [VENDORS_TAG],
    },
    JobsPaginated: {
      providesTags: [JOBS_TAG],
    },
    ExecuteDiscoveryJob: {
      invalidatesTags: [JOBS_TAG],
    },
  },
});
