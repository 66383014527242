import { createPortableRoutes } from '@verticeone/router/src/routes/utils';

export const cloudRoutes = createPortableRoutes({
  CLOUD: {
    path: '/cloud',
    children: {
      OVERVIEW: {
        path: 'overview',
      },
      ANALYTICS: {
        path: 'analytics',
        children: {
          TAB: {
            path: ':activeTab',
          },
        },
      },
      INSIGHTS: {
        path: 'insights',
        children: {
          TAB: {
            path: ':activeTab',
          },
        },
      },
      EDP: {
        path: 'edp',
      },
      OPTIMIZATION: {
        path: 'optimization',
        children: {
          RECOMMENDATION: {
            path: 'recommendation',
            children: {
              CODE: {
                path: ':code',
              },
            },
          },
        },
      },
      RIO: {
        path: 'rio',
        children: {
          OPTIMIZE: {
            path: 'optimize',
            children: {
              DETAIL: {
                path: ':product',
              },
            },
          },
          INVENTORY: {
            path: 'inventory',
            children: {
              DETAIL: {
                path: ':product',
              },
            },
          },
          TRANSACTIONS: {
            path: 'transactions',
          },
        },
      },
    },
  },
} as const);
