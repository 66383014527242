import React, { forwardRef } from 'react';
import styled from '@mui/material/styles/styled';
import { type TextFieldAreaProps, type StyledComponentProps } from './components/constants';
import TextFieldAreaWrapper from './components/TextFieldAreaWrapper';
import { getTextFieldAreaStyles } from './utils';
import { TextareaAutosize } from '@mui/material';

const StyledTextArea = styled('textarea')<Pick<StyledComponentProps, 'theme' | '$autosize' | '$minHeight' | '$size'>>(
  ({ theme, $size, $minHeight, $autosize }) => getTextFieldAreaStyles({ theme, $size, $minHeight, $autosize })
);

const StyledTextAreaAutosize = styled(TextareaAutosize)<
  Pick<StyledComponentProps, 'theme' | '$autosize' | '$minHeight' | '$size'>
>(({ theme, $size, $minHeight, $autosize }) => getTextFieldAreaStyles({ theme, $size, $minHeight, $autosize }));

const TextFieldArea = forwardRef<HTMLDivElement, TextFieldAreaProps>(
  ({ size = 'M', minHeight = 30, autosize = false, ...otherProps }, ref) => {
    const limitCheck = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (otherProps.textLimit && e.target.value.length > otherProps.textLimit) {
        e.target.value = e.target.value.slice(0, otherProps.textLimit);
      }

      otherProps.onChange?.(e);
    };

    const textAreaProps = {
      className: 'textarea',
      placeholder: otherProps.placeholder,
      onChange: limitCheck,
      $size: size,
      $minHeight: minHeight,
      $autosize: autosize,
      value: otherProps.value,
      disabled: otherProps.disabled,
    };

    return (
      <TextFieldAreaWrapper size={size} autosize={autosize} {...otherProps} ref={ref}>
        {autosize ? (
          <StyledTextAreaAutosize {...otherProps} {...textAreaProps} />
        ) : (
          <StyledTextArea {...textAreaProps} />
        )}
      </TextFieldAreaWrapper>
    );
  }
);

export default TextFieldArea;
