import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useGetServiceCatalogResources } from '@vertice/admin-panel/src/modules/intelligentWorkflows/Workflow/sharedHooks/useGetServiceCatalogResources';
import { IntelligentWorkflowsLayout } from '../../components/IntelligentWorkflowsLayout';
import { WorkflowsListSkeleton } from './ServiceWorkflowTableSkeleton';
import { ServiceWorkflowTable } from './ServiceWorkflowTable';

export const WorkflowsListPage: FC = () => {
  const { accountId } = useAccountContext();
  const { t } = useTranslation();
  const { resources, isLoading } = useGetServiceCatalogResources({
    resourcesFilter: (item) => item.kind === 'Vertice/ServiceCatalog/Service/ServiceDefinition',
    skipGlobalResources: true,
  });
  const unknownServiceRef = `urn:verticeone:vertice:${accountId}:services:resource/unknown`;

  return (
    <IntelligentWorkflowsLayout heading={t('INTELLIGENT_WORKFLOWS.NAVBAR.WORKFLOWS')}>
      <Stack gap={8}>
        {isLoading ? (
          [1, 2, 3].map((i) => <WorkflowsListSkeleton key={i} />)
        ) : (
          <>
            {resources.map((resource) => (
              <ServiceWorkflowTable
                key={resource.urn}
                serviceName={resource.name}
                serviceRef={resource.urn}
                defaultWorkflowRef={resource.definition?.ServiceProvider?.HandlerUrn}
              />
            ))}
            <ServiceWorkflowTable
              serviceName={t('INTELLIGENT_WORKFLOWS.WORKFLOWS_LIST.UNKNOWN_SERVICE')}
              serviceRef={unknownServiceRef}
            />
          </>
        )}
      </Stack>
    </IntelligentWorkflowsLayout>
  );
};
