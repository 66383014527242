import { WorkflowRow } from './types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Stack } from '@mui/material';

import { DataGridColDef } from '@verticeone/design-system/src/components/DataGrid';
import Button from '@verticeone/design-system/src/components/Button';
import Text from '@verticeone/design-system/src/components/Text';
import ChipButton from '@verticeone/design-system/src/components/ChipButton';

import { dateTimeFormatterOptions, useFormatDate } from '@vertice/core/src/utils/formatting/date';
import { useListServiceWorkflowsQuery } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { parseWorkflowRef } from '@vertice/core/src/hooks/workflows/refUtils';

export const useGetServiceWorkflows = (serviceRef?: string, defaultWorkflowRef?: string) => {
  const { accountId } = useAccountContext();
  const { data: workflowTypeData } = useListServiceWorkflowsQuery({ accountId, serviceRef: serviceRef });

  const workflows = useMemo(() => {
    const { workflowId: defaultWorkflowId } = parseWorkflowRef(defaultWorkflowRef);
    return workflowTypeData?.workflows.map((workflow) => ({
      ...workflow,
      isDefault: workflow.id === defaultWorkflowId,
    }));
  }, [defaultWorkflowRef, workflowTypeData?.workflows]);

  return {
    workflows,
  };
};

export const useWorkflowsColumns = (): DataGridColDef<WorkflowRow>[] => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const { generatePath } = useRouteNavigate();
  const routes = useRoutes();

  return useMemo(() => {
    const columns: DataGridColDef<WorkflowRow>[] = [
      {
        field: 'workflowName',
        headerName: t('INTELLIGENT_WORKFLOWS.WORKFLOWS_LIST.WORKFLOW_NAME_COLUMN'),
        flex: 2,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <Stack gap={2} direction="row" alignItems="center">
            <Text variant="body-bold">{row.workflowName}</Text>
            {row.isDefault && (
              <ChipButton isActive={false} color="success" size="S" variant="ghost">
                {t('INTELLIGENT_WORKFLOWS.WORKFLOWS_LIST.DEFAULT_WORKFLOW_CHIP')}
              </ChipButton>
            )}
          </Stack>
        ),
      },
      {
        field: 'activeCompletedCounts',
        headerName: t('INTELLIGENT_WORKFLOWS.WORKFLOWS_LIST.ACTIVE_COMPLETED_COLUMN'),
        disableColumnMenu: true,
        sortable: false,
        renderCell: ({ row }) => (
          <>
            <Text variant="body-regular">{row.activeInstances}</Text>
            <Text variant="body-regular" color="text3">
              /{row.completedInstances}
            </Text>
          </>
        ),
        flex: 1,
      },
      {
        field: 'activeVersionName',
        headerName: t('INTELLIGENT_WORKFLOWS.WORKFLOWS_LIST.LIVE_VERSION_NAME_COLUMN'),
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
      },
      {
        field: 'lastInvokedAt',
        headerName: t('INTELLIGENT_WORKFLOWS.WORKFLOWS_LIST.LAST_INVOCATION_COLUMN'),
        renderCell: ({ row }) => formatDate(row.lastInvokedAt, { formatterOptions: dateTimeFormatterOptions }),
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
      },
      {
        field: 'isDefault',
      },
      {
        field: 'actions',
        headerName: '',
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <Button
            component={Link}
            to={generatePath(routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.DETAIL.ABSOLUTE_PATH, {
              workflowId: row.id,
            })}
            size="S"
            variant="ghost"
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          >
            {t('INTELLIGENT_WORKFLOWS.WORKFLOWS_LIST.VIEW_ACTION')}
          </Button>
        ),
        renderSkeletonCell: () => null,
      },
    ];
    return columns;
  }, [formatDate, generatePath, routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.DETAIL.ABSOLUTE_PATH, t]);
};
