import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography, Button } from '@mui/material';
import { FilePresentOutlined, DownloadOutlined } from '@mui/icons-material';
import { Loader } from '@vertice/components';
import styles from './QuestionnaireFile.module.scss';

const statusClassMap = {
  notSent: 'not-sent',
  sent: 'sent',
  requested: 'requested',
  completed: 'completed',
};

export type QuestionerStatus = 'notSent' | 'sent' | 'requested' | 'completed';
type QuestionerState = 'active' | 'inactive' | 'received';

export interface Questionnaire {
  fileName: string;
  status: QuestionerStatus;
  state: QuestionerState;
  completedDate?: string;
}

interface QuestionnaireFileProps extends Questionnaire {
  onSend?: () => void;
  onCancel?: () => void;
  onDownload: () => void;
  isLoading?: boolean;
}

const QuestionnaireFile: FC<QuestionnaireFileProps> = ({
  fileName,
  status,
  state,
  completedDate,
  onSend,
  onCancel,
  onDownload,
  isLoading,
}) => {
  const { t } = useTranslation();

  const withCancel = typeof onCancel === 'function';
  const withSend = typeof onSend === 'function';

  const statusLocalMap = {
    notSent: t('LEGAL_INFO.STATUSES.NOT_SENT'),
    sent: t('LEGAL_INFO.STATUSES.SENT'),
    requested: t('LEGAL_INFO.STATUSES.REQUESTED'),
    completed: t('LEGAL_INFO.STATUSES.COMPLETED'),
  };

  const isInactive = state === 'inactive';

  const send = () => {
    if (withSend) {
      onSend();
    }
  };

  const cancel = () => {
    if (withCancel) {
      onCancel();
    }
  };

  const downloadFile = () => {
    if (!isInactive) {
      onDownload();
    }
  };

  if (state === 'received') {
    return (
      <div className={styles['received-file-wrap']} data-testid="received-wrapper">
        {t('LEGAL_INFO.REQUEST_RECEIVED')}
      </div>
    );
  }

  return (
    <div className={clsx(styles['file-wrap'], { [styles.inactive]: isInactive })} data-testid="wrapper">
      <div className={styles['file-content']}>
        {isLoading && (
          <div className={styles['file-loader-wrap']}>
            <Loader size={30} />
          </div>
        )}

        <div className={styles['file-icon-wrap']}>
          <FilePresentOutlined className={styles['file-icon']} data-testid="file-icon" />
        </div>

        <div className={styles['info-wrap']}>
          <div className={styles.info}>
            <Typography variant="body-regular-s" className={styles['info-name']}>
              {fileName}
            </Typography>
            {['notSent', 'sent', 'requested', 'completed'].includes(status) && (
              <div
                className={clsx(styles['status-chip'], { [styles[statusClassMap[status]]]: statusClassMap[status] })}
                data-testid="status-chip"
              >
                <Typography variant="button-xxs">{statusLocalMap[status]}</Typography>
              </div>
            )}
          </div>

          {completedDate && (
            <Typography variant="caption-xs" className={styles['info-date']}>
              {t('LEGAL_INFO.COMPLETED_AT', { completedDate })}
            </Typography>
          )}
        </div>

        <div className={styles['download-icon-wrap']} onClick={downloadFile} data-testid="download-button">
          <DownloadOutlined className={styles['download-icon']} data-testid="download-icon" />
        </div>
      </div>

      {withSend && (
        <Button
          variant="contained"
          className={styles['send-button']}
          onClick={send}
          disabled={isInactive}
          data-testid="send-button"
        >
          <Typography variant="button-s">{t('LEGAL_INFO.SEND_BUTTON')}</Typography>
        </Button>
      )}

      {withCancel && (
        <Button
          variant="contained"
          className={styles['cancel-button']}
          onClick={cancel}
          disabled={isInactive}
          data-testid="cancel-button"
        >
          <Typography variant="button-s">{t('LEGAL_INFO.CANCEL_BUTTON')}</Typography>
        </Button>
      )}
    </div>
  );
};

export default QuestionnaireFile;
