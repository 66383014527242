import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';

export const useGetCurrencyParts = () => {
  const { locale } = useLocaleContext();

  return (currency: string, value: number, maximumFractionDigits = 0) => {
    const parts = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      maximumFractionDigits,
    }).formatToParts(value);

    return {
      currency: parts.find((part) => part.type === 'currency')?.value ?? currency,
      value: parts
        .filter((item) => item.type !== 'currency')
        .map((item) => item.value)
        .join(''),
    };
  };
};
