import React from 'react';
import { OneLoginIcon } from '@vertice/assets';
import { useTranslation } from 'react-i18next';
import { INTEGRATION_ROUTES, ROUTES } from '@vertice/utils/src/constants/cfa/constants';
import UsageStatsSetupCard from '../UsageStatsSetupCard';

interface OneLoginSetupCardProps {
  providerId: string;
}

const OneLoginSetupCard = ({ providerId }: OneLoginSetupCardProps) => {
  const { t } = useTranslation();

  return (
    <UsageStatsSetupCard
      providerId={providerId}
      providerName="OneLogin"
      providerIcon={<OneLoginIcon />}
      description={t('PREFERENCES.INTEGRATIONS.ONELOGIN.DESCRIPTION')}
      wizardRoute={`${ROUTES.PREFERENCES}/${INTEGRATION_ROUTES.BASE_PATH}/${INTEGRATION_ROUTES.ONELOGIN_SETUP}`}
    />
  );
};

export default OneLoginSetupCard;
