import { useLatestOptimizationCheckQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import { useTranslation } from 'react-i18next';
import LoadableAdvanced from '@vertice/utils/src/loadableAdvanced';
import toConstantCase from '@vertice/core/src/utils/strings/toConstantCase';
import { getTableData } from '../../utils/graphDataUtils';
import { chain, sumBy } from 'lodash';

const CHECK_CODE = 'EC2_AMD_GRAVITON_INTEL_COST';

export type EC2ProcessorUsageRow = {
  name: string;
  processor: string;
  normalizedUsageQuantity: number;
  cost: number;
};

export type EC2ProcessorUsageData = EC2ProcessorUsageRow[];

const useEC2ProcessorUsageData = (): LoadableAdvanced<EC2ProcessorUsageData> => {
  const { accountId } = useSelector(getAccount);
  const { data: rawData, error } = useLatestOptimizationCheckQuery(
    { accountId: accountId!, checkCode: CHECK_CODE },
    { skip: !accountId }
  );
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EC2_PROCESSOR_USAGE.PROCESSORS' });

  const computed = useMemo(() => {
    if (!rawData || rawData.monitoringLatestQuery?.__typename !== 'MonitoringResult') {
      return undefined;
    }

    const checkData = rawData.monitoringLatestQuery.items?.find(
      (rec) => rec.code === CHECK_CODE && rec.results?.__typename === 'MonitoringValueCheckResult'
    );

    if (checkData?.results?.__typename !== 'MonitoringValueCheckResult') {
      return [];
    }

    const result = checkData?.results;

    const checkDataParsed = getTableData(result, {
      processor: 'processor',
      sum_normalized_usage_quantity: 'normalizedUsageQuantity',
      sum_cost: 'cost',
    }) as Omit<EC2ProcessorUsageRow, 'name'>[];

    return chain(checkDataParsed)
      .groupBy('processor')
      .map((records, processor) => ({
        name: t(toConstantCase(processor)) ?? processor,
        processor,
        normalizedUsageQuantity: sumBy(records, 'normalizedUsageQuantity'),
        cost: sumBy(records, 'cost'),
      }))
      .value();
  }, [t, rawData]);

  return { error: error, isEmpty: Boolean(computed && computed.length === 0), isLoading: !computed, data: computed };
};

export default useEC2ProcessorUsageData;
