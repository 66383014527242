import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useListWorkflowsRequestsPaginatedQuery } from '@vertice/slices/src/api/enhancedBffeWorkflowsAPI';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { isSaasRenewalServiceRef } from '@vertice/core/src/hooks/workflows/refUtils';

export const useContractRenewalRequests = (contract: Contract) => {
  const { accountId, isFeatureEnabled } = useAccountContext();
  const isIWEnabled = isFeatureEnabled(FEATURES.INTELLIGENT_WORKFLOWS);

  const { data: requests, isLoading } = useListWorkflowsRequestsPaginatedQuery(
    {
      accountId,
      contractId: contract.record.contractId,
    },
    { skip: !isIWEnabled }
  );
  const linkedRequests = requests?.items.filter((request) => isSaasRenewalServiceRef(request.serviceRef)) || [];

  return {
    linkedRequests,
    isLoadingLinkedRequests: isLoading,
  };
};
