import React, { useMemo, useState } from 'react';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { useTranslation } from 'react-i18next';
import { Box, Stack, styled, useTheme } from '@mui/material';
import DataGrid, { type GridColDef } from '@vertice/components/src/DataGrid';
import type { GridCellParams, GridRenderCellParams, GridRowModel, GridRowParams } from '@mui/x-data-grid-pro';
import RegionTypeCell from './RegionTypeCell';
import Text from '@verticeone/design-system/src/components/Text';
import { isNil } from 'lodash';
import type { FamilyInstanceItem, RegionItem } from './types';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import { useFormatPercentage } from '@vertice/core/src/utils/formatting/percentage';
import type { SxProps } from '@mui/system';
import type { Theme } from '@mui/material/styles/createTheme';
import RowDetailModal from './RowDetailModal';
import { useCloudInventory } from './useCloudInventory';
import { productToDBEMap, ProductType } from './Rds/products';

interface HourlyRateCellProps {
  value: number;
  row: {
    averageHourlyOnDemandRate: number;
  };
}
const HourlyRateCell: React.FC<HourlyRateCellProps> = ({ value, row }) => {
  const { locale } = useLocaleContext();
  const formatPercentage = useFormatPercentage();

  if (isNil(value)) {
    return null;
  }

  const hourlyOnDemandRate = row.averageHourlyOnDemandRate;
  const formattedHourlyRate = new Intl.NumberFormat(locale, {
    maximumFractionDigits: 3,
    minimumFractionDigits: 3,
  }).format(value);

  const getFormattedPercentage = () => {
    let reservedToOnDemand = null;
    if (hourlyOnDemandRate) {
      // Check for null, undefined and 0 value
      reservedToOnDemand = value / hourlyOnDemandRate;
    } else if (value === 0) {
      reservedToOnDemand = 0;
    }

    if (reservedToOnDemand === null) {
      return null;
    }

    return `(${formatPercentage(reservedToOnDemand)})`;
  };

  const formattedPercentage = getFormattedPercentage();

  return (
    <Stack direction="row" gap={2} justifyContent="flex-end">
      <Text variant="body-regular" size="M" color="text1">
        {formattedHourlyRate}
      </Text>
      <Box display="flex" flexShrink={0} flexBasis="50px" justifyContent="flex-end">
        {!!formattedPercentage && (
          <Text variant="body-regular" size="M" color="text3">
            {formattedPercentage}
          </Text>
        )}
      </Box>
    </Stack>
  );
};

const useRiUtilizationTableColumns = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RI_UTILIZATION' });

  const { activeTab } = useCloudInventory();

  const getProductValue = (value: string) => {
    const { databaseEngine, databaseEdition } = productToDBEMap[value as ProductType] ?? {};

    return databaseEngine
      ? databaseEdition === 'NA'
        ? databaseEngine
        : `${databaseEngine} ${databaseEdition}`
      : value;
  };

  return (
    [
      activeTab.id === 'rds'
        ? {
            field: 'product',
            headerName: t('COLUMN.DATABASE_ENGINE'),
            renderCell: ({ value }) => (
              <Text variant="body-regular" size="M">
                {getProductValue(value)}
              </Text>
            ),
            editable: false,
            sortable: true,
            flex: 1,
          }
        : undefined,
      {
        field: 'quantity',
        headerName: t('COLUMN.QUANTITY'),
        renderCell: ({ value }) => (
          <Text variant="body-regular" size="M">
            {value}
          </Text>
        ),
        align: 'right',
        headerAlign: 'right',
        editable: false,
        sortable: true,
        flex: 1,
      },
      {
        field: 'normalizedUnits',
        headerName: t('COLUMN.NORMALIZED_UNITS'),
        renderCell: ({ value }) => (
          <Text variant="body-regular" size="M">
            {value}
          </Text>
        ),
        align: 'right',
        headerAlign: 'right',
        editable: false,
        sortable: true,
        flex: 1,
      },
      {
        field: 'averageHourlyRate',
        headerName: `${t('COLUMN.HOURLY_RATE')} (${t('COLUMN.%_VS_OD')})`,
        renderCell: ({ value, row }) => <HourlyRateCell value={value} row={row} />,
        disableColumnMenu: true,
        align: 'right',
        headerAlign: 'right',
        editable: false,
        sortable: false,
        flex: 1,
      },
    ] as GridColDef[]
  ).filter(Boolean);
};

const HEADER_HEIGHT = 48;
const ITEM_ROW_HEIGHT = 60;

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  borderRadius: '0',
  border: 'none',

  '.MuiDataGrid-row': {
    backgroundColor: theme.palette.input.bg,
    '&:hover': {
      cursor: 'pointer',
    },
  },

  '.group-row': {
    backgroundColor: theme.palette.input.color1,
  },
}));

type RIUtilizationTableProps = {
  data: RegionItem[];
  sx?: SxProps<Theme>;
};

const PAGE_HEADER_HEIGHT = 233;

const RIUtilizationTable = ({ data, sx }: RIUtilizationTableProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RI_UTILIZATION' });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerData, setDrawerData] = useState(null);
  const columns = useRiUtilizationTableColumns();
  const { palette } = useTheme();

  /** Flatten tree data and add helper attributes */
  const rows = useMemo(
    () =>
      data.reduce((acc: (RegionItem | FamilyInstanceItem)[], item: RegionItem) => {
        return [...acc, { ...item, group: true }, ...item.items];
      }, []),
    [data]
  );

  const getRowId = (row: GridRowModel) => row.id;
  const getTreeDataPath = (row: GridRowModel) => [row.region, ...(row.group ? [] : [row.id])];

  const groupingColumn = {
    headerName: t('COLUMN.INSTANCE_FAMILY'),
    renderCell: (params: GridRenderCellParams) => <RegionTypeCell {...params} />,
    colSpan: ({ row }: GridCellParams) => (row.group ? columns.length + 1 : 1),
  };

  return (
    <Box bgcolor={palette.core.color1} sx={{ height: `max(calc(100vh - ${PAGE_HEADER_HEIGHT}px), 600px)` }}>
      <RowDetailModal data={drawerData} onClose={() => setIsDrawerOpen(false)} isOpen={isDrawerOpen} />
      <StyledDataGrid
        treeData
        getTreeDataPath={getTreeDataPath}
        groupingColDef={groupingColumn}
        defaultGroupingExpansionDepth={-1} // expand all rows
        rows={rows || []}
        columns={columns}
        getRowId={getRowId}
        sortingMode="client"
        hideFooter
        getRowClassName={(params: GridRowParams) => (params.row.group ? 'group-row' : '')}
        onRowClick={(props) => {
          if (!props.row.group) {
            setDrawerData(props.row);
            setIsDrawerOpen(true);
          }
        }}
        rowHeight={ITEM_ROW_HEIGHT}
        columnHeaderHeight={HEADER_HEIGHT}
        showCellVerticalBorder
        showColumnVerticalBorder
        disableRowSelectionOnClick
        autoHeight={false}
        color={AWS_BRAND_COLOR}
      />
    </Box>
  );
};

export default RIUtilizationTable;
