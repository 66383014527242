type ApplicationType = 'IAT' | 'CFA';

type profileType = 'default' | 'production' | 'stg' | 'qa' | 'development' | 'rnd';

const {
  REACT_APP_PROFILE,
  REACT_APP_API_MAIN_BASE_URL,
  REACT_APP_API_ADMIN_ACCOUNTS_BASE_URL,
  REACT_APP_API_ACCOUNTS_BASE_URL,
  REACT_APP_API_CONTRACTS_BASE_URL,
  REACT_APP_API_CONTRACTS_V2_BASE_URL,
  REACT_APP_API_BFFE_SAAS_BASE_URL,
  REACT_APP_API_CONTRACT_COST_BASE_URL,
  REACT_APP_API_TAGS_BASE_URL,
  REACT_APP_API_WORKFLOWS_V2_BASE_URL,
  REACT_APP_API_CONVERSATIONS_BASE_URL,
  REACT_APP_API_CONTRACT_WORKFLOWS_V2_BASE_URL,
  REACT_APP_API_PRODUCTS_BASE_URL,
  REACT_APP_API_USERS_BASE_URL,
  REACT_APP_API_EVENTS_BASE_URL,
  REACT_APP_API_FEATURES_BASE_URL,
  REACT_APP_API_ANALYTICS_BASE_URL,
  REACT_APP_API_INTEGRATIONS_BASE_URL,
  REACT_APP_API_ACCESSMANAGEMENT_BASE_URL,
  REACT_APP_API_INSIGHT_BASE_URL,
  REACT_APP_AWS_OPTIMIZATION_GUIDE_URI,
  REACT_APP_INSIGHT_GRAPHQL_BASE_URL,
  REACT_APP_USAGE_GRAPHQL_BASE_URL,
  REACT_APP_COREAPP_GRAPHQL_BASE_URL,
  REACT_APP_CLOUDOPTIMIZATION_GRAPHQL_BASE_URL,
  REACT_APP_LOGOS_BASE_URL,
  REACT_APP_API_STORAGE_BASE_URL,
  REACT_APP_API_LEGAL_BASE_URL,
  REACT_APP_API_SCIM_BASE_URL,
  REACT_APP_API_BFFE_WORKFLOWS_BASE_URL,
  REACT_APP_API_SERVICES_BASE_URL,
  REACT_APP_API_AUTH_SERVICE_BASE_URL,
  REACT_APP_API_DOCPROC_BASE_URL,
  REACT_APP_WSS_BASE_URL,
} = window._configEnv;

const PROFILE = (REACT_APP_PROFILE || 'default') as profileType;
export const VENDORS_LIST_FILTERS_LIMIT = 25;

const baseUrlsMap = {
  default: {
    MAIN: REACT_APP_API_MAIN_BASE_URL,
    ADMIN_ACCOUNTS: REACT_APP_API_ADMIN_ACCOUNTS_BASE_URL,
    ACCOUNTS: REACT_APP_API_ACCOUNTS_BASE_URL,
    CONTRACT_V2: REACT_APP_API_CONTRACTS_V2_BASE_URL,
    CONTRACT_COST: REACT_APP_API_CONTRACT_COST_BASE_URL,
    TAGS: REACT_APP_API_TAGS_BASE_URL,
    WORKFLOWS_V2: REACT_APP_API_WORKFLOWS_V2_BASE_URL,
    CONTRACT_WORKFLOWS_V2: REACT_APP_API_CONTRACT_WORKFLOWS_V2_BASE_URL,
    BFFE_SAAS: REACT_APP_API_BFFE_SAAS_BASE_URL,
    CONTRACTS: REACT_APP_API_CONTRACTS_BASE_URL,
    CONVERSATIONS: REACT_APP_API_CONVERSATIONS_BASE_URL,
    PRODUCTS: REACT_APP_API_PRODUCTS_BASE_URL,
    USERS: REACT_APP_API_USERS_BASE_URL,
    EVENTS: REACT_APP_API_EVENTS_BASE_URL,
    FEATURES: REACT_APP_API_FEATURES_BASE_URL,
    INTEGRATIONS: REACT_APP_API_INTEGRATIONS_BASE_URL,
    ACCESS_MANAGEMENT: REACT_APP_API_ACCESSMANAGEMENT_BASE_URL,
    INSIGHT: REACT_APP_API_INSIGHT_BASE_URL,
    ANALYTICS: REACT_APP_API_ANALYTICS_BASE_URL,
    LOGOS: REACT_APP_LOGOS_BASE_URL,
    OPTIMIZATION_GUIDE: REACT_APP_AWS_OPTIMIZATION_GUIDE_URI,
    INSIGHT_GRAPHQL: REACT_APP_INSIGHT_GRAPHQL_BASE_URL,
    BFFE_USAGE_GRAPHQL: REACT_APP_USAGE_GRAPHQL_BASE_URL,
    COREAPP_GRAPHQL: REACT_APP_COREAPP_GRAPHQL_BASE_URL,
    CLOUDOPTIMIZATION_GRAPHQL: REACT_APP_CLOUDOPTIMIZATION_GRAPHQL_BASE_URL,
    STORAGE: REACT_APP_API_STORAGE_BASE_URL,
    LEGAL: REACT_APP_API_LEGAL_BASE_URL,
    SCIM: REACT_APP_API_SCIM_BASE_URL,
    BFFE_WORKFLOWS: REACT_APP_API_BFFE_WORKFLOWS_BASE_URL,
    SERVICES: REACT_APP_API_SERVICES_BASE_URL,
    AUTH_SERVICE: REACT_APP_API_AUTH_SERVICE_BASE_URL,
    DOCPROC: REACT_APP_API_DOCPROC_BASE_URL,
    WSS: REACT_APP_WSS_BASE_URL,
  },
  production: {
    MAIN: 'https://api.prod-v1.vertice-cloud.com',
    ADMIN_ACCOUNTS: 'https://api.prod-v1.vertice-cloud.com/admin-accounts',
    ACCOUNTS: 'https://api.prod-v1.vertice-cloud.com/accounts',
    CONTRACT_V2: 'https://api.saas-prod-v1.vertice-cloud.com/contracts-v2',
    CONTRACT_COST: 'https://api.saas-prod-v1.vertice-cloud.com/contract-cost',
    TAGS: 'https://api.prod-v1.vertice-cloud.com/tagging',
    WORKFLOWS_V2: 'https://api.prod-v1.vertice-cloud.com/workflows',
    CONTRACT_WORKFLOWS_V2: 'https://api.saas-prod-v1.vertice-cloud.com/contract-workflows',
    BFFE_SAAS: 'https://api.saas-prod-v1.vertice-cloud.com/contracts-bffe',
    CONTRACTS: 'https://api.prod-v1.vertice-cloud.com/contracts',
    CONVERSATIONS: 'https://api.prod-v1.vertice-cloud.com/conversations',
    PRODUCTS: 'https://api.prod-v1.vertice-cloud.com/products',
    USERS: 'https://api.prod-v1.vertice-cloud.com/users',
    EVENTS: 'https://api.prod-v1.vertice-cloud.com/events',
    FEATURES: 'https://api.prod-v1.vertice-cloud.com/features',
    INTEGRATIONS: 'https://api.prod-v1.vertice-cloud.com/integrations',
    ACCESS_MANAGEMENT: 'https://api.prod-v1.vertice-cloud.com/access-management',
    INSIGHT: 'https://api.prod-v1.vertice-cloud.com/insight',
    ANALYTICS: 'https://api.prod-v1.vertice-cloud.com/bi',
    LOGOS: 'https://logos.prod-v1.vertice.one',
    OPTIMIZATION_GUIDE: REACT_APP_AWS_OPTIMIZATION_GUIDE_URI,
    INSIGHT_GRAPHQL: 'https://insight-appsync.prod-v1.vertice-cloud.com/graphql',
    BFFE_USAGE_GRAPHQL: 'https://usage-appsync-bffe.prod-v1.vertice-cloud.com/graphql',
    COREAPP_GRAPHQL: 'https://core-app-api.prod-v1.vertice-cloud.com/graphql',
    CLOUDOPTIMIZATION_GRAPHQL: 'https://cloud-optimisation-api.cco-prod-v1.vertice-cloud.com/graphql',
    STORAGE: 'https://api.prod-v1.vertice-cloud.com/storage',
    LEGAL: 'https://api.prod-v1.vertice-cloud.com/legal',
    SCIM: 'https://api.prod-v1.vertice-cloud.com/scim',
    BFFE_WORKFLOWS: 'https://api.prod-v1.vertice-cloud.com/workflows-bffe',
    SERVICES: 'https://api.prod-v1.vertice-cloud.com/services',
    AUTH_SERVICE: 'https://api.prod-v1.vertice-cloud.com/public-auth-service',
    DOCPROC: 'https://api.saas-prod-v1.vertice-cloud.com/doc-proc',
    WSS: 'wss://ws.saas-prod-v1.vertice-cloud.com/doc-proc',
  },
  stg: {
    MAIN: 'https://api.stg-v1.vertice-cloud.co.uk',
    ADMIN_ACCOUNTS: 'https://api.stg-v1.vertice-cloud.co.uk/admin-accounts',
    ACCOUNTS: 'https://api.stg-v1.vertice-cloud.co.uk/accounts',
    CONTRACT_V2: 'https://api.saas-stg-v1.vertice-cloud.co.uk/contracts-v2',
    CONTRACT_COST: 'https://api.saas-stg-v1.vertice-cloud.co.uk/contract-cost',
    TAGS: 'https://api.stg-v1.vertice-cloud.co.uk/tagging',
    WORKFLOWS_V2: 'https://api.stg-v1.vertice-cloud.co.uk/workflows',
    CONTRACT_WORKFLOWS_V2: 'https://api.saas-stg-v1.vertice-cloud.co.uk/contract-workflows',
    BFFE_SAAS: 'https://api.saas-stg-v1.vertice-cloud.co.uk/contracts-bffe',
    CONTRACTS: 'https://api.stg-v1.vertice-cloud.co.uk/contracts',
    CONVERSATIONS: 'https://api.stg-v1.vertice-cloud.co.uk/conversations',
    PRODUCTS: 'https://api.stg-v1.vertice-cloud.co.uk/products',
    USERS: 'https://api.stg-v1.vertice-cloud.co.uk/users',
    EVENTS: 'https://api.stg-v1.vertice-cloud.co.uk/events',
    FEATURES: 'https://api.stg-v1.vertice-cloud.co.uk/features',
    INTEGRATIONS: 'https://api.stg-v1.vertice-cloud.co.uk/integrations',
    INSIGHT: 'https://api.stg-v1.vertice-cloud.co.uk/insight',
    ACCESS_MANAGEMENT: 'https://api.stg-v1.vertice-cloud.co.uk/access-management',
    ANALYTICS: 'https://api.stg-v1.vertice-cloud.co.uk/bi',
    LOGOS: 'https://logos.stg-v1.vertice.one',
    OPTIMIZATION_GUIDE: REACT_APP_AWS_OPTIMIZATION_GUIDE_URI,
    INSIGHT_GRAPHQL: 'https://insight-appsync.stg-v1.vertice-cloud.co.uk/graphql',
    BFFE_USAGE_GRAPHQL: 'https://usage-appsync-bffe.stg-v1.vertice-cloud.co.uk/graphql',
    COREAPP_GRAPHQL: 'https://core-app-api.stg-v1.vertice-cloud.co.uk/graphql',
    CLOUDOPTIMIZATION_GRAPHQL: 'https://cloud-optimisation-api.cco-stg-v1.vertice-cloud.co.uk/graphql',
    STORAGE: 'https://api.stg-v1.vertice-cloud.co.uk/storage',
    LEGAL: 'https://api.stg-v1.vertice-cloud.co.uk/legal',
    SCIM: 'https://api.stg-v1.vertice-cloud.co.uk/scim',
    BFFE_WORKFLOWS: 'https://api.stg-v1.vertice-cloud.co.uk/workflows-bffe',
    SERVICES: 'https://api.stg-v1.vertice-cloud.co.uk/services',
    AUTH_SERVICE: 'https://api.stg-v1.vertice-cloud.co.uk/public-auth-service',
    DOCPROC: 'https://api.saas-stg-v1.vertice-cloud.co.uk/doc-proc',
    WSS: 'wss://ws.saas-stg-v1.vertice-cloud.co.uk/doc-proc',
  },
  qa: {
    MAIN: 'https://api.qa-v1.vertice-cloud.qa',
    ADMIN_ACCOUNTS: 'https://api.qa-v1.vertice-cloud.qa/admin-accounts',
    ACCOUNTS: 'https://api.qa-v1.vertice-cloud.qa/accounts',
    CONTRACT_V2: 'https://api.saas-qa-v1.vertice-cloud.qa/contracts-v2',
    CONTRACT_COST: 'https://api.saas-qa-v1.vertice-cloud.qa/contract-cost',
    TAGS: 'https://api.qa-v1.vertice-cloud.qa/tagging',
    WORKFLOWS_V2: 'https://api.qa-v1.vertice-cloud.qa/workflows',
    CONTRACT_WORKFLOWS_V2: 'https://api.saas-qa-v1.vertice-cloud.qa/contract-workflows',
    BFFE_SAAS: 'https://api.saas-qa-v1.vertice-cloud.qa/contracts-bffe',
    CONTRACTS: 'https://api.qa-v1.vertice-cloud.qa/contracts',
    CONVERSATIONS: 'https://api.qa-v1.vertice-cloud.qa/conversations',
    PRODUCTS: 'https://api.qa-v1.vertice-cloud.qa/products',
    USERS: 'https://api.qa-v1.vertice-cloud.qa/users',
    EVENTS: 'https://api.qa-v1.vertice-cloud.qa/events',
    FEATURES: 'https://api.qa-v1.vertice-cloud.qa/features',
    INTEGRATIONS: 'https://api.qa-v1.vertice-cloud.qa/integrations',
    INSIGHT: 'https://api.qa-v1.vertice-cloud.qa/insight',
    ACCESS_MANAGEMENT: 'https://api.qa-v1.vertice-cloud.qa/access-management',
    ANALYTICS: 'https://api.qa-v1.vertice-cloud.qa/bi',
    LOGOS: 'https://logos.qa-v1.vertice.one',
    OPTIMIZATION_GUIDE: REACT_APP_AWS_OPTIMIZATION_GUIDE_URI,
    INSIGHT_GRAPHQL: 'https://insight-appsync.qa-v1.vertice-cloud.qa/graphql',
    BFFE_USAGE_GRAPHQL: 'https://usage-appsync-bffe.qa-v1.vertice-cloud.qa/graphql',
    COREAPP_GRAPHQL: 'https://core-app-api.qa-v1.vertice-cloud.qa/graphql',
    CLOUDOPTIMIZATION_GRAPHQL: 'https://cloud-optimisation-api.cco-qa-v1.vertice-cloud.qa/graphql',
    STORAGE: 'https://api.qa-v1.vertice-cloud.qa/storage',
    LEGAL: 'https://api.qa-v1.vertice-cloud.qa/legal',
    SCIM: 'https://api.qa-v1.vertice-cloud.qa/scim',
    BFFE_WORKFLOWS: 'https://api.qa-v1.vertice-cloud.qa/workflows-bffe',
    SERVICES: 'https://api.qa-v1.vertice-cloud.qa/services',
    AUTH_SERVICE: 'https://api.qa-v1.vertice-cloud.qa/public-auth-service',
    DOCPROC: 'https://api.saas-qa-v1.vertice-cloud.qa/doc-proc',
    WSS: 'wss://ws.saas-qa-v1.vertice-cloud.qa/doc-proc',
  },
  development: {
    MAIN: 'https://api.dev-v1.vertice-cloud.dev',
    ADMIN_ACCOUNTS: 'https://api.dev-v1.vertice-cloud.dev/admin-accounts',
    ACCOUNTS: 'https://api.dev-v1.vertice-cloud.dev/accounts',
    CONTRACT_V2: 'https://api.saas-dev-v1.vertice-cloud.dev/contracts-v2',
    CONTRACT_COST: 'https://api.saas-dev-v1.vertice-cloud.dev/contract-cost',
    TAGS: 'https://api.dev-v1.vertice-cloud.dev/tagging',
    WORKFLOWS_V2: 'https://api.dev-v1.vertice-cloud.dev/workflows',
    CONTRACT_WORKFLOWS_V2: 'https://api.saas-dev-v1.vertice-cloud.dev/contract-workflows',
    BFFE_SAAS: 'https://api.saas-dev-v1.vertice-cloud.dev/contracts-bffe',
    CONTRACTS: 'https://api.dev-v1.vertice-cloud.dev/contracts',
    CONVERSATIONS: 'https://api.dev-v1.vertice-cloud.dev/conversations',
    PRODUCTS: 'https://api.dev-v1.vertice-cloud.dev/products',
    USERS: 'https://api.dev-v1.vertice-cloud.dev/users',
    EVENTS: 'https://api.dev-v1.vertice-cloud.dev/events',
    FEATURES: 'https://api.dev-v1.vertice-cloud.dev/features',
    INTEGRATIONS: 'https://api.dev-v1.vertice-cloud.dev/integrations',
    INSIGHT: 'https://api.dev-v1.vertice-cloud.dev/insight',
    ACCESS_MANAGEMENT: 'https://api.dev-v1.vertice-cloud.dev/access-management',
    ANALYTICS: 'https://api.dev-v1.vertice-cloud.dev/bi',
    LOGOS: 'https://logos.dev-v1.vertice.one',
    OPTIMIZATION_GUIDE: REACT_APP_AWS_OPTIMIZATION_GUIDE_URI,
    INSIGHT_GRAPHQL: 'https://insight-appsync.dev-v1.vertice-cloud.dev/graphql',
    BFFE_USAGE_GRAPHQL: 'https://usage-appsync-bffe.dev-v1.vertice-cloud.dev/graphql',
    COREAPP_GRAPHQL: 'https://core-app-api.dev-v1.vertice-cloud.dev/graphql',
    CLOUDOPTIMIZATION_GRAPHQL: 'https://cloud-optimisation-api.cco-dev-v1.vertice-cloud.dev/graphql',
    STORAGE: 'https://api.dev-v1.vertice-cloud.dev/storage',
    LEGAL: 'https://api.dev-v1.vertice-cloud.dev/legal',
    SCIM: 'https://api.dev-v1.vertice-cloud.dev/scim',
    BFFE_WORKFLOWS: 'https://api.dev-v1.vertice-cloud.dev/workflows-bffe',
    SERVICES: 'https://api.dev-v1.vertice-cloud.dev/services',
    AUTH_SERVICE: 'https://api.dev-v1.vertice-cloud.dev/public-auth-service',
    DOCPROC: 'https://api.saas-dev-v1.vertice-cloud.dev/doc-proc',
    WSS: 'wss://ws.saas-dev-v1.vertice-cloud.dev/doc-proc',
  },
  rnd: {
    MAIN: 'https://api.rnd.vertice-cloud.cz',
    ADMIN_ACCOUNTS: 'https://api.rnd.vertice-cloud.cz/admin-accounts',
    ACCOUNTS: 'https://api.rnd.vertice-cloud.cz/accounts',
    CONTRACT_V2: 'https://api.saas-rnd-v1.vertice-cloud.cz/contracts-v2',
    CONTRACT_COST: 'https://api.saas-rnd-v1.vertice-cloud.cz/contract-cost',
    TAGS: 'https://api.rnd.vertice-cloud.cz/tagging',
    WORKFLOWS_V2: 'https://api.rnd.vertice-cloud.cz/workflows',
    CONTRACT_WORKFLOWS_V2: 'https://api.saas-rnd-v1.vertice-cloud.cz/contract-workflows',
    BFFE_SAAS: 'https://api.saas-rnd-v1.vertice-cloud.cz/contracts-bffe',
    CONTRACTS: 'https://api.rnd.vertice-cloud.cz/contracts',
    CONVERSATIONS: 'https://api.rnd.vertice-cloud.cz/conversations',
    PRODUCTS: 'https://api.rnd.vertice-cloud.cz/products',
    USERS: 'https://api.rnd.vertice-cloud.cz/users',
    EVENTS: 'https://api.rnd.vertice-cloud.cz/events',
    FEATURES: 'https://api.rnd.vertice-cloud.cz/features',
    INTEGRATIONS: 'https://api.rnd.vertice-cloud.cz/integrations',
    INSIGHT: 'https://api.rnd.vertice-cloud.cz/insight',
    ACCESS_MANAGEMENT: 'https://api.rnd.vertice-cloud.cz/access-management',
    ANALYTICS: 'https://api.rnd.vertice-cloud.cz/bi',
    LOGOS: 'https://logos.rnd.vertice.one',
    OPTIMIZATION_GUIDE: REACT_APP_AWS_OPTIMIZATION_GUIDE_URI,
    INSIGHT_GRAPHQL: 'https://insight-appsync.rnd.vertice-cloud.cz/graphql',
    BFFE_USAGE_GRAPHQL: 'https://usage-appsync-bffe.rnd.vertice-cloud.cz/graphql',
    COREAPP_GRAPHQL: 'https://core-app-api.rnd.vertice-cloud.cz/graphql',
    CLOUDOPTIMIZATION_GRAPHQL: 'https://cloud-optimisation-api.cco-rnd-v1.vertice-cloud.cz/graphql',
    STORAGE: 'https://api.rnd.vertice-cloud.cz/storage',
    LEGAL: 'https://api.rnd.vertice-cloud.cz/legal',
    SCIM: 'https://api.rnd.vertice-cloud.cz/scim',
    BFFE_WORKFLOWS: 'https://api.rnd.vertice-cloud.cz/workflows-bffe',
    SERVICES: 'https://api.rnd.vertice-cloud.cz/services',
    AUTH_SERVICE: 'https://api.rnd.vertice-cloud.cz/public-auth-service',
    DOCPROC: 'https://api.saas-rnd-v1.vertice-cloud.cz/doc-proc',
    WSS: 'wss://ws.saas-rnd-v1.vertice-cloud.cz/doc-proc',
  },
};

export const API_URLS = {
  BASE_URLS: baseUrlsMap[PROFILE],
  BFFE_USAGE_GRAPHQL_URL: baseUrlsMap[PROFILE].BFFE_USAGE_GRAPHQL,
  COREAPP_GRAPHQL_URL: baseUrlsMap[PROFILE].COREAPP_GRAPHQL,
  INSIGHT_GRAPHQL_URL: baseUrlsMap[PROFILE].INSIGHT_GRAPHQL,
  CLOUDOPTIMIZATION_GRAPHQL_URL: baseUrlsMap[PROFILE].CLOUDOPTIMIZATION_GRAPHQL,
  LOGOS_URL: baseUrlsMap[PROFILE].LOGOS,
  OPTIMIZATION_GUIDE: baseUrlsMap[PROFILE].OPTIMIZATION_GUIDE,
  VENDORS: 'vendors',
  PRODUCTS: 'products',
  SEARCH: 'search',
  USERS: 'users',
  ADMIN_ACCOUNTS: '/admin-accounts',
  ACCOUNTS: '/accounts',
  CONTRACTS: '/accounts/{ACCOUNT_ID}/contracts',
  CONTRACT: '/{ACCOUNT_ID}/departments/{DEPARTMENT_ID}/contracts/{CONTRACT_ID}',
  CONTRACT_RENEW: '/accounts/{ACCOUNT_ID}/contracts/{CONTRACT_ID}/versions/{VERSION}/renew',
  CONTRACT_REVISION: '/accounts/{ACCOUNT_ID}/contracts/{CONTRACT_ID}/versions/{VERSION}/revisions/{REVISION}',
  CONTRACT_FILES:
    '/{ACCOUNT_ID}/departments/{DEPARTMENT_ID}/contracts/{CONTRACT_ID}/versions/{VERSION}/files/{FILE_NAME}',
  BFFE_SAAS: baseUrlsMap[PROFILE].BFFE_SAAS,
  BFFE_WORKFLOWS: baseUrlsMap[PROFILE].BFFE_WORKFLOWS,
  SERVICES: baseUrlsMap[PROFILE].SERVICES,
  CONVERSATIONS: baseUrlsMap[PROFILE].CONVERSATIONS,
  SCIM: baseUrlsMap[PROFILE].SCIM,
  DOCPROC: baseUrlsMap[PROFILE].DOCPROC,
  WSS: baseUrlsMap[PROFILE].WSS,
};

export function applicationTypeString(isIAT: boolean): ApplicationType {
  return isIAT ? 'IAT' : 'CFA';
}
