import { forwardRef, useLayoutEffect, useRef, useState } from 'react';
import { Stack, useTheme } from '@mui/material';
import { VerticeVIcon } from '@vertice/assets';
import styled from '@mui/material/styles/styled';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '@vertice/hooks/src/useWindowDimensions';

import Avatar from '../Avatar';
import CommentHeader from './CommentHeader';
import Text, { EllipsisText } from '../Text';
import { CommentMetadata } from './types';
import i18n from '../../translate';
import Grid from '../Grid';

export type CommentVariant = 'default' | 'branded';

export type CommentProps = CommentMetadata & {
  variant: CommentVariant;
  truncate?: boolean;
  truncateLines?: number;
};

const BadgeContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.global.color.purple['10'].main,
  borderRadius: '50%',
  position: 'absolute',
  right: '-20%',
  bottom: '-22%',
  width: theme.spacing(3.75),
  height: theme.spacing(3.75),
  svg: {
    margin: theme.spacing(0),
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

const Clickable = styled('div')(() => ({
  cursor: 'pointer',
  ':hover': {
    textDecoration: 'underline',
  },
}));

const Comment = forwardRef<HTMLDivElement, CommentProps>(
  ({ variant, content, truncate = false, truncateLines = 5, isCommentAuthor, ...metadataProps }, ref) => {
    const [isTruncated, setIsTruncated] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const contentRef = useRef<HTMLSpanElement>(null);

    const { width } = useWindowDimensions();
    const { palette } = useTheme();
    const { t } = useTranslation(undefined, { i18n });

    useLayoutEffect(() => {
      if (!contentRef.current) return;

      setIsTruncated(contentRef.current.scrollHeight > contentRef.current.clientHeight);
    }, [contentRef, width]);

    const isBranded = variant === 'branded';
    const userAvatarAccent = isCommentAuthor ? 'secondary' : 'neutral';

    const handleShowMore = () => setIsExpanded(true);

    return (
      <Stack
        padding={4}
        width={'100%'}
        bgcolor={isBranded ? palette.primary.color4 : palette.core.bg}
        gap={2}
        ref={ref}
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <Avatar
            personId={metadataProps.personId}
            personName={metadataProps.personName}
            size="XS"
            badge={
              isBranded && (
                <BadgeContainer container alignItems="center" justifyItems="center">
                  <VerticeVIcon />
                </BadgeContainer>
              )
            }
            color={isBranded ? 'primary' : userAvatarAccent}
            invertColors={isCommentAuthor}
          />
          <CommentHeader isBranded={isBranded} isCommentAuthor={isCommentAuthor} {...metadataProps} />
        </Stack>
        <Stack paddingLeft={8} alignItems="top" gap={2}>
          {!truncate || isExpanded ? (
            <Text variant="body-regular" size="S" whiteSpace="break-spaces">
              {content}
            </Text>
          ) : (
            <EllipsisText
              variant="body-regular"
              size="S"
              lineClamp={truncateLines}
              ref={contentRef}
              whiteSpace="break-spaces"
            >
              {content}
            </EllipsisText>
          )}

          {truncate && isTruncated && !isExpanded && (
            <Clickable>
              <Text size="S" variant="body-regular" onClick={handleShowMore} whiteSpace="break-spaces">
                {t('COMMENTS.EXPAND_COMMENT')}
              </Text>
            </Clickable>
          )}
        </Stack>
      </Stack>
    );
  }
);

export default Comment;
