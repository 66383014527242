import React, { useEffect, useState } from 'react';
import Button from '../../../IconButton';
import type { TextFormatType } from 'lexical/nodes/LexicalTextNode';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection, $isRangeSelection, FORMAT_TEXT_COMMAND } from 'lexical';
import { FormatBold, FormatItalic, FormatUnderlined, SvgIconComponent } from '@mui/icons-material';
import { useToolbar } from '../ToolbarContext';

type Types = Extract<TextFormatType, 'bold' | 'italic' | 'underline'>;

type FormatTextProps = {
  types: Array<Types>;
};

type FormatTextButtonProps = {
  icon: SvgIconComponent;
  type: Types;
};

const typeIcons: Record<Types, SvgIconComponent> = {
  bold: FormatBold,
  italic: FormatItalic,
  underline: FormatUnderlined,
};

const FormatTextButton = ({ icon, type }: FormatTextButtonProps) => {
  const { size, disabled } = useToolbar();
  const [editor] = useLexicalComposerContext();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          setIsActive(selection.hasFormat(type));
        }
      });
    });
  }, [editor, setIsActive, type]);

  const handleChange = () => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, type);
  };

  return (
    <Button
      size={size}
      disabled={disabled}
      variant="plain"
      onClick={handleChange}
      isActive={isActive}
      icon={icon}
    />
  ); //prettier-ignore
};

const Text = ({ types }: FormatTextProps) => (
  <>
    {types.map((type) => (
      <FormatTextButton key={type} type={type} icon={typeIcons[type]} />
    ))}
  </>
);

export default Text;
