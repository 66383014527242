import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '@vertice/utils/src/constants/cfa/constants';
import { useMainLayout } from '@vertice/dashboard/src/layouts/Main/LayoutWrapper';
import { useClickOutside } from '@vertice/dashboard/src/layouts/Main/Sidebar/useClickOutside';
import AccountManagerCard from '@vertice/dashboard/src/components/AccountManagerCard/AccountManagerCard';

import NavigationGroup from './components/NavigationGroup';
import NavigationItem from './components/NavigationItem';

import LogoutIcon from '@mui/icons-material/LogoutTwoTone';
import SupportIcon from '@mui/icons-material/HelpTwoTone';
import SettingsIcon from '@mui/icons-material/SettingsTwoTone';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CloudNavbarItems from '@vertice/dashboard/src/modules/cloud/components/CloudNavbarItems';
import SaasNavbarItems from '@vertice/dashboard/src/modules/saas/components/SaasNavbarItems';
import WorkflowsNavbarItems from '@vertice/dashboard/src/modules/intelligentWorkflows/components/WorkflowsNavbarItems';
import { amplifyConfig } from '@vertice/dashboard/src/config';
import useAmplifyConfigs from '../../../hooks/useAmplifyConfigs';
import { useAuthentication } from '@verticeone/auth/src';

import { useCustomerSuccessWidget } from '@vertice/core/src/contexts/CustomerSuccessContext';
import { useRoutes } from '@verticeone/router/src';

const { KNOWLEDGE_BASE } = ROUTES;

const Sidebar = () => {
  useClickOutside();
  const { t } = useTranslation();
  const routes = useRoutes();
  const { isOpen } = useMainLayout();
  const { signOut } = useAuthentication();
  const { clear: removeAmplifyConfigsFromStorage } = useAmplifyConfigs(amplifyConfig);

  const { zendeskEnabled, showWidget } = useCustomerSuccessWidget();

  const logOut = () => {
    removeAmplifyConfigsFromStorage();
    void signOut();
  };

  return (
    <>
      <Stack gap={4}>
        <SaasNavbarItems />
        <WorkflowsNavbarItems />
        <CloudNavbarItems />
      </Stack>
      <Stack gap={4}>
        {isOpen && <AccountManagerCard />}
        <NavigationGroup>
          {zendeskEnabled && <NavigationItem onClick={showWidget} icon={SupportIcon} label={t('SIDEBAR.HELP')} />}
          <NavigationItem to={KNOWLEDGE_BASE} icon={MenuBookIcon} label={t('SIDEBAR.KNOWLEDGE_BASE')} />
          {/* prettier-ignore */}
          <NavigationItem to={routes.PREFERENCES.ABSOLUTE_PATH} match={[routes.PREFERENCES.TAB.ABSOLUTE_PATH]} icon={SettingsIcon} label={t('SIDEBAR.PREFERENCES')} />
          <NavigationItem onClick={logOut} icon={LogoutIcon} label={t('AUTH.LOG_OUT')} />
        </NavigationGroup>
      </Stack>
    </>
  );
};

export default Sidebar;
