import React from 'react';
import MuiTableContainer, {
  TableContainerProps as MuiTableContainerProps,
} from '@mui/material/TableContainer/TableContainer';
import styled from '@mui/material/styles/styled';
import type { Theme } from '@mui/material/styles';
import type { JSXElementConstructor } from 'react';

export type TableContainerProps = MuiTableContainerProps & {
  noBorder?: boolean;
  noBorderRadius?: boolean;
};

export type StyledTableContainerProps = MuiTableContainerProps & {
  $noBorder?: boolean;
  $noBorderRadius?: boolean;
  theme: Theme;
};

const StyledTableContainer = styled<JSXElementConstructor<TableContainerProps>>(
  MuiTableContainer
)<StyledTableContainerProps>(({ theme, $noBorder, $noBorderRadius }) => ({
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: theme.palette.core.color3,
  ...($noBorder ? { border: 'none' } : {}),
  borderRadius: $noBorderRadius ? 0 : theme.spacing(4),
  borderCollapse: 'separate',
  overflow: 'auto',
}));

const TableContainer = ({ noBorder, noBorderRadius, ...otherProps }: TableContainerProps) => (
  <StyledTableContainer $noBorder={noBorder} $noBorderRadius={noBorderRadius} {...otherProps} />
);

export default TableContainer;
