import { useAccordionContext } from './AccordionContext';
import { MultiChipContainer, MultiChipContainerProps } from '../ChipButton';

type Props = Omit<MultiChipContainerProps, 'size'>;

const AccordionMultiChipContainer = (props: Props) => {
  const { size } = useAccordionContext();

  return <MultiChipContainer size={size} {...props} isActive={false} />;
};

export default AccordionMultiChipContainer;
