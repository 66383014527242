import { useTranslation } from 'react-i18next';
import { Stack, useTheme } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import InfoIconOutlined from '@mui/icons-material/InfoOutlined';

import { orderBy } from 'lodash';
import Text from '@verticeone/design-system/src/components/Text';
import Tooltip from '@verticeone/design-system/src/components/Tooltip';
import IconWrapper from '@verticeone/design-system/src/components/IconWrapper';
import { getTimeFromNow } from '@vertice/slices/src/slices/UsageAnalytics/helpers';
import {
  AlarmDataLastFetched,
  AlarmDay,
  AlarmStatus,
} from '@vertice/slices/src/graphql/bffeUsage/generated/usageGraphQL';
import LastFetchedTooltip from './LastFetchedTooltip';

type LastFetchedInfoProps = {
  accessLogs: AlarmDataLastFetched[];
  measureReportsGeneratedAt?: string;
  alarmsPerDay?: AlarmDay[];
  providers?: string[];
};

export const getLastUpdatedTimePeriod = (fetchedAt: string, reportGeneratedAt?: string) =>
  reportGeneratedAt && new Date(reportGeneratedAt) < new Date(fetchedAt) ? reportGeneratedAt : fetchedAt;

export const LastFetchedInfo = ({
  accessLogs,
  measureReportsGeneratedAt,
  alarmsPerDay,
  providers,
}: LastFetchedInfoProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const hasFailedAlarms = alarmsPerDay?.some((dailyAlarm) =>
    dailyAlarm.alarms?.some((alarm) => alarm.status === AlarmStatus.Failed)
  );

  // Google Social Login should not have a 'Provider:' prefix thus using temporary workaround
  if (providers?.includes('Provider:Google:Workspace:SocialLogin')) {
    const index = providers.indexOf('Provider:Google:Workspace:SocialLogin');
    if (index > -1) providers.splice(index, 1);
    providers.push('Google:Workspace:SocialLogin');
  }
  const filteredAccessLogs =
    providers && providers.length > 0
      ? accessLogs.filter((logItem) => providers.includes(logItem.providerName))
      : accessLogs;

  if (filteredAccessLogs.length === 0) return null;

  const lastAccessLog = orderBy(filteredAccessLogs, 'fetchedAt', 'desc')[0];
  const lastUpdatedTimePeriod = getTimeFromNow(
    getLastUpdatedTimePeriod(lastAccessLog.fetchedAt, measureReportsGeneratedAt)
  );

  return (
    <Tooltip
      content={
        filteredAccessLogs.length > 0 && (
          <LastFetchedTooltip
            accessLogs={filteredAccessLogs}
            measureReportsGeneratedAt={measureReportsGeneratedAt}
            alarmsPerDay={alarmsPerDay}
            providers={providers}
          />
        )
      }
      size="M"
      maxWidth="320px"
    >
      <Stack direction="row" alignItems="center" gap={1}>
        <IconWrapper
          icon={hasFailedAlarms ? InfoIcon : InfoIconOutlined}
          size="S"
          htmlColor={hasFailedAlarms ? palette.error.color1 : palette.text.color3}
        />
        <Text
          variant="link"
          size="S"
          color={hasFailedAlarms ? palette.error.color1 : palette.text.color3}
          sx={{ cursor: 'default' }}
        >
          {t('APPLICATIONS.USAGE.LAST_UPDATED.TITLE', { timePeriod: lastUpdatedTimePeriod })}
        </Text>
      </Stack>
    </Tooltip>
  );
};
