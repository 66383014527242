import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@verticeone/design-system/src/components/Button';
import { useContractGridContext } from '../ContractContext';
import { useContractData } from '../../ContractsDataContext';

type DiscardChangesProps = {
  contractId: string;
  state: 'new' | 'existing';
};

const DiscardChanges = ({ contractId, state }: DiscardChangesProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EDP_CONTRACT_GRID.BUTTONS' });
  const { actions, isEditing } = useContractGridContext();
  const { removeContract } = useContractData();

  const discard = () => {
    if (state === 'new') {
      removeContract(contractId);
    } else {
      actions.discard();
    }
  };

  if (!isEditing) {
    return null;
  }

  return (
    <Button size="S" variant="outline" color="tertiary" onClick={discard}>
      {t('DISCARD')}
    </Button>
  );
};

export default DiscardChanges;
