import { MutableRefObject, useEffect, useState } from 'react';

const useResizeObserver = (containerRef?: MutableRefObject<Element>) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (!containerRef) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      setDimensions((oldDimensions) =>
        // Cause re-render only if dimensions changed
        oldDimensions.width !== width || oldDimensions.height !== height ? { width, height } : oldDimensions
      );
    });
    resizeObserver.observe(containerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef]);

  return dimensions;
};

export default useResizeObserver;
