import React from 'react';
import { useTranslation } from 'react-i18next';
import { HourglassBottom } from '@mui/icons-material';
import RITransactions from './RITransactions';
import { GridRowId } from '@mui/x-data-grid-pro';
import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices';
import { Dialog, DialogActions, DialogHeader } from '@verticeone/design-system/src/components/Dialog';
import Button from '@verticeone/design-system/src/components/Button';
import { useSnackbar } from 'notistack';
import useLoadMoreRiPurchaseData from './useLoadMoreRiPurchaseData';
import { useCancelRiReservationMutation } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';

const RIPendingTransactions = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RI_PURCHASE' });
  const { data, refetch, onLoadMore, ...loadingStatus } = useLoadMoreRiPurchaseData(true);

  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [transactionsToCancel, setTransactionsToCancel] = React.useState<Map<GridRowId, any>>(new Map());
  const [cancelReservations] = useCancelRiReservationMutation();
  const { accountId } = useSelector(getAccount);
  const [isCancelling, setIsCanceling] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleNotConfirmed = () => setDialogOpen(false);
  const handleConfirmed = () => {
    setIsCanceling(true);
    const count = transactionsToCancel.size;

    cancelReservations({
      accountId: accountId!,
      deletions: Array.from(transactionsToCancel.values()).map((transaction) => ({
        service: transaction.service,
        region: transaction.region,
        purchaseOrderId: transaction.id,
      })),
    })
      .then(() => {
        // need to wait for the mutation to complete before refetching the data. RIO API is async
        setTimeout(() => {
          enqueueSnackbar(t('SNACKBAR.SUCCESS', { count }), { variant: 'success' });

          if (refetch) {
            void refetch();
          }
          setDialogOpen(false);
          setIsCanceling(false);
          setTransactionsToCancel(new Map());
        }, 1000 * count); // 1000ms per transaction
      })
      .catch(() => {
        enqueueSnackbar(t('SNACKBAR.ERROR', { count: count }), { variant: 'error' });
        setDialogOpen(false);
        setIsCanceling(false);
        setTransactionsToCancel(new Map());
      });
  };

  const handleCancelTransactions = (rows: Map<GridRowId, any>) => {
    setDialogOpen(true);
    setTransactionsToCancel(rows);
  };

  return (
    <>
      <RITransactions
        isPending={true}
        title={t('PENDING_TITLE')}
        icon={HourglassBottom}
        testId="ri-pending-transactions"
        visibleRows={10}
        onCancelTransactions={handleCancelTransactions}
        onLoadMore={onLoadMore}
        data={data}
        {...loadingStatus}
      />
      <Dialog open={isDialogOpen} align="left" size="M">
        <DialogHeader>{t('CONFIRMATION_MODAL.TITLE', { count: transactionsToCancel.size })}</DialogHeader>
        <DialogActions>
          <Button onClick={handleNotConfirmed} variant="outline" color="tertiary" disabled={isCancelling}>
            {t('CONFIRMATION_MODAL.BUTTONS.DISCARD')}
          </Button>
          <Button onClick={handleConfirmed} variant="solid" color="tertiary" isLoading={isCancelling}>
            {t('CONFIRMATION_MODAL.BUTTONS.CONFIRM')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RIPendingTransactions;
