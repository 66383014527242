import React from 'react';
import ContractFormEntry, { ContractFormEntryPropsWithFixedComponents } from '../../ContractFormEntry';
import FormReadOnlyText, { FormReadOnlyTextType } from '@vertice/core/src/modules/forms/fields/FormReadOnlyText';
import FormSelectField2, {
  FormSelectFieldComponentType,
} from '@vertice/core/src/modules/forms/fields/FormSelectField2';
import { ContractFormData } from '../../types';
import currencies from '../../../../../utils/currency/currencies';

type ReadComponent = FormReadOnlyTextType<ContractFormData>;
type WriteComponent = FormSelectFieldComponentType<ContractFormData, BaseCurrencySelectOption, false>;

type BaseCurrencyFormEntryProps = ContractFormEntryPropsWithFixedComponents<ReadComponent, WriteComponent>;

type BaseCurrencySelectOption = { value: string; label: string };

const BaseCurrencyFormEntry = (options: BaseCurrencyFormEntryProps) => {
  const currencyOptions = currencies.map((currency) => ({ value: currency, label: currency }));

  return (
    <ContractFormEntry<ReadComponent, WriteComponent>
      {...options}
      component={FormReadOnlyText}
      writeComponent={FormSelectField2}
      writeComponentProps={{
        ...options.writeComponentProps,
        options: currencyOptions,
        boxValue: (value) => (value ? { label: value as string, value: value as string } : undefined),
        unboxValue: (selectValue) => selectValue?.value,
      }}
    />
  );
};

export default BaseCurrencyFormEntry;
