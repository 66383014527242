import Select, { SelectProps } from '../../Select';
import styled from '@mui/material/styles/styled';
import { useDataGridContext } from '../DataGridContext';
import { GroupBase } from '../../SelectCommon/types';

const StyledSelect = styled(Select)({
  width: '100%',
}) as typeof Select;

type GridSelectProps<
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
> = Omit<SelectProps<Option, IsMulti, Group>, 'size'>;

export type GridSelectComponentType = <
  Option extends unknown = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: GridSelectProps<Option, IsMulti, Group>
) => JSX.Element;

export const GridSelect = <
  Option extends unknown = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: GridSelectProps<Option, IsMulti, Group>
) => {
  const { size } = useDataGridContext();

  return <StyledSelect {...props} menuPortalTarget={document.body} size={size} />;
};
