import { createPortableRoutes } from '@verticeone/router/src/routes/utils';
import { ApplicationRouter } from '@vertice/core/src/modules/applications/Routes';
import { PageNotFoundRouter } from '@vertice/core/src/modules/notFound/Routes';

export const ContractRouter = createPortableRoutes({
  CONTRACTS: {
    path: '/contracts',
    children: {
      DETAIL: {
        path: ':contractId',
      },
    },
  },
} as const);

export const coreRoutes = {
  ...PageNotFoundRouter,
  ...ApplicationRouter,
  ...ContractRouter,
};
