import { featuresApi as api } from '../../api/featuresAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    listFeatures: build.query<ListFeaturesApiResponse, ListFeaturesApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/features` }),
    }),
    enableDisableFeatures: build.mutation<EnableDisableFeaturesApiResponse, EnableDisableFeaturesApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/features`,
        method: 'PUT',
        body: queryArg.approachableFeatures,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as featuresAPICodegen };
export type ListFeaturesApiResponse = /** status 200 Provides list of features
 */ {
  features?: {
    [key: string]: Feature;
  };
};
export type ListFeaturesApiArg = {
  accountId: UuidIdentifier | 'VERTICE_WORKFLOW_ADMINISTRATION';
};
export type EnableDisableFeaturesApiResponse =
  /** status 201 Provides list of features that could be enabled or disabled by user
   */ ApproachableFeatures;
export type EnableDisableFeaturesApiArg = {
  accountId: UuidIdentifier | 'VERTICE_WORKFLOW_ADMINISTRATION';
  approachableFeatures: ApproachableFeatures;
};
export type Feature = {
  code: string;
  name: string;
  version?: string;
  enabled: boolean;
  properties?: {
    [key: string]: any;
  };
};
export type UuidIdentifier = string;
export type ApproachableFeatures = {
  features?: {
    AWS_COST_INSIGHT?: {
      enabled: boolean;
      properties?: {
        activeSpendAudit?: boolean;
        activateIntegration?: boolean;
        intakeParameters?: string;
        visibleTests?: string;
        [key: string]: any;
      };
    };
    AWS_RIO?: {
      enabled: boolean;
    };
    AWS_ANALYTICS?: {
      enabled: boolean;
    };
    CUSTOM_WORKFLOWS?: {
      enabled: boolean;
    };
    CONTRACTS_V2?: {
      enabled: boolean;
    };
    WORKFLOWS_V2?: {
      enabled: boolean;
    };
    INSIGHT_ACCOUNTING?: {
      enabled: boolean;
      properties?: {
        spendInsightsEnabled?: boolean;
        simulatorActive?: boolean;
        simulatorConfig?: string;
        [key: string]: any;
      };
    };
    INSIGHTS?: {
      enabled: boolean;
    };
    INTEGRATION_GOOGLE?: {
      enabled: boolean;
    };
    INTEGRATION_MERGE?: {
      enabled: boolean;
      properties: {
        accounting: boolean;
      };
    };
    INTEGRATION_NETSUITE?: {
      enabled: boolean;
      properties: {
        standalone: boolean;
      };
    };
    INTEGRATION_QUICKBOOKS?: {
      enabled: boolean;
      properties: {
        standalone: boolean;
      };
    };
    INTEGRATION_XERO?: {
      enabled: boolean;
      properties: {
        standalone: boolean;
      };
    };
    INTEGRATION_MS_DYNAMICS?: {
      enabled: boolean;
      properties: {
        standalone: boolean;
      };
    };
    INTEGRATIONS?: {
      enabled: boolean;
    };
    SAVINGS_DASHBOARD?: {
      enabled: boolean;
    };
    SSO?: {
      enabled: boolean;
    };
    USAGE_ANALYTICS?: {
      enabled: boolean;
      properties: {
        simulatorActive: boolean;
        simulatorConfig?: string;
      };
    };
    VENDOR_LEGAL?: {
      enabled: boolean;
      properties: {
        isSubscribed: boolean;
      };
    };
  };
};
export const { useListFeaturesQuery, useLazyListFeaturesQuery, useEnableDisableFeaturesMutation } = injectedRtkApi;
