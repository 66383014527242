import React from 'react';
import { styled, Typography } from '@mui/material';
import { Tooltip, TooltipProps } from '@vertice/components/src/Tooltip';
import type { Severity } from '@vertice/slices/src/types/Severity';

type UsageBoxTextProps = {
  append?: string;
  text: React.ReactNode;
  dataTestId?: string;
};

type UsageBoxBadgeProps = UsageBoxTextProps & {
  severity: Severity;
};

type UsageBoxTitleProps = {
  tooltip?: TooltipProps;
  children: React.ReactNode;
};

const UsageBoxBadgeWrapper = styled('div')<Pick<UsageBoxBadgeProps, 'severity'>>`
  display: inline-flex;
  background-color: ${(props) => `var(--tokens-color-light-${props.severity}-3)`};
  color: ${(props) => `var(--tokens-color-light-${props.severity}-1)`};
  padding: 0.25rem 0.5rem;
  border-radius: 2rem;
  align-items: center;
`;

const UsageBoxTitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  color: var(--tokens-color-light-text-3);
  font-weight: bold;
  margin-bottom: 0.25rem;
`;

const UsageBoxContentWrapper = styled('div')`
  margin-bottom: 1.5rem;
`;

export const UsageBox = styled('div')`
  padding: 1.5rem;
  height: 100%;
  border-right: 1px solid var(--tokens-color-light-core-3);
  margin-right: -1px;
`;

const Append = styled('span')`
  opacity: 0.4;
`;

export const UsageBoxTitle = ({ tooltip, children, ...otherProps }: UsageBoxTitleProps) => (
  <UsageBoxTitleWrapper {...otherProps}>
    {children}
    {tooltip && <Tooltip header={tooltip.header} content={tooltip.content} />}
  </UsageBoxTitleWrapper>
);

export const UsageBoxContent = ({ text, append, dataTestId, ...otherProps }: UsageBoxTextProps) => (
  <UsageBoxContentWrapper {...otherProps}>
    <Typography variant="heading-l" component="div" data-test-id={dataTestId}>
      {text}
      {append && <Append>{append}</Append>}
    </Typography>
  </UsageBoxContentWrapper>
);

export const UsageBoxBadge = ({ text, severity, append = '', ...otherProps }: UsageBoxBadgeProps) => (
  <UsageBoxBadgeWrapper severity={severity} {...otherProps}>
    <Typography variant="caption-s">{text}</Typography>
    {append}
  </UsageBoxBadgeWrapper>
);
