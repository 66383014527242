import React, { useMemo } from 'react';
import { ContractContextDataRequireFetched, FetchedContract } from '@vertice/core/src/modules/saas/contract/types';
import Card, { CardHeaderActions, CardHeaderTitle } from '@verticeone/design-system/src/components/Card';
import { useTranslation } from 'react-i18next';
import Text from '@verticeone/design-system/src/components/Text';
import { Contract, type ContractCreate } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { canContractBeRenewed } from '@vertice/core/src/modules/saas/contract/computed';
import { Stack } from '@mui/material';
import HighlightedCardHeader from '@vertice/core/src/modules/saas/contract/components/HighlightedCardHeader';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import { hasFeDeadlineOrRollingFrequency } from '@vertice/core/src/modules/saas/contract/components/Deadline/utils';
import DeadlineWithLabel from '@vertice/core/src/modules/saas/contract/components/Deadline/DeadlineWithLabel';
import { useUserContractPermissions } from '@vertice/core/src/modules/saas/contract/hooks/useUserContractPermissions';
import { ContractNotYetRenewedContentActions } from './ContractNotYetRenewedContentActions';
import { useContractRenewalRequests } from '../useContractRenewalRequests';

export const deriveRenewedOutsideContractFromCurrent = (contract: Contract): ContractCreate => {
  const originalVendor = contract.parts.contractual?.vendor;
  if (!originalVendor) {
    throw new Error('No vendor found on the original contract');
  }

  return {
    record: {
      contractCategory: 'SAAS', // deprecated
      contractOrigin: 'RENEWAL_WITHOUT_VERTICE',
    },
    classification: {
      category: contract.classification?.category,
      departmentId: contract.classification?.departmentId,
      contractOwner: contract.classification?.contractOwner,
      contractWatchers: contract.classification?.contractWatchers,
    },
    parts: {
      contractual: {
        vendor: {
          ...omitBy(originalVendor, isNil),
          vendorType: originalVendor.vendorType,
          vendorContact: originalVendor?.vendorContact?.name ? originalVendor?.vendorContact : undefined,
        },
        products:
          contract.parts.contractual?.products?.map((product) => ({
            productId: product.productId,
            licenseCount: product.licenseCount ?? undefined,
          })) || [],
        financial: {
          baseCurrency: contract.parts.contractual?.financial?.baseCurrency,
        },
      },
    },
    lineage: [
      {
        linkType: 'PREV',
        linkedContractId: contract.record.contractId,
      },
    ],
  };
};

type ContractNotYetRenewedCardProps = { fetchedContract: FetchedContract };

const ContractNotYetRenewedCard = ({ fetchedContract: { contract } }: ContractNotYetRenewedCardProps) => {
  const { t } = useTranslation();
  const { userCanExecuteContract } = useUserContractPermissions(contract.record.contractId);

  return (
    <Card>
      <HighlightedCardHeader size="S">
        <CardHeaderTitle text={t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.TITLE')} />
        <CardHeaderActions>
          {hasFeDeadlineOrRollingFrequency(contract) && <DeadlineWithLabel contract={contract} />}
        </CardHeaderActions>
      </HighlightedCardHeader>
      {userCanExecuteContract && (
        <Stack p={6} spacing={12} alignItems="start">
          <Text variant="body-regular" color="text2" size="S">
            {t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.BODY')}
          </Text>
          <ContractNotYetRenewedContentActions contract={contract} />
        </Stack>
      )}
    </Card>
  );
};

export const shouldCardBeVisible = (fetchedContract: FetchedContract, isEditing: boolean, hasRequests?: boolean) =>
  !hasRequests && !isEditing && canContractBeRenewed(fetchedContract.contract);

export const useContractNotYetRenewedCard = ({
  fetchedContract,
  editMode: { isEditing },
}: ContractContextDataRequireFetched) => {
  const { linkedRequests, isLoadingLinkedRequests } = useContractRenewalRequests(fetchedContract.contract);
  const hasRequests = isLoadingLinkedRequests || linkedRequests?.length > 0;

  return useMemo(
    () =>
      shouldCardBeVisible(fetchedContract, isEditing, hasRequests)
        ? () => <ContractNotYetRenewedCard fetchedContract={fetchedContract} />
        : null,
    [fetchedContract, hasRequests, isEditing]
  );
};
