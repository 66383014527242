import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AuthContainer from '../components/AuthContainer/AuthContainer';

import styles from './Error.module.scss';

const Error = () => {
  const { t } = useTranslation();

  return (
    <AuthContainer>
      <ErrorOutlineIcon className={styles.errorIcon} />
      <div className={styles.contentWrapper}>
        <Typography variant="heading-xxs">{t('AUTH.ERROR.HEADER')}</Typography>
        <Typography>{t('AUTH.ERROR.DESCRIPTION')}</Typography>
      </div>
    </AuthContainer>
  );
};

export default Error;
