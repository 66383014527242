import { TextFieldAreaProps } from '../TextFieldArea/components/constants';
import getTextVariantStyle from '../Text/utils/getTextVariantStyle';

const theme = {
  text: {
    bold: 'textBold',
    italic: 'textItalic',
    underline: 'textUnderline',
  },
};

export const getStyles = ({ size }: Pick<TextFieldAreaProps, 'size'>) => ({
  '.textBold': getTextVariantStyle({ variant: 'body-bold', size }),
  '.textItalic': {
    fontStyle: 'italic',
  },
  '.textUnderline': {
    textDecoration: 'underline',
  },
  //....
});

export default theme;
