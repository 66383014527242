import {
  DateRange,
  DateRangeValidationError,
  PickersActionBarProps,
  PickersShortcutsItem,
  StaticDateRangePicker as MuiStaticDateRangePicker,
} from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { CustomizedProps, DesignSystemColor, DesignSystemSize, TestProps } from '../../types';
import CustomShortcuts from './components/CustomShortcuts';
import CustomActionBar from './components/CustomActionBar';
import CustomCalendarHeader from './components/CustomCalendarHeader';
import CustomDateRangePickerDay from './components/CustomDateRangePickerDay';
import StaticDateRangePickerProvider from './context/StaticDateRangePickerContext';
import { StaticDateRangePickerVariant } from './types';
import CustomLayout from './components/CustomLayout/CustomLayout';
import { checkDateFormatAndWarn } from '../DatePicker/utils';
import { CustomLayoutProps, LayoutProps } from './components/CustomLayout/types';
import { PickerChangeHandlerContext } from '@mui/x-date-pickers/models';

type ChangeContext = PickerChangeHandlerContext<DateRangeValidationError | string | null>;

export type StaticDateRangePickerProps = TestProps &
  CustomizedProps & {
    // IMPLEMENTATION NOTE:
    // We don't want to leak the Dayjs type to the outside world, we always use YYYY-MM-DD strings.
    /** Date from in the format of YYYY-MM-DD */
    dateFrom?: string | null;
    /** Date to in the format of YYYY-MM-DD */
    dateTo?: string | null;
    /** Called when value changes & user clicks on accept button */
    onChange?: (dateFrom: string | null, dateTo: string | null, context: ChangeContext | null) => void;
    onCancel?: () => void;
    /** Minimum date allowed to be picked, in the format of YYYY-MM-DD */
    minDate?: string;
    /** Maximum date allowed to be picked, in the format of YYYY-MM-DD */
    maxDate?: string;
    id?: string;
    color?: DesignSystemColor;
    variant?: StaticDateRangePickerVariant;
    label?: string;
    size?: DesignSystemSize;
    shortcutsItems?: PickersShortcutsItem<DateRange<Dayjs>>[];
    infoText?: string;
  };

const parseNullableDate = (value: string | null): Dayjs | null => (value ? dayjs(value) : null);

const StaticDateRangePicker = ({
  dateFrom = null,
  dateTo = null,
  color = 'primary',
  variant = 'double',
  minDate,
  maxDate,
  size = 'M',
  testId,
  onChange,
  onCancel,
  shortcutsItems,
  infoText,
}: StaticDateRangePickerProps) => {
  const [localValues, setLocalValues] = useState<[Dayjs | null, Dayjs | null, ChangeContext | null]>([
    parseNullableDate(dateFrom),
    parseNullableDate(dateTo),
    null,
  ]);
  const [localDateFrom, localDateTo, localContext] = localValues;

  useEffect(() => {
    const from = parseNullableDate(dateFrom);
    const to = parseNullableDate(dateTo);

    setLocalValues((prev) => {
      if (!from?.isSame(prev[0]) || !to?.isSame(prev[1])) {
        return [from, to, prev[2]];
      }
      return prev;
    });
  }, [dateFrom, dateTo]);

  useEffect(() => {
    checkDateFormatAndWarn(dateFrom, 'dateFrom');
    checkDateFormatAndWarn(dateTo, 'dateTo');
    checkDateFormatAndWarn(minDate, 'minDate');
    checkDateFormatAndWarn(maxDate, 'maxDate');
  }, [dateFrom, dateTo, minDate, maxDate]);

  const handleChangeDateFrom = (newValue: Dayjs | null, context: PickerChangeHandlerContext<string | null>) => {
    if (!context.validationError) {
      setLocalValues([dayjs(newValue), localDateTo, context]);
    }
  };

  const handleChangeDateTo = (newValue: Dayjs | null, context: PickerChangeHandlerContext<string | null>) => {
    if (!context.validationError) {
      setLocalValues([localDateFrom, dayjs(newValue), context]);
    }
  };

  return (
    <StaticDateRangePickerProvider
      color={color}
      size={size}
      minDate={minDate ? dayjs(minDate) : undefined}
      maxDate={maxDate ? dayjs(maxDate) : undefined}
      dateFormat="DD/MM/YYYY"
    >
      <MuiStaticDateRangePicker<Dayjs>
        calendars={variant === 'single' ? 1 : 2}
        shouldDisableDate={(day) => {
          if (minDate && day.isBefore(dayjs(minDate), 'day')) {
            return true;
          }
          return !!(maxDate && day.isAfter(dayjs(maxDate), 'day'));
        }}
        value={[localDateFrom, localDateTo]}
        dayOfWeekFormatter={(date) => date.format('dd')}
        onChange={(newValue, context) => {
          setLocalValues([...newValue, context]);
        }}
        slots={{
          shortcuts: CustomShortcuts,
          actionBar: CustomActionBar,
          calendarHeader: CustomCalendarHeader,
          layout: CustomLayout as React.ComponentType<LayoutProps>,
          day: CustomDateRangePickerDay,
        }}
        slotProps={{
          layout: {
            localValues: [localDateFrom, localDateTo],
            onChangeDateFrom: handleChangeDateFrom,
            onChangeDateTo: handleChangeDateTo,
            testId,
            variant,
            infoText,
          } as CustomLayoutProps,
          toolbar: { hidden: true },
          actionBar: {
            actions: ['cancel', 'accept'],
            onAccept: () => {
              if (localDateFrom?.isValid() && localDateTo?.isValid()) {
                onChange?.(
                  localDateFrom?.format('YYYY-MM-DD') || null,
                  localDateTo?.format('YYYY-MM-DD') || null,
                  localContext
                );
              }
            },
            onCancel,
          } as PickersActionBarProps,
          shortcuts: {
            items: shortcutsItems,
          },
        }}
      />
    </StaticDateRangePickerProvider>
  );
};

export default StaticDateRangePicker;
