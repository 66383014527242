import styled from '@mui/material/styles/styled';
import Text, { TextProps } from './Text';
import { ifExpression } from '../../utils/ifExpression';
import { forwardRef } from 'react';
import { isUndefined } from 'lodash';

type EllipsisTextProps = TextProps & { lineClamp?: number };

export const EllipsisText = styled(
  forwardRef<HTMLElement, EllipsisTextProps>(({ lineClamp, ...rest }: EllipsisTextProps, ref) => (
    <Text {...rest} ref={ref} />
  ))
)(({ lineClamp }) => ({
  overflow: 'hidden',

  ...ifExpression(isUndefined(lineClamp), {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),

  ...ifExpression(!isUndefined(lineClamp), {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: lineClamp,
  }),
}));
