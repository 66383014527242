export const USER_REF_PREFIX = 'urn:verticeone:vertice::iam:user';
const RESOURCE_REF_PREFIX = 'arn:';

export const getUserRef = (userId: string) => {
  const ref = `${USER_REF_PREFIX}/${userId}`;
  return encodeURIComponent(ref);
};

export const parseUserId = (userRef: string) => {
  const parts = userRef.split('/');
  if (parts.length < 2) {
    throw new Error(`Failed to parse user id from ref "${userRef}"`);
  }
  return parts[1];
};

export const isResourceRef = (ref: string) => ref.startsWith(RESOURCE_REF_PREFIX);
