import type { PaletteOptions } from '@mui/material/styles/createPalette';
import { global } from './global';

export const darkPalette: PaletteOptions = {
  mode: 'dark',
  global,
  core: {
    bg: global.color.coolGray['100'].main,
    color1: global.color.coolGray['90'].main,
    color2: global.color.coolGray['80'].main,
    color3: global.color.coolGray['70'].main,
    color4: global.color.coolGray['60'].main,
    color5: global.color.coolGray['50'].main,
    color6: global.color.coolGray['20'].main,
  },
  inactive: {
    main: global.color.gray['60'].main,
    color1: global.color.gray['50'].main,
    color2: global.color.gray['60'].main,
    color3: global.color.gray['70'].main,
    color4: global.color.gray['80'].main,
    hover: {
      color1: global.color.gray['50'].hover,
      color2: global.color.gray['60'].hover,
      color3: global.color.gray['70'].hover,
      color4: global.color.gray['80'].hover,
    },
  },
  input: {
    bg: global.color.coolGray['100'].main,
    color1: global.color.coolGray['90'].main,
    color2: global.color.coolGray['80'].main,
  },
  text: {
    color1: global.color.white.main,
    color2: global.color.coolGray['30'].main,
    color3: global.color.coolGray['50'].main,
    color4: global.color.coolGray['60'].main,
    color5: global.color.coolGray['100'].main,
  },
  primary: {
    main: global.color.purple['60'].main,
    color1: global.color.purple['30'].main,
    color2: global.color.purple['60'].main,
    color3: global.color.purple['70'].main,
    color4: global.color.purple['80'].main,
    hover: {
      color1: global.color.purple['30'].hover,
      color2: global.color.purple['60'].hover,
      color3: global.color.purple['70'].hover,
      color4: global.color.purple['80'].hover,
    },
  },
  secondary: {
    main: global.color.blue['50'].main,
    color1: global.color.blue['30'].main,
    color2: global.color.blue['50'].main,
    color3: global.color.blue['60'].main,
    color4: global.color.blue['80'].main,
    hover: {
      color1: global.color.blue['30'].hover,
      color2: global.color.blue['50'].hover,
      color3: global.color.blue['60'].hover,
      color4: global.color.blue['80'].hover,
    },
  },
  neutral: {
    main: global.color.coolGray['60'].main,
    contrastText: global.color.white.main,

    color1: global.color.coolGray['30'].main,
    color2: global.color.coolGray['60'].main,
    color3: global.color.coolGray['70'].main,
    color4: global.color.coolGray['80'].main,

    hover: {
      color1: global.color.coolGray['30'].hover,
      color2: global.color.coolGray['60'].hover,
      color3: global.color.coolGray['70'].hover,
      color4: global.color.coolGray['80'].hover,
    },
  },
  tertiary: {
    main: global.color.magenta['50'].main,
    color1: global.color.magenta['30'].main,
    color2: global.color.magenta['50'].main,
    color3: global.color.magenta['60'].main,
    color4: global.color.magenta['80'].main,
    hover: {
      color1: global.color.magenta['30'].hover,
      color2: global.color.magenta['50'].hover,
      color3: global.color.magenta['60'].hover,
      color4: global.color.magenta['80'].hover,
    },
  },
  error: {
    main: global.color.red['30'].main,
    color1: global.color.red['30'].main,
    color2: global.color.red['60'].main,
    color3: global.color.red['80'].main,
    color4: global.color.red['80'].main,
    hover: {
      color1: global.color.red['30'].hover,
      color2: global.color.red['60'].hover,
      color3: global.color.red['80'].hover,
      color4: global.color.red['80'].hover,
    },
  },
  warning: {
    main: global.color.orange['30'].main,
    color1: global.color.orange['30'].main,
    color2: global.color.orange['50'].main,
    color3: global.color.orange['70'].main,
    color4: global.color.orange['70'].main,
    hover: {
      color1: global.color.orange['30'].hover,
      color2: global.color.orange['50'].hover,
      color3: global.color.orange['70'].hover,
      color4: global.color.orange['70'].hover,
    },
  },
  success: {
    main: global.color.green['30'].main,
    color1: global.color.green['30'].main,
    color2: global.color.green['60'].main,
    color3: global.color.green['80'].main,
    color4: global.color.green['80'].main,
    hover: {
      color1: global.color.green['30'].hover,
      color2: global.color.green['60'].hover,
      color3: global.color.green['80'].hover,
      color4: global.color.green['80'].hover,
    },
  },
  info: {
    main: global.color.blue['30'].main,
    color1: global.color.blue['30'].main,
    color2: global.color.blue['60'].main,
    color3: global.color.blue['80'].main,
    color4: global.color.blue['80'].main,
    hover: {
      color1: global.color.blue['30'].hover,
      color2: global.color.blue['60'].hover,
      color3: global.color.blue['80'].hover,
      color4: global.color.blue['80'].hover,
    },
  },
  transparent: {
    main: global.transparency.white['60'],
    color1: global.transparency.white['80'],
    color2: global.transparency.white['60'],
    color3: global.transparency.white['40'],
    color4: global.transparency.white['20'],
    hover: {
      color1: global.transparency.white['75'],
      color2: global.transparency.white['55'],
      color3: global.transparency.white['35'],
      color4: global.transparency.white['15'],
    },
  },
  gradient: global.gradient.dark,
  visualization: {
    sequential: {
      primary: {
        10: '#39008a',
        20: '#40047a',
        30: '#48096b',
        40: '#500e5c',
        50: '#58134c',
        60: '#60173d',
        70: '#681c2e',
        80: '#70211e',
        90: '#78260f',
        100: '#802b00',
      },
      secondary: {
        10: '#002685',
        20: '#0e2482',
        30: '#1d2280',
        40: '#2c207e',
        50: '#3b1e7c',
        60: '#491c7a',
        70: '#581a78',
        80: '#671876',
        90: '#761674',
        100: '#851472',
      },
      tertiary: {
        10: '#15562f',
        20: '#224d2c',
        30: '#30442a',
        40: '#3e3c28',
        50: '#4c3326',
        60: '#592a24',
        70: '#672222',
        80: '#751920',
        90: '#83101e',
        100: '#91081c',
      },
    },
    divergent: {
      primary: {
        '-50': '#39008a',
        '-40': '#350776',
        '-30': '#310f63',
        '-20': '#2e1750',
        '-10': '#2a1f3d',
        '+10': '#382721',
        '+20': '#4a2819',
        '+30': '#5c2910',
        '+40': '#6e2a08',
        '+50': '#802b00',
      },
      secondary: {
        '-50': '#002685',
        '-40': '#072672',
        '-30': '#0f2660',
        '-20': '#17264e',
        '-10': '#1f263c',
        '+10': '#392338',
        '+20': '#4c1f46',
        '+30': '#5f1b55',
        '+40': '#721763',
        '+50': '#851472',
      },
      tertiary: {
        '-50': '#15562f',
        '-40': '#184c2e',
        '-30': '#1c432d',
        '-20': '#1f392c',
        '-10': '#23302b',
        '+10': '#3c2027',
        '+20': '#511a24',
        '+30': '#661421',
        '+40': '#7b0e1e',
        '+50': '#91081c',
      },
    },
  },
};
