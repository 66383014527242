import { integrationsAPICodegen } from '@vertice/slices';

const TAG_TYPE = 'Integrations';
const INTEGRATIONS_SLACK_CONNECTION_TAG = 'INTEGRATIONS_SLACK_CONNECTION_TAG';

type AllTagType = typeof TAG_TYPE | typeof INTEGRATIONS_SLACK_CONNECTION_TAG;

export const enhancedIntegrationsAPI = integrationsAPICodegen.enhanceEndpoints<AllTagType>({
  addTagTypes: [TAG_TYPE],
  endpoints: {
    getSupportedIntegrations: {
      providesTags: [TAG_TYPE],
    },
    getAccountIntegrations: {
      providesTags: [TAG_TYPE],
    },
    getAccountIntegration: {
      providesTags: (result, error, arg) => [{ type: TAG_TYPE, id: `${arg.accountId}/${arg.integrationId}` }],
    },
    connectAccountIntegration: {
      invalidatesTags: (result, error, arg) => [{ type: TAG_TYPE, id: `${arg.accountId}/${arg.integrationId}` }],
    },
    configureAccountIntegration: {
      invalidatesTags: (result, error, arg) => [{ type: TAG_TYPE, id: `${arg.accountId}/${arg.integrationId}` }],
    },
    deactivateAccountIntegration: {
      invalidatesTags: (result, error, arg) => [{ type: TAG_TYPE, id: `${arg.accountId}/${arg.integrationId}` }],
    },
    revokeAccountIntegration: {
      invalidatesTags: (result, error, arg) => [{ type: TAG_TYPE, id: `${arg.accountId}/${arg.integrationId}` }],
    },
    testAccountIntegration: {
      invalidatesTags: (result, error, arg) => [{ type: TAG_TYPE, id: `${arg.accountId}/${arg.integrationId}` }],
    },
    activateAccountIntegration: {
      invalidatesTags: (result, error, arg) => [{ type: TAG_TYPE, id: `${arg.accountId}/${arg.integrationId}` }],
    },
    listSlackUserIdentities: {
      providesTags: [INTEGRATIONS_SLACK_CONNECTION_TAG],
    },
    adminConnectUserIdentity: {
      invalidatesTags: [INTEGRATIONS_SLACK_CONNECTION_TAG],
    },
    revokeUserAuthorization: {
      invalidatesTags: [INTEGRATIONS_SLACK_CONNECTION_TAG],
    },
  },
});
