import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import { useLatestOptimizationTestsQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useMemo } from 'react';
import { countBy } from 'lodash';
import LoadableAdvanced from '@vertice/utils/src/loadableAdvanced';

export type TestStats = { passed: number; failed: number; notRun: number };

export const useTestStats = (): LoadableAdvanced<TestStats> => {
  const { accountId } = useSelector(getAccount);
  const {
    data: rawData,
    isLoading,
    error,
  } = useLatestOptimizationTestsQuery({
    accountId: accountId!,
  });

  return useMemo(() => {
    if (!rawData || rawData.monitoringLatestQuery?.__typename !== 'MonitoringResult') {
      return { isLoading: isLoading, error, isEmpty: false };
    }

    return {
      isLoading: isLoading,
      isEmpty: false,
      error,
      data: {
        // Fallback counts
        passed: 0,
        failed: 0,
        notRun: 0,
        // Override fallbacks with actual counts
        ...countBy(rawData.monitoringLatestQuery.items, ({ breaching }) =>
          breaching === true ? 'failed' : breaching === false ? 'passed' : 'notRun'
        ),
      },
    };
  }, [error, isLoading, rawData]);
};
