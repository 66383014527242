import React from 'react';
import { Stack } from '@mui/material';
import Card from '@verticeone/design-system/src/components/Card';
import Divider from '@verticeone/design-system/src/components/Divider';
import { CloudAccountsProvider } from './CloudAccountsContext';
import { endOfMonth, format, startOfDay, startOfMonth, subMonths } from 'date-fns';
import TableLayout from './Table/components/TableLayout';
import TableByLastNode from './Table/components/TableByLastNode';
import TableBreadcrumbs from './Table/components/TableBreadcrumbs';
import GraphByLastNode from './Graph/components/GraphByLastNode';
import { DATE_FORMAT } from './constants';
import StatsByLastNode from './Stats/StatsByLastNode';
import DateRangePicker from './Filters/DateRangePicker/DateRangePicker';

const CloudAccountsTab = () => {
  const dataGenerationDayOfMonth = 8;
  const today = startOfDay(new Date());
  const startDate = today.getDate() <= dataGenerationDayOfMonth ? subMonths(today, 1) : today;

  const previousMonth = subMonths(startDate, 1);
  const previousMonthEnd = endOfMonth(previousMonth);
  const previousMonthStart = startOfMonth(previousMonth);

  const previous2Month = subMonths(startDate, 2);
  const periodTwoEnd = endOfMonth(previous2Month);
  const periodTwoStart = startOfMonth(previous2Month);

  const period = {
    periodOneStart: format(previousMonthStart, DATE_FORMAT),
    periodOneEnd: format(previousMonthEnd, DATE_FORMAT),
    periodTwoStart: format(periodTwoStart, DATE_FORMAT),
    periodTwoEnd: format(periodTwoEnd, DATE_FORMAT),
  };

  return (
    <CloudAccountsProvider period={period}>
      <Stack gap={4}>
        <TableBreadcrumbs />
        <DateRangePicker />
        <Card>
          <StatsByLastNode />
          <Divider />
          <Stack>
            <GraphByLastNode />
            <Divider />
            <TableLayout>
              <TableByLastNode />
            </TableLayout>
          </Stack>
        </Card>
      </Stack>
    </CloudAccountsProvider>
  );
};

export default CloudAccountsTab;
