import { FC } from 'react';
import { DesignSystemColor, DesignSystemSize } from '@verticeone/design-system/src/types';
import ChipButton from '@verticeone/design-system/src/components/ChipButton';
import { Request } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useTranslation } from 'react-i18next';
import assertExhausted from '@vertice/core/src/utils/assertExhausted';

const getRequestStatusColor = (status: Request['status']): DesignSystemColor => {
  switch (status) {
    case 'PENDING':
      return 'warning';
    case 'ACTIVE':
      return 'secondary';
    case 'COMPLETED':
      return 'success';
    case 'TERMINATED':
      return 'neutral';
    case 'CANCELLED':
      return 'error';
    case 'FAILED':
      return 'error';
    default:
      assertExhausted(status);
      return 'neutral';
  }
};

type RequestStatusChipProps = {
  status: Request['status'];
  variant?: 'ghost' | 'outline' | 'solid';
  size?: DesignSystemSize;
};

export const RequestStatusChip: FC<RequestStatusChipProps> = ({ status, variant = 'ghost', size = 'S' }) => {
  const { t } = useTranslation();
  return (
    <ChipButton variant={variant} size={size} color={getRequestStatusColor(status)} isActive={false}>
      {t(`ENTITIES.WORKFLOW_REQUEST.STATUSES.${status}`)}
    </ChipButton>
  );
};
