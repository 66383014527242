import React from 'react';
import { GridValidRowModel } from '@mui/x-data-grid';
import DataGridBase from './DataGridBase';
import { DataGridColDef, DataGridProps } from './types';
import { GridCellSkeleton } from './components/GridCellSkeleton';
import { pick } from 'lodash';

const DEFAULT_ROW_COUNT = 8;

const getDataGridSkeletonProps = ({ loadingRowCount = DEFAULT_ROW_COUNT, ...props }: DataGridProps) => {
  const columnDisablingProps = {
    disableColumnMenu: true,
    sortable: false,
    editable: false,
    resizable: false,
    disableReorder: true,
  };

  const groupColumns = props.groupingColDef
    ? [
        {
          ...pick(
            props.groupingColDef,
            'headerName',
            'description',
            'width',
            'flex',
            'minWidth',
            'maxWidth',
            'align',
            'headerAlign',
            'filterOperators'
          ),
          filterable: false, // Disable filters for skeleton to prevent noResultsOverlay
          field: 'group',
          renderCell: () => <GridCellSkeleton />,
          ...columnDisablingProps,
        },
      ]
    : [];

  const columns = props.columns.map(({ renderSkeletonCell, ...colDef }: DataGridColDef) => ({
    ...pick(
      colDef,
      'field',
      'headerName',
      'description',
      'width',
      'flex',
      'minWidth',
      'maxWidth',
      'align',
      'headerAlign',
      'filterOperators'
    ),
    filterable: false, // Disable filters for skeleton to prevent noResultsOverlay
    renderCell: renderSkeletonCell ?? (() => <GridCellSkeleton />),
    ...columnDisablingProps,
  }));

  return {
    ...props,
    treeData: false,
    getTreeDataPath: undefined,
    rows: [...Array(loadingRowCount).keys()].map((i) => ({ id: String(i) })),
    getRowId: (row: { id: number }) => row.id,
    columns: [...groupColumns, ...columns],
  };
};

const DataGridSkeleton = <R extends GridValidRowModel>(props: DataGridProps<R>) => (
  <DataGridBase {...getDataGridSkeletonProps(props)} />
);

export default DataGridSkeleton;
