import { CustomizedProps, TestProps } from '../../types';
import { forwardRef } from 'react';
import MuiGrid, { GridProps as MuiGridProps } from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';
import { testProps } from '../../utils/testProperties';

export type GridProps = TestProps &
  CustomizedProps &
  MuiGridProps<'div'> & {
    hideIfEmpty?: boolean;
  };

const StyledMuiGrid = styled(MuiGrid)<{ $hideIfEmpty: boolean }>(({ $hideIfEmpty }) =>
  $hideIfEmpty
    ? {
        '&:empty': {
          display: 'none',
        },
      }
    : {}
);

const Grid = forwardRef<HTMLDivElement, GridProps>(
  (
    {
      hideIfEmpty = false,
      children,
      testId,
      customized: _customized, // to not pass the flag down to the button
      ...otherProps
    }: GridProps,
    ref
  ) => (
    <StyledMuiGrid ref={ref} $hideIfEmpty={hideIfEmpty} {...testProps(testId, 'grid')} {...otherProps}>
      {children}
    </StyledMuiGrid>
  )
);

export default Grid;
