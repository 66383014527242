import { createRequiredContext } from '@verticeone/design-system/src/utils/contexts';
import { useMemo, useState } from 'react';

export type LocaleContextData = {
  locale: string;
  setLocale: (locale: string) => void;
};

export const { LocaleContextProvider, useLocaleContext } = createRequiredContext<LocaleContextData, 'Locale'>('Locale');

const DEFAULT_LOCALE = 'en-US';

/** Prepares LocaleContextData */
export const useLocale = (): LocaleContextData => {
  const [locale, setLocale] = useState(DEFAULT_LOCALE);
  return useMemo(
    () => ({
      locale,
      setLocale,
    }),
    [locale, setLocale]
  );
};
