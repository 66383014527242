import { Stack, useTheme } from '@mui/material';
import styled from '@mui/material/styles/styled';
import { useTranslation } from 'react-i18next';

import i18n from '../../../translate';
import { SendTwoTone } from '@mui/icons-material';
import TextFieldArea from '../../TextFieldArea/TextFieldArea';
import { SendCommentCallback } from '../types';
import { ChangeEventHandler, FC, useState } from 'react';
import useKeyboardShortcuts from '@vertice/hooks/src/useKeyboardShortcuts';

const CommentInputWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.core.bg,
  padding: theme.spacing(4),
  minHeight: 0,
  flexShrink: 0,
  position: 'relative',
}));

const SendCommentButtonWrapper = styled(Stack)<{ $disabled: boolean }>(({ theme, $disabled }) => ({
  position: 'absolute',
  right: theme.spacing(8),
  bottom: theme.spacing(6),
  cursor: $disabled ? 'not-allowed' : 'pointer',
}));

export type CommentInputProps = {
  onSend?: SendCommentCallback;
  disabled?: boolean;
};

const CommentInput: FC<CommentInputProps> = ({ onSend, disabled = false }) => {
  const [comment, setComment] = useState<string | undefined>(undefined);

  const { t } = useTranslation(undefined, { i18n });
  const { palette } = useTheme();

  const resetField = () => setComment('');

  const handleSendComment = () => {
    if (comment) {
      setTimeout(() => onSend?.(comment, resetField), 0);
    }
  };

  const { handlers } = useKeyboardShortcuts({
    ignoreLetterPresses: true,
    shortcuts: {
      submit: {
        keys: ['Enter'],
        action: handleSendComment,
        onDownCallback: (e) => e.preventDefault(), // Prevents new line on Enter
      },
    },
  });

  const handleCommentChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => setComment(e.target.value);

  return (
    <CommentInputWrapper justifyContent="center" minHeight={0}>
      <TextFieldArea
        disabled={disabled}
        autosize
        size="S"
        minHeight={3.5}
        minRows={1}
        maxRows={10}
        value={comment}
        onChange={handleCommentChange}
        variant="outline"
        color="transparent"
        placeholder={t('COMMENTS.ADD_COMMENT')}
        {...handlers}
      />
      <SendCommentButtonWrapper onClick={handleSendComment} $disabled={disabled}>
        <SendTwoTone htmlColor={palette.secondary.color2} fontSize="small" />
      </SendCommentButtonWrapper>
    </CommentInputWrapper>
  );
};

export default CommentInput;
