import { CommentMetadata } from '../Comment/types';

export const getNewCommentsCount = (comments: CommentMetadata[], cursor: string) =>
  comments.reduce(
    (acc, comment) => (new Date(comment.timestamp) > new Date(cursor) && !comment.isCommentAuthor ? acc + 1 : acc),
    0
  );

export const getFirstNewCommentIndex = (comments: CommentMetadata[], cursor: string) =>
  comments.findIndex((comment) => new Date(comment.timestamp) > new Date(cursor!) && !comment.isCommentAuthor);
