import React from 'react';
import { Stack, styled, Theme, Typography, useTheme } from '@mui/material';
import type { User } from '../../hooks/useUsers';
import type { Color } from '@verticeone/design-system/src/theme/palette/types';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

type AvatarProps = {
  background: Color;
  theme: Theme;
};

const Avatar = styled('div')<AvatarProps>(({ background, theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(0),
  width: theme.spacing(8),
  height: theme.spacing(8),
  borderRadius: theme.spacing(8),
  fontWeight: '600',
  fontSize: '0.875rem',
  background,
  color: theme.palette.text.color5,
}));

// prettier-ignore
const getNameInitials = (name: string) => name
    .split(' ')
    .map((i) => i.charAt(0))
    .join('')
    .toUpperCase()
    .slice(0,2);

const FullNameCell = ({ id, row }: GridRenderCellParams<User, string>) => {
  const { palette: { text, visualization: { divergent: colors } } } = useTheme(); // prettier-ignore
  const palette = [
    colors.primary['+30'],
    colors.primary['-30'],
    colors.secondary['+30'],
    colors.secondary['-30'],
    colors.tertiary['+30'],
  ];

  return (
    <Stack key={id} direction="row" alignItems="center" gap={2}>
      <Avatar background={palette[row.index % palette.length]}>{getNameInitials(row.name)}</Avatar>
      <Stack>
        <Typography variant="body-bold-m">{row.name}</Typography>
        {row.email && (
          <Typography variant="body-regular-s" color={text.color3}>
            {row.email}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default FullNameCell;
