import { createPortableRoutes } from '@verticeone/router/src/routes/utils';

export const preferencesRoutes = createPortableRoutes({
  PREFERENCES: {
    path: '/preferences',
    children: {
      TAB: {
        path: ':tab',
      },
    },
  },
} as const);
