import React from 'react';
import WorkflowsV2 from '@vertice/core/src/modules/workflows/Workflows';
import { Stack } from '@mui/material';
import Card from '@verticeone/design-system/src/components/Card';

const Workflows = () => {
  return (
    <Stack px={10}>
      <Card>
        <WorkflowsV2 />
      </Card>
    </Stack>
  );
};

export default Workflows;
