import { useTranslation } from 'react-i18next';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import Button from '@verticeone/design-system/src/components/Button';
import { Link } from 'react-router-dom';
import React from 'react';
import { ViewOnContractForVendorsPage } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

export type NavigationCellProps = {
  contractData: ViewOnContractForVendorsPage;
};

const NavigationCell = ({ contractData }: NavigationCellProps) => {
  const { t } = useTranslation();
  const routes = useRoutes();
  const { generatePath } = useRouteNavigate();
  const getContractPath = (contractId: string) => generatePath(routes.CONTRACTS.DETAIL.ABSOLUTE_PATH, { contractId });

  if (!contractData.visibility) {
    return null;
  }

  return (
    <Button component={Link} color="primary" variant="ghost" to={getContractPath(contractData.contractId!)}>
      {t('ENTITIES.CONTRACT.ACTIONS.VIEW')}
    </Button>
  );
};

export default NavigationCell;
