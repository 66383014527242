import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, Stack } from '@mui/material';
import TextFieldCaption from '@verticeone/design-system/src/components/TextFieldCaption';
import Select from '@verticeone/design-system/src/components/Select';

import { type DataType, useDataOptions } from './useDataOptions';
import { useOfferings } from '../../../../providers/OfferingsProvider';

type DropDownFilterFieldProps = {
  id: DataType;
  tKey: string;
  minWidth?: number;
};

const SelectFilterItem = ({ id, tKey, minWidth = 120 }: DropDownFilterFieldProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: tKey });
  const options = useDataOptions(tKey, id);
  const { filter } = useOfferings();

  return (
    <FormControl variant="outlined">
      <Stack direction="row" alignItems="center" gap={1}>
        <TextFieldCaption htmlFor={id} label={t('LABEL')} size="M" />
        <Stack minWidth={minWidth}>
          <Select<(typeof options)[number], false>
            variant="outlined"
            size="S"
            color="tertiary"
            placeholder={t('PLACEHOLDER')}
            id={id}
            defaultValue={options.find((option) => option.value === filter.data[id])}
            getOptionValue={(option) => option.value.toString()}
            getOptionLabel={(option) => option.label}
            isClearable={true}
            isMulti={false}
            options={options}
            onChange={(option) => filter.updateFilter(id, option?.value)}
          />
        </Stack>
      </Stack>
    </FormControl>
  );
};

export default SelectFilterItem;
