import { useFormContext } from 'react-hook-form';
import { useCallback, useState } from 'react';
import {
  CreateRequestApiResponse,
  RenewalRequestInput,
  useCreateRequestMutation,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';
import { RenewalFormData } from '../../RenewalWizard/types';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { formDataToExternalContract } from '../../utils/convertors';
import { parseRequestRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { useWaitForRequestStage } from '@vertice/core/src/modules/saas/contract/utils/useWaitForRequestStage';
import useInvalidateContracts from '@vertice/core/src/modules/saas/contract/hooks/useInvalidateContracts';
import keepOnlyLocalDatePart from '@vertice/core/src/utils/dates/keepOnlyLocalDatePart';
import omitEmptyValues from '@vertice/core/src/utils/objects/omitEmptyValues';
import { isNil } from 'lodash';

/**
 *  Create Contracts V2 request for Renewal
 */
export const useCreateRenewalRequest = (onSuccess: () => void): [() => void, boolean] => {
  const { accountId } = useAccountContext();
  const { watch, setValue } = useFormContext<RenewalFormData>();
  const formValues = watch();

  const [inProgress, setInProgress] = useState(false);
  const [createRequestMutation] = useCreateRequestMutation();
  const waitForRequestStage = useWaitForRequestStage();
  const invalidateContracts = useInvalidateContracts();

  const createRequest = useCallback(() => {
    void (async () => {
      try {
        const { existingContractId, targetSignDate, renewalType, vendor } = formValues;
        if (existingContractId !== undefined && renewalType !== null && !isNil(vendor)) {
          const requestInput: RenewalRequestInput = omitEmptyValues({
            parentContractId: existingContractId,
            targetSignDate: keepOnlyLocalDatePart(targetSignDate),
            baseCurrency: formValues.contractCurrency ?? DEFAULT_CURRENCY,
            additionalNotes: formValues.additionalNotes,
            renewalType,
            vendorType: formValues.vendor ? formValues.vendor.type : null,
            vendorId: formValues.vendor ? formValues.vendor.id : null,
            vendorName: formValues.vendor ? formValues.vendor.name : null,
            vendorProducts: formValues.products,
            departmentId: formValues.departmentId,
            signingEntity: formValues.signingEntity,
            userPlannedBudget: formValues.approvedBudget,
            vendorProposedCost: formValues.totalAnnualCost,
            applicationOrigin: 'CFA',
            external: omitEmptyValues(formDataToExternalContract(formValues)),
          });

          setInProgress(true);

          const createRequestResponse = (await createRequestMutation({
            accountId: accountId,
            createRequest: {
              type: 'RENEWAL',
              name: `Renewal - ${vendor.name}`,
              input: requestInput,
            },
          })) as { data: CreateRequestApiResponse };

          if (createRequestResponse.data?.ref) {
            const requestId = parseRequestRef(createRequestResponse.data.ref).requestId;

            await waitForRequestStage(accountId, requestId);
            invalidateContracts();

            setValue('newContractId', requestId);
            onSuccess();
          }
        }
      } finally {
        setInProgress(false);
      }
    })();
  }, [formValues, createRequestMutation, accountId, waitForRequestStage, setValue, onSuccess, invalidateContracts]);

  return [createRequest, inProgress];
};
