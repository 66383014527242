import {
  AssignUserTaskApiArg,
  AssignUserTaskApiResponse,
  workflowsV2ApiCodegen,
} from '../openapi/codegen/workflowsV2Api';
import { enhancedBffeWorkflowsAPI, WORKFLOW_BFFE_TASKS } from './enhancedBffeWorkflowsAPI';

const PREFIX = 'workflowsV2';
const WORKFLOW_VERSIONS = `${PREFIX}WorkflowVersions`;
const WORKFLOW_INSTANCES = `${PREFIX}WorkflowInstances`;
const WORKFLOW_TASKS_TAG = `${PREFIX}WorkflowTasks`;

type TagType = typeof WORKFLOW_VERSIONS | typeof WORKFLOW_INSTANCES | typeof WORKFLOW_TASKS_TAG;

export const enhancedWorkflowsV2API = workflowsV2ApiCodegen
  .enhanceEndpoints<TagType>({
    addTagTypes: [WORKFLOW_VERSIONS],
    endpoints: {
      listWorkflows: {
        providesTags: [WORKFLOW_VERSIONS],
      },
      activateWorkflowVersion: {
        invalidatesTags: [WORKFLOW_VERSIONS],
      },
      deployWorkflow: {
        invalidatesTags: [WORKFLOW_VERSIONS],
      },
      deployWorkflowVersion: {
        invalidatesTags: [WORKFLOW_VERSIONS],
      },
      listWorkflowInstances: {
        providesTags: [WORKFLOW_INSTANCES],
      },
      startWorkflowInstance: {
        invalidatesTags: [WORKFLOW_INSTANCES],
      },
      listAccountWorkflowInstances: {
        providesTags: [WORKFLOW_INSTANCES],
      },
      listUserTasks: {
        providesTags: [WORKFLOW_TASKS_TAG],
      },
      fulfillUserTask: {
        invalidatesTags: [WORKFLOW_TASKS_TAG],
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          await queryFulfilled;
          dispatch(enhancedBffeWorkflowsAPI.util.invalidateTags([WORKFLOW_BFFE_TASKS]));
        },
      },
      getUserTask: {
        providesTags: [WORKFLOW_TASKS_TAG],
      },
      draftUserTask: {
        invalidatesTags: [WORKFLOW_TASKS_TAG],
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          await queryFulfilled;
          dispatch(enhancedBffeWorkflowsAPI.util.invalidateTags([WORKFLOW_BFFE_TASKS]));
        },
      },
      assignUserTask: {
        invalidatesTags: [WORKFLOW_TASKS_TAG],
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          await queryFulfilled;
          dispatch(enhancedBffeWorkflowsAPI.util.invalidateTags([WORKFLOW_BFFE_TASKS]));
        },
      },
      deleteWorkflowVersion: {
        invalidatesTags: [WORKFLOW_VERSIONS],
      },
      listWorkflowVersions: {
        providesTags: [WORKFLOW_VERSIONS],
      },
    },
  })
  .injectEndpoints({
    endpoints: (build) => ({
      optimisticallyAssignUserTask: build.mutation<AssignUserTaskApiResponse, AssignUserTaskApiArg>({
        query: (queryArg) => ({
          url: `/accounts/${queryArg.accountId}/tasks/${queryArg.taskId}/assign`,
          method: 'POST',
          body: queryArg.assignUserTask,
        }),
        async onQueryStarted({ accountId, taskId, assignUserTask }, { dispatch, queryFulfilled }) {
          const patchResults = [
            dispatch(
              enhancedBffeWorkflowsAPI.util.updateQueryData('getTaskOverview', { accountId, taskId }, (draft) => {
                draft.task.assignment = [...assignUserTask.assignments];
              })
            ),
            dispatch(
              enhancedWorkflowsV2API.util.updateQueryData('getUserTask', { accountId, taskId }, (draft) => {
                draft.assignment = [...assignUserTask.assignments];
              })
            ),
          ];

          try {
            await queryFulfilled;
            dispatch(enhancedBffeWorkflowsAPI.util.invalidateTags([WORKFLOW_BFFE_TASKS]));
          } catch {
            patchResults.forEach((patchResult) => {
              patchResult.undo();
            });
          }
        },
        invalidatesTags: [WORKFLOW_TASKS_TAG],
      }),
    }),
  });

export const { useOptimisticallyAssignUserTaskMutation } = enhancedWorkflowsV2API;
