import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import { UserIdentity } from '@vertice/slices';
import Text from '@verticeone/design-system/src/components/Text';
import { getConnectedUser, SlackConnections, SlackConnectionStatus } from '../../../common';

type SlackUserCellProps = {
  connectedUsers?: UserIdentity[];
} & GridRenderCellParams<SlackConnections>;

export const SlackUserCell = ({ connectedUsers, ...params }: SlackUserCellProps) => {
  const { t } = useTranslation();

  const {
    row: { userId, slackUsers },
    value: selectedSlackUserEmail,
  } = params;

  const connectedUser = getConnectedUser(userId, connectedUsers);
  const connectedSlackUserName = slackUsers?.find((user) => user.email === connectedUser?.identityEmail)?.userName;
  const selectedSlackUserName = slackUsers?.find((user) => user.email === selectedSlackUserEmail)?.userName;

  if (connectedUser?.status === SlackConnectionStatus.ACTIVE) {
    return (
      <Stack gap={1}>
        <Text variant="body-regular" size="M" color="text1">
          {connectedUser.identityEmail}
        </Text>
        <Text variant="caption" size="XS" color="text3">
          {connectedSlackUserName}
        </Text>
      </Stack>
    );
  }

  if (!selectedSlackUserEmail) {
    return (
      <Text variant="body-regular" size="M" color="text3">
        {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.NO_USER_LINKED')}
      </Text>
    );
  }

  return (
    <Stack gap={1}>
      <Text variant="body-regular" size="M" color="text1">
        {selectedSlackUserEmail}
      </Text>
      <Text variant="caption" size="XS" color="text3">
        {selectedSlackUserName}
      </Text>
    </Stack>
  );
};
