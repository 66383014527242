import { Box, Paper, Stack, styled } from '@mui/material';
import React, { ReactNode } from 'react';
import Button from '@verticeone/design-system/src/components/Button';
import IconWrapper from '@verticeone/design-system/src/components/IconWrapper/IconWrapper';
import { ArrowBackIos } from '@mui/icons-material';
import backgroundGlowImage from './assets/background_glow.svg';

export const DialogWrapper = styled(Stack)({
  position: 'relative',
  backgroundImage: `url(${backgroundGlowImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  margin: '-40px',
  padding: '40px',
});

const DialogWindowPosition = styled(Box)({
  transition: 'width .25s',
  height: 'calc(100vh - 80px)',
  margin: 'auto',
});

const DialogWindowLook = styled(Paper)({
  height: '100%',
  borderRadius: 16,
  overflow: 'hidden',
});

type DialogProps = {
  children: ReactNode;
  fullWidth?: boolean;
};

export const Dialog: React.FC<DialogProps> = ({ children, fullWidth = false }) => (
  <DialogWindowPosition sx={{ width: fullWidth ? '100%' : 560 }}>
    <DialogWindowLook>{children}</DialogWindowLook>
  </DialogWindowPosition>
);

const DialogCloseButtonPosition = styled(Box)({
  position: 'absolute',
  top: '40px',
  left: '40px',
});

type DialogCloseButtonProps = {
  onClick?: () => void;
  title?: string;
};

export const DialogCloseButton: React.FC<DialogCloseButtonProps> = ({ onClick, title }) =>
  onClick && title ? (
    <DialogCloseButtonPosition>
      <Button variant={'outline'} onClick={onClick}>
        <IconWrapper icon={ArrowBackIos} />
        {title}
      </Button>
    </DialogCloseButtonPosition>
  ) : null;
