import React from 'react';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import type { Theme } from '@mui/material/styles';
import type { DesignSystemSize } from '../../types';

type DialogWrapperProps = {
  theme: Theme;
  children: React.ReactNode;
  $size: DesignSystemSize;
};

const padding: Record<DesignSystemSize, number> = {
  XL: 30,
  L: 27,
  M: 24,
  S: 21,
  XS: 18,
  XXS: 15,
};
const gap: Record<DesignSystemSize, number> = {
  XL: 20,
  L: 18,
  M: 16,
  S: 14,
  XS: 12,
  XXS: 10,
};

export const DialogWrapper = styled(Stack)<DialogWrapperProps>(({ $size }) => ({
  padding: `${padding[$size]}px`,
  gap: `${gap[$size]}px`,
}));
