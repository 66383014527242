import { createPortableRoutes } from '@verticeone/router/src/routes/utils';

export const saasRoutes = createPortableRoutes({
  DASHBOARD: {
    path: '/dashboard',
    children: {
      SAVINGS: {
        path: 'savings',
      },
    },
  },
  CONTRACTS: {
    path: '/contracts',
    children: {
      DETAIL: {
        path: ':contractId',
        children: {
          RENEWAL_INTAKE: {
            path: 'renewal-intake',
          },
          EDIT: {
            path: '#edit',
          },
        },
      },
    },
  },
  CREATE_EXISTING_CONTRACT: {
    path: '/create-existing-contract',
  },
  VENDORS: {
    path: '/vendors',
    children: {
      VENDOR: {
        path: ':id',
      },
    },
  },
  OTP: {
    path: '/otp',
    children: {
      ASSUME_ROLE: {
        path: '/assume-role',
      },
    },
  },
} as const);
