import React, { useCallback, useEffect, useMemo, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { Chart as HighchartsChart, SeriesOptionsType } from 'highcharts';
import { Box, Stack, useTheme } from '@mui/material';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/theme';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';
import {
  cssObjectToString,
  monthFormatter,
  yLabelStyles,
} from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import TooltipSeriesValuePair from '@vertice/core/src/components/charts/components/Tooltip/TooltipSeriesValuePair';
import TooltipTotal from '@vertice/core/src/components/charts/components/Tooltip/TooltipTotal';
import extractTooltipContent, {
  TooltipItem,
} from '@vertice/core/src/components/charts/components/Tooltip/extractTooltipContent';
import { sortTooltipPointsFunction } from '@vertice/core/src/components/charts/components/Tooltip/sortTooltipPointsUtils';
import { getSeriesWithBorderRadius } from '@vertice/core/src/components/charts/highcharts-specific/utils/graphBorderRadiusUtils';
import { useStackedColumnHover } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStackedColumnHover';
import extractLegendItemsFromSeries from '@vertice/core/src/components/charts/highcharts-specific/utils/extractLegendItemsFromSeries';
import usePositiveNegativeColoredData from '@vertice/core/src/components/charts/highcharts-specific/utils/usePositiveNegativeColoredData';
import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import { SpendByTransactionTypeData } from './useSpendByTransactionTypeData';
import { currencyFormatter } from '@vertice/core/src/components/charts/components/Tooltip/valueFormatters';
import { useTranslation } from 'react-i18next';
import { MAXIMUM_FRACTION_DIGITS_DYNAMIC, useFormatCurrency } from '@vertice/core/src/utils/formatting/currency';
import { AWS_BRAND_COLOR, AWS_DEFAULT_CURRENCY_DECIMAL_PLACES } from '../../constants';

type SpendByTransactionTypeGraphProps = {
  data: SpendByTransactionTypeData;
};

const SERIES_ID_TOTAL = '__total';

const SpendByTransactionTypeGraph = ({ data }: SpendByTransactionTypeGraphProps) => {
  const { locale } = useLocaleContext();
  const { palette } = useTheme();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.SPEND_BY_TRANSACTION_TYPE' });
  const formatCurrency = useFormatCurrency();

  const [chart, setChart] = useState<HighchartsChart | null>(null);
  const saveChartRef = useCallback((ch: HighchartsChart) => setChart(ch), []);

  const applyColumnHover = useStackedColumnHover();
  const applyXAxisOffset = useXAxisOffset();

  const { currency, values, totals, months, usedCategories } = data;

  const orderedCategories = useMemo(
    () => (usedCategories ? [...usedCategories.positive, ...usedCategories.negative, SERIES_ID_TOTAL] : []),
    [usedCategories]
  );

  const coloredSeries = usePositiveNegativeColoredData({ values, usedCategories });
  const coloredSeriesWithTotal = useMemo(
    () => [
      ...coloredSeries,
      {
        id: SERIES_ID_TOTAL,
        type: 'line',
        color: palette.core.color6,
        lineWidth: 2,
        name: t('CATEGORY.INVOICED_SPEND'),
        data: totals,
        clip: false,
      },
    ],
    [coloredSeries, palette, t, totals]
  );

  const [filteredSeriesIds, setFilteredSeriesIds] = useState(orderedCategories);
  const filteredSeries = useMemo(
    () => coloredSeriesWithTotal?.filter((value) => filteredSeriesIds.includes(value.id)),
    [coloredSeriesWithTotal, filteredSeriesIds]
  );

  const optionsOverrides = useMemo(
    () =>
      buildOptions([
        applyColumnHover,
        applyXAxisOffset,
        (options) =>
          mergeOptions(
            {
              chart: { type: 'column' },
              plotOptions: {
                series: {
                  marker: {
                    enabled: false,
                    states: {
                      hover: { fillColor: palette.core.color6 },
                    },
                  },
                },
              },
              yAxis: {
                labels: {
                  formatter: ({ value }) => {
                    const formattedValue = formatCurrency(value as number, {
                      currency,
                      maximumFractionDigits: MAXIMUM_FRACTION_DIGITS_DYNAMIC,
                      plusSign: true,
                      compact: true,
                      withSpace: true,
                    });

                    const styles = { ...yLabelStyles(palette) } as { [arg: string]: number | string };
                    if (+value > 0) {
                      styles.color = palette.error.color1;
                    }
                    if (+value < 0) {
                      styles.color = palette.success.color1;
                    }
                    return `<span style="${cssObjectToString(styles)}">${formattedValue}</span>`;
                  },
                },
                plotLines: [{ value: 0, width: 1, color: palette.core.color6, zIndex: 3 }],
              },
              xAxis: {
                categories: months,
                labels: { formatter: monthFormatter },
              },
              tooltip: { shared: true },
              series: getSeriesWithBorderRadius(filteredSeries, 8) as SeriesOptionsType[],
            },
            options
          ),
      ]),
    [applyColumnHover, applyXAxisOffset, palette, months, filteredSeries, formatCurrency, currency]
  );
  const options = useStyledHighcharts(optionsOverrides);

  useEffect(() => {
    if (chart) {
      // This assures the series are rendered in the required order and not cached by HighChart
      chart.update({ ...options, series: [] }, false, true);
      chart.update(options, true, true);
    }
  }, [options, chart]);

  const legendItems = extractLegendItemsFromSeries(
    { series: coloredSeriesWithTotal } as Highcharts.Options,
    orderedCategories
  );
  const tooltipSort = sortTooltipPointsFunction(orderedCategories);
  const tooltipExtractor = extractTooltipContent(
    tooltipSort,
    currencyFormatter(locale, currency, AWS_DEFAULT_CURRENCY_DECIMAL_PLACES)
  );

  return (
    <Stack gap={1.5}>
      {legendItems.length > 0 && (
        <Box alignSelf="flex-end" px={6}>
          <Legend
            items={legendItems}
            filters={filteredSeriesIds}
            onFilterChange={setFilteredSeriesIds}
            color={AWS_BRAND_COLOR}
          />
        </Box>
      )}
      <HighchartsReact highcharts={Highcharts} options={options} callback={saveChartRef} />
      <HighchartTooltip chart={chart}>
        {(context) => {
          const ctxWithoutTotalSeries = {
            ...context,
            points: context.points?.filter(
              (point) =>
                !point.series.userOptions?.id ||
                // We need to check the prefix because `getSeriesWithBorderRadius` creates permutations like
                // `__total-top`, `__total-bottom`, `__total-top-bottom` and so on
                !point.series.userOptions.id.startsWith(SERIES_ID_TOTAL)
            ),
          };
          const tooltipContent = tooltipExtractor(ctxWithoutTotalSeries);
          return (
            <TooltipWrapper>
              {tooltipContent.items?.map((item: TooltipItem, index: number) => (
                <TooltipSeriesValuePair
                  seriesColor={item.color}
                  seriesName={item.name}
                  value={item.value}
                  key={item.name || index}
                />
              ))}
              <TooltipTotal value={tooltipContent.total} />
            </TooltipWrapper>
          );
        }}
      </HighchartTooltip>
    </Stack>
  );
};

export default SpendByTransactionTypeGraph;
