import IconButton, { IconButtonProps } from '../../IconButton';
import { useDataGridContext } from '../DataGridContext';

type Props = Omit<IconButtonProps, 'size'>;

export const GridIconButton = (props: Props) => {
  const { size } = useDataGridContext();

  return <IconButton {...props} size={size} />;
};
