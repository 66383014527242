import React, { useEffect, useRef } from 'react';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { isNil } from 'lodash';

/**
 * Tries to scroll to the specified row immediately after the DataGrid is rendered for the first time.
 * @param apiRef
 * @param scrollTargetIdGetter A function that returns the id of the row to scroll to.
 */
const useInitialScroll = (
  apiRef: React.MutableRefObject<GridApiPro>,
  scrollTargetIdGetter: (sortedIds: string[]) => string | undefined
) => {
  const initiallyScrolledRef = useRef(false);
  useEffect(() => {
    setTimeout(() => {
      if (!apiRef?.current.getRowElement || initiallyScrolledRef.current) {
        return;
      }

      const scrollTargetId = scrollTargetIdGetter(apiRef.current?.state.sorting.sortedRows as string[]);
      if (isNil(scrollTargetId)) {
        return;
      }
      const element = apiRef?.current?.getRowElement(scrollTargetId);

      if (!element) {
        console.warn("Couldn't find a row element to scroll to");
        return;
      }
      element?.scrollIntoView({ behavior: 'smooth' });
      initiallyScrolledRef.current = true;
    }, 500);
  }, [apiRef, scrollTargetIdGetter]);

  useEffect(() => {
    return () => {
      // Scroll back to the top of the main element when leaving the scrolled page
      const mainElements = window.document.getElementsByTagName('main');
      if (mainElements.length) {
        mainElements[0].scrollTo(0, 0);
      }
    };
  }, []);
};

export default useInitialScroll;
