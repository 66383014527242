import { useMemo, useState } from 'react';
import { Box } from '@mui/material';

import { useGetAccountIntegrationQuery } from '@vertice/slices';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import Loader from '@verticeone/design-system/src/components/Loader';
import { IntegrationSetup } from './IntegrationSetup';
import { SLACK_INTEGRATION_ID, STEPS } from '../common';
import { InitialStep } from './Steps';
import { ConnectionStep } from './Steps';

export const SlackSetupWizardV2 = () => {
  const { accountId } = useAccountContext();

  const [step, setStep] = useState(STEPS.START);
  const { data, isLoading } = useGetAccountIntegrationQuery(
    { accountId: accountId, integrationId: SLACK_INTEGRATION_ID },
    { skip: !accountId }
  );

  const effectiveStep = useMemo(() => {
    if (step === STEPS.START && data?.status === 'ACTIVE') {
      return STEPS.CONNECTION;
    }
    return step;
  }, [step, data?.status]);

  if (isLoading) {
    return (
      <Box sx={{ height: '100%' }}>
        <Loader />
      </Box>
    );
  }

  return (
    <IntegrationSetup activeStep={effectiveStep} accountId={accountId}>
      <InitialStep stepValue={STEPS.START} setStep={setStep} accountId={accountId} />
      <ConnectionStep stepValue={STEPS.CONNECTION} accountId={accountId} />
    </IntegrationSetup>
  );
};
