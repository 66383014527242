import { createContext, useContext } from 'react';

export type TaskFormContextType = {
  readOnly: boolean;
};

const TaskFormContext = createContext<TaskFormContextType>({
  readOnly: false,
});

const useTaskFormContext = () => {
  return useContext(TaskFormContext);
};

const TaskFormContextProvider = TaskFormContext.Provider;

export { useTaskFormContext, TaskFormContextProvider };
