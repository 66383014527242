import { Box, Palette, Stack, styled, useTheme } from '@mui/material';

import { Color } from '@verticeone/design-system/src/theme/palette/types';
import Text from '@verticeone/design-system/src/components/Text';
import { useFormatPercentage } from '@vertice/core/src/utils/formatting/percentage';

type ProgressBarProps = {
  utilization?: number;
  hasSeatBasedLicense?: boolean;
};

type StyledFillProps = {
  backgroundColor: Color;
};

enum UtilizationCategories {
  LOW = 'LOW',
  AVERAGE = 'AVERAGE',
  HIGH = 'HIGH',
  OVERUTILIZED = 'OVERUTILIZED',
  ESTIMATED = 'ESTIMATED',
}

const StyledFill = styled(Box)<StyledFillProps>(({ backgroundColor }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: '20px',
  borderRadius: '4px',
  padding: '4px',
  backgroundColor,
}));

const getUtilizationCategory = (utilization: number, hasSeatBasedLicense: boolean): UtilizationCategories => {
  if (utilization === 0 || !hasSeatBasedLicense) return UtilizationCategories.ESTIMATED;
  if (utilization > 1) return UtilizationCategories.OVERUTILIZED;
  return [UtilizationCategories.LOW, UtilizationCategories.AVERAGE, UtilizationCategories.HIGH][
    Math.min(2, Math.floor(utilization / 0.33))
  ];
};

const getFillColor = (utilization: number, hasSeatBasedLicense: boolean, palette: Palette): Color => {
  const utilizationCategory = getUtilizationCategory(utilization, hasSeatBasedLicense);

  switch (utilizationCategory) {
    case UtilizationCategories.LOW:
      return palette.error.color2;
    case UtilizationCategories.AVERAGE:
      return palette.warning.color2;
    case UtilizationCategories.HIGH:
      return palette.success.color2;
    case UtilizationCategories.OVERUTILIZED:
      return palette.info.color2;
    case UtilizationCategories.ESTIMATED:
    default:
      return palette.neutral.color2;
  }
};

const TINY_UTILIZATION_TRESHOLD = 0.005;

const getFlexBasis = (utilization: number, formatPercentage: ReturnType<typeof useFormatPercentage>) => {
  const BAR_SIZE_XXS = 0.18;
  const BAR_SIZE_XS = 0.15;
  const BAR_SIZE_S = 0.19;

  if (utilization < 0.005) return formatPercentage(BAR_SIZE_XXS);
  if (utilization < 0.1) return formatPercentage(BAR_SIZE_XS);
  if (utilization < BAR_SIZE_S) return formatPercentage(BAR_SIZE_S);
  if (utilization >= 1) return formatPercentage(1);
  return formatPercentage(utilization, { maximumFractionDigits: 0 });
};

const ProgressBar = ({ utilization = 0, hasSeatBasedLicense = false }: ProgressBarProps) => {
  const { palette } = useTheme();
  const formatPercentage = useFormatPercentage();

  return (
    <Stack direction="row" gap={0.5}>
      {utilization > 0 && (
        <StyledFill
          flexBasis={getFlexBasis(utilization, formatPercentage)}
          backgroundColor={getFillColor(utilization, hasSeatBasedLicense, palette)}
        >
          <Text variant="caption" size="S" color={palette.text.color5} position="absolute">
            {utilization < TINY_UTILIZATION_TRESHOLD
              ? `<${formatPercentage(0.01, { maximumFractionDigits: 0 })}`
              : formatPercentage(utilization, { maximumFractionDigits: 0 })}
          </Text>
        </StyledFill>
      )}
      {utilization < 1 && (
        <StyledFill
          flexGrow={1}
          backgroundColor={getFillColor(utilization, hasSeatBasedLicense, palette)}
          sx={{ opacity: 0.2 }}
        ></StyledFill>
      )}
    </Stack>
  );
};

export default ProgressBar;
