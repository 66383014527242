import React, { type ChangeEvent, type FocusEvent, useState } from 'react';
import { type GridCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import TextField from '@verticeone/design-system/src/components/TextField';

const pattern = /^[0-9]*$/;

type DataGridQuantityCellProps = GridCellParams & {
  onChange?: (quantity: number) => void;
};

const QuantityField = ({ id, value, field, onChange }: DataGridQuantityCellProps) => {
  const apiRef = useGridApiContext();
  const [quantity, setQuantity] = useState<number>(Number(value));

  const handleValueChange = (newValue: string, isOnBlur?: boolean) => {
    const numericValue = !newValue || isNaN(+newValue) ? null : +newValue;
    void apiRef.current.setEditCellValue({ id, field, value: numericValue });

    if (numericValue) {
      setQuantity(Number(numericValue));
      onChange?.(Number(numericValue));
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (pattern.test(e.target.value)) {
      handleValueChange(e.target.value);
    }
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (e.target.value !== quantity.toString()) {
      handleValueChange(quantity.toString(), true);
    }
  };

  return (
    <TextField
      type="text"
      value={value === null ? '' : value}
      variant="outlined"
      hiddenLabel
      onChange={handleChange}
      onBlur={handleBlur}
      InputProps={{
        inputProps: {
          min: 0,
          inputMode: 'numeric',
          pattern: '[0-9]*',
        },
      }}
    />
  );
};

export default QuantityField;
