import React from 'react';
import { useTranslation } from 'react-i18next';
import NoData from '@verticeone/design-system/src/components/NoData';

const NoRowsOverlay = () => {
  const { t } = useTranslation();

  return (
    <NoData
      icon={null}
      header={t('ENTITIES.CONTRACT_CUSTOMIZATION.NO_CUSTOM_FIELDS')}
      content={t(`ENTITIES.CONTRACT_CUSTOMIZATION.NO_CUSTOM_FIELDS_HINT`)}
    />
  );
};

export default NoRowsOverlay;
