import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IntegrationSuccess } from '@vertice/assets';
import Button from '@verticeone/design-system/src/components/Button';
import { StepProps, SLACK_INTEGRATION_ID } from '../../common';
import IntegrationStatus from 'pages/Preferences/Integrations/components/IntegrationStatus';
import WizardStepTemplate from 'pages/Preferences/Integrations/components/WizardStepTemplate';
import { useActivateIntegrationAndProvider } from 'pages/Preferences/Integrations/util';

export const ConnectionSuccessStep = ({ accountId }: StepProps) => {
  const { t } = useTranslation();

  const { activate } = useActivateIntegrationAndProvider(SLACK_INTEGRATION_ID, '', accountId);

  const navigate = useNavigate();

  const onFinish = () => {
    void activate().then(() => navigate(-1));
  };

  return (
    <WizardStepTemplate
      content={
        <IntegrationStatus
          icon={<IntegrationSuccess />}
          title={t('PREFERENCES.INTEGRATIONS.SLACK.WIZARD.SETUP_SUCCESSFUL')}
        />
      }
      buttons={[
        <Button variant="solid" color="primary" size="S" isCaption onClick={onFinish}>
          {t('COMMON.FINISH')}
        </Button>,
      ]}
      scrollable={false}
    />
  );
};
