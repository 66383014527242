import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, useTheme } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';

const NoRowsOverlay = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  return (
    <Stack height="100%" alignItems="center" justifyContent="center" bgcolor={palette.core.color2}>
      <Text variant="body-regular" size="M" color="text2">
        {t('ENTITIES.DEPARTMENT.NO_DEPARTMENTS')}
      </Text>
    </Stack>
  );
};

export default NoRowsOverlay;
