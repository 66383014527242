import { Stack, styled, useTheme } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import IconWrapper from '@verticeone/design-system/src/components/IconWrapper';
import { CardGridColDef, CardGridSortDirection, CardGridSortItem, CardGridSortModel, ValidRowModel } from './types';
import { Sort } from '@mui/icons-material';
import { useState } from 'react';

const StyledCardGridHeaderColumn = styled(Stack)<{ $flex: number; $sortable?: boolean }>(
  ({ theme, $flex = 1, $sortable }) => ({
    padding: 16,
    gap: 8,
    flex: $flex,
    flexDirection: 'row',
    overflow: 'hidden',
    minWidth: 0,
    alignItems: 'center',
    cursor: $sortable ? 'pointer' : 'default',
    marginBottom: theme.spacing(2),
    '&:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.core.color3}`,
    },
    borderBottom: `1px solid ${theme.palette.core.color3}`,
  })
);

const StyledSortIcon = styled(IconWrapper)<{ $direction: CardGridSortDirection; $visible: boolean }>(
  ({ $direction, $visible }) => ({
    transform: `scaleY(${$direction === 'desc' ? '1' : '-1'})`,
    opacity: $visible ? 1 : 0,
  })
);

type CardGridHeaderColumnProps<R extends ValidRowModel> = {
  column: CardGridColDef<R>;
  sortModel: CardGridSortModel;
  onSortChange: (sortModel: CardGridSortModel) => void;
};

const defaultSortingOrder: CardGridSortDirection[] = ['asc', 'desc'];

export const CardGridHeaderColumn = <R extends ValidRowModel>({
  column,
  sortModel,
  onSortChange,
}: CardGridHeaderColumnProps<R>) => {
  const { palette } = useTheme();

  const isSortable = column.sortable ?? true;
  const appliedSort = sortModel.find((sort) => sort.field === column.field);

  const sortingOrder = column.sortingOrder || defaultSortingOrder;
  const nextSortDirection: CardGridSortDirection = appliedSort
    ? sortingOrder[(sortingOrder.indexOf(appliedSort.sort) + 1) % sortingOrder.length]
    : sortingOrder[0];
  const nextSort: CardGridSortItem = { field: column.field, sort: nextSortDirection };

  const handleSortChange = () => {
    if (!isSortable) return;
    onSortChange([nextSort]);
  };

  const [isHovered, setIsHovered] = useState(false);

  return (
    <StyledCardGridHeaderColumn
      $flex={column.flex}
      onClick={handleSortChange}
      $sortable={isSortable}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      role="columnheader" /* <- for testing & a11y */
      data-field={column.field} /* <- for testing */
    >
      <Text variant="label" size="S">
        {column.renderHeader ? column.renderHeader({ field: column.field }) : column.headerName}
      </Text>
      <StyledSortIcon
        icon={Sort}
        $direction={appliedSort?.sort || nextSort?.sort}
        $visible={!!appliedSort || (isHovered && isSortable)}
        htmlColor={palette.core.color5}
      />
    </StyledCardGridHeaderColumn>
  );
};
