import React from 'react';
import NoData from '@verticeone/design-system/src/components/NoData';
import { useTranslation } from 'react-i18next';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import IconWrapper from '@verticeone/design-system/src/components/IconWrapper';

const NoRows = () => {
  const { t } = useTranslation();

  return (
    <NoData
      icon={<IconWrapper size="XL" color="error" icon={DocumentScannerOutlinedIcon} />}
      header={t(`SAAS.CONTRACT_LIST.NO_DATA`)}
    />
  );
};

export default NoRows;
