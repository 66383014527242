import { useTranslation } from 'react-i18next';

import Text from '@verticeone/design-system/src/components/Text';

type ActivityFrequencyCellProps = {
  activityFrequency?: string | null;
};

const ActivityFrequencyCell = ({ activityFrequency }: ActivityFrequencyCellProps) => {
  const { t } = useTranslation();

  if (activityFrequency === undefined) {
    return null;
  }

  return (
    <Text variant="body-regular" size="M">
      {activityFrequency === null
        ? t(`APPLICATIONS.USAGE.ACTIVITY_FREQUENCY.BINS.NO_DATA`)
        : t(`APPLICATIONS.USAGE.ACTIVITY_FREQUENCY.BINS.${activityFrequency}`)}
    </Text>
  );
};

export default ActivityFrequencyCell;
