import { Dialog, DialogHeader, DialogContent } from '@verticeone/design-system/src/components/Dialog';
import Text from '@verticeone/design-system/src/components/Text';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import Button from '@verticeone/design-system/src/components/Button';
import { VerticeVWhiteIcon } from '@verticeone/design-system/src/assets';
import Divider from '@verticeone/design-system/src/components/Divider';
import ChipButton from '@verticeone/design-system/src/components/ChipButton';
import { IntelligentWorkflowsProductIcon } from '../../components/icons/Icons';
import { Link } from 'react-router-dom';

type InitiateRequestBetaDialogProps = {
  isOpened: boolean;
  onClose: () => void;
  onIWRequestClick: () => void;
  // either handler or route should be provided
  legacyRequestLinkRoute?: string;
  onLegacyRequestClick?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  requestType: 'RENEWAL' | 'PURCHASE';
};

// Interim modal for beta customers of intelligent workflows
export const InitiateRequestBetaDialog: FC<InitiateRequestBetaDialogProps> = ({
  isOpened,
  onClose,
  onIWRequestClick,
  onLegacyRequestClick,
  legacyRequestLinkRoute,
  isLoading,
  isDisabled,
  requestType,
}) => {
  const { t } = useTranslation();

  const buttonsDisabled = useMemo(() => isDisabled || isLoading, [isDisabled, isLoading]);

  return (
    <Dialog open={isOpened} onClose={onClose} size="M">
      <DialogHeader>{t('INTELLIGENT_WORKFLOWS.BETA_REQUEST_MODAL.LABELS.HEADING')}</DialogHeader>
      <Divider />
      <DialogContent>
        <Stack gap={3}>
          {legacyRequestLinkRoute && (
            <Button
              isLoading={isLoading}
              variant="solid"
              color="primary"
              disabled={buttonsDisabled}
              component={Link}
              to={legacyRequestLinkRoute}
            >
              <VerticeVWhiteIcon />
              <Text variant="button">
                {t(`INTELLIGENT_WORKFLOWS.BETA_REQUEST_MODAL.LABELS.${requestType}_WITH_VERTICE`)}
              </Text>
            </Button>
          )}
          {onLegacyRequestClick && (
            <Button
              isLoading={isLoading}
              variant="solid"
              color="primary"
              disabled={buttonsDisabled}
              onClick={onLegacyRequestClick}
            >
              <VerticeVWhiteIcon />
              <Text variant="button">
                {t(`INTELLIGENT_WORKFLOWS.BETA_REQUEST_MODAL.LABELS.${requestType}_WITH_VERTICE`)}
              </Text>
            </Button>
          )}
          <Text variant="body-regular" size="XS" color="text2">
            {t(`INTELLIGENT_WORKFLOWS.BETA_REQUEST_MODAL.DESCRIPTIONS.WITH_VERTICE`)}
          </Text>
          <Divider>
            <Text variant="label" color="text3" size="XXS">
              {t('INTELLIGENT_WORKFLOWS.BETA_REQUEST_MODAL.LABELS.OR')}
            </Text>
          </Divider>
          <Button
            variant="outline"
            color="neutral"
            isLoading={isLoading}
            onClick={onIWRequestClick}
            disabled={buttonsDisabled}
          >
            <Text variant="button">
              {t(`INTELLIGENT_WORKFLOWS.BETA_REQUEST_MODAL.LABELS.${requestType}_WITHOUT_VERTICE`)}
            </Text>
            <ChipButton size="XS" color="success" variant="ghost">
              <IntelligentWorkflowsProductIcon />
              {t('INTELLIGENT_WORKFLOWS.BETA_REQUEST_MODAL.LABELS.BETA')}
            </ChipButton>
          </Button>
          <Text variant="body-regular" size="XS" color="text2">
            {t(`INTELLIGENT_WORKFLOWS.BETA_REQUEST_MODAL.DESCRIPTIONS.WITHOUT_VERTICE`)}
          </Text>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
