import React from 'react';
import styled from '@mui/material/styles/styled';
import MuiTableCell, { type TableCellProps as MuiTableCellProps } from '@mui/material/TableCell/TableCell';
import { type Theme } from '@mui/material/styles';
import type { DesignSystemSize } from '../../types';
import { useContext } from 'react';
import { TableContext } from './TableContext';
import { tableCellSpacing } from '../../utils/css/tableSharedStyles';

export type TableCellProps = Omit<MuiTableCellProps, 'size'> & {
  size?: DesignSystemSize;
  dynamicHeight?: boolean;
};

type StyledTableCellProps = {
  theme: Theme;
  $size: DesignSystemSize;
  $dynamicHeight: boolean;
};

export const TableCellWrapper = styled(MuiTableCell)<StyledTableCellProps>(({ theme, $size, $dynamicHeight }) => ({
  height: $dynamicHeight ? 'auto' : theme.spacing(tableCellSpacing[$size].height),
  paddingLeft: theme.spacing(tableCellSpacing[$size].padding),
  paddingRight: theme.spacing(tableCellSpacing[$size].padding),
  paddingTop: 0,
  paddingBottom: 0,
  verticalAlign: 'middle',
  border: 'none',
}));

const TableCell = ({ children, size, dynamicHeight, ...props }: TableCellProps) => {
  const context = useContext(TableContext);

  return (
    <TableCellWrapper $size={size ?? context.size} $dynamicHeight={!!dynamicHeight} {...props}>
      {children}
    </TableCellWrapper>
  );
};

export default TableCell;
