import React from 'react';
import MuiDialog, { dialogClasses } from '@mui/material/Dialog';
import styled from '@mui/material/styles/styled';
import { type DialogContextProps, DialogProvider } from './DialogContext';
import type { DesignSystemSize, TestProps } from '../../types';
import { testProps } from '../../utils/testProperties';

const widthsBySize: Record<DesignSystemSize, number> = {
  XL: 600,
  L: 540,
  M: 480,
  S: 420,
  XS: 360,
  XXS: 300,
};

type DialogProps = TestProps & {
  onClose?: () => void;
  children: React.ReactNode;
  width?: number; // override with derived from size
  fullHeight?: boolean;
} & DialogContextProps;

export const StyledDialog = styled(MuiDialog)<{ $size: DesignSystemSize; $width?: number }>(
  ({ theme, $size, $width }) => ({
    [`.${dialogClasses.paper}`]: {
      borderRadius: theme.spacing(4),
      border: `1px solid ${theme.palette.core.color3}`,
      width: $width || widthsBySize[$size as DesignSystemSize],
    },
  })
);

export const Dialog = ({
  children,
  open,
  onClose,
  setOpen,
  size = 'M',
  width,
  fullHeight,
  align = 'left',
  testId,
}: DialogProps) => (
  <StyledDialog
    open={open}
    onClose={onClose}
    $size={size}
    $width={width}
    {...testProps(testId, 'dialogOverlay')}
    PaperProps={{
      ...testProps(undefined, 'dialog'),
      ...{ sx: { minHeight: fullHeight ? '80vh' : undefined, maxWidth: 'none' } },
    }}
  >
    <DialogProvider value={{ open, setOpen, size, align }}>{children}</DialogProvider>
  </StyledDialog>
);
