import { default as MuiMenu, menuClasses, MenuProps as MuiMenuProps } from '@mui/material/Menu';
import { paperClasses } from '@mui/material/Paper';
import styled from '@mui/material/styles/styled';
import { FC, useMemo } from 'react';
import { MenuContextProvider } from './MenuContext';
import { MenuColor } from './types';
import MenuItem from './MenuItem';
import { TestProps } from '../../types';
import { testProps } from '../../utils/testProperties';

const StyledMenu = styled(MuiMenu)(({ theme }) => ({
  [`& .${paperClasses.root}`]: {
    border: `1px solid ${theme.palette.core.color3}`,
    boxShadow: theme.palette.global.getShadow({ color: 'core', type: 'soft', depth: '1z', distance: '60' }),
    marginTop: theme.spacing(1),
  },
  [`& .${menuClasses.list}`]: {
    padding: 0,
  },
}));

type MenuProps = MuiMenuProps &
  TestProps & {
    color?: MenuColor;
    width?: number;
    isLoading?: boolean;
    loadingItemsCount?: number;
  };

const DEFAULT_LOADING_WIDTH = 200;

const Menu: FC<MenuProps> = ({
  color = 'neutral',
  isLoading,
  width,
  slotProps,
  loadingItemsCount = 10,
  testId,
  ...restProps
}) => {
  const contextValue = useMemo(() => ({ color }), [color]);

  const slotPropsWithMenuWidth = {
    ...slotProps,
    paper: {
      ...slotProps?.paper,
      sx: {
        width: isLoading ? width || DEFAULT_LOADING_WIDTH : width, // if loading and with is not set then set default width
      },
    },
  };

  const testProperties = { ...testProps(testId, 'menu') };

  return (
    <MenuContextProvider value={contextValue}>
      {isLoading ? (
        <StyledMenu {...restProps} slotProps={slotPropsWithMenuWidth} {...testProperties}>
          {Array.from({ length: loadingItemsCount }, (_, i) => i).map((i) => (
            <MenuItem key={i} isLoading />
          ))}
        </StyledMenu>
      ) : (
        <StyledMenu {...restProps} slotProps={slotPropsWithMenuWidth} {...testProperties} />
      )}
    </MenuContextProvider>
  );
};

export default Menu;
