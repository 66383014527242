import React from 'react';
import NavigationDropdown from '../../../../layouts/Main/Sidebar/components/NavigationDropdown';
import DashboardIcon from '@mui/icons-material/DashboardTwoTone';
import NavigationItem from '../../../../layouts/Main/Sidebar/components/NavigationItem';
import WorkIcon from '@mui/icons-material/WorkTwoTone';
import AssessmentIcon from '@mui/icons-material/AssessmentTwoTone';
import StorefrontIcon from '@mui/icons-material/StorefrontTwoTone';
import NavigationGroup from '../../../../layouts/Main/Sidebar/components/NavigationGroup';
import { useTranslation } from 'react-i18next';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { useMainLayout } from '../../../../layouts/Main/LayoutWrapper';
import useSavingsDashboard from '../../savings/useSavingsDashboard';
import { ReactComponent as SaasNavbarIcon } from './navbarIcon.svg';
import { useRoutes } from '@verticeone/router/src';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import useLoggedUserAccountRoles from '../../../../hooks/useLoggedUserAccountRoles';

const SaasNavbarItems = () => {
  const { t } = useTranslation();
  const { isEnabled } = useFeatures();
  const { isOpen: isNavbarExpanded } = useMainLayout();
  const routes = useRoutes();

  const { isUserAdmin, isUserPowerUser } = useLoggedUserAccountRoles();
  const isDashboardVisible = isUserAdmin || isUserPowerUser;
  const { enabled: isSavingsDashboardVisible } = useSavingsDashboard();
  const applicationsVisible = Boolean(isUserAdmin && isEnabled(FEATURES.USAGE_ANALYTICS));

  return (
    <NavigationGroup
      label={isNavbarExpanded ? t('SAAS.NAVBAR.SAAS_PURCHASING') : t('SAAS.NAVBAR.SAAS')}
      icon={SaasNavbarIcon}
    >
      <NavigationDropdown
        to={routes.DASHBOARD.ABSOLUTE_PATH}
        icon={DashboardIcon}
        isVisible={isDashboardVisible}
        label={t('SAAS.NAVBAR.DASHBOARD')}
      >
        <NavigationItem
          to={routes.DASHBOARD.SAVINGS.ABSOLUTE_PATH}
          isVisible={isSavingsDashboardVisible}
          label={t('SAAS.NAVBAR.DASHBOARD_SAVINGS')}
        />
      </NavigationDropdown>

      <NavigationItem
        to={routes.CONTRACTS.ABSOLUTE_PATH}
        match={[routes.CONTRACTS.DETAIL.ABSOLUTE_PATH]}
        icon={WorkIcon}
        label={t('SAAS.NAVBAR.CONTRACTS')}
      />
      <NavigationItem
        to={routes.APPLICATIONS.ABSOLUTE_PATH}
        match={[routes.APPLICATIONS.USAGE_DETAIL.ABSOLUTE_PATH]}
        icon={AssessmentIcon}
        isVisible={applicationsVisible}
        label={t('SAAS.NAVBAR.APPLICATIONS')}
      />
      <NavigationItem
        to={routes.VENDORS.ABSOLUTE_PATH}
        match={[routes.VENDORS.VENDOR.ABSOLUTE_PATH]}
        icon={StorefrontIcon}
        label={t('SAAS.NAVBAR.VENDORS')}
      />
    </NavigationGroup>
  );
};

export default SaasNavbarItems;
