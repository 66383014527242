import React, { useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ComputeUsageGraph from './ComputeUsageGraph';
import useComputeUsageData from './useComputeUsageData';
import Tab from '@verticeone/design-system/src/components/Tab';
import Tabs from '@verticeone/design-system/src/components/Tabs';
import { LoadableContentWrapper } from '../../components/LoadableContentWrapper';
import Card, {
  CardHeader,
  CardHeaderDescription,
  CardHeaderSubtitle,
  CardHeaderTitle,
} from '@verticeone/design-system/src/components/Card';
import { CalendarTodayOutlined } from '@mui/icons-material';
import TabsBottomLineWrapper from '@verticeone/design-system/src/components/Tabs/TabsBottomLineWrapper';
import { AWS_BRAND_COLOR } from '../../constants';

enum ComputeUsageCardTab {
  BY_PRODUCT = 'product',
  BY_PURCHASE_OPTION = 'purchase_option',
}

const ComputeUsageCard = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { palette } = useTheme();
  const [activeTab, setActiveTab] = useState(ComputeUsageCardTab.BY_PRODUCT);
  const [isNormalized, setIsNormalized] = useState(false);
  const { data, ...loadingStatus } = useComputeUsageData({ groupBy: activeTab, normalize: isNormalized });

  return (
    <Card>
      <CardHeader size="S" noDivider>
        <CardHeaderTitle text={t('COMPUTE_USAGE.TITLE')} tag="h2" />
        <CardHeaderSubtitle text={t('LAST_X_MONTHS', { monthCount: 12 })} icon={CalendarTodayOutlined} />
        <CardHeaderDescription>{t('COMPUTE_USAGE.DESCRIPTION')}</CardHeaderDescription>
      </CardHeader>
      <TabsBottomLineWrapper paddingX={6}>
        <Tabs variant="outlined" value={activeTab} onChange={(_, value) => setActiveTab(value)} color={AWS_BRAND_COLOR}>
          <Tab value={ComputeUsageCardTab.BY_PRODUCT} label={t('COMPUTE_USAGE.BY_PRODUCT_TAB')} />
          <Tab value={ComputeUsageCardTab.BY_PURCHASE_OPTION} label={t('COMPUTE_USAGE.BY_PURCHASE_OPTION_TAB')} />
        </Tabs>
      </TabsBottomLineWrapper>
      <Box bgcolor={palette.core.color1}>
        <LoadableContentWrapper {...loadingStatus} pt="22px" pb={6}>
          <ComputeUsageGraph data={data!} isNormalized={isNormalized} setIsNormalized={setIsNormalized} />
        </LoadableContentWrapper>
      </Box>
    </Card>
  );
};

export default ComputeUsageCard;
