import React, { FC, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Stack } from '@mui/material';

import NoData from '@verticeone/design-system/src/components/NoData';
import DataGrid, { DataGridProps } from '@verticeone/design-system/src/components/DataGrid';
import Text from '@verticeone/design-system/src/components/Text';
import ChipButton from '@verticeone/design-system/src/components/ChipButton';

import { IntelligentWorkflowsLayout } from '../../components/IntelligentWorkflowsLayout';
import { useWorkflowVersions } from './useWorkflowVersions';
import { useWorkflowVersionColumns } from './useWorkflowVersionColumns';
import { WorkflowVersionRow } from './types';
import { useWorkflowVersionDraftColumns } from './useWorkflowVersionDraftColumns';
import { WorkflowVersionSchema } from './WorkflowVersionSchema';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { SetAsDefaultButton } from './SetAsDefaultButton';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useListServiceCatalogQuery } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useIsDefaultWorkflow } from './utils';

const Section = (props: { label: string; testId: string; children: ReactNode }) => {
  return (
    <Stack component="section" gap={4} data-testid={props.testId}>
      <Text variant="label" size="XS" sx={{ paddingLeft: 6 }}>
        {props.label}
      </Text>
      {props.children}
    </Stack>
  );
};

export const WorkflowDetailPage: FC = () => {
  const { t } = useTranslation();
  const params = useParams() as { workflowId: string };

  const { workflowVersions, isLoadingWorkflowVersions, activeVersion } = useWorkflowVersions(params.workflowId);

  const { accountId } = useAccountContext();
  const { data: servicesData } = useListServiceCatalogQuery({
    accountId,
  });
  const service = servicesData?.items?.find((s) => s.urn === activeVersion?.serviceRef);

  const isDefault = useIsDefaultWorkflow(activeVersion, service);

  const columns = useWorkflowVersionColumns();
  const draftColumns = useWorkflowVersionDraftColumns();
  const commonDataGridProps: Partial<DataGridProps> = useMemo(
    () => ({
      loadingStyle: 'skeleton',
      loading: isLoadingWorkflowVersions,
      loadingRowCount: 1,
      color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
      sortingMode: 'client',
      disableRowSelectionOnClick: true,
      getRowHeight: () => 60,
      autoHeight: true,
      noRowsOverlayHeight: 120,
      getRowId: (row: WorkflowVersionRow) => `${row.id}-${row.versionId}`,
      slots: {
        noRowsOverlay: () => <NoData header={t(`INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.NO_WORKFLOWS`)} />,
      },
      initialState: {
        sorting: {
          sortModel: [
            { field: 'versionName', sort: 'desc' },
            { field: 'workflowName', sort: 'desc' },
          ],
        },
      },
    }),
    [isLoadingWorkflowVersions, t]
  );

  const firstDraft = workflowVersions['PENDING']?.[0];

  return (
    <IntelligentWorkflowsLayout
      heading={
        <Stack>
          <Stack direction="row" gap={2} alignItems="center">
            {/*if there is no active version, use the first draft name in the heading*/}
            {activeVersion?.workflowName || firstDraft?.workflowName}
            {isDefault && (
              <ChipButton variant="ghost" color="success" size="S" isActive={false}>
                {t('ENTITIES.WORKFLOW.DEFAULT')}
              </ChipButton>
            )}
          </Stack>
          <Text variant="caption" size="S" color="text2">
            {service?.name}
          </Text>
        </Stack>
      }
      currentPageName={activeVersion?.workflowName || firstDraft?.workflowName}
      actions={<SetAsDefaultButton activeVersion={activeVersion} service={service} />}
      isLoading={isLoadingWorkflowVersions}
    >
      <Stack gap={8}>
        {activeVersion && (
          <Section label={t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.HEADERS.OVERVIEW')} testId="overview">
            <WorkflowVersionSchema activeVersion={activeVersion} />
          </Section>
        )}
        <Section label={t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.HEADERS.LIVE_VERSION')} testId="active">
          <DataGrid<WorkflowVersionRow>
            columns={columns}
            rows={workflowVersions['ACTIVE'] || []}
            {...commonDataGridProps}
          ></DataGrid>
        </Section>
        <Section label={t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.HEADERS.DRAFTS')} testId="drafts">
          <DataGrid<WorkflowVersionRow>
            columns={draftColumns}
            rows={workflowVersions['PENDING'] || []}
            {...commonDataGridProps}
          ></DataGrid>
        </Section>
        <Section label={t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.HEADERS.PREVIOUS_VERSIONS')} testId="previous">
          <DataGrid<WorkflowVersionRow>
            columns={columns}
            rows={workflowVersions['ARCHIVED'] || []}
            {...commonDataGridProps}
          ></DataGrid>
        </Section>
      </Stack>
    </IntelligentWorkflowsLayout>
  );
};
