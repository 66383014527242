import React from 'react';
import { useTheme } from '@mui/material';
import { TableCell } from '@verticeone/design-system/src/components/Table';
import Text from '@verticeone/design-system/src/components/Text';

type DetailPanelCellProps = {
  label: string;
  children: React.ReactNode;
  testId: string;
};

const DetailPanelCell = ({ label, children, testId }: DetailPanelCellProps) => {
  const { palette } = useTheme();

  return (
    <TableCell>
      <Text variant="caption" size="S" tag="div" color={palette.text.color3} data-testid={`${testId}-header`}>
        {label}
      </Text>
      <Text variant="body-regular" size="M" tag="div" data-testid={`${testId}-value`}>
        {children}
      </Text>
    </TableCell>
  );
};

export default DetailPanelCell;
