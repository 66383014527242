import { Stack, useTheme } from '@mui/material';
import DateField from '../DateField/DateField';
import IconWrapper from '../../../IconWrapper';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Dayjs } from 'dayjs';
import { useStaticDateRangePickerContext } from '../../context/StaticDateRangePickerContext';
import { useErrorMessage } from './utils';
import { useEffect, useState } from 'react';
import Text from '../../../Text';
import sizeDefinitions from '../../../TextField/styledVariants/sizeDefinitions';

type DateRangeInputProps = {
  dateFrom: Dayjs | null;
  dateTo: Dayjs | null;
  onChangeDateFrom: (value: Dayjs | null, context: { validationError: string | null }) => void;
  onChangeDateTo: (value: Dayjs | null, context: { validationError: string | null }) => void;
  alignItems?: 'flex-start' | 'center';
};

const DateRangeInput = ({
  dateFrom,
  dateTo,
  onChangeDateFrom,
  onChangeDateTo,
  alignItems = 'flex-start',
}: DateRangeInputProps) => {
  const { size, color, minDate, maxDate, dateFormat } = useStaticDateRangePickerContext();
  const [error, setError] = useState<string | undefined>();
  const { palette } = useTheme();
  const { getErrorMessage } = useErrorMessage();

  useEffect(() => {
    setError(undefined);
  }, []);

  const handleDateFromChange = (value: Dayjs | null, context: { validationError: string | null }) => {
    const errorMessage = getErrorMessage({
      error: context.validationError,
      type: 'from',
      dateFrom: minDate?.format(dateFormat ?? 'L'),
      dateTo: dateTo?.format(dateFormat ?? 'L'),
    });
    setError(errorMessage);

    onChangeDateFrom(value, context);
  };

  const handleDateToChange = (value: Dayjs | null, context: { validationError: string | null }) => {
    const errorMessage = getErrorMessage({
      error: context.validationError,
      type: 'to',
      dateFrom: dateFrom?.format(dateFormat ?? 'L'),
      dateTo: maxDate?.format(dateFormat ?? 'L'),
    });
    setError(errorMessage);

    onChangeDateTo(value, context);
  };

  return (
    <Stack gap={2} direction="column" alignItems={alignItems}>
      <Stack flex={0} direction="row" gap={2} alignItems="center">
        <DateField
          value={dateFrom}
          format={dateFormat ?? 'L'}
          name="start-date"
          minDate={minDate}
          maxDate={dateTo || undefined}
          onChange={handleDateFromChange}
          size={size}
          color={color}
          sx={{
            flex: 1,
            '.MuiInputBase-input': {
              textAlign: 'center',
            },
          }}
          inputProps={{
            size: 12,
          }}
        />
        <IconWrapper htmlColor={palette.core.color5} size={size} icon={ArrowForwardIcon} />
        <DateField
          value={dateTo}
          format={dateFormat ?? 'L'}
          minDate={dateFrom || undefined}
          maxDate={maxDate}
          name="end-date"
          onChange={handleDateToChange}
          size={size}
          color={color}
          sx={{
            flex: 1,
            '.MuiInputBase-input': {
              textAlign: 'center',
            },
          }}
          inputProps={{
            size: 12,
          }}
        />
      </Stack>
      {error && (
        <Text variant="body-bold" color="error1" size={sizeDefinitions[size].helperTextSize}>
          {error}
        </Text>
      )}
    </Stack>
  );
};

export default DateRangeInput;
