import React, { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type NavigationNode = {
  search?: string;
  label: string;
  id: string;
  type: 'root' | 'account' | 'service';
  loadingHeight?: string;
  children?: NavigationNode | null;
};

type CloudAccountsState = {
  node: NavigationNode;
  period: {
    periodOneStart: string;
    periodOneEnd: string;
    periodTwoStart: string;
    periodTwoEnd: string;
  };
  chartDataLimit: number;
  updatePeriod: (newPeriod: CloudAccountsState['period']) => void;
  removeNode: (node: NavigationNode) => void;
  addChildNode: (node: NavigationNode) => void;
  getLastNode: () => NavigationNode;
  setSearch: (search: string) => void;
};

const CloudAccountsContext = createContext<CloudAccountsState>({
  period: {
    periodOneStart: '',
    periodOneEnd: '',
    periodTwoStart: '',
    periodTwoEnd: '',
  },
  chartDataLimit: 0,
  updatePeriod: () => {},
  node: { label: '', id: '', type: 'root' },
  removeNode: () => {},
  addChildNode: () => {},
  setSearch: () => {},
  getLastNode: () => {
    throw new Error('getLastNode must be implemented');
  },
});

type CloudAccountsProviderProps = {
  children: React.ReactNode;
  period: CloudAccountsState['period'];
};

export const CloudAccountsProvider = ({ children, period }: CloudAccountsProviderProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.ACCOUNTS.TABLE' });
  const [node, setNode] = useState<NavigationNode>({
    label: t('BREADCRUMB.ACCOUNTS'),
    id: 'root',
    type: 'root',
  });
  const [localPeriod, setLocalPeriod] = useState(period);

  const removeNode = (remove: NavigationNode) => {
    const removeNodeFromLastNode = (currentNode: NavigationNode, nodeToRemove: NavigationNode): NavigationNode => {
      if (!currentNode.children) {
        return currentNode;
      }
      if (currentNode.children.id === nodeToRemove.id) {
        return { ...currentNode, children: undefined };
      }
      return { ...currentNode, children: removeNodeFromLastNode(currentNode.children, nodeToRemove) };
    };

    setNode((prevNode) => {
      if (!prevNode) {
        return prevNode;
      }
      return removeNodeFromLastNode(prevNode, remove);
    });
  };

  const addChildNode = (newChildNode: NavigationNode) => {
    const addChildToLastNode = (currentNode: NavigationNode, newNode: NavigationNode): NavigationNode => {
      if (!currentNode.children) {
        return { ...currentNode, children: newNode };
      } else {
        return { ...currentNode, children: addChildToLastNode(currentNode.children, newNode) };
      }
    };

    setNode((prevNode) => {
      if (!prevNode) {
        return newChildNode;
      }
      return addChildToLastNode(prevNode, newChildNode);
    });
  };

  const getLastNode = (): NavigationNode => {
    const findLastNode = (item: NavigationNode): NavigationNode => {
      if (!item.children) {
        return item;
      }
      return findLastNode(item.children);
    };

    return findLastNode(node);
  };

  const setSearch = (search: string) => {
    setNode((currentNode) => {
      const updateSearchInLastNode = (nodeToUpdate: NavigationNode): NavigationNode => {
        if (!nodeToUpdate.children) {
          return { ...nodeToUpdate, search };
        }
        return { ...nodeToUpdate, children: updateSearchInLastNode(nodeToUpdate.children) };
      };

      if (currentNode) {
        return updateSearchInLastNode(currentNode);
      }
      return currentNode;
    });
  };

  const updatePeriod = (newPeriod: CloudAccountsState['period']) => {
    setLocalPeriod((prevPeriod) => ({
      ...prevPeriod,
      ...newPeriod,
    }));
  };

  /* @todo enable when BE is ready
  const chartDataLimit = useMemo(() => {
    const { periodOneStart, periodOneEnd, periodTwoStart, periodTwoEnd } = localPeriod;
    return (
      dayjs(periodOneEnd).diff(dayjs(periodOneStart), 'day') +
      1 +
      dayjs(periodTwoEnd).diff(dayjs(periodTwoStart), 'day') +
      1
    );
  }, [localPeriod]);
   */

  return (
    <CloudAccountsContext.Provider
      value={{
        period: localPeriod,
        chartDataLimit: 2 * 365, // chartDataLimit
        updatePeriod,
        node,
        removeNode,
        addChildNode,
        setSearch,
        getLastNode,
      }}
    >
      {children}
    </CloudAccountsContext.Provider>
  );
};

export const useCloudAccounts = () => useContext(CloudAccountsContext);
