import { useTranslation } from 'react-i18next';
import { Divider, Stack, useTheme } from '@mui/material';

import Text from '@verticeone/design-system/src/components/Text';
import Button from '@verticeone/design-system/src/components/Button';
import { Dialog, DialogContent } from '@verticeone/design-system/src/components/Dialog';

type AuthEventsDialogProps = {
  open: boolean;
  onClose: () => void;
};

const AuthEventsDialog = ({ open, onClose }: AuthEventsDialogProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  return (
    <Dialog size="M" open={open} setOpen={onClose}>
      <DialogContent>
        <Stack>
          <Text size="M" variant="body-bold" color={palette.text.color2} mb={2}>
            {t('APPLICATIONS.USAGE.AUTH_EVENTS_DIALOG.HEADER')}
          </Text>
        </Stack>
        <Stack gap={4}>
          <Text size="M" variant="body-regular" color={palette.text.color2} mb={2}>
            {t('APPLICATIONS.USAGE.AUTH_EVENTS_DIALOG.CONTENT_1')}
          </Text>
          <Text size="M" variant="body-regular" color={palette.text.color2} mb={2}>
            {t('APPLICATIONS.USAGE.AUTH_EVENTS_DIALOG.CONTENT_2')}
          </Text>
        </Stack>
        <Divider />
        <Stack direction={'row'} justifyContent={'flex-end'} mt={2}>
          <Button variant="solid" size="M" color="primary" onClick={onClose}>
            {t('APPLICATIONS.USAGE.AUTH_EVENTS_DIALOG.CLOSE')}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default AuthEventsDialog;
