import React, { FC } from 'react';
import { useAccountUsersById } from '../../../../../../hooks/useAccountUsersById';
import { getFullName } from '../../../../../../utils/formatting/user';
import { useLoggedUser } from '@verticeone/auth/src';
import { useTranslation } from 'react-i18next';
import IconWrapper from '@verticeone/design-system/src/components/IconWrapper';
import { PersonOutline } from '@mui/icons-material';
import Text from '@verticeone/design-system/src/components/Text';
import { Stack } from '@mui/material';

type TaskAssigneesProps = {
  assigneeIds?: string[];
  textColor: string;
};

export const TaskAssignees: FC<TaskAssigneesProps> = ({ assigneeIds, textColor }) => {
  const { usersById } = useAccountUsersById();
  const { userId: loggedUserId } = useLoggedUser();
  const { t } = useTranslation();

  if (assigneeIds === undefined || assigneeIds.length === 0) {
    return null;
  }

  const assigneesNames =
    assigneeIds?.map((assignee) => {
      const fullName = getFullName(usersById[assignee]) || assignee;
      return assignee === loggedUserId
        ? t('INTELLIGENT_WORKFLOWS.WORKFLOW_SCHEMA.CURRENT_USER_NAME', { fullName })
        : fullName;
    }) || [];

  return (
    <Stack gap={1} direction="row" alignItems="center">
      <IconWrapper icon={PersonOutline} htmlColor={textColor} size="S" />
      <Stack>
        {assigneesNames.map((fullName) => (
          <Text key={fullName} variant="button" size="XS" color={textColor}>
            {fullName}
          </Text>
        ))}
      </Stack>
    </Stack>
  );
};
