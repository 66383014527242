import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useGetSsoDetailsQuery, useInitiateSsoMutation } from '@vertice/slices';
import { Loader } from '@vertice/components';
import { ROUTES } from '@vertice/utils/src/constants/cfa/constants';
import StepsIndicator from './StepsIndicator';

import styles from './SamlIntegrationStart.module.scss';
import commonStyles from './SamlIntegration.module.scss';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

const SamlIntegrationStart = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accountId } = useAccountContext();
  const [ssoInitiated, setSsoInitiated] = useState(false);
  const toCreateAppStep = () => navigate(ROUTES.SAML_CREATE_APP);

  const { data: ssoDetails, isLoading: isSsoDetailsLoading } = useGetSsoDetailsQuery(
    { accountId: accountId! },
    { skip: !accountId }
  );

  const [initiateSso, { isLoading: isSsoInitiateLoading }] = useInitiateSsoMutation();
  if (ssoDetails?.status === 'INACTIVE' && accountId && !ssoInitiated) {
    setSsoInitiated(true);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    initiateSso({ accountId: accountId! });
  }

  const isLoading = !ssoDetails || isSsoDetailsLoading || isSsoInitiateLoading;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography variant="heading-s">{t('SAML.START.TITLE')}</Typography>
      </div>

      <div className={commonStyles['inner-body']}>
        <div className={commonStyles.description}>
          <Typography variant="body-regular-m">{t('SAML.START.BODY_1')}</Typography>
        </div>
        <div className={commonStyles.description}>
          <Typography variant="body-regular-m">{t('SAML.START.BODY_2')}</Typography>
        </div>

        <div className={commonStyles['nav-buttons']}>
          <Button variant="contained" onClick={toCreateAppStep} disabled={isLoading} fullWidth>
            {isLoading ? <Loader size={16} /> : t('SAML.NEXT')}
          </Button>
        </div>
      </div>

      <div className={commonStyles.steps}>
        <StepsIndicator total={5} current={1} />
      </div>
    </div>
  );
};

export default SamlIntegrationStart;
