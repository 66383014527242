import MuiTableBody, { type TableBodyProps as MuiTableBodyProps } from '@mui/material/TableBody/TableBody';
import { styled, Theme } from '@mui/material/styles';
import { getTextVariantStyle, TextPropsCombination } from '../Text/utils';
import { TableContext } from './TableContext';
import React, { useContext } from 'react';
import { TableCellWrapper } from './TableCell';
import { TestProps } from '../../types';
import { testProps } from '../../utils/testProperties';

export type TableBodyProps = MuiTableBodyProps & TestProps;

type StyledTableBodyProps = {
  theme: Theme;
  $size: TextPropsCombination['size'];
};

export const StyledTableBody = styled(MuiTableBody)<StyledTableBodyProps>(({ theme, $size }) => ({
  [TableCellWrapper]: {
    ...getTextVariantStyle({ variant: 'body-regular', size: $size }),
  },
}));

const TableBody = ({ children, testId, ...otherProps }: TableBodyProps) => {
  const { size } = useContext(TableContext);
  return (
    <StyledTableBody {...otherProps} {...testProps(testId, 'table-body')} $size={size}>
      {children}
    </StyledTableBody>
  );
};

export default TableBody;
