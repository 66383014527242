import React from 'react';
import { styled, Tooltip as BaseTooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export type TooltipProps = {
  header?: string;
  content: string;
};

const TooltipHeader = styled('div')`
  color: var(--tokens-color-dark-text-1);
  font-weight: 600;
  margin-bottom: 0.25rem;
`;

const TooltipText = styled('div')`
  color: var(--tokens-color-dark-text-2);
`;

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)`
  margin-left: 0.25rem;
`;

const TooltipTitle = ({ header, content }: TooltipProps) => (
  <>
    {!!header && <TooltipHeader>{header}</TooltipHeader>}
    <TooltipText>{content}</TooltipText>
  </>
);

/** @deprecated use Design System's Tooltip */
export const Tooltip = ({ header, content }: TooltipProps) => (
  <BaseTooltip title={<TooltipTitle header={header} content={content} />}>
    <StyledInfoOutlinedIcon fontSize="small" />
  </BaseTooltip>
);
