import React from 'react';
import { StyledEngineProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider as DatePickerLocalizationProvider } from '@mui/x-date-pickers-pro';
import { translations } from '@aws-amplify/ui-react';
import SnackbarProvider from '@vertice/slices/src/slices/Notifications/Provider';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import duration from 'dayjs/plugin/duration';
import { ErrorBoundary } from 'react-error-boundary';
import useAwsRum from '@vertice/hooks/src/useAwsRum';
import { AppTypeContext } from '@vertice/components/src/contexts/AppTypeContext';
import { ThemeProvider } from '@verticeone/design-system/src/theme/ThemeProvider';
import { SmallScreenWarning } from '@vertice/components';
import { ErrorFallback } from './components/ErrorFallback/ErrorFallback';
import { enableRum, rumConfigs, rumCredentials } from './config';
import RouteContextProvider from './router/RouteContext';
import Router from './router/Router';
import '@vertice/i18n';

import '@aws-amplify/ui-react/styles.css';
import '@verticeone/design-system/src/theme/muiOverrides.scss';
import '@vertice/assets/styles/common.scss';
import '@vertice/assets/styles/amplify.scss';
import './App.scss';
import { LocaleContextProvider, useLocale } from '@vertice/core/src/contexts/LocaleContext';
import { CfaAuthContextProvider } from './modules/auth/CfaAuthContextProvider';
import { SimpleDialogContextProvider } from '@verticeone/design-system/src/components/Dialog/SimpleDialog';
import { I18n } from 'aws-amplify';
import enTranslation from '@vertice/i18n/src/i18n/en.json';

I18n.putVocabularies(translations);
I18n.setLanguage('en');
I18n.putVocabularies({
  en: {
    'Sign in': enTranslation.AUTH.SIGN_IN,
    Email: enTranslation.AUTH.EMAIL,
    Password: enTranslation.AUTH.PASSWORD,
    'Forgot your password?': enTranslation.AUTH.FORGOT_PASSWORD,
    'Reset your password': enTranslation.AUTH.RESET_PASSWORD,
    'Reset your Password': enTranslation.AUTH.RESET_PASSWORD,
    'Send Code': enTranslation.AUTH.SEND_CODE,
    'Send code': enTranslation.AUTH.SEND_CODE,
    'Back to Sign In': enTranslation.AUTH.BACK_TO_LOGIN,
    'Enter your email': enTranslation.AUTH.EMAIL,
    'Confirm Password': enTranslation.AUTH.CONFIRM_PASSWORD,
    'New password': enTranslation.AUTH.NEW_PASSWORD,
    Code: enTranslation.AUTH.CODE,
    Submit: enTranslation.AUTH.SUBMIT,
  },
});

dayjs.extend(updateLocale);
dayjs.updateLocale('en', { weekStart: 1 });
dayjs.extend(duration);

declare function cwr(operation: string, payload: any): void;

function App() {
  useAwsRum({ config: rumConfigs, rumCredentials, enableRum });
  const localeContext = useLocale();

  const errorHandler = (error: Error, info: { componentStack: string }) => {
    if (typeof cwr === 'function') {
      cwr('recordError', error);
    }
  };

  return (
    <LocaleContextProvider value={localeContext}>
      <ThemeProvider locale={localeContext.locale}>
        <AppTypeContext.Provider value={{ isIAT: false }}>
          <DatePickerLocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{
              fieldMonthPlaceholder: ({ format }) => format,
            }}
          >
            <StyledEngineProvider injectFirst>
              <RouteContextProvider>
                <SnackbarProvider>
                  <CfaAuthContextProvider>
                    <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
                      <SimpleDialogContextProvider>
                        <Router />
                      </SimpleDialogContextProvider>
                    </ErrorBoundary>
                  </CfaAuthContextProvider>
                  <SmallScreenWarning />
                </SnackbarProvider>
              </RouteContextProvider>
            </StyledEngineProvider>
          </DatePickerLocalizationProvider>
        </AppTypeContext.Provider>
      </ThemeProvider>
    </LocaleContextProvider>
  );
}

export default App;
