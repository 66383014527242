import React from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';

import Breadcrumbs, { BreadcrumbLink } from '@verticeone/design-system/src/components/Breadcrumbs';
import Text from '@verticeone/design-system/src/components/Text';
import LoadableAWSPageWrapper from '../LoadableAWSPageWrapper';
import OptimizationTestsCard from '@vertice/dashboard/src/modules/cloud/cards/OptimizationTestsCard/OptimizationTestsCard';
import DisclaimerBanner from './components/DisclaimerBanner';

const CloudOptimization = () => {
  const { t } = useTranslation();

  return (
    <LoadableAWSPageWrapper>
      <Stack gap={8} p={10}>
        <Stack gap={1}>
          <Breadcrumbs variant="slash" size="S">
            <BreadcrumbLink>{t('CLOUD.NAVBAR.CLOUD')}</BreadcrumbLink>
            <BreadcrumbLink disabled>{t('CLOUD.CLOUD_OPTIMIZATION.BREADCRUMBS_TITLE')}</BreadcrumbLink>
          </Breadcrumbs>
          <Text variant="heading" size="M" tag="h1">
            {t('CLOUD.CLOUD_OPTIMIZATION.TITLE')}
          </Text>
        </Stack>
        <DisclaimerBanner />
        <Grid container rowSpacing={4} columnSpacing={4}>
          <Grid item sm={12}>
            <OptimizationTestsCard />
          </Grid>
        </Grid>
      </Stack>
    </LoadableAWSPageWrapper>
  );
};

export default CloudOptimization;
