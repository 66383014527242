import { useTranslation } from 'react-i18next';

import { SlackTitleIcon } from '@vertice/assets';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { AlertingSetupCard } from '../AlertingSetupCard';
import { AlertingSetupCardV2 } from '../AlertingSetupCardV2';
import { SLACK_WIZARD_ROUTE } from './common';

type SlackSetupCardProps = {
  providerId: string;
};

export const SlackSetupCard = ({ providerId }: SlackSetupCardProps) => {
  const { t } = useTranslation();
  const { isEnabled } = useFeatures();

  if (isEnabled(FEATURES.INTEGRATION_SLACK_V2)) {
    return (
      <AlertingSetupCardV2
        providerId={providerId}
        providerName={t('PREFERENCES.INTEGRATIONS.SLACK.TITLE')}
        providerIcon={<SlackTitleIcon />}
        description={t('PREFERENCES.INTEGRATIONS.SLACK.DESCRIPTION')}
        wizardRoute={SLACK_WIZARD_ROUTE}
      />
    );
  }

  return (
    <AlertingSetupCard
      providerId={providerId}
      providerName={t('PREFERENCES.INTEGRATIONS.SLACK.TITLE')}
      providerIcon={<SlackTitleIcon />}
      description={t('PREFERENCES.INTEGRATIONS.SLACK.DESCRIPTION')}
      wizardRoute={SLACK_WIZARD_ROUTE}
    />
  );
};
