import React, { useContext, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import { ContractListFilterContext } from '@vertice/core/src/modules/saas/contract/contexts/ContractListFilterContext';
import Placeholder from '@verticeone/design-system/src/components/Placeholder';
import { FilterView, FilterViewWithCount } from '../types';
import { FilterViewButton } from './FilterViewButton';
import {
  ViewSettingsDrawer,
  ViewSettingsDrawerOpeningState,
} from './customViews/ViewSettingsDrawer/ViewSettingsDrawer';
import reachedMaxNumberOfCustomViews from './customViews/reachedMaxNumberOfCustomViews';
import NewFilterViewButton from './customViews/NewFilterViewButton';
import { getFilterKey } from '../functions';
import { CustomFilterView } from './customViews/types';

export type FilterViewPanelProps = {
  filterViewsWithCounts: FilterViewWithCount[];
  activeFilterView: FilterView;
  maxNumberOfCustomFilterViews?: number;
  customViewsEnabled?: boolean;
  onFilterViewDeleted?: () => void;
};

export const FilterViewPanel = ({
  filterViewsWithCounts: filterViewsWithCountsRaw,
  activeFilterView,
  customViewsEnabled = false,
  maxNumberOfCustomFilterViews,
  onFilterViewDeleted,
}: FilterViewPanelProps) => {
  const { setLastActiveFilterView } = useContext(ContractListFilterContext);
  const [openedViewSettingsDrawer, setOpenedViewSettingsDrawer] = useState<ViewSettingsDrawerOpeningState>(null);

  const filterViewsWithCounts = useMemo(() => {
    return customViewsEnabled
      ? filterViewsWithCountsRaw
      : filterViewsWithCountsRaw.filter(({ filterView }) => filterView.type !== 'custom');
  }, [customViewsEnabled, filterViewsWithCountsRaw]);

  const numberOfCustomFilterViews = useMemo(
    () => filterViewsWithCounts.filter(({ filterView }) => filterView.type === 'custom').length,
    [filterViewsWithCounts]
  );
  const isLoading = filterViewsWithCounts.length === 0;

  const isNewFilterViewButtonVisible =
    customViewsEnabled &&
    !isLoading &&
    !reachedMaxNumberOfCustomViews({ numberOfCustomFilterViews, maxNumberOfCustomFilterViews });

  return (
    <>
      <Stack direction="row" gap={2} flexWrap="wrap">
        {isLoading && [1, 2, 3, 4, 5, 6, 7].map((i) => <Placeholder width={100} height={30} key={i}></Placeholder>)}
        {filterViewsWithCounts.map((filterViewWithCount) => {
          const isActive = getFilterKey(activeFilterView) === getFilterKey(filterViewWithCount.filterView);
          return (
            <FilterViewButton
              key={
                filterViewWithCount.filterView.type === 'predefined'
                  ? filterViewWithCount.filterView.labelToTranslate
                  : filterViewWithCount.filterView.id
              }
              filterViewWithCount={filterViewWithCount}
              active={isActive}
              onClick={() => setLastActiveFilterView(filterViewWithCount.filterView)}
              onEditClick={
                isActive && filterViewWithCount.filterView.type === 'custom'
                  ? () =>
                      setOpenedViewSettingsDrawer({
                        mode: 'update',
                        viewId: (filterViewWithCount.filterView as CustomFilterView).id,
                      })
                  : undefined
              }
            />
          );
        })}
        {isNewFilterViewButtonVisible && (
          <NewFilterViewButton onClick={() => setOpenedViewSettingsDrawer({ mode: 'create' })} />
        )}
      </Stack>
      {customViewsEnabled && (
        <ViewSettingsDrawer
          state={openedViewSettingsDrawer}
          onClose={() => setOpenedViewSettingsDrawer(null)}
          onFilterViewCreated={(customFilterView) => setLastActiveFilterView({ type: 'custom', ...customFilterView })}
          onFilterViewUpdated={(customFilterView) => setLastActiveFilterView({ type: 'custom', ...customFilterView })}
          onFilterViewDeleted={onFilterViewDeleted}
        />
      )}
    </>
  );
};
