import React, { PropsWithChildren, forwardRef } from 'react';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import { ifExpression } from '../../../utils/ifExpression';
import { type StyledComponentProps, type TextFieldAreaProps, areaPadding } from './constants';
import Label, { Divider, getHoverState, StyledLabel } from './Label';

const StyledOutlinedWrapper = styled('fieldset')<StyledComponentProps>(
  ({ theme, $size, $color, $disabled, $hasError, $hasLabel, $autosize }) => ({
    padding: $autosize ? 0 : '0 5px 5px 0',
    '.textarea': {
      ...ifExpression(!$hasLabel, {
        paddingTop: `${areaPadding[$size]}px`,
      }),
      paddingLeft: `${areaPadding[$size] * 2}px`,
      paddingRight: `calc(${areaPadding[$size] * 2}px - 5px) `,
      paddingBottom: `${areaPadding[$size]}px`,
      borderRadius: theme.spacing(2),
      border: 'none',
      outline: 'none',
    },
    legend: {
      whiteSpace: 'nowrap',
      margin: `0 ${areaPadding[$size] * 2}px`,
      width: `calc(100% - ${areaPadding[$size] * 4}px + 5px)`,
    },
    border: `1px solid ${theme.palette.core.color5}`,
    borderRadius: theme.spacing(2),
    '&:hover, &:focus-within': ifExpression(!$disabled && !$hasError, {
      borderColor: theme.palette[$color].hover.color1,
      [StyledLabel]: getHoverState({ theme, $color, $disabled }),
      [Divider]: getHoverState({ theme, $color, $disabled }, 'backgroundColor'),
    }),
    ...ifExpression($hasError && !$disabled, {
      borderColor: theme.palette.error.hover.color1,
      [StyledLabel]: getHoverState({ theme, $color: 'error', $disabled }),
      [Divider]: getHoverState({ theme, $color: 'error', $disabled }, 'backgroundColor'),
    }),
  })
);

const OutlinedWrapper = forwardRef<HTMLDivElement, PropsWithChildren<TextFieldAreaProps>>((props, ref) => (
  <StyledOutlinedWrapper
    $size={props.size}
    $color={props.color}
    $disabled={!!props.disabled}
    $hasError={!!props.hasError}
    $hasLabel={!!props.label}
    $autosize={!!props.autosize}
  >
    {(props.label || props.textLimit) && (
      <legend>
        <Label {...props} showDivider />
      </legend>
    )}
    <Stack ref={ref}>{props.children}</Stack>
  </StyledOutlinedWrapper>
));

export default OutlinedWrapper;
