import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import { VerticeOneLoginIntegration } from '@vertice/assets';
import Button from '@verticeone/design-system/src/components/Button';
import WizardStepTemplate from '../../components/WizardStepTemplate';
import { BulletList } from '../../components/WizardSteps';
import { STEPS } from './common';
import { WizardStepProps } from '../../components/Wizard';

export const StartStep = ({ setStep }: WizardStepProps) => {
  const { t } = useTranslation();

  const header = (
    <Stack gap="8px">
      <Stack direction="row" gap="6px">
        <Text variant="heading" size="M">
          {t('PREFERENCES.INTEGRATIONS.ONELOGIN.USAGE.SETUP_ONELOGIN')}
        </Text>
        <Text variant="heading" size="M" color="text1" sx={{ opacity: '0.4' }}>
          {t('PREFERENCES.INTEGRATIONS.ONELOGIN.USAGE.FOR_USAGE_STATS')}
        </Text>
      </Stack>
      <Text variant="body-regular" size="M" color="text2">
        {t('PREFERENCES.INTEGRATIONS.ONELOGIN.USAGE.INTRO')}
      </Text>
    </Stack>
  );

  const content = (
    <Stack gap="8px">
      <Text variant="caption" size="XS" color="text2">
        {t('PREFERENCES.INTEGRATIONS.ONELOGIN.USAGE.PREREQUISITES')}
      </Text>
      <BulletList>
        <li>
          <Text variant="body-regular" size="M">
            {t('PREFERENCES.INTEGRATIONS.ONELOGIN.USAGE.PREREQUISITE_1')}
          </Text>
        </li>
        <li>
          <Text variant="body-regular" size="M">
            {t('PREFERENCES.INTEGRATIONS.ONELOGIN.USAGE.PREREQUISITE_2')}
          </Text>
        </li>
        <li>
          <Text variant="body-regular" size="M">
            {t('PREFERENCES.INTEGRATIONS.ONELOGIN.USAGE.PREREQUISITE_3')}
          </Text>
        </li>
      </BulletList>
    </Stack>
  );

  return (
    <WizardStepTemplate
      headerImage={<VerticeOneLoginIntegration />}
      header={header}
      content={content}
      buttons={[
        <Button variant="solid" color="primary" size="S" isCaption onClick={() => setStep(STEPS.DATA_SOURCES)}>
          {t('COMMON.NEXT')}
        </Button>,
      ]}
      scrollable={false}
    />
  );
};
