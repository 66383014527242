import { FC, useContext, useMemo } from 'react';
import { ContractsTable } from '@vertice/core/src/modules/saas/contract/components/ContractList/ContractsTable';
import { PageHeader } from './Components/PageHeader';
import { Stack } from '@mui/material';
import {
  ContractListColumn,
  PREDEFINED_VIEW_AWAITING_APPROVAL,
  PREDEFINED_VIEW_COMPLETED,
  PREDEFINED_VIEW_DRAFT,
  PREDEFINED_VIEW_EXPIRED,
  PREDEFINED_VIEW_IN_PROGRESS,
  PREDEFINED_VIEW_LIVE_FIXED_TERM,
  PREDEFINED_VIEW_LIVE_ROLLING,
  PREDEFINED_VIEW_TOTAL,
} from '@vertice/core/src/modules/saas/contract/components/ContractList/types';
import { useContracts } from '@vertice/core/src/modules/saas/contract/components/ContractList/useContracts';
import { FilterPanel } from '@vertice/core/src/modules/saas/contract/components/ContractList/Filters/FilterPanel';
import { ContractListFilterContext } from '@vertice/core/src/modules/saas/contract/contexts/ContractListFilterContext';
import { useGetColumnBuilder } from '@vertice/core/src/modules/saas/contract/components/ContractList/useGetColumnBuilder';
import { GridColDef } from '@mui/x-data-grid-pro';
import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { getDefaultSortModel } from '@vertice/core/src/modules/saas/contract/components/ContractList/functions';
import { useCustomViewsList } from '@vertice/core/src/modules/saas/contract/components/ContractList/Filters/customViews/hooks/useCustomViewsList';
import { useCanUseCustomViewsAndFilters } from '@vertice/core/src/modules/saas/contract/components/ContractList/Filters/hooks/useCanUseCustomViewsAndFilters';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { FilterViewPanel } from '@vertice/core/src/modules/saas/contract/components/ContractList/Filters/FilterViewPanel';

export const Contracts: FC = () => {
  const routes = useRoutes();
  const { generatePath } = useRouteNavigate();
  const { isEnabled } = useFeatures();
  const canUseCustomViewsAndFilters = useCanUseCustomViewsAndFilters();
  const { customViews } = useCustomViewsList({ skip: !canUseCustomViewsAndFilters });

  const getContractPath = (contractId: string) => generatePath(routes.CONTRACTS.DETAIL.PATH, { contractId });

  const { lastActiveFilterView, setLastActiveFilterView, filterValues, activeFilters } =
    useContext(ContractListFilterContext);
  const activeFilterView = lastActiveFilterView || PREDEFINED_VIEW_TOTAL;

  const { viewsOnContracts, allViewsOnContracts, filterViewsWithCounts, isLoading } = useContracts({
    filterViews: [
      PREDEFINED_VIEW_TOTAL,
      PREDEFINED_VIEW_LIVE_FIXED_TERM,
      PREDEFINED_VIEW_LIVE_ROLLING,
      PREDEFINED_VIEW_IN_PROGRESS,
      PREDEFINED_VIEW_AWAITING_APPROVAL,
      PREDEFINED_VIEW_DRAFT,
      PREDEFINED_VIEW_COMPLETED,
      PREDEFINED_VIEW_EXPIRED,
      ...(canUseCustomViewsAndFilters && customViews ? customViews : []),
    ],
    activeFilterView,
    filterValues,
    activeFilters,
  });

  const hasAnyFilteredContractAssignedOwner = useMemo(
    () => viewsOnContracts.some((view) => view.computed?.owners?.length),
    [viewsOnContracts]
  );
  const hasVariousContractCategories = useMemo(
    () =>
      viewsOnContracts.some((view) => view.contract.classification?.category === 'SAAS') &&
      viewsOnContracts.some((view) => view.contract.classification?.category === 'OTHER'),
    [viewsOnContracts]
  );

  const { buildColumnsFromView } = useGetColumnBuilder({
    resolveCustomFields: isEnabled(FEATURES.CONTRACTS_CUSTOMIZATION),
  });
  const columns: GridColDef<ViewOnContract>[] = useMemo(
    () =>
      (buildColumnsFromView?.(activeFilterView) ?? []).filter((colDef) => {
        const ownerColumnToBeHidden = colDef.field === ContractListColumn.OWNER && !hasAnyFilteredContractAssignedOwner;
        const categoryColumnToBeHidden = colDef.field === ContractListColumn.CATEGORY && !hasVariousContractCategories;
        return !(ownerColumnToBeHidden || categoryColumnToBeHidden);
      }),
    [buildColumnsFromView, activeFilterView, hasAnyFilteredContractAssignedOwner, hasVariousContractCategories]
  );

  return (
    <Stack gap={8} py={10}>
      <PageHeader />
      <Stack px={10} gap={8}>
        <Stack gap={4}>
          <FilterPanel
            viewsOnContracts={allViewsOnContracts}
            customFiltersEnabled={canUseCustomViewsAndFilters}
            isLoading={isLoading}
          />
          <FilterViewPanel
            filterViewsWithCounts={filterViewsWithCounts}
            activeFilterView={activeFilterView}
            customViewsEnabled={canUseCustomViewsAndFilters}
            maxNumberOfCustomFilterViews={5}
            onFilterViewDeleted={() => {
              setLastActiveFilterView(PREDEFINED_VIEW_TOTAL);
            }}
          />
        </Stack>
        <ContractsTable
          rows={viewsOnContracts}
          isLoading={isLoading}
          columns={columns}
          defaultSortModel={getDefaultSortModel(activeFilterView)}
          getContractPath={getContractPath}
        />
      </Stack>
    </Stack>
  );
};
