import { Fragment, useEffect, useRef } from 'react';
import { Stack } from '@mui/material';

import Comment, { CommentMetadata } from '../Comment';
import CommentsContainer from './components/CommentsContainer';
import Divider from '../Divider';
import CommentsWrapper from './components/CommentsWrapper';
import CommentInput from './components/CommentInput';
import EmptyComments from './components/EmptyComments';
import { SendCommentCallback, UpdateCursorCallback } from './types';
import NewMessageDivider from './components/NewMessageDivider';
import CommentLoading from './CommentLoading';
import { useScrollElement } from './components/useScrollElement';
import CommentSkeleton from '../Comment/CommentSkeleton';
import { getFirstNewCommentIndex, getNewCommentsCount } from './utils';

export type CommentsProps = {
  comments?: CommentMetadata[] | null;
  isLoading?: boolean;
  cursor: string | null;
  isSending?: boolean;
  onSendComment: SendCommentCallback;
  onUpdateCursor?: UpdateCursorCallback;
};

const Comments = ({ comments, isLoading, isSending, cursor, onSendComment, onUpdateCursor }: CommentsProps) => {
  const newMessagesCount = comments && cursor ? getNewCommentsCount(comments, cursor) : 0;
  const newMessageIndex = comments && cursor ? getFirstNewCommentIndex(comments, cursor) : undefined;

  const lastMessageBeforeNewMessagesRef = useRef<HTMLDivElement>(null);
  const commentsContainerRef = useRef<HTMLDivElement>(null);

  const { isScrolledToBottom } = useScrollElement({
    container: commentsContainerRef,
    condition: (!isLoading && (!!cursor || !newMessagesCount)) || !!isSending,
  });

  useScrollElement({
    element: lastMessageBeforeNewMessagesRef,
    container: commentsContainerRef,
    condition: !isLoading && newMessagesCount > 0,
  });

  const handleNewMessagesChipClick = () => {
    if (!lastMessageBeforeNewMessagesRef.current) return;

    lastMessageBeforeNewMessagesRef.current.scrollIntoView({ behavior: 'smooth' });

    // Since we are scrolling with smooth behavior, we need to wait for the scroll to finish before updating the cursor
    setTimeout(() => onUpdateCursor?.(new Date().toISOString()), 500);
  };

  const handleSendComment: SendCommentCallback = (comment, onSuccess) => {
    onSendComment(comment, () => {
      onSuccess?.();
    });
  };

  useEffect(() => {
    if (isScrolledToBottom && newMessagesCount < 1) {
      // Not sure if timeout is ideal, but we want the user to see the information, not just make it disappear immediately
      setTimeout(() => onUpdateCursor?.(new Date().toISOString()), 5000);
    }
  }, [isScrolledToBottom, newMessagesCount, onUpdateCursor]);

  return (
    <Stack minHeight={0} height={'100%'}>
      <CommentsWrapper minHeight={'100%'}>
        {!isLoading ? (
          <>
            {comments && comments.length > 0 ? (
              <CommentsContainer
                newMessagesCount={newMessagesCount}
                onNewMessagesChipClick={handleNewMessagesChipClick}
                ref={commentsContainerRef}
              >
                <>
                  {comments.map(({ isVerticeUser, ...comment }, index) => (
                    <Fragment key={comment.commentId}>
                      <Comment
                        ref={
                          newMessageIndex && index === newMessageIndex - 1 ? lastMessageBeforeNewMessagesRef : undefined
                        }
                        variant={isVerticeUser ? 'branded' : 'default'}
                        {...comment}
                      />
                      {newMessageIndex !== undefined && index === newMessageIndex - 1 && <NewMessageDivider />}
                    </Fragment>
                  ))}
                  {isSending && <CommentSkeleton />}
                </>
              </CommentsContainer>
            ) : (
              <CommentsContainer justifyContent="center" alignItems="center">
                <EmptyComments />
              </CommentsContainer>
            )}
          </>
        ) : (
          <CommentLoading />
        )}
        <Divider />
        <CommentInput onSend={handleSendComment} disabled={isLoading || isSending} />
      </CommentsWrapper>
    </Stack>
  );
};

export default Comments;
