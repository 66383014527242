import { GridApiPro, GridPreProcessEditCellProps, GridSortCellParams } from '@mui/x-data-grid-pro';
import { gridStringOrNumberComparator } from '@mui/x-data-grid/hooks/features/sorting/gridSortingUtils';
import { CustomFieldIdentifier, FieldItem, FieldType } from './types';
import {
  CustomContractFieldDropdownType,
  CustomContractFieldSpec,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { MutableRefObject } from 'react';
import { isNotNil } from '../../../../../utils/validation/isNotNil';

export const normalizeFieldLabel = (value: string) => value.trim().replace(/\s\s+/g, ' ');

export const preProcessFieldTypeCell = (params: GridPreProcessEditCellProps) => {
  const fieldType = params.props.value;
  return { ...params.props, error: !fieldType };
};

export const preProcessLabelCell = (fields: FieldItem[]) => (params: GridPreProcessEditCellProps) => {
  const fieldId = params.row.id;
  const fieldLabel = normalizeFieldLabel(params.props.value);
  const isEmpty = !fieldLabel;
  const nameExists =
    fieldLabel && fields.some((field: FieldItem) => field.label === fieldLabel && field.id !== fieldId);
  return { ...params.props, error: isEmpty || nameExists };
};

export const preProcessDropdownValuesCell = (params: GridPreProcessEditCellProps) => {
  const fieldType = params.otherFieldsProps?.fieldType.value;
  const fieldValues = params.props.value;
  const isDropdown = fieldType?.type === FieldType.DROPDOWN;
  const isEmpty = !fieldValues || fieldValues.length === 0;
  return { ...params.props, error: isDropdown && isEmpty };
};

export const labelComparator = (
  v1: string,
  v2: string,
  params1: GridSortCellParams<string>,
  params2: GridSortCellParams<string>
) => {
  if (!v1 || !v2) {
    return 0; //This will keep newly added row always at the end regardless of sort direction
  }
  return gridStringOrNumberComparator(v1, v2, params1, params2);
};

export const fieldTypeComparator = (
  v1: CustomFieldIdentifier,
  v2: CustomFieldIdentifier,
  params1: GridSortCellParams<CustomFieldIdentifier>,
  params2: GridSortCellParams<CustomFieldIdentifier>
) => {
  if (!v1 || !v2) {
    return 0; //This will keep newly added row always at the end regardless of sort direction
  }
  return gridStringOrNumberComparator(v1.label, v2.label, params1, params2);
};

export const isShownComparator = (
  v1: CustomFieldIdentifier,
  v2: CustomFieldIdentifier,
  params1: GridSortCellParams<boolean>,
  params2: GridSortCellParams<boolean>
) => {
  const model = params1.api.state.rows.dataRowIdToModelLookup;
  const v1Item = model[params1.rowNode.id];
  const v2Item = model[params2.rowNode.id];

  if (!v1Item.label || !v2Item.label) {
    return 0; //This will keep newly added row always at the end regardless of sort direction
  }

  return gridStringOrNumberComparator(v1, v2, params1, params2);
};

export const getUsedFieldTypes = (apiRef: MutableRefObject<GridApiPro>): CustomFieldIdentifier[] => {
  const savedRows = apiRef.current.state.rows.dataRowIdToModelLookup;
  return Object.values(savedRows)
    .map((row) => row.fieldType)
    .filter(isNotNil);
};

export const mapAPIFieldToFieldItem = (
  apiField: CustomContractFieldSpec,
  allFieldTypesMap: Record<string, CustomFieldIdentifier>
): FieldItem => {
  return {
    id: apiField.id!,
    fieldType: allFieldTypesMap[apiField.id],
    label: apiField.label,
    isVisible: apiField.isVisible,
    options: (apiField as CustomContractFieldDropdownType).options,
  };
};

export const mapFieldItemToAPIField = (fieldItem: FieldItem): CustomContractFieldSpec => {
  return {
    id: fieldItem.fieldType!.id,
    type: fieldItem.fieldType!.type,
    label: fieldItem.label,
    isVisible: fieldItem.isVisible,
    options: fieldItem.options,
  };
};
