import React, { FC, useMemo } from 'react';
import { useTheme } from '@mui/material';
import {
  Background,
  Controls,
  BackgroundVariant,
  ReactFlow,
  ReactFlowProvider,
  NodeProps,
  MarkerType,
  EdgeTypes,
} from 'reactflow';

import { StartNodeComponent, EndNodeComponent, TaskNodeComponent, GatewayNodeComponent } from './NodeComponents';
import { RendererNodeType } from './types';
import { LabelledEdge } from './EdgeComponents';

import 'reactflow/dist/style.css';
import { WorkflowModel } from '../model/types';
import { modelToRendererGraph } from './modelToRendererGraph';

type WorkflowRendererProps = {
  model: WorkflowModel;
};

const edgeTypes: EdgeTypes = {
  labelled: LabelledEdge,
};

const nodeTypes: Record<RendererNodeType, FC<NodeProps>> = {
  start: StartNodeComponent,
  end: EndNodeComponent,
  task: TaskNodeComponent,
  gateway: GatewayNodeComponent,
};

const WorkflowRenderer: FC<WorkflowRendererProps> = ({ model }) => {
  const { palette } = useTheme();

  const { nodes, edges } = useMemo(() => modelToRendererGraph(model), [model]);

  return (
    <ReactFlowProvider>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        multiSelectionKeyCode={null}
        maxZoom={1.2}
        minZoom={0.1}
        zoomOnScroll={false}
        preventScrolling={false}
        fitView
        snapToGrid
        snapGrid={[10, 10]}
        attributionPosition="bottom-left"
        defaultEdgeOptions={{
          markerEnd: {
            type: MarkerType.Arrow,
            width: 30,
            height: 30,
            color: palette.core.color5,
            strokeWidth: 0.6,
          },
          style: {
            strokeWidth: 1,
          },
        }}
      >
        <Background
          color={palette.core.color4}
          variant={BackgroundVariant.Lines}
          style={{
            background: palette.core.color1,
            strokeDasharray: '5 5',
            opacity: 0.8,
          }}
          gap={170}
          offset={2.5}
        />
        <Controls showInteractive={false} />
      </ReactFlow>
    </ReactFlowProvider>
  );
};

export default WorkflowRenderer;
