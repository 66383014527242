import { useMemo } from 'react';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import useLoggedUserAccountRoles from '../../../hooks/useLoggedUserAccountRoles';

const useSavingsDashboard = () => {
  const { isUserAdmin, isUserPowerUser } = useLoggedUserAccountRoles();
  const userCanSeeDashboard = isUserAdmin || isUserPowerUser;
  const { isEnabled, isLoading: isFeatureLoading } = useFeatures();
  const savingsDashboardEnabled = isEnabled(FEATURES.SAVINGS_DASHBOARD);

  return useMemo(
    () => ({
      enabled: userCanSeeDashboard && savingsDashboardEnabled,
      isLoading: isFeatureLoading,
    }),
    [userCanSeeDashboard, savingsDashboardEnabled, isFeatureLoading]
  );
};

export default useSavingsDashboard;
