import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Box, Grid, Stack, Tab, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useGetVendorLegalInfoQuery } from '@vertice/slices';
import BlurredContent from './BlurredContent';
import VendorLegal from './VendorLegal';
import VendorSecurity from './VendorSecurity';
import VendorCompliance from './VendorCompliance';
import styles from './VendorDiligenceInsights.module.scss';
import { isComplianceDataAvailable, isDataAvailable, isLegalDataAvailable } from './utils';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';

interface VendorLegalProps {
  mainSectionCols: number;
  accountId: string;
  vendorId: string;
  topPadding?: boolean;
  leftPadding?: boolean;
}

const VendorDiligenceInsights: FC<VendorLegalProps> = ({
  mainSectionCols,
  accountId,
  vendorId,
  topPadding,
  leftPadding = false,
}) => {
  const { t } = useTranslation();
  const { getFeature } = useFeatures();
  const isSubscribed = getFeature(FEATURES.VENDOR_LEGAL)?.properties?.isSubscribed;

  const [currentTab, setCurrentTab] = useState('legal');

  const { data: vendorLegalData, isLoading: isVendorLegalDataLoading } = useGetVendorLegalInfoQuery(
    {
      accountId: accountId!,
      vendorId: vendorId!,
    },
    { skip: !isSubscribed }
  );
  const emptyData = !isSubscribed || (!isVendorLegalDataLoading && !isDataAvailable(vendorLegalData!));
  const emptyLegalData = emptyData || !isLegalDataAvailable(vendorLegalData!);
  const emptyComplianceData = emptyData || !isComplianceDataAvailable(vendorLegalData!);

  const blurredContentMode = isSubscribed ? 'NO_DATA' : 'NO_SUBSCRIPTION';

  if (isVendorLegalDataLoading) {
    return null;
  }

  return (
    <Grid item xs={12 - mainSectionCols} pl={leftPadding ? '36px' : '0px'} pt={topPadding ? '20px' : '0px'}>
      <div className={styles.container}>
        <div className={styles.bg} />

        <TabContext value={currentTab}>
          <Stack className={styles['header-container']} gap="16px">
            <Stack className={styles.header} gap="7px">
              <Typography variant="heading-s" color="var(--tokens-color-light-text-1)">
                {t('LEGAL_INFO.TITLE')}
              </Typography>
              <Typography variant="body-regular-s" color="var(--tokens-color-light-text-2)">
                {emptyData ? t(`LEGAL_INFO.${blurredContentMode}.DETAILS`) : t(`LEGAL_INFO.DETAILS`)}
              </Typography>
            </Stack>
            <Box>
              <TabList
                className={clsx('section-tabs', styles['custom-tabs'])}
                onChange={(e, value) => setCurrentTab(value)}
              >
                <Tab
                  label={<Typography variant="button-m">{t('LEGAL_INFO.TABS.LEGAL')}</Typography>}
                  value="legal"
                  className="tab-item"
                />
                <Tab
                  label={<Typography variant="button-m">{t('LEGAL_INFO.TABS.SECURITY')}</Typography>}
                  value="security"
                  className="tab-item"
                  disabled={!isSubscribed}
                />
                <Tab
                  label={<Typography variant="button-m">{t('LEGAL_INFO.TABS.COMPLIANCE')}</Typography>}
                  value="compliance"
                  className="tab-item"
                  disabled={!isSubscribed}
                />
              </TabList>
            </Box>
          </Stack>
          <hr className="tabs-divider" />
          <TabPanel value="legal" className={styles['tab-panel']}>
            {emptyLegalData ? (
              <BlurredContent
                accountId={accountId}
                vendorId={vendorId}
                mode={isSubscribed ? 'NO_DATA' : 'NO_SUBSCRIPTION'}
              />
            ) : (
              <VendorLegal {...{ accountId, vendorId, vendorLegalData, isVendorLegalDataLoading }} />
            )}
          </TabPanel>
          <TabPanel value="security" className={styles['tab-panel']}>
            <VendorSecurity vendorId={vendorId} />
          </TabPanel>
          <TabPanel value="compliance" className={styles['tab-panel']}>
            {emptyComplianceData ? (
              <BlurredContent
                accountId={accountId}
                vendorId={vendorId}
                mode={isSubscribed ? 'NO_DATA' : 'NO_SUBSCRIPTION'}
              />
            ) : (
              <VendorCompliance {...{ accountId, vendorId, vendorLegalData, isVendorLegalDataLoading }} />
            )}
          </TabPanel>
        </TabContext>
      </div>
    </Grid>
  );
};

export default VendorDiligenceInsights;
