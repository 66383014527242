import React from 'react';
import { Stack, StackProps, useTheme } from '@mui/material';
import { LegendItemData } from './types';
import LegendItem from './LegendItem';
import { DesignSystemColor } from '@verticeone/design-system/src/types';

type LegendProps = StackProps & {
  items: LegendItemData[];
  filters?: string[];
  onFilterChange?: (args: string[]) => void;
  direction?: 'row' | 'column';
  color?: DesignSystemColor;
};

const Legend = ({ items, filters, onFilterChange, direction = 'row', color = 'primary' }: LegendProps) => {
  const { palette } = useTheme();
  const filterable = filters && onFilterChange;

  const onItemChange = (id: string) => (selected: boolean) => {
    if (!filterable) {
      return;
    }

    const filterIndex = filters.indexOf(id);
    const filterFound = filterIndex > -1;

    if (selected && !filterFound) {
      onFilterChange([...filters, id]);
    } else if (!selected && filterFound) {
      onFilterChange([...filters.slice(0, filterIndex), ...filters.slice(filterIndex + 1)]);
    }
  };

  return (
    <Stack
      direction={direction}
      py={1}
      px={1.75}
      bgcolor={palette.core.bg}
      columnGap={3.5}
      rowGap={2.5}
      borderRadius="8px"
      flexWrap="wrap"
      justifyContent={direction === 'row' ? 'center' : undefined}
    >
      {items.map((item) => (
        <LegendItem
          key={item.id}
          checkboxColor={color}
          selected={filterable ? filters.includes(item.id) : undefined}
          onSelectionChange={filterable ? onItemChange(item.id) : undefined}
          {...item}
        />
      ))}
    </Stack>
  );
};

export default Legend;
