import { styled } from '@mui/material';
import SolidStatsTile, { SolidStatsTileProps } from './SolidStatsTile';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { GradientName } from '@verticeone/design-system/src/theme/palette/global/gradientPalette';
import React from 'react';

const StyledSolidStatsTile = styled(SolidStatsTile)<{ $gradient: GradientName }>(({ $gradient, theme }) => ({
  background: theme.palette.gradient[$gradient],
}));

type GradientStatsTileProps = Omit<SolidStatsTileProps, 'color'> & {
  gradient?: GradientName;
};

const GradientStatsTile = ({ gradient = 'purpleToBlue', children, ...props }: GradientStatsTileProps) => (
  <StyledSolidStatsTile $gradient={gradient} {...props}>
    {children}
  </StyledSolidStatsTile>
);
export default GradientStatsTile;
