import React from 'react';
import { Authenticator } from '@aws-amplify/ui-react';

type WithAmplifyProps = {
  children: React.ReactNode;
};

const withAmplify = <P extends object>(WrappedComponent: React.ComponentType<P>) => (props: WithAmplifyProps) => (
  <Authenticator.Provider>
    <WrappedComponent {...props as P} />
  </Authenticator.Provider>
);

export default withAmplify;
