import React, { ElementType } from 'react';
import { FieldValues } from 'react-hook-form';
import FormEntry, { FormEntryProps } from '@vertice/core/src/modules/forms/fields/FormEntry';
import { useTaskFormContext } from '../TaskFormContext';

export type FormEntryComponentWithPreFilledFormDataType<FormDataType extends FieldValues> = <
  CompType extends ElementType
>(
  props: FormEntryProps<FormDataType, CompType>
) => JSX.Element;

export const createTypedTaskFormEntry = <FormDataType extends FieldValues = never>() =>
  TaskFormEntry as FormEntryComponentWithPreFilledFormDataType<FormDataType>;

export const TaskFormEntry = <FormDataType, CompType extends ElementType>({
  disabled: disabledByProp = false,
  ...restProps
}: FormEntryProps<FormDataType extends Record<string, unknown> ? FormDataType : never, CompType>) => {
  const disabledByContext = useTaskFormContext().readOnly;
  const disabled = disabledByProp || disabledByContext;

  return <FormEntry<any, any> disabled={disabled} {...restProps} />;
};
