import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormSection from '@vertice/core/src/modules/forms/FormSection';
import FormToggleButtonField from '@vertice/core/src/modules/forms/fields/FormToggleButtonField';
import { TaskTwoTone, RequestQuoteTwoTone } from '@mui/icons-material';
import { useWizard, WizardStepProps } from '@vertice/core/src/components/Wizard';
import { Header, InnerScrollable, Content } from '@vertice/core/src/components/Dialog/Components';
import SelectedVendorPanel from '../../../SharedComponents/SelectedVendorPanel';
import { AddExistingContractFormEntry } from '../../types';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import {
  Product,
  useCreateRenewalRequest,
} from '@vertice/dashboard/src/modules/intelligentWorkflows/hooks/useCreateRenewalRequest';
import { useFormContext } from 'react-hook-form';
import { SharedRenewalFormData } from '../../../SharedRenewalSteps/types';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { FormDataProductItem } from '../../../types';
import { InitiateRequestBetaDialog } from '@vertice/dashboard/src/modules/intelligentWorkflows/request/InitiateRequestBetaDialog/InitiateRequestBetaDialog';
import { useSnackbar } from 'notistack';

const useChooseInitiateRenewalWizard = () => {
  const wizard = useWizard();

  return {
    goToConfirmation: () => wizard.goToStep('confirmation'),
    goToConfirmRenewalRequirements: () => wizard.goToStep('confirm_renewal_requirements'),
  };
};

const ChooseInitiateRenewal: React.FC<WizardStepProps> = () => {
  const { t } = useTranslation();
  const { goToConfirmation, goToConfirmRenewalRequirements } = useChooseInitiateRenewalWizard();
  const { watch } = useFormContext<SharedRenewalFormData>();
  const contractId = watch('existingContractId');
  const vendor = watch('vendor');
  const products = watch('products');
  const { accountId, isFeatureEnabled, getFeature } = useAccountContext();
  const [createRenewalRequest, isCreatingRequest] = useCreateRenewalRequest();
  const { navigate } = useRouteNavigate();
  const routes = useRoutes();
  const { enqueueSnackbar } = useSnackbar();

  const [betaWorkflowsDialogOpened, setBetaWorkflowsDialogOpened] = useState(false);

  const initiateRenewalRequest = useCallback(async () => {
    const pollingSuccessful = await createRenewalRequest({
      contractId: contractId!,
      vendor: vendor!,
      products: formProductsToRenewalRequestProducts(products),
      source: `urn:verticeone:vertice:${accountId}:cfa:page/intake/existingContract/${contractId}`,
    });
    if (pollingSuccessful) {
      enqueueSnackbar(t('ENTITIES.WORKFLOW_REQUEST.SNACKBARS.CREATED'), { variant: 'success' });
    }
    navigate(routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL, {
      requestId: pollingSuccessful!,
    });
  }, [
    accountId,
    contractId,
    createRenewalRequest,
    enqueueSnackbar,
    navigate,
    products,
    routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL,
    t,
    vendor,
  ]);

  const onInitiateRenewalChosen = useCallback(
    async (value: string) => {
      if (value === 'no') {
        goToConfirmation();
      } else if (value === 'yes') {
        const isIWEnabled = isFeatureEnabled(FEATURES.INTELLIGENT_WORKFLOWS);
        const workflowsFeature = getFeature(FEATURES.INTELLIGENT_WORKFLOWS);
        if (isIWEnabled && workflowsFeature?.properties?.beta === true) {
          setBetaWorkflowsDialogOpened(true);
        } else if (isIWEnabled) {
          await initiateRenewalRequest();
        } else {
          goToConfirmRenewalRequirements();
        }
      }
    },
    [goToConfirmation, isFeatureEnabled, getFeature, initiateRenewalRequest, goToConfirmRenewalRequirements]
  );

  return (
    <>
      <Content>
        <Header
          title={t('INTAKE_FORM.EXISTING_CONTRACT_CHOOSE_INITIATE_RENEWAL.HEADING_TITLE')}
          subtitle={t('INTAKE_FORM.EXISTING_CONTRACT_CHOOSE_INITIATE_RENEWAL.HEADING_SUBTITLE')}
        />
        <SelectedVendorPanel />
        <InnerScrollable>
          <FormSection>
            <AddExistingContractFormEntry
              name="initiateRenewal"
              width={12}
              component={FormToggleButtonField}
              componentProps={{
                onClick: onInitiateRenewalChosen,
                buttonHeight: 234,
                options: [
                  {
                    title: t('INTAKE_FORM.EXISTING_CONTRACT_CHOOSE_INITIATE_RENEWAL.LABELS.UPLOAD_ONLY'),
                    value: 'no',
                    icon: TaskTwoTone,
                  },
                  {
                    title: t('INTAKE_FORM.EXISTING_CONTRACT_CHOOSE_INITIATE_RENEWAL.LABELS.INITIATE_RENEWAL'),
                    value: 'yes',
                    icon: RequestQuoteTwoTone,
                  },
                ],
              }}
            />
          </FormSection>
        </InnerScrollable>
      </Content>
      <InitiateRequestBetaDialog
        isOpened={betaWorkflowsDialogOpened}
        onClose={() => setBetaWorkflowsDialogOpened(false)}
        onIWRequestClick={initiateRenewalRequest}
        onLegacyRequestClick={goToConfirmRenewalRequirements}
        requestType="RENEWAL"
        isLoading={isCreatingRequest}
        isDisabled={isCreatingRequest}
      />
    </>
  );
};

export default ChooseInitiateRenewal;

const formProductsToRenewalRequestProducts = (products: FormDataProductItem[]): Product[] => {
  return (
    products.map(
      (product) =>
        ({
          id: product.productId ?? undefined,
          name: product.name ?? null ?? undefined,
          productId: product.productId ?? undefined,
          annualCost: product.annualCost ?? undefined,
          numberOfLicences: product.numberOfLicences ?? undefined,
        } ?? undefined)
    ) ?? []
  );
};
