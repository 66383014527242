import React, { FC } from 'react';
import { Product, useListVendorProductsQuery } from '@vertice/slices';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';
import { createRequiredContext } from '@verticeone/design-system/src/utils/contexts';
import { useTiers } from './useTiers';
import { ProductItem, Tier } from './types';

type ProductsContextProviderProps = {
  currency?: string | null;
  vendorId?: string;
  selectedProducts: ProductItem[];
  children: string | JSX.Element | JSX.Element[];
};

type ProductsContextType = {
  vendorProducts: Product[];
  isLoading: boolean;
  currency: string;
  tiers: Tier[];
  addTier: (label: string) => Tier;
};

const { VendorProductsContextProvider: RawProductsContextProvider, useVendorProductsContext } = createRequiredContext<
  ProductsContextType,
  'VendorProducts'
>('VendorProducts');

export { useVendorProductsContext };

const VendorProductsContextProvider: FC<ProductsContextProviderProps> = ({
  vendorId,
  currency,
  selectedProducts,
  children,
}) => {
  const { data: vendorProducts, isLoading } = useListVendorProductsQuery({ vendorId: vendorId! }, { skip: !vendorId });

  const { tiers, addTier } = useTiers({ selectedProducts });

  const context: ProductsContextType = {
    vendorProducts: vendorId ? vendorProducts?.products || [] : [],
    isLoading,
    currency: currency || DEFAULT_CURRENCY,
    tiers: tiers,
    addTier,
  };

  return <RawProductsContextProvider value={context}>{children}</RawProductsContextProvider>;
};

export default VendorProductsContextProvider;
