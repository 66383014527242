import { ROUTES, VENDOR_ROUTE_OPTIONS } from '@vertice/utils/src/constants/cfa/constants';
import { useMemo } from 'react';
import { useLoggedUser } from '@verticeone/auth/src';
import { useUserOwnerDepartments } from './useUserOwnerDepartments';
import useLoggedUserAccountRoles from './useLoggedUserAccountRoles';

const useIntakeForm = () => {
  const { userId } = useLoggedUser();

  const { isLoading: isLoadingAccountRoles, isUserAdmin, isUserPowerUser } = useLoggedUserAccountRoles();
  const isAdminOrPowerUser = isUserAdmin || isUserPowerUser;
  const { departments, isLoading: isLoadingDepartments } = useUserOwnerDepartments({
    userId: userId!,
    skip: isAdminOrPowerUser || isLoadingAccountRoles || !userId,
  });

  return useMemo(
    () => ({
      vendorAddExistingContractRoute: VENDOR_ROUTE_OPTIONS.ADD_EXISTING_CONTRACT,
      existingContractRoute: ROUTES.INTAKE_EXISTING_CONTRACT,
      newPurchaseRoute: VENDOR_ROUTE_OPTIONS.NEW_PURCHASE,
      isLoading: isLoadingDepartments || isLoadingAccountRoles,
      userCanAddExistingContract: isAdminOrPowerUser || !!departments?.length,
    }),
    [departments, isAdminOrPowerUser, isLoadingAccountRoles, isLoadingDepartments]
  );
};

export default useIntakeForm;
