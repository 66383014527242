import React, { useMemo } from 'react';
import ContractFormEntry, { ContractFormEntryPropsWithFixedComponents } from '../../ContractFormEntry';
import FormReadOnlyText, { FormReadOnlyTextType } from '@vertice/core/src/modules/forms/fields/FormReadOnlyText';
import FormSelectField2, {
  FormSelectFieldComponentType,
} from '@vertice/core/src/modules/forms/fields/FormSelectField2';
import { keyBy } from 'lodash';
import { ContractFormData } from '../../types';
import { SimpleOption } from '@verticeone/design-system/src/components/Select';
import { useAccountDepartments } from '../../../../../hooks/useAccountDepartments';

type ReadComponent = FormReadOnlyTextType<ContractFormData>;
type WriteComponent = FormSelectFieldComponentType<ContractFormData, SimpleOption, false>;

const DepartmentFormEntry = (options: ContractFormEntryPropsWithFixedComponents<ReadComponent, WriteComponent>) => {
  const { data: loadedDepartmentList } = useAccountDepartments();

  const departmentOptions = useMemo(
    () =>
      loadedDepartmentList?.map((department) => ({
        value: department.departmentId,
        label: department.name,
      })),
    [loadedDepartmentList]
  );

  const departmentMap = useMemo(() => keyBy(departmentOptions, 'value'), [departmentOptions]);

  return (
    <ContractFormEntry<
      FormReadOnlyTextType<ContractFormData>,
      FormSelectFieldComponentType<ContractFormData, SimpleOption, false>
    >
      {...options}
      component={FormReadOnlyText}
      componentProps={{
        formatter: (value?: string | null) => (value ? departmentMap[value]?.label : '-'),
        ...options.componentProps,
      }}
      writeComponent={FormSelectField2}
      writeComponentProps={{
        ...options.writeComponentProps,
        options: departmentOptions,
        isClearable: false,
        isLoading: !departmentOptions,
        boxValue: (value) => (value ? departmentMap[value as string] : undefined),
        unboxValue: (selectValue) => selectValue?.value,
      }}
    />
  );
};

export default DepartmentFormEntry;
