import React from 'react';
import { useTranslation } from 'react-i18next';
import { GridRowModel } from '@mui/x-data-grid-pro';
import useAccountsData, { TableRow } from '../useAccountsData';
import { useCloudAccounts } from '../../CloudAccountsContext';
import { getTableHeight } from '../utils';
import BaseCloudAccountTable from './BaseCloudAccountTable';

const CloudAccountsTable = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.ACCOUNTS.TABLE' });
  const { getLastNode, addChildNode, period } = useCloudAccounts();
  const { search, loadingHeight } = getLastNode();
  const { data, ...loadingStatus } = useAccountsData(period);
  const filteredData = data?.tableData?.filter((row) =>
    row.accountName?.toLowerCase().includes(search?.toLowerCase() || '')
  );

  const getRowId = (row: GridRowModel) => row.accountId;

  const calculatedHeight = getTableHeight(filteredData);

  const handleRowClick = ({ row }: GridRowModel) => {
    addChildNode({
      id: row.accountId,
      label: row.accountName,
      type: 'account',
      loadingHeight: calculatedHeight,
    });
  };

  return (
    <BaseCloudAccountTable<TableRow>
      height={loadingStatus.isLoading && loadingHeight ? loadingHeight : calculatedHeight}
      data={filteredData || []}
      firstColumn={{
        field: 'accountName',
        headerName: t('COLUMN.ACCOUNT_NAME'),
      }}
      getRowId={getRowId}
      onRowClick={handleRowClick}
      isLoading={loadingStatus.isLoading}
    />
  );
};

export default CloudAccountsTable;
