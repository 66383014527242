export function removeNullables<T extends Record<string, unknown>>(obj: T): T {
  const res = Object.entries(obj).reduce((acc: { [key: string]: any }, [key, value]) => {
    if (value) {
      acc[key] = value;
    }
    return acc;
  }, {});

  return res as T;
}
