import React from 'react';
import { Checkbox, Stack, styled } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import Text from '@verticeone/design-system/src/components/Text';
import { useWizard, type WizardStepProps } from '@vertice/core/src/components/Wizard';
import { Buttons, Content, Header, InnerScrollable } from '@vertice/core/src/components/Dialog/Components';
import { ContentWrapper } from './Components';
import { useConnectIntegration } from '../useConnectIntegration';
import { useIntegrationFeatures } from './useIntegrationFeatures';

type FidelityItemProps = {
  title: string;
  content: string;
  onChange: () => void;
  isActive: boolean;
  isSubItem?: boolean;
};

export type IntegrationDetailsProps = {
  mergeIntegrationId: string;
  providerCode: string;
  translationId: string;
  onSuccess: () => void;
};

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  width: '38px',
  height: '38px',
  '& .MuiSvgIcon-root': {
    fontSize: 28,
    color: theme.palette.inactive.color2,
  },
}));

const FidelityItem = ({ title, content, onChange, isActive, isSubItem = false }: FidelityItemProps) => (
  <Stack
    gap={1}
    marginLeft={isSubItem ? 6 : 0}
    marginTop={2}
    direction="row"
    // onClick={onChange} /*VMVP-6203*/
    sx={{ cursor: 'pointer' }}
  >
    <Stack justifyContent="center">
      <StyledCheckbox checked={isActive} disabled={true} /*VMVP-6203*/ />
    </Stack>
    <Stack>
      <Text variant="caption" size="M" color="text1">
        <Trans i18nKey={title} />
      </Text>
      <Text variant="body-regular" size="M" color="text3">
        <Trans i18nKey={content} />
      </Text>
    </Stack>
  </Stack>
);

const IntegrationDetails = ({
  mergeIntegrationId,
  providerCode,
  translationId,
  onSuccess,
}: IntegrationDetailsProps & WizardStepProps) => {
  const { t } = useTranslation();
  const wizard = useWizard();
  const { isConnecting, connect } = useConnectIntegration({ providerCode, onSuccess });
  const { hasFeatureRecords, getFeatureAllowedRecords, changeFeatureRecords, isFeatureEnabled, changeFeature } =
    useIntegrationFeatures();

  return (
    <Content>
      <Header
        title={t('PREFERENCES.INTEGRATIONS.ACCOUNTING.WIZARD.INTEGRATION_DETAIL.TITLE')}
        subtitle={t('PREFERENCES.INTEGRATIONS.ACCOUNTING.WIZARD.INTEGRATION_DETAIL.SUB_TITLE')}
      />
      <InnerScrollable padding={0}>
        <ContentWrapper>
          <Text variant="caption" size="XS" color="text2" marginBottom={1}>
            {t('PREFERENCES.INTEGRATIONS.ACCOUNTING.WIZARD.INTEGRATION_DETAIL.FIDELITY.TITLE')}
          </Text>
          <FidelityItem
            onChange={() => changeFeature('spendInsights')}
            isActive={isFeatureEnabled('spendInsights')}
            title={t('PREFERENCES.INTEGRATIONS.ACCOUNTING.WIZARD.INTEGRATION_DETAIL.FIDELITY.SPEND.TITLE')}
            content={t('PREFERENCES.INTEGRATIONS.ACCOUNTING.WIZARD.INTEGRATION_DETAIL.FIDELITY.SPEND.CONTENT', {
              systemName: t(`PREFERENCES.INTEGRATIONS.ACCOUNTING.${translationId}.TITLE`),
            })}
          />
          <FidelityItem
            isSubItem
            onChange={() => changeFeatureRecords('spendInsights', ['contact', 'invoice'])}
            isActive={hasFeatureRecords('spendInsights', ['contact', 'invoice'])}
            title={t('PREFERENCES.INTEGRATIONS.ACCOUNTING.WIZARD.INTEGRATION_DETAIL.FIDELITY.INVOICES.TITLE')}
            content={t('PREFERENCES.INTEGRATIONS.ACCOUNTING.WIZARD.INTEGRATION_DETAIL.FIDELITY.INVOICES.CONTENT')}
          />
        </ContentWrapper>
      </InnerScrollable>
      <Buttons
        secondary={{
          onClick: () => wizard.goToStep('setup'),
          title: t('DIALOG.BUTTONS.BACK'),
        }}
        primary={{
          isLoading: isConnecting,
          onClick: () =>
            connect({
              mergeIntegrationId,
              category: 'accounting',
              features: [
                {
                  id: 'spendInsights',
                  enabled: isFeatureEnabled('spendInsights'),
                  allowedRecords: getFeatureAllowedRecords('spendInsights'),
                },
              ],
            }),
          title: t('PREFERENCES.INTEGRATIONS.CONNECT'),
        }}
      />
    </Content>
  );
};

export default IntegrationDetails;
