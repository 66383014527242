import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import format from 'date-fns/format';
import { addMonths, startOfMonth } from 'date-fns';
import {
  ForecastPrecisionType,
  TimeSeriesDataValue,
  useUsageCostPerRecordTypeQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useDeferredQuery from '@vertice/core/src/utils/api/useDeferredQuery';
import {
  CategoryValues,
  DATE_FORMAT,
  fillMissingMonths,
  findFirstCurrency,
  getSeriesByCategory,
  sumValuesByDimension,
} from '../../utils/graphDataUtils';
import { categoryTranslationMap, CategoryType } from './categoryTranslationMap';
import LoadableAdvanced from '@vertice/utils/src/loadableAdvanced';
import { flatMap, sum } from 'lodash';
import { SeriesOptionsWithData } from '@vertice/core/src/components/charts/highcharts-specific/types';

const startDate = addMonths(startOfMonth(new Date()), -11);

type UsedCategories = {
  positive: string[];
  negative: string[];
};

export type SpendByTransactionTypeData = {
  currency?: string;

  /** All months in the X axis in the format of yyyy-MM-dd. */
  months: string[];

  /** The list of transaction type categories with the monetary values. The number of values should correspond to the number of months. */
  values: SeriesOptionsWithData[];

  totals: number[];

  /** Order of series from top to bottom separately for positive and negative series */
  usedCategories: UsedCategories;
};

const useSpendByTransactionTypeData = (): LoadableAdvanced<SpendByTransactionTypeData> => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.SPEND_BY_TRANSACTION_TYPE.CATEGORY' });
  const { accountId } = useSelector(getAccount);
  const { data: rawData, error } = useDeferredQuery(
    useUsageCostPerRecordTypeQuery,
    {
      accountId: accountId!,
      // Acceptable timezone inaccuracy: We're sending local timezone date to UTC endpoint
      startDate: format(startDate, DATE_FORMAT),
      precision: ForecastPrecisionType.Month,
    },
    { skip: !accountId, pollingInterval: 5000 },
    ({ costUsageQuery }) => costUsageQuery
  );

  const getDataSeries = (monthlyData: TimeSeriesDataValue[][], usedCategories: UsedCategories) => {
    // Negative values are stacked in the opposite order in column HighChart
    const categoryChartOrder = [...usedCategories.positive, ...usedCategories.negative.slice().reverse()];
    const seriesByCategory = getSeriesByCategory(monthlyData, categoryChartOrder);

    return seriesByCategory.map((categorySeries: CategoryValues) => {
      const translationKey = categoryTranslationMap[categorySeries.id as CategoryType];
      return {
        ...categorySeries,
        name: translationKey ? t(translationKey) : categorySeries.id,
        type: 'column',
      };
    });
  };

  const getUsedCategories = (monthlyData: TimeSeriesDataValue[][]): UsedCategories => {
    const usedCategories = sumValuesByDimension(monthlyData);

    // We try to get the bigger positive values on top and negative values on the bottom to avoid the small values with the rounded corners
    return Object.entries(usedCategories)
      .sort(([, a], [, b]) => b - a)
      .reduce(
        (acc, [name, value]) =>
          value < 0 ? { ...acc, negative: [...acc.negative, name] } : { ...acc, positive: [...acc.positive, name] },
        { positive: [], negative: [] } as UsedCategories
      );
  };

  const computed = useMemo(() => {
    if (!rawData) return undefined;
    const allMonthsData = fillMissingMonths(rawData, startDate, []);
    const allMonthsDataValues = allMonthsData.map(({ values }) => values);
    const usedCategories = getUsedCategories(allMonthsDataValues);
    return {
      months: allMonthsData.map(({ time }) => time!),
      values: getDataSeries(allMonthsDataValues, usedCategories) as SeriesOptionsWithData[],
      totals: allMonthsDataValues.map((itemsInMonth) =>
        sum(flatMap(itemsInMonth, ({ values: itemValues }) => itemValues))
      ),
      currency: findFirstCurrency(allMonthsData),
      usedCategories,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData]);

  return {
    error: error,
    isEmpty: Boolean(rawData && rawData.length === 0),
    isLoading: !computed,
    data: computed,
  };
};

export default useSpendByTransactionTypeData;
