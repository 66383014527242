import { FileWithPath } from 'react-dropzone';

const bytesInMegabyte = 1048576;

const forbiddenFileNameCharacters = ['+'];

const maxFileNameLength = 128;

const maxFileSizeMegabytes = 10; // Mb
const maxFileSizeBytes = maxFileSizeMegabytes * bytesInMegabyte;
const isSizeExceed = (fileSizeBytes: number) => fileSizeBytes > maxFileSizeBytes;

/**
 * @deprecated You probably want to use the Design System Fileupload's built-in validation, see
 * `@verticeone/design-system/src/components/Fileupload/supportedFileTypes`
 */
export const csvFileCriteria: FileCriteria = {
  extensions: ['.csv'],
  mimeTypes: ['text/csv'],
};

/**
 * @deprecated You probably want to use the Design System Fileupload's built-in validation, see
 * `@verticeone/design-system/src/components/Fileupload/supportedFileTypes`
 */
export const contractFileCriteria: FileCriteria = {
  extensions: ['.jpeg', '.jpg', '.png', '.xls', '.xlsx', '.doc', '.docx', '.ppt', '.pptx', '.txt', '.pdf'],
  mimeTypes: [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'text/plain',
    'application/pdf',
  ],
};

export type FileCriteria = {
  extensions: string[];
  mimeTypes: string[];
};

/**
 * Validates a file object against specified criteria
 * @return
 *   - Error message in case of problems
 *   - Empty string in case of a valid file.
 *
 * @deprecated You probably want to use the Design System Fileupload's built-in validation, see
 * `@verticeone/design-system/src/components/Fileupload/supportedFileTypes`
 */
export const validateFile = (file: FileWithPath, acceptedFileCriteria: FileCriteria): string => {
  const { extensions, mimeTypes } = acceptedFileCriteria;
  const invalidFileMessageArr = [];

  // TODO: Make all the messages below translatable

  if (!mimeTypes.includes(file.type)) {
    invalidFileMessageArr.push(
      `Unsupported file. Accepted file types: ${extensions.map((v) => v.split('.')[1]).join(', ')}.`
    );
  }
  if (isSizeExceed(file.size)) {
    invalidFileMessageArr.push(`Too big file. Accepted file size: ${maxFileSizeMegabytes}Mb.`);
  }
  if (file.name.length > maxFileNameLength) {
    invalidFileMessageArr.push(`Max file name is ${maxFileNameLength} chars (including extension).`);
  }
  if (forbiddenFileNameCharacters.some((char: string) => file.name.includes(char))) {
    invalidFileMessageArr.push(`File name doesn't support following chars: ${forbiddenFileNameCharacters.join(', ')}.`);
  }
  return invalidFileMessageArr.join('\n\n');
};
