import { Stack } from '@mui/material';
import React, { useId } from 'react';
import TextFieldCaption from '@verticeone/design-system/src/components/TextFieldCaption';
import FormControl from '@mui/material/FormControl';
import { useIsContractFieldVisible } from '../../hooks/fieldsRulesHooks';
import { useTranslation } from 'react-i18next';
import useFormatContractCurrency from '../../hooks/useFormatContractCurrency';
import LargeValue from '../LargeValue';
import useContractFormDataSelector from '../../hooks/useContractFormDataSelector';
import { getFutureSavings, getAnnualFutureSavings } from '../../../savings/computed';

const FutureSavingsContractFormEntry = () => {
  const { t } = useTranslation();
  const formatContractCurrency = useFormatContractCurrency();
  const id = useId();

  const annualFutureSavings = useContractFormDataSelector(getAnnualFutureSavings);
  const futureSavings = useContractFormDataSelector(getFutureSavings);
  const isFutureSavingsVisible = useIsContractFieldVisible('parts.overview.unrealizedConcessions');

  if (!isFutureSavingsVisible) return null;

  return (
    <FormControl variant="outlined" fullWidth>
      <Stack gap={1}>
        <TextFieldCaption
          htmlFor={id}
          label={t('ENTITIES.CONTRACT.LABELS.FUTURE_SAVINGS')}
          size="XS"
          tooltip={{
            title: t('ENTITIES.CONTRACT.LABELS.FUTURE_SAVINGS'),
            content: t('ENTITIES.CONTRACT.TOOLTIPS.FUTURE_SAVINGS'),
          }}
        />
        <LargeValue
          id={id}
          size="S"
          primaryValue={formatContractCurrency(annualFutureSavings)}
          primaryTestId="annualFutureSavingsValue"
          secondaryValue={t('ENTITIES.CONTRACT.LABELS.N_TCV', { value: formatContractCurrency(futureSavings) })}
          secondaryTestId="futureSavingsValue"
        />
      </Stack>
    </FormControl>
  );
};

export default FutureSavingsContractFormEntry;
