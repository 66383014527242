import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import {
  ForecastPrecisionType,
  useUsageCostPerServiceQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import format from 'date-fns/format';
import { addMonths, startOfMonth } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { chain } from 'lodash';
import useDeferredQuery from '@vertice/core/src/utils/api/useDeferredQuery';
import { DATE_FORMAT, findFirstCurrency, sumPositiveTimeStreamValues } from '../../utils/graphDataUtils';
import LoadableAdvanced from '@vertice/utils/src/loadableAdvanced';

const monthBeginning = startOfMonth(new Date());

type ProductBySpend = {
  product: string;
  value: number;
  previousValue?: number;
};

export type TopProductsBySpendData = {
  currency?: string;
  items: ProductBySpend[];
};

const useTopProductsBySpendData = (monthWindow = 6): LoadableAdvanced<TopProductsBySpendData> => {
  const { accountId } = useSelector(getAccount);
  const [, setIsUpToDate] = useState(false);
  const startDate = addMonths(monthBeginning, -monthWindow);
  const previousStartDate = addMonths(monthBeginning, -(2 * monthWindow));

  useEffect(() => {
    setIsUpToDate(false);
  }, [monthWindow]);

  const { data: rawData, error: dataError } = useDeferredQuery(
    useUsageCostPerServiceQuery,
    {
      accountId: accountId!,
      // Acceptable timezone inaccuracy: We're sending local timezone date to UTC endpoint
      startDate: format(startDate, DATE_FORMAT),
      endDate: format(monthBeginning, DATE_FORMAT),
      precision: ForecastPrecisionType.Month,
    },
    { skip: !accountId, pollingInterval: 5000 },
    ({ costUsageQuery }) => costUsageQuery
  );

  const { data: rawPreviousData, error: previousDataError } = useDeferredQuery(
    useUsageCostPerServiceQuery,
    {
      accountId: accountId!,
      // Acceptable timezone inaccuracy: We're sending local timezone date to UTC endpoint
      startDate: format(previousStartDate, DATE_FORMAT),
      endDate: format(startDate, DATE_FORMAT),
      precision: ForecastPrecisionType.Month,
    },
    { skip: !accountId, pollingInterval: 5000 },
    ({ costUsageQuery }) => costUsageQuery
  );

  const computed = useMemo(() => {
    if (!rawData || !rawPreviousData) return undefined;

    setIsUpToDate(true);

    const previousItems = chain(rawPreviousData)
      .flatMap((x) => x.values)
      .groupBy((x) => x.dimensions[0])
      .map((valuesPerService, service) => ({
        product: service,
        value: sumPositiveTimeStreamValues(valuesPerService),
      }))
      .keyBy('product')
      .value();

    const items = chain(rawData)
      .flatMap((x) => x.values)
      .groupBy((x) => x.dimensions[0])
      .map((valuesPerService, service) => ({
        product: service,
        value: sumPositiveTimeStreamValues(valuesPerService),
        previousValue: previousItems[service]?.value,
      }))
      .orderBy('value', 'desc')
      .value();

    return {
      currency: findFirstCurrency(rawData),
      items,
    };
  }, [rawData, rawPreviousData]);

  return {
    error: dataError || previousDataError,
    isEmpty: Boolean(rawData && rawData.length === 0),
    isLoading: !computed,
    data: computed,
  };
};

export default useTopProductsBySpendData;
