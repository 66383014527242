import React, { useRef } from 'react';
import { AuthContextData, AuthenticationContextProvider } from './authentication/AuthenticationContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AuthorizationContext from './authorization/AuthorizationContext';

type AuthProviderProps = {
  authServiceBaseUrl: string;
  children: React.ReactNode;
  authentication: AuthContextData;
};

const AuthProvider = ({ children, authServiceBaseUrl, authentication }: AuthProviderProps) => {
  const queryClient = useRef(new QueryClient());

  return (
    <QueryClientProvider client={queryClient.current}>
      <AuthenticationContextProvider value={authentication}>
        <AuthorizationContext baseUrl={authServiceBaseUrl}>
          {children}
        </AuthorizationContext>
      </AuthenticationContextProvider>
    </QueryClientProvider>
  );
};

export default AuthProvider;