import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import {
  RiResult,
  useRiPurchasesQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useMemo } from 'react';
import LoadableAdvanced from '@vertice/utils/src/loadableAdvanced';
import getRICost, { Price } from '../../utils/getRiCost';
import dayjs from 'dayjs';
import { removeNullables } from '@vertice/utils';

type RIResultItem = {
  id?: string;
  details?: RiResult;
  createdAt?: string;
  purchasedAt?: string;
  purchasePlannedAt?: string;
};

type RIPurchaseResult = {
  items?: RIResultItem[];
  nextToken?: string;
};

export type RIDetails = {
  offeringClass: string;
  term: number;
  paymentOption: string;
  tenancy: string;
};

export type ReservedInstance = {
  id: string;
  region: string;
  familySize: string;
  totalCost: number;
  quantity: number;
  dateOfExecution?: string;
  purchasePlannedAt?: string;
  purchasedAt?: string;
  details: RIDetails;
  nextToken?: string;
};

export type ReservedInstancesResult = {
  items?: ReservedInstance[];
  nextToken?: string;
};

export const processQueryData = (reservedInstancesItems: RIPurchaseResult) => {
  const { items, nextToken } = reservedInstancesItems || {};

  const resultItems = items?.map((item) => {
    const { details } = item;

    return {
      id: item.id,
      totalCost: getRICost(
        details?.offering?.type,
        details?.instance?.count,
        details?.duration,
        details?.price as Price
      ),
      quantity: details?.instance?.count ?? 0,
      region: details?.region,
      familySize: details?.instance?.type,
      scheduledAt: item.createdAt,
      purchasePlannedAt: item.purchasePlannedAt,
      purchasedAt: item.purchasedAt,
      service: details?.service,
      details: {
        offeringClass: details?.offering?.class,
        term: details?.duration ? Math.round(dayjs.duration(details.duration * 1000).asMonths()) : 0,
        paymentOption: details?.offering?.type,
        tenancy: details?.instance?.tenancy,
      },
    };
  }) as ReservedInstance[];

  return { items: resultItems, nextToken };
};

type RiPurchaseDataProps = {
  isPending?: boolean;
  nextToken?: string | null;
};

const useRIPurchaseData = ({ isPending = false, nextToken = null }: RiPurchaseDataProps = {}): LoadableAdvanced<
  ReservedInstancesResult | undefined
> => {
  const { accountId } = useSelector(getAccount);

  const { data, error, isLoading, refetch } = useRiPurchasesQuery(
    removeNullables({
      accountId: accountId!,
      limit: 100,
      status: isPending ? 'QUEUED' : 'FINISHED',
      nextToken,
    })
  );

  const processedData = useMemo(() => {
    if (
      !data ||
      data?.riPurchasesQuery?.__typename !== 'RIPurchasesResult' ||
      !data.riPurchasesQuery?.items ||
      data.riPurchasesQuery?.items.length === 0
    )
      return;

    return processQueryData(data.riPurchasesQuery);
  }, [data]);

  return {
    error: error,
    isEmpty: Boolean(processedData?.items && processedData.items?.length === 0),
    isLoading,
    data: processedData,
    refetch,
  };
};

export default useRIPurchaseData;
