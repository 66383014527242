import React, { forwardRef, RefAttributes } from 'react';
import OrigSelect, { Props as OrigSelectProps } from 'react-select';
import { Stack } from '@mui/material';
import { CustomizedProps, TestProps } from '../../types';
import Text from '../Text';
import sizeDefinitions from '../TextField/styledVariants/sizeDefinitions';
import { testProps } from '../../utils/testProperties';
import { useStyledSelectComponents } from '../SelectCommon/useStyledSelectComponents';
import { useSelectStylesConfig } from '../SelectCommon/useSelectStylesConfig';
import { CustomSelectProps, GroupBase } from '../SelectCommon/types';
import { SelectInstance } from 'react-select';
export type { PropsValue } from 'react-select';

export type SelectProps<
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
> = TestProps &
  CustomizedProps &
  OrigSelectProps<Option, IsMulti, Group> &
  RefAttributes<SelectInstance<Option, IsMulti, Group>> &
  CustomSelectProps;

export type SimpleOption = {
  value: string;
  label: string;
};

export type SelectComponentType = <
  Option extends unknown = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: SelectProps<Option, IsMulti, Group>
) => JSX.Element;

const Select = forwardRef(
  <
    Option extends unknown = unknown,
    IsMulti extends boolean = boolean,
    Group extends GroupBase<Option> = GroupBase<Option>
  >(
    {
      color = 'primary',
      variant = 'outlined',
      size = 'M',
      error = false,
      helperText,
      maxMultiChips,
      className,
      testId,
      components: componentOverrides = {},
      sx,
      ...reactSelectProps
    }: SelectProps<Option, IsMulti, Group>,
    ref: React.Ref<SelectInstance<Option, IsMulti, Group>> | undefined
  ) => {
    const customComponents = useStyledSelectComponents<Option, IsMulti, Group>(size, color, maxMultiChips);
    const sizeDef = sizeDefinitions[size];
    const stylesConfig = useSelectStylesConfig<Option, IsMulti, Group>({ color, variant, size, error });

    return (
      <Stack spacing={`${sizeDef.helperTextGap}px`} className={className} sx={sx} {...testProps(testId, 'select')}>
        <OrigSelect
          styles={stylesConfig}
          components={{ ...customComponents, ...componentOverrides }}
          ref={ref}
          maxMenuHeight={5000} // override default 300
          {...reactSelectProps}
          closeMenuOnSelect={!reactSelectProps.isMulti}
          hideSelectedOptions={false}
          menuPortalTarget={document.body}
        />
        {helperText && (
          <Text
            color={error && !reactSelectProps.isDisabled ? 'error' : 'text3'}
            variant="body-regular"
            size={sizeDef.helperTextSize}
            px={`${sizeDef.paddingX}px`}
          >
            {helperText}
          </Text>
        )}
      </Stack>
    );
  }
) as SelectComponentType;

export default Select;
