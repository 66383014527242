import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { hasDeadlineWithinDays, isPlannedForExpiration } from '@vertice/core/src/modules/saas/contract/computed';

export const isUpcomingContract = ({ contract }: ViewOnContract) => {
  const rollFrequency = contract.parts.contractual?.lifecycle?.rollFrequency || 'NO';
  if (rollFrequency !== 'NO') {
    return false;
  }
  const actualStage = contract.materialized?.simplifiedStage;
  if (actualStage !== 'LIVE' && actualStage !== 'NOT_YET_LIVE') {
    return false;
  }
  const followingStage = contract.materialized?.next?.simplifiedStage;
  if (followingStage && !['CANCELLED_FIXED_TERM', 'CANCELLED_REQUEST'].includes(followingStage)) {
    return false;
  }

  if (isPlannedForExpiration(contract)) {
    return false;
  }

  return hasDeadlineWithinDays(contract, 91);
};
