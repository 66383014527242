import { FC, useCallback } from 'react';
import { CommonIntakeFormFormData, FormDataProductItem } from '../types';
import { useFormContext, useWatch } from 'react-hook-form';
import { ProductsList } from '@vertice/core/src/modules/saas/contract/components/ProductList';

type IntakeProductListProps = {
  showAnnualCost?: boolean;
};
export const IntakeProductList: FC<IntakeProductListProps> = ({ showAnnualCost }) => {
  const { setValue } = useFormContext<CommonIntakeFormFormData>();

  const products = useWatch<CommonIntakeFormFormData, 'products'>({ name: 'products' });
  const contractCurrency = useWatch<CommonIntakeFormFormData, 'contractCurrency'>({ name: 'contractCurrency' });
  const vendor = useWatch<CommonIntakeFormFormData, 'vendor'>({ name: 'vendor' });

  const setProducts = useCallback(
    (productsToSet: FormDataProductItem[]) => {
      setValue('products', productsToSet);
    },
    [setValue]
  );

  return (
    <ProductsList
      withActions
      showAnnualCost={showAnnualCost}
      selectedProducts={products || []}
      setSelectedProducts={setProducts}
      currency={contractCurrency}
      vendorId={vendor?.id ?? undefined}
    />
  );
};
