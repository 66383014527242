import React, { CSSProperties, FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone, type FileWithPath as FileWPath } from 'react-dropzone';
import { Stack, useTheme } from '@mui/material';
import { LaptopOutlined } from '@mui/icons-material';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { contractFileCriteria, validateFile } from '@vertice/utils';
import Text from '@verticeone/design-system/src/components/Text';
import IconButton from '@verticeone/design-system/src/components/IconButton';

export type FileWithPath = FileWPath;

export interface DragDropAreaProps {
  handleFile: (file: FileWithPath) => void;
}

/** @deprecated use Dropzone from design system instead */
const FilesDropArea: FC<DragDropAreaProps> = ({ handleFile }) => {
  // TODO: Rewrite using Fileupload from Design System

  const { t } = useTranslation();
  const { palette } = useTheme();

  const onDropAccepted = (newFiles: FileWithPath[]) => {
    const file = newFiles[0];
    const invalidFileMessage = validateFile(file, contractFileCriteria);

    if (invalidFileMessage) {
      // eslint-disable-next-line no-alert
      alert(invalidFileMessage);
      return;
    }

    handleFile(file);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    multiple: false,
    onDropAccepted,
  });

  const style: CSSProperties = useMemo(
    () => ({
      borderColor: isDragAccept ? palette.neutral.color2 : palette.neutral.color4,
      borderStyle: 'solid',
      borderRadius: 8,
      cursor: 'pointer',
      borderWidth: 1,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      <Stack direction="column" alignItems="center" justifyContent="center" height={200} gap={10}>
        <Text variant="body-regular" size="M" color={palette.text.color3}>
          {t('INTAKE_FORM.FILE_UPLOAD.DRAG_AND_DROP_OR')}
        </Text>
        <Stack gap={2} direction="column" alignItems="center">
          <IconButton icon={LaptopOutlined} size="M" color="neutral" variant="ghost" />
          <Text variant="caption" size="S">
            {t('LEGAL_INFO.BROWSE')}
          </Text>
        </Stack>
      </Stack>
    </div>
  );
};

export default FilesDropArea;
