import React from 'react';
import { useTheme } from '@mui/material';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { gridClasses, GridColDef, GridRowModel } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { useFormatCurrency } from '@vertice/core/src/utils/formatting/currency';
import { AWS_BRAND_COLOR, AWS_DEFAULT_CURRENCY } from '../../constants';
import DataGrid from '@verticeone/design-system/src/components/DataGrid';
import { UnattachedIPv4Data } from './types';

type UnattachedIPv4TableProps = {
  data: UnattachedIPv4Data;
};

const ROW_COUNT = 100;

const UnattachedIPv4Table = ({ data }: UnattachedIPv4TableProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.UNATTACHED_IPV4' });
  const { locale } = useLocaleContext();
  const theme = useTheme();
  const formatCurrency = useFormatCurrency();
  const getRowId = (row: GridRowModel) => `${row.accountId}-${row.region}-${row.cost}`;

  if (!data) {
    return null;
  }

  const columns: GridColDef[] = [
    {
      field: 'resourceId',
      headerName: t('COLUMN.RESOURCE_ID'),
      editable: false,
      renderCell: ({ value }) => {
        return !value ? t('NA') : value;
      },
      width: 180,
    },
    {
      field: 'region',
      headerName: t('COLUMN.REGION'),
      editable: false,
      flex: 1,
    },
    {
      field: 'accountName',
      headerName: t('COLUMN.ACCOUNT_NAME'),
      editable: false,
      minWidth: 240,
      flex: 1,
    },
    {
      field: 'addressType',
      headerName: t('COLUMN.TYPE'),
      editable: false,
      flex: 1,
    },
    {
      field: 'usage',
      headerName: t('COLUMN.USAGE'),
      renderCell: ({ value }) => {
        return new Intl.NumberFormat(locale, { maximumFractionDigits: 0 }).format(Number(value));
      },
      editable: false,
      flex: 1,
    },
    {
      field: 'cost',
      headerName: t('COLUMN.COST'),
      renderCell: ({ value }) => {
        return formatCurrency(value, {
          currency: AWS_DEFAULT_CURRENCY,
          maximumFractionDigits: 0,
        });
      },
      editable: false,
      flex: 1,
    },
  ];

  return (
    <DataGrid
      columns={columns}
      rows={data.items.slice(0, ROW_COUNT) || []}
      testId="unattached-ipv4-datagrid"
      sortingMode="client"
      color={AWS_BRAND_COLOR}
      disableRowSelectionOnClick
      showColumnVerticalBorder
      showCellVerticalBorder
      size={'M'}
      hideFooter
      getRowId={getRowId}
      noBorder
      noBorderRadius
      scrollbarSize={12}
      sx={{
        flex: 1,
        [`.${gridClasses.cell}, .${gridClasses.columnHeader}`]: {
          paddingLeft: theme.spacing(6),
          paddingRight: theme.spacing(6),
        },
      }}
    />
  );
};

export default UnattachedIPv4Table;
