import { ZodOptional, ZodTypeAny, ZodNullable, ZodDefault, ZodObject, ZodEffects, ZodUnion } from 'zod';

export const setRequiredTo = <T extends ZodTypeAny>(
  schema: T,
  isRequired: boolean
): ZodOptional<ZodNullable<T>> | T => {
  if (!isRequired) {
    return schema.nullish();
  }

  while (schema instanceof ZodOptional || schema instanceof ZodNullable) {
    schema = schema.unwrap();
  }
  return schema;
};

export const makeRequired = <T extends ZodTypeAny>(schema: T): ZodOptional<ZodNullable<T>> | T => {
  return setRequiredTo(schema, true);
};

export const isNestedFieldOptional = (schema: ZodTypeAny, path: string): boolean => {
  const keys = path.split('.');
  let currentSchema: ZodTypeAny | undefined = schema;

  for (const key of keys) {
    if (currentSchema instanceof ZodOptional) {
      return true;
    }
    if (currentSchema instanceof ZodObject) {
      currentSchema = currentSchema.shape[key];
    } else if (currentSchema instanceof ZodEffects) {
      // eslint-disable-next-line no-underscore-dangle
      currentSchema = currentSchema._def.schema.shape[key];
    } else {
      return false;
    }
  }

  if (currentSchema instanceof ZodUnion) {
    // Quite specific solution... we're ignoring the other branch...
    currentSchema = currentSchema.options[0];
  }

  return currentSchema instanceof ZodOptional || currentSchema instanceof ZodDefault;
};
