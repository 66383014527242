import ContractFormEntry from '../../ContractFormEntry';
import React, { useEffect } from 'react';
import AutoRenewalWaivedCheckboxField from '../AutoRenewalWaivedCheckboxField';
import { useWatch } from 'react-hook-form';
import { ContractFormData } from '../../types';
import { isNil } from 'lodash';
import { useContractContext } from '../../ContractContext';

type Component = typeof AutoRenewalWaivedCheckboxField;

const AutoRenewalWaivedFormEntry = () => {
  const contractContext = useContractContext('DONT_REQUIRE_FETCHED');
  const autoRenewalDeadline = useWatch<ContractFormData>({ name: 'parts.contractual.lifecycle.autoRenewalDeadline' });

  useEffect(() => {
    if (isNil(autoRenewalDeadline)) {
      contractContext.hookForm.setValue('parts.contractual.renewal.autoRenewalWaived', false);
    }
  }, [autoRenewalDeadline, contractContext.hookForm]);

  return (
    <ContractFormEntry<Component, Component>
      name="parts.contractual.renewal.autoRenewalWaived"
      width={12}
      component={AutoRenewalWaivedCheckboxField}
      readFormFieldDisabled
      writeComponent={AutoRenewalWaivedCheckboxField}
      disabled={isNil(autoRenewalDeadline)}
    />
  );
};

export default AutoRenewalWaivedFormEntry;
