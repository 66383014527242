import React from 'react';
import styled from '@mui/material/styles/styled';
import Stack, { StackProps } from '@mui/material/Stack';

type CardHeaderActionsProps = StackProps;

export const StyledCardHeaderActions = styled(Stack)({});

export const CardHeaderActions: React.FC<CardHeaderActionsProps> = ({ children, ...props }) => {
  return (
    <StyledCardHeaderActions direction="row" {...props}>
      {children}
    </StyledCardHeaderActions>
  );
};
