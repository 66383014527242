import React from 'react';
import clsx from 'clsx';
import { useNavigate, useLocation } from 'react-router';
import { Button, Typography } from '@mui/material';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import { ROUTES } from '@vertice/utils/src/constants/cfa/constants';
import { useTranslation } from 'react-i18next';
import authStyles from '../../Auth.module.scss';
import loginStyles from '../Login.module.scss';

const SSOLoginButton = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state || {};

  const handleSSOLogin = () => navigate(ROUTES.SSO_LOGIN, { state: { from } });

  return (
    <Button
      fullWidth
      variant="contained"
      className={clsx('uppercase', authStyles.formField, loginStyles.ssoButton)}
      startIcon={<VpnKeyOutlinedIcon className={clsx(authStyles.buttonIcon)} />}
      onClick={handleSSOLogin}
    >
      <Typography variant="label-s">{t('LOGIN.LOGIN_SSO')}</Typography>
    </Button>
  );
};

export default SSOLoginButton;
