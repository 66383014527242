import React from 'react';
import { useTranslation } from 'react-i18next';
import useUnattachedIPv4Data from './useUnattachedIPv4Data';
import { LoadableContentWrapper } from '../../components/LoadableContentWrapper';
import Card, { CardHeader, CardHeaderTitle } from '@verticeone/design-system/src/components/Card';
import UnattachedIPv4Table from './UnattachedIPv4Table';

const UnattachedIPv4Card = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { data, ...loadingStatus } = useUnattachedIPv4Data();
  return (
    <Card sx={{ minHeight: 480 }}>
      <CardHeader size="S">
        <CardHeaderTitle text={t('UNATTACHED_IPV4.TITLE')} tag="h2" />
      </CardHeader>
      <LoadableContentWrapper {...loadingStatus} sx={{ flex: 1 }}>
        <UnattachedIPv4Table data={data!} />
      </LoadableContentWrapper>
    </Card>
  );
};

export default UnattachedIPv4Card;
