import { eventsApi as api } from '../../api/eventsAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    listAccountEvents: build.query<ListAccountEventsApiResponse, ListAccountEventsApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/events` }),
    }),
    listContractEvents: build.query<ListContractEventsApiResponse, ListContractEventsApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/contracts/${queryArg.contractId}/events` }),
    }),
    listContractVersionEventsV2: build.query<ListContractVersionEventsV2ApiResponse, ListContractVersionEventsV2ApiArg>(
      {
        query: (queryArg) => ({
          url: `/accounts/${queryArg.accountId}/departments/${queryArg.departmentId}/contracts/${queryArg.contractId}/versions/${queryArg.version}/events`,
        }),
      }
    ),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as eventsAPICodegen };
export type ListAccountEventsApiResponse = /** status 200 Provides list of retrieved Events
 */ Event[];
export type ListAccountEventsApiArg = {
  accountId: UuidIdentifier;
};
export type ListContractEventsApiResponse = /** status 200 Provides list of retrieved Contract Events
 */ ContractEvent[];
export type ListContractEventsApiArg = {
  accountId: UuidIdentifier;
  contractId: UuidIdentifier;
};
export type ListContractVersionEventsV2ApiResponse = /** status 200 Provides list of retrieved Contract Events
 */ ContractEvent[];
export type ListContractVersionEventsV2ApiArg = {
  departmentId: UuidIdentifier;
  accountId: UuidIdentifier;
  contractId: UuidIdentifier;
  version: Version;
};
export type UuidIdentifier = string;
export type Version = number;
export type Revision = number;
export type Timestamp = string;
export type EventContext = {
  accountId?: UuidIdentifier;
  contractId?: UuidIdentifier;
  contractVendorId?: UuidIdentifier;
  contractVendorName?: string;
};
export type Event = {
  accountId?: UuidIdentifier;
  departmentId?: UuidIdentifier;
  contractId?: UuidIdentifier;
  contractVersion?: Version;
  contractPreviousVersion?: Version;
  contractRevision?: Revision;
  timestamp?: Timestamp;
  context?: EventContext;
  message?: string;
};
export type ContractEvent = {
  accountId?: UuidIdentifier;
  departmentId?: UuidIdentifier;
  contractId?: UuidIdentifier;
  contractVersion?: Version;
  contractPreviousVersion?: Version;
  contractRevision?: Revision;
  timestamp?: Timestamp;
  message?: string;
};
export const {
  useListAccountEventsQuery,
  useLazyListAccountEventsQuery,
  useListContractEventsQuery,
  useLazyListContractEventsQuery,
  useListContractVersionEventsV2Query,
  useLazyListContractVersionEventsV2Query,
} = injectedRtkApi;
