import { useSearchParams } from 'react-router-dom';
import { removeNullables } from '@vertice/utils';

const useQueryParams = () => {
  const [queryParams, setQueryParams] = useSearchParams();

  const updateQueryParams = (searchParamsToUpdate: { [key: string]: string }) => {
    // below we convert iterator queryParams into plain object
    const queryParamsObj = Object.fromEntries(queryParams.entries());

    return setQueryParams(removeNullables({ ...queryParamsObj, ...searchParamsToUpdate }));
  };

  return { queryParams, updateQueryParams };
};

export default useQueryParams;
