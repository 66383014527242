import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@verticeone/design-system/src/components/Button';
import { useContractData } from '../../ContractsDataContext';

const AddContract = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EDP_CONTRACT_GRID' });
  const { addContract } = useContractData();

  return (
    <Button size="S" variant="solid" color="tertiary" onClick={addContract}>
      {t('BUTTONS.ADD_CONTRACT')}
    </Button>
  );
};

export default AddContract;
