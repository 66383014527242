import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { ROUTES } from '@vertice/utils/src/constants/cfa/constants';

type LoginProps = {
  email: string;
  password: string;
};

const EXCEPTION_CODE_REGEX = /.*<code>(.*?)<\/code>/g;

const extractExceptionCode = (source: string, delimiterRegex: RegExp) => {
  const matches = source.matchAll(delimiterRegex);
  const result = Array.from(matches, (match) => match[1]);
  return result[0];
};

export const useHandleLogIn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState('');

  const handleLogIn = ({ email, password }: LoginProps) => {
    setError('');

    return Auth.signIn(email, password)
      .then((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          navigate(ROUTES.FORCE_NEW_PASSWORD, {
            state: { from: location.state || {}, user: { email, oldPassword: password } },
            replace: true,
          });
        }
      })
      .catch((e) => {
        if (e && typeof e === 'object' && !!Object.prototype.hasOwnProperty.call(e, 'message')) {
          const errorObject = e as { message: string; code: string };
          if (errorObject.code === 'NotAuthorizedException') {
            setError(t('LOGIN.ERROR.NOT_AUTHORIZED'));
          } else if (errorObject?.message) {
            const exceptionCode = extractExceptionCode(errorObject.message, EXCEPTION_CODE_REGEX);
            if (exceptionCode && exceptionCode === 'UserNotFoundException') {
              setError(t('LOGIN.ERROR.NOT_AUTHORIZED'));
            } else {
              setError(errorObject.message);
            }
          }
        }
      });
  };

  return { handleLogIn, error };
};
