import { useCallback, useMemo } from 'react';
import { Auth } from 'aws-amplify';
import { AmplifyUser } from '@aws-amplify/ui';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useAuthenticator } from '@aws-amplify/ui-react';

import AuthUser from '../AuthUser';
import { AuthContextData, AuthType } from '../AuthenticationContext';
import getAmplifyJwtToken from './getAmplifyJwtToken';

/** Convert Amplify user to our internal representation */
const amplifyUserToAuthUser = (user?: AmplifyUser): AuthUser | undefined => {
  const cognitoIdTokenPayload = user?.getSignInUserSession()?.getIdToken()?.payload;
  return cognitoIdTokenPayload
    ? {
        userId: cognitoIdTokenPayload['vertice:userId'] as string,
        email: cognitoIdTokenPayload.email as string,

        // Account ID nor roles are not included in Amplify JWT tokens. In CFA, they need to get fetched separately.
        accountId: undefined,
        roles: undefined,
      }
    : undefined;
};

/**
 * Abstraction over Amplify Auth that generalizes it to our unified & universal interface AuthContextData.
 *
 * In the future, it could cover even more functions like signIn, resetPassword, etc., but for now, we just stick
 * to the basic stuff.
 */
const useAmplifyAuth = (): AuthContextData => {
  const amplifyAuth = useAuthenticator((context) => [context.user]);

  const signOut = useCallback(async () => {
    await Auth.signOut();
  }, []);

  const user = useMemo(() => amplifyUserToAuthUser(amplifyAuth.user), [amplifyAuth.user]);

  return useMemo(() => ({ type: AuthType.AMPLIFY, signOut, user, getJwtToken: getAmplifyJwtToken }), [signOut, user]);
};

export default useAmplifyAuth;
