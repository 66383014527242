import React from 'react';
import Card, {
  CardHeader,
  CardHeaderDescription,
  CardHeaderSubtitle,
  CardHeaderTitle,
} from '@verticeone/design-system/src/components/Card';
import { useTranslation } from 'react-i18next';
import { Box, useTheme } from '@mui/material';
import useS3IntelligentTieringData from './useS3IntelligentTieringData';
import { LoadableContentWrapper } from '../../components/LoadableContentWrapper';
import S3IntelligentTieringGraph from './S3IntelligentTieringGraph';
import { CalendarTodayOutlined } from '@mui/icons-material';

const S3IntelligentTieringCard = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { palette } = useTheme();
  const { data, ...loadingStatus } = useS3IntelligentTieringData();

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle text={t('S3_INTELLIGENT_TIERING.TITLE')} tag="h2" />
        <CardHeaderSubtitle text={t('LAST_X_MONTHS', { monthCount: 12 })} icon={CalendarTodayOutlined} />
        <CardHeaderDescription>{t('S3_INTELLIGENT_TIERING.DESCRIPTION')}</CardHeaderDescription>
      </CardHeader>
      <Box bgcolor={palette.core.color1}>
        <LoadableContentWrapper {...loadingStatus} pt={6} pb={6}>
          <S3IntelligentTieringGraph data={data!} />
        </LoadableContentWrapper>
      </Box>
    </Card>
  );
};

export default S3IntelligentTieringCard;
