import type { DesignSystemVariant, DesignSystemColor, DesignSystemSize, Direction } from '../../types';
import styled from '@mui/material/styles/styled';
import Stack from '@mui/material/Stack';
import type { Theme } from '@mui/material/styles';
import BaseButton from '../Button/BaseButton';
import { reducedSizes } from '../../guidelines/Sizing/sizings';

type StyledButtonGroupProps = {
  $size: DesignSystemSize;
  $color: DesignSystemColor;
  $direction: Direction;
  $variant: DesignSystemVariant;
  $container: boolean;
};

// prettier-ignore
const getPadding = (size: DesignSystemSize, borderSize: `${string}px` = '0px'): string => ({
  XL: `calc(6px - ${borderSize})`,
  L: `calc(6px - ${borderSize})`,
  M: `calc(4px - ${borderSize})`,
  S: `calc(4px - ${borderSize})`,
  XS: `calc(2px - ${borderSize})`,
  XXS: `calc(2px - ${borderSize})`,
}[size]);

const directionMap: Record<Direction, 'row' | 'column'> = {
  horizontal: 'row',
  vertical: 'column',
};

const getButtonGroupBg = (theme: Theme, color: DesignSystemColor): Record<DesignSystemVariant, string> => ({
  solid: theme.palette[color].color4,
  ghost: theme.palette[color].color3,
  outline: 'transparent',
  plain: theme.palette[color].color4,
});

const BaseButtonGroup = styled(Stack)<StyledButtonGroupProps>(
  ({ theme, $container, $direction, $size, $color, $variant }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: directionMap[$direction],
    gap: $container ? reducedSizes[$size] : '0',
    padding: $container ? getPadding($size, $variant === 'outline' && $container ? '1px' : '0px') : '0',
    border: $variant === 'outline' && $container ? `solid 1px ${theme.palette[$color].color2}` : 'none',
    backgroundColor: $variant === 'ghost' && !$container ? 'transparent' : getButtonGroupBg(theme, $color)[$variant],
    borderRadius: '8px',
    [BaseButton]: {
      // prettier-ignore
      ...(!$container ? {
      '&:not(:first-child):not(:last-child)': {
        borderRadius: '0',
        '&::before': {
          borderRadius: '0',
        },
      },

      ...($direction === 'vertical' ? {
        width: '100%',
      } : {}),

      // prettier-ignore
      ...($direction === 'horizontal' ? {
        '&:first-child': {
          borderTopRightRadius: '0',
          borderBottomRightRadius: '0',
          '&::before': {
            borderTopRightRadius: '0',
            borderBottomRightRadius: '0',
          },
        },
        '&:last-child': {
          borderTopLeftRadius: '0',
          borderBottomLeftRadius: '0',
          '&::before': {
            borderTopLeftRadius: '0',
            borderBottomLeftRadius: '0',
          },
        },
        '&:not(:first-child)': {
          marginLeft: '-1px',
        },
      } : {}),

      // prettier-ignore
      ...($direction === 'vertical' ? {
        '&:first-child': {
          borderBottomLeftRadius: '0',
          borderBottomRightRadius: '0',
          '&::before': {
            borderBottomLeftRadius: '0',
            borderBottomRightRadius: '0',
          },
        },
        '&:last-child': {
          borderTopLeftRadius: '0',
          borderTopRightRadius: '0',
          '&::before': {
            borderTopLeftRadius: '0',
            borderTopRightRadius: '0',
          },
        },
        '&:not(:first-child)': {
          marginTop: '-1px',
        },
      } : {}),
    } : {}),
    },
  })
);

export default BaseButtonGroup;
