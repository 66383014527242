import { useTranslation } from 'react-i18next';
import { Stack, useTheme } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import {
  EntraSourceIcon,
  GoogleSourceIcon,
  JumpCloudSourceIcon,
  OktaSourceIcon,
  OneLoginSourceIcon,
} from '@vertice/assets';
import Text from '@verticeone/design-system/src/components/Text';
import IconWrapper from '@verticeone/design-system/src/components/IconWrapper';
import { useFormatDate } from '@vertice/core/src/utils/formatting/date';
import { isNotNil } from '@vertice/core/src/utils/validation/isNotNil';
import {
  AlarmDataLastFetched,
  AlarmDay,
  AlarmStatus,
} from '@vertice/slices/src/graphql/bffeUsage/generated/usageGraphQL';
import { chain } from 'lodash';
import { getLastUpdatedTimePeriod } from './LastFetchedInfo';

type LastFetchedTooltipProps = {
  accessLogs: AlarmDataLastFetched[];
  measureReportsGeneratedAt?: string;
  alarmsPerDay?: AlarmDay[];
  providers?: string[];
};

enum ProviderIds {
  ENTRA = 'Provider:Azure',
  GOOGLE_SAML = 'Provider:Google:Workspace:SAML',
  GOOGLE_SOCIAL_LOGIN = 'Google:Workspace:SocialLogin',
  JUMPCLOUD = 'Provider:JumpCloud',
  OKTA = 'Provider:Okta',
  ONE_LOGIN = 'Provider:OneLogin',
  SIMULATOR = 'Provider:Simulator',
}

export const getProvider = (providerName: string) => {
  const ICON_SIZE = 16;

  switch (providerName) {
    case ProviderIds.ENTRA:
      return { name: 'Entra', icon: <EntraSourceIcon width={ICON_SIZE} height={ICON_SIZE} /> };
    case ProviderIds.GOOGLE_SAML:
      return { name: 'Google SAML', icon: <GoogleSourceIcon width={ICON_SIZE} height={ICON_SIZE} /> };
    case ProviderIds.GOOGLE_SOCIAL_LOGIN:
      return { name: 'Google Social Login', icon: <GoogleSourceIcon width={ICON_SIZE} height={ICON_SIZE} /> };
    case ProviderIds.JUMPCLOUD:
      return { name: 'JumpCloud', icon: <JumpCloudSourceIcon width={ICON_SIZE} height={ICON_SIZE} /> };
    case ProviderIds.OKTA:
    case ProviderIds.SIMULATOR:
      return { name: 'Okta', icon: <OktaSourceIcon width={ICON_SIZE} height={ICON_SIZE} /> };
    case ProviderIds.ONE_LOGIN:
      return { name: 'OneLogin', icon: <OneLoginSourceIcon width={ICON_SIZE} height={ICON_SIZE} /> };
    default:
      return null;
  }
};

const isProviderWithFailedAlarms = (providers: string[], providerName: string) => providers.includes(providerName);

const LastFetchedTooltip = ({
  accessLogs,
  measureReportsGeneratedAt,
  alarmsPerDay,
  providers,
}: LastFetchedTooltipProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const formatDate = useFormatDate();

  const providersWithFailedAlarms = chain(alarmsPerDay)
    .flatMap(({ alarms }) =>
      alarms?.filter((alarm) => alarm.status === AlarmStatus.Failed).map((alarm) => alarm.providerName)
    )
    .filter(isNotNil)
    .uniq()
    .value();

  providers?.forEach((provider) => {
    const isProviderInAccessLogs = accessLogs.some((logItem) => logItem.providerName === provider);
    if (!isProviderInAccessLogs) accessLogs.push({ providerName: provider, fetchedAt: '' });
  });

  return (
    <Stack gap={1}>
      {accessLogs.map((logItem) => (
        <Stack direction="row" alignItems="center" gap={1} key={logItem.fetchedAt}>
          {isProviderWithFailedAlarms(providersWithFailedAlarms, logItem.providerName) ? (
            <IconWrapper icon={InfoIcon} size="M" htmlColor={palette.error.color3} />
          ) : (
            getProvider(logItem.providerName)?.icon
          )}
          <Text
            variant="body-regular"
            size="S"
            color={
              isProviderWithFailedAlarms(providersWithFailedAlarms, logItem.providerName)
                ? palette.error.color3
                : palette.text.color5
            }
          >
            {`${getProvider(logItem.providerName)?.name}:`}
          </Text>
          <Text
            variant="body-regular"
            size="S"
            color={
              isProviderWithFailedAlarms(providersWithFailedAlarms, logItem.providerName)
                ? palette.error.color3
                : palette.text.color4
            }
          >
            {logItem.fetchedAt
              ? formatDate(getLastUpdatedTimePeriod(logItem.fetchedAt, measureReportsGeneratedAt), {
                  formatterOptions: {
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  },
                })
              : t('APPLICATIONS.USAGE.LAST_UPDATED.NO_DATA')}
          </Text>
        </Stack>
      ))}
    </Stack>
  );
};

export default LastFetchedTooltip;
