import LoadableAdvanced from '@vertice/utils/src/loadableAdvanced';
import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import { useOptimizationCheckQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useMemo } from 'react';
import { DATE_FORMAT, fillMissingMonths, getTableData } from '../../utils/graphDataUtils';
import { addMonths, startOfMonth } from 'date-fns';
import format from 'date-fns/format';
import useDeferredQuery from '@vertice/core/src/utils/api/useDeferredQuery';
import { chain, flatMap, sumBy, uniq } from 'lodash';
import { SeriesOptionsWithData } from '@vertice/core/src/components/charts/highcharts-specific/types';

const startDate = addMonths(startOfMonth(new Date()), -12);

export type S3IntelligentTieringData = {
  /** All months in the X axis in the format of yyyy-MM-dd. */
  months: string[];

  /** The list of storages classes with storage volume. The number of values should correspond to the number of months. */
  values: SeriesOptionsWithData[];

  /** Order of series from top to bottom */
  usedCategories: string[];
};

type S3Item = {
  name: string;
  unit: string;
  usage: number;
};

const splitIntoSeries = (items: { time: string; values: Record<string, number | null> }[]) => {
  const allCategories = uniq(flatMap(items, ({ values }) => Object.keys(values)));

  // We need to take only S3 Storages into account
  const filteredCategories = allCategories.filter((item) => item.startsWith('Storage'));

  const series = chain(filteredCategories)
    .map((category) => ({
      id: category,
      name: category.substring(7).trim(),
      data: items.map(({ values: valuePerCategory }) => valuePerCategory[category] ?? null),
      type: 'column',
    }))
    .orderBy((p) => sumBy(p.data), 'desc')
    .value();

  return {
    usedCategories: series.map(({ id }) => id),
    series,
  };
};

const useS3IntelligentTieringData = (): LoadableAdvanced<S3IntelligentTieringData> => {
  const { accountId } = useSelector(getAccount);

  const {
    data: rawS3UsageData,
    error,
    isLoading,
  } = useDeferredQuery(
    useOptimizationCheckQuery,
    {
      accountId: accountId!,
      // Acceptable timezone inaccuracy: We're sending local timezone date to UTC endpoint
      startDate: format(startDate, DATE_FORMAT),
      checkCode: 'S3_COST_USAGE',
    },
    { skip: !accountId, pollingInterval: 5000 },
    ({ checkQuery }) => checkQuery
  );

  const computedData = useMemo(() => {
    if (!rawS3UsageData || rawS3UsageData.length === 0) {
      return undefined;
    }

    const allMonthsData = chain(rawS3UsageData)
      .flatMap(({ values }) => values)
      .map(({ dateStart, checkResult }) => ({
        // 2023-01-01T00:00:00 -> 2023-01-01
        time: format(new Date(dateStart), DATE_FORMAT),
        values: chain(
          getTableData(checkResult, {
            usage_type: 'name',
            pricing_unit: 'unit',
            sum_usage_amount: 'usage',
          }) as S3Item[]
        )
          .filter(({ unit }) => unit === 'GB-Mo')
          .groupBy(({ name }) => name)
          .mapValues((classItems) => sumBy(classItems, ({ usage }) => usage))
          .value(),
      }))
      .orderBy('time', 'asc')
      .thru((items) => (items.length > 0 ? fillMissingMonths(items, new Date(items[0].time), {}) : items))
      .value();

    const { series, usedCategories } = splitIntoSeries(allMonthsData);

    return {
      values: series,
      usedCategories,
      months: allMonthsData.map(({ time }) => time),
    };
  }, [rawS3UsageData]);

  return {
    isLoading,
    error,
    isEmpty: Boolean(
      (rawS3UsageData && rawS3UsageData.length === 0) || (computedData && computedData.values.length === 0)
    ),
    data: computedData,
  };
};

export default useS3IntelligentTieringData;
