import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, styled } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';

import { SlackIcon, VerticeVIcon } from '@vertice/assets';
import { useGetSlackIntegrationConfigQuery, useUpdateSlackIntegrationConfigMutation } from '@vertice/slices';
import Card, {
  CardHeader,
  CardHeaderDescription,
  CardHeaderTitle,
} from '@verticeone/design-system/src/components/Card';
import Text from '@verticeone/design-system/src/components/Text';
import Tooltip from '@verticeone/design-system/src/components/Tooltip';
import IconWrapper from '@verticeone/design-system/src/components/IconWrapper';
import ToggleSwitch from '@verticeone/design-system/src/components/ToggleSwitch';
import { StepProps } from '../../../common';
import { SlackUsersGrid } from './SlackUsersGrid';

const MessageWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing(2),
  border: `1px solid ${theme.palette.core.color3}`,
  borderRadius: 12,
  backgroundColor: theme.palette.core.color1,
  padding: theme.spacing(4),
}));

const AppBadge = styled(Box)(({ theme }) => ({
  borderRadius: 4,
  backgroundColor: theme.palette.core.color3,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  textTransform: 'uppercase',
  lineHeight: 1,
}));

const ActionsBar = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  gap: theme.spacing(2),
  padding: '8px 24px',
  borderBottom: `1px solid ${theme.palette.core.color3}`,
  backgroundColor: theme.palette.core.color1,
}));

export const ConnectionStep: FC<Omit<StepProps, 'setStep'>> = ({ accountId }) => {
  const { t } = useTranslation();

  const { data } = useGetSlackIntegrationConfigQuery({ accountId });
  const [enableAutoConnection, { isLoading: isLoadingAutoConnection }] = useUpdateSlackIntegrationConfigMutation();

  const [isAutoConnectionEnabled, setIsAutoConnectionEnabled] = useState(false);

  useEffect(() => {
    if (data?.automaticallyConnectMatchedUsers !== undefined)
      setIsAutoConnectionEnabled(data.automaticallyConnectMatchedUsers);
  }, [data?.automaticallyConnectMatchedUsers]);

  return (
    <Stack gap={2}>
      <Card>
        <CardHeader size="S">
          <Stack direction="row" alignItems="center" gap={2}>
            <IconWrapper icon={SlackIcon} />
            <CardHeaderTitle text={t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CARD_HEADER_TITLE')} />
          </Stack>
          <Text variant="body-regular" size="S" color="text2">
            {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CARD_HEADER_SUBTITLE')}
          </Text>
        </CardHeader>
        <Stack direction="row" gap={6} p={6}>
          <Stack flex={1} gap={8}>
            <Stack gap={2}>
              <Text variant="caption" size="S" color="success1">
                {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.WORKSPACE_TITLE')}
              </Text>
              <Text variant="heading" size="M" color="success1">
                {/* // TODO: Get workspace URL from BE once the API is ready */}
              </Text>
            </Stack>
          </Stack>
          <Stack flex={1} gap={2}>
            <Text variant="caption" size="XS" color="text2">
              {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_PREVIEW_TITLE')}
            </Text>
            <MessageWrapper>
              <IconWrapper icon={VerticeVIcon} />
              <Stack gap={4}>
                <Box>
                  <Stack direction="row" gap={1} alignItems="center">
                    <Text variant="body-bold" size="XS" color="text1">
                      {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_HEAD_VERTICE')}
                    </Text>
                    <AppBadge>
                      <Text variant="body-regular" size="XXS" color="text2">
                        {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_HEAD_APP')}
                      </Text>
                    </AppBadge>
                    <Text variant="body-regular" size="XS" color="text2">
                      {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_HEAD_TIME')}
                    </Text>
                  </Stack>
                  <Text variant="body-regular" size="XS" color="text2" sx={{ fontWeight: 500 }}>
                    {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_HEAD_TITLE')}
                  </Text>
                </Box>
                <Stack>
                  <Text variant="body-bold" size="XS" color="text2">
                    {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_P1_TITLE')}
                  </Text>
                  <Text variant="body-regular" size="XS" color="text2" sx={{ fontWeight: 500 }}>
                    {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_P1_CONTENT')}
                  </Text>
                </Stack>
                <Stack>
                  <Text variant="body-bold" size="XS" color="text2">
                    {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_P2_TITLE')}
                  </Text>
                  <Text variant="body-regular" size="XS" color="text2" sx={{ fontWeight: 500 }}>
                    {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_P2_CONTENT')}
                  </Text>
                </Stack>
              </Stack>
            </MessageWrapper>
          </Stack>
        </Stack>
      </Card>
      <Card>
        <CardHeader size="S">
          <CardHeaderTitle text={t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.USERS_CARD_HEADER_TITLE')} />
          <CardHeaderDescription>
            {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.USERS_CARD_HEADER_DESCRIPTION')}
          </CardHeaderDescription>
        </CardHeader>
        <ActionsBar>
          <ToggleSwitch
            color="neutral"
            size="S"
            onChange={async () => {
              await enableAutoConnection({
                accountId,
                body: { automaticallyConnectMatchedUsers: !isAutoConnectionEnabled },
              });
              setIsAutoConnectionEnabled((prevState) => !prevState);
            }}
            checked={isAutoConnectionEnabled}
            disabled={isLoadingAutoConnection}
          />
          <Text variant="body-regular" size="S" color="text1">
            {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.TOGGLE_CHECKBOX')}
          </Text>
          <Tooltip content={t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.TOGGLE_INFO')} size="M">
            <IconWrapper icon={InfoIcon} size="M" />
          </Tooltip>
        </ActionsBar>
        <SlackUsersGrid accountId={accountId} />
      </Card>
    </Stack>
  );
};
