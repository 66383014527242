import { servicesAPI as api } from '../../api/servicesAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiVersion: build.query<GetApiVersionApiResponse, GetApiVersionApiArg>({
      query: () => ({ url: `/version` }),
    }),
    listServiceCatalog: build.query<ListServiceCatalogApiResponse, ListServiceCatalogApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/serviceCatalog`,
        params: {
          nextToken: queryArg.nextToken,
          maxResults: queryArg.maxResults,
          includeAllVersions: queryArg.includeAllVersions,
        },
      }),
    }),
    updateServiceCatalogServiceResource: build.mutation<
      UpdateServiceCatalogServiceResourceApiResponse,
      UpdateServiceCatalogServiceResourceApiArg
    >({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/serviceCatalog/service`,
        method: 'POST',
        body: queryArg.updateServiceCatalogResourceService,
        params: { resourceUrn: queryArg.resourceUrn },
      }),
    }),
    listRequests: build.query<ListRequestsApiResponse, ListRequestsApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/requests`,
        params: { nextToken: queryArg.nextToken, maxResults: queryArg.maxResults },
      }),
    }),
    createRequest: build.mutation<CreateRequestApiResponse, CreateRequestApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/requests`,
        method: 'POST',
        body: queryArg.createRequest,
      }),
    }),
    updateRequestsOwner: build.mutation<UpdateRequestsOwnerApiResponse, UpdateRequestsOwnerApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/requests/updateOwner`,
        method: 'PUT',
        body: queryArg.updateRequestsOwner,
      }),
    }),
    getRequest: build.query<GetRequestApiResponse, GetRequestApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/requests/${queryArg.requestId}` }),
    }),
    getRequestJournal: build.query<GetRequestJournalApiResponse, GetRequestJournalApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/requests/${queryArg.requestId}/journal` }),
    }),
    terminateRequest: build.mutation<TerminateRequestApiResponse, TerminateRequestApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/requests/${queryArg.requestId}/terminate`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as servicesAPICodegen };
export type GetApiVersionApiResponse = unknown;
export type GetApiVersionApiArg = void;
export type ListServiceCatalogApiResponse = /** status 200 List of ServiceCatalog items */ {
  items?: ServiceCatalogResource[];
  /** Token to get the next page of results.
    Example: `523jn5324nmnfklj3`
     */
  nextToken?: string;
};
export type ListServiceCatalogApiArg = {
  accountId: string;
  nextToken?: string | null;
  maxResults?: number;
  includeAllVersions?: boolean;
};
export type UpdateServiceCatalogServiceResourceApiResponse = /** status 200 Object reference urn */ {
  ref: string;
};
export type UpdateServiceCatalogServiceResourceApiArg = {
  accountId: string;
  resourceUrn: string;
  /** Update service catalog service resource */
  updateServiceCatalogResourceService: UpdateServiceCatalogResourceService;
};
export type ListRequestsApiResponse = /** status 200 Request list response */ {
  items: Request[];
  nextToken?: PaginationToken;
};
export type ListRequestsApiArg = {
  accountId: string;
  nextToken?: string | null;
  maxResults?: number;
};
export type CreateRequestApiResponse = /** status 201 Object reference urn */ {
  ref: string;
};
export type CreateRequestApiArg = {
  accountId: string;
  /** Request create request */
  createRequest: CreateRequest;
};
export type UpdateRequestsOwnerApiResponse = /** status 200 Object reference urns */ ObjectRefResponse[];
export type UpdateRequestsOwnerApiArg = {
  accountId: string;
  /** Request update request */
  updateRequestsOwner: UpdateRequestsOwner;
};
export type GetRequestApiResponse = /** status 200 Request detail response */ {
  request: Request;
};
export type GetRequestApiArg = {
  accountId: string;
  requestId: string;
};
export type GetRequestJournalApiResponse = /** status 200 Request journal response */ {
  items: JournalItem[];
  nextToken?: PaginationToken;
};
export type GetRequestJournalApiArg = {
  accountId: string;
  requestId: string;
};
export type TerminateRequestApiResponse = /** status 200 Empty Response */ object;
export type TerminateRequestApiArg = {
  accountId: string;
  requestId: string;
};
export type ServiceCatalogResourceDefinition = {
  [key: string]: any;
};
export type ResourceThumbnail = {
  Type: 'PREDEFINED';
  Id: string;
};
export type ServiceCatalogResource = {
  name: string;
  status: string;
  kind: string;
  urn: string;
  managedBy?: string;
  definition: ServiceCatalogResourceDefinition;
  thumbnail?: ResourceThumbnail;
  category?: string;
};
export type UpdateServiceCatalogResourceService = {
  /** Custom name for the service */
  name?: string;
  /** Handler URN.
    Example: `urn:verticeone:vertice:123456:vertice-workflows:workflow/default_triage_workflow`
     */
  handlerUrn: string;
};
export type DateTime = string;
export type Request = {
  /** Request reference urn */
  ref: string;
  /** Parent request reference urn */
  parentRequestRef?: string | null;
  parentRequestName?: string | null;
  serviceRef: string;
  serviceName?: string;
  name: string;
  status: 'PENDING' | 'ACTIVE' | 'COMPLETED' | 'CANCELLED' | 'TERMINATED' | 'FAILED';
  errorMessage?: string;
  input: {
    [key: string]: any;
  };
  /** Reserved properties for Vertice platform */
  verticeReserved?: {
    [key: string]: any;
  };
  /** Request owner */
  owner?: string | null;
  /** Requestor reference urn */
  requestorRef: string;
  /** Source reference urn */
  sourceRef: string;
  /** Ref of underlying execution instance, e.g. `workflowInstanceRef` */
  executionRef?: string | null;
  result?: {
    [key: string]: any;
  };
  resultType?: string;
  createdAt: DateTime;
  createdBy: string | null;
  closedAt?: DateTime;
  updatedAt?: DateTime;
  changedBy?: string | null;
};
export type PaginationToken = string | null;
export type CreateRequest = {
  /** Request reference urn that the source originates from */
  requestRef?: string;
  serviceRef: string;
  name: string;
  input: {
    [key: string]: any;
  };
  /** Request owner. Gets propagated to verticeReserved.request.owner (and back if not set) */
  owner?: string;
  /** Requestor reference urn */
  sourceRef: string;
};
export type ObjectRefResponse = any;
export type UpdateRequestsOwner = {
  /** Request reference urns */
  requestRefs: string[];
  /** Request owner. Gets propagated to verticeReserved.request.owner (and back if not set) */
  owner: string;
};
export type JournalItem = {
  actor: string;
  intents: string[];
  delta: string;
  timestamp: DateTime;
};
export const {
  useGetApiVersionQuery,
  useLazyGetApiVersionQuery,
  useListServiceCatalogQuery,
  useLazyListServiceCatalogQuery,
  useUpdateServiceCatalogServiceResourceMutation,
  useListRequestsQuery,
  useLazyListRequestsQuery,
  useCreateRequestMutation,
  useUpdateRequestsOwnerMutation,
  useGetRequestQuery,
  useLazyGetRequestQuery,
  useGetRequestJournalQuery,
  useLazyGetRequestJournalQuery,
  useTerminateRequestMutation,
} = injectedRtkApi;
