import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useTopProductsBySpendData from './useTopProductsBySpendData';
import TopProductsBySpendTable from './TopProductsBySpendTable';
import TopProductsBySpendFilter, { FilterOption } from './TopProductsBySpendFilter';
import { LoadableContentWrapper } from '../../components/LoadableContentWrapper';
import Card, { CardHeader, CardHeaderTitle } from '@verticeone/design-system/src/components/Card';
import CardDivider from '../shared/CardDivider';

const monthWindow = {
  '6_months': 6,
  '3_months': 3,
  '1_month': 1,
};

const TopProductsBySpendCard = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const [filter, setFilter] = useState<FilterOption>('6_months');
  const { data, ...loadingStatus } = useTopProductsBySpendData(monthWindow[filter]);
  return (
    <Card sx={{ position: 'relative', height: '100%', minHeight: 480, minWidth: 485 }}>
      <CardHeader size="S">
        <CardHeaderTitle text={t('TOP_PRODUCTS_BY_SPEND.TITLE')} tag="h2" />
      </CardHeader>
      <TopProductsBySpendFilter selected={filter} onChange={setFilter} />
      <CardDivider />
      <LoadableContentWrapper {...loadingStatus} sx={{ flex: 1 }}>
        <TopProductsBySpendTable data={data!} />
      </LoadableContentWrapper>
    </Card>
  );
};

export default TopProductsBySpendCard;
