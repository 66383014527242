import { FieldPath } from 'react-hook-form';
import {
  ContractFormData,
  FetchedContract,
  FieldRule,
  FieldRulesPerField,
  FormPlusComputedFieldPaths,
  OverrideFieldRuleGetter,
  OverrideFieldRuleGetterContext,
  ResolvedFieldRulesPerField,
  ResolvedFieldsConfig,
} from '../types';
import { ZodTypeAny } from 'zod';
import { mapValues } from 'lodash';
import { setRequiredTo } from '../../../../utils/validation/zodUtils';

export type FieldsConfigEvaluationContext = {
  fieldsConfig: ResolvedFieldsConfig;
  fetchedContract?: FetchedContract;
  formData: ContractFormData;
  isEditing: boolean;
};

export const evalFieldRule = (
  fieldName: FormPlusComputedFieldPaths,
  context: FieldsConfigEvaluationContext
): FieldRule | undefined => {
  const fieldRules = context.fieldsConfig.fieldRules;
  return fieldRules[fieldName];
};

export const isFieldVisible = (
  fieldName: FormPlusComputedFieldPaths,
  context: FieldsConfigEvaluationContext
): boolean => {
  const { fieldsConfig, isEditing } = context;

  const fieldRule = evalFieldRule(fieldName, context);

  if (!fieldRule) return fieldsConfig.isVisibleAsDefault;

  if (fieldRule[0] === 'HIDDEN') return false;

  const { showIn = 'BOTH' } =
    (fieldRule[0] === 'READABLE' ? fieldRule[1] : fieldRule[0] === 'WRITABLE' ? fieldRule[2] : undefined) ?? {};

  if (isEditing && showIn === 'READ_MODE_ONLY') return false;

  if (!isEditing && showIn === 'EDIT_MODE_ONLY') return false;

  return true;
};

export const isFieldRequired = (
  fieldName: FieldPath<ContractFormData>,
  context: FieldsConfigEvaluationContext
): boolean => {
  const fieldRule = evalFieldRule(fieldName, context);
  if (!fieldRule || fieldRule[0] !== 'WRITABLE') return false;
  const zodSchema = fieldRule[1];
  return !zodSchema.isOptional();
};

export const isFieldEditable = (
  fieldName: FieldPath<ContractFormData>,
  context: FieldsConfigEvaluationContext
): boolean => {
  const fieldRule = evalFieldRule(fieldName, context);
  return (
    context.isEditing &&
    ((!!fieldRule && fieldRule[0] === 'WRITABLE') || (!fieldRule && context.fieldsConfig.isWritableAsDefault))
  );
};

/** Updates schema in 'WRITABLE' rule using the passed updateFn. If the rule is not 'WRITABLE', returns it with no change. */
export const updateRuleSchema = (origRule: FieldRule, updateFn: (schema: ZodTypeAny) => ZodTypeAny): FieldRule => {
  if (origRule[0] !== 'WRITABLE') return origRule;
  const newSchema = updateFn(origRule[1]);
  return origRule.length === 3 ? ['WRITABLE', newSchema, origRule[2]] : ['WRITABLE', newSchema];
};

const isFieldRule = (rule: FieldRule | OverrideFieldRuleGetter | undefined): rule is FieldRule => {
  return rule !== undefined && typeof rule !== 'function';
};

const resolveRule = (rule: FieldRule | OverrideFieldRuleGetter, baseRule: FieldRule): FieldRule =>
  isFieldRule(rule) ? rule : rule({ baseRule });

export const overrideRules = (
  baseRules: ResolvedFieldRulesPerField,
  newRules: FieldRulesPerField
): ResolvedFieldRulesPerField => ({
  ...baseRules,
  ...mapValues(newRules, (newRule, r: FormPlusComputedFieldPaths) => {
    const baseRule = baseRules[r];
    if (!baseRule) {
      if (isFieldRule(newRule)) return newRule;
      throw new Error(`Field ${r} not found in base rules`);
    }
    if (!newRule) {
      throw new Error(`Undefined rule override for field ${r}`);
    }
    return resolveRule(newRule, baseRule);
  }),
});

export const makeFieldRequiredIf =
  (condition: boolean) =>
  ({ baseRule }: OverrideFieldRuleGetterContext) => {
    if (!baseRule) {
      throw new Error('baseRule is required');
    }
    return updateRuleSchema(baseRule, (s) => (condition ? setRequiredTo(s, true) : s));
  };
