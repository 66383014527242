import { CheckTwoTone, SvgIconComponent } from '@mui/icons-material';
import { TaskColorVariant } from '../workflowSchema/WorkflowRenderer/NodeComponents/TaskNode/types';
import { Stack, styled } from '@mui/material';
import { FC } from 'react';
import { TaskStatus } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { DesignSystemSize } from '@verticeone/design-system/src/types';
import useLoggedUser from '@verticeone/auth/src/authentication/useLoggedUser';

type TaskStyle = {
  Icon?: SvgIconComponent;
  colorVariant: TaskColorVariant;
  withShadow?: boolean;
  withColoredBorder?: boolean;
  invertedColors?: boolean;
};

export const useTaskStyle = (status?: string, assignees?: string[], type?: string): TaskStyle => {
  const { userId } = useLoggedUser();
  const isAssignedToCurrentUser = assignees?.includes(userId);

  switch (type) {
    case 'VERTICE_SERVICE':
      return getTaskStyleForVerticeServiceTask(status);
    case 'SERVICE':
      return getTaskStyleForUserServiceTask(status);
    default:
      return getTaskStyleForUserTask(isAssignedToCurrentUser, status);
  }
};

const getTaskStyleForUserTask = (isAssignedToCurrentUser?: boolean, status?: string): TaskStyle => {
  switch (status) {
    case 'COMPLETED':
      return {
        Icon: CheckTwoTone,
        colorVariant: 'success',
      };
    case 'ACTIVE':
      return {
        colorVariant: isAssignedToCurrentUser ? 'secondary' : 'neutral',
        withShadow: true,
        withColoredBorder: true,
      };
    case 'FAILED':
      return {
        colorVariant: 'error',
        withShadow: true,
        withColoredBorder: true,
        invertedColors: true,
      };
    default:
      return {
        colorVariant: 'transparent',
      };
  }
};

const getTaskStyleForVerticeServiceTask = (status?: string): TaskStyle => {
  switch (status) {
    case 'COMPLETED':
      return {
        Icon: CheckTwoTone,
        colorVariant: 'success',
      };
    case 'ACTIVE':
      return {
        colorVariant: 'primary',
        withShadow: true,
        withColoredBorder: true,
      };
    case 'FAILED':
      return {
        colorVariant: 'error',
        withShadow: true,
        withColoredBorder: true,
        invertedColors: true,
      };
    default:
      return {
        colorVariant: 'primary',
      };
  }
};

const getTaskStyleForUserServiceTask = (status?: string): TaskStyle => {
  switch (status) {
    case 'COMPLETED':
      return {
        Icon: CheckTwoTone,
        colorVariant: 'success',
      };
    case 'ACTIVE':
      return {
        colorVariant: 'neutral',
        withShadow: true,
        withColoredBorder: true,
      };
    case 'FAILED':
      return {
        colorVariant: 'error',
        withShadow: true,
        withColoredBorder: true,
        invertedColors: true,
      };
    default:
      return {
        colorVariant: 'transparent',
      };
  }
};

const sizes: Record<DesignSystemSize, string> = {
  XL: '40px',
  L: '36px',
  M: '32px',
  S: '28px',
  XS: '24px',
  XXS: '20px',
};

const StyledTaskIcon = styled(Stack)<{
  $colorVariant: TaskColorVariant;
  $invertedColors?: boolean;
  $size: DesignSystemSize;
}>(({ theme, $colorVariant, $invertedColors, $size }) => ({
  color: $invertedColors ? theme.palette[$colorVariant].color2 : theme.palette[$colorVariant].color4,
  backgroundColor: $invertedColors ? theme.palette[$colorVariant].color3 : theme.palette[$colorVariant].color2,
  borderRadius: 4,
  width: sizes[$size],
  height: sizes[$size],
  padding: 4,
  '& svg': {
    width: '100%',
    height: '100%',
  },
}));

type TaskIconProps = {
  status?: TaskStatus;
  type?: string;
  assigneeIds?: string[];
  size?: DesignSystemSize;
};

export const TaskIcon: FC<TaskIconProps> = ({ status, assigneeIds, type, size = 'M' }) => {
  const { Icon, colorVariant, invertedColors } = useTaskStyle(status, assigneeIds, type);

  return (
    <StyledTaskIcon $colorVariant={colorVariant} $invertedColors={invertedColors} $size={size}>
      {Icon && <Icon />}
    </StyledTaskIcon>
  );
};
