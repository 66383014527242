import { Stack } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import Text from '@verticeone/design-system/src/components/Text';
import { SlackConnections } from '../../../common';

export const VerticeUserCell = (props: GridRenderCellParams<SlackConnections>) => {
  const {
    row: { userName, firstName, lastName },
  } = props;

  return (
    <Stack>
      <Text variant="body-regular" size="M" color="text1">
        {userName}
      </Text>
      <Text variant="caption" size="XS" color="error1">
        {`${firstName} ${lastName}`}
      </Text>
    </Stack>
  );
};
