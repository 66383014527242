import React, { useMemo } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import getAmplifyJwtToken from '@verticeone/auth/src/authentication/amplify/getAmplifyJwtToken';
import { print } from 'graphql';
import { API_URLS } from '@vertice/slices';

type CloudClientContextProviderProps = {
  children: React.ReactNode;
};

export const fetchCloudOptimization = async <TResult, TVariables>(
  document: TypedDocumentNode<TResult, TVariables>,
  variables: TVariables
): Promise<TResult> => {
  const response = await fetch(API_URLS.CLOUDOPTIMIZATION_GRAPHQL_URL, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${await getAmplifyJwtToken()}`,
    },
    body: JSON.stringify({
      query: print(document),
      variables,
    }),
  });

  if (response.status !== 200) {
    throw new Error(`Failed to fetch: ${response.statusText}. Body: ${await response.text()}`);
  }

  return await response.json().then((res) => {
    return new Promise(function (resolve, reject) {
      if (res.errors) {
        reject(res.errors);
      } else {
        resolve(res.data);
      }
    });
  });
};

const CloudClientContextProvider = ({ children }: CloudClientContextProviderProps) => {
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 60000,
          },
        },
      }),
    []
  );

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export default CloudClientContextProvider;
