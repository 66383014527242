import React, { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Divider, Stack, Typography } from '@mui/material';
import { FileWithPath, Loader, ConfirmationDialog } from '@vertice/components';
import {
  useListFilesQuery,
  useUploadFileMutation,
  useRequestVendorSecurityStandardQuestionnairesMutation,
  useLazyGetPreSignedLinkQuery,
} from '@vertice/slices';
import { useSnackbar } from 'notistack';
import { autoDownload, autoDownloadBlob } from '@vertice/core/src/utils/file/downloadFile';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import QuestionnaireFile, { Questionnaire } from './QuestionnaireFile';
import DragDropArea from './DragDropArea';
import { getFileNames, getFiles, prepareQuestionnaires } from './utils';
import commonStyles from './VendorDiligenceInsights.module.scss';
import styles from './VendorSecurity.module.scss';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

const standardQuestionnaireStub: Questionnaire = {
  fileName: 'standard_questionnaire.xlsx',
  status: 'notSent',
  state: 'active',
};

interface VendorSecurityProps {
  vendorId: string;
}

const VendorSecurity = ({ vendorId }: VendorSecurityProps) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const { enqueueSnackbar } = useSnackbar();

  const [stagedFile, setStagedFile] = useState<FileWithPath | null>();
  const [stagedState, setStagedState] = useState<'active' | 'received'>('active');
  const [replaceOpen, setReplaceOpen] = useState(false);
  const [downloadingFile, setDownloadingFile] = useState('');

  const {
    data: customQuestionnairesData,
    isLoading: isLoadingCustom,
    refetch: refetchCustom,
  } = useListFilesQuery(
    {
      accountId: accountId!,
      'path+': `vendors/${vendorId}/legal_docs`,
    },
    { skip: !accountId || !vendorId }
  );

  const {
    data: standardQuestionnairesData,
    isLoading: isLoadingStandard,
    refetch: refetchStandard,
  } = useListFilesQuery(
    {
      accountId: 'VERTICE',
      'path+': `vendors/${vendorId}/legal_docs`,
    },
    { skip: !vendorId }
  );

  const { data: standardAssetsData, isLoading: isLoadingStandardAssets } = useListFilesQuery({
    accountId: 'VERTICE',
    'path+': 'assets/legal_docs',
  });

  const allQuestionnaires = [...(standardQuestionnairesData?.files || []), ...(customQuestionnairesData?.files || [])];

  const allRequestedQuestionnaires = useMemo(
    () => prepareQuestionnaires(allQuestionnaires),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [standardQuestionnairesData, customQuestionnairesData]
  );

  const standardQuestionnairesFileNames = getFileNames(getFiles(standardQuestionnairesData?.files || []));
  const standardAssetsFileNames = getFileNames(getFiles(standardAssetsData?.files || []));

  const standardQuestionnaireWasRequested = standardQuestionnairesFileNames.includes(
    standardQuestionnaireStub.fileName
  );
  const withStandardQuestionnaire = standardAssetsFileNames.includes(standardQuestionnaireStub.fileName);

  const [getPreSignedLink] = useLazyGetPreSignedLinkQuery();
  const [uploadFile, { isLoading: isFileUploading }] = useUploadFileMutation();

  const [sendStandardQuestionnaires, { isLoading: isSendingStandardQuestionnaires }] =
    useRequestVendorSecurityStandardQuestionnairesMutation();

  const handleFile = (file: FileWithPath) => {
    if (file.name === standardQuestionnaireStub.fileName) {
      enqueueSnackbar(t('LEGAL_INFO.ERROR.NOT_ALLOWED_FILE_NAME'), {
        variant: 'error',
      });
    } else {
      setStagedFile(file);
    }
  };
  const handleCancel = () => setStagedFile(null);

  const handleStandardSend = () =>
    sendStandardQuestionnaires({
      accountId: accountId!,
      vendorId: vendorId!,
    }).then(() => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      refetchStandard();
    });

  const cancelFileReplace = () => setReplaceOpen(false);

  const confirmFileReplace = useCallback(() => {
    if (stagedFile) {
      handleStagedSend(stagedFile);
    }
    setReplaceOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stagedFile]);

  const onStagedSendClick = useCallback(() => {
    if (stagedFile) {
      if (getFileNames(allQuestionnaires).includes(stagedFile.name)) {
        setReplaceOpen(true);
      } else {
        handleStagedSend(stagedFile);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stagedFile]);

  const handleStagedSend = (file: FileWithPath) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    uploadFile({
      accountId: accountId!,
      'path+': `vendors/${vendorId}/legal_docs/questionnaires/${file.name}`,
      body: file,
    }).then(() => {
      setStagedState('received');

      let inactiveTimeout = false;
      let refetched = false;

      setTimeout(() => {
        inactiveTimeout = true;
        if (refetched) {
          setStagedFile(null);
          setStagedState('active');
        }
      }, 3000); // UI behaviour -- not reset storage file during at least 3 seconds

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      refetchCustom().then(() => {
        refetched = true;
        if (inactiveTimeout) {
          setStagedFile(null);
          setStagedState('active');
        }
      });
    });
  };

  const handleStandardDownload = () => {
    setDownloadingFile(standardQuestionnaireStub.fileName);

    getPreSignedLink({
      accountId: 'VERTICE',
      'path+': `assets/legal_docs/${standardQuestionnaireStub.fileName}`,
      responseContentDisposition: 'attachment',
    })
      .unwrap()
      .then((reply) => {
        if (reply.preSignedLink) {
          autoDownload(reply.preSignedLink, standardQuestionnaireStub.fileName);
        }
        setDownloadingFile('');
      })
      .catch(() => setDownloadingFile(''));
  };

  const handleStagedDownload = () => {
    if (stagedFile) {
      autoDownloadBlob(stagedFile, stagedFile.name);
    }
  };

  const handleRequestedDownload = (fullPath: string, fileName: string) => () => {
    const vendorsParts = fullPath.split('/vendors/');
    setDownloadingFile(fileName);

    getPreSignedLink({
      accountId: vendorsParts[0].split('accounts/')[1],
      'path+': `vendors/${vendorsParts[1]}`,
      responseContentDisposition: 'attachment',
    })
      .unwrap()
      .then((reply) => {
        if (reply.preSignedLink) {
          autoDownload(reply.preSignedLink, standardQuestionnaireStub.fileName);
        }
        setDownloadingFile('');
      })
      .catch(() => setDownloadingFile(''));
  };

  if (isLoadingCustom || isLoadingStandard || isLoadingStandardAssets) {
    return (
      <div className={commonStyles.body}>
        <Loader />
      </div>
    );
  }

  return (
    <Stack className={commonStyles.body} data-testid="content">
      <Stack gap="8px">
        <Typography variant="caption-s" className={commonStyles['sub-header']}>
          <ForumOutlinedIcon fontSize="inherit" />
          {t('LEGAL_INFO.SECURITY_TITLE')}
        </Typography>

        <Typography variant="body-regular-s" color="var(--tokens-color-light-text-2)">
          {t('LEGAL_INFO.SECURITY_DESCRIPTION')}
        </Typography>
      </Stack>

      <Stack gap="8px">
        {withStandardQuestionnaire ? (
          <QuestionnaireFile
            {...standardQuestionnaireStub}
            onSend={handleStandardSend}
            onDownload={handleStandardDownload}
            state={standardQuestionnaireWasRequested ? 'inactive' : standardQuestionnaireStub.state}
            status={standardQuestionnaireWasRequested ? 'sent' : standardQuestionnaireStub.status}
            isLoading={
              isSendingStandardQuestionnaires ||
              (!standardQuestionnaireWasRequested && downloadingFile === standardQuestionnaireStub.fileName)
            }
          />
        ) : (
          <Typography variant="body-regular-s" className={styles['requested-files-wrap']}>
            {t('LEGAL_INFO.NO_STANDARD_QUESTIONNAIRE')}
          </Typography>
        )}

        <Divider orientation="horizontal" className={commonStyles.divider}>
          <Typography variant="caption-s" className={clsx(styles.or, 'uppercase')}>
            {t('LEGAL_INFO.OR_DIVIDER')}
          </Typography>
        </Divider>

        {stagedFile ? (
          <QuestionnaireFile
            state={isFileUploading ? 'inactive' : stagedState}
            status="notSent"
            fileName={stagedFile.name}
            onSend={onStagedSendClick}
            onCancel={handleCancel}
            onDownload={handleStagedDownload}
            isLoading={isFileUploading}
          />
        ) : (
          <DragDropArea handleFile={handleFile} />
        )}
      </Stack>

      <Stack className={styles['requested-files']} gap="8px">
        <Typography variant="caption-s" color="var(--tokens-color-light-text-2)">
          {t('LEGAL_INFO.REQUESTED_FILES_TITLE')}
        </Typography>

        {allRequestedQuestionnaires.length ? (
          allRequestedQuestionnaires.map((questionnaire) => (
            <QuestionnaireFile
              {...questionnaire}
              fileName={questionnaire.fileName}
              status={questionnaire.status}
              state="active"
              onDownload={handleRequestedDownload(questionnaire.fullPath, questionnaire.fileName)}
              key={questionnaire.fileName}
              isLoading={downloadingFile === questionnaire.fileName}
            />
          ))
        ) : (
          <Typography variant="body-regular-s" className={styles['requested-files-wrap']}>
            {t('LEGAL_INFO.REQUESTED_FILES_PLACEHOLDER')}
          </Typography>
        )}
      </Stack>

      <ConfirmationDialog
        isOpen={replaceOpen}
        headerText={t('FILE.REPLACE_MODAL_TITLE')}
        bodySecondaryText={t('FILE.REPLACE_MODAL_MESSAGE')}
        primaryButtonText={t('FILE.ACTION_BUTTONS.REPLACE')}
        secondaryButtonText={t('FILE.ACTION_BUTTONS.CANCEL')}
        primaryButtonAction={confirmFileReplace}
        secondaryButtonAction={cancelFileReplace}
      />
    </Stack>
  );
};

export default VendorSecurity;
