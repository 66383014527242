import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Stack, useTheme } from '@mui/material';

import Text from '@verticeone/design-system/src/components/Text';
import Button from '@verticeone/design-system/src/components/Button';
import { Dialog, DialogContent, DialogHeader } from '@verticeone/design-system/src/components/Dialog';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import {
  DiscoveredApplicationMutation,
  useDiscoveredApplicationMutation,
} from '@vertice/slices/src/graphql/insight/generated/insightGraphQL';
import { ApplicationWithMeasureReports } from '@vertice/slices/src/graphql/bffeUsage/derivedTypes';
import ContractList from './ContractList';
import { DataHealthCode } from '../DataHealth/types';

type ContractDialogProps = {
  open: boolean;
  onClose: () => void;
  onSuccess: (code: DataHealthCode) => void;
  application?: ApplicationWithMeasureReports;
};

const checkIfCorrected = (response: DiscoveredApplicationMutation) =>
  response.updateApplication?.dataHealth?.checks?.some(
    (check) =>
      (check.code === DataHealthCode.CONTRACT_LINK_MISSING && check.status === 'PASSED') || check.status === 'IGNORED'
  );

const ContractDialog = ({ open, onClose, onSuccess, application }: ContractDialogProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const { accountId } = useAccountContext();
  const [selectedContract, setSelectedContract] = useState('');
  const [updateDiscoveredApplication] = useDiscoveredApplicationMutation();

  useEffect(() => {
    if (!open) {
      setSelectedContract('');
    }
  }, [open]);

  const handleMarkApplicationAsUnsanctioned = async () => {
    const response = await updateDiscoveredApplication({
      accountId: accountId,
      input: {
        id: application?.id!,
        dataHealthConfigs: [
          {
            code: DataHealthCode.CONTRACT_LINK_MISSING,
            enabled: false,
          },
          {
            code: DataHealthCode.LICENSE_COUNT_MISSING,
            enabled: false,
          },
        ],
      },
    });

    if ('data' in response && checkIfCorrected(response.data)) {
      onSuccess(DataHealthCode.CONTRACT_LINK_MISSING);
    }
  };

  const handleLinkContract = useCallback(
    async (lineageId: string) => {
      const response = await updateDiscoveredApplication({
        accountId: accountId,
        input: {
          id: application?.id!,
          matches: [
            ...(application?.matches ?? []).filter((item) => item.entityId !== lineageId),
            { entityId: lineageId, entityName: `Contract ${lineageId}`, entityType: 'Contract' },
          ],
        },
      });

      if ('data' in response && checkIfCorrected(response.data)) {
        onSuccess(DataHealthCode.CONTRACT_LINK_MISSING);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId, application]
  );

  return (
    <Dialog size="M" open={open} setOpen={onClose}>
      <DialogHeader>
        <Text variant="heading" size="S" color={palette.text.color1}>
          {t('APPLICATIONS.USAGE.CONTRACT_DIALOG.HEADER')}
        </Text>
      </DialogHeader>
      <Divider />
      <DialogContent>
        <Stack gap={4}>
          <Stack gap={1}>
            <Text size="XS" variant="caption" mb={1}>
              {t('APPLICATIONS.USAGE.CONTRACT_DIALOG.CONTRACT_SUGGESTIONS')}
            </Text>
            <ContractList
              selectedContract={selectedContract}
              setSelectedContract={setSelectedContract}
              application={application}
            />
          </Stack>
          <Button
            variant="solid"
            size="M"
            color="primary"
            onClick={async () => {
              onClose();
              await handleLinkContract(selectedContract);
            }}
            disabled={selectedContract === ''}
          >
            {t('APPLICATIONS.USAGE.CONTRACT_DIALOG.CONFIRM')}
          </Button>
          <Divider>{t('APPLICATIONS.USAGE.CONTRACT_DIALOG.OR')}</Divider>
          <Button
            onClick={async () => {
              onClose();
              await handleMarkApplicationAsUnsanctioned();
            }}
          >
            {t('APPLICATIONS.USAGE.CONTRACT_DIALOG.UNSANCTIONED_APPLICATION')}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ContractDialog;
